import { Text, TextInput } from '@heathmont/moon-core';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import FormField from '../FormField';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import { expensesRequireStatuses } from '../../pages/Tools/EmploymentTypes/suggestions';
import { getRequiredValue } from '../../utils/tableHelpers';
import { schema } from './schema';
import { IAddNewEmploymentType } from './types';
import {
  FormFieldWrapper,
  ButtonsWrapper,
  DialogWrapper,
  Background,
  CloseIcon,
  Wrapper,
  Content,
  Footer,
  Label,
  Title,
} from './styles';

const AddNewEmploymentType: React.FC<IAddNewEmploymentType> = ({
  secondButtonLabel,
  label,
  handleCloseDialog,
  handleAgree,
}) => {
  return (
    <>
      <Background onClick={() => handleCloseDialog()} />

      <DialogWrapper>
        <Wrapper>
          <Formik
            validationSchema={schema}
            isInitialValid
            initialValues={{
              expensesRequired: 1,
              label: '',
            }}
            validateOnBlur={true}
            onSubmit={(values, { resetForm }): void => {
              handleAgree(values);
              resetForm();
            }}
          >
            {({ errors, values, resetForm, setFieldValue, touched, handleBlur }: any): JSX.Element => {
              return (
                <Form>
                  <Title>
                    <Text size={18}>{label}</Text>

                    <CloseIcon onClick={() => handleCloseDialog()}>
                      <CloseSvg />
                    </CloseIcon>
                  </Title>

                  <Content>
                    <FormFieldWrapper>
                      <FormField
                        errorPosition={-20}
                        fieldName="label"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="label"
                        isError={touched?.label && errors?.label}
                        errors={touched?.label && errors}
                        value={values.label}
                        label="Employment type name"
                        type="text"
                        onChange={(e) => setFieldValue('label', e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormFieldWrapper>

                    <FormFieldWrapper>
                      <Label>Expense report</Label>

                      <FormField
                        currentValue={values.expensesRequired}
                        fieldName="expensesRequired"
                        component={CustomSelect}
                        fieldId="expensesRequired"
                        value={values.expensesRequired}
                        items={expensesRequireStatuses}
                        getItemLabel={getRequiredValue}
                        onChange={(value) => setFieldValue('expensesRequired', value)}
                      />
                    </FormFieldWrapper>
                  </Content>

                  <Footer>
                    <ButtonsWrapper>
                      <Button
                        variant="tertiary"
                        label="Cancel"
                        handleClick={() => {
                          resetForm();
                          handleCloseDialog();
                        }}
                      />

                      {handleAgree && secondButtonLabel && (
                        <Button
                          isDisabled={!isEmpty(errors)}
                          variant="primary"
                          label={secondButtonLabel}
                          type="submit"
                        />
                      )}
                    </ButtonsWrapper>
                  </Footer>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </DialogWrapper>
    </>
  );
};

export default AddNewEmploymentType;
