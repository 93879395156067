import { IEditFlightDialog } from './types';
import InfoBlock from '../../InfoBlock';
import ExternalFlightForm from '../../AddExternalTripModalContent/forms/ExternalFlightForm';
import Dialog from '../../Dialog';
import { DialogContent } from '../styles';
import { formatFlightDataForForm } from './utils';
import { isFlightExternal } from '../utils';
import { useMemo } from 'react';

const EditFlightDialog: React.FC<IEditFlightDialog> = ({
  editedFlight,
  isLoading,
  onSubmit,
  onClose,
}) => {
  const passengersOptions = useMemo(() => {
    return editedFlight.passengers.map(passenger => ({
      title: `${passenger.firstName} ${passenger.lastName}`,
      value: passenger.id,
    }));
  }, [editedFlight.passengers]);

  return (
    <Dialog
      withFooter={false}
      width={600}
      title={`[${editedFlight.reference}] Edit flight`}
      onClose={onClose}
    >
      <DialogContent>
        <InfoBlock text={isFlightExternal(editedFlight) ? `
            After a manual update, the traveler will be notified,
            and TTR and calendar events will be updated automatically. 
          ` : `
            After a manual update, synchronization with the Amadeus Sales Platform
            will be stopped for the current flight, the traveler will be notified,
            and TTR and calendar events will be updated automatically.  
          `}
        />
        <ExternalFlightForm
          shouldBeScrollable
          isExternalFlight={isFlightExternal(editedFlight)}
          passengers={passengersOptions}
          isEditMode
          isLoading={isLoading}
          handleSubmit={onSubmit}
          handleClose={onClose}
          flightData={formatFlightDataForForm(editedFlight)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditFlightDialog;
