import styled from 'styled-components';

export const FormFooter = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FormItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #181b1c;
  text-align: start;

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}

  .text-input{
    height: 48px;
  }
`;

export const FormItemLabel = styled.span`
  color: #78787d;
  font-size: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 5px;
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;
