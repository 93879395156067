import {
  SET_TOOLS_ALL_ROLES_CHECKBOX_DATA,
  SET_TOOLS_ROLES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_ROLE_MODAL,
  CLOSE_DELETE_ROLES_MODAL,
  SHOW_DELETE_ROLES_MODAL,
  SET_TOOLS_ROLES_DATA,
  SET_TOOLS_PAGES_DATA,
  CLOSE_ROLE_OVERVIEW,
  SHOW_ROLE_OVERVIEW,
} from './actionTypes';

const initState = {
  roles: [],
  totalRoles: 0,
  isActiveAllRolesCheckboxes: false,
  rolesCheckboxesData: [],
  isOpenRolesOverview: false,
  overviewRolesData: null,
  pages: [],
  isDeleteRolesModal: false,
  deletableRoles: [],
  isOpenAddNewRole: false,
};

const rolesReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TOOLS_ROLES_DATA:
      return {
        ...state,
        roles: action.payload.records,
        totalRoles: action.payload.total,
      };

    case SET_TOOLS_ALL_ROLES_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllRolesCheckboxes: isActive,
        rolesCheckboxesData: data,
      };
    }

    case SET_TOOLS_ROLES_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllRolesCheckboxes: false,
          rolesCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          rolesCheckboxesData: [...state.rolesCheckboxesData, data],
        };
      }

      return {
        ...state,
        rolesCheckboxesData: state.rolesCheckboxesData.filter((item) => item.roleId !== data.roleId),
        isActiveAllRolesCheckboxes: false,
      };
    }

    case SHOW_ROLE_OVERVIEW:
      return {
        ...state,
        isOpenRolesOverview: true,
        overviewRolesData: action.payload.data,
        roles: state.roles.map((role) => ({
          ...role,
          isSelected: role.id === action.payload.data.id,
        })),
      };

    case CLOSE_ROLE_OVERVIEW:
      return {
        ...state,
        isOpenRolesOverview: false,
        overviewRolesData: null,
        roles: state.roles.map((role) => ({ ...role, isSelected: false })),
      };

    case SET_TOOLS_PAGES_DATA:
      return {
        ...state,
        pages: action.payload,
      };

    case SHOW_DELETE_ROLES_MODAL:
      return {
        ...state,
        isDeleteRolesModal: true,
        deletableRoles: action.payload.deletableRoles,
      };

    case CLOSE_DELETE_ROLES_MODAL:
      return {
        ...state,
        isDeleteRolesModal: false,
        deletableRoles: [],
      };

    case SET_IS_OPEN_ADD_NEW_ROLE_MODAL:
      return {
        ...state,
        isOpenAddNewRole: action.payload,
      };

    default:
      return state;
  }
};

export default rolesReducer;
