import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { formatStringToNormal } from '../../utils/commonFunctions';
import { EDIT_PROFILE_TABS } from '../../components/Routes/suggestions';

export const optionsMapper = (options, translate) => {
  return options.map(({ id, title, businessVerticals }) => ({
    value: String(id),
    label: translate(title),
    businessVerticals,
  }));
};

export const optionsBusinessVerticalMapper = (options, translate) => {
  return options.map(({ value, title, businessVerticals, type }) => ({
    value: String(value),
    label: translate(title),
    businessVerticals,
    type
  }));
};

export const isEditProfileSubmitButtonDisabled = ({
  isValidNewPassword,
  currentTab,
  errors,
  values,
  dirty,
}) => {
  return !dirty || (
    !!(currentTab === EDIT_PROFILE_TABS.DETAILS && errors?.details)
    || !!(currentTab === EDIT_PROFILE_TABS.DOCUMENT && errors?.document)
    || !!(currentTab === EDIT_PROFILE_TABS.SECURITY && errors?.security)
    || !!(currentTab === EDIT_PROFILE_TABS.SETTINGS && errors?.settings)
    || !!(currentTab === EDIT_PROFILE_TABS.LOYALTY_PROGRAMS && errors?.loyaltyNumbers)
    || !!(currentTab === EDIT_PROFILE_TABS.FAVORITE_HOTELS && errors?.favoriteHotels)
    || (currentTab === EDIT_PROFILE_TABS.SECURITY && values.security.newPassword && !isValidNewPassword)
    || !!(currentTab === EDIT_PROFILE_TABS.CRYPTO_WALLET && errors?.cryptoWallets)
  );
};
  
export const getInitialValues = (currentUser) => {
  return {
    favoriteHotels: currentUser?.favoriteHotels?.length
      ? currentUser.favoriteHotels.map(favoriteHotel => ({
          regionId: favoriteHotel?.hotel?.region?.id,
          hotelId: favoriteHotel?.hotel?.id,
          userId: favoriteHotel?.user?.id,
          id: uuidv4(),
        }))
      : [],
    loyaltyNumbers: currentUser?.loyaltyNumbers?.length
      ? currentUser.loyaltyNumbers.map(loyaltyNumber => ({
          id: loyaltyNumber.id,
          number: loyaltyNumber.number,
          airline: loyaltyNumber.airline.id,
        }))
      : [],
    details: {
      email: currentUser?.user?.email ?? '',
      employer: currentUser?.user?.employerId ?? null,
      company: currentUser?.company?.name ?? '',
      companyId: currentUser?.company?.id ?? '',
      team: currentUser?.user?.teamId ?? null,
      role: formatStringToNormal(currentUser?.role?.name) ?? '',
      businessVertical: currentUser?.user?.businessVertical?.id ?? '',
    },
    settings: {
      firstname: currentUser?.user?.firstname ?? '',
      lastname: currentUser?.user?.lastname ?? '',
      email: currentUser?.passenger?.passengerEmail ?? '',
      phone: currentUser?.passenger?.passengerNumber ?? '',
      birthDate: moment.utc(currentUser?.passenger?.birthDate).format('YYYY-MM-DD') ?? '',
      gender: currentUser?.passenger?.gender ?? null,
      title: currentUser?.passenger?.titleId ?? null,
    },
    document: {
      passengerId: currentUser?.passenger?.id ?? null,
      country: currentUser?.document?.countryId ?? null,
      number: currentUser?.document?.number ?? '',
      expirationDate: moment.utc(currentUser?.document?.validTill).format('YYYY-MM-DD') ?? '',
    },
    security: {
      currentPassword: '',
      newPassword: '',
    },
    cryptoWallets: currentUser?.cryptoWallets?.length
      ? currentUser.cryptoWallets.map(cryptoWallet => ({
          id: cryptoWallet.id,
          address: cryptoWallet.address,
          cryptoCurrency: { value: cryptoWallet.cryptoCurrency.id, label: cryptoWallet.cryptoCurrency.title },
        }))
      : [],
  };
};
