import { useCallback, useState } from 'react';
import { IChangePagesPopover } from './types';
import { Wrapper, Input, Label, Content, Page, ButtonsWrapper, GroupedButtons } from './styles';
import { strToUpperCase } from '../../utils/commonFunctions';
import clone from 'lodash/clone';
import Button from '../Button';

const checkOnChecked = (allPages, currPage) => allPages.some((item) => item.id === currPage.id);

const ChangePagesPopover: React.FC<IChangePagesPopover> = ({
  usersPages,
  allPages,
  handleAgree,
  handleClose,
  withButtons = true,
  withMultiselect = true,
  customSelectItem,
}) => {
  const [activePages, setActivePages] = useState(clone(usersPages ?? allPages));

  const handleChangeCheckbox = useCallback(
    (page) => {
      if (customSelectItem) {
        customSelectItem(page);
      } else {
        if (checkOnChecked(activePages, page)) {
          setActivePages((prevState) => prevState.filter((item) => item.id !== page.id));
        } else {
          setActivePages((prevState) => [...prevState, page]);
        }
      }
    },
    [activePages]
  );

  return (
    <Wrapper>
      <Content>
        {allPages.map((page, i) => (
          <Page key={page.id} onClick={() => handleChangeCheckbox(page)}>
            <Label htmlFor={page.id}>{strToUpperCase(page.name)}</Label>

            {withMultiselect && (
              <Input type="checkbox" id={page.id} name={page.name} checked={checkOnChecked(activePages, page)} />
            )}
          </Page>
        ))}
      </Content>

      {withButtons && (
        <ButtonsWrapper>
          <Button handleClick={() => handleClose(false)} label="Cancel" />

          <GroupedButtons>
            <Button handleClick={() => setActivePages([])} variant="tertiary" label="Discard" />

            <Button handleClick={() => handleAgree(activePages)} variant="primary" label="Apply" />
          </GroupedButtons>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};

export default ChangePagesPopover;
