import styled from 'styled-components';

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 164px;
  width: 100%;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 164px;
  width: 100%;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}
`;

export const ReceiptsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 164px;
  width: 100%;
`;

export const ReceiptZoomIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
`;

export const ReceiptStyled = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  cursor: pointer;
  margin: 4px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${ReceiptZoomIcon} {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`;

export const AddNewPhotoIcon = styled.span`
  width: 48px;
  height: 48px;
  cursor: pointer;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: rgba(253, 239, 196, 0.38);
  cursor: pointer;

  &:hover {
    background: rgba(253, 239, 196, 0.78);
  }
`;
