import moment from 'moment';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import mean from 'lodash/mean';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';
import { getDate } from '../DashboardOverview/suggestions';

export const getAvgRate = (feedback) => {
  const avgRate = mean([feedback.accommodation, feedback.flight, feedback.webApp, feedback.app].filter(rate => rate));
  return +round(avgRate, 1).toFixed(1);
};

export const getItemText = (item, type) => {
  if ([NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES, NOTIFICATION_TYPES.VERIFYING_EXPENSES].includes(type)) {
    return `${item?.user?.fullName} — ${item?.destinationLabel} — ${item.expensesTotalCost} EUR`;
  }

  if (type === NOTIFICATION_TYPES.EXPIRED_DOCUMENTS) {
    return `${item?.fullName} — ${moment(item?.userDocument?.expiredDate).format('LL')}`;
  }

  if (type === NOTIFICATION_TYPES.SUBSCRIPTIONS) {
    return item?.title;
  }

  if (type === NOTIFICATION_TYPES.TRIPS_FOR_MERGING) {
    const trips = [item, ...item?.tripsForMerging];
    const [tripWithLastEndDate] = orderBy(trips, (trip) => new Date(trip.tripFinishDate), ['desc']);
    return `${item?.user?.fullName} — ${getDate(item?.tripStartDate)} → ${getDate(tripWithLastEndDate.tripFinishDate)}`;
  }

  if (type === NOTIFICATION_TYPES.NEW_FEEDBACKS) {
    return `${item?.traveler?.fullName} — ${item?.trip?.destinationLabel} — ${getAvgRate(item)} star(s)`;
  }

  return `${item?.user?.fullName} — ${item?.destinationLabel}`;
};

export const getSearchValue = (item, type) => {
  if (type === NOTIFICATION_TYPES.EXPIRED_DOCUMENTS) {
    return item.email;
  }

  if (type === NOTIFICATION_TYPES.SUBSCRIPTIONS) {
    return;
  }

  if (type === NOTIFICATION_TYPES.NEW_FEEDBACKS) {
    return item.trip.reference;
  }

  return item.reference;
};
