import { GenericPlus } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { ITripExternalAttachmentActions } from './types';
import { AddButtons } from '../styles';

const TripExternalAttachmentActions: React.FC<ITripExternalAttachmentActions> = ({
  handleOpenAttachOtherTransportModal,
  handleOpenAttachAccommodationModal,
  handleOpenAttachFlightModal,
}) => {
  return (
    <AddButtons>
      <Button
        variant="ghost"
        size="small"
        onClick={() => handleOpenAttachFlightModal()}
        iconLeft={<GenericPlus fontSize="1.2rem" />}
      >
        Add external flight
      </Button>
      <Button
        variant="ghost"
        size="small"
        onClick={() => handleOpenAttachAccommodationModal()}
        iconLeft={<GenericPlus fontSize="1.2rem" />}
      >
        Add external accommodation
      </Button>
      <Button
        variant="ghost"
        size="small"
        onClick={() => handleOpenAttachOtherTransportModal()}
        iconLeft={<GenericPlus fontSize="1.2rem" />}
      >
        Add other transport
      </Button>
    </AddButtons>
  );
};

export default TripExternalAttachmentActions;
