import {
  SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA,
  SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA,
  SET_BOOKING_FOR_IGNORE_CREATION_LOADING,
  SET_BOOKING_FOR_IGNORE_DELETION_LOADING,
  CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL,
  SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL,
  CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL,
  SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL,
  CLOSE_BOOKING_FOR_IGNORE_OVERVIEW,
  SHOW_BOOKING_FOR_IGNORE_OVERVIEW,
  SET_ACCOMMODATIONS_IGNORE_LIST,
} from './actionTypes';

interface ignoreListAccommodationsReducerSchema {
  isActiveAllAccommodationsForIgnoreCheckboxes: boolean,
  accommodationsForIgnoreCheckboxesData: any[],
  bookingForIgnoreDeleteModalOpen: boolean,
  bookingForIgnoreDeleteData: any,
  bookingForIgnoreAddingModalOpen: boolean,
  isOpenBookingForIgnoreOverview: boolean,
  bookingForIgnoreOverviewData: any,
  ignoreListAccommodationsTotalCount: number,
  ignoreListAccommodations: any[],
  isCreationLoading: boolean,
  isDeletionLoading: boolean,
};

const initState: ignoreListAccommodationsReducerSchema = {
  isActiveAllAccommodationsForIgnoreCheckboxes: false,
  accommodationsForIgnoreCheckboxesData: [],
  bookingForIgnoreDeleteModalOpen: false,
  bookingForIgnoreDeleteData: null,
  bookingForIgnoreAddingModalOpen: false,
  isOpenBookingForIgnoreOverview: false,
  bookingForIgnoreOverviewData: null,
  ignoreListAccommodationsTotalCount: 0,
  ignoreListAccommodations: [],
  isCreationLoading: false,
  isDeletionLoading: false,
};

const ignoreListAccommodationsReducer = (state = initState, action):ignoreListAccommodationsReducerSchema  => {
  switch (action.type) {
    case SET_BOOKING_FOR_IGNORE_CREATION_LOADING:
      return {
        ...state,
        isCreationLoading: action.payload.loading,
      };


    case SET_BOOKING_FOR_IGNORE_DELETION_LOADING:
      return {
        ...state,
        isDeletionLoading: action.payload.loading,
      };


    case SHOW_BOOKING_FOR_IGNORE_OVERVIEW:
      return {
        ...state,
        isOpenBookingForIgnoreOverview: true,
        bookingForIgnoreOverviewData: action.payload.data,
      };

    case CLOSE_BOOKING_FOR_IGNORE_OVERVIEW:
      return {
        ...state,
        isOpenBookingForIgnoreOverview: false,
        bookingForIgnoreOverviewData: null,
      };

    case SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL:
      return {
        ...state,
        bookingForIgnoreDeleteModalOpen: true,
        bookingForIgnoreDeleteData: Array.isArray(action.payload.data)
          ? action.payload.data.map(booking => booking.id)
          : [ action.payload.data.id ],
      };

    case CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL:
      return {
        ...state,
        bookingForIgnoreDeleteModalOpen: false,
        bookingForIgnoreDeleteData: null,
      };

    case SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL:
      return {
        ...state,
        bookingForIgnoreAddingModalOpen: true,
      };

    case CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL:
      return {
        ...state,
        bookingForIgnoreAddingModalOpen: false,
      };
    
    case SET_ACCOMMODATIONS_IGNORE_LIST:
      return {
        ...state,
        ignoreListAccommodationsTotalCount: action.payload.total,
        ignoreListAccommodations: action.payload.records,
      };

    case SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllAccommodationsForIgnoreCheckboxes: isActive,
        accommodationsForIgnoreCheckboxesData: data,
      };
    }

    case SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllAccommodationsForIgnoreCheckboxes: false,
          accommodationsForIgnoreCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          accommodationsForIgnoreCheckboxesData: [...state.accommodationsForIgnoreCheckboxesData, data],
        };
      }

      return {
        ...state,
        accommodationsForIgnoreCheckboxesData: state.accommodationsForIgnoreCheckboxesData.filter(
          (item) => item.id !== data.id
        ),
        isActiveAllAccommodationsForIgnoreCheckboxes: false,
      };
    }

    default:
      return state;
  }
};

export default ignoreListAccommodationsReducer;
