import {
  SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA,
  SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA,
  SET_BOOKING_FOR_IGNORE_CREATION_LOADING,
  SET_BOOKING_FOR_IGNORE_DELETION_LOADING,
  CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL,
  SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL,
  CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL,
  SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL,
  CLOSE_BOOKING_FOR_IGNORE_OVERVIEW,
  SHOW_BOOKING_FOR_IGNORE_OVERVIEW,
  SET_ACCOMMODATIONS_IGNORE_LIST,
} from './actionTypes';

export const setBookingForIgnoreCreationLoading = (loading: boolean) => {
  return { type: SET_BOOKING_FOR_IGNORE_CREATION_LOADING, payload: { loading } };
};

export const setBookingForIgnoreDeletionLoading = (loading: boolean) => {
  return { type: SET_BOOKING_FOR_IGNORE_DELETION_LOADING, payload: { loading } };
};

export const showBookingForIgnoreOverview = (data) => {
  return { type: SHOW_BOOKING_FOR_IGNORE_OVERVIEW, payload: { data } };
};

export const closeBookingForIgnoreOverview = () => {
  return { type: CLOSE_BOOKING_FOR_IGNORE_OVERVIEW };
};

export const setAccommodationsIgnoreList = (records, total) => {
  return { type: SET_ACCOMMODATIONS_IGNORE_LIST, payload: { records, total } };
};

export const showBookingForIgnoreDeleteModal = (data) => {
  return { type: SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL, payload: { data } };
};

export const closeBookingForIgnoreDeleteModal = () => {
  return { type: CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL };
};

export const showBookingForIgnoreCreationModal = (data) => {
  return { type: SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL, payload: { data } };
};

export const closeBookingForIgnoreCreationModal = () => {
  return { type: CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL };
};

export const setBookingForIgnoreCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllBookingForIgnoreCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};
