import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextInput } from '@heathmont/moon-core';
import FormField from '../../../../../components/FormField';
import { PasswordValidator } from '../../../../../components/PasswordValidator';
import { setIsChangedTabField, setIsValidPassword } from '../../../../../store/editProfile/actions';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { ISecurity } from './types';
import { FieldWrapper, SecurityStyled } from './styled';
import { ErrorMessage, ErrorMessageWrapper } from '../../styles';
import { TogglePasswordText } from '../../../../../components/TogglePasswordText';

const Security: React.FC<ISecurity> = ({ values, setFieldValue, errors, initialValues, touched }) => {
  const { currentPassword, newPassword } = values.security;

  const isErrorCurrentPassword = errors?.security?.currentPassword && touched?.currentPassword;
  const isErrorNewPassword = errors?.security?.newPassword && touched?.newPassword;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.security, initialValues.security)) {
      dispatch(setIsChangedTabField('security', true));
    } else {
      dispatch(setIsChangedTabField('security', false));
    }
  }, [values.security, initialValues.security]);

  return (
    <SecurityStyled>
      <FieldWrapper>
        <FormField
          fieldId="currentPassword"
          fieldName="currentPassword"
          value={currentPassword}
          component={TextInput}
          type="password"
          inputSize="xlarge"
          onChange={({ target: { value } }) => setFieldValue('security.currentPassword', value)}
          isError={isErrorCurrentPassword}
          label="Current password"
          showPasswordText={<TogglePasswordText />}
        />

        {isErrorCurrentPassword && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.security?.currentPassword}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <FormField
          fieldId="newPassword"
          fieldName="newPassword"
          value={newPassword}
          component={TextInput}
          type="password"
          inputSize="xlarge"
          onChange={({ target: { value } }) => setFieldValue('security.newPassword', value)}
          isError={isErrorNewPassword}
          label="New password"
          showPasswordText={<TogglePasswordText />}
        />

        {isErrorNewPassword && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.security?.newPassword}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </FieldWrapper>

      <PasswordValidator password={newPassword} setPasswordValid={(isValid) => dispatch(setIsValidPassword(isValid))} />
    </SecurityStyled>
  );
};

export default Security;
