import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { GenericCheckAlternative } from '@heathmont/moon-icons';
import { Select } from '@heathmont/moon-select';
import { ErrorMessage } from '../FormField/styled';
import { ICustomSelect } from './types';
import { DefaultLabel, Option } from './styles';
import './styles.scss';

const startSearchingMessage = (minLength = 3) => `Type minimum of ${minLength} characters to see options`;

const CustomSelect: React.FC<ICustomSelect> = ({
  minLengthToStartSearching,
  noOptionsMessage = 'No options',
  currentValue,
  isSearchable = false,
  isClearable = false,
  placeholder,
  isDisabled = false,
  isLoading,
  position = 'right',
  isError = false,
  isMulti = false,
  items,
  label,
  error,
  size,
  onInputChange,
  getItemLabel = (value, label) => <DefaultLabel>{label || value}</DefaultLabel>,
  onChange,
  onBlur = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = items.map(item => ({ value: item.value, label: item.title }));
  
  const initialValue = useMemo(() => {
    if (isMulti && !isSearchable && Array.isArray(currentValue)) {
      return options.filter(option => currentValue.includes(option.value as never))
    }

    if (isMulti && isSearchable && Array.isArray(currentValue)) {
      return currentValue
        .map(value => {
          return options.find(option => option.value === value)
            || selectedOptions.find(option => option.value === value)
        })
        .filter(value => value);
    }

    return options.find(option => option.value === currentValue) || null;
  }, [currentValue, options, selectedOptions]);

  const placeholderSlot = useMemo(() => {
    return currentValue && !isMulti && !Array.isArray(currentValue) 
      ? getItemLabel(currentValue)
      : placeholder
  }, [currentValue]);

  const formatOptionLabel = (option: any, index) => (
    <Option key={index}>
      {getItemLabel(option.value, t(option.label))}
      {!isMulti && currentValue === option.value && <GenericCheckAlternative color="#275ADB" fontSize="1.5rem" />}
    </Option>
  );

  const handleOnChange = (newValue) => {
    if (isMulti) {
      const updatedSelectedOptions = [ ...(Array.isArray(newValue) ? newValue : [ newValue ]), ...selectedOptions ];
      setSelectedOptions(uniqBy(updatedSelectedOptions, 'value'));
      onChange(newValue.map(newValue => newValue.value));
    } else {
      onChange(newValue ? newValue.value : null);
    }
  };

  return (
    <div>
      <Select
        blurInputOnSelect
        menuPortalTarget={document.body} 
        noOptionsMessage={({ inputValue }) => {
          if (minLengthToStartSearching && inputValue.length <= minLengthToStartSearching) {
            return startSearchingMessage(minLengthToStartSearching);
          }

          return noOptionsMessage;
        }}
        placeholderSlot={placeholderSlot}
        classNamePrefix={`custom-select--${position}`}
        isSearchable={isSearchable}
        menuPosition={'fixed'} 
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuWidth={280}
        isLoading={isLoading}
        options={options}
        isError={isError}
        isMulti={isMulti}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        value={initialValue}
        label={label}
        size={size}
        formatOptionLabel={formatOptionLabel}
        onInputChange={onInputChange ? (inputText) => {
          onInputChange(inputText);
        } : null}
        onChange={handleOnChange}
        onBlur={onBlur}
      />
      {error && (
        <div style={{ position: 'relative', marginLeft: '5px', marginTop: '5px' }}>
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
