import { debounce, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { IAddNewRecommendedHotel } from './types';
import { Text, TextInput, Button, Checkbox } from '@heathmont/moon-core';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import { Background, ButtonsWrapper, CloseIcon, Content, DialogWrapper, Footer, FormItem, FormItemsWrapper, PriceLabel, Title, Wrapper } from './styles';
import { recommendedHotelSchema } from './schema';
import DatePicker from '../../components/DatePicker';
import { fetchCities, fetchHotels, fetchTeamsByCompany } from '../../pages/Tools/RecommendedHotels/utils';

import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';
import { PurposeTypes } from '../../enums/PurposeTypes';

const initialValues = {
  applyToAllPurposes: false,
  applyToAllTeams: false,
  applyToAllDates: false,
  cityId: null,
  hotelId: null,
  startDate: null,
  endDate: null,
  companyId: null,
  teamsIds: [],
  purposesIds: [],
  comment: null
};

const AddNewRecommendedHotel: React.FC<IAddNewRecommendedHotel> = ({
  companies,
  purposes,
  handleCloseDialog,
  handleAgree,
  getTripPurposeLabel,
}) => {
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [hotelsOptions, setHotelsOptions] = useState([]);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [isHotelsLoading, setIsHotelsLoading] = useState(false);
  const [teamsOptions, setTeamsOptions] = useState([]);

  return (
    <>
      <Background onClick={() => handleCloseDialog()} />
      <DialogWrapper>
        <Wrapper>
          <Formik
            validateOnBlur={false}
            validationSchema={recommendedHotelSchema}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }): void => {
              handleAgree(values);
              resetForm();
            }}
          >
            {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => (
              <Form>
                <Title>
                  <Text size={18}>Add recommended hotel</Text>

                  <CloseIcon onClick={() => handleCloseDialog()}>
                    <CloseSvg />
                  </CloseIcon>
                </Title>
                
                <Content>          
                  <FormItemsWrapper>
                    <FormItem>
                      <CustomSelect
                        minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                        currentValue={values.cityId}
                        isSearchable
                        placeholder='City'
                        isLoading={isCitiesLoading}
                        isError={touched?.cityId && errors?.cityId}
                        error={touched?.cityId && errors?.cityId}
                        items={citiesOptions}
                        onInputChange={debounce((search) => 
                            fetchCities({ search, setFieldValue, setCitiesOptions, setHotelsOptions, setIsCitiesLoading }), 
                          DEFAULT_DEBOUNCE_MS_COUNT)}
                        onChange={(value) => {
                          setFieldValue('cityId', value);
                          setFieldValue('hotelId', null);
                        }}
                      />
                    </FormItem>
                    <FormItem>
                      <CustomSelect
                        minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                        isSearchable
                        currentValue={values.hotelId}
                        placeholder='Hotel'
                        isDisabled={!values.cityId}
                        isLoading={isHotelsLoading}
                        isError={touched?.hotelId  && errors?.hotelId}
                        error={(touched?.hotelId && errors?.hotelId)}
                        items={hotelsOptions}
                        onInputChange={debounce((search) => 
                            fetchHotels({ search, regionId: values.cityId, setFieldValue, setHotelsOptions, setIsHotelsLoading }), 
                          DEFAULT_DEBOUNCE_MS_COUNT)}
                        onChange={(value) => {
                          setFieldTouched('hotelId', true, false);
                          setFieldValue('hotelId', value);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem>
                      <DatePicker
                        placeholder='Start date'
                        isEditView={true}
                        disabled={values?.applyToAllDates}
                        errors={touched?.startDate && errors?.startDate}
                        value={values.startDate}
                        field="startDate"
                        onChange={(field, value) => {
                          setFieldTouched(field, true);
                          setFieldValue(field, value);
                        }}
                      />
                    </FormItem>
                    <FormItem>
                      <DatePicker
                        placeholder='End date'
                        isEditView={true}
                        disabled={values?.applyToAllDates}
                        minDate={values.endDate}
                        errors={touched?.endDate && errors?.endDate}
                        value={values.endDate}
                        field="endDate"
                        onChange={(field, value) => {
                          setFieldTouched(field, true);
                          setFieldValue(field, value);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem>
                      <Checkbox
                        checked={values?.applyToAllDates}
                        label={'Apply to all dates'}
                        onChange={() => {
                          setFieldTouched('applyToAllDates', true);
                          setFieldValue('startDate', null);
                          setFieldValue('endDate', null);
                          setFieldValue('applyToAllDates', !values?.applyToAllDates);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem fullWidth>
                      <CustomSelect
                        isMulti
                        currentValue={values.purposesIds}
                        isDisabled={values?.applyToAllPurposes}
                        isSearchable
                        placeholder='Purposes'
                        isError={touched?.purposesIds && errors?.purposesIds}
                        error={(touched?.purposesIds && errors?.purposesIds)}
                        getItemLabel={getTripPurposeLabel}
                        items={purposes.filter(purpose => purpose.type === PurposeTypes.INTERNAL)}
                        onChange={(value) => {
                          setFieldValue('purposesIds', value);
                          setFieldTouched('purposesIds', true);
                        }}
                      />
                    </FormItem>                      
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem>
                      <Checkbox
                        checked={values?.applyToAllPurposes}
                        label={'Apply to all purposes'}
                        onChange={() => {
                          setFieldTouched('applyToAllPurposes', true);
                          setFieldValue('purposesIds', []);
                          setFieldValue('applyToAllPurposes', !values?.applyToAllPurposes);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem fullWidth>
                      <CustomSelect
                        currentValue={values.companyId}
                        isSearchable
                        placeholder='Company'
                        isError={touched?.companyId && errors?.companyId}
                        error={(touched?.companyId && errors?.companyId)}
                        items={companies}
                        onChange={(value) => {
                          fetchTeamsByCompany({ companyId: values.companyId, newCompanyId: value, setTeamsOptions });
                          setFieldValue('companyId', value);
                          setFieldValue('teamsIds', []);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem fullWidth>
                      <CustomSelect
                        currentValue={values.teamsIds}
                        isSearchable
                        placeholder='Teams'
                        isDisabled={!values.companyId || values?.applyToAllTeams}
                        isError={touched?.teamsIds && errors?.teamsIds}
                        error={(touched?.teamsIds && errors?.teamsIds)}
                        isMulti
                        items={teamsOptions}
                        onChange={(value) => {
                          setFieldValue('teamsIds', value);
                          setFieldTouched('teamsIds', true);
                        }}
                      />
                    </FormItem>                   
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem>
                      <Checkbox
                        checked={values?.applyToAllTeams}
                        label={'Apply to all teams'}
                        onChange={() => {
                          setFieldTouched('applyToAllTeams', true);
                          setFieldValue('teamsIds', []);
                          setFieldValue('applyToAllTeams', !values?.applyToAllTeams);
                        }}
                      />
                    </FormItem>
                  </FormItemsWrapper>
                  <FormItemsWrapper>
                    <FormItem fullWidth>
                      <FormField
                        value={values.comment}
                        fieldId="comment"
                        fieldName="comment"
                        component={TextInput}
                        type="text"
                        inputSize="xlarge"
                        onChange={(e) => setFieldValue('comment', e.target.value)}
                        onBlur={handleBlur}
                        errors={touched?.comment && errors}
                        errorPosition={-20}
                        label="Comment"
                      />
                    </FormItem>                      
                  </FormItemsWrapper>
                </Content>
                <Footer>
                  <ButtonsWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        resetForm();
                        handleCloseDialog();
                      }}
                    >Cancel</Button>
                    {handleAgree && (
                      <Button
                        type="submit"
                        disabled={!isEmpty(errors)}
                      >Add</Button>
                    )}
                  </ButtonsWrapper>
                </Footer>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </DialogWrapper>
    </>
  );
};

export default AddNewRecommendedHotel;
