import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const Wrapper = styled.div`
  & div {
    ${({ avatarStyle }: any) =>
      avatarStyle?.border &&
      css`
        border: ${avatarStyle.border};
      `}
    ${({ avatarStyle }: any) =>
      avatarStyle?.borderRadius &&
      css`
        border-radius: ${avatarStyle.borderRadius};
      `}
      ${({ avatarStyle }: any) =>
      avatarStyle?.borderRadius &&
      css`
        border-radius: ${avatarStyle.borderRadius};
      `}
      font-size: ${({ size }: any): any => (size === 'xsmall' ? rem(14) : rem(16))};

    font-weight: 600;
    letter-spacing: 0px;

    &:hover {
      cursor: pointer;
    }
  }
` as any;
