export const CLOSE_ALL_NOTIFICATION_ITEMS = 'dashboard/CLOSE_ALL_NOTIFICATION_ITEMS';
export const SHOW_ALL_NOTIFICATION_ITEMS = 'dashboard/SHOW_ALL_NOTIFICATION_ITEMS';
export const CLOSE_UPCOMING_TRIPS = 'dashboard/CLOSE_UPCOMING_TRIPS';
export const SHOW_UPCOMING_TRIPS = 'dashboard/SHOW_UPCOMING_TRIPS';
export const SET_UPCOMING_TRIPS = 'dashboard/SET_UPCOMING_TRIPS';
export const SET_GRAPHICS_DATA = 'dashboard/SET_GRAPHICS_DATA';
export const SET_NOTIFICATIONS = 'dashboard/SET_NOTIFICATIONS';
export const TOGGLE_FILTERS = 'dashboard/TOGGLE_FILTERS';
export const CLOSE_FILTERS = 'dashboard/CLOSE_FILTERS';
export const OPEN_FILTERS = 'dashboard/OPEN_FILTERS';
export const SET_OPTIONS = 'dashboard/SET_OPTIONS';
export const SET_NUMBERS = 'dashboard/SET_NUMBERS';
