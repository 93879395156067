export const SET_IS_OPEN_ADD_NEW_TEAM_MODAL = 'tools/SET_IS_OPEN_ADD_NEW_TEAM_MODAL';
export const SET_UPDATE_TOOLS_TEAMS_STATUS = 'tools/SET_UPDATE_TOOLS_TEAMS_STATUS';
export const SET_TEAMS_ALL_CHECKBOX_DATA = 'tools/SET_TEAMS_ALL_CHECKBOX_DATA';
export const SET_TOOLS_TEAMS_COMPANIES = 'tools/SET_TOOLS_TEAMS_COMPANIES';
export const SET_IS_UPDATED_TEAM_DATA = 'tools/SET_IS_UPDATED_TEAM_DATA';
export const CLOSE_DELETE_TEAMS_MODAL = 'tools/CLOSE_DELETE_TEAMS_MODAL';
export const SHOW_DELETE_TEAMS_MODAL = 'tools/SHOW_DELETE_TEAMS_MODAL';
export const SET_TEAMS_CHECKBOX_DATA = 'tools/SET_TEAMS_CHECKBOX_DATA';
export const SET_TOOLS_TEAMS_OPTIONS = 'tools/SET_TOOLS_TEAMS_OPTIONS';
export const SET_TOOLS_TEAMS_DATA = 'tools/SET_TOOLS_TEAMS_DATA';
export const CLOSE_TEAM_OVERVIEW = 'tools/CLOSE_TEAM_OVERVIEW';
export const SHOW_TEAM_OVERVIEW = 'tools/SHOW_TEAM_OVERVIEW';
