import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 100%;
  border-left: 1px solid #e0e0e0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 24px;
`;

export const Status = styled.span`
  position: relative;

  & span {
    position: absolute;
    top: -5px;
    left: 5px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  width: 100%;
  padding: 24px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ isTicket }) => (!isTicket ? '23px' : '55px')};
`;

export const TitleWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Title = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  display: block;
  font-size: 20px;
  line-height: 28px;
  word-break: break-word;
  position: relative;
  text-overflow: ellipsis;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
`;
