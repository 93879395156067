import { PAYMENT_PROVIDER } from '../../enums/PaymentProviders';
import { IPaymentProviderIcon } from './types';
import { RevolutLogo, SwapinLogo } from './styles';

const PaymentProviderIcon: React.FC<IPaymentProviderIcon> = ({
  provider,
}) => {
  if (provider === PAYMENT_PROVIDER.REVOLUT) {
    return <RevolutLogo />
  }

  if (provider === PAYMENT_PROVIDER.SWAPIN) {
    return <SwapinLogo />
  }

  return null;
};

export default PaymentProviderIcon;
