import styled from 'styled-components';

export const CompaniesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 32px;
  margin: 0;
`;

export const CompanyCardEditButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: none;
  
  button {
    background: #FCE9B0;
    color: #181B1C;
  }

  &:hover {
    button {
      background: linear-gradient(0deg, rgba(24, 27, 28, 0.12), rgba(24, 27, 28, 0.12)), #FCE9B0;
    }
  }
`; 

export const CompanyCard = styled.div`
  position: relative;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 32px;
  width: calc(100% / 2 - 10px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: ${props => props.active ? "rgba(253, 239, 196, 0.38)" : "inherit"};

  &:hover {
    background-color: rgba(253, 239, 196, 0.38);
  }

  &:hover ${CompanyCardEditButton} {
    display: block;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CompanyCardTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
`;

export const CompanyCardLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #878788;
`;

export const CompanyCardBlock = styled.div`
  display: flex;
  gap: 15px;
`;

export const ItemSeparator = styled.span`
  font-size: 14px;
  color: #878788;
`;

