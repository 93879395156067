import styled from 'styled-components';

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 40px;
  margin-top: 5px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff0000;
  padding: 5px 5px 0 0;
`;
