import filter from 'lodash/filter';
import clone from 'lodash/clone';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import {
  REMOVE_PASSENGER_FROM_SELECTED,
  ROUTE_SEGMENT_SET_DEPARTURE,
  SET_UPDATE_OFFER_REQUESTED,
  CLEAR_PASSENGERS_AVAILABLE,
  ROUTE_SEGMENT_SET_ARRIVAL,
  SET_PASSENGERS_AVAILABLE,
  SET_PASSENGERS_SELECTED,
  ROUTE_SEGMENT_SET_DATE,
  ROUTE_SEGMENT_SET_SYNC,
  SET_PASSENGERS_LOADED,
  ROUTE_SEGMENT_REMOVE,
  SET_OFFERS_REQUESTED,
  SET_ROUTE_SHORT_VIEW,
  SET_OFFERS_LOADING,
  SET_OFFER_SELECTED,
  ROUTE_SEGMENT_ADD,
  SET_OFFER_PLACING,
  OFFERS_DATA,
  SET_COMPANY,
  SET_USER,
} from './actionTypes';

const initState = {
  companyId: null,
  companyName: null,
  userId: null,
  userName: null,
  isPassengersLoaded: false,
  passengers: [],
  passengersAvailable: [],
  segments: [
    {
      departureDate: null,
      departurePoint: null,
      arrivalPoint: null,
      syncWithPrevSegment: false,
    },
    {
      departureDate: null,
      departurePoint: null,
      arrivalPoint: null,
      syncWithPrevSegment: true,
    },
  ],
  isRouteShortViewEnabled: false,
  isOffersRequested: false,
  isOffersLoading: false,
  offers: [],
  selectedOffer: null,
  updateOffersRequested: false,
  isOfferPlacing: false,
};

const builderReducer = (state = initState, action) => {
  let clonedSegments = {};
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        companyId: action.data.companyId,
        companyName: action.data.companyName,
      };
    case SET_USER:
      return {
        ...state,
        userId: action.data.userId,
        userName: action.data.userName,
      };
    case SET_PASSENGERS_LOADED:
      return {
        ...state,
        isPassengersLoaded: action.data,
      };
    case SET_PASSENGERS_AVAILABLE:
      return {
        ...state,
        passengersAvailable: merge({}, state.passengersAvailable, action.data),
      };
    case CLEAR_PASSENGERS_AVAILABLE:
      return {
        ...state,
        passengersAvailable: {},
      };
    case SET_PASSENGERS_SELECTED:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.data.id]: action.data,
        },
      };
    case REMOVE_PASSENGER_FROM_SELECTED:
      return {
        ...state,
        passengers: omit(state.passengers, action.data),
      };
    case ROUTE_SEGMENT_ADD:
      clonedSegments = clone(state.segments);
      // @ts-ignore
      clonedSegments.push(action.data);
      return {
        ...state,
        segments: clonedSegments,
      };
    case ROUTE_SEGMENT_REMOVE:
      clonedSegments = clone(state.segments);
      delete clonedSegments[action.data];
      return {
        ...state,
        segments: filter(clonedSegments),
      };
    case ROUTE_SEGMENT_SET_DATE:
      clonedSegments = clone(state.segments);
      clonedSegments[action.data.segmentId].departureDate = action.data.segmentDate;
      return {
        ...state,
        segments: clonedSegments,
      };
    case ROUTE_SEGMENT_SET_ARRIVAL:
      clonedSegments = clone(state.segments);
      clonedSegments[action.data.segmentId].arrivalPoint = action.data.pointData;
      return {
        ...state,
        segments: clonedSegments,
      };
    case ROUTE_SEGMENT_SET_DEPARTURE:
      clonedSegments = clone(state.segments);
      clonedSegments[action.data.segmentId].departurePoint = action.data.pointData;
      return {
        ...state,
        segments: clonedSegments,
      };
    case ROUTE_SEGMENT_SET_SYNC:
      clonedSegments = clone(state.segments);
      clonedSegments[action.data.segmentId].syncWithPrevSegment = action.data.isSyncable;
      return {
        ...state,
        segments: clonedSegments,
      };
    case SET_OFFERS_LOADING:
      return {
        ...state,
        isOffersLoading: action.data,
      };
    case OFFERS_DATA:
      return {
        ...state,
        offers: action.data,
      };
    case SET_OFFERS_REQUESTED:
      return {
        ...state,
        isOffersRequested: action.data,
      };
    case SET_ROUTE_SHORT_VIEW:
      return {
        ...state,
        isRouteShortViewEnabled: action.data,
      };
    case SET_OFFER_SELECTED:
      return {
        ...state,
        selectedOffer: action.data,
      };
    case SET_UPDATE_OFFER_REQUESTED:
      return {
        ...state,
        updateOffersRequested: action.data,
      };
    case SET_OFFER_PLACING:
      return {
        ...state,
        isOfferPlacing: action.data,
      };
    default:
      return state;
  }
};

export default builderReducer;
