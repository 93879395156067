import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ControlsCloseSmall } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { HeathmontLogo } from '../../../components/HeathmontLogo';
import { FormTabType, tabNames } from '../../../enums/FormTabType';
import useIsMobile from '../../../hooks/useIsMobile';
import { setSignUpError } from '../../../store/signUp/actions';
import { isDisableSubmit, STEPPER_STYLES, TOTAL_STEPS } from '../suggestions';
import MobileSignUpStepper from '../../../components/MobileSignUpStepper';
import { ActiveTab } from '../ActiveTab';
import { signUpSchema } from '../schema';
import {
  NotificationWrapper,
  NotificationText,
  ButtonWrapper,
  Notification,
  RestStepText,
  Wrapper,
  Header,
  Footer,
} from './styles';

const MobileSignUp = ({
  initialValues,
  options,
  handleSubmit,
  handleBack,
  handleNext,
}) => {
  const activeTab = useSelector((state: any) => state.signUpReducer.activeTab);
  const submitError = useSelector((state: any) => state.signUpReducer.submitError);

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const isValidNewPassword = useSelector((state: any) => state.signUpReducer.isValidNewPassword);

  const restStepCountText = useMemo(() => {
    const restSteps = TOTAL_STEPS - activeTab;

    if (!restSteps) {
      return '';
    }

    return `${restSteps > 1 ? `${restSteps} steps` : `${restSteps} step`} to submit`;
  }, [activeTab]);

  return (
    <Wrapper isMobile={isMobile}>
      <div>
        <HeathmontLogo style={{ marginBottom: '27px', width: '32px', height: '32px' }} />
      </div>

      <Header>{tabNames[activeTab]}</Header>

      {submitError.isError && (
        <NotificationWrapper>
          <Notification isUpdateError={submitError.isError}>
            <NotificationText>{submitError.message}</NotificationText>
            <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setSignUpError(false))} />
          </Notification>
        </NotificationWrapper>
      )}

      <MobileSignUpStepper activeStep={activeTab} steps={TOTAL_STEPS} additionalStyles={STEPPER_STYLES} />

      <Formik
        enableReinitialize
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={signUpSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps: any): JSX.Element => {
          const { errors } = formProps;

          return (
            <Form>
              <div>
                <ActiveTab activeTab={activeTab} options={options} {...formProps} />
              </div>

              <Footer>
                {activeTab > 1 && (
                  <Button
                    variant="secondary"
                    style={{ marginRight: 10 }}
                    type="button"
                    onClick={handleBack}
                  >Back</Button>
                )}

                <RestStepText>{restStepCountText}</RestStepText>

                {activeTab === FormTabType.ChangePassword && (
                  <ButtonWrapper>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isDisableSubmit(errors, isValidNewPassword, activeTab)}
                    >Sign up</Button>
                  </ButtonWrapper>
                )}

                {activeTab !== FormTabType.ChangePassword && (
                  <ButtonWrapper>
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      type="button"
                      disabled={isDisableSubmit(errors, isValidNewPassword, activeTab)}
                    >Next</Button>
                  </ButtonWrapper>
                )}
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default MobileSignUp;
