import { GenericPlus, GenericDelete, GenericEdit, GenericBet } from '@heathmont/moon-icons';
import Tooltip from '../Tooltip';
import { IRowActions } from './types';
import { ActionGhostContent, ActionTooltipWrapper, ActionWrapper, Icon, RowActionsWrapper } from './styles';

const RowActions: React.FC<IRowActions> = ({
  deleteLabel,
  addNewLabel,
  editLabel,
  row,
  handleIsOverviewOpen,
  handleRemoveDialog,
  handleIsTicketOpen,
  handleAddNew,
}) => {
  return (
    <RowActionsWrapper>
      {handleIsOverviewOpen && (
        <>
          {row?.original?.isActionsAndNotifications ? (
            <ActionWrapper>
              <ActionGhostContent style={{ color: 'transparent', width: 0 }}>
                {row.values.description}
              </ActionGhostContent>
              <ActionTooltipWrapper>
                <Tooltip label={editLabel || 'Overview'}>
                  <Icon onClick={() => handleIsOverviewOpen(row)}>
                    <GenericEdit />
                  </Icon>
                </Tooltip>
              </ActionTooltipWrapper>
            </ActionWrapper>
          ) : (
            <Tooltip label={editLabel || 'Overview'}>
              <Icon onClick={() => handleIsOverviewOpen(row)}>
                <GenericEdit />
              </Icon>
            </Tooltip>
          )}
        </>
      )}

      {handleIsTicketOpen && (
        <Tooltip label={row?.original?.ticketName ?? 'Ticket'}>
          <Icon onClick={() => handleIsTicketOpen(row)}>
            <GenericBet />
          </Icon>
        </Tooltip>
      )}

      {handleAddNew && (
        <Tooltip label={addNewLabel || 'Add new'}>
          <Icon onClick={() => handleAddNew(row.original)}>
            <GenericPlus />
          </Icon>
        </Tooltip>
      )}

      {handleRemoveDialog && (
        <Tooltip label={deleteLabel || 'Delete'}>
          <Icon onClick={() => handleRemoveDialog(row.original)}>
            <GenericDelete />
          </Icon>
        </Tooltip>
      )}
    </RowActionsWrapper>
  );
};

export default RowActions;
