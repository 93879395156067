import { formatStringToNormal } from '../../utils/commonFunctions';
import { IDashboardNumbers } from './types';
import {
  NumberCount,
  NumberCountWrapper,
  NumberName,
  NumberWrapper,
  Wrapper,
  NumberChange,
  Divider,
  TravelerName,
} from './styles';

const getPeriodName = (text, periodType) => {
  switch (periodType) {
    case 'past_month':
    case 'current_month':
      return `${text} month`;
    case 'current_year':
    case 'past_year':
      return `${text} year`;
    case 'ALL':
      return `${text} all period`;
    case 'custom':
      return `${text} period`;
    default:
      return '';
  }
};

const DashboardNumbers: React.FC<IDashboardNumbers> = ({ data, periodType }) => {
  return (
    <Wrapper>
      <NumberWrapper>
        <NumberName>Reservation number</NumberName>

        <NumberCountWrapper>
          <NumberCount>{data?.totalReservations?.count}</NumberCount>
          <NumberChange isPositive={data?.totalReservations?.change > 0}>
            {data?.totalReservations?.change && `${data?.totalReservations?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      <Divider />

      <NumberWrapper>
        <NumberName>Successful trips</NumberName>

        <NumberCountWrapper>
          <NumberCount>{data?.successfulTrips?.count}</NumberCount>
          <NumberChange isPositive={data?.successfulTrips?.change > 0}>
            {data?.successfulTrips?.change && `${data?.successfulTrips?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      <Divider />

      <NumberWrapper>
        <NumberName>Cancelled trips</NumberName>

        <NumberCountWrapper>
          <NumberCount>{data?.canceledTrips?.count}</NumberCount>
          <NumberChange isPositive={data?.canceledTrips?.change > 0}>
            {data?.canceledTrips?.change && `${data?.canceledTrips?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      <Divider />

      <NumberWrapper>
        <NumberName>Total spent</NumberName>

        <NumberCountWrapper>
          <NumberCount>{data?.totalSpent?.sum.toFixed(2)}</NumberCount>
          <NumberChange isPositive={data?.totalSpent?.change > 0}>
            {data?.totalSpent?.change && `${data?.totalSpent?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      <Divider />
      
      <NumberWrapper>
        <NumberName>Number of feedbacks</NumberName>

        <NumberCountWrapper>
          <NumberCount>{data?.numberOfFeedbacks?.count}</NumberCount>
          <NumberChange isPositive={data?.numberOfFeedbacks?.change > 0}>
            {data?.numberOfFeedbacks?.change && `${data?.numberOfFeedbacks?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      <Divider />

      <NumberWrapper>
        <NumberName>Average feedback</NumberName>

        <NumberCountWrapper>
          <NumberCount>{(data?.avgFeedback?.rate || 0).toFixed(2)}</NumberCount>
          <NumberChange isPositive={data?.avgFeedback?.change > 0}>
            {data?.avgFeedback?.change && `${data?.avgFeedback?.change}%`}
          </NumberChange>
        </NumberCountWrapper>
      </NumberWrapper>

      {
        data?.destinationOfPeriod && (
          <>
            <Divider />
            <NumberWrapper>
              <NumberName>{getPeriodName('Destination of the', periodType)}</NumberName>
              <NumberCountWrapper>
                <NumberCount>{formatStringToNormal(data?.destinationOfPeriod)}</NumberCount>
              </NumberCountWrapper>
            </NumberWrapper>
          </>
        )
      }

      {
        data?.travelerOfPeriod?.fullName && (
          <>
            <Divider />
            <NumberWrapper>
              <NumberName>{getPeriodName('Traveler of the', periodType)}</NumberName>
              <NumberCountWrapper>
                <TravelerName>{data?.travelerOfPeriod?.fullName ?? ''}</TravelerName>
              </NumberCountWrapper>
            </NumberWrapper>
          </>
        )
      }

      {
        data?.teamOfPeriod?.name && (
          <>
            <Divider />
            <NumberWrapper>
              <NumberName>{getPeriodName('Team of the', periodType)}</NumberName>
              <NumberCountWrapper>
                <NumberCount>{data?.teamOfPeriod?.name ?? ''}</NumberCount>
              </NumberCountWrapper>
            </NumberWrapper>
          </>
        )
      }
    </Wrapper>
  );
};

export default DashboardNumbers;
