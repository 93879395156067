import { useEffect, useMemo, useState } from 'react';
import { GenericBetslip, SoftwareSorting } from '@heathmont/moon-icons';
import { Text } from '@heathmont/moon-core';
import { Helmet } from 'react-helmet';
import { FiltersReciever } from '../../components/FiltersReciever';
import { initDashboardFilters, useFilters } from '../../hooks/useFilters';
import { useQuery } from '../../hooks/useQuery';
import { usePageTitle } from '../../models/page';
import {
  lineChartOptions,
  getDashboardFilters,
  getLineChartData,
  barChartOptions,
  getBarChartData,
} from './suggestions';
import DashboardNumbers from '../../components/DashboardNumbers';
import Drawer from '../../components/Drawer';
import Notifications from '../../components/Notifications';
import DashboardOverview from '../../components/DashboardOverview';
import BarChart from '../../components/Charts/BarChart';
import LineChart from '../../components/Charts/LineChart';
import { prepareFilters } from '../../utils/filters';
import { formatCustomLabel } from '../../utils/tableHelpers';
import { FilterHeaderWrapper } from '../../styled';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import { IDashboard } from './types';
import { Wrapper, DashboardContent, PageHeader, IconWrapper, IconsWrapper, ChartsWrapper } from './styles';
import MergeTripsDialog from '../../components/MergeTripsDialog';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';


const Dashboard: React.FC<IDashboard> = ({
  isOpenNotificationData,
  isOpenNotification,
  isOpenUpcoming,
  upcomingTrips,
  isFiltersOpen,
  notifications,
  chartsData,
  options,
  numbers,
  history,
  closeNotificationAllItems,
  showNotificationAllItems,
  closeUpcomingTrips,
  fetchUpcomingTrips,
  fetchNotifications,
  fetchGraphicsData,
  showUpcomingTrips,
  toggleFilters,
  fetchOptions,
  fetchNumbers,
  closeFilters,
}) => {
  const pageTitle = usePageTitle('html.dashboard.title');
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [openDatePickerField, setOpenDatePickerField] = useState(null);
  const { getQuery, setQuery } = useQuery();
  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(getQuery(), initDashboardFilters);
  const periodType = useMemo(() => filters.period, [filters]);
  const periodRange = useMemo(
    () => formatCustomLabel(filters.period, filters.periodRange.startDate, filters.periodRange.endDate),
    [filters]
  );
  const [isMergeTripsModalOpen, setIsMergeTripsModalOpen] = useState(false);
  const [mergeTripIds, setMergeTripIds] = useState([]);
  const [isMergeTripsLoading, setIsMergeTripsLoading] = useState(false);

  const setFilters = (field: string, value: any) => {
    setFilter(field, value);
  };

  const toggleFiltersAndToFirstPage = (field, value) => {
    toggleFilter(field, value);
  };

  const setQueryParams = () => {
    const params: any = {
      filters: prepareFilters(filters),
    };

    setQuery(params.filters);

    return params;
  };

  const params = useMemo(() => setQueryParams(), [filters]);

  const handleDatePicketOpen = (field) => {
    return (isOpen) => {
      setOpenDatePickerField(field);
      setIsOpenDatePicker(isOpen);
    };
  };

  useEffect(() => {
    fetchOptions();
    fetchNotifications();

    return () => {
      closeFilters();
      closeNotificationAllItems();
      closeUpcomingTrips();
    };
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      history.push({ pathname: '/', search: '?period=current_month' });
    }
  }, []);

  useEffect(() => {
    fetchNumbers(params);
    fetchGraphicsData(params);
  }, [filters]);

  useEffect(() => {
    if (isOpenUpcoming) {
      fetchUpcomingTrips();
    }
  }, [isOpenUpcoming]);

  const filterComponents = useMemo(
    () =>
      getDashboardFilters(
        isOpenDatePicker,
        openDatePickerField,
        filters,
        options,
        handleDatePicketOpen,
        setFilter,
        toggleFiltersAndToFirstPage,
        toggleFilter
      ),
    [
      isOpenDatePicker,
      openDatePickerField,
      filters,
      options,
      handleDatePicketOpen,
      setFilter,
      toggleFiltersAndToFirstPage,
      toggleFilter,
    ]
  );

  const handleMergeTripsModalOpen = (ids) => {
    setMergeTripIds(ids);
    setIsMergeTripsModalOpen(true);
  };

  const handleMergeTripsModalClose = () => {
    setIsMergeTripsModalOpen(false);
  };

  const handleMergeTrips = async (baseTripId) => {
    try {
      setIsMergeTripsLoading(true);

      await request.post('trips/merge-trips', { ids: mergeTripIds, baseTripId, ignoreValidation: false });

      getErrorStatusMessage({
        status: 200,
        message: 'Trips successfully merged.',
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      setIsMergeTripsLoading(false);
      handleMergeTripsModalClose();
      history.push({ pathname: '/', search: '?period=current_month' });
    }
  }; 

  const onClickItemInsideDrawer = (param: any) => {
    if (isOpenNotificationData.notificationType === NOTIFICATION_TYPES.TRIPS_FOR_MERGING) {
      return handleMergeTripsModalOpen(param);
    }

    return history.push(param);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper>
        {isFiltersOpen && (
          <FiltersReciever
            filterComponents={filterComponents}
            isOpenDatePicker={isOpenDatePicker}
            placeholder="Search for users, price and more"
            filters={filters}
            setFilter={setFilters}
          />
        )}

        <DashboardContent>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />
              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Dashboard
              </Text>
            </FilterHeaderWrapper>

            <IconsWrapper>
              <IconWrapper isOpen={isOpenUpcoming} onClick={showUpcomingTrips}>
                <GenericBetslip fontSize="1.5rem" />
              </IconWrapper>
            </IconsWrapper>
          </PageHeader>

          <DashboardNumbers data={numbers} periodType={periodType} />

          <Notifications
            notifications={notifications}
            history={history}
            openNotifications={showNotificationAllItems}
            handleMergeTripsDialog={handleMergeTripsModalOpen}
          />

          <ChartsWrapper>
            <BarChart
              chartData={getBarChartData(chartsData.tripStatistics)}
              options={barChartOptions}
              periodType={periodType}
              periodRange={periodRange}
            />

            <LineChart
              chartData={getLineChartData(chartsData.numbers)}
              options={lineChartOptions}
              periodType={periodType}
              periodRange={periodRange}
            />
          </ChartsWrapper>
        </DashboardContent>

        {isOpenNotification && (
          <div>
            <Drawer
              title={`${isOpenNotificationData.title} • ${isOpenNotificationData.data.length}`}
              handleClose={closeNotificationAllItems}
            >
              <DashboardOverview
                data={isOpenNotificationData.data}
                type={isOpenNotificationData.notificationType}
                onClick={onClickItemInsideDrawer}
              />
            </Drawer>
          </div>
        )}

        {isOpenUpcoming && (
          <div>
            <Drawer
              title={`Upcoming trips • ${upcomingTrips.length}`}
              handleClose={closeUpcomingTrips}
            >
              <DashboardOverview
                data={upcomingTrips}
                type="upcomingTrips"
                onClick={onClickItemInsideDrawer}
              />
            </Drawer>
          </div>
        )}

        {isMergeTripsModalOpen && (
          <MergeTripsDialog
            submitButtonLabel='Confirm'
            isSubmitDisabled={isMergeTripsLoading}
            onSubmit={handleMergeTrips}
            onClose={handleMergeTripsModalClose}
            mergeTripIds={mergeTripIds}
          />)}
      </Wrapper>
    </>
  );
};

export default Dashboard;
