import moment from 'moment';
import omit from 'lodash/omit';
import { Flight } from '../../enums/Flight';
import { defineFlightDepartureCity, defineFlightReturnCity } from '../../utils/trips';
import { Trip } from '../../enums/Trip';
import { Passenger } from '../../enums/Passenger';
import { IStatuses } from '../../pages/Trips/types';
import { PaymentRequest } from '../../enums/PaymentRequest';

interface IFlightOption {
  value: number;
  title: string;
};

export const formatFlightDate = (date) => {
  return moment.utc(date).format('DD MMM YYYY');
};

export const formatFlightTitle = (flight: Flight): string => {
  const departureCity = defineFlightDepartureCity(flight);
  const arrivalCity = defineFlightReturnCity(flight);
  const departureDate = formatFlightDate(flight.startDate);
  const returnDate = formatFlightDate(flight.endDate);
  return `[${flight.reference}] ${departureCity} → ${arrivalCity}, ${departureDate} — ${returnDate}`;
};

export const formatFlightOptions = (flights: Flight[]): IFlightOption[] => {
  return flights.map(flight => ({
    title: formatFlightTitle(flight),
    value: flight.id,
  }));
};

export const formatPassengerName = (passenger: Passenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

export const findAndFormatPassengers = (trip: Trip, flightId: number): IStatuses[] => {
  const flight = trip.flights.find(flight => flight.id === flightId);

  if (flight?.passengers?.length) {
    return flight.passengers.map(passenger => ({
      value: passenger.id,
      title: formatPassengerName(passenger),
    }));
  }

  return [];
};

export const normalizePaymentRequest = (paymentRequest: PaymentRequest, passengers: IStatuses[]): PaymentRequest => {
  const passenger = passengers.find(passenger => passenger.value === paymentRequest.passengerId);
  return omit(
    {
      ...paymentRequest,
      passenger: passenger ? passenger.title : null,
    },
    ['passengerId'],
  );
};
