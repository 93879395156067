import {
  SET_TOOLS_ALL_PURPOSES_CHECKBOX_DATA,
  SET_TOOLS_PURPOSES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_PURPOSE_MODAL,
  SET_IS_CHANGED_PURPOSE,
  SHOW_DELETE_PURPOSE_MODAL,
  CLOSE_DELETE_PURPOSE_MODAL,
  SHOW_PURPOSE_OVERVIEW,
  CLOSE_PURPOSE_OVERVIEW,
} from './actionTypes';

export const setPurposesCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_PURPOSES_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllPurposesCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_PURPOSES_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setIsChangedPurpose = () => {
  return { type: SET_IS_CHANGED_PURPOSE };
};

export const setIsOpenAddNewPurpose = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_PURPOSE_MODAL, payload: isOpen };
};

export const showDeletePurposeModal = (data: any[] | any) => {
  const deletablePurposes = data?.length ? data.map((item) => item.purposeId) : [data?.purposeId];

  return { type: SHOW_DELETE_PURPOSE_MODAL, payload: { deletablePurposes } };
};

export const closeDeletePurposeModal = () => {
  return { type: CLOSE_DELETE_PURPOSE_MODAL };
};

export const showPurposeOverview = (purpose) => {
  return { type: SHOW_PURPOSE_OVERVIEW, payload: { data: purpose } };
};

export const closePurposeOverview = () => {
  return { type: CLOSE_PURPOSE_OVERVIEW };
};



