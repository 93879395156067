import { useMemo } from 'react';
import { GenericUser } from '@heathmont/moon-icons-tw';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import {  ITripOtherTransportBooking } from './types';
import { OverviewContentValue } from '../../TripsOverviewDrawerContent/styles';
import Accordion from '../../Accordion';
import OtherTransportOrder from '../../OtherTransportOrder';
import {
  ChangesHistoryLabel,
  ButtonsWrapper,
  CostContainer,
  CostWrapper,
  CostTitle,
  Cost,
} from '../styles';
import { formatDate } from '../utils';
import TripEditButton from '../TripEditButton';
import {
  AdditionItemsWrapper,
  AdditionItem,
} from '../../../styled';
import { AdditionItemTitle } from '../../CancellationPenalty/styles';

const TripOtherTransportBooking: React.FC<ITripOtherTransportBooking> = ({
  otherTransport,
  hasEditAccess,
  handleDelete,
  handleEdit,
}) => {
  const title = useMemo(() => {
    return `Other transport reference ${otherTransport.reference}`;
  }, [otherTransport.reference]);

  return (
    <Accordion
      withoutPadding
      openByDefault
      title={(
        <OverviewContentValue isBold>
          {title}
        </OverviewContentValue>
      )}
    >       
      {hasEditAccess && (
        <TripEditButton handleEdit={() => handleEdit(otherTransport.id)}/>
      )}

      {otherTransport?.updatedBy?.id && otherTransport.updatedAt && (
        <ChangesHistoryLabel>
          <GenericEdit fontSize="16px" /> Updated by {otherTransport?.updatedBy?.fullName} at {formatDate(otherTransport.updatedAt)}
        </ChangesHistoryLabel>
      )}

      <OtherTransportOrder otherTransport={otherTransport} />

      {otherTransport.passengers?.length > 0 && (
        <>
          <AdditionItemTitle>Passengers:</AdditionItemTitle>

          {otherTransport.passengers.map((passenger) => (
            <AdditionItemsWrapper key={passenger.id}>
              <GenericUser fontSize="1.5rem" />
              <AdditionItem>
                {passenger.firstName} {passenger.lastName}
              </AdditionItem>
            </AdditionItemsWrapper>
          ))}
        </>
      )}

      <AdditionItemsWrapper>
        <AdditionItemTitle>Type:</AdditionItemTitle>
        <AdditionItem>{otherTransport.type}</AdditionItem>
      </AdditionItemsWrapper>

      <CostWrapper>
        <CostContainer>
          <CostTitle>Other transport cost</CostTitle>
          <Cost>{otherTransport.price} EUR</Cost>
        </CostContainer>
      </CostWrapper>

      {hasEditAccess && (
        <ButtonsWrapper>
          <Button
            animation="error"
            size="small"
            onClick={() => handleDelete(otherTransport)}
          >
            Delete other transport
          </Button>
        </ButtonsWrapper>
      )}
    </Accordion>
  );
};

export default TripOtherTransportBooking;
