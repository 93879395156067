import { useEffect, useState, useRef, useMemo } from 'react';
import { TextInput, Text } from '@heathmont/moon-core';
import { FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { IChangeTripsStatusDialog } from './types';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import InfoBlock from '../InfoBlock'; 
import Dialog from '../Dialog';
import request from '../../models/request';
import { getStatusLabel } from '../../utils/tableHelpers';
import { TripStatusType } from '../../enums/TripStatusType';
import { ConfirmTextWrapper, FieldsWrapper, FormItem, FormItemLabel } from './styles';
import { tripStatusSchema, getInitialValues } from './schema';
import { getAvailableTripStatuses } from './utils';

const ChangeTripsStatusDialog: React.FC<IChangeTripsStatusDialog> = ({
  submitButtonLabel,
  isEditable,
  isLoading,
  statuses = [],
  status,
  trips,
  title,
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: tripStatusSchema,
    validateOnMount: true,
    initialValues: getInitialValues(status),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, setTouched } = formik;
  const availableStatuses = useMemo(() => getAvailableTripStatuses(statuses, trips), [statuses, trips]);
  const [multiPassengersFlightsTrips, setMultiPassengersFlightTrips] = useState<string[]>([]);
  const mounted = useRef(false);

  // TODO: Need to update
  // const fetchTripsMultiPassengersFlights = async () => {
  //   try {
  //     const { data: { multiPassengersFlights } } = await request.get('trips/multi-passengers-flights', {
  //       params: {
  //         tripIds: trips.map(({ tripId }) => tripId),
  //       },
  //     });
  //     setMultiPassengersFlightTrips(multiPassengersFlights.map(({ reference }) => reference));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  
  // useEffect(() => {
  //   mounted.current = true;
  
  //   if (isExternal && trips.length) {
  //     fetchTripsMultiPassengersFlights();
  //   }

  //   return () => {
  //     mounted.current = false;
  //   };
  // }, []);

  useEffect(() => {
    if (mounted.current && values.status !== TripStatusType.DECLINED) {
      setValues(getInitialValues(values.status));
      setTouched({}, false);
    }
  }, [values.status]);

  return (
    <Dialog
      submitButtonLabel={submitButtonLabel}
      isSubmitDisabled={!isEmpty(errors)}
      withoutMinWidth
      isLoading={isLoading}
      width={400}
      title={title}
      data={trips}
      onSubmit={() => onSubmit(values.status, values.declineReason)}
      onClose={onClose}
    >
      {multiPassengersFlightsTrips.length > 0 && values.status === TripStatusType.DECLINED &&
        <InfoBlock
          text={`
            One of the flights belong to the multi passengers booking. 
            In case of updating trip status to Declined, actual flight booking will be cancelled in Amadeus. 
            Booking${multiPassengersFlightsTrips.length > 1 ? 's' : ''} with multi passengers: ${multiPassengersFlightsTrips.join(', ')}.
          `}
        />
      }

      <FormikProvider value={formik}>
        <FieldsWrapper>        
          {
            isEditable ? (
              <FormItem>
                <FormItemLabel>New status</FormItemLabel>
                <CustomSelect
                  currentValue={values.status}
                  items={availableStatuses}
                  getItemLabel={getStatusLabel}
                  onChange={value => {
                    setFieldValue('status', value);
                    setFieldTouched('status', true, false);
                  }}
                />
              </FormItem>
            ) : (
              <ConfirmTextWrapper>
                <Text size={16}>Are you sure you want to update the trip status to</Text>
                {getStatusLabel(values.status)}
                <Text size={16}>?</Text>
              </ConfirmTextWrapper>
            )
          }
          {
            values.status === TripStatusType.DECLINED && (
              <FormItem>
                <FormItemLabel>Decline reason</FormItemLabel>
                <FormField
                  placeholder={'Specify decline reason'}
                  fieldName='declineReason'
                  component={TextInput}
                  inputSize='large'
                  fieldId='declineReason'
                  isError={touched?.declineReason && errors?.declineReason}
                  errors={touched?.declineReason && errors}
                  value={values.declineReason}
                  type='text'
                  onChange={({ target: { value } }) => setFieldValue('declineReason', value)}
                  onBlur={() => setFieldTouched('declineReason', true, false)}
                />
              </FormItem>
            )
          }
        </FieldsWrapper>
      </FormikProvider>
    </Dialog>
  );
};

export default ChangeTripsStatusDialog;
