import { TripStatusUpdateFields } from '../../enums/TripStatusUpdateFields';
import { TripStatusType } from '../../enums/TripStatusType';
import { UserStatuses } from '../../enums/UserStatuses';
import { IStatuses } from '../../pages/Trips/types';

export const isManagerApprovalRequired = (trips: TripStatusUpdateFields[]) => {
  return trips.every((trip) => {
    const isExternalTraveler = trip.userType === UserStatuses.External;
    const paymentRequired = trip.paymentRequired;
    return !isExternalTraveler && !paymentRequired;
  });
};

export const getAvailableTripStatuses = (statuses: IStatuses[], trips: TripStatusUpdateFields[]) => {
  if (isManagerApprovalRequired(trips)) {
    return statuses;
  }

  return statuses.filter(status => status.value !== TripStatusType.APPROVED);
};
