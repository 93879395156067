export const SET_ACCOMMODATIONS_IGNORE_LIST = 'tools/SET_ACCOMMODATIONS_IGNORE_LIST';
export const SHOW_BOOKING_FOR_IGNORE_OVERVIEW = 'tools/SHOW_BOOKING_FOR_IGNORE_OVERVIEW';
export const CLOSE_BOOKING_FOR_IGNORE_OVERVIEW = 'tools/CLOSE_BOOKING_FOR_IGNORE_OVERVIEW';
export const SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL = 'tools/SHOW_BOOKING_FOR_IGNORE_DELETE_MODAL';
export const CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL = 'tools/CLOSE_BOOKING_FOR_IGNORE_DELETE_MODAL';
export const SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL = 'tools/SHOW_BOOKING_FOR_IGNORE_CREATION_MODAL';
export const CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL = 'tools/CLOSE_BOOKING_FOR_IGNORE_CREATION_MODAL';
export const SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA = 'tools/SET_TOOLS_ALL_BOOKING_FOR_IGNORE_CHECKBOX_DATA';
export const SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA = 'tools/SET_TOOLS_BOOKING_FOR_IGNORE_CHECKBOX_DATA';
export const SET_BOOKING_FOR_IGNORE_CREATION_LOADING = 'tools/SET_BOOKING_FOR_IGNORE_CREATION_LOADING';
export const SET_BOOKING_FOR_IGNORE_DELETION_LOADING = 'tools/SET_BOOKING_FOR_IGNORE_DELETION_LOADING';
