import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { TextInput } from '@heathmont/moon-core';
import { v4 as uuidv4 } from 'uuid';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import DynamicFormFields from '../../../../../components/DynamicFormFields';
import FormField from '../../../../../components/FormField';
import validationSchema from '../../../../../utils/validation-schema';
import request from '../../../../../models/request';
import { ILoyaltyPrograms } from './types';
import SelectWithExternalDataSource from '../../../../../components/SelectWithExternalDataSource';
import { LoyaltyProgramsWrapper, GroupInputWrapper } from './styled';

const LoyaltyPrograms: React.FC<ILoyaltyPrograms> = ({ values, touched, errors, initialValues, setFieldValue }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.loyaltyNumbers, initialValues.loyaltyNumbers)) {
      dispatch(setIsChangedTabField('loyalty', true));
    } else {
      dispatch(setIsChangedTabField('loyalty', false));
    }
  }, [values.loyaltyNumbers, initialValues.loyaltyNumbers]);

  const getDefaultValue = () => {
    return { id: uuidv4(), number: '', airline: '' };
  };

  const getFormattedAirlines = (airlines = []) => {
    return airlines.map((airline) => ({
      title: airline.title,
      value: airline.value,
    }));
  };

  const fetchAirlines = async (params: { search?: string, id?: string | number }) => {
    try {
      const { data } = await request.get('airlines', { params });
      return getFormattedAirlines(data.airlines || []);
    } catch (err) {
      return [];
    }
  };

  return (
    <LoyaltyProgramsWrapper>
      <DynamicFormFields
        fieldName="loyaltyNumbers"
        infoText={`You can provide up to ${validationSchema.USER.LOYALTY_PROGRAMS.max} loyalty numbers.`}
        touched={touched}
        errors={errors}
        data={values.loyaltyNumbers}
        setFieldValue={setFieldValue}
        getDefaultValue={getDefaultValue}
        renderItem={(fieldName, value, index) => (
          <GroupInputWrapper>
            <SelectWithExternalDataSource
              currentValue={value.airline}
              placeholder='Select airline'
              position="left"
              isError={!!(get(touched, [fieldName, index, 'airline']) && get(errors, [fieldName, index, 'airline']))}
              fetchOptions={(params: any) => fetchAirlines(params)}
              resetValues={() => (
                setFieldValue(`${fieldName}.${index}.airline`, undefined)
              )}
              onChange={(value) => {
                setFieldValue(`${fieldName}.${index}.airline`, value);
              }}
            />
            <FormField
              placeholder={'Number'}
              fieldName={`${fieldName}.${index}.number`}
              inputSize="large"
              component={TextInput}
              fieldId={`${fieldName}.${index}.number`}
              isError={get(touched, [fieldName, index, 'number']) && get(errors, [fieldName, index, 'number'])}
              value={value.number}
              type="text"
              onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}.number`, value)}
            />
          </GroupInputWrapper>
        )}
      />
    </LoyaltyProgramsWrapper>
  );
};

export default LoyaltyPrograms;
