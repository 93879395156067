import { IEllipsisText } from './types';
import { Text } from './styles';

const EllipsisText: React.FC<IEllipsisText> = ({
  text = '',
}) => {
  return (
    <Text title={text}>{text}</Text>
  );
};

export default EllipsisText;
