import sharedTokens, { Theme } from '@heathmont/moon-themes/lib/sharedTokens/sharedTokens';
import avertaStd from '@heathmont/moon-themes/lib/sharedTokens/avertaStd';
import supportColors from '@heathmont/moon-themes/lib/supportColors/supportColors';
import componentsTokens from '@heathmont/moon-themes/lib/sharedTokens/componentsTokens';
import { supportiveColors } from '@heathmont/moon-themes';

/**
    Do not change color's names. It need for correct working of library
 */

const boxShadow = '0px 60px 60px rgba(0, 0, 0, 0.12)';


const color = {
  // Primary color - Main button, links etc
  piccolo: {
    100: '#275ADB',
  },
  // Secondary color - Secondary button
  hit: {
    100: '#fff',
  },
  //Border color
  beerus: {
    100: '#E0E0E0',
  },
  //Main background color
  goku: {
    100: '#ffffff',
  },
  //Secondary background color
  gohan: {
    100: '#ffffff',
  },
  //Button text, promotions card text. Some cases body text or headline text.
  goten: {
    100: '#ffffff',
  },
  //Main body text
  bulma: {
    100: '#181B1C',
  },
  //Secondary body text
  trunks: {
    100: '#78787D',
  },
  frieza: {
    100: '#5C33CF'
  }
};

const colorNew = {
  piccolo: '#275ADB',
  hit: '#ffffff',
  beerus: '#E0E0E0',
  goku: '#ffffff',
  gohan: '#ffffff',
  bulma: '#181B1C',
  trunks: '#78787D',
  goten: '#ffffff',
  popo: '#000000'
};

const themeSupportColors = {
  //Warning color
  krillin: {
    100: '#f5a300',
  },
  //Success color
  roshi: {
    100: '#0BC15A',
  },
  //Error color
  chiChi: {
    100: '#FF1F39',
  },
  ghost: {
    100: '#FEF9E9',
  },
};

const yoloTheme: Theme = {
  ...sharedTokens,
  brand: 'MoonDesign',
  fontFace: avertaStd.fontFace,
  fontFamily: avertaStd.fontStack,
  fontSize: {
    body: 14,
  },
  fontWeight: avertaStd.fontWeight,
  boxShadow,
  color: {
    text: color.bulma[100],
    background: color.goku[100],
    ...supportColors,
    ...color,
    ...themeSupportColors,
  },
  colorNew: {
    ...colorNew,
    ...supportiveColors,
  },
  radius: {
    small: 4,
    default: 8,
    largest: 12,
  },
  ...componentsTokens(colorNew),
  colorScheme: 'light',
};

export default yoloTheme;
