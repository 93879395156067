import { useCallback, useMemo } from 'react';
import { FilesCopy } from '@heathmont/moon-icons-tw';
import get from 'lodash/get';
import { Field } from 'formik';
import {
  CopyToClipboardButton,
  WithCopyToClipboard,
  SubmissionButton,
  WithSubmission,
  WithSwapIcon,
  ErrorMessage,
  WithPostfix,
  WithPrefix,
  Wrapper,
  Label,
} from './styled';
import { ReactComponent as ChevronRightSmallSvg } from '../../static/icons/chevron-right-small.svg';
import './styles.scss';
import { showToast } from '../../utils/commonFunctions';

const FormField: React.FC<any> = ({
  copyToClipboard,
  withSubmission,
  customOnChange,
  errorPosition,
  labelPadding,
  fieldLabel,
  component,
  className,
  fieldName,
  swapIcon = null,
  isMobile,
  fieldId,
  postfix,
  errors,
  prefix,
  setFieldValue = () => {},
  onChange,
  ...props
}) => {
  const fieldError = useMemo(() => get(errors, fieldId), [errors, fieldId]);

  const handleChange = useCallback((e) => {
    if (customOnChange) {
      setFieldValue(fieldName, e.target.value);

      return customOnChange(fieldName, e.target.value);
    }

    onChange(e);
  }, [fieldName]);  

  const componentJSX = (
    <Wrapper isMobile={isMobile} className={className || ''}>
      {fieldLabel && (
        <Label labelPadding={labelPadding}>
          {fieldLabel} {postfix && <span>*</span>}
        </Label>
      )}

      <Field id={fieldId} name={fieldName} component={component} onChange={handleChange} {...props} />

      {fieldError ? (
        <div style={{ position: 'relative', marginLeft: '5px', marginTop: '5px' }}>
          <ErrorMessage errorPosition={errorPosition}>{fieldError}</ErrorMessage>
        </div>
      ) : null}

      {swapIcon && <WithSwapIcon>{swapIcon}</WithSwapIcon>}
    </Wrapper>
  );

  if (prefix) {
    return (
      <WithPrefix>
        <span>{prefix}</span>
        {componentJSX}      
      </WithPrefix>
    );
  } 

  if (postfix) {
    return (
      <WithPostfix disabled={props.disabled}>
        {componentJSX}      
        <span>{postfix}</span>
      </WithPostfix>
    );
  }

  if (copyToClipboard) {
    return (
      <WithCopyToClipboard disabled={props.disabled}>
        {componentJSX}   
        <CopyToClipboardButton
          variant="secondary"
          iconOnly
          size="small"
          type="button"
          onClick={() => {
            showToast('Text copied', 'success');
            navigator.clipboard.writeText(props.value);
          }}
        >
          <FilesCopy />
        </CopyToClipboardButton>   
      </WithCopyToClipboard>
    );
  }

  if (withSubmission) {
    return (
      <WithSubmission disabled={props.disabled}>
        <SubmissionButton
          disabled={props.disabled}
          iconOnly={<ChevronRightSmallSvg />}
          type="button"
          onClick={props.onSubmit}
        />
        {componentJSX}  
      </WithSubmission>
    );
  } 

  return componentJSX;
};

export default FormField;
