import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { TextInput } from '@heathmont/moon-core';
import { TextInputGroup, Select } from '@heathmont/moon-select';
import FormField from '../../../../../components/FormField';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { IMobileAccountDetails } from './types';
import { DoubleFieldWrapper, EmailSign, FieldWrapper } from './styles';
import { filterItemsWithBusinessVerticalByVerticalId, filterItemsWithVerticalsByVerticalObjectId } from '../../../../../utils/filters';

const MobileAccountDetails: React.FC<IMobileAccountDetails> = ({ values, setFieldValue, options, initialValues }) => {
    const { email, company, employer, team, role, businessVertical } = values.details;
    const [employers, setEmployers] = useState([]);
    const [teams, setTeams] = useState([]);

  const dispatch = useDispatch();

  const assignEmployersByVertical = (employers, businessVertical) => {
    setEmployers(filterItemsWithVerticalsByVerticalObjectId(employers, businessVertical));
  };

  const assignTeamsByVertical = (teams, businessVertical) => {
    setTeams(filterItemsWithBusinessVerticalByVerticalId(teams, businessVertical));
  };

  useEffect(() => {
    assignEmployersByVertical(options?.employers ?? [], businessVertical);
    assignTeamsByVertical(options?.teams ?? [], businessVertical);
  }, []);

  useEffect(() => {
    assignEmployersByVertical(options?.employers ?? [], businessVertical);
    assignTeamsByVertical(options?.teams ?? [], businessVertical);
    setFieldValue('employer', null)
    setFieldValue('team', null)
  }, [businessVertical]);

  useEffect(() => {
    if (!checkOnIsEqual(values.details, initialValues.details)) {
      dispatch(setIsChangedTabField('details', true));
    } else {
      dispatch(setIsChangedTabField('details', false));
    }
  }, [values.details, initialValues.details]);

  return (
    <div>
      <FieldWrapper>
        <FormField
          value={email}
          fieldId="email"
          fieldName="email"
          component={TextInput}
          type="email"
          inputSize="xlarge"
          onChange={(e) => setFieldValue('details.email', e.target.value)}
          errorPosition={-20}
          label="E-mail address"
          disabled
        />

        <EmailSign>We'll email you trip confirmations and receipts.</EmailSign>
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: {
              type: 'text',
              value: role,
              inputSize: 'xlarge',
              label: 'Role',
              disabled: true,
            },
            input2: {
              type: 'text',
              value: company,
              inputSize: 'xlarge',
              label: 'Company',
              disabled: true,
            },
          }}
        />
      </DoubleFieldWrapper>

      <FieldWrapper>
        <FormField
          value={(options.businessVerticals ?? []).find((item) => +item.value === businessVertical)}
          component={Select}
          size="xLarge"
          onChange={({ value }) => setFieldValue('details.businessVertical', +value)}
          label="Your business vertical"
          options={options.businessVerticals ?? []}
          isSearchable={true}
          isDisabled={true}
        />
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: {
              type: 'select',
              value: employers.find((item) => +item?.value === employer),
              onChange: ({ value }) => setFieldValue('details.employer', +value),
              inputSize: 'xlarge',
              label: 'Your employer',
              options: employers,
              isSearchable: true,
              menuPlacement: 'top',
            },
            input2: {
              type: 'select',
              value: teams.find((item) => +item.value === team),
              onChange: ({ value }) => setFieldValue('details.team', +value),
              inputSize: 'xlarge',
              label: 'Your team',
              options: teams,
              isSearchable: true,
              menuPlacement: 'top',
            },
          }}
        />
      </DoubleFieldWrapper>
    </div>
  );
}

export default MobileAccountDetails;