import { combineReducers } from 'redux';
import currentUser from './currentUser/reducer';
import builder from './builder/reducer';
import table from './table/reducer';
import auth from './auth/reducer';
import app from './app/reducer';
import dashboardReducer from './dashboard/reducer';
import tripsReducer from './trips/reducer';
import expensesReducer from './expenses/reducer';
import usersReducer from './users/reducer';
import rolesReducer from './tools/roles/reducer';
import feedbacksReducer from './tools/feedbacks/reducer';
import teamsReducer from './tools/teams/reducer';
import employersReducer from './tools/employers/reducer';
import companiesReducer from './tools/companies/reducer';
import purposesReducer from './tools/purposes/reducer';
import editProfileReducer from './editProfile/reducer';
import signUpReducer from './signUp/reducer';
import signUpCompanyReducer from './signUpCompany/reducer';
import subscriptionsReducer from './tools/subscriptions/reducer';
import loadingReducer from './spinner/reducer';
import allowancesReducer from './tools/dailyAllowances/reducer'
import actionsAndNotificationsReducer from './tools/actionsAndNotifications/reducer';
import employmentTypesReducer from './tools/employmentTypes/reducer';
import recommendedHotelsReducer from './tools/recommendedHotels/reducer';
import ignoreListAccommodationsReducer from './tools/ignoreListAccommodations/reducer';
import paymentsReducer from './payments/reducer';
import customHotelsReducer from './tools/customHotels/reducer';
import businessVerticalsReducer from './tools/businessVerticals/reducer';

export default combineReducers({
  currentUser,
  builder,
  table,
  auth,
  app,
  tripsReducer,
  expensesReducer,
  usersReducer,
  feedbacksReducer,
  rolesReducer,
  teamsReducer,
  dashboardReducer,
  companiesReducer,
  employersReducer,
  purposesReducer,
  allowancesReducer,
  subscriptionsReducer,
  actionsAndNotificationsReducer,
  employmentTypesReducer,
  editProfileReducer,
  signUpReducer,
  signUpCompanyReducer,
  loadingReducer,
  recommendedHotelsReducer,
  ignoreListAccommodationsReducer,
  paymentsReducer,
  customHotelsReducer,
  businessVerticalsReducer,
});
