import { useMemo } from 'react';
import { GenericUser } from '@heathmont/moon-icons-tw';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { OverviewContentValue } from '../../TripsOverviewDrawerContent/styles';
import CancellationPenalty from '../../CancellationPenalty';
import AccommodationOrder from '../../AccommodationOrder';
import Accordion from '../../Accordion';
import { isAccommodationCancelable } from '../../../utils/trips';
import {
  ChangesHistoryLabel,
  ButtonsWrapper,
  CostContainer,
  CostWrapper,
  CostTitle,
  Cost,
} from '../styles';
import {
  isAccommodationExternal,
  formatDate,
} from '../utils';
import { ITripAccommodationBooking } from './types';
import TripEditButton from '../TripEditButton';
import {
  AdditionItemsWrapper,
  AdditionItem,
} from '../../../styled';
import { AdditionItemTitle } from '../../CancellationPenalty/styles';

const TripAccommodationBooking: React.FC<ITripAccommodationBooking> = ({
  accommodation,
  hasEditAccess,
  isApproved,
  handleChangePrice,
  handleDelete,
  handleEdit,
}) => {

  const title = useMemo(() => {
    return `Accommodation reference ${accommodation.reference}`;
  }, [accommodation.reference]);

  return (
    <Accordion
      withoutPadding
      openByDefault
      title={(
        <OverviewContentValue isBold>
          {title}
        </OverviewContentValue>
      )}
    > 
      {hasEditAccess && isAccommodationExternal(accommodation) && (
        <TripEditButton handleEdit={() => handleEdit(accommodation.id)}/>
      )}

      {accommodation?.updatedBy?.id && accommodation.updatedAt && (
        <ChangesHistoryLabel>
          <GenericEdit fontSize="16px" /> Updated by {accommodation?.updatedBy?.fullName} at {formatDate(accommodation.updatedAt)}
        </ChangesHistoryLabel>
      )}

      <AccommodationOrder accommodation={accommodation} />

      {accommodation.guests?.length > 0 && (
        <>
          <AdditionItemTitle>Guests:</AdditionItemTitle>

          {accommodation.guests.map((guest) => (
            <AdditionItemsWrapper key={guest.id}>
              <GenericUser fontSize="1.5rem" />
              <AdditionItem>
                {guest.firstName} {guest.lastName}
              </AdditionItem>
            </AdditionItemsWrapper>
          ))}
        </>
      )}

      {!isAccommodationExternal(accommodation) && <CancellationPenalty accommodation={accommodation} />}
      
      <CostWrapper>
        <CostContainer>
          <CostTitle>Accommodation cost</CostTitle>
          <Cost>{accommodation.price} EUR</Cost>
        </CostContainer>
      </CostWrapper>

      {hasEditAccess && (
        <ButtonsWrapper>
          {isApproved && !isAccommodationExternal(accommodation) && (
            <Button variant="secondary" size="small" onClick={() => handleChangePrice(accommodation)}>
              Change price
            </Button>
          )}
          {isAccommodationCancelable(accommodation) && (
            <Button
              animation="error"
              size="small"
              onClick={() => handleDelete(accommodation)}
            >
              Delete accommodation
            </Button>
          )}
        </ButtonsWrapper>
      )}
    </Accordion>
  );
};

export default TripAccommodationBooking;
