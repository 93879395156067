import moment from 'moment';
import { HotelFormData } from '../../../enums/HotelFormData';

export const formatTime = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).format('HH:mm:ss');
};

export const formatHotelBeforeSave = (hotel: HotelFormData): HotelFormData => {
  return {
    ...hotel,
    checkOutTime: formatTime(hotel.checkOutTime),
    checkInTime: formatTime(hotel.checkInTime),
  };
};
