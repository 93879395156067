import { CompanyFormTabType } from "../../enums/FormTabType";

export const getTabsData = (activeTab, checkedTabs) => [
    {
      checked: checkedTabs.includes(CompanyFormTabType.AccountDetails),
      active: activeTab === CompanyFormTabType.AccountDetails,
      tabNumber: CompanyFormTabType.AccountDetails,
      tabName: 'Account details',
    },
    {
      checked: checkedTabs.includes(CompanyFormTabType.Teams),
      active: activeTab === CompanyFormTabType.Teams,
      tabNumber: CompanyFormTabType.Teams,
      tabName: 'Teams',
    },
    {
      checked: checkedTabs.includes(CompanyFormTabType.Employers),
      active: activeTab === CompanyFormTabType.Employers,
      tabNumber: CompanyFormTabType.Employers,
      tabName: 'Employers',
    },
    {
      checked: checkedTabs.includes(CompanyFormTabType.Managers),
      active: activeTab === CompanyFormTabType.Managers,
      tabNumber: CompanyFormTabType.Managers,
      tabName: 'Managers',
    },
    {
      checked: checkedTabs.includes(CompanyFormTabType.Integrations),
      active: activeTab === CompanyFormTabType.Integrations,
      tabNumber: CompanyFormTabType.Integrations,
      tabName: 'Integrations',
    },
  ];