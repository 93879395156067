import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const CheckboxLabel = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
`;

export const Wrapper = styled.div`
  & label {
    display: flex;
    align-items: center;

    & span {
      display: flex;
      align-items: center;
      height: 30px;
    }
  }

  p {
    margin-bottom: 0 !important;
  }
`;

export const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  text-shadow: 0 0 0.01px black;
`;

export const MoreText = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
  isBold: true,
})`
  line-height: 24px;
  margin: 0px;
  margin-right: 5px;
`;
