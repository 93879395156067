import {
  CLEAR_BEFORE_LOGIN_URL,
  SET_BEFORE_LOGIN_URL,
  SET_ERROR_PAGE_DATA,
  CLEAR_LOGIN_EMAIL,
  SET_REDIRECT_URL,
  SET_LOGIN_EMAIL,
  SET_READY,
} from './actionTypes';

export const setAppReady = (isAppReady) => ({ type: SET_READY, data: { isAppReady } });

export const setAppShallBeRedirectedUrl = (shallBeRedirectedUrl) => ({
  type: SET_REDIRECT_URL,
  data: {
    shallBeRedirectedTo: shallBeRedirectedUrl,
  },
});

export const setErrorPageContent = (
  errorData = {
    header: 'errors.default.header',
    message: 'errors.default.message',
  }
) => ({
  type: SET_ERROR_PAGE_DATA,
  data: errorData,
});

export const setBeforeLoginUrl = (beforeLoginUrl) => ({
  type: SET_BEFORE_LOGIN_URL,
  data: { beforeLoginUrl },
});

export const clearBeforeLoginUrl = () => ({
  type: CLEAR_BEFORE_LOGIN_URL,
});

export const setLoginEmail = (loginEmail) => ({
  type: SET_LOGIN_EMAIL,
  data: { loginEmail },
});

export const clearLoginEmail = () => ({
  type: CLEAR_LOGIN_EMAIL,
});
