import styled from 'styled-components';

export const FormFieldsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

export const FieldsGroup = styled.div`
  display: flex;
  justify-items: center;
  gap: 10px;

  & > * {
    width: calc(50% - 5px);
  } 
`;

export const BusinessVerticalField = styled.div`
  display: flex;
  & > * {
    width: 100%;
  } 
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }
`;
