import styled from 'styled-components'

export const DeleteButton = styled.div`
  width: 40px;
`;

export const Wrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-grow: 1;
`;
