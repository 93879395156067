import { useMemo, useEffect } from 'react';
import { NotificationsQuestionMark } from '@heathmont/moon-icons';
import { Checkbox, Text } from '@heathmont/moon-core';
import { TextInput } from '@heathmont/moon-components';
import { 
  FormItemsWrapper,
  CheckboxWrapper,
  FormItemName,
  FormWrapper,
  IconWrapper,
  FormItem,
} from '../styles';
import CustomSelect from '../../CustomSelect';
import FormField from '../../FormField';
import InfoBlock from '../../InfoBlock';
import { formatPurpose } from '../../../utils/tableHelpers';
import { PurposeTypes } from '../../../enums/PurposeTypes';
import Tooltip from '../../Tooltip';
import { FormikProps } from 'formik';
import { IFormikAddExternalForm } from '../types';

const INFO_BLOCK_MESSAGE = `
  At least one flight or accommodation has to be added to the trip.
  The trip will be created with pending status,
  and the manager will get the approval notification
  if the required manager verification is not checked.
`;
interface ITravelerForm {
  travelerForm: FormikProps<IFormikAddExternalForm>;
  companyUsers: any[];
  purposes: any[];
};

const TravelerForm = ({
  travelerForm,
  companyUsers,
  purposes,
}: ITravelerForm) => {
  const { errors, values, setFieldValue, touched, handleBlur, setFieldTouched } = travelerForm;

  const usersOptions = useMemo(() => {
    return companyUsers?.map((user) => ({ value: user.value, title: `${user.title} (${user.email})` }));
  }, [companyUsers]);

  const purposesOptions = useMemo(() => {
    return purposes.filter(purpose => purpose.type === PurposeTypes.INTERNAL && !purpose.paymentRequired);
  }, [purposes]);

  const selectedUser = useMemo(() => {
    return companyUsers?.find(user => user.value === values?.traveler);
  }, [values?.traveler]);

  const getTripPurposeLabel = (value) => {
    const purpose = purposesOptions.find((purpose) => purpose.value === value);
    return formatPurpose(purpose);
  };

  useEffect(() => {
    if (selectedUser?.isSkippedManagerApproval) {
      setFieldValue('isManagerVerificationRequired', false);
    }
  }, [selectedUser?.id]);

  return (
    <>
      <FormWrapper>
        <InfoBlock text={INFO_BLOCK_MESSAGE} />
        <FormItemsWrapper>
          <FormItemName isTraveler>Traveler</FormItemName>
          <FormItem>
            <CustomSelect
              isSearchable
              currentValue={values.traveler}
              placeholder="Select traveler"
              isError={!!(touched?.traveler && errors?.traveler)}
              error={touched?.traveler && errors?.traveler}
              items={usersOptions}
              onChange={(value) => {
                setFieldValue('traveler', value);
                setFieldTouched('traveler', true, false);
              }}
            />
          </FormItem>
        </FormItemsWrapper>
        <FormItemsWrapper>
          <FormItemName isTraveler>Purpose</FormItemName>
          <FormItem>
            <CustomSelect
              isSearchable
              currentValue={values.purpose}
              getItemLabel={getTripPurposeLabel}
              placeholder="Select purpose"
              isError={!!(touched?.purpose && errors?.purpose)}
              error={touched?.purpose && errors?.purpose}
              items={purposesOptions}
              onChange={(value) => {
                setFieldValue('purpose', value);
                setFieldTouched('purpose', true, false);
              }}
            />
          </FormItem>
        </FormItemsWrapper>
        <FormItemsWrapper>
          <FormItemName isTraveler>Purpose details</FormItemName>
          <FormItem>
            <FormField
              errorPosition={-20}
              placeholder="Provide purpose details"
              fieldName="purposeDetails"
              component={TextInput}
              inputSize="large"
              fieldId="purposeDetails"
              isError={touched?.purposeDetails && errors?.purposeDetails}
              errors={touched?.purposeDetails && errors}
              value={values.purposeDetails}
              type="text"
              onChange={(e) => setFieldValue('purposeDetails', e.target.value)}
              onBlur={handleBlur}
            />
          </FormItem>
        </FormItemsWrapper>
        <CheckboxWrapper>
          <Checkbox
            disabled={(selectedUser?.isSkippedManagerApproval)}
            checked={values.isManagerVerificationRequired}
            onChange={() => setFieldValue('isManagerVerificationRequired', !values.isManagerVerificationRequired)}
          />

          <Text size={14}>Manager verification required</Text>

          {!!selectedUser?.isSkippedManagerApproval && (
            <Tooltip label={'Selected user has "Trips don’t require manager’s approval” activated setting'}>
              <IconWrapper>
                <NotificationsQuestionMark />
              </IconWrapper>
            </Tooltip>
          )}
        </CheckboxWrapper>
      </FormWrapper>
    </>
  );
};

export default TravelerForm;
