import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_PROFILE_TABS } from '../../components/Routes/suggestions';
import useIsMobile from '../../hooks/useIsMobile';
import EditProfile from './DesktopEditProfile/EditProfile.ui';
import MobileEditProfile from './MobileEditProfile/MobileEditProfile.ui';
import {
  setIsUpdateChanges,
  fetchOptions,
  saveData,
} from '../../store/editProfile/actions';
import { getInitialValues } from './suggestions';

const EditProfileSwitcher: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isGoAway, setIsGoAway] = useState(false);
  const { t: translate } = useTranslation();
  const currentUser = useSelector((state: any) => state.currentUser.currentUserData);
  const optionsLoading = useSelector((state: any) => state.editProfileReducer.optionsLoading);
  const optionsError = useSelector((state: any) => state.editProfileReducer.optionsError);
  const options = useSelector((state: any) => state.editProfileReducer.options);
  const tabs = useSelector((state: any) => state.editProfileReducer.tabs);
  const isValidNewPassword = useSelector((state: any) => state.editProfileReducer.isValidNewPassword);
  const isUpdated = useSelector((state: any) => state.editProfileReducer.isUpdate);
  const isUpdateError = useSelector((state: any) => state.editProfileReducer.isUpdateError);
  const initialValues = useMemo(() => getInitialValues(currentUser), [currentUser]);

  useEffect(() => {
    dispatch(fetchOptions(translate));
    dispatch(saveData(initialValues));
  }, []);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        dispatch(setIsUpdateChanges(false));
      }, 5000);
    }
  }, [isUpdated]);

  useEffect(() => {
    if (!isMobile && (props.history.location.pathname === EDIT_PROFILE_TABS.MAIN)) {
      props.history.replace(EDIT_PROFILE_TABS.DETAILS)
    }
  }, [isMobile]);

  const additionalProps = {
    isValidNewPassword,
    initialValues,
    isUpdateError,
    currentUser,
    isUpdated,
    isGoAway,
    options,
    tabs,
    setIsGoAway,
  };

  if (optionsLoading || optionsError || !options) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <MobileEditProfile {...props} {...additionalProps} />
      ) : (
        <EditProfile {...props} {...additionalProps} />
      )}
    </>
  );
};

export default EditProfileSwitcher;
