export enum PAYMENT_TYPE {
  CRYPTO = 'crypto',
  CARD = 'credit_card',
  INVOICE = 'invoice',
};

export enum PAYMENT_TYPE_LABEL {
  CRYPTO = 'Crypto',
  CARD = 'Credit card',
  INVOICE = 'Invoice',
}

export const paymentTypeOptions = [
  {
    value: PAYMENT_TYPE.CRYPTO,
    label: PAYMENT_TYPE_LABEL.CRYPTO,
  },
  {
    value: PAYMENT_TYPE.CARD,
    label: PAYMENT_TYPE_LABEL.CARD,
  },
  {
    value: PAYMENT_TYPE.INVOICE,
    label: PAYMENT_TYPE_LABEL.INVOICE,
  },
];
