import { useState, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { OtherPlug } from '@heathmont/moon-icons';
import { TextInput, Button, Loader } from '@heathmont/moon-core';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import FormField from '../FormField';
import TokenField from '../TokenField';
import FormActions from './FormActions';
import EditButton from './EditButton';
import InfoBlock from '../InfoBlock';
import { ICompanyHibobIntegration } from './types';
import {
  OverviewContentWrapper,
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
} from './styles';
import { hibobIntegrationSchema } from './schema';
import EllipsisText from '../EllipsisText';


const getInitialValues = (hibob) => ({
  accessToken: hibob?.accessToken || '',
  accessId: hibob?.accessId || null,
});

const CompanyHibobIntegration: React.FC<ICompanyHibobIntegration> = ({
  companyId,
  hibob,
  updateHibobIntegration,
  deleteHibobIntegration,
  addHibobIntegration,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCheckingConnectionLoading, setIsCheckingConnectionLoading] = useState(false);
  const initialValues = useMemo(() => getInitialValues(hibob), [hibob]);

  useEffect(() => {
    setIsEditMode(false);
  }, [companyId]);

  const onCancel = (resetForm) => {
    setIsEditMode(false);
    resetForm();
  };

  const onDelete = (resetForm) => {
    setIsEditMode(false);
    resetForm();
    deleteHibobIntegration(companyId);
  };

  const onSubmit = (values, { resetForm }) => {
    setIsEditMode(false);
    resetForm();

    if (hibob?.accessToken && hibob?.accessId) {
      return updateHibobIntegration(companyId, values);
    } else {
      return addHibobIntegration(companyId, values);
    }
  };

  const toEditMode = () => {
    setIsEditMode(true)
  };

  const testConnectionHibob = async (companyId) => {
    try {
      setIsCheckingConnectionLoading(true);
      await request.get(`tools/companies/test-connection/hibob/${companyId}`);
      setIsCheckingConnectionLoading(false);
      getErrorStatusMessage({
        status: 200,
        message: `Connection established!`,
      });
    } catch (err) {
      setIsCheckingConnectionLoading(false);
      getErrorStatusMessage({
        status: err?.response?.status,
        message: 'Connection could not be established!',
      });
    }
  };

  if (!isEditMode && !hibob?.accessToken && !hibob?.accessId) {
    return (
      <InfoBlock
        text="Connect to Hibob to update employee’s data automatically."
        onClick={toEditMode}
      />
    );
  }

  return (
    <OverviewContentWrapper>
      {!isEditMode && <EditButton onClick={toEditMode} />}

      <Formik
        enableReinitialize
        validationSchema={hibobIntegrationSchema}
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ errors, values, resetForm, setFieldValue, touched, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <OverviewContent>
                <OverviewContentName>Access id</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="accessId"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="accessId"
                      errors={touched?.accessId && errors}
                      label="Access id"
                      value={values.accessId}
                      type="text"
                      onChange={(e) => {
                        setFieldValue('accessId', e.target.value); 
                        setFieldTouched('accessId', true, false);
                      }}
                    />
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={hibob?.accessId}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>
              <OverviewContent>
                <OverviewContentName>Access token</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="accessToken"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="accessToken"
                      errors={touched?.accessToken && errors}
                      label="Access token"
                      value={values.accessToken}
                      type="password"
                      onChange={(e) => {
                        setFieldValue('accessToken', e.target.value);
                        setFieldTouched('accessToken', true, false);
                      }}
                    />
                  ) : (
                    <OverviewContentValue>
                      <TokenField token={hibob?.accessToken}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>

              {isEditMode ? (
                <FormActions
                  errors={errors}
                  onCancel={() => onCancel(resetForm)}
                  onDelete={hibob?.accessToken ? () => onDelete(resetForm) : null}
                />
              ) : (
                <Button
                  disabled={isCheckingConnectionLoading}
                  variant="ghost"
                  type="button"
                  onClick={() => testConnectionHibob(companyId)}
                >
                  {isCheckingConnectionLoading ? <Loader size="twoxsmall" color="trunks.100" />: <OtherPlug />}
                  Test connection
                </Button>
              )}
            </Form>
          )
        }}
      </Formik>
    </OverviewContentWrapper>
  );
};

export default CompanyHibobIntegration;
