import React from 'react';
import { IManagersTab } from './types';
import DynamicFormFields from '../../DynamicFormFields';
import { TabHelperText } from '../../../pages/SignUpCompany/suggestions';

const ManagersTab: React.FC<IManagersTab> = ({ values, errors, setFieldValue, touched }) => {
  return (
    <DynamicFormFields
      data={values.managers}
      setFieldValue={setFieldValue}
      touched={touched}
      label="Manager"
      fieldName="managers"
      infoText={TabHelperText.travelManagers}
      errors={errors}
    />
  );
};

export default ManagersTab;
