import { useLayoutEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const checkIsMobile = () => {
  return window.innerWidth < 768;
};

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(checkIsMobile());

  const updateSize = useCallback((): void => {
    setIsMobile(checkIsMobile());
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', debounce(updateSize, 250));
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return isMobile;
};

export default useIsMobile;
