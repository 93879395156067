const localStorageHelper: {
  get: (key: string, defaultValue?: any | null) => any;
  getRaw: (key: string, defaultValue?: any | null) => any;
  set: (key: string, value: string) => void;
  remove: (key: string) => void;
  prefix: string;
} = {
  prefix: 'ht_',
  get: () => null,
  getRaw: () => null,
  set: () => {},
  remove: () => {},
};

localStorageHelper.get = (key, defaultValue = null) => {
  const value = localStorageHelper.getRaw(key, defaultValue);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

localStorageHelper.getRaw = (key, defaultValue = null) => {
  const value = localStorage.getItem(`${localStorageHelper.prefix}${key}`);
  if (!value) return defaultValue;
  return value;
};

localStorageHelper.set = (key, value) => {
  if (value === null) {
    localStorageHelper.remove(key);
  } else {
    localStorage.setItem(`${localStorageHelper.prefix}${key}`, value);
  }
};

localStorageHelper.remove = (key) => {
  localStorage.removeItem(`${localStorageHelper.prefix}${key}`);
};

export default localStorageHelper;
