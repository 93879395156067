import styled from 'styled-components'

export const SubscriptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

export const SubscriptionCardEditButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: none;
  
  button {
    background: #FCE9B0;
    color: #181B1C;
  }

  &:hover {
    button {
      background: linear-gradient(0deg, rgba(24, 27, 28, 0.12), rgba(24, 27, 28, 0.12)), #FCE9B0;
      
    }
  }
`; 

export const SubscriptionCard = styled.div`
  position: relative;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 32px;
  width: calc(100% / 2 - 10px);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: ${props => props.active ? "rgba(253, 239, 196, 0.38)" : "inherit"};

  &:hover {
    background-color: rgba(253, 239, 196, 0.38);
    cursor: pointer;
  }

  &:hover ${SubscriptionCardEditButton} {
    display: block;
  }
`;

export const SubscriptionCardTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  word-break: break-word;

  &:hover {
    ${({ hasLink }) => hasLink && `
      cursor: pointer;
      text-decoration: underline;
    `}
  }
`;

export const SubscriptionCardDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212529;
`;

export const SubscriptionCardFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const SubscriptionCardEmail = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #878788;
`;

export const SubscriptionLogo = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;
