import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_TOOLS_ALL_ROLES_CHECKBOX_DATA,
  SET_TOOLS_ROLES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_ROLE_MODAL,
  CLOSE_DELETE_ROLES_MODAL,
  SHOW_DELETE_ROLES_MODAL,
  SET_TOOLS_ROLES_DATA,
  SET_TOOLS_PAGES_DATA,
  CLOSE_ROLE_OVERVIEW,
  SHOW_ROLE_OVERVIEW,
} from './actionTypes';

const setRolesData = (data) => ({ type: SET_TOOLS_ROLES_DATA, payload: data });

const setPagesData = (data) => ({ type: SET_TOOLS_PAGES_DATA, payload: data });

export const fetchToolsRolesData = (params: any) => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .get('tools/roles', { params })
    .then(({ data }) => dispatch(setRolesData(data)))
    .catch((e) => console.error(e))
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const fetchPagesData = () => (dispatch) => {
  request
    .get('tools/roles/options')
    .then(({ data }) => dispatch(setPagesData(data.pages)))
    .catch((e) => console.error(e));
};

export const setRolesCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_ROLES_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllRolesCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_ROLES_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setIsOpenAddNewRole = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_ROLE_MODAL, payload: isOpen };
};

export const showRoleOverview = (role) => {
  return { type: SHOW_ROLE_OVERVIEW, payload: { data: role } };
};

export const closeRoleOverview = () => {
  return { type: CLOSE_ROLE_OVERVIEW };
};

export const showDeleteRolesModal = (data: any[] | any) => {
  const deletableRoles = data?.length ? data.map((item) => item.roleId) : [data.roleId];
  return { type: SHOW_DELETE_ROLES_MODAL, payload: { deletableRoles } };
};

export const closeDeleteRolesModal = () => {
  return { type: CLOSE_DELETE_ROLES_MODAL };
};

export const updateRole = (data) => (dispatch, getState) => {
  dispatch(setIsLoadingData(true));

  const { id, name, pages } = data;
  const {
    rolesReducer: { overviewRolesData },
  } = getState();

  const pageIds = pages.map((page) => page.id);

  request
    .update(`tools/roles/${id}`, {
      name,
      pages: pageIds,
    })
    .then(() => {
      dispatch(showRoleOverview({ ...overviewRolesData, name, pages }));
      getErrorStatusMessage({
        status: 200,
        message: `Role successfully updated`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    })
    .finally(() => dispatch(setIsLoadingData(false)));
};
