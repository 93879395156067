import Yup from '../../utils/validation';

const validateMessages = {
  company: {
    required: 'Company cannot be empty'
  },
  team: {
    required: 'Team cannot be empty'
  },
  employer: {
    required: 'Employer cannot be empty'
  },
};

export const referralCodeGenerationSchema = Yup.object().shape({
  company: Yup
    .number()
    .required(validateMessages.company.required)
    .nullable(),
  team: Yup
    .number()
    .required(validateMessages.team.required)
    .nullable(),
  employer: Yup
    .number()
    .required(validateMessages.employer.required)
    .nullable(),
});
