import { connect } from 'react-redux';
import {
  closePossibleTripsForMerging,
  setDownDrawerTripStatusModal,
  openPossibleTripsForMerging,
  setOverviewTripStatusModal,
  fetchTripsForMergingData,
  setTableTripStatusModal,
  onChangePaymentStatus,
  onChangePaymentInvoice,
  setTripsCheckboxData,
  closeDeleteTripModal,
  deleteOtherTransport,
  openDeleteTripModal,
  deleteAccommodation,
  closeTripOverview,
  fetchOptionsData,
  openTripOverview,
  closeTripTicket,
  fetchTripTicket,
  openTripTicket,
  fetchTripsData,
  toggleFilters,
  closeFilters,
  deleteFlight,
} from '../../store/trips/actions';
import { updateFeedback } from '../../store/trips/api';
import Trips from './Trips.ui';

const mapStateToProps = ({ tripsReducer, currentUser, loadingReducer }) => {
  const {
    isPossibleTripsForMergingOpen,
    isDownDrawerStatusModal,
    isOverviewStatusModal,
    tableTripStatusModal,
    tripsForMergingData,
    checkboxesData,
    isOpenOverview,
    deletableTrips,
    isDeleteModal,
    isFiltersOpen,
    isOpenTicket,
    overviewData,
    tripOptions,
    totalItems,
    ticketData,
    tripsData,
  } = tripsReducer;

  return {
    isPossibleTripsForMergingOpen,
    isDownDrawerStatusModal,
    isOverviewStatusModal,
    tableTripStatusModal,
    tripsForMergingData,
    isOpenOverview,
    checkboxesData,
    deletableTrips,
    isLoadingData: loadingReducer.isLoadingData,
    isDeleteModal,
    isFiltersOpen,
    overviewData,
    isOpenTicket,
    tripOptions,
    ticketData,
    tripsData,
    userRole: currentUser?.currentUserData?.role?.name,
    total: totalItems,
  };
};

const mapDispatchToProps = {
  closePossibleTripsForMerging,
  setTableTripStatusModalData: setTableTripStatusModal,
  openPossibleTripsForMerging,
  setDownDrawerStatusModal: setDownDrawerTripStatusModal,
  setOverviewStatusModal: setOverviewTripStatusModal,
  onChangePaymentInvoice,
  onChangePaymentStatus,
  closeDeleteTripModal,
  fetchTripsForMerging: fetchTripsForMergingData,
  deleteOtherTransport,
  openDeleteTripModal,
  deleteAccommodation,
  closeTripOverview,
  openTripOverview,
  setCheckboxData: setTripsCheckboxData,
  fetchTripTicket,
  closeTripTicket,
  updateFeedback,
  openTripTicket,
  toggleFilters,
  fetchOptions: fetchOptionsData,
  closeFilters,
  deleteFlight,
  fetchTrips: fetchTripsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
