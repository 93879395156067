import Yup from '../../utils/validation';

const DECLINE_REASON_MIN_LENGTH = 2;
const DECLINE_REASON_MAX_LENGTH = 255;

const validateMessages = {
  declineReason: {
    required: 'Decline reason cannot be empty',
    min: `Decline reason cannot be less than ${DECLINE_REASON_MIN_LENGTH} characters`,
    max: `Decline reason cannot be longer than ${DECLINE_REASON_MAX_LENGTH} characters`,
  },
};

export const getInitialValues = () => ({
  declineReason: '',
});

export const initDeclineSchema = (isLastPart) => {
  if (isLastPart) {
    return Yup.object().shape({
      declineReason: Yup
        .string()
        .min(DECLINE_REASON_MIN_LENGTH, validateMessages.declineReason.min)
        .max(DECLINE_REASON_MAX_LENGTH, validateMessages.declineReason.max)
        .isInLatinCharacters()
        .required(validateMessages.declineReason.required),
    });
  }

  return Yup.object().shape({
    declineReason: Yup
      .string()
      .nullable(),
  });
};

