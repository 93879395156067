import AccountDetailsTab from '../../../components/SignUpTabs/AccountDetails';
import PasswordTab from '../../../components/SignUpTabs/Password';
import ProfileSettingsTab from '../../../components/SignUpTabs/ProfileSettings';
import TravelDocumentTab from '../../../components/SignUpTabs/TravelDocument';
import { FormTabType } from '../../../enums/FormTabType';

export const ActiveTab = ({ activeTab, options, ...props }) => {
  switch (activeTab) {
    case FormTabType.AccountDetails:
      return <AccountDetailsTab options={options} {...props} />;
    case FormTabType.ProfileSettings:
      return <ProfileSettingsTab options={options} {...props} />;
    case FormTabType.TravelDocument:
      return <TravelDocumentTab options={options} {...props} />;
    case FormTabType.ChangePassword:
      return <PasswordTab {...props} />;
    default:
      return null;
  }
};
