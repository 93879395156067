import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from '../ErrorBoundary';
import * as i18n from '../../utils/i18n';
import { AuthContext, getToken as getAuthToken, setToken as setAuthToken } from '../../models/auth';
import request from '../../models/request';
import { Routes } from '../Routes';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = ({
  isCurrentUserLoading,
  isAuthenticated,
  isCurrentUserLoaded,
  setCurrentUserLoading,
  setCurrentUserLoaded,
  setAppReady,
  setCurrentUser,
}) => {
  const [authToken] = useState(getAuthToken());

  useEffect(() => {
    if (isAuthenticated) {
      request.setAuthorized();
    }

    try {
      setAppReady(false);
      request.init();
      i18n.init();
      setAppReady(true);
    } catch (error) {
      console.error(error);
    }
  }, [setAppReady, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isCurrentUserLoaded && !isCurrentUserLoading) {
      (async () => {
        try {
          setCurrentUserLoading(true);
          setCurrentUserLoaded(false);

          const response = await request.get('user/me');

          if (!response) {
            throw new Error(response);
          }

          const currentUser = response.data.user;

          setCurrentUser(currentUser);
        } catch (err) {
          new Error(err);
        } finally {
          setCurrentUserLoading(false);
          setCurrentUserLoaded(true);
        }
      })();
    }
  }, [setCurrentUserLoaded, setCurrentUserLoading, isAuthenticated, isCurrentUserLoaded, isCurrentUserLoading, setCurrentUser]);

  return (
    <BrowserRouter>
      <Helmet>
        <title />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          authToken,
          setAuthToken,
        }}
      >
        <ErrorBoundary>
          <Routes />
          <ToastContainer />
        </ErrorBoundary>
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  isCurrentUserLoaded: PropTypes.bool,
  isCurrentUserLoading: PropTypes.bool,
  setCurrentUserLoading: PropTypes.func.isRequired,
  setCurrentUserLoaded: PropTypes.func.isRequired,
  setAppReady: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

App.defaultProps = {
  isCurrentUserLoading: false,
  isAuthenticated: false,
  isCurrentUserLoaded: false,
};

export default App;
