import { ITogglerWithCount } from './types';
import { TogglerWithCountStyled, PageFiltersCount } from './styles';
import Tooltip from '../Tooltip';

const TogglerWithCount: React.FC<ITogglerWithCount> = ({
  isActive,
  count,
  handleToggle,
  icon,
  label = 'filters',
  position = 'bottom',
}) => {
  const fullLabel = isActive ? `Collapse ${label}` : `Expand ${label}`;
  return (
    <Tooltip label={fullLabel} position={position}>
      <TogglerWithCountStyled isActive={isActive} onClick={handleToggle}>
        {icon}
        {!!count && <PageFiltersCount>{count}</PageFiltersCount>}
      </TogglerWithCountStyled>
    </Tooltip>
  );
};

export default TogglerWithCount;
