import Rating from '@mui/material/Rating';
import { ErrorMessage } from '../FormField/styled';

const StarRating = ({
  precision = 0.1,
  readOnly,
  error,
  rate,
  size = 'medium',
  onChange = () => {},
}: {
  precision?: number;
  readOnly?: boolean;
  error?: string | null;
  size?: 'small' | 'medium';
  rate: number;
  onChange?: (value: number) => void;
}) => {
  const handleOnChange = (event, value) => {
    onChange(value);
  };

  return (
    <>
      <Rating
        precision={precision}
        readOnly={!!readOnly}
        value={rate}
        size={size}
        onChange={handleOnChange}
      />
      {error && (
        <div style={{ position: 'relative', marginLeft: '5px' }}>
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </>
  );
};

export default StarRating;
