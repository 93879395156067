import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'Traveler',
    accessor: 'traveler',
    minWidth: 180,
  },
  {
    Header: 'Trip dates',
    accessor: 'tripDates',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Destination',
    accessor: 'destination',
    disableSortBy: true,
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Travel app experience',
    accessor: 'app',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Web app experience',
    accessor: 'webApp',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Flight experience',
    accessor: 'flight',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Accommodation experience',
    accessor: 'accommodation',
    minWidth: 180,
    height: 70,
  },

  {
    Header: 'Comments and Recommendations',
    accessor: 'comments',
    disableSortBy: true,
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Submitted by',
    accessor: 'submittedBy',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Submitted at',
    accessor: 'submittedAt',
    minWidth: 180,
    height: 70,
  },
];
