import {
  SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA,
  SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL,
  SET_TOOLS_EMPLOYERS_CHECKBOX_DATA,
  SET_TOOLS_ALL_COMPANIES_DATA,
  CLOSE_DELETE_EMPLOYERS_MODAL,
  SHOW_DELETE_EMPLOYERS_MODAL,
  SET_TOOLS_EMPLOYERS_DATA,
  CLOSE_EMPLOYER_OVERVIEW,
  SHOW_EMPLOYER_OVERVIEW,
} from './actionTypes';

const initState = {
  employers: [],
  totalEmployers: 0,
  isActiveAllEmployersCheckboxes: false,
  employersCheckboxesData: [],
  isOpenAddNewEmployer: false,
  employersCompanies: [],
  isDeleteEmployersModal: false,
  deletableEmployers: [],
  isOpenEmployerOverview: false,
  overviewEmployerData: null,
  isUpdatedEmployerData: false,
};

const employersReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TOOLS_EMPLOYERS_DATA:
      return {
        ...state,
        employers: action.payload.records,
        totalEmployers: action.payload.total,
      };

    case SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllEmployersCheckboxes: isActive,
        employersCheckboxesData: data,
      };
    }

    case SET_TOOLS_EMPLOYERS_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllEmployersCheckboxes: false,
          employersCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          employersCheckboxesData: [...state.employersCheckboxesData, data],
        };
      }

      return {
        ...state,
        employersCheckboxesData: state.employersCheckboxesData.filter((item) => item.employerId !== data.employerId),
        isActiveAllEmployersCheckboxes: false,
      };
    }

    case SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL:
      return {
        ...state,
        isOpenAddNewEmployer: action.payload,
      };

    case SET_TOOLS_ALL_COMPANIES_DATA:
      return {
        ...state,
        employersCompanies: action.payload.data,
      };

    case SHOW_DELETE_EMPLOYERS_MODAL:
      return {
        ...state,
        isDeleteEmployersModal: true,
        deletableEmployers: action.payload.deletableEmployers,
      };

    case CLOSE_DELETE_EMPLOYERS_MODAL:
      return {
        ...state,
        isDeleteEmployersModal: false,
        deletableEmployers: [],
      };

    case SHOW_EMPLOYER_OVERVIEW:
      return {
        ...state,
        isOpenEmployerOverview: true,
        overviewEmployerData: action.payload.data,
        employers: state.employers.map((employer) => ({
          ...employer,
          isSelected: employer.id === action.payload.data.id,
        })),
      };

    case CLOSE_EMPLOYER_OVERVIEW:
      return {
        ...state,
        isOpenEmployerOverview: false,
        overviewEmployerData: null,
        employers: state.employers.map((employer) => ({ ...employer, isSelected: false })),
      };

    case SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA:
      return {
        ...state,
        isUpdatedEmployerData: action.payload.isUpdated,
      };

    default:
      return state;
  }
};

export default employersReducer;
