import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITicketDrawerContent } from './types';
import { UserRoleType } from '../../enums/UserRoleTypes';
import { TripStatusType } from '../../enums/TripStatusType';
import { setFlightSelectedAirlines, setFlightSelectedAirports } from '../../store/trips/actions';
import { TicketWrapper } from './styles';
import {
  extractAirlinesAndAirportsFromFlightBooking,
  isFlightExternal,
} from './utils';
import { UserStatuses } from '../../enums/UserStatuses';
import TripExternalAttachmentActions from './TripExternalAttachmentActions';
import TripOtherTransportBooking from './TripOtherTransportBooking';
import TripAccommodationBooking from './TripAccommodationBooking';
import EditOtherTransportDialog from './EditOtherTransportDialog';
import EditAccommodationDialog from './EditAccommodationDialog';
import DeleteTripPartDialog from '../DeleteTripPartDialog';
import TripFlightBooking from './TripFlightBooking';
import EditFlightDialog from './EditFlightDialog';

const TicketDrawerContent: React.FC<ITicketDrawerContent> = ({
  travelDocumentTypes,
  isLoading,
  countries,
  userRole,
  data,
  handleOpenAttachOtherTransportModal,
  handleOpenAttachAccommodationModal,
  handleUpdateExternalAccommodation,
  handleChangeAccommodationPrice,
  handleOpenAttachFlightModal,
  handleUpdateOtherTransport,
  deleteOtherTransport,
  deleteAccommodation,
  handleUpdateFlight,
  handleRefetchData,
  deleteFlight,
}) => {
  const [isConfirmPopupOpen, seIisConfirmPopupOpen] = useState(false);
  const [editedFlightId, setEditedFlightId] = useState(null);
  const [editedAccommodationId, setEditedAccommodationId] = useState(null);
  const [editedOtherTransportId, setEditedOtherTransportId] = useState(null);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
  const [flightToDelete, setFlightToDelete] = useState(null);
  const [accommodationToDelete, setAccommodationItemToDelete] = useState(null);
  const [otherTransportToDelete, setOtherTransportToDelete] = useState(null);
  const editedFlight = useMemo(() => data.flights.find((flight) => flight.id === editedFlightId), [editedFlightId, data.flights])
  const editedAccommodation = useMemo(() => data.accommodations.find((accommodation) => accommodation.id === editedAccommodationId), [editedAccommodationId, data.accommodations])
  const editedOtherTransport = useMemo(() => data.otherTransports.find((otherTransport) => otherTransport.id === editedOtherTransportId), [editedOtherTransportId, data.otherTransports])
  const hasEditAccess = useMemo(() => userRole === UserRoleType.Travelops, [userRole]);
  const hasAccessToSensitiveData = useMemo(() => userRole === UserRoleType.Travelops, [userRole]);
  const isApproved = useMemo(() => data.status === TripStatusType.APPROVED || data.status === TripStatusType.PURCHASED, [data.status]);
  const isTravelerExternal = useMemo(() =>  data?.userType === UserStatuses.External, [data?.userType]);
  const paymentRequired = useMemo(() =>  !!data?.purpose?.paymentRequired, [data?.purpose?.paymentRequired]);

  const dispatch = useDispatch();

  const handleAccommodationConfirmDeleteOpen = (accommodation) => {
    setFlightToDelete(null);
    setOtherTransportToDelete(null);
    seIisConfirmPopupOpen(true);
    setAccommodationItemToDelete(accommodation);
  };

  const handleFlightConfirmDeleteOpen = (flight) => {
    setAccommodationItemToDelete(null);
    setOtherTransportToDelete(null);
    seIisConfirmPopupOpen(true);
    setFlightToDelete(flight);      
  };

  const handleConfirmDeleteClose = () => {
    seIisConfirmPopupOpen(false);
    setAccommodationItemToDelete(null);
    setFlightToDelete(null);
    setOtherTransportToDelete(null);
  };

  const deleteFinish = () => {
    setIsConfirmationLoading(false);
    seIisConfirmPopupOpen(false);
    setAccommodationItemToDelete(null);
    setFlightToDelete(null);
    setOtherTransportToDelete(null);
    handleRefetchData();
  };

  const handleConfirmDelete = (declineReason: string | null) => {
    setIsConfirmationLoading(true);

    if (flightToDelete) {
      deleteFlight(flightToDelete, declineReason, deleteFinish);
    } else if(accommodationToDelete) {
      deleteAccommodation(accommodationToDelete, declineReason, deleteFinish);
    } else {
      deleteOtherTransport(otherTransportToDelete, declineReason, deleteFinish);
    }
  };

  const handleEditFlight = (flightId) => {
    setEditedFlightId(flightId);
    const { airlines, airports } = extractAirlinesAndAirportsFromFlightBooking(flightId, data.flights);
    dispatch(setFlightSelectedAirlines(airlines))
    dispatch(setFlightSelectedAirports(airports))
  };

  const handleEditExternalAccommodation = async (values) => {
    await handleUpdateExternalAccommodation(values, editedAccommodationId);
    setEditedAccommodationId(null);
  };

  const handleFlightEdit = async (values) => {
    await handleUpdateFlight(values, isFlightExternal(editedFlight), editedFlightId);
    setEditedFlightId(null);
  };

  const handleEditOtherTransport = async (values) => {
    await handleUpdateOtherTransport(values, editedOtherTransportId);
    setEditedOtherTransportId(null);
  };

  const handleOtherTransportConfirmDeleteOpen = (otherTransport) => {
    setAccommodationItemToDelete(null);
    setFlightToDelete(null);
    seIisConfirmPopupOpen(true);
    setOtherTransportToDelete(otherTransport);
  };

  useEffect(() => {
    setEditedFlightId(null);
  }, [data.id])

  return (
    <TicketWrapper>      
      {/* Flights */}
      {data.flights.map((flight) => (
        <TripFlightBooking
          hasAccessToSensitiveData={hasAccessToSensitiveData}
          travelDocumentTypes={travelDocumentTypes}
          hasEditAccess={hasEditAccess}
          countries={countries}
          flight={flight}
          handleDelete={handleFlightConfirmDeleteOpen}
          handleEdit={handleEditFlight}
          key={flight.id}
        />
      ))}
    
      {/* Accommodations */}
      {data.accommodations.map((accommodation) => (
        <TripAccommodationBooking
          accommodation={accommodation}
          hasEditAccess={hasEditAccess}
          isApproved={isApproved}
          key={accommodation.id}
          handleChangePrice={handleChangeAccommodationPrice}
          handleDelete={handleAccommodationConfirmDeleteOpen}
          handleEdit={setEditedAccommodationId}
        />
      ))}

      {/* Other transports */}
      {data.otherTransports.map((otherTransport) => (
        <TripOtherTransportBooking
          otherTransport={otherTransport}
          hasEditAccess={hasEditAccess}
          handleDelete={handleOtherTransportConfirmDeleteOpen}
          handleEdit={setEditedOtherTransportId}
          key={otherTransport.id}
        />
      ))}

      {/* External attachment actions */}
      {!isTravelerExternal && !paymentRequired && (
        <TripExternalAttachmentActions
          handleOpenAttachOtherTransportModal={handleOpenAttachOtherTransportModal}
          handleOpenAttachAccommodationModal={handleOpenAttachAccommodationModal}
          handleOpenAttachFlightModal={handleOpenAttachFlightModal}
        />
      )}

      {/* Edit flight dialog */}
      {!!editedFlightId && (
        <EditFlightDialog
          editedFlight={editedFlight}
          isLoading={isLoading}
          onSubmit={handleFlightEdit}
          onClose={() => setEditedFlightId(null)}
        />
      )}
      
      {/* Edit accommodation dialog */}
      {!!editedAccommodationId && (
        <EditAccommodationDialog
          editedAccommodation={editedAccommodation}
          isLoading={isLoading}
          onSubmit={handleEditExternalAccommodation}
          onClose={() => setEditedAccommodationId(null)}
        />
      )}

      {/* Edit other transport dialog */}
      {!!editedOtherTransportId && (
        <EditOtherTransportDialog
          editedOtherTransport={editedOtherTransport}
          isLoading={isLoading}
          onSubmit={handleEditOtherTransport}
          onClose={() => setEditedOtherTransportId(null)}
        />
      )}

      {/* Delete confirmation dialog */}
      {isConfirmPopupOpen && (
        <DeleteTripPartDialog
          otherTransportToDelete={otherTransportToDelete}
          isConfirmationLoading={isConfirmationLoading}
          accommodationToDelete={accommodationToDelete}
          flightToDelete={flightToDelete}
          trip={data}
          handleConfirmDeleteClose={handleConfirmDeleteClose}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}
    </TicketWrapper>
  );
};

export default TicketDrawerContent;
