import { SET_IS_LOADING_DATA } from './actionTypes';

const initState = {
  isLoadingData: false,
};

const loadingReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingData: action.payload.isLoading
      };

    default:
      return state;
  }
};

export default loadingReducer;
