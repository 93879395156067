import { useMemo } from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import Yup from '../../utils/validation';
import isEmpty from 'lodash/isEmpty';
import { TextInput, Button } from '@heathmont/moon-core';
import { IChangeAccommodationPriceDialogContent } from './types';
import FormField from '../FormField';
import InfoBlock from '../InfoBlock'; 
import {
  AccommodationReferenceAndPrice,
  AccommodationOverviewWrapper,
  AccommodationInfoBlock,
  AccommodationAddress,
  AccommodationDates,
  ButtonsWrapper,
} from './styles';

const getInitialValues = (accommodation) => {  
  return {
    accommodationId: accommodation?.id || undefined,
    tripId: accommodation?.tripId || undefined,
    price: accommodation?.price || 0,
  };
};

const schema = Yup.object().shape({
  price: Yup
    .number()
    .required('Price can not be empty')
    .min(0, 'Price can not be negative'),
});

const ChangeAccommodationPriceDialogContent: React.FC<IChangeAccommodationPriceDialogContent> = ({
  accommodation,
  loading,
  onSubmit,
  onCancel,
}) => {
  const initialValues = useMemo(() => getInitialValues(accommodation), [accommodation.id]);

  const formatDate = (date) => {
    return moment(date).format('DD MMM YYYY');
  };

  return (
    <>
      <InfoBlock
        text={`
          Company's accommodation margin will be added automatically to the price.
          If it's a multi-guests booking, please divide the price by the number of guests. 
          Accommodation's price will not change during the next synchronization. 
        `}
      />
      <AccommodationOverviewWrapper>
        <AccommodationInfoBlock>
          <AccommodationReferenceAndPrice>
            <strong>[{accommodation.reference}]</strong> {accommodation.name} — <strong>{accommodation.price} EUR</strong>
          </AccommodationReferenceAndPrice>
          <AccommodationAddress target="_blank" href={`https://maps.google.com/?q=${accommodation.latitude},${accommodation.longitude}`}>
            {accommodation.address}
          </AccommodationAddress>
          <AccommodationDates>
            {formatDate(accommodation.checkIn)} → {formatDate(accommodation.checkOut)}
          </AccommodationDates>
        </AccommodationInfoBlock>
      </AccommodationOverviewWrapper>
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, values, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <FormField
                fieldName="price"
                component={TextInput}
                inputSize="xlarge"
                fieldId="price"
                errors={errors}
                value={values.price}
                label="Price without margin"
                type="number"
                onChange={(e) => setFieldValue('price', e.target.value)}
              />
              <ButtonsWrapper>
                <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                <Button disabled={!isEmpty(errors) || loading} variant="primary" type="submit">Confirm</Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangeAccommodationPriceDialogContent;
