export enum EXPENSES_TYPE {
  PENDING = 'pending',
  UNDER_CONSIDERATION = 'under_consideration',
  VERIFYING = 'verifying',
  CHECKED = 'checked',
  TO_BE_PAID = 'to_be_paid',
  REFUNDED = 'refunded',
}

export enum TRIPS_EXPENSES_TYPES {
  DAILY_ALLOWANCE = 'daily_allowance',
  BUSINESS_DINNER = 'business_dinner',
  TRANSPORT = 'transport',
  OTHER = 'other',
}

export const tripsExpensesStatusNames = {
  [TRIPS_EXPENSES_TYPES.DAILY_ALLOWANCE]: 'DAILY ALLOWANCE',
  [TRIPS_EXPENSES_TYPES.BUSINESS_DINNER]: 'BUSINESS DINNER',
  [TRIPS_EXPENSES_TYPES.TRANSPORT]: 'TRANSPORT',
  [TRIPS_EXPENSES_TYPES.OTHER]: 'OTHER',
};

export const tripsExpensesTypeNames = {
  [EXPENSES_TYPE.PENDING]: 'PENDING',
  [EXPENSES_TYPE.UNDER_CONSIDERATION]: 'UNDER CONSIDERATION',
  [EXPENSES_TYPE.VERIFYING]: 'VERIFYING',
  [EXPENSES_TYPE.CHECKED]: 'CHECKED',
  [EXPENSES_TYPE.TO_BE_PAID]: 'TO BE PAID',
  [EXPENSES_TYPE.REFUNDED]: 'REFUNDED',
};
