import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 16px;
  width: 520px;
  min-height: 264px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  & p {
    display: flex;
    align-items: center;
    line-height: 24px;
  }
`;

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const Footer = styled.div`
  padding: 16px 24px;
`;

export const FormFieldWrapper = styled.div`
  margin: 0 0 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
`;

export const Background = styled.div`
  background: rgba(24, 27, 28, 0.4);
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

export const DialogWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
