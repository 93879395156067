import { useState, useCallback } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartBarChart,
  Legend as RechartLegend,
} from 'recharts';
import { IBarChart } from './types';
import { BottomChartSign, Header, HeaderWrapper, LeftChartSign, Period, RightChartSign, Wrapper } from './styles';
import Legend from '../Components/Legend';
import { Tooltip } from '../Components/Tooltip';
import { getPeriodName } from '../suggestions';

const BarChart: React.FC<IBarChart> = ({ chartData, options, periodType, periodRange }) => {
  const [isHovered, setIsHoveredItem] = useState(false);

  const setIsHovered = useCallback(() => {
    setIsHoveredItem((prev) => !prev);
  }, []);

  const initialActiveOptions = options.filter(({ isActive }) => isActive).map(({ dataKey }) => dataKey);

  const [activeOptions, setActiveOptions] = useState(initialActiveOptions);

  const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={15} y={0} dy={16} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYLeftAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-20} y={-12} dy={16} textAnchor="start" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYRightAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={35} y={-12} dy={16} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const handleSelectorChange = (dataKey: string, isActive: boolean) => {
    const newActiveOptions = isActive
      ? [...activeOptions, dataKey]
      : activeOptions.filter((option) => option !== dataKey);

    setActiveOptions(newActiveOptions);
  };

  const displayLines = useCallback(
    () =>
      activeOptions.map((option) => {
        const activeOption = options.find(({ dataKey }) => dataKey === option);

        if (!activeOption) {
          return null;
        }

        return (
          <Bar
            key={activeOption.dataKey}
            fill={activeOption.color}
            dataKey={activeOption.dataKey}
            yAxisId={activeOption.yAxisId}
          />
        );
      }),
    [activeOptions]
  );

  return (
    <Wrapper onMouseEnter={setIsHovered} onMouseLeave={setIsHovered}>
      <HeaderWrapper>
        <Header>Trip statistics</Header>
        <Period>{getPeriodName(periodType, periodRange)}</Period>
      </HeaderWrapper>

      {isHovered && (
        <>
          <LeftChartSign>Count</LeftChartSign>
          <RightChartSign>Total cost (EUR)</RightChartSign>
          <BottomChartSign>Time (days)</BottomChartSign>
        </>
      )}

      <ResponsiveContainer height="80%" minWidth={645}>
        <RechartsBarChart
          data={chartData}
          margin={{
            top: 20,
            right: 50,
            left: -30,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" height={60} tick={<CustomizedXAxisTick />} />
          <YAxis yAxisId="left" orientation="left" tick={<CustomizedYLeftAxisTick />} />
          <YAxis yAxisId="right" orientation="right" tick={<CustomizedYRightAxisTick />} />
          <RechartBarChart cursor={{ fill: '#E0E0E0', fillOpacity: 0.3 }} content={<Tooltip isBarChart />} />

          <RechartLegend
            verticalAlign="bottom"
            align="center"
            content={<Legend onChange={handleSelectorChange} options={options} activeOptions={activeOptions} />}
          />

          {displayLines()}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default BarChart;
