import { Line, CurrentStepLine } from './styles';

const MobileSignUpStepper = ({ activeStep, steps, additionalStyles }) => {
  return (
    <>
      <Line styles={additionalStyles} />
      <CurrentStepLine styles={additionalStyles} steps={steps} activeStep={activeStep} />
    </>
  );
};

export default MobileSignUpStepper;
