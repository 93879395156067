import { Heading, Button } from '@heathmont/moon-components';
import { Link } from 'react-router-dom';
import { Footer, Text } from '@heathmont/moon-core';
import { GenericClose } from '@heathmont/moon-icons';
import { HeathmontLogo } from '../HeathmontLogo';
import { MessageContainer, FormContent, TertButtonWrapper } from '../../styled';
import { IErrorMessage } from './types';

const ErrorMessage: React.FC<IErrorMessage> = ({
  withReturnBtn,
  errorMessage,
  heading,
  signupUrlValidity,
  setErrorMessage,
}) => (
  <MessageContainer>
    <FormContent>
      <HeathmontLogo />
      <GenericClose style={{ margin: '0 auto 10px', display: 'block' }} fontSize="5rem" color="dodoria.100" />
      <Heading style={{ marginBottom: 20, textAlign: 'center' }} size={32}>
        {heading || (!signupUrlValidity ? 'Registration URL is not valid' : 'Registration failed')}
      </Heading>
      <Text style={{ ...(!signupUrlValidity && { marginBottom: 40 }), textAlign: 'center' }}>{errorMessage}</Text>
    </FormContent>
    {signupUrlValidity && (
      <Footer
        primButton={
          <Button type="button" fullWidth variant="primary" size="small" onClick={() => setErrorMessage('')}>
            Retry
          </Button>
        }
        isDivider={true}
      />
    )}
    {withReturnBtn && (
      <Footer
        primButton={
          <TertButtonWrapper>
            <Link to="/signin">
              <Button type="button" fullWidth variant="tertiary" size="small">
                Sign in
              </Button>
            </Link>
          </TertButtonWrapper>
        }
        isDivider={true}
      />
    )}
  </MessageContainer>
);

export default ErrorMessage;
