import useIsMobile from '../../hooks/useIsMobile';
import { EDIT_PROFILE_TABS, TAB_NAMES } from '../Routes/suggestions';
import { TabButtonEdit, TabButtonWrapper, TabWrapper } from './styled';

const EditProfileFormTabs = ({ activeTab, setActiveTab }) => {
  const isActiveTab = (activeTab, newTab) => activeTab === newTab;
  const isMobile = useIsMobile();

  return (
    <TabWrapper isMobile={isMobile}>
      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.DETAILS)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.DETAILS)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.DETAILS)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.DETAILS]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.SETTINGS)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.SETTINGS)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.SETTINGS)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.SETTINGS]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.DOCUMENT)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.DOCUMENT)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.DOCUMENT)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.DOCUMENT]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.CRYPTO_WALLET)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.CRYPTO_WALLET)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.CRYPTO_WALLET)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.CRYPTO_WALLET]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.LOYALTY_PROGRAMS)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.LOYALTY_PROGRAMS)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.LOYALTY_PROGRAMS)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.LOYALTY_PROGRAMS]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.FAVORITE_HOTELS)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.FAVORITE_HOTELS)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.FAVORITE_HOTELS)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.FAVORITE_HOTELS]}
        </TabButtonEdit>
      </TabButtonWrapper>

      <TabButtonWrapper
        isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.SECURITY)}
        onClick={() => setActiveTab(EDIT_PROFILE_TABS.SECURITY)}
      >
        <TabButtonEdit isMobile={isMobile} isActive={isActiveTab(activeTab, EDIT_PROFILE_TABS.SECURITY)}>
          {TAB_NAMES[EDIT_PROFILE_TABS.SECURITY]}
        </TabButtonEdit>
      </TabButtonWrapper>
    </TabWrapper>
  );
};

export default EditProfileFormTabs;
