export default {
  200: {
    message: 'Your company workspace is already connected. TravelApp has access to all necessary permissions.',
    title: 'Already connected!',
  },
  201: {
    message: 'Permissions have been updated. TravelApp now has access to all necessary permissions in your workspace.',
    title: 'Permissions updated!',
  },
  400: {
    message: 'TravelApp cannot access your workspace. Please contact our administrator.',
    title: 'Failed to connect workspace!',
  },
  401: {
    message: 'TravelApp cannot access your workspace. Please contact our administrator.',
    title: 'Failed to update permissions!',
  },
};