import React, { useMemo, useState } from 'react';
import { Button, Text } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { DeleteButton, ItemWrapper, Wrapper } from './styles';
import DailyAllowanceCustomRuleForm from '../DailyAllowanceCustomRuleForm';

function DailyAllowanceCustomRuleContent({
  handleEdit,
  rule,
  editedId,
  allowanceId,
  rulesOptions,

}: {
  handleEdit: (id) => void;
  rule: any;
  editedId: any;
  allowanceId: number;
  rulesOptions: any;
}) {
  const isEdited = useMemo(() => editedId === rule.id, [editedId]);

  const conditionTitle = useMemo(
    () => rulesOptions?.conditions?.find(({ id }) => id === rule.condition)?.title || '',
    [rule.condition, rulesOptions?.conditions]
  );
  const actionTitle = useMemo(
    () => rulesOptions?.actions?.find(({ id }) => id === rule.action)?.title || '',
    [rule.action, rulesOptions?.actions]
  );

  const handleEditOpen = () => {
    handleEdit(rule.id);
  };
  const handleEditClose = () => {
    handleEdit(null);
  };
  return (
    <>
      {isEdited ? (
        <DailyAllowanceCustomRuleForm
          rulesOptions={rulesOptions}
          handleCancel={handleEditClose}
          ruleData={rule}
          allowanceId={allowanceId}
        />
      ) : (
        <Wrapper key={rule.id}>
          <ItemWrapper>
            <div>
              <Text size={14}>
                WHEN{` ${conditionTitle}`} - <strong>{rule.conditionValue}</strong>
              </Text>
              <Text size={14}>
                THEN{` ${actionTitle}`} - <strong>{rule.actionValue}</strong>
              </Text>
            </div>
            <DeleteButton>
              <Button
                iconOnly={<GenericEdit fontSize="1.5rem" />}
                variant="ghost"
                type="button"
                disabled={false}
                onClick={handleEditOpen}
              />
            </DeleteButton>
          </ItemWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default DailyAllowanceCustomRuleContent;
