import { useCallback, useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { isEmpty, get } from 'lodash';
import { ArrowsRefreshRound, MailSend } from '@heathmont/moon-icons-tw';
import { GenericEdit } from '@heathmont/moon-icons';
import { Text, TextInput, Button, Checkbox } from '@heathmont/moon-core';
import request from '../../../models/request';
import {
  formatBusinessVerticalLabel,
  getUserStatusLabel,
  formatDateAndTime,
  getUsersRoleLabel,
  getRoleLabelById,
  formatManager,
  getLabels
} from '../../../utils/tableHelpers';
import { checkOnIsEqual, N_A } from '../../../utils/commonFunctions';
import DynamicFormFields from '../../../components/DynamicFormFields';
import EllipsisText from '../../../components/EllipsisText';
import CustomSelect from '../../../components/CustomSelect';
import DatePicker from '../../../components/DatePicker';
import FormField from '../../../components/FormField';
import Accordion from '../../../components/Accordion';
import InfoBlock from '../../../components/InfoBlock';
import validationSchema from '../../../utils/validation-schema';
import {
  userOverviewDocumentSchema,
  userOverviewPassportSchema,
  userFavoriteHotelsSchema,
  userLoyaltyNumbersSchema,
  userCryptoWalletsSchema,
  userOverviewSchema,
  passengerSchema,
} from './schema';
import { IUserDrawerContent } from './types';
import {
  ConfirmDialogBodyWrapper,
  OverviewItemsWrapper,
  GroupInputWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from './styles';
import { UserRoleType } from '../../../enums/UserRoleTypes';
import { UserStatuses } from '../../../enums/UserStatuses';
import SelectWithExternalDataSource from '../../../components/SelectWithExternalDataSource';
import CopyToClipboard from '../../../components/CopyToClipboard';
import Dialog from '../../../components/Dialog';
import { TeamTypes } from '../../../enums/TeamTypes';
import { EmployerTypes } from '../../../enums/EmployerTypes';
import { 
  filterItemsWithBusinessVerticalByVerticalId,
  filterItemsWithVerticalsByVerticalObjectId,
  filterItemsWithBusinessVertical,
  filterBusinessVerticalsByType,
} from '../../../utils/filters';
import { BUSINESS_VERTICAl_TYPES } from '../../../constants';


const getInitialFormValues = (data?: any) => ({
  isSkippedManagerNotifications: !!data?.isSkippedManagerNotifications,
  isSkippedManagerApproval: !!data?.isSkippedManagerApproval,
  businessVerticalId: data?.businessVertical?.id,
  employmentType: data?.employmentType?.id,
  passengerId: data?.passengerId ?? '',
  employerId: isTheSameBusinessVerticalWithEmployer(data?.businessVertical, data?.employer) ? data?.employer?.id : null,
  assistants: getAssistantsWithTheSameUserBusinessVertical(data?.businessVertical, data?.assistants),
  balancedId: data?.balancedId ?? '',
  firstname: data?.firstname ?? '',
  lastname: data?.lastname ?? '',
  manager: isTheSameBusinessVerticalWithManager(data?.businessVertical, data?.manager) ? data?.manager?.id : null,
  comment: data?.comment ?? '',
  teamId: isTheSameBusinessVerticalWithTeam(data?.businessVertical, data?.team) ? data?.team?.id : undefined,
  userId: data?.id ?? '',
  jiraId: data?.jiraId ?? '',
  role: data?.role?.id,
});

const getAssistantsWithTheSameUserBusinessVertical = (userBusinessVertical, assistants) => { 
  return assistants?.filter(assistant => userBusinessVertical?.id && userBusinessVertical.id === assistant?.businessVerticalId)
    .map(assistant => assistant?.id);
};

const isTheSameBusinessVerticalWithManager = (userBusinessVertical, manager) => { 
  return userBusinessVertical?.id && userBusinessVertical?.id === manager?.businessVerticalId;
};

const isTheSameBusinessVerticalWithEmployer = (userBusinessVertical, employer) => { 
  return employer?.businessVerticals?.some(businessVertical => userBusinessVertical?.id && userBusinessVertical?.id === businessVertical?.id);
};

const isTheSameBusinessVerticalWithTeam = (userBusinessVertical, team) => { 
  return userBusinessVertical?.id && userBusinessVertical?.id === team?.businessVerticalId;
};

const getInitialPassengerValues = (passenger?: any) => ({
  firstname: passenger?.firstName ?? '',
  lastname: passenger?.lastName ?? '',
  passenger_email: passenger?.email ?? '',
  passenger_mobile_number: passenger?.phone ?? '',
  birthdate: moment.utc(passenger?.birthDate).format('MMM DD, YYYY') ?? '',
  gender: passenger?.isFemale ?? '',
  title: passenger?.title?.id ?? '',
});

const getInitialPassportValues = (data?: any) => ({
  document_number: data?.userDocument?.number ?? '',
  document_valid: moment.utc(data?.userDocument?.expiredDate).format('MMM DD, YYYY') ?? '',
  country: data?.userDocument?.country?.id ?? '',
});

const getInitialDocumentValues = (data?: any) => ({
  document_number: data?.userDocument?.number ?? '',
  document_valid: moment.utc(data?.userDocument?.expiredDate).format('MMM DD, YYYY') ?? '',
  country: data?.userDocument?.country?.id ?? '',
  document_type: data?.userDocument?.type?.id ?? '',
});

const getInitialLoyaltyNumbersValues = (data?: any) => ({
  loyaltyNumbers: data?.loyaltyNumbers?.length
    ? data.loyaltyNumbers.map(loyaltyNumber => ({
        id: loyaltyNumber.id,
        number: loyaltyNumber.number,
        airline: loyaltyNumber.airline.id,
      }))
    : [],
});

const getInitialFavoriteHotels = (data?: any) => ({
  favoriteHotels: data?.favoriteHotels?.length
    ? data.favoriteHotels.map(favoriteHotel => ({
        regionId: favoriteHotel?.hotel?.region?.id,
        hotelId: favoriteHotel?.hotel?.id,
        userId: favoriteHotel?.user?.id,
        id: uuidv4(),
      }))
    : [],
});

const getInitialCryptoWallets = (data?: any) => ({
  cryptoWallets: data?.cryptoWallets?.length
    ? data.cryptoWallets.map(cryptoWallet => ({
        id: cryptoWallet.id,
        address: cryptoWallet.address,
        cryptoCurrencyId: cryptoWallet.cryptoCurrency.id,
      }))
    : [],
});

const UserDrawerContent: React.FC<IUserDrawerContent> = ({
  availableForCryptoWalletCurrencies,
  isResendActivationLinkLoading,
  travelDocumentTypes,
  businessVerticals,
  companyEmployees,
  employmentTypes,
  currentUserData,
  employers,
  countries,
  managers,
  genders,
  titles,
  teams,
  roles,
  data,
  handleResendActivationLink,
  updateUserLoyaltyNumbers,
  updateUserFavoriteHotels,
  updateUserCryptoWallets,
  handleSyncUpdates,
  updatePassenger,
  updatePassport,
  updateDocument,
  updateDetails,
  inviteUser,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);
  const [isEditFormPassengerData, setIsEditFormPassengerData] = useState(false);
  const [isEditFormPassportData, setIsEditPassportData] = useState(false);
  const [isEditLoyaltyNumbers, setIsEditLoyaltyNumbers] = useState(false);
  const [isEditFavoriteHotels, setIsEditFavoriteHotels] = useState(false);
  const [isEditCryptoWallets, setIsEditCryptoWallets] = useState(false);
  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);
  const [employersByBusinessVertical, setEmployersByBusinessVertical] = useState([]);
  const [managersByBusinessVertical, setManagersByBusinessVertical] = useState([]);
  const [teamsByBusinessVertical, setTeamsByBusinessVertical] = useState([]);
  const [companyEmployeesByBusinessVertical, setCompanyEmployeesByBusinessVertical] = useState([]);
  
  const assignVerticalCompanyEmployees = (companyEmployees, businessVerticalId) => {
    setCompanyEmployeesByBusinessVertical(filterItemsWithBusinessVertical(companyEmployees, businessVerticalId));
  };

  const assignVerticalEmployers = (employers, businessVerticalId) => {
    setEmployersByBusinessVertical(filterItemsWithVerticalsByVerticalObjectId(employers, businessVerticalId));
  };

  const assignVerticalManagers = (managers, businessVerticalId) => {
    setManagersByBusinessVertical(filterItemsWithBusinessVertical(managers, businessVerticalId));
  };

  const assignVerticalTeams = (teams, businessVerticalId) => {
    setTeamsByBusinessVertical(filterItemsWithBusinessVerticalByVerticalId(teams, businessVerticalId));
  };

  const assignBusinessVerticalsByType = (businessVerticals, type) => {
    setBusinessVerticalsByType(filterBusinessVerticalsByType(businessVerticals, type));
  };

  const getBusinessVerticalType = (isUserExternal: boolean): string => {
    return isUserExternal ? BUSINESS_VERTICAl_TYPES.EXTERNAL : BUSINESS_VERTICAl_TYPES.INTERNAL
  };

  useEffect(() => {
    assignBusinessVerticalsByType(businessVerticals, isUserExternal ? BUSINESS_VERTICAl_TYPES.EXTERNAL : BUSINESS_VERTICAl_TYPES.INTERNAL );
    assignVerticalEmployers(employers, data?.businessVertical?.id);
    assignVerticalManagers(managers, data?.businessVertical?.id);
    assignVerticalTeams(teams, data?.businessVertical?.id);
    assignVerticalCompanyEmployees(companyEmployees, data?.businessVertical?.id);
  }, [data?.id]);

  const initialFormValues = useMemo(() => getInitialFormValues(data), [data]);
  const initialPassportValues = useMemo(() => getInitialPassportValues(data), [data]);
  const initialPassengerValues = useMemo(() => getInitialPassengerValues(data?.passenger), [data?.passenger]);
  const initialLoyaltyNumbersValues = useMemo(() => getInitialLoyaltyNumbersValues(data), [data]);
  const initialFavoriteHotelsValues = useMemo(() => getInitialFavoriteHotels(data), [data]);
  const initialCryptoWalletsValues = useMemo(() => getInitialCryptoWallets(data), [data]);
  const isCurrentUser = useMemo(() => currentUserData?.user?.id === data?.id, [currentUserData, data]);
  const isUserRegistered = useMemo(() => data?.status === UserStatuses.Registered, [data]);
  const isUserExternal = useMemo(() => data?.status === UserStatuses.External, [data]);
  const initialDocumentValues = useMemo(() => getInitialDocumentValues(data), [data]);
  const [isOpenedWarningAboutSkippingmanagerApproval, setOpenedWarningAboutSkippingManagerApproval] = useState(false);
  const businessVerticalType = useMemo(() => getBusinessVerticalType(isUserExternal), [isUserExternal]);
  const doesUserAndTeamVerticalMatch = useMemo(() => data?.businessVertical?.id && data?.businessVertical?.id === data?.team?.businessVerticalId, [data]);
  const doesUserAndEmployerVerticalMatch = useMemo(() => {
    const [employer] = filterItemsWithVerticalsByVerticalObjectId([data?.employer],  data?.businessVertical?.id);
    return !!employer;
  }, [data]);
  const doesUserAndManagerVerticalMatch = useMemo(() => {
    if (!data?.manager?.id) {
      return true;
    }

    return data?.businessVertical?.id && data?.businessVertical?.id === data?.manager?.businessVerticalId;
  }, [data]);
  const doesUserAndAssistantsVerticalMatch = useMemo(() => {
    if (!data?.assistants?.length) {
      return true;
    }

    return data?.businessVertical?.id && data?.assistants.every(assitant => assitant?.businessVerticalId === data?.businessVertical?.id);
  }, [data]);

  const isManager = (role) => {
    const managerRoleData = roles.find(({ value }) => value === role);
    return managerRoleData?.title === UserRoleType.Manager;
  };

  const handleSubmitFormData = useCallback((formData) => {
    updateDetails(data, formData, setIsEditFormData);
  }, [data.id]);

  const handleSubmitPassengerData = useCallback((formData) => {
    formData.birthdate = moment(formData.birthdate).format('YYYY-MM-DD');
    updatePassenger(data, formData, setIsEditFormPassengerData);
  }, [data.id]);

  const handleSubmitPassportData = useCallback((formData) => {
    formData.document_valid = moment(formData.document_valid).format('YYYY-MM-DD');
    updatePassport(data, formData, setIsEditPassportData);
  }, [data.id]);

  const handleSubmitDocumentData = useCallback((formData) => {
    formData.document_valid = moment(formData.document_valid).format('YYYY-MM-DD');
    updateDocument(data, formData, setIsEditPassportData);
  }, [data.id]);

  const handleSubmitLoyaltyNumbers = useCallback((formData) => {
    updateUserLoyaltyNumbers(data, formData, setIsEditLoyaltyNumbers)
  }, [data.id]);

  const handleSubmitFavoriteHotels = useCallback((formData) => {
    updateUserFavoriteHotels(data.id, formData, setIsEditFavoriteHotels)
  }, [data.id]);

  const handleSubmitCryptoWallets = useCallback((formData, resetForm) => {
    updateUserCryptoWallets({ userId: data.id, data: formData, setIsEditForm: setIsEditCryptoWallets, resetForm });
  }, [data.id]);

  const handleUpdateRole = (value, setFieldValue) => {
    setFieldValue('role', value);
    setFieldValue('assistants', []);
    setFieldValue('isSkippedManagerNotifications', false);
  };

  const handleInviteUser = () => {
    inviteUser({
      businessVertical: data?.businessVertical?.id,
      firstName: data?.firstname,
      lastName: data?.lastname,
      employer: data?.employer?.id,
      email: data?.email,
      role: data?.role?.id,
      team: data?.team?.id,
      external: false
    });
  };

  useEffect(() => {
    setIsEditFormData(false);
    setIsEditFormPassengerData(false);
    setIsEditPassportData(false);
    setIsEditLoyaltyNumbers(false);
    setIsEditCryptoWallets(false);
  }, [data.id]);

  const formatDestinations = (cities) => {
    return cities.map((city) => ({
      title: `${city.name} (${city.country})`,
      value: city.id,
    }));
  };

  const getFormattedHotels = ({hotels}) => {
    return hotels.map((hotel) => ({
      title: `${hotel.name}`,
      value: hotel.id,
    }));
  };

  const getFormattedAirlines = (airlines = []) => {
    return airlines.map((airline) => ({
      title: airline.title,
      value: airline.value,
    }));
  };

  const fetchAirlines = async (params: { search?: string, id?: string | number }) => {
    try {
      const { data } = await request.get('airlines', { params });
      return getFormattedAirlines(data.airlines || []);
    } catch (err) {
      return [];
    }
  };

  const fetchCities = async (params: { search?: string, id?: string | number }) => {
    try {
      const { data } = await request.get(`locations/cities`, { params });
      return formatDestinations(data.airports || []);
    } catch (err) {
      return [];
    }
  };

  const fetchHotels = async (params: { search?: string, id?: string | number }, regionId: number) => {
    try {
      const { data } = await request.get('hotels', { params: { ...params, regionId } });
      return getFormattedHotels(data || []);
    } catch(err) {
      return [];
    }
  };

  const resetFieldsOnVerticalChanging = (setFieldValue) => {
    setFieldValue('employerId', null);
    setFieldValue('teamId', null);
    setFieldValue('manager', null);
    setFieldValue('assistants', []);
  };

  const handleBusinessVerticalChanging = (value, setFieldValue, employers, teams, managers, businessVerticalType) => {
    assignBusinessVerticalsByType(businessVerticals, businessVerticalType);
    assignVerticalEmployers(employers, value);
    assignVerticalManagers(managers, value);
    assignVerticalTeams(teams, value);
    assignVerticalCompanyEmployees(companyEmployees, value);
    resetFieldsOnVerticalChanging(setFieldValue);
    setFieldValue('businessVerticalId', value);
  };

  return (
    <div>
      {data?.status === UserStatuses.Unregistered && (
        <InfoBlock
          actionLabel="Invite"
          text={`  
            User was imported from the company's Hibob but not registered.
            You can invite ${data?.firstname} ${data?.lastname} to sign up to Travel.
          `}
          onHandleAction={handleInviteUser}
        />
      )}

      {isUserExternal && (
        <InfoBlock
          text={`  
            User was pulled up into system after successful registration in the Web application.
            External user does not have access to the BO or app in Slack.
          `}
        />
      )}

      {!doesUserAndTeamVerticalMatch && (
        <InfoBlock
          text={`Team's vertical doesn't match with user's vertical`}
        />
      )}

      {!doesUserAndEmployerVerticalMatch && (
        <InfoBlock
          text={`Employer's vertical doesn't match with user's vertical`}
        />
      )}

      {!doesUserAndManagerVerticalMatch && (
        <InfoBlock
          text={`Manager's vertical doesn't match with user's vertical`}
        />
      )}

      {!doesUserAndAssistantsVerticalMatch && (
        <InfoBlock
          text={`Assistant's vertical doesn't match with user's vertical`}
        />
      )}

      {!isEditFormData && !isCurrentUser && (
        <EditButtonWrapper>
          <Button variant="ghost" onClick={() => setIsEditFormData(!isEditFormData)} iconLeft={<GenericEdit fontSize="1.2rem" />}>
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <Formik
        enableReinitialize
        validateOnBlur={false}
        validationSchema={userOverviewSchema}
        initialValues={initialFormValues}
        onSubmit={(values, { resetForm }): void => {
          if (!initialFormValues.isSkippedManagerApproval && values.isSkippedManagerApproval) {
            setOpenedWarningAboutSkippingManagerApproval(true);

            return;
          }

          handleSubmitFormData(values);
          resetForm();
        }}
      >
        {({ errors, values, handleChange, resetForm, setFieldValue, touched }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemsWrapper>
                <OverviewItemName>User ID</OverviewItemName>
                <OverviewItem>{data?.id}</OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>First name</OverviewItemName>

                <OverviewItem>
                  {isEditFormData ? (
                    <FormField
                      placeholder="First name"
                      value={values.firstname}
                      fieldId="firstname"
                      fieldName="firstname"
                      component={TextInput}
                      type="text"
                      inputSize="medium"
                      onChange={handleChange}
                      errors={errors}
                      errorPosition={-20}
                    />
                  ) : (
                    <EllipsisText text={values.firstname} />
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Last name</OverviewItemName>

                <OverviewItem>
                  {isEditFormData ? (
                    <FormField
                      placeholder="Last name"
                      value={values.lastname}
                      fieldId="lastname"
                      fieldName="lastname"
                      component={TextInput}
                      type="text"
                      inputSize="medium"
                      onChange={handleChange}
                      errors={errors}
                      errorPosition={-20}
                    />
                  ) : (
                    <EllipsisText text={values.lastname} />
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Email</OverviewItemName>
                <OverviewItem>
                  <EllipsisText text={data?.email} />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper style={{ position: 'relative' }}>
                <OverviewItemName>Role</OverviewItemName>

                <OverviewItem>
                  {(isEditFormData && !isUserExternal) ? (
                    <CustomSelect
                      currentValue={values.role}
                      items={roles}
                      getItemLabel={(value) => getRoleLabelById(value, roles)}
                      onChange={(value) => handleUpdateRole(value, setFieldValue)}
                    />
                  ) : (
                    getUsersRoleLabel(data?.role)
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Team</OverviewItemName>
                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.teamId}
                      placeholder="Team"
                      isError={!!errors?.teamId}
                      items={teamsByBusinessVertical.filter(team => (
                        data?.status === UserStatuses.External
                          ? team.type === TeamTypes.EXTERNAL
                          : team.type === TeamTypes.INTERNAL
                      ))}
                      error={errors?.teamId}
                      onChange={(value) => setFieldValue('teamId', value)}
                    />
                  ) : (
                    <EllipsisText text={data?.team?.name || N_A} />
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Employer</OverviewItemName>
                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.employerId}
                      placeholder="Employer"
                      isError={!!errors?.employerId}
                      items={employersByBusinessVertical.filter(employer => (
                        data?.status === UserStatuses.External
                          ? employer.type === EmployerTypes.EXTERNAL
                          : employer.type === EmployerTypes.INTERNAL
                      ))}
                      error={errors?.employerId}
                      onChange={(value) => setFieldValue('employerId', value)}
                    />
                  ) : (
                    <EllipsisText text={data?.employer?.name || N_A} />
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Business vertical</OverviewItemName>
                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.businessVerticalId}
                      isError={!!errors?.businessVerticalId}
                      placeholder="Business vertical"
                      items={businessVerticalsByType}
                      error={errors?.businessVerticalId}
                      onChange={(value) => {
                        handleBusinessVerticalChanging(
                          value, setFieldValue, employers, teams, managers, businessVerticalType)
                      }}
                    />
                  ) : (
                    <OverviewItem>
                      {data?.businessVertical?.name ?
                        formatBusinessVerticalLabel(data?.businessVertical?.name, data?.businessVertical?.type, true)
                        : N_A}
                    </OverviewItem>
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              {
                isUserRegistered && (
                  <>
                    <OverviewItemsWrapper>
                      <OverviewItemName>Employment type</OverviewItemName>
                      <OverviewItem>
                        {isEditFormData ? (
                          <CustomSelect
                            currentValue={values.employmentType}
                            items={employmentTypes}
                            onChange={(value) => setFieldValue('employmentType', value)}
                          />
                        ) : (
                          <EllipsisText text={data?.employmentType?.label} />
                        )}
                      </OverviewItem>
                    </OverviewItemsWrapper>

                    {!!currentUserData?.company?.balancedStatus && (
                      <OverviewItemsWrapper>
                        <OverviewItemName>BALANCED.IO integration number</OverviewItemName>

                        <OverviewItem>
                          {isEditFormData ? (
                            <FormField
                              errorPosition={-20}
                              placeholder="Integration number"
                              fieldName="balancedId"
                              component={TextInput}
                              inputSize="medium"
                              fieldId="balancedId"
                              value={values.balancedId}
                              errors={errors}
                              type="text"
                              onChange={handleChange}
                            />
                          ) : (
                            <EllipsisText text={data?.balancedId || N_A} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>
                    )}

                    <OverviewItemsWrapper>
                      <OverviewItemName>Jira ID</OverviewItemName>

                      <OverviewItem>
                        {isEditFormData ? (
                          <FormField
                            placeholder="Jira ID"
                            value={values.jiraId}
                            fieldId="jiraId"
                            fieldName="jiraId"
                            component={TextInput}
                            type="text"
                            inputSize="medium"
                            onChange={handleChange}
                            errors={errors}
                            errorPosition={-20}
                          />
                        ) : (
                          <EllipsisText text={data?.jiraId || N_A} /> 
                        )}
                      </OverviewItem>
                    </OverviewItemsWrapper>

                    <OverviewItemsWrapper>
                      <OverviewItemName>Manager</OverviewItemName>

                      <OverviewItem>
                        {isEditFormData ? (
                          <CustomSelect
                            currentValue={values.manager}
                            isSearchable
                            isClearable
                            placeholder="Manager"
                            items={managersByBusinessVertical}
                            onChange={(value) => setFieldValue('manager', value)}
                          />
                        ) : (
                          formatManager(data?.manager)
                        )}
                      </OverviewItem>
                    </OverviewItemsWrapper>
                  </>
                )
              }

              {(isUserRegistered || isUserExternal) && (
                  <>
                    <OverviewItemsWrapper>
                      <OverviewItemName>Created at</OverviewItemName>
                      <OverviewItem>
                        <div>
                          {data?.createdAt ? formatDateAndTime(data?.createdAt, true) : N_A}
                        </div>
                      </OverviewItem>
                    </OverviewItemsWrapper>

                    <OverviewItemsWrapper>
                      <OverviewItemName>Activated at</OverviewItemName>
                      <OverviewItem>
                        <div>
                          {data?.activatedAt ? formatDateAndTime(data?.activatedAt, true) : N_A}
                        </div>
                      </OverviewItem>
                    </OverviewItemsWrapper>
                  </>
                )
              }

              {isManager(values.role) && (
                <OverviewItemsWrapper>
                  <OverviewItemName>Assistants</OverviewItemName>
                  <OverviewItem>
                    {isEditFormData ? (
                      <CustomSelect
                        currentValue={values.assistants}
                        placeholder="Assistants"
                        isSearchable
                        isError={errors?.assistants}
                        error={errors?.assistants}
                        isMulti
                        items={companyEmployeesByBusinessVertical.filter((employer) => employer.value !== values.userId)}
                        onChange={(value: any) => {
                          if (!value?.length) {
                            setFieldValue('isSkippedManagerNotifications', false);
                          }
                          setFieldValue('assistants', value);
                        }}
                      />
                    ) : data.assistants.length ? (
                      <span className="user-assistants">
                        {getLabels(
                          data.assistants.map(({ id, lastName, firstName }) => ({
                            id,
                            name: `${firstName} ${lastName}`,
                          })),
                          'name'
                        )}
                      </span>
                    ) : (
                      <EllipsisText text={N_A} />
                    )}
                  </OverviewItem>
                </OverviewItemsWrapper>
              )}

              <OverviewItemsWrapper>
                <OverviewItemName>Status</OverviewItemName>
                <OverviewItem>
                  {getUserStatusLabel(data?.status)}
                </OverviewItem>
              </OverviewItemsWrapper>

              {data?.hibobId && (
                <OverviewItemsWrapper>
                  <OverviewItemName>Hibob ID</OverviewItemName>
                  <OverviewItem>{data?.hibobId}</OverviewItem>
                </OverviewItemsWrapper>
              )}

              {
                isUserRegistered && (
                  <OverviewItemsWrapper>
                    <OverviewItemName>Comment</OverviewItemName>
                    <OverviewItem>
                      {isEditFormData ? (
                        <FormField
                          placeholder="Comment"
                          value={values.comment}
                          fieldId="comment"
                          fieldName="comment"
                          component={TextInput}
                          type="text"
                          inputSize="medium"
                          onChange={handleChange}
                          errors={errors}
                          errorPosition={-20}
                        />
                      ) : data?.comment || N_A}
                    </OverviewItem>
                  </OverviewItemsWrapper>
                )
              }

              {
                isUserRegistered && isManager(values.role) && (
                  <OverviewItemsWrapper>
                    <OverviewItemName>Do not notify to approve trips or expenses</OverviewItemName>
                    <OverviewItem>
                      <Checkbox
                        disabled={!isEditFormData || !values.assistants.length}
                        checked={values.isSkippedManagerNotifications}
                        onChange={() => setFieldValue('isSkippedManagerNotifications', !values.isSkippedManagerNotifications)}
                      />
                    </OverviewItem>
                  </OverviewItemsWrapper>
                )
              }

              {
                isUserRegistered && (
                  <OverviewItemsWrapper>
                    <OverviewItemName>Trips don't require manager's approval</OverviewItemName>
                    <OverviewItem>
                      <Checkbox
                        disabled={!isEditFormData}
                        checked={values.isSkippedManagerApproval}
                        onChange={() => setFieldValue('isSkippedManagerApproval', !values.isSkippedManagerApproval)}
                      />
                    </OverviewItem>
                  </OverviewItemsWrapper>
                )
              }

              {isEditFormData && (
                <ButtonsWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      setIsEditFormData(!isEditFormData);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      !isEmpty(errors) ||
                      !values.firstname ||
                      !values.lastname ||
                      checkOnIsEqual(values, initialFormValues)
                    }
                  >
                    Save
                  </Button>
                </ButtonsWrapper>
              )}

              {isOpenedWarningAboutSkippingmanagerApproval && (
                <Dialog
                  submitButtonLabel="Confirm"
                  maxWidth={500}
                  title="Confirm"
                  onSubmit={() => {
                    setIsEditFormData(false);
                    handleSubmitFormData(values);
                    setOpenedWarningAboutSkippingManagerApproval(false);
                   }}
                  onClose={() => setOpenedWarningAboutSkippingManagerApproval(false)}
                >
                  <ConfirmDialogBodyWrapper>
                    {<InfoBlock text={`You selected "Trips don't require manager's approval"
                    setting for user. The manager approval step will be skipped for user. If user has 
                    pending trips, they will be approved automatically.`} />}

                    <Text size={16}>{'Are you sure you want to activate this setting?'}</Text>
                  </ConfirmDialogBodyWrapper>
                </Dialog>
              )}
            </Form>
          );
        }}
      </Formik>

      {isUserRegistered && (
        <Button variant="ghost" type="button" onClick={() => handleSyncUpdates(data?.id)}>
          <ArrowsRefreshRound />
          Sync updates
        </Button>
      )}

      {isUserExternal && !data?.activatedAt && (
        <Button
          disabled={isResendActivationLinkLoading}
          variant="ghost"
          type="button" onClick={() => handleResendActivationLink(data?.id)}
        >
          <MailSend />
          Resend activation link
        </Button>
      )}

      {
        (isUserRegistered || isUserExternal) && (
          <>
            <Formik
              enableReinitialize
              validationSchema={passengerSchema}
              initialValues={initialPassengerValues}
              validateOnBlur={false}
              onSubmit={handleSubmitPassengerData}
            >
              {({ errors, values, handleChange, resetForm, setFieldValue }: any): JSX.Element => {
                return (
                  <Form>
                    <Accordion title="Passenger" openByDefault withoutPadding>
                      {!isEditFormPassengerData && !isCurrentUser && (
                        <EditButtonWrapper>
                          <Button
                            variant="ghost"
                            onClick={() => setIsEditFormPassengerData(!isEditFormPassengerData)}
                            iconLeft={<GenericEdit fontSize="1.2rem" />}
                          >
                            Edit
                          </Button>
                        </EditButtonWrapper>
                      )}
                      <OverviewItemsWrapper>
                        <OverviewItemName>First name</OverviewItemName>

                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <FormField
                              placeholder="First name"
                              value={values.firstname}
                              fieldId="firstname"
                              fieldName="firstname"
                              component={TextInput}
                              type="text"
                              inputSize="medium"
                              onChange={handleChange}
                              errors={errors}
                              errorPosition={-20}
                            />
                          ) : (
                            <EllipsisText text={values.firstname} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      <OverviewItemsWrapper>
                        <OverviewItemName>Last name</OverviewItemName>

                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <FormField
                              placeholder="Last name"
                              value={values.lastname}
                              fieldId="lastname"
                              fieldName="lastname"
                              component={TextInput}
                              type="text"
                              inputSize="medium"
                              onChange={handleChange}
                              errors={errors}
                              errorPosition={-20}
                            />
                          ) : (
                            <EllipsisText text={values.lastname} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      <OverviewItemsWrapper>
                        <OverviewItemName>Passenger email</OverviewItemName>

                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <FormField
                              placeholder="Passenger email"
                              value={values.passenger_email}
                              fieldId="passenger_email"
                              fieldName="passenger_email"
                              component={TextInput}
                              type="email"
                              inputSize="medium"
                              onChange={handleChange}
                              errors={errors}
                              errorPosition={-20}
                            />
                          ) : (
                            <EllipsisText text={values.passenger_email} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      {
                        isUserRegistered && values?.passenger_email !== data?.passenger?.email && (
                          <InfoBlock text={`All user's future calendar trip events will be resent to the new passenger email.`} />
                        )
                      }

                      <OverviewItemsWrapper>
                        <OverviewItemName>Phone</OverviewItemName>

                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <FormField
                              placeholder="Phone"
                              value={values.passenger_mobile_number}
                              fieldId="passenger_mobile_number"
                              fieldName="passenger_mobile_number"
                              component={TextInput}
                              type="text"
                              inputSize="medium"
                              onChange={handleChange}
                              errors={errors}
                              errorPosition={-20}
                            />
                          ) : (
                            <EllipsisText text={values.passenger_mobile_number} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      <OverviewItemsWrapper>
                        <OverviewItemName>Birth date</OverviewItemName>

                        <OverviewItem>
                          <DatePicker
                            isEditView={isEditFormPassengerData}
                            errors={errors}
                            value={values.birthdate}
                            field="birthdate"
                            onChange={setFieldValue}
                          />
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      <OverviewItemsWrapper>
                        <OverviewItemName>Gender</OverviewItemName>
                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <CustomSelect
                              currentValue={values.gender}
                              isError={!!errors?.gender}
                              items={genders}
                              error={errors?.gender}
                              onChange={(value) => setFieldValue('gender', value)}
                            />
                          ) : (
                            <EllipsisText text={data?.passenger?.isFemale ? 'Female' : 'Male'} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>

                      <OverviewItemsWrapper>
                        <OverviewItemName>Title</OverviewItemName>
                        <OverviewItem>
                          {isEditFormPassengerData ? (
                            <CustomSelect
                              currentValue={values.title}
                              isError={!!errors?.title}
                              items={titles}
                              error={errors?.title}
                              onChange={(value) => setFieldValue('title', value)}
                            />
                          ) : (
                            <EllipsisText text={data?.passenger?.title?.keyword} />
                          )}
                        </OverviewItem>
                      </OverviewItemsWrapper>
                
                      {isEditFormPassengerData && (
                        <ButtonsWrapper>
                          <Button
                            variant="secondary"
                            type="reset"
                            onClick={() => {
                              resetForm();
                              setIsEditFormPassengerData(!isEditFormPassengerData);
                            }}
                          >
                            Cancel
                          </Button>

                          <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialPassengerValues)}>
                            Save
                          </Button>
                        </ButtonsWrapper>
                      )}
                    </Accordion>
                  </Form>
                );
              }}
            </Formik>

            {isUserRegistered && (
              <Formik
                enableReinitialize
                validateOnBlur={false}
                validationSchema={userOverviewPassportSchema}
                initialValues={initialPassportValues}
                onSubmit={handleSubmitPassportData}
              >
                {({ errors, values, handleChange, resetForm, setFieldValue }: any): JSX.Element => {
                  return (
                    <Form>
                      <Accordion title="Passport" openByDefault withoutPadding>
                        {!isEditFormPassportData && !isCurrentUser && (
                          <EditButtonWrapper>
                            <Button
                              variant="ghost"
                              onClick={() => setIsEditPassportData(!isEditFormPassportData)}
                              iconLeft={<GenericEdit fontSize="1.2rem" />}
                            >
                              Edit
                            </Button>
                          </EditButtonWrapper>
                        )}
                        <OverviewItemsWrapper>
                          <OverviewItemName>Country</OverviewItemName>

                          <OverviewItem>
                            {isEditFormPassportData ? (
                              <CustomSelect
                                isSearchable
                                currentValue={values.country}
                                items={countries}
                                onChange={(value) => setFieldValue('country', value)}
                              />
                            ) : (
                              data?.userDocument?.country?.name
                            )}
                          </OverviewItem>
                        </OverviewItemsWrapper>

                        <OverviewItemsWrapper>
                          <OverviewItemName>Number</OverviewItemName>

                          <OverviewItem>
                            {isEditFormPassportData ? (
                              <FormField
                                placeholder="Number"
                                value={values.document_number}
                                fieldId="document_number"
                                fieldName="document_number"
                                component={TextInput}
                                type="text"
                                inputSize="medium"
                                onChange={handleChange}
                                errors={errors}
                                errorPosition={5}
                              />
                            ) : (
                              <EllipsisText text={values.document_number} />
                            )}
                          </OverviewItem>
                        </OverviewItemsWrapper>

                        <OverviewItemsWrapper>
                          <OverviewItemName>Expiration date</OverviewItemName>

                          <OverviewItem>
                            <DatePicker
                              isEditView={isEditFormPassportData}
                              errors={errors}
                              value={values.document_valid}
                              field="document_valid"
                              onChange={setFieldValue}
                            />
                          </OverviewItem>
                        </OverviewItemsWrapper>
                        {isEditFormPassportData && (
                          <ButtonsWrapper>
                            <Button
                              variant="secondary"
                              type="reset"
                              onClick={() => {
                                resetForm();
                                setIsEditPassportData(!isEditFormPassportData);
                              }}
                            >
                              Cancel
                            </Button>

                            <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialPassportValues)}>
                              Save
                            </Button>
                          </ButtonsWrapper>
                        )}
                      </Accordion>
                    </Form>
                  );
                }}
              </Formik>
            )}

            {!isUserRegistered && !!data?.userDocument?.number && (
              <Formik
                enableReinitialize
                validateOnBlur={false}
                validationSchema={userOverviewDocumentSchema}
                initialValues={initialDocumentValues}
                onSubmit={handleSubmitDocumentData}
              >
                {({ errors, values, handleChange, resetForm, setFieldValue }: any): JSX.Element => {
                  return (
                    <Form>
                      <Accordion title="Document" openByDefault withoutPadding>
                        {!isEditFormPassportData && !isCurrentUser && (
                          <EditButtonWrapper>
                            <Button
                              variant="ghost"
                              onClick={() => setIsEditPassportData(!isEditFormPassportData)}
                              iconLeft={<GenericEdit fontSize="1.2rem" />}
                            >
                              Edit
                            </Button>
                          </EditButtonWrapper>
                        )}
                        <OverviewItemsWrapper>
                          <OverviewItemName>Type</OverviewItemName>

                          <OverviewItem>
                            {isEditFormPassportData ? (
                              <CustomSelect
                                currentValue={values.document_type}
                                items={travelDocumentTypes}
                                onChange={(value) => setFieldValue('document_type', value)}
                              />
                            ) : (
                              data?.userDocument?.type?.name
                            )}
                          </OverviewItem>
                        </OverviewItemsWrapper>

                        <OverviewItemsWrapper>
                          <OverviewItemName>Country</OverviewItemName>

                          <OverviewItem>
                            {isEditFormPassportData ? (
                              <CustomSelect
                                isSearchable
                                currentValue={values.country}
                                items={countries}
                                onChange={(value) => setFieldValue('country', value)}
                              />
                            ) : (
                              data?.userDocument?.country?.name
                            )}
                          </OverviewItem>
                        </OverviewItemsWrapper>

                        <OverviewItemsWrapper>
                          <OverviewItemName>Number</OverviewItemName>

                          <OverviewItem>
                            {isEditFormPassportData ? (
                              <FormField
                                placeholder="Number"
                                value={values.document_number}
                                fieldId="document_number"
                                fieldName="document_number"
                                component={TextInput}
                                type="text"
                                inputSize="medium"
                                onChange={handleChange}
                                errors={errors}
                                errorPosition={5}
                              />
                            ) : (
                              <EllipsisText text={values.document_number} />
                            )}
                          </OverviewItem>
                        </OverviewItemsWrapper>

                        <OverviewItemsWrapper>
                          <OverviewItemName>Expiration date</OverviewItemName>

                          <OverviewItem>
                            <DatePicker
                              isEditView={isEditFormPassportData}
                              errors={errors}
                              value={values.document_valid}
                              field="document_valid"
                              onChange={setFieldValue}
                            />
                          </OverviewItem>
                        </OverviewItemsWrapper>
                        {isEditFormPassportData && (
                          <ButtonsWrapper>
                            <Button
                              variant="secondary"
                              type="reset"
                              onClick={() => {
                                resetForm();
                                setIsEditPassportData(!isEditFormPassportData);
                              }}
                            >
                              Cancel
                            </Button>

                            <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialPassportValues)}>
                              Save
                            </Button>
                          </ButtonsWrapper>
                        )}
                      </Accordion>
                    </Form>
                  );
                }}
              </Formik>
            )}

            <Formik
              enableReinitialize
              validateOnBlur={false}
              validationSchema={userLoyaltyNumbersSchema}
              initialValues={initialLoyaltyNumbersValues}
              onSubmit={handleSubmitLoyaltyNumbers}
            >
              {({ errors, values, touched, resetForm, setFieldValue }: any): JSX.Element => {
                return (
                  <Form>
                    <Accordion title="Loyalty Programs" openByDefault withoutPadding>
                      <InfoBlock
                        text={`
                          Travelopses and managers can provide up to ${validationSchema.USER.LOYALTY_PROGRAMS.max} loyalty numbers for each traveler.
                          If you provide an invalid loyalty number system ignores it during the booking process.
                        `}
                      />

                      {!isEditLoyaltyNumbers && !isCurrentUser && (
                        <EditButtonWrapper>
                          <Button
                            variant="ghost"
                            onClick={() => setIsEditLoyaltyNumbers(!isEditLoyaltyNumbers)}
                            iconLeft={<GenericEdit fontSize="1.2rem" />}
                          >
                            Edit
                          </Button>
                        </EditButtonWrapper>
                      )}
                    
                      {
                        isEditLoyaltyNumbers ? (
                          <DynamicFormFields
                            fieldName="loyaltyNumbers"
                            touched={touched}
                            errors={errors}
                            data={values.loyaltyNumbers}
                            setFieldValue={setFieldValue}
                            getDefaultValue={() => ({ id: uuidv4(), number: '', airline: '' })}
                            renderItem={(fieldName, value, index) => (
                              <GroupInputWrapper>
                                <SelectWithExternalDataSource
                                  currentValue={value.airline}
                                  placeholder='Select airline'
                                  position="left"
                                  isError={!!(get(touched, [fieldName, index, 'airline']) && get(errors, [fieldName, index, 'airline']))}
                                  fetchOptions={(params: any) => fetchAirlines(params)}
                                  resetValues={() => (
                                    setFieldValue(`${fieldName}.${index}.airline`, undefined)
                                  )}
                                  onChange={(value) => {
                                    setFieldValue(`${fieldName}.${index}.airline`, value);
                                  }}
                                />
                                <FormField
                                  placeholder={'Number'}
                                  fieldName={`${fieldName}.${index}.number`}
                                  inputSize="large"
                                  component={TextInput}
                                  fieldId={`${fieldName}.${index}.number`}
                                  isError={get(touched, [fieldName, index, 'number']) && get(errors, [fieldName, index, 'number'])}
                                  value={value.number}
                                  type="text"
                                  onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}.number`, value)}
                                />
                              </GroupInputWrapper>
                            )}
                          />
                        ) : (
                          <>
                            {
                              data.loyaltyNumbers.map(loyaltyNumber => (
                                <OverviewItemsWrapper key={loyaltyNumber.airline.id + loyaltyNumber.number}>
                                  <OverviewItemName>{loyaltyNumber.airline.title}</OverviewItemName>
                                  <OverviewItem>
                                    <EllipsisText text={loyaltyNumber.number} />
                                  </OverviewItem>
                                </OverviewItemsWrapper>
                              ))
                            }
                          </>
                        )
                      }
                      {isEditLoyaltyNumbers && (
                        <ButtonsWrapper>
                          <Button
                            variant="secondary"
                            type="reset"
                            onClick={() => {
                              resetForm();
                              setIsEditLoyaltyNumbers(!isEditLoyaltyNumbers);
                            }}
                          >
                            Cancel
                          </Button>

                          <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialLoyaltyNumbersValues)}>
                            Save
                          </Button>
                        </ButtonsWrapper>
                      )}
                    </Accordion>
                  </Form>
                );
              }}
            </Formik>

            {isUserRegistered && (
              <>
                <Formik
                  enableReinitialize
                  validateOnBlur={false}
                  validationSchema={userFavoriteHotelsSchema}
                  initialValues={initialFavoriteHotelsValues}
                  onSubmit={handleSubmitFavoriteHotels}
                >
                  {({ errors, values, touched, resetForm, setFieldValue }: any): JSX.Element => {
                    return (
                      <Form>
                        <Accordion title="Favorite Hotels" openByDefault withoutPadding>
                          {!isEditFavoriteHotels && !isCurrentUser && (
                            <EditButtonWrapper>
                              <Button
                                variant="ghost"
                                onClick={() => setIsEditFavoriteHotels(!isEditFavoriteHotels)}
                                iconLeft={<GenericEdit fontSize="1.2rem" />}
                              >
                                Edit
                              </Button>
                            </EditButtonWrapper>
                          )}

                          {isEditFavoriteHotels ? (
                            <DynamicFormFields
                              withoutLimit
                              fieldName="favoriteHotels"
                              touched={touched}
                              errors={errors}
                              data={values.favoriteHotels}
                              setFieldValue={setFieldValue}
                              getDefaultValue={() => ({ id: uuidv4(), userId: data.id, hotelId: undefined, regionId: undefined })}
                              renderItem={(fieldName, value, index) => (
                                <GroupInputWrapper>
                                  <SelectWithExternalDataSource
                                    currentValue={value.regionId}
                                    placeholder='Select city'
                                    position="left"
                                    isError={!!(get(touched, [fieldName, index, 'regionId']) && get(errors, [fieldName, index, 'regionId']))}
                                    fetchOptions={(params: any) => fetchCities(params)}
                                    resetValues={() => (
                                      setFieldValue(`${fieldName}.${index}.regionId`, undefined)
                                      && setFieldValue(`${fieldName}.${index}.hotelId`, undefined)
                                    )}
                                    onChange={(value) => {
                                      setFieldValue(`${fieldName}.${index}.regionId`, value);
                                      setFieldValue(`${fieldName}.${index}.hotelId`, undefined);
                                    } } />
                                  <SelectWithExternalDataSource
                                    dependOnFieldValue={value.regionId}
                                    isDependedFiled
                                    currentValue={value.hotelId}
                                    placeholder='Select hotel'
                                    isDisabled={!value.regionId}
                                    position="right"
                                    isError={!!(get(touched, [fieldName, index, 'hotelId']) && get(errors, [fieldName, index, 'hotelId']))}
                                    fetchOptions={(params: any) => fetchHotels(params, value.regionId)}
                                    resetValues={() => (
                                      setFieldValue(`${fieldName}.${index}.hotelId`, undefined)
                                    )}
                                    onChange={(value) => {
                                      setFieldValue(`${fieldName}.${index}.hotelId`, value);
                                    } } />
                                </GroupInputWrapper>
                              )} />
                          ) : (
                            <>
                              {data.favoriteHotels.map(favoriteHotels => (
                                <OverviewItemsWrapper key={favoriteHotels?.hotel?.id}>
                                  <OverviewItemName>
                                    <EllipsisText text={favoriteHotels?.hotel?.region?.city} />
                                  </OverviewItemName>
                                  <OverviewItem>
                                    <EllipsisText text={favoriteHotels?.hotel?.name} />
                                  </OverviewItem>
                                </OverviewItemsWrapper>
                              ))}
                            </>
                          )}
                          {isEditFavoriteHotels && (
                            <ButtonsWrapper>
                              <Button
                                variant="secondary"
                                type="reset"
                                onClick={() => {
                                  resetForm();
                                  setIsEditFavoriteHotels(!isEditFavoriteHotels);
                                } }
                              >
                                Cancel
                              </Button>

                              <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialFavoriteHotelsValues)}>
                                Save
                              </Button>
                            </ButtonsWrapper>
                          )}
                        </Accordion>
                      </Form>
                    );
                  } }
                </Formik>
                <Formik
                  enableReinitialize
                  validateOnBlur={false}
                  validationSchema={userCryptoWalletsSchema}
                  initialValues={initialCryptoWalletsValues}
                  onSubmit={(values, { resetForm }): void => handleSubmitCryptoWallets(values, resetForm)}
                >
                    {({ errors, values, touched, resetForm, setFieldValue, setFieldTouched }: any): JSX.Element => (
                      <Form>
                        <Accordion title="Crypto wallets" openByDefault withoutPadding>
                          {!isEditCryptoWallets && !isCurrentUser && (
                            <EditButtonWrapper>
                              <Button
                                variant="ghost"
                                onClick={() => setIsEditCryptoWallets(!isEditCryptoWallets)}
                                iconLeft={<GenericEdit fontSize="1.2rem" />}
                              >
                                Edit
                              </Button>
                            </EditButtonWrapper>
                          )}

                          {isEditCryptoWallets ? (
                            <DynamicFormFields
                              fieldName="cryptoWallets"
                              touched={touched}
                              errors={errors}
                              data={values.cryptoWallets}
                              setFieldValue={setFieldValue}
                              getDefaultValue={() => ({ id: null, address: undefined, cryptoCurrencyId: undefined })}
                              renderItem={(fieldName, value, index) => <>
                                <FormField
                                  placeholder={'Select crypto wallet address'}
                                  fieldName={`${fieldName}.${index}.address`}
                                  inputSize="large"
                                  component={TextInput}
                                  fieldId={`${fieldName}.${index}.address`}
                                  value={value.address}
                                  type="text"
                                  onChange={({ target: { value } }) => {
                                    setFieldValue(`${fieldName}.${index}.address`, value);
                                    setFieldTouched(`${fieldName}.${index}.address`, true);
                                  } } />
                                <CustomSelect
                                  placeholder={'Select crypto wallet currency'}
                                  currentValue={value.cryptoCurrencyId}
                                  items={availableForCryptoWalletCurrencies}
                                  onChange={(cryptoCurrencyId) => setFieldValue(`${fieldName}.${index}.cryptoCurrencyId`, cryptoCurrencyId)} />
                              </>} />
                          ) : (
                            <>
                              {data.cryptoWallets.map(cryptoWallet => (
                                <OverviewItemsWrapper key={cryptoWallet?.id}>
                                  <OverviewItemName>
                                    <EllipsisText text={cryptoWallet?.cryptoCurrency?.title} />
                                  </OverviewItemName>
                                  <OverviewItem>
                                    <CopyToClipboard text={cryptoWallet?.address} />
                                  </OverviewItem>
                                </OverviewItemsWrapper>
                              ))}
                            </>
                          )}
                          {isEditCryptoWallets && (
                            <ButtonsWrapper>
                              <Button
                                variant="secondary"
                                type="reset"
                                onClick={() => {
                                  resetForm();
                                  setIsEditCryptoWallets(!isEditCryptoWallets);
                                } }
                              >
                                Cancel
                              </Button>

                              <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, initialCryptoWalletsValues) || errors.cryptoWallets?.length}>
                                Save
                              </Button>
                            </ButtonsWrapper>
                          )}
                        </Accordion>
                      </Form>
                    )}
                </Formik>
              </>            
            )}
          </>
        ) 
      }
    </div>
  );
};

export default UserDrawerContent;
