import React, { useMemo, useState } from 'react';
import { ITogglePasswordText } from './types';

const TogglePasswordText: React.FC<ITogglePasswordText> = ({ renderShowIcon, renderHideIcon, isLogin }) => {
  const [show, setShow] = useState(true);

  const currentIconText = useMemo(() => show ? 'Show' : 'Hide', [show])

  const toggleClick = () => setShow((value) => !value);

  return (
    <span style={{ display: 'block' }} onClick={toggleClick}>
      {isLogin ? currentIconText : show ? <>{renderShowIcon ? renderShowIcon() : 'Show'}</> : <>{renderHideIcon ? renderHideIcon() : 'Hide'}</>}
    </span>
  );
};

export default TogglePasswordText;
