import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { ICustomHotels } from './types';
import { RowHeight } from '../../Reports/suggestions';
import CustomHotelOverviewDrawerContent from '../../../components/CustomHotelOverviewDrawerContent';
import AddNewCustomHotel from '../../../components/AddNewCustomHotel';
import TableContentReciever from '../../../components/TableContentReciever';
import TablePagination from '../../../components/TablePagination';
import { Spinner } from '../../../components/Spinner';
import DownDrawer from '../../../components/DownDrawer';
import Drawer from '../../../components/Drawer';
import Dialog from '../../../components/Dialog';
import { toolsCustomHotelsDataMapper } from '../../../utils/tableHelpers';
import { LIMIT_PER_PAGE } from '../../../constants';
import { PageHeader, TableWrapper, Title } from '../styles';
import { initialColumns } from './suggestions';
import { defineDeleteText } from './utils';

const CustomHotels: React.FC<ICustomHotels> = ({
  isActiveAllCustomHotelsCheckboxes,
  customHotelsCheckboxesData,
  isDeleteCustomHotelsModal,
  isOpenCustomHotelOverview,
  isUpdatedCustomHotelsData,
  overviewCustomHotelData,
  isOpenAddNewCustomHotel,
  deletableCustomHotels,
  totalCustomHotels,
  customHotels,
  filters,
  sortBy,
  page,
  setAllCustomHotelsCheckboxData,
  closeDeleteCustomHotelsModal,
  setCustomHotelsCheckboxData,
  showDeleteCustomHotelsModal,
  setIsOpenAddNewCustomHotel,
  showCustomHotelOverview,
  closeCustomHotelOverview,
  fetchCustomHotels,
  removeCustomHotel,
  addNewCustomHotel,
  updateCustomHotel,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage, 
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const queryParams = useRef('');
  const totalItems = useRef(0);
  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchCustomHotels(params);
  }, [filters, page, sortBy, isUpdatedCustomHotelsData]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const mappedData = useMemo(() => toolsCustomHotelsDataMapper(customHotels), [customHotels]);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  useMemo(() => {
    totalItems.current = totalCustomHotels;
  }, [totalCustomHotels]);

  const deleteDialogText = useMemo(() => defineDeleteText({
    isActiveAllCustomHotelsCheckboxes,
    customHotelsCheckboxesData,
  }), [isActiveAllCustomHotelsCheckboxes, customHotelsCheckboxesData]);
  
  const handleIsOverviewOpen = useCallback((row?: any) => showCustomHotelOverview(customHotels[row?.id]), [customHotels]);

  const tableContentRecieverProps = {
    isActiveAllCustomHotelsCheckboxes,
    isToolsCustomHotelsPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    checkboxesData: customHotelsCheckboxesData,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TOOLS.CUSTOM_HOTELS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: mappedData,
    handleSetAllCheckboxs: setAllCustomHotelsCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: showDeleteCustomHotelsModal,
    toggleTableFilters,
    handleSetCheckbox: setCustomHotelsCheckboxData,   
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Custom hotels</Title>
          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewCustomHotel(true)}>
            <ControlsPlus /> Add custom hotel
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.CUSTOM_HOTELS}
          totalItems={totalItems}
          data={customHotels}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchCustomHotels(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenAddNewCustomHotel && (
        <AddNewCustomHotel
          handleCloseDialog={() => setIsOpenAddNewCustomHotel(false)}
          handleAgree={addNewCustomHotel}
        />
      )}

       {isOpenCustomHotelOverview && (
        <div>
          <Drawer
            title={overviewCustomHotelData?.name ?? ''}
            handleClose={closeCustomHotelOverview}
          >
            <CustomHotelOverviewDrawerContent
              hotel={overviewCustomHotelData}
              handleUpdate={updateCustomHotel}
            />
          </Drawer>
        </div>
      )}

      {isDeleteCustomHotelsModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableCustomHotels}
          onSubmit={removeCustomHotel}
          onClose={closeDeleteCustomHotelsModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {!!customHotelsCheckboxesData.length && (
        <DownDrawer
          data={customHotelsCheckboxesData}
          text="custom hotel"
          handleRemoveDialog={showDeleteCustomHotelsModal}
          handleSetCheckbox={setCustomHotelsCheckboxData}
        />
      )}
    </>
  );
};

export default CustomHotels;
