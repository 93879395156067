import { useMemo } from 'react';
import { TravelSuitcase, TravelSeats, TravelMeal } from '@heathmont/moon-icons';
import { useTranslation } from 'react-i18next';
import { IFLightSegment } from './types';
import TripStepper from '../TripSteapper';
import { convertTimeToFlightLocalTime, getFlightDuration } from '../../utils/commonFunctions';
import { AdditionItem, AdditionItemsWrapper } from './styles';
import { extractExtraBags } from './utils';

const FlightSegment: React.FC<IFLightSegment> = ({ passengers, segment }) => {
  const { t } = useTranslation();
  const flightClass = segment?.cabin ?? null;
  const flightMeals = segment?.meals ?? [];
  const startDate = convertTimeToFlightLocalTime(segment.departure.timestamp, segment.departure.code);
  const endDate = convertTimeToFlightLocalTime(segment.arrival.timestamp, segment.arrival.code);
  const checkedBag = segment.includedCheckedBags;
  const extraBags = useMemo(() => extractExtraBags(segment.id, passengers), [segment.id, passengers]);

  return (
    <TripStepper
      startLabel={`${segment.departure.location.city} (${segment.departure.code})`}
      startDate={startDate}
      endLabel={`${segment.arrival.location.city} (${segment.arrival.code})`}
      endDate={endDate}
      duration={getFlightDuration(endDate, startDate)}
    >
      {checkedBag && !!checkedBag.quantity && (
        <AdditionItemsWrapper>
          <TravelSuitcase fontSize="1.5rem" />
          <AdditionItem>{`${checkedBag.quantity} checked bag(s) up to ${checkedBag.weight} ${checkedBag.weightUnit} per passenger`}</AdditionItem>
        </AdditionItemsWrapper>
      )}

      {extraBags.length > 0 && (
        extraBags.map(extraBag => (
          <AdditionItemsWrapper>
            <TravelSuitcase fontSize="1.5rem" />
            <AdditionItem>
              {`${extraBag.quantity} extra bag(s) up to ${extraBag.weight} ${extraBag.weightUnit}`}
            </AdditionItem>
          </AdditionItemsWrapper>
        ))
      )}

      {flightClass && (
        <AdditionItemsWrapper>
          <TravelSeats fontSize="1.5rem" />
          <AdditionItem>{t(`trips.lists.cabins.${flightClass}` || flightClass)}</AdditionItem>
        </AdditionItemsWrapper>
      )}

      {flightMeals && flightMeals.length > 0
        ? flightMeals.map((meal: any) => (
            <AdditionItemsWrapper key={meal.label}>
              <TravelMeal fontSize="1.5rem" />
              <AdditionItem>{meal?.label ?? ''}</AdditionItem>
            </AdditionItemsWrapper>
          ))
        : null}
    </TripStepper>
  );
};

export default FlightSegment;
