const CurrentUser: {
  _currentUser?: object | null;
  set?: (user: object | null) => void;
  get?: (key: string) => object | null;
} = {
  _currentUser: {},
};

CurrentUser.set = (currentUserRawData) => {
  CurrentUser._currentUser = currentUserRawData;
};

CurrentUser.get = (key) => {
  return CurrentUser._currentUser[key] || null;
}

export default CurrentUser;
