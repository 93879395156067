import {
  CLOSE_FEEDBACK_OVERVIEW,
  SHOW_FEEDBACK_OVERVIEW,
  UPDATE_FEEDBACK_DATA,
  SET_FEEDBACKS,
} from './actionTypes';

const initState = {
  isOpenFeedbackOverview: false,
  overviewFeedbackData: null,
  totalFeedbacks: 0,
  feedbacks: [],
};

const feedbacksReducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_FEEDBACK_OVERVIEW:
      return {
        ...state,
        isOpenFeedbackOverview: true,
        overviewFeedbackData: action.payload.data,
      };

    case CLOSE_FEEDBACK_OVERVIEW:
      return {
        ...state,
        isOpenFeedbackOverview: false,
        overviewFeedbackData: null,
      };
    
    case SET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.payload.records,
        totalFeedbacks: action.payload.total,
      };

    case UPDATE_FEEDBACK_DATA:
      return {
        ...state,
        overviewFeedbackData: action.payload.data,
        feedbacks: state.feedbacks.map((feedback) => {
          if (feedback.id !== action.payload.feedbackId) {
            return feedback;
          }

          return action.payload.data;
        }),
      };

    default:
      return state;
  }
};

export default feedbacksReducer;
