import {
  SET_SUBSCRIPTIONS,
  SHOW_SUBSCRIPTION_OVERVIEW,
  CLOSE_SUBSCRIPTION_OVERVIEW,
  SET_SUBSCRIPTION_LOGS,
} from './actionTypes';

const initState = {
  subscriptions: [],
  overviewSubscriptionsData: null,
  isOpenSubscriptionsOverview: false,
  subscriptionLogs: [],
};

const subscriptionsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.records,
      };

    case SHOW_SUBSCRIPTION_OVERVIEW:
      return {
        ...state,
        isOpenSubscriptionsOverview: true,
        overviewSubscriptionsData: action.payload.data,
      };

    case CLOSE_SUBSCRIPTION_OVERVIEW:
      return {
        ...state,
        overviewSubscriptionsData: null,
        isOpenSubscriptionsOverview: false,
        subscriptionLogs: [],
      };

    case SET_SUBSCRIPTION_LOGS:
      return {
        ...state,
        subscriptionLogs: action.payload.subscriptionLogs,
      };

    default:
      return state;
  }
};

export default subscriptionsReducer;
