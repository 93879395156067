import { businessVerticalSchema } from '../../components/BusinessVerticalOverviewDrawerEditContent/schema';
import { optionsMapper } from '../../pages/EditProfile/suggestions';
import {
  SET_EDIT_PROFILE_OPTIONS_SUCCESS,
  SET_EDIT_PROFILE_OPTIONS_LOADING,
  SET_EDIT_PROFILE_OPTIONS_ERROR,
  SET_IS_VALID_NEW_PASSWORD,
  SET_IS_CHANGED_TAB_VALUE,
  SET_IS_UPDATE_CHANGES,
  SET_IS_UPDATE_ERROR,
  SAVE_DATA,
} from './actionTypes';

const initState = {
  optionsLoading: false,
  optionsError: null,
  options: null,
  isValidNewPassword: false,
  tabs: {
    favorites: { isChanged: false }, 
    loyalty: { isChanged: false },
    details: { isChanged: false },
    settings: { isChanged: false },
    document: { isChanged: false },
    security: { isChanged: false },
    crypto: { isChanged: false },
  },
  isUpdate: false,
  isUpdateError: {
    isError: false,
    message: '',
  },
  data: {},
};

const editProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case SAVE_DATA: {
      return {
        ...state,
        data: action.payload.data,
      };
    }

    case SET_EDIT_PROFILE_OPTIONS_LOADING: {
      return {
        ...state,
        optionsLoading: true,
      };
    }
  
    case SET_EDIT_PROFILE_OPTIONS_ERROR: {
      return {
        ...state,
        optionsLoading: false,
        optionsError: action.payload.error,
      };
    }
  
    case SET_EDIT_PROFILE_OPTIONS_SUCCESS: {
      const { companies, employers, genders, titles, teams, countries, availableForCryptoWalletCurrencies, businessVerticals } = action.payload.options;
      const translate = action.payload.translate;

      return {
        ...state,
        optionsLoading: false,
        optionsError: null,
        options: {
          companies: optionsMapper(companies, translate),
          employers: employers.map(({ id, title, businessVerticals }) => ({
            businessVerticals,
            value: String(id),
            label: title,
          })),
          teams: optionsMapper(teams, translate),
          genders: optionsMapper(genders, translate),
          titles: optionsMapper(titles, translate),
          countries: optionsMapper(countries, translate),
          availableForCryptoWalletCurrencies: optionsMapper(availableForCryptoWalletCurrencies, translate),
          businessVerticals: optionsMapper(businessVerticals, translate),
        },
      };
    }

    case SET_IS_VALID_NEW_PASSWORD:
      return {
        ...state,
        isValidNewPassword: action.payload.isValid,
      };

    case SET_IS_CHANGED_TAB_VALUE:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.field]: { isChanged: action.payload.isChanged },
        },
      };

    case SET_IS_UPDATE_CHANGES:
      return {
        ...state,
        isUpdate: action.payload.isUpdate,
      };

    case SET_IS_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: {
          isError: action.payload.isUpdateError,
          message: action.payload.message,
        },
      };

    default:
      return state;
  }
};

export default editProfileReducer;
