import { useState } from 'react';
import { Tooltip as MoonTooltip } from '@heathmont/moon-core';
import styled from 'styled-components';
import { ITooltip } from './types';

const TooltipWrapper = styled.div`
  > div {
    z-index: 10;
  }
`;

const Tooltip: React.FC<ITooltip> = ({ label, position = 'top', children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TooltipWrapper onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      {
        isOpen ? (
          <MoonTooltip text={label} position={position} show={true}>
            <>{children}</>
          </MoonTooltip>
        ) : (
          <>{children}</>
        ) 
      }
    </TooltipWrapper>
  );
};

export default Tooltip;
