import { FLIGHT_TYPE } from './FlightType';

export const FLIGHT_LABELS = {
  [FLIGHT_TYPE.ROUND]: 'Round flight',
  [FLIGHT_TYPE.ONE_WAY]: 'One way',
  [FLIGHT_TYPE.MULTI_DESTINATION]: 'Multi-destination',
};

export const flightTypeOptions = [
  {
    title: FLIGHT_LABELS[FLIGHT_TYPE.ROUND],
    value: FLIGHT_TYPE.ROUND,
  },
  {
    title: FLIGHT_LABELS[FLIGHT_TYPE.ONE_WAY],
    value: FLIGHT_TYPE.ONE_WAY,
  },
  {
    title: FLIGHT_LABELS[FLIGHT_TYPE.MULTI_DESTINATION],
    value: FLIGHT_TYPE.MULTI_DESTINATION,
  },
];
