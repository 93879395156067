import {
  SET_TOOLS_BUSINESS_VERTICAl_OVERVIEW_IS_UPDATED_DATA,
  SET_TOOLS_BUSINESS_VERTICALS_COMPANIES_DATA,
  SET_IS_OPEN_ADD_NEW_BUSINESS_VERTICAL_MODAL,
  SET_TOOLS_ALL_BUSINESS_VERTICALS_CHECKBOX_DATA,
  SET_TOOLS_BUSINESS_VERTICAL_CHECKBOX_DATA,
  SET_TOOLS_BUSINESS_VERTICALS_DATA,
  SHOW_BUSINESS_VERTICAL_OVERVIEW,
  CLOSE_BUSINESS_VERTICAL_OVERVIEW,
  SHOW_DELETE_BUSINESS_VERTICALS_MODAL,
  CLOSE_DELETE_BUSINESS_VERTICALS_MODAL,
} from './actionTypes';

const initState = {
  businessVerticals: [],
  totalBusinessVerticals: 0,
  isActiveAllBusinessVerticalsCheckboxes: false,
  businessVerticalsCheckboxesData: [],
  isOpenBusinessVerticalOverview: false,
  overviewBusinessVerticalData: null,
  isOpenAddNewBusinessVertical: false,
  businessVerticalsCompanies: [],
  isUpdatedBusinessVerticalData: false,
  deletableBusinessVerticals: [],
  isDeleteBusinessVerticalsModal: false,
};

const businessVerticalsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TOOLS_BUSINESS_VERTICALS_DATA:

      return {
        ...state,
        businessVerticals: action.payload.records,
        totalBusinessVerticals: action.payload.total,
      };

    case SET_TOOLS_ALL_BUSINESS_VERTICALS_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllBusinessVerticalsCheckboxes: isActive,
        businessVerticalsCheckboxesData: data,
      };
    }

    case SET_TOOLS_BUSINESS_VERTICAL_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllBusinessVerticalsCheckboxes: false,
          businessVerticalsCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          businessVerticalsCheckboxesData: [...state.businessVerticalsCheckboxesData, data],
        };
      }

      return {
        ...state,
        businessVerticalsCheckboxesData: state.businessVerticalsCheckboxesData.filter((item) => item.businessVerticalId !== data.businessVerticalId),
        isActiveAllBusinessVerticalsCheckboxes: false,
      };
    }

    case SHOW_BUSINESS_VERTICAL_OVERVIEW:{
      return {
        ...state,
        isOpenBusinessVerticalOverview: true,
        overviewBusinessVerticalData: action.payload.data,
        businessVerticals: state.businessVerticals.map((vertical) => ({
          ...vertical,
          isSelected: vertical.id === action.payload.data.id,
        })),
      };
    }

    case SET_IS_OPEN_ADD_NEW_BUSINESS_VERTICAL_MODAL:
      return {
        ...state,
        isOpenAddNewBusinessVertical: action.payload,
      };

    case SET_TOOLS_BUSINESS_VERTICALS_COMPANIES_DATA:
      return {
        ...state,
        businessVerticalsCompanies: action.payload.data,
      };

    case SET_TOOLS_BUSINESS_VERTICAl_OVERVIEW_IS_UPDATED_DATA: 
      return {
        ...state,
        isUpdatedBusinessVerticalData: action.payload.isUpdated,
      };
  
    case SHOW_DELETE_BUSINESS_VERTICALS_MODAL:
      return {
        ...state,
        isDeleteBusinessVerticalsModal: true,
        deletableBusinessVerticals: action.payload.deletableBusinessVerticals,
      };

    case CLOSE_DELETE_BUSINESS_VERTICALS_MODAL:
      return {
        ...state,
        isDeleteBusinessVerticalsModal: false,
        deletableBusinessVerticals: [],
      };

    case CLOSE_BUSINESS_VERTICAL_OVERVIEW:
      return {
        ...state,
        isOpenBusinessVerticalOverview: false,
        overviewBusinessVerticalData: null,
        businessVerticals: state.businessVerticals.map((vertical) => ({ ...vertical, isSelected: false })),
      };
 
    default:
      return state;
  }
};

export default businessVerticalsReducer;
