import Yup from '../../utils/validation';

const validateMessages = {
  label: {
    min: 'Name cannot be less than 2 characters',
    max: 'Name cannot be longer than 255 characters',
    required: 'Name cannot be empty',
  },
};

export const schema = Yup.object().shape({
  label: Yup
    .string()
    .min(2, validateMessages.label.min)
    .max(100, validateMessages.label.max)
    .withoutSpaces()
    .required(validateMessages.label.required),
  expensesRequired: Yup.number()
});
