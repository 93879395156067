import { EmployerTypes } from '../../enums/EmployerTypes';
import Yup from '../../utils/validation';

const validateMessages = {
  registrationNumber: {
    max: 'Registration cannot be longer than 40 characters',
  },
  name: {
    min: 'Employer name cannot be less than 2 characters',
    max: 'Employer name cannot be longer than 40 characters',
    required: 'Employer name cannot be empty',
  },
  company: {
    required: 'Company cannot be empty'
  },
  businessVerticals:  {
    min: 'At least one business vertical should be added',
  },
};

export const employerSchema = Yup.object().shape({
  registrationNumber: Yup
    .string()
    .max(40, validateMessages.registrationNumber.max),
  company: Yup
    .number()
    .required(validateMessages.company.required)
    .nullable(),
  name: Yup
    .string()
    .required(validateMessages.name.required)
    .min(2, validateMessages.name.min)
    .max(40, validateMessages.name.max)
    .withoutSpaces(),
  businessVerticals: Yup
    .array()
    .of(Yup.number()),
  type: Yup
    .string()
    .oneOf([EmployerTypes.INTERNAL, EmployerTypes.EXTERNAL])
    .required(),
});
