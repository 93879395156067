import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import { setEmploymentTypesData, setIsChangedEmploymentType, showEmployerTypeOverview } from './actions';

export const fetchEmploymentTypes = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const {
      data: { records, total },
    } = await request.get('tools/employment-types', { params });

    dispatch(setEmploymentTypesData(records, total));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const addNewEmploymentType = (data) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    dispatch(setIsChangedEmploymentType(true));

    await request.post('tools/employment-types', data);

    getErrorStatusMessage({
      status: 200,
      message: 'New employment type is successfully added',
    });

    return;
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
    dispatch(setIsChangedEmploymentType(false));
  }
};

export const removeEmploymentType = (employmentTypeIds) => () => {
  return Promise.allSettled(
    employmentTypeIds.map((employmentTypeId) => {
      return request
        .delete(`tools/employment-types/${employmentTypeId}`)
        .then(() => {
          getErrorStatusMessage({
            status: 200,
            message: `Employment type with ID - ${employmentTypeId} successfully removed`,
          });
        })
        .catch((e) => {
          getErrorStatusMessage({
            status: e?.response?.status,
            message: `Employment type with ID - ${employmentTypeId} can't be removed`,
          });
        });
    })
  );
};

export const updateEmploymentType = (employmentTypeId, data) => (dispatch, getState) => {
  dispatch(setIsChangedEmploymentType(true));
  dispatch(setIsLoadingData(true));

  const {
    employmentTypesReducer: { overviewEmploymentTypeData },
  } = getState();

  request
    .update(`tools/employment-types/${employmentTypeId}`, {
      ...data,
    })
    .then(({ data }) => {
      const updatedOverview = { ...overviewEmploymentTypeData, ...data };

      dispatch(showEmployerTypeOverview(updatedOverview));
      getErrorStatusMessage({
        status: 200,
        message: `Employment type successfully updated`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });

      console.error(e);
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsChangedEmploymentType(false));
    });
};

export const updateEmploymentTypeStatus = (id, status) => async (dispatch, getState) => {
  dispatch(setIsChangedEmploymentType(true));

  const {
    employmentTypesReducer: { overviewEmploymentTypeData },
  } = getState();

  try {
    const { data } = await request.update(`tools/employment-types/status/${id}`, { status });

    const updatedOverview = { ...overviewEmploymentTypeData, ...data };

    dispatch(showEmployerTypeOverview(updatedOverview));

    getErrorStatusMessage({
      status: 200,
      message: `Employment type status is successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsChangedEmploymentType(false));
  }
};
