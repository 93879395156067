import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const Content = styled.div`
  position: relative;
  padding-left: 2px;
  &::before {
    content: '';
    border-left: 1px dashed #e0e0e0;
    height: 50%;
    position: absolute;
    top: 51.5%;
    left: 7px;
    transform: translate(-50%, -50%);
  }
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DateInfo = styled.span`
  margin-left: 16px;
`;

export const Date = styled.span`
  font-size: 12px;
  color: #78787d;
  margin-left: 7px;
`;

export const Time = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const Label = styled.div`
  font-size: 14px;
  color: #181b1c;
`;

export const Duration = styled.div`
  font-size: 12px;
  color: #78787d;
  margin-left: 9px;
`;

export const Icon = styled.span`
  z-index: 3;
  ${({ withMargin }) =>
    withMargin &&
    `
      margin-left: -5px;
   `}
  & svg {
    ${({ color }) =>
    color &&
      `
         stroke: ${color}
      `}
  }
`;

export const Children = styled.div`
  margin-top: 16px;
`;
