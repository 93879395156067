import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { IEditButton } from './types';
import { EditButtonWrapper } from './styles';

const EditButton: React.FC<IEditButton> = ({ onClick }) => {
  return (
    <EditButtonWrapper>
      <Button iconLeft={<GenericEdit />} variant="ghost" size="small" onClick={onClick}>
        Edit
      </Button>
    </EditButtonWrapper>
  );
};

export default EditButton;
