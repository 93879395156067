import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Form, FormikProvider, useFormik } from 'formik';
import { IAddNewPurpose } from './types';
import { Text, TextInput, Button, Checkbox } from '@heathmont/moon-core';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import { getInitialValues, purposeSchema } from './schema';
import { PurposeTypes } from '../../enums/PurposeTypes';
import { PAYMENT_TYPE, paymentTypeOptions } from '../../enums/PaymentTypes';
import { Background, ButtonsWrapper, CloseIcon, Content, DialogWrapper, Footer, FormFieldWrapper, Title, Wrapper } from './styles';

const AddNewPurpose: React.FC<IAddNewPurpose> = ({
  secondButtonLabel,
  companyList,
  label,
  handleCloseDialog,
  handleAgree,
}) => {
  const formik = useFormik({
    validationSchema: purposeSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: handleAgree,
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, setValues } = formik;

  const companiesOptions = useMemo(() => {
    return companyList.map(company => ({ value: company.id, title: company.name }));
  }, [companyList]);

  useEffect(() => {
    if (values.paymentRequired) {
      const defaultPayments = [PAYMENT_TYPE.CRYPTO, PAYMENT_TYPE.CARD];
      setValues({ ...values, expensesRequired: false, paymentTypes: defaultPayments });
    } else {
      setValues({ ...values, paymentTypes: [] });
    }
  }, [values.paymentRequired]);

  useEffect(() => {
    if (values?.type === PurposeTypes.EXTERNAL) {
      setValues({ ...values, expensesRequired: false });
    }
  }, [values?.type]);

  return (
    <>
      <Background onClick={() => handleCloseDialog()} />

      <DialogWrapper>
        <Wrapper>
          <FormikProvider value={formik}>
            <Form>
              <Title>
                <Text size={18}>{label}</Text>

                <CloseIcon onClick={() => handleCloseDialog()}>
                  <CloseSvg />
                </CloseIcon>
              </Title>
                  
              <Content>
                <FormFieldWrapper>
                  <FormField
                    errorPosition={-20}
                    fieldName="label"
                    inputSize="xlarge"
                    component={TextInput}
                    fieldId="label"
                    isError={touched?.label && errors?.label}
                    errors={touched?.label && errors}
                    label="Trip purpose title"
                    value={values.label}
                    type="text"
                    onChange={(e) => setFieldValue('label', e.target.value)}
                    onBlur={() => setFieldTouched('label')}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <Checkbox
                    checked={values?.type === PurposeTypes.EXTERNAL}
                    label={'For external bookings'}
                    onChange={(e) => setFieldValue('type', e.target.checked ? PurposeTypes.EXTERNAL : PurposeTypes.INTERNAL)}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.companies}
                    placeholder='Companies'
                    isMulti
                    isError={!!(touched?.companies && errors?.companies)} 
                    error={(touched?.companies && errors?.companies) as string}
                    items={companiesOptions}
                    onChange={(value) => {
                      setFieldValue('companies', value);
                      setFieldTouched('companies', true, false);
                    }}
                    onBlur={() => setFieldTouched('companies', true, false)}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <Checkbox
                    checked={values.paymentRequired}
                    label={'Payment required'}
                    onChange={e => setFieldValue('paymentRequired', e.target.checked)}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.paymentTypes}
                    placeholder='Payment types'
                    isDisabled={!values.paymentRequired}
                    isMulti
                    isError={!!(touched?.paymentTypes && errors?.paymentTypes)} 
                    error={(touched?.paymentTypes && errors?.paymentTypes) as string}
                    items={paymentTypeOptions.map(paymentTypeOption => ({ ...paymentTypeOption, title: paymentTypeOption.label }))}
                    onChange={(value) => {
                      setFieldValue('paymentTypes', value);
                      setFieldTouched('paymentTypes', true, false);
                    }}
                    onBlur={() => setFieldTouched('paymentTypes', true, false)}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <Checkbox
                    disabled={values?.type === PurposeTypes.EXTERNAL || values?.paymentRequired}
                    checked={values.expensesRequired}
                    label={'Expense report required'}
                    onChange={e => setFieldValue('expensesRequired', e.target.checked)}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FormField
                    errorPosition={-20}
                    inputSize="xlarge"
                    fieldName="flightBookingMargin"
                    component={TextInput}
                    fieldId="flightBookingMargin"
                    isError={touched?.flightBookingMargin && errors?.flightBookingMargin}
                    errors={touched?.flightBookingMargin && errors}
                    label="Flight booking margin"
                    value={values.flightBookingMargin}
                    type="number"
                    onChange={(e) => setFieldValue('flightBookingMargin', e.target.value)}
                    onBlur={() => setFieldTouched('flightBookingMargin')}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FormField
                    errorPosition={-20}
                    component={TextInput}
                    fieldName="accommodationBookingMargin"
                    inputSize="xlarge"
                    fieldId="accommodationBookingMargin"
                    isError={touched?.accommodationBookingMargin && errors?.accommodationBookingMargin}
                    errors={touched?.accommodationBookingMargin && errors}
                    value={values.accommodationBookingMargin}
                    label="Accommodation booking margin"
                    type="number"
                    onChange={(e) => setFieldValue('accommodationBookingMargin', e.target.value)}
                    onBlur={() => setFieldTouched('accommodationBookingMargin')}
                  />
                </FormFieldWrapper>
              </Content>

              <Footer>
                <ButtonsWrapper>
                  <Button type="button" variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
                  {handleAgree && secondButtonLabel && (
                    <Button type="submit" disabled={!isEmpty(errors)}>
                      {secondButtonLabel}
                    </Button>
                  )}
                </ButtonsWrapper>
              </Footer>
            </Form>
          </FormikProvider>
        </Wrapper>
      </DialogWrapper>
    </>
  );
};

export default AddNewPurpose;
