import Yup from '../../utils/validation';

const validateMessages = {
  name: {
    min: 'Name is cannot be less than 1 characters',
    max: 'Name is cannot be longer than 255 characters',
    required: 'Name is cannot be empty',
  },
  timeAndFrequency: {
    min: 'Time and frequency is cannot be less than 1 characters',
    max: 'Time and frequency is cannot be longer than 255 characters',
    required: 'Time and frequency is cannot be empty',
  },
  description: {
    max: 'Description is cannot be longer than 1000 characters',
    required: 'Description is cannot be empty',
  },
};

export const schema = Yup.object().shape({
  name: Yup
    .string()
    .min(1, validateMessages.name.min)
    .max(255, validateMessages.name.max)
    .withoutSpaces()
    .required(validateMessages.name.required),
  timeAndFrequency: Yup
    .string()
    .max(255, validateMessages.timeAndFrequency.max)
    .min(1, validateMessages.timeAndFrequency.min)
    .withoutSpaces()
    .required(validateMessages.timeAndFrequency.required),
  description: Yup
    .string()
    .max(1000, validateMessages.description.max)
    .withoutSpaces(),
});
