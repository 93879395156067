export const defineDeleteText = ({
  isActiveAllCustomHotelsCheckboxes,
  customHotelsCheckboxesData,
}) => {
  let title = 'Do you want to delete ';

  if ((customHotelsCheckboxesData && customHotelsCheckboxesData.length > 1) || isActiveAllCustomHotelsCheckboxes) {
    return `${title} these custom hotels?`;
  }

  return `${title} this custom hotel?`;
};
