import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import { setIsChangedPurpose, showPurposeOverview } from './actions';
import { SET_PURPOSES } from './actionTypes';

export const fetchPurposes = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const {
      data: { records, total },
    } = await request.get('tools/purposes', { params });

    dispatch({ type: SET_PURPOSES, payload: { records, total } });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const addNewPurpose = (data) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));

    await request.post('tools/purposes', data);

    dispatch(setIsChangedPurpose());

    getErrorStatusMessage({
      status: 200,
      message: 'New purpose successfully added',
    });

    return;
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const removePurpose = (purposeIds) => () => {
  return Promise.allSettled(
    purposeIds.map((purposeId) => {
      return request
        .delete(`tools/purposes/${purposeId}`)
        .then(() => {
          getErrorStatusMessage({
            status: 200,
            message: `Purpose ID - ${purposeId} successfully removed.`,
          });
        })
        .catch((e) => {
          getErrorStatusMessage({
            status: e?.response?.status,
            message: `Purpose ID - ${purposeId} can't be removed.`,
          });
        });
    })
  );
};

export const updatePurpose = (purposeId, purpose) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingData(true));

    await request.update(`tools/purposes/${purposeId}`, purpose);

    const { overviewPurposeData } = getState().purposesReducer;

    dispatch(showPurposeOverview({
      ...overviewPurposeData,
      ...purpose,
      title: purpose?.label,
    }));
  
    dispatch(setIsChangedPurpose());

    getErrorStatusMessage({
      status: 200,
      message: 'Purpose successfully updated',
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};
