import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { links } from './links';
import './TopNav.scss';

const TopNav = ({ userRole }) => {
  const { t } = useTranslation();

  return (
    <div className="header-topnav">
      <ul>
        {/* @ts-ignore */}
        {links.map(({ requiredRoles, label, icon, to }) => (
          requiredRoles.includes(userRole) && (
            <li key={label}>
              <NavLink to={to}>
                {/* @ts-ignore */}
                <FontAwesomeIcon icon={icon} size="1x" />
                {t(`template.nav.${label}`)}
              </NavLink>
            </li>
          )
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ currentUser }) => ({
  userRole: currentUser && currentUser.currentUserData && currentUser.currentUserData.role ? currentUser.currentUserData.role.name : null,
});

export default connect(mapStateToProps)(TopNav);
