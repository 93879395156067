import React, { useEffect, useMemo, useCallback } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';
import { getSubscriptionStatusLabel } from '../../../utils/tableHelpers';
import Drawer from '../../../components/Drawer';
import SubscriptionOverviewDrawerContent from '../../../components/SubscriptionOverviewDrawerContent';
import { ISubscriptions } from './types';
import { ItemSeparator } from '../Companies/styles';
import { PageHeader, TableWrapper, Title } from '../styles';
import {
  SubscriptionCard,
  SubscriptionCardDescription,
  SubscriptionCardEditButton,
  SubscriptionCardEmail,
  SubscriptionCardFooter,
  SubscriptionCardTitle,
  SubscriptionLogo,
  SubscriptionsWrapper,
} from './styles';
import Tooltip from '../../../components/Tooltip';

const Subscriptions: React.FC<ISubscriptions> = ({
  fetchSubscriptions,
  subscriptions,
  filters,
  setQueryParams,
  showSubscriptionOverview,
  closeSubscriptionOverview,
  overviewSubscriptionsData,
  isOpenSubscriptionsOverview,
  updateSubscription,
  fetchSubscriptionsLogs,
  subscriptionLogs,
}) => {
  const params = useMemo(() => setQueryParams(), [filters]);
  const dispatch = useDispatch();

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const handleOpenOverview = useCallback(async (subscription) => {
    fetchSubscriptionsLogs(subscription.id);
    dispatch(showSubscriptionOverview(subscription));
  }, []);

  useEffect(() => {
    fetchSubscriptions(params);
  }, [filters]);

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Subscriptions</Title>
        </PageHeader>

        {isLoadingData ? (
          <Spinner />
        ) : (
          <SubscriptionsWrapper>
            {subscriptions.length ? (
              subscriptions.map((subscription) => {
                return (
                  <SubscriptionCard>
                    <SubscriptionLogo src={`${process.env.REACT_APP_API_URL}/api/v1/static${subscription.image}`} />

                    <div>
                      <SubscriptionCardTitle
                        onClick={() => subscription.link && window.open(subscription.link, '_blank')}
                        hasLink={subscription.link}
                      >
                        {subscription?.title ?? ''}
                      </SubscriptionCardTitle>
                    </div>

                    <SubscriptionCardDescription>{subscription?.description ?? ''}</SubscriptionCardDescription>

                    {subscription?.plan && <SubscriptionCardEmail>{subscription?.plan}</SubscriptionCardEmail>}

                    <SubscriptionCardFooter>
                      {getSubscriptionStatusLabel(subscription?.status)}

                      {subscription?.email && (
                        <>
                          <ItemSeparator>•</ItemSeparator>
                          <SubscriptionCardEmail>{subscription?.email}</SubscriptionCardEmail>
                        </>
                      )}
                    </SubscriptionCardFooter>

                    <SubscriptionCardEditButton>
                      <Tooltip label="Edit">
                        <Button iconOnly size="small" onClick={() => handleOpenOverview(subscription)}>
                          <GenericEdit />
                        </Button>
                      </Tooltip>
                    </SubscriptionCardEditButton>
                  </SubscriptionCard>
                );
              })
            ) : (
              <Text size={14} textAlign="center" styles={{ marginBottom: 0 }}>
                No records were found
              </Text>
            )}
          </SubscriptionsWrapper>
        )}
      </TableWrapper>

      {isOpenSubscriptionsOverview && (
        <div>
          <Drawer
            handleClose={closeSubscriptionOverview}
            title={overviewSubscriptionsData?.title ?? ''}
            status={overviewSubscriptionsData.status}
            getStatusLabel={getSubscriptionStatusLabel}
            withStatus
          >
            <SubscriptionOverviewDrawerContent
              data={overviewSubscriptionsData}
              handleUpdateSubscription={updateSubscription}
              subscriptionLogs={subscriptionLogs}
            />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Subscriptions;
