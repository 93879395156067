import { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { isEmpty, uniqBy } from 'lodash';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { employerSchema } from './schema';
import { IAddNewEmployer } from './types';
import {
  FormFieldWrapper,
  ButtonsWrapper,
} from './styles';
import { onCompanyChangeHandler } from '../../pages/Tools/Employers/utils';
import CustomSelect from '../CustomSelect';
import Dialog from '../Dialog';
import { getBusinessVerticalsAccountants, getBusinessVerticalType } from '../../../src/utils/employers';
import { filterBusinessVerticalsByType } from '../../utils/filters';
import { EmployerTypes } from '../../enums/EmployerTypes';

const AddNewTeamDialog: React.FC<IAddNewEmployer> = ({
  companies,
  handleCloseDialog,
  handleConfirm,
}) => {
  const initialValues = {
    registrationNumber: '',
    businessVerticals: [],
    accountant: null,
    company: null,
    name: '',
    type: EmployerTypes.INTERNAL,
  };
  const [accountants, setAccountants] = useState([]);
  const [employerType, setEmployerType] = useState(initialValues.type);
  const [businessVerticals, setBusinessVerticals] = useState([]);
  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);

  const assignBusinessVerticalsByType = (businessVerticals, type) => {
    if (!type) {
      return businessVerticals;
    }
    return setBusinessVerticalsByType(filterBusinessVerticalsByType(businessVerticals, type));
  };

  const updateBusinessVerticalsByType = (businessVerticals, type) => {
    assignBusinessVerticalsByType(businessVerticals, type);
  };

  useEffect(() => {
    updateBusinessVerticalsByType(businessVerticals, employerType);
  }, [businessVerticals, employerType]);

  return (
    <Dialog
      withFooter={false}
      title="Add employer"
      onClose={handleCloseDialog}
    >
      <Formik
        validationSchema={employerSchema}
        validateOnMount
        validateOnBlur
        initialValues={initialValues}
        onSubmit={(values, { resetForm }): void => {
          handleConfirm(values);
          resetForm();
        }}
      >
        {({ errors, values, touched, resetForm, setFieldValue, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <FormFieldWrapper>
                <FormField
                  errorPosition={-20}
                  component={TextInput}
                  fieldName="name"
                  inputSize="xlarge"
                  fieldId="name"
                  isError={touched?.name && errors?.name}
                  errors={touched?.name && errors}
                  value={values.name}
                  label="Employer name"
                  type="text"
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  onBlur={() => setFieldTouched('name')}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.company}
                  placeholder="Company"
                  isError={touched?.company && errors?.company}
                  error={touched?.company && errors?.company}
                  items={companies}
                  onChange={(newCompanyId) => {
                    onCompanyChangeHandler(values.company, newCompanyId, setFieldValue, setBusinessVerticals);
                  }}
                  onBlur={() => setFieldTouched('company')}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <FormField
                  errorPosition={-20}
                  fieldName="registrationNumber"
                  inputSize="xlarge"
                  component={TextInput}
                  fieldId="registrationNumber"
                  isError={touched?.registrationNumber && errors?.registrationNumber}
                  errors={touched?.registrationNumber && errors}
                  value={values.registrationNumber}
                  type="text"
                  label="Registration number"
                  onChange={(e) => setFieldValue('registrationNumber', e.target.value)}
                  onBlur={() => setFieldTouched('registrationNumber')}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <Checkbox
                  checked={values?.type === EmployerTypes.EXTERNAL}
                  label={'For external users'}
                  onChange={(event) => {
                    const type = event.target.checked ? EmployerTypes.EXTERNAL : EmployerTypes.INTERNAL;
                    setEmployerType(type);
                    setFieldValue('type', type);
                    setFieldValue('accountant', null);
                    setFieldValue('businessVerticals', []);
                  }}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.businessVerticals}
                  isSearchable
                  placeholder="Business Verticals"
                  isDisabled={!values.company}
                  isMulti
                  isClearable
                  items={businessVerticalsByType}
                  onChange={value => {
                    setFieldValue('accountant', null);
                    setFieldValue('businessVerticals', value);
                    setAccountants(getBusinessVerticalsAccountants(businessVerticals, value));
                  }}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.accountant}
                  isSearchable
                  placeholder="Accountant"
                  isDisabled={!values.company || !values.businessVerticals?.length}
                  isClearable
                  isError={touched?.accountant && errors?.accountant}
                  error={touched?.accountant && errors?.accountant}
                  items={accountants}
                  onChange={value => setFieldValue('accountant', value)}
                  onBlur={() => setFieldTouched('accountant')}
                />
              </FormFieldWrapper>

              <ButtonsWrapper>
                <Button
                  variant="secondary"
                  onClick={() => {
                    resetForm();
                    handleCloseDialog();
                  }}
                >Cancel</Button>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isEmpty(errors)}
                >Add</Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Dialog>   
  );
};

export default AddNewTeamDialog;
