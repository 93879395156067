import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LIMIT_PER_PAGE as limitPerPage} from '../../../constants';
import { Button, Text } from '@heathmont/moon-core';
import { ControlsPlus } from '@heathmont/moon-icons';
import TablePagination from '../../../components/TablePagination';
import { Spinner } from '../../../components/Spinner';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { useCustomTable } from '../../../hooks/useCustomTable';
import { RowHeight } from '../../Reports/suggestions';
import { DEFAULT_SORTING } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllAllowancesCheckboxData,
  setAllowancesCheckboxData,
  updateAllowanceOptions,
  fetchDailyAllowances,
  setShouldRefreshOff,
  addNewAllowance,
  deleteAllowance,
  updateAllowance,
} from '../../../store/tools/dailyAllowances/actions';
import { fetchOptionsData } from '../../../store/expenses/actions';
import { fetchCompanies } from '../../../store/tools/companies/actions';
import Drawer from '../../../components/Drawer';
import AddNewDailyAllowance from '../../../components/AddNewDailyAllowance';
import { PageHeader, TableWrapper, Title } from '../styles';
import Dialog from '../../../components/Dialog';
import DailyAllowanceOverviewDrawerContent from '../../../components/DailyAllowanceOverviewDrawerContent';
import DownDrawer from '../../../components/DownDrawer';
import { useQuery } from '../../../hooks/useQuery';
import { prepareFilters } from '../../../utils/filters';

const LIMIT_PER_PAGE = limitPerPage.TOOLS.DAILY_ALLOWANCES;

function DailyAllowances({filters}) {  

  const dispatch = useDispatch();

  const isActiveAllAllowancesCheckboxes = useSelector(
    (state: any) => state.allowancesReducer.isActiveAllAllowancesCheckboxes
  );
  const allowancesCheckboxesData = useSelector((state:any) => state.allowancesReducer.allowancesCheckboxesData)
  const dailyAllowancesData = useSelector((state: any) => state.allowancesReducer.dailyAllowances)
  const currenciesOptions = useSelector((state: any) => state.expensesReducer.options.currencies)
  const companiesOptions = useSelector((state: any) => state.companiesReducer.companies)
  const shouldRefresh = useSelector((state: any) => state.allowancesReducer.shouldRefresh)
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const totalCount = useSelector((state: any) => state.allowancesReducer.totalCount)
  
  const [isDeletePurposeModal, setIsDeletePurposeModal] = useState(false);
  const [selectedAllowanceId, setSelectedAllowanceId] = useState(null);
  const [deletableAllowances, setDeletableAllowances] = useState([]);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [isOverviewOpen,setIsOverviewOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.TOOLS.DAILY_ALLOWANCES);

  const selectedAllowance = dailyAllowancesData.find((allowance) => allowance.id === selectedAllowanceId)
  const queryParams = useRef('');
  const totalItems = useRef(0);
  
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable(totalItems, LIMIT_PER_PAGE);

  const { setQuery } = useQuery();

  const columns = useMemo(() => initialColumns, []);
  
  const queryWithSortBy = useMemo(() => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }
    
    setQuery(params.filters);

    return params;
  }, [perPage, page, sortBy, filters])

  const formatedDailyAllowances = useMemo(
    () =>
      dailyAllowancesData.map((allowance) => ({
        ...allowance,
        currency: { ...allowance.currency, code: allowance.currency.code.toUpperCase()},
        company: allowance.company.name ? allowance.company : { ...allowance.company, name: "Default"},
      })),
    [dailyAllowancesData]
  );

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete';

    if ((allowancesCheckboxesData && allowancesCheckboxesData.length > 1) || isActiveAllAllowancesCheckboxes) {
      return `${title} these allowances?`;
    }

    return `${title} this allowance?`;
  }, [isActiveAllAllowancesCheckboxes, allowancesCheckboxesData]);


  useEffect(() => {
    dispatch(fetchDailyAllowances(queryWithSortBy))
    dispatch(fetchOptionsData())
    dispatch(fetchCompanies())
   }, [])
 
  useEffect(() => {
    if(shouldRefresh){
      dispatch(fetchDailyAllowances(queryWithSortBy))
    }
    return () => {
      dispatch(setShouldRefreshOff());
    }
  }, [shouldRefresh])

  useEffect(() => {
    setIsOverviewOpen(false)
    dispatch(fetchDailyAllowances(queryWithSortBy))
  }, [queryWithSortBy])

  useEffect(() => {
    setPage(1);
  }, [filters])

  useEffect(() => {
    totalItems.current = totalCount;
  }, [totalCount])

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);  

  const handleCloseAddNewPurpose = () => {
    setIsAddModalOpen(false);
  }
    
  const handleAddNewAllowance = async (values) => {
    const {company, currency } = values;
    dispatch(addNewAllowance({company, currency}))
    setIsAddModalOpen(false);
  }

  const handleUpdateAllowanceType = async (values) => {
    const { company, currency } = values;
    dispatch(updateAllowance({allowanceId: selectedAllowance.id,company, currency}))
    setIsOverviewOpen(false);
  }
  const handleUpdateOptions = async (values) => {
    const {options} = values
    dispatch(updateAllowanceOptions({allowanceId: selectedAllowance.id, options}))
  }

  const handleOpenOverview = (row) => {
    setIsOverviewOpen(true);
    setSelectedAllowanceId(row.original.id)
  }

  const handleOpenRemoveDialog = (checkboxesData) => {
    setDeletableAllowances(checkboxesData.map((allowance) => allowance.allowanceId))
    setIsDeletePurposeModal(true);
  }
  const handleDeleteTableAction = (allowance) => {
    setDeletableAllowances([allowance.id])
    setIsDeletePurposeModal(true);
  }

  const handleRemoveAllowanceType = async (allowances) => {
    await dispatch(deleteAllowance(allowances));
    dispatch(setAllowancesCheckboxData([]));
    dispatch(setAllAllowancesCheckboxData());
    setIsDeletePurposeModal(false);
  }

  const closeDeletePurposeModal = () => {
    setIsDeletePurposeModal(false);
  }
  const closePurposeOverview = () => {
    setIsOverviewOpen(false);
  }

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };
  const handleSelectAllAllowances = (data, isActive) => {
    dispatch(setAllAllowancesCheckboxData(data, isActive))
  }
  const handleSetAllowanceCheckbox = (data) => {
    dispatch(setAllowancesCheckboxData(data))
  }
  const tableContentRecieverProps = {
    isActiveAllAllowancesCheckboxes,
    isShowTableFilters,
    isToolsAllowancesPage: true,
    initialColumns: columns,
    checkboxesData: allowancesCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE,
    withCheckboxRow: true,
    queryParams: queryParams.current,
    isExpenses: false,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: formatedDailyAllowances,
    handleIsOverviewOpen: handleOpenOverview,
    handleSetAllCheckboxs: handleSelectAllAllowances,
    handleRemoveDialog:  !hoveredRow?.original?.default && handleDeleteTableAction,
    toggleTableFilters,
    handleSetCheckbox: handleSetAllowanceCheckbox,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Daily Allowances</Title>
          <Button variant="primary" size="small" onClick={() => setIsAddModalOpen(true)}>
            <ControlsPlus /> Add daily allowance type
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE}
          totalItems={totalItems}
          data={formatedDailyAllowances}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => dispatch(fetchDailyAllowances(queryWithSortBy))}
          nextPage={nextPage}
          prevPage={prevPage}
        />
        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isAddModalOpen && (
          <AddNewDailyAllowance 
            secondButtonLabel="Add"
            currencyList={currenciesOptions}
            companyList={companiesOptions}
            handleCloseDialog={handleCloseAddNewPurpose}
            handleAgree={handleAddNewAllowance}
          />
        )}
        {isDeletePurposeModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableAllowances}
          onSubmit={handleRemoveAllowanceType}
          onClose={closeDeletePurposeModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}
      {isOverviewOpen && selectedAllowance && (
        <div>
          <Drawer handleClose={closePurposeOverview} title={`${selectedAllowance?.company?.name ? selectedAllowance.company.name : "Default"}`}>
            <DailyAllowanceOverviewDrawerContent
              data={selectedAllowance}
              handleUpdateAllowance={handleUpdateAllowanceType}
              handleUpdateOptions={handleUpdateOptions}
            />
          </Drawer>
        </div>
      )}
      {!!allowancesCheckboxesData.length && (
        <DownDrawer
          data={allowancesCheckboxesData}
          text="allowance"
          handleRemoveDialog={handleOpenRemoveDialog}
          handleSetCheckbox={handleSetAllowanceCheckbox}
        />
      )}
    </>
  )
}

export default DailyAllowances