import currentUserModel from '../../models/currentUser';
import { SET_CURRENT_USER_LOADING, SET_CURRENT_USER_LOADED, SET_CURRENT_USER } from './actionTypes';

export const setCurrentUserLoading = (isCurrentUserLoading) => ({
  type: SET_CURRENT_USER_LOADING,
  data: {
    isCurrentUserLoading,
  },
});

export const setCurrentUserLoaded = (isCurrentUserLoaded) => ({
  type: SET_CURRENT_USER_LOADED,
  data: {
    isCurrentUserLoaded,
  },
});

export const setCurrentUser = (currentUserData) => (dispatch) => {
  currentUserModel.set(currentUserData);

  return dispatch({
    type: SET_CURRENT_USER,
    data: {
      currentUserData,
    },
  });
};
