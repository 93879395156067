import { Form, Formik } from "formik";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { ButtonsWrapper, FormFieldWrapper  } from "./styles";
import { IAddNewDailyAllowance } from "./types";
import CustomSelect from "../CustomSelect";
import { allowanceTypeSchema } from "./schema";
import Dialog from "../Dialog";
import { Button } from "@heathmont/moon-core";

const AddNewDailyAllowance: React.FC<IAddNewDailyAllowance> = ({
  secondButtonLabel,
  currencyList,
  companyList,
  handleCloseDialog,
  handleAgree,
}) => {

  const companiesOptions = useMemo(() => companyList.map(company => ({ value: company.id, title: company.name })), [companyList]);

  return (
    <Dialog title="Add daily allowance type" withFooter={false} onClose={() => handleCloseDialog()}>
      <Formik
        isInitialValid
        validateOnBlur={true}
        validationSchema={allowanceTypeSchema}
        initialValues={{ company: '', currency: '' }}
        onSubmit={(values, { resetForm }): void => {
          handleAgree(values);
          resetForm();
        }}
      >
        {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.company}
                  placeholder="Company"
                  isError={touched?.company && errors?.company}
                  error={touched?.company && errors?.company}
                  items={companiesOptions}
                  onChange={(value) => {
                    setFieldValue('company', value);
                    setFieldTouched('company', true, false);
                  }}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.currency}
                  placeholder="Currency"
                  isError={touched?.currency && errors?.currency}
                  error={touched?.currency && errors?.currency}
                  items={currencyList}
                  onChange={(value) => {
                    setFieldValue('currency', value);
                    setFieldTouched('currency', true, false);
                  }}
                />
              </FormFieldWrapper>
                <ButtonsWrapper>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      handleCloseDialog();
                    }}
                    >Cancel</Button>
                  {handleAgree && secondButtonLabel && (
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={!isEmpty(errors)}
                    >{secondButtonLabel}</Button>
                  )}
                </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddNewDailyAllowance;