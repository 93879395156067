import { FilesCopy } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { ICopyToClipboard } from './types';
import { CopyToClipboardStyled, TextWrapper } from './styles';
import EllipsisText from '../EllipsisText';
import { showToast } from '../../utils/commonFunctions';

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ text }) => {
  const copy =() => {
    showToast('Text copied', 'success');
    navigator.clipboard.writeText(text);
  };

  return (
    <CopyToClipboardStyled>
      <TextWrapper>
        <EllipsisText text={text}/>
      </TextWrapper>
      <Button variant="secondary" iconOnly size="small" type="button" onClick={copy}>
        <FilesCopy />
      </Button>
    </CopyToClipboardStyled>
  );
};

export default CopyToClipboard;
