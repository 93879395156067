import styled from 'styled-components';

export const DialogWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 16px;
  transition: transform 0.15s ease-in-out;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '740px')};
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  min-height: 264px;

  ${({ withoutMinWidth }) =>
    !withoutMinWidth
      ? `
     min-width: 480px;
  `
      : `
     min-width: 300px;
  `}
  ${({ isConfirmationOn }) =>
    isConfirmationOn
      ? `
      transform: scaleY(0);
            `
      : `
      transform: scaleY(1);
      `}
`;
export const ConfirmationWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 16px;
  transition: transform 0.15s ease-in-out;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '740px')};
  min-height: 264px;

  ${({ withoutMinWidth }) =>
    !withoutMinWidth
      ? `
     min-width: 480px;
  `
      : `
     min-width: 300px;
  `}
  ${({ isConfirmationOn }) =>
    isConfirmationOn
      ? `
      transform: translate(-50%, -50%) scaleY(1);
  `
      : `
      transform:  translate(-50%, -50%) scaleY(0);
      `}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;

  & p {
    line-height: 24px;
  }
`;

export const TitleText = styled.div`
  font-weight: 700;
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;
`;

export const DeleteButton = styled.div`
  button {
    background-color: ${({ theme }) => theme.color.chiChi['100']};
  }
`;