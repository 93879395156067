import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const OverviewWrapper = styled.div``;

export const OverviewContentWrapper = styled.div``;

export const OverviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
`;

export const OverviewContentName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 154px;
  width: 100%;
`;

export const OverviewContentValue = styled.span`
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 175px;
  width: 100%;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}
`;

export const OverviewContentValueSelect = styled.span`
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  width: 187px;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}
`;

export const EditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const DeleteButton = styled.div`
  button {
    background-color: ${({ theme }) => theme.color.chiChi['100']};
  }
`;

export const SlackChannelWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

