
import moment from 'moment';
import request from '../../../models/request';

import { DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from "../../../constants";

const getFormattedCities = (cities) => cities.map((city) => ({
    title: `${city.name} (${city.country})`,
    value: city.id,
  }));

const fetchCities = async ({ search, setFieldValue, setCitiesOptions, setHotelsOptions, setIsCitiesLoading}) => {
  if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
    setCitiesOptions([]);
    setHotelsOptions([]);
    setIsCitiesLoading(true);

    try {
      const { data } = await request.get(`locations/cities`, { params: { search } });

      setCitiesOptions(getFormattedCities(data.airports || []));
      
      if (setFieldValue) {
        setFieldValue('cityId', null);
        setFieldValue('hotelId', null);
      }
    } catch (err) {
      setCitiesOptions([]);
    } finally {
      setIsCitiesLoading(false);
    }
  }
};

const getFormattedHotels = ({hotels}) => hotels.map((hotel) => ({
  title: `${hotel.name}`,
  value: hotel.id,
}));

const fetchHotels = async ({ search, regionId, setFieldValue, setHotelsOptions, setIsHotelsLoading }) => {
  if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
    setHotelsOptions([]);
    setIsHotelsLoading(true);

    try {
      const { data } = await request.get(`hotels`, { params: { search, regionId } });
      setHotelsOptions(getFormattedHotels(data || []));
      setFieldValue && setFieldValue('hotelId', null);
    } catch(err) {
      setHotelsOptions([]);
    } finally {
      setIsHotelsLoading(false); 
    }
  }
};

export const fetchTeamsByCompany = async ({ companyId, newCompanyId, setTeamsOptions }) => {
  const isCompanyChanged = newCompanyId !== companyId;
  if (isCompanyChanged) {
    try{
      const { data } = await request.get('tools/teams', { params: { team : { company: newCompanyId }}})

      if(data?.records?.length){
        setTeamsOptions(data?.records.map(({ id, name }) => ({ value: id, title: name })))
      } else {
        setTeamsOptions([])
      }
    } catch(err) {
      setTeamsOptions([]);
    }
  }
};

export const formAPIRecommendedHotelData = (data) => {
    const { startDate, endDate, ...rest } = data;

    return {
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      ...rest,
    }
  }; 

export { fetchCities, fetchHotels }