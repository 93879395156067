import { setAccommodationsIgnoreList } from './actions';
import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';

export const getAccommodationsIgnoreList = (params: any) => async (dispatch) => {
  try {
    const {
      data: { records, total },
    } = await request.get('tools/accommodations-ignore-list', { params });
    
    dispatch(setIsLoadingData(true));
    dispatch(setAccommodationsIgnoreList(records, total));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const addBookingToAccommodationsIgnoreList = (data, setLoading: (loading: boolean) => void, handleSuccess: () => void) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await request.post('tools/accommodations-ignore-list', data);
    handleSuccess();
    getErrorStatusMessage({
      status: 200,
      message: 'New booking is successfully added to ignore list',
    });
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeBookingFromAccommodationsIgnoreList = (bookingsForDelete, setLoading: (loading: boolean) => void, handleSuccess: () => void) => async (dispatch) => {
  dispatch(setLoading(true));
  await Promise.allSettled(
    bookingsForDelete.map((bookingId) => {
      return request
        .delete(`tools/accommodations-ignore-list/${bookingId}`)
        .then(() => {
          getErrorStatusMessage({
            status: 200,
            message: `Booking with ID - ${bookingId} successfully removed from accommodations ignore list`,
          });
        })
        .catch((e) => {
          getErrorStatusMessage({
            status: e?.response?.status,
            message: `Booking with ID - ${bookingId} can't be removed from accommodations ignore list`,
          });
        });
    })
  );
  dispatch(setLoading(false));
  handleSuccess();
};