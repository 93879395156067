import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  updateFeedback as updateFeedbackAction,
  setFeedbacks,
} from './actions';

export const fetchFeedbacks = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const {
      data: { records, total },
    } = await request.get('tools/feedbacks', { params });

    dispatch(setFeedbacks(records, total));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const updateFeedback = (feedbackId, data) => async (dispatch) => {
  try {
    const { data: { feedback } } = await request.update(`tools/feedbacks/${feedbackId}`, data);

    dispatch(updateFeedbackAction(feedbackId, feedback));
    getErrorStatusMessage({
      status: 200,
      message: `Feedback successfully updated!`,
    });
  } catch(err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};