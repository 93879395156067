import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import DynamicFormFields from '../../../../../components/DynamicFormFields';
import request from '../../../../../models/request';
import { IFavoriteHotels } from './types';
import { FavoriteHotelsWrapper, GroupInputWrapper } from './styled';
import SelectWithExternalDataSource from '../../../../../components/SelectWithExternalDataSource';

const FavoriteHotels: React.FC<IFavoriteHotels> = ({ userId, values, touched, errors, initialValues, setFieldValue }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.favoriteHotels, initialValues.favoriteHotels)) {
      dispatch(setIsChangedTabField('favorites', true));
    } else {
      dispatch(setIsChangedTabField('favorites', false));
    }
  }, [values.favoriteHotels, initialValues.favoriteHotels]);

  const getDefaultValue = () => {
    return { id: uuidv4(), userId, hotelId: undefined, regionId: undefined };
  };

  const formatDestinations = (cities) => {
    return cities.map((city) => ({
      title: `${city.name} (${city.country})`,
      value: city.id,
    }));
  };

  const getFormattedHotels = ({hotels}) => {
    return hotels.map((hotel) => ({
      title: `${hotel.name}`,
      value: hotel.id,
    }));
  };

  const fetchCities = async (params: { search?: string, id?: string | number }) => {
    try {
      const { data } = await request.get(`locations/cities`, { params });
      return formatDestinations(data.airports || []);
    } catch (err) {
      return [];
    }
  };

  const fetchHotels = async (params: { search?: string, id?: string | number }, regionId) => {
    try {
      const { data } = await request.get(`hotels`, { params: { ...params, regionId } });
      return getFormattedHotels(data || []);
    } catch(err) {
      return [];
    }
  };

  return (
    <FavoriteHotelsWrapper>
      <DynamicFormFields
        withoutLimit
        fieldName="favoriteHotels"
        touched={touched}
        errors={errors}
        data={values.favoriteHotels}
        setFieldValue={setFieldValue}
        getDefaultValue={getDefaultValue}
        renderItem={(fieldName, value, index) => (
          <GroupInputWrapper>
            <SelectWithExternalDataSource
              currentValue={value.regionId}
              placeholder='Select city'
              position="left"
              isError={!!(get(touched, [fieldName, index, 'regionId']) && get(errors, [fieldName, index, 'regionId']))}
              fetchOptions={search => fetchCities(search)}
              resetValues={() => {
                setFieldValue(`${fieldName}.${index}.regionId`, undefined)
                setFieldValue(`${fieldName}.${index}.hotelId`, undefined)
              }}
              onChange={(value) => {
                setFieldValue(`${fieldName}.${index}.regionId`, value)
                setFieldValue(`${fieldName}.${index}.hotelId`, undefined);
              }}
            />
            <SelectWithExternalDataSource
              dependOnFieldValue={value.regionId}
              isDependedFiled
              currentValue={value.hotelId}
              placeholder='Select hotel'
              isDisabled={!value.regionId}
              position="right"
              isError={!!(get(touched, [fieldName, index, 'hotelId']) && get(errors, [fieldName, index, 'hotelId']))}
              fetchOptions={search => fetchHotels(search, value.regionId)}
              resetValues={() => (
                setFieldValue(`${fieldName}.${index}.hotelId`, undefined)
              )}
              onChange={(value) => {
                setFieldValue(`${fieldName}.${index}.hotelId`, value);
              }}
            />
          </GroupInputWrapper>
        )}
      />
    </FavoriteHotelsWrapper>
  );
};

export default FavoriteHotels;
