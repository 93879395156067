import { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { Button, Text } from '@heathmont/moon-core';
import { IExternalFlightSegment } from '../types';
import CustomSelect from '../../CustomSelect';
import request from '../../../models/request';
import DatePicker from '../../../components/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, debounce } from 'lodash';
import FormField from '../../FormField';
import { TextInput } from '@heathmont/moon-components';
import { AddFlightSegmentSchema } from '../schema';
import { fetchOptions } from '../../../store/editProfile/actions';
import { useTranslation } from 'react-i18next';
import {
  setFlightSelectedAirlines,
  setFlightSelectedAirports,
} from '../../../store/trips/actions';
import {
  getSegmentInitialValues,
  getInitialAirlines,
  generateLocalId,
  formatAirports,
} from '../utils';
import {
  FormItemLabelWrapper,
  FormItemsWrapper,
  ButtonsWrapper,
  FormItemLabel,
  FormWrapper,
  FormItem,
} from '../styles';
import { DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../../constants';
import AdditionalServicesFields from './AdditionalServicesFields';
import { PassengerOption } from '../../../enums/PassengerOption';

function FlightSegmentForm({
  flightItineraryIndex,
  segmentData,
  passengers,
  handleClose,
}: {
  flightItineraryIndex: number;
  segmentData?: IExternalFlightSegment;
  passengers: PassengerOption[];
  handleClose: () => void;
}) {
  const cabinsOptions = useSelector((state: any) => state.tripsReducer.tripOptions.cabins);
  const selectedAirports = useSelector((state: any) => state.tripsReducer.externalTrip.selectedOptions.airports);
  const selectedAirlines = useSelector((state: any) => state.tripsReducer.externalTrip.selectedOptions.airlines);
  const [airportDepartureOptions, setAirportDepartureOptions] = useState([]);
  const [airportDepartureOptionsLoading, setAirportDepartureOptionsLoading] = useState(false);
  const [airportArrivalOptions, setAirportArrivalOptions] = useState([]);
  const [airportArrivalOptionsLoading, setAirportArrivalOptionsLoading] = useState(false);
  const [airlineOptions, setAirlineOptions] = useState(getInitialAirlines(segmentData, selectedAirlines));
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const { values: flightFormValues, setFieldValue: setFlightFormValue } = useFormikContext<any>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const fetchAirports = async ({
    iataCode, 
    search = '',
    type,
    setFieldValue,
  }: {
    iataCode?: string, 
    search?: string,
    type: string,
    setFieldValue?: (fieldName: string, value?: string | null) => void,
  }) => {
    if (search?.length >= 3 || iataCode) {
      
      if (type === 'departure') {
        setAirportDepartureOptions([]);
        setAirportDepartureOptionsLoading(true);
      } else {
        setAirportArrivalOptions([]);
        setAirportArrivalOptionsLoading(true);
      }

      try {
        const { data } = await request.get(`locations/airports`, { params: { search, iataCode } });
  
        if (type === 'departure') {
          setFieldValue && setFieldValue('departureCode', null);
          setAirportDepartureOptions(formatAirports(data.airports));
        } else {
          setFieldValue && setFieldValue('arrivalCode', null);
          setAirportArrivalOptions(formatAirports(data.airports));
        }
      } catch (err) {
        if (type === 'departure') {
          setAirportDepartureOptions([]);
        } else {
          setAirportArrivalOptions([]);
        }
      } finally {
        if (type === 'departure') {
          setAirportDepartureOptionsLoading(false);
        } else {
          setAirportArrivalOptionsLoading(false);
        }
      }
    }
  };

  const handleAddSegment = (values) => {
    const newSelectedAirports = [];
  
    if (!selectedAirports.find((airport) => airport?.value === values.departureCode)) {
      newSelectedAirports.push(airportDepartureOptions.find((airport) => airport.value === values.departureCode));
    }
  
    if (!selectedAirports.find((airport) => airport?.value === values.arrivalCode)) {
      newSelectedAirports.push(airportArrivalOptions.find((airport) => airport.value === values.arrivalCode));
    }
  
    const foundAirline = airlineOptions.find((airline) => airline.value === values.airline);
  
    dispatch(setFlightSelectedAirports(newSelectedAirports));
    dispatch(setFlightSelectedAirlines([foundAirline]));    
    flightFormValues.itineraries[flightItineraryIndex].segments.push({
      ...values,
      airlineType: foundAirline.type,
      id: generateLocalId(),
    });
    setFlightFormValue('itineraries', flightFormValues.itineraries);
    handleClose();
  };

  const handleEditSegment = (values) => {
    const newSelectedAirports = [];
  
    if (!selectedAirports.find((airport) => airport?.value === values.departureCode)) {
      newSelectedAirports.push(airportDepartureOptions.find((airport) => airport.value === values.departureCode));
    }
  
    if (!selectedAirports.find((airport) => airport?.value === values.arrivalCode)) {
      newSelectedAirports.push(airportArrivalOptions.find((airport) => airport.value === values.arrivalCode));
    }
  
    const foundAirline = airlineOptions.find((airline) => airline.value === values.airline);

    dispatch(setFlightSelectedAirports(newSelectedAirports));
    dispatch(setFlightSelectedAirlines([foundAirline]));
    flightFormValues.itineraries[flightItineraryIndex].segments = flightFormValues.itineraries[flightItineraryIndex].segments.map((segment) => segment.id === values.id ? values : segment);
    setFlightFormValue('itineraries', flightFormValues.itineraries);
  };

  const fetchAirlines = async (search, setFieldValue?) => {
    if (search.length >= 3) {
      setIsSearchLoading(true);
      setAirlineOptions([]);

      try {
        const { data } = await request.get(`airlines`, { params: { search } });
        setAirlineOptions(data.airlines);
        setFieldValue && setFieldValue('airline', null);
      } catch (err) {
        setAirlineOptions([]);
      } finally {
        setIsSearchLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchOptions(translate));
    if (segmentData?.arrivalCode && segmentData?.departureCode) {
      fetchAirports({ iataCode: segmentData.arrivalCode, type: 'arrival' });
      fetchAirports({ iataCode: segmentData.departureCode, type: 'departure' });
    }
  }, []);

  return (
    <Formik
      validationSchema={AddFlightSegmentSchema}
      validateOnBlur={true}
      initialValues={getSegmentInitialValues(segmentData)}
      onSubmit={(values, { resetForm }): void => {
        resetForm();
      }}
    >
      {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => {
        return (
          <FormWrapper>
            <strong>
              <Text size={16}>{segmentData?.editMode ? 'Edit' : 'Add'} segment</Text>
            </strong>
            <FormItemsWrapper>
              <FormItem isAirportSelect>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  currentValue={values.departureCode}
                  isSearchable
                  placeholder="Select departure airport"
                  isLoading={airportDepartureOptionsLoading}
                  isError={!!(touched?.departureCode && errors?.departureCode)}
                  error={touched?.departureCode && errors?.departureCode}
                  items={airportDepartureOptions}
                  onInputChange={debounce((search) => fetchAirports({ search, type: 'departure', setFieldValue }), 500)}
                  onChange={(value) => {
                    setFieldValue('departureCode', value);
                    setFieldTouched('departureCode', true, false);
                  }}
                />
              </FormItem>
              <FormItem isAirportSelect>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  currentValue={values.arrivalCode}
                  isSearchable
                  placeholder="Select arrival airport"
                  isLoading={airportArrivalOptionsLoading}
                  isError={!!(touched?.arrivalCode && errors?.arrivalCode)}
                  error={touched?.arrivalCode && errors?.arrivalCode}
                  items={airportArrivalOptions}
                  onInputChange={debounce((search) => fetchAirports({ search, type: 'arrival', setFieldValue }), 500)}
                  onChange={(value) => {
                    setFieldValue('arrivalCode', value);
                    setFieldTouched('arrivalCode', true, false);
                  }}
                />
              </FormItem>
            </FormItemsWrapper>

            <FormItemsWrapper>
              <FormItem>
                <DatePicker
                  format={'MMM dd, yyyy HH:mm'}
                  showTimeSelect
                  placeholder={'Select departure date'}
                  isEditView={true}
                  errors={touched?.departureAt && errors}
                  value={values.departureAt}
                  field="departureAt"
                  onChange={(field, value) => {
                    setFieldTouched(field, true);
                    setFieldValue(field, value);
                  }}
                />
              </FormItem>
              <FormItem>
                <DatePicker
                  format={'MMM dd, yyyy HH:mm'}
                  showTimeSelect
                  placeholder={'Select arrival date'}
                  isEditView={true}
                  minDate={values.departureAt}
                  errors={touched?.arrivalAt && errors}
                  value={values.arrivalAt}
                  field="arrivalAt"
                  onChange={(field, value) => {
                    setFieldTouched(field, true);
                    setFieldValue(field, value);
                  }}
                />
              </FormItem>
            </FormItemsWrapper>

            <FormItemsWrapper>
              <FormItem>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  noOptionsMessage={`The airline wasn't found. Please contact the technical team to have it added to the system. Now you can choose another one and replace it with the one you need in the future.`}
                  isSearchable
                  currentValue={values.airline}
                  placeholder={'Select airline'}
                  isLoading={isSearchLoading}
                  isError={!!(touched?.airline && errors?.airline)}
                  error={touched?.airline && errors?.airline}
                  items={airlineOptions}
                  onInputChange={debounce((search) => fetchAirlines(search, setFieldValue), 500)}
                  onChange={(value) => {
                    setFieldValue('airline', value)
                    setFieldTouched('airline', true, false);
                  }}
                />
              </FormItem>
              <FormItem>
                <FormField
                  placeholder={'Flight number'}
                  fieldName="flightNumber"
                  className={'text-input'}
                  component={TextInput}
                  inputSize="xlarge"
                  isError={touched?.flightNumber && errors?.flightNumber}
                  fieldId="flightNumber"
                  errors={touched?.flightNumber && errors}
                  value={values.flightNumber}
                  type="text"
                  onChange={(e) => setFieldValue('flightNumber', e.target.value)}
                  onBlur={handleBlur}
                />
              </FormItem>
            </FormItemsWrapper>

            <FormItemsWrapper>
              <FormItem>
                <FormField
                  placeholder={'Meals'}
                  className={'text-input'}
                  fieldName="meals"
                  component={TextInput}
                  inputSize="xlarge"
                  isError={touched?.meals && errors?.meals}
                  fieldId="meals"
                  errors={touched?.meals && errors}
                  value={values.meals}
                  type="text"
                  onChange={(e) => setFieldValue('meals', e.target.value)}
                  onBlur={handleBlur}
                />
              </FormItem>
              <FormItem>
                <CustomSelect
                  currentValue={values.cabin}
                  placeholder={'Select cabin'}
                  isError={!!(touched?.cabin && errors?.cabin)}
                  error={touched?.cabin && errors?.cabin}
                  items={cabinsOptions}
                  onChange={(value) => {
                    setFieldValue('cabin', value);
                    setFieldTouched('cabin', true, false);
                  }}
                />
              </FormItem>
            </FormItemsWrapper>

            <FormItemLabelWrapper>
              <FormItemLabel>Checked bag(s)</FormItemLabel>
            </FormItemLabelWrapper>
            <FormItemsWrapper>
              <FormItem>
                <FormField
                  placeholder={'Quantity'}
                  fieldName="includedCheckedBags.quantity"
                  component={TextInput}
                  className={'text-input'}
                  inputSize="xlarge"
                  fieldId="includedCheckedBags.quantity"
                  isError={touched?.includedCheckedBags?.quantity && errors?.includedCheckedBags?.quantity}
                  errors={touched?.includedCheckedBags?.quantity && errors}
                  value={values?.includedCheckedBags?.quantity}
                  type="number"
                  onChange={(e) => setFieldValue('includedCheckedBags.quantity', e.target.value)}
                  onBlur={handleBlur}
                />
              </FormItem>
              <FormItem>
                <FormField
                  placeholder={'Weight'}
                  fieldName="includedCheckedBags.weight"
                  component={TextInput}
                  className={'text-input'}
                  inputSize="xlarge"
                  isError={touched?.includedCheckedBags?.weight && errors?.includedCheckedBags?.weight}
                  postfix={values?.includedCheckedBags?.weightUnit}
                  fieldId="includedCheckedBags.weight"
                  errors={touched?.includedCheckedBags?.weight && errors}
                  value={values?.includedCheckedBags?.weight}
                  type="number"
                  onChange={(e) => setFieldValue('includedCheckedBags.weight', e.target.value)}
                  onBlur={handleBlur}
                />
              </FormItem>
            </FormItemsWrapper>

            <strong>
              <Text size={16}>Additional services:</Text>
            </strong>

            <AdditionalServicesFields
              additionalServices={values.additionalServices}
              passengers={passengers}
              segmentId={segmentData?.id}
              touched={touched?.additionalServices}
              errors={errors?.additionalServices}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            <ButtonsWrapper>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => segmentData?.editMode ? handleEditSegment(values) : handleAddSegment(values)}
                variant="primary"
                disabled={!isEmpty(errors) || Object.values(touched).length < 1}
              >
                {segmentData?.editMode ? 'Update' : 'Save'}
              </Button>
            </ButtonsWrapper>
          </FormWrapper>
        );
      }}
    </Formik>
  );
}

export default FlightSegmentForm;
