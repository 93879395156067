import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export enum RowHeight {
  S = '0px',
  M = '16px',
  L = '24px',
}

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      return <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}</span>;
    },
    SubCell: () => null,
  },
  {
    Header: 'Employer',
    accessor: 'employer',
  },
  {
    Header: 'Traveler name',
    accessor: 'travelerName',
  },
  {
    Header: 'Team',
    accessor: 'team',
  },
  {
    Header: 'Destination',
    accessor: 'destinationForReports',
    disableSortBy: true,
  },
  {
    Header: 'Purpose',
    accessor: 'purpose',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Payment date',
    accessor: 'paymentDate',
  },
  {
    Header: 'Payment method',
    accessor: 'paymentMethod',
    disableSortBy: true,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Trip time',
    accessor: 'tripStartDate',
  },
  {
    Header: 'Total cost, EUR',
    accessor: 'totalCost',
  },
  {
    Header: 'TTR link',
    accessor: 'tripTicketLinkInJira',
    disableSortBy: true,
  },
  {
    Header: 'Invoice amount, EUR',
    accessor: 'invoicePrice',
  },
];
