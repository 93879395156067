import Yup from '../../utils/validation';

const validateMessages = {
  condition: {
    required: 'Condition should be provided.',
  },
  conditionValue: {
    required: 'Condition should be provided.',
    type: 'Condition value has to be numeric',
    min: 'Condition value can not be negative',
  },
  action: {
    required: 'Action should be provided.',
  },
  actionValue: {
    required: 'Action value should be provided.',
    type: 'Action value has to be numeric',
    min: 'Action value can not be negative',
  },
};

export const customRuleFormSchema = Yup.object().shape({
  condition: Yup.number().required(validateMessages.condition.required),
  conditionValue: Yup.number()
    .required(validateMessages.conditionValue.required)
    .typeError(validateMessages.conditionValue.type)
    .min(1, validateMessages.conditionValue.min),
  action: Yup.number().required(validateMessages.action.required),
  actionValue: Yup.number()
    .required(validateMessages.actionValue.required)
    .typeError(validateMessages.actionValue.type)
    .min(1, validateMessages.actionValue.min),
});
