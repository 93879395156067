import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Spinner.scss';

const Spinner = () => (
  <div className="app-spinner">
    <FontAwesomeIcon icon="spinner" spin size="lg" />
  </div>
);

export default Spinner;
