import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
    {
      Header: () => null,
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
        </span>
      ),
      SubCell: () => null,
    },
    {
      Header: 'ID',
      accessor: 'purposeId',
      minWidth: 100,
      disableSortBy: false,
    },
    {
      Header: 'Name',
      accessor: 'purposeName',
      minWidth: 225,
      disableSortBy: false,
    },
    {
      Header: 'Companies',
      accessor: 'companies',
      minWidth: 225,
      height: 70,
      disableSortBy: true,
    },
    {
      Header: 'Payment',
      accessor: 'paymentRequired',
      minWidth: 180,
      disableSortBy: false,
    },
    {
      Header: 'Payment types',
      accessor: 'paymentTypes',
      minWidth: 180,
      disableSortBy: true,
    },
    {
      Header: 'Expense report',
      accessor: 'expensesRequired',
      minWidth: 180,
      disableSortBy: false,
    },
    {
      Header: 'Flight booking margin',
      accessor: 'flightBookingMargin',
      disableSortBy: false,
    },
    {
      Header: 'Accommodation booking margin',
      accessor: 'accommodationBookingMargin',
      disableSortBy: false,
    },
  ];