import {
  SET_ACTIONS_AND_NOTIFICATIONS,
  SHOW_ACTION_AND_NOTIFICATION_OVERVIEW,
  CLOSE_ACTION_AND_NOTIFICATION_OVERVIEW,
  SET_ACTION_AND_NOTIFICATION_LOGS,
  SET_TOOLS_ACTIONS_CHECKBOX_DATA,
  SET_TOOLS_ALL_ACTIONS_CHECKBOX_DATA,
  SET_TOOLS_IS_CHANGED_ACTION,
} from './actionTypes';

export const setActionsAndNotifications = (records, total) => {
  return { type: SET_ACTIONS_AND_NOTIFICATIONS, payload: { records, total } };
};

export const setActionsAndNotificationsLogs = (records) => {
  return { type: SET_ACTION_AND_NOTIFICATION_LOGS, payload: { records } };
};

export const showActionAndNotificationOverview = (data) => {
  return { type: SHOW_ACTION_AND_NOTIFICATION_OVERVIEW, payload: { data } };
};

export const closeActionAndNotificationOverview = () => {
  return { type: CLOSE_ACTION_AND_NOTIFICATION_OVERVIEW };
};

export const setActionsCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_ACTIONS_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllActionsCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_ACTIONS_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setIsChangedAction = (isChangedAction) => {
  return { type: SET_TOOLS_IS_CHANGED_ACTION, payload: { isChangedAction } };
};