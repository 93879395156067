import { useState, useEffect } from 'react';
import SubRows from './SubRows';
import request from '../../models/request';
import { reportsDataMapper, defineGroupById } from '../../utils/tableHelpers';
import { ISubRowAsync } from './types';

const SubRowAsync: React.FC<ISubRowAsync> = ({
  visibleColumns,
  queryParams = {},
  rowProps,
  groupBy,
  data: groupedTableData,
  row,
}) => {
  if (!groupBy) {
    return null;
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const groupById = defineGroupById(groupBy, groupedTableData, row);

  useEffect(() => {
    const fetchByGroup = async () => {
      try {
        const { data } = await request.get(`reports/by-group`, {
          params: {
            ...queryParams,
            groupById,
          },
        });

        setLoading(false);
        setData(reportsDataMapper(data.records));
      } catch (error) {}
    };
    fetchByGroup();
  }, []);

  return (
    <SubRows
      visibleColumns={visibleColumns}
      rowProps={rowProps}
      loading={loading}
      groupBy={groupBy}
      data={data}
      row={row}
    />
  );
};

export default SubRowAsync;
