import { GenericCheckAlternative, GenericAlarm } from '@heathmont/moon-icons';
import { ICancellationPenalty } from './types';
import { CancellationPenaltyWrapper, AdditionItemTitle } from './styles';
import {
  isAccommodationCancelable,
  getCancellationPolicies,
  getFlightDelayToCancel,
  getCancellationFeeText,
  isFlightCancelable,
} from '../../utils/trips';
import { AdditionItemsWrapper, AdditionItem } from '../../styled';

const CancellationPenalty: React.FC<ICancellationPenalty> = ({ accommodation, flight }) => {
  const accommodationPolicies = getCancellationPolicies(accommodation);
  const delayToCancel = getFlightDelayToCancel(flight);

  return (
    <CancellationPenaltyWrapper>
      <AdditionItemsWrapper>
        <AdditionItemTitle>Cancellation penalty:</AdditionItemTitle>
      </AdditionItemsWrapper>
      {accommodation &&
        isAccommodationCancelable(accommodation) &&
        accommodationPolicies.map((policy, index) => (
          <AdditionItemsWrapper key={index}>
            <GenericCheckAlternative fontSize="1.5rem" />
            <AdditionItem>{getCancellationFeeText(policy)}</AdditionItem>
          </AdditionItemsWrapper>
        ))}
      {flight && isFlightCancelable(flight) && (
        <AdditionItemsWrapper>
          <GenericCheckAlternative fontSize="1.5rem" />
          <AdditionItem>{`Free cancellation until ${delayToCancel}`}</AdditionItem>
        </AdditionItemsWrapper>
      )}
      {((accommodation && !isAccommodationCancelable(accommodation)) || (flight && !isFlightCancelable(flight))) && (
        <AdditionItemsWrapper>
          <GenericAlarm fontSize="1.5rem" />
          <AdditionItem>Non-refundable</AdditionItem>
        </AdditionItemsWrapper>
      )}
    </CancellationPenaltyWrapper>
  );
};

export default CancellationPenalty;
