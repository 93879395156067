import { Fragment, useEffect, useMemo } from 'react';
import { TextInput } from '@heathmont/moon-components';
import { Button } from '@heathmont/moon-core';
import {
  GenericDelete,
  GenericPlus,
} from '@heathmont/moon-icons-tw';
import { ExtraBag } from '../../../enums/ExtraBag';
import CustomSelect from '../../CustomSelect';
import FormField from '../../FormField';
import {
  FormItemLabelWrapper,
  DeleteButtonWrapper,
  AddButtonsWrapper,
  FormItemsWrapper,
  FormItemLabel,
  FormItem,
} from '../styles';
import { ErrorMessage } from '../../FormField/styled';
import { generateDefaultExtraBag, generateLocalId } from '../utils';
import { PassengerOption } from '../../../enums/PassengerOption';

function AdditionalServicesFields({
  additionalServices,
  passengers,
  segmentId,
  touched = {},
  errors = {},
  setFieldTouched,
  setFieldValue,
  handleBlur,
}: {
  additionalServices: ExtraBag[];
  passengers: PassengerOption[];
  segmentId: number | string;
  touched: any;
  errors: any;
  setFieldTouched: (field: string, touched: boolean, validate: boolean) => void;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: () => void;
}) {
  const handleAddNew = () => {
    const updateAdditionalServices: ExtraBag[] = [
      ...additionalServices,
      generateDefaultExtraBag(passengers, segmentId),
    ];
    setFieldValue('additionalServices', updateAdditionalServices);
  };

  const handleDelete = (index: number) => {
    const updateAdditionalServices = additionalServices.filter((v, i) => i !== index);
    setFieldValue('additionalServices', updateAdditionalServices);
    setFieldTouched('additionalServices', true, false);
  };

  useEffect(() => {
    setFieldValue('additionalServices', additionalServices.map(additionalService => ({
      ...additionalService,
      passengerId: passengers.some(passengerOption => passengerOption.value === additionalService.passengerId)
        ? additionalService.passengerId
        : null
    })));
  }, [passengers])

  return (
    <>
      {additionalServices.map((additionalService, index) => (
        <Fragment key={index}>
          <FormItemLabelWrapper>
            <FormItemLabel>Extra bag(s)</FormItemLabel>
          </FormItemLabelWrapper>
          <FormItemsWrapper>
            <FormItem fullWidth>
              <CustomSelect
                currentValue={additionalService.passengerId}
                placeholder="Select passenger"
                isError={!!(touched[index]?.passengerId && errors[index]?.passengerId)}
                items={passengers}
                onChange={(value) => {
                  setFieldValue(`additionalServices.${index}.passengerId`, value)
                  setFieldTouched(`additionalServices.${index}.passengerId`, true, false);
                }}
              />
            </FormItem>
            <FormItem>
              <FormField
                placeholder={'Qty'}
                className={'text-input'}
                component={TextInput}
                inputSize="xlarge"
                fieldName={`additionalServices.${index}.quantity`}
                fieldId={`additionalServices.${index}.quantity`}
                isError={!!(touched[index]?.quantity && errors[index]?.quantity)}
                value={additionalService?.quantity}
                type="number"
                onChange={(e) => setFieldValue(`additionalServices.${index}.quantity`, e.target.value)}
                onBlur={handleBlur}
              />
            </FormItem>
            <FormItem>
              <FormField
                placeholder={'Wt'}
                className={'text-input'}
                component={TextInput}
                inputSize="xlarge"
                fieldName={`additionalServices.${index}.weight`}
                fieldId={`additionalServices.${index}.weight`}
                postfix={additionalService?.weightUnit}
                isError={!!(touched[index]?.weight && errors[index]?.weight)}
                value={additionalService?.weight}
                type="number"
                onChange={(e) => setFieldValue(`additionalServices.${index}.weight`, e.target.value)}
                onBlur={handleBlur}
              />
            </FormItem>
            <DeleteButtonWrapper>
              <Button
                iconOnly={<GenericDelete />}
                variant="ghost"
                size="large"
                onClick={() => handleDelete(index)}
              />
            </DeleteButtonWrapper>
          </FormItemsWrapper>

          {!!(touched[index]?.passengerId && errors[index]?.passengerId) && (
            <ErrorMessage>{errors[index]?.passengerId}</ErrorMessage>
          )}

          {!!(touched[index]?.quantity && errors[index]?.quantity) && (
            <ErrorMessage>{errors[index]?.quantity}</ErrorMessage>
          )}

          {!!(touched[index]?.weight && errors[index]?.weight) && (
            <ErrorMessage>{errors[index]?.weight}</ErrorMessage>
          )}
        </Fragment>
      ))}
      <AddButtonsWrapper>
        <Button
          iconLeft={<GenericPlus fontSize="1.2rem" />}
          variant="ghost"
          size="small"
          onClick={handleAddNew}
        >
          Add extra bag
        </Button>
      </AddButtonsWrapper>
    </>
  );
};

export default AdditionalServicesFields;
