import * as Yup from 'yup'

const validateMessages = {
  user: {
    required: 'User has to be provided.'
  }
}
export const unassignedFlightsForm = Yup.object().shape({
  user: Yup.string()
    .required(validateMessages.user.required)
});