import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
`;
