import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled(Text).attrs({
  size: 20,
  color: 'bulma.100',
})`
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 400;
`;

export const ToolsNavigation = styled.div`
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
`;

export const NavigationBlock = styled.div`
  width: 272px;
  margin-top: 16px;
  padding-bottom: 20px;

  ${({ lastItem }) =>
    !lastItem &&
    `
      border-bottom: 1px solid #E0E0E0;
   `}
`;

export const NavigationHeader = styled(Text).attrs({
  size: 16,
  color: 'bulma.100',
})`
  line-height: 24px;
  max-width: 164px;
  height: 48px;
  padding: 12px;
  margin-bottom: 0;
  font-weight: 600;
`;

export const NavigationItem = styled.div`
  height: 40px;
  padding: 8px 8px 8px 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: rgba(39, 90, 219, 0.05);
  }

  ${({ isActive }) =>
    isActive &&
    `
      background: rgba(39, 90, 219, 0.05);
   `}
`;

export const NavigationItemName = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  max-width: 164px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow: auto;
`;

export const Title = styled.h1`
  font-size: 32px;
  margin: 0;
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;
