import { fetchOptionsForCompany } from '../../../store/tools/teams/api';

export const onCompanyChangeHandler = async ({
  newCompanyId,
  companyId,
  setCompanyOptions,
  setFieldTouched,
  setFieldValue,
}: {
  newCompanyId?: number;
  companyId?: number;
  setCompanyOptions: (options: any) => void;
  setFieldTouched: (name: string, touched: boolean, validate: boolean) => void; 
  setFieldValue: (name: string, value: any, validate?: boolean) => void,
}) => {
  const isCompanyChanged = newCompanyId !== companyId;

  if (isCompanyChanged) {
    const options = await fetchOptionsForCompany(newCompanyId);
    setCompanyOptions(options)
    setFieldValue('tripPurposes', []);
    setFieldTouched('tripPurposes', false, false);

    setFieldValue('travelopses', options.defaultTravelopses?.map(item => item.value));
    setFieldTouched('travelopses', false, false);

    setFieldValue('managers', []);
    setFieldTouched('managers', false, false);

    setFieldValue('businessVertical', null);
    setFieldTouched('businessVertical', false, false);
  }
  
  setFieldValue('company', newCompanyId, false)
  setFieldTouched('company', false, false);
};
