import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../components/Spinner';

const Logout = ({ setAppShallBeRedirectedUrl, setIsAuthenticated }) => {
  useEffect(() => {
    setIsAuthenticated(false);
    setAppShallBeRedirectedUrl('/signin');
  }, [setAppShallBeRedirectedUrl, setIsAuthenticated]);

  return <Spinner />;
};

Logout.propTypes = {
  setAppShallBeRedirectedUrl: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Logout;
