import {
  CLOSE_DELETE_UNEMPLOYED_USER_MODAL,
  OPEN_DELETE_UNEMPLOYED_USER_MODAL,
  SET_EMPLOYEES_FOR_DELETION_DATA,
  SET_LOADING_USERS_OPTIONS_DATA,
  SET_UPDATE_OVERVIEW_DETAILS,
  CLOSE_UNEMPLOYED_USERS_LIST,
  OPEN_UNEMPLOYED_USERS_LIST,
  CLOSE_DELETE_USER_MODAL,
  SET_USERS_OPTIONS_DATA,
  OPEN_DELETE_USER_MODAL,
  SET_UPDATE_USER_ROLE,
  CLOSE_USER_OVERVIEW,
  SHOW_USER_OVERVIEW,
  SET_ANONYMIZE_USER,
  TOGGLE_FILTERS,
  SET_USERS_DATA,
  CLOSE_FILTERS,
  DELETE_USER,
} from './actionTypes';

const initState = {
  options: {
    businessVerticals: [],
    userStatuses: [],
    employers: [],
    roles: [],
    teams: [],
  },
  isDeleteUnemployedUserModalOpen: false,
  isUnemployedUsersListOpen: false,
  isUpdateOverviewDetails: false,
  employeesForDeletion: [],
  isDeleteModalOpen: false,
  isUpdateUserRole: false,
  userForDeleting: null,
  isOpenOverview: false,
  isLoadingUsersOptions: false,
  isFiltersOpen: false,
  anonymizeUser: false,
  overviewData: null,
  usersData: [],
  total: null,
};

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case DELETE_USER:
      return {
        ...state,
        isDeleteUnemployedUserModalOpen: false,
        isUnemployedUsersListOpen: false,
        isDeleteModalOpen: false,
        userForDeleting: null,
      };

    case CLOSE_DELETE_USER_MODAL:
      return {
        ...state,
        isDeleteUnemployedUserModalOpen: false,
        isDeleteModalOpen: false,
        userForDeleting: null,
      };

    case CLOSE_DELETE_UNEMPLOYED_USER_MODAL:
      return {
        ...state,
        isDeleteUnemployedUserModalOpen: false,
        isDeleteModalOpen: false,
        userForDeleting: null,
      };

    case OPEN_DELETE_USER_MODAL:
      return {
        ...state,
        isDeleteUnemployedUserModalOpen: false,
        isDeleteModalOpen: true,
        userForDeleting: action.payload.user,
        anonymizeUser: action.payload.user.anonymizeUser,
      };

    case SET_ANONYMIZE_USER:
      return {
        ...state,
        anonymizeUser: action.payload,
      };

    case OPEN_DELETE_UNEMPLOYED_USER_MODAL:
      return {
        ...state,
        isDeleteUnemployedUserModalOpen: true,
        isDeleteModalOpen: false,
        userForDeleting: action.payload.user,
      };

    case SET_USERS_OPTIONS_DATA:
      return {
        ...state,
        options: action.payload.data,
      };

    case SET_USERS_DATA:
      return {
        ...state,
        usersData: action.payload.data.records,
        total: action.payload.data.total,
      };

    case SHOW_USER_OVERVIEW:
      return {
        ...state,
        isUnemployedUsersListOpen: false,
        isOpenOverview: true,
        isFiltersOpen: false,
        overviewData: {
          ...action.payload.user,
          favoriteHotels: action.payload.favoriteHotels,
          loyaltyNumbers: action.payload.loyaltyNumbers,
          cryptoWallets: action.payload.cryptoWallets,
        },
        usersData: state.usersData.map((user) => ({ ...user, isSelected: user.id === action.payload.user.id })),
      };

    case CLOSE_USER_OVERVIEW:
      return {
        ...state,
        isOpenOverview: false,
        usersData: state.usersData.map((user) => ({ ...user, isSelected: false })),
      };

    case TOGGLE_FILTERS:
      return {
        ...state,
        isUnemployedUsersListOpen: false,
        isOpenOverview: false,
        isFiltersOpen: !state.isFiltersOpen,
        overviewData: null,
        usersData: state.usersData.map((user) => ({ ...user, isSelected: false })),
      };

    case CLOSE_FILTERS:
      return {
        ...state,
        isFiltersOpen: false,
        usersData: state.usersData.map((user) => ({ ...user, isSelected: false })),
      };

    case SET_UPDATE_USER_ROLE:
      return {
        ...state,
        isUpdateUserRole: action.payload.data,
      };

    case SET_UPDATE_OVERVIEW_DETAILS:
      return {
        ...state,
        isUpdateOverviewDetails: action.payload.isUpdated,
      };

    case SET_EMPLOYEES_FOR_DELETION_DATA:
      return {
        ...state,
        employeesForDeletion: action.payload.data,
      };
    
    case OPEN_UNEMPLOYED_USERS_LIST:
      return {
        ...state,
        isUnemployedUsersListOpen: !state.isUnemployedUsersListOpen,
        isOpenOverview: false,
        isFiltersOpen: false,
      };

    case CLOSE_UNEMPLOYED_USERS_LIST:
      return {
        ...state,
        isUnemployedUsersListOpen: false,
      };

    case SET_LOADING_USERS_OPTIONS_DATA:
      return {
        ...state,
        isLoadingUsersOptions: action.payload.isLoadingUsersOptions,
      };
      
    default:
      return state;
  }
};

export default usersReducer;
