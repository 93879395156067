import {
  SET_TOOLS_ALL_PURPOSES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_PURPOSE_MODAL,
  SET_TOOLS_PURPOSES_CHECKBOX_DATA,
  CLOSE_DELETE_PURPOSE_MODAL,
  SHOW_DELETE_PURPOSE_MODAL,
  SET_IS_CHANGED_PURPOSE,
  CLOSE_PURPOSE_OVERVIEW,
  SHOW_PURPOSE_OVERVIEW,
  SET_PURPOSES,
} from './actionTypes';

const initState = {
  isActiveAllPurposesCheckboxes: false,
  purposesCheckboxesData: [],
  isOpenPurposeOverview: false,
  isDeletePurposeModal: false,
  isOpenAddNewPurpose: false,
  overviewPurposeData: null,
  deletablePurposes: [],
  isChangedPurpose: false,
  totalPurposes: 0,
  purposes: [],
};

const purposesReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PURPOSES:
      return {
        ...state,
        purposes: action.payload.records,
        totalPurposes: action.payload.total,
      };

    case SET_TOOLS_ALL_PURPOSES_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllPurposesCheckboxes: isActive,
        purposesCheckboxesData: data,
      };
    }

    case SET_TOOLS_PURPOSES_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllPurposesCheckboxes: false,
          purposesCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          purposesCheckboxesData: [...state.purposesCheckboxesData, data],
        };
      }

      return {
        ...state,
        purposesCheckboxesData: state.purposesCheckboxesData.filter((item) => item.purposeId !== data.purposeId),
        isActiveAllPurposesCheckboxes: false,
      };
    }

    case SET_IS_OPEN_ADD_NEW_PURPOSE_MODAL:
      return {
        ...state,
        isOpenAddNewPurpose: action.payload,
      };

    case SET_IS_CHANGED_PURPOSE:
      return {
        ...state,
        isChangedPurpose: !state.isChangedPurpose,
      };

    case SHOW_DELETE_PURPOSE_MODAL:
      return {
        ...state,
        isDeletePurposeModal: true,
        deletablePurposes: action.payload.deletablePurposes,
      };

    case CLOSE_DELETE_PURPOSE_MODAL:
      return {
        ...state,
        isDeletePurposeModal: false,
        deletablePurposes: [],
      };

    case SHOW_PURPOSE_OVERVIEW:
      return {
        ...state,
        isOpenPurposeOverview: true,
        overviewPurposeData: action.payload.data,
      };

    case CLOSE_PURPOSE_OVERVIEW:
      return {
        ...state,
        isOpenPurposeOverview: false,
        overviewPurposeData: null,
      };

    default:
      return state;
  }
};

export default purposesReducer;
