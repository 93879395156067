import styled from 'styled-components';

export const DatesWrapper = styled.div`
  height: 100%;
`;

export const FormFieldsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
  max-height: 350px;
  overflow-y: scroll;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;
