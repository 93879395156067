import { useState, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ArrowsRefreshRound } from '@heathmont/moon-icons-tw';
import { TextInput, Button } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { N_A } from '../../../utils/commonFunctions';
import {
  getFeedbackTypeLabel,
  formatDateAndTime,
} from '../../../utils/tableHelpers';
import { ITripFeedback } from './types';
import FormField from '../../FormField';
import StarRating from '../../StarRating';
import { Form, Formik } from 'formik';
import { feedbackSchema } from './schema';
import {
  OverviewContentValue,
  OverviewContentName,
  FeedbackEmptyLabel,
  EditButtonWrapper,
  OverviewContent,
  ButtonsWrapper,
  FeedbackLabel,
} from '../styles';

const TripFeedback: React.FC<ITripFeedback> = ({
  isCurrentUserTravelops,
  isTravelerExternal,
  isTravelerActive,
  paymentRequired,
  isPurchased,
  trip,
  requestFeedback,
  updateFeedback,
}) => {
  const [feedbackForEdit, setFeedbackForEdit] = useState(null);

  return (
    <>
      {trip?.feedbacks?.length ? trip?.feedbacks.map(feedback => (
        <Fragment key={feedback.id}>
          <FeedbackLabel>
            {getFeedbackTypeLabel(feedback.type)}
          </FeedbackLabel>
        
          {feedbackForEdit !== feedback.id && isCurrentUserTravelops && (
            <EditButtonWrapper>
              <Button
                onClick={() => setFeedbackForEdit(feedback.id)}
                iconLeft={<GenericEdit fontSize="1.2rem" />}
                variant="ghost"
              >
                Edit
              </Button>
            </EditButtonWrapper>
          )}

          {feedback?.app && (
            <OverviewContent>
              <OverviewContentName>Travel app experience</OverviewContentName>
              <OverviewContentValue>
                {<StarRating rate={+feedback?.app} readOnly />}
              </OverviewContentValue>
            </OverviewContent>
          )}

          {feedback?.webApp && (
            <OverviewContent>
              <OverviewContentName>Web app experience</OverviewContentName>
              <OverviewContentValue>
                {<StarRating rate={+feedback?.webApp} readOnly />}
              </OverviewContentValue>
            </OverviewContent>
          )}

          {feedback?.flight && (
            <OverviewContent>
              <OverviewContentName>Flight experience</OverviewContentName>
              <OverviewContentValue>
                {<StarRating rate={+feedback?.flight} readOnly />}
              </OverviewContentValue>
            </OverviewContent>
          )}

          {feedback?.accommodation && (
            <OverviewContent>
              <OverviewContentName>Accommodation experience</OverviewContentName>
              <OverviewContentValue>
                {<StarRating rate={+feedback?.accommodation} readOnly />}
              </OverviewContentValue>
            </OverviewContent>
          )}
          
          {feedback?.comments && (
            <OverviewContent>
              <OverviewContentName>Comments and Recommendations</OverviewContentName>
              <OverviewContentValue>{feedback?.comments}</OverviewContentValue>
            </OverviewContent>
          )}

          {feedback?.submittedBy && (
            <OverviewContent>
              <OverviewContentName>Submitted by</OverviewContentName>
              <OverviewContentValue>
                {feedback?.submittedBy.fullName}
              </OverviewContentValue>
            </OverviewContent>
          )}

          <OverviewContent>
            <OverviewContentName>Submitted at</OverviewContentName>
            <OverviewContentValue>
              {formatDateAndTime(feedback?.submittedAt, true)}
            </OverviewContentValue>
          </OverviewContent>

          {
            feedbackForEdit === feedback.id ? (
              <Formik
                enableReinitialize
                isInitialValid
                validateOnBlur={true}
                validationSchema={feedbackSchema}
                initialValues={{ adminComments: feedback?.adminComments ?? '' }}
                onSubmit={() => {}}
              >
                {({ errors, values, resetForm, setFieldValue, touched, handleBlur, dirty }: any): JSX.Element => {
                  return (
                    <Form>
                      <OverviewContent>
                        <OverviewContentName>Admin comments</OverviewContentName>
                        <OverviewContentValue>
                          <FormField
                            value={values.adminComments}
                            fieldId={`adminComments_${feedback.id}`}
                            fieldName={`adminComments_${feedback.id}`}
                            component={TextInput}
                            type="text"
                            inputSize="xlarge"
                            onChange={(e) => setFieldValue('adminComments', e.target.value)}
                            onBlur={handleBlur}
                            isError={touched?.adminComments && errors?.adminComments}
                            errors={touched?.adminComments && errors}
                            errorPosition={-20}
                            label="Admin comments"
                          />
                        </OverviewContentValue>
                      </OverviewContent>
                      <ButtonsWrapper>
                        <Button
                          onClick={() => {
                            resetForm();
                            setFeedbackForEdit(null);
                          }}
                          variant="secondary"
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          disabled={!isEmpty(errors) || !dirty}
                          onClick={() => {
                            updateFeedback(trip.id, feedback.id, values);
                            setFeedbackForEdit(false);
                            resetForm();
                          }}
                        >
                          Save
                        </Button>
                      </ButtonsWrapper>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <OverviewContent>
                <OverviewContentName>Admin comments</OverviewContentName>
                <OverviewContentValue>{feedback?.adminComments ?? N_A}</OverviewContentValue>
              </OverviewContent>
            ) 
          }
        </Fragment>
      )) : (
        <FeedbackEmptyLabel>No feedback was provided</FeedbackEmptyLabel>
      )}

      {isCurrentUserTravelops && isPurchased && isTravelerActive && !isTravelerExternal && !paymentRequired && (
        <Button variant="secondary" size="small" type="button" onClick={() => requestFeedback(trip.id)}>
          <ArrowsRefreshRound />
          Request feedback
        </Button>
      )}
    </>
  );
};

export default TripFeedback;
