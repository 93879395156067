import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSuitcaseRolling,
  faPlaneDeparture,
  faClipboardCheck,
  faPlaneArrival,
  faBusinessTime,
  faCalendarAlt,
  faAddressCard,
  faUserCircle,
  faSignOutAlt,
  faInfoCircle,
  faPlaneSlash,
  faUserClock,
  faBriefcase,
  faIdCardAlt,
  faUserCheck,
  faArrowDown,
  faUserPlus,
  faSpinner,
  faHistory,
  faSearch,
  faUsers,
  faHotel,
  faPlane,
  faClock,
  faUser,
  faEdit,
  faSync,
  faCity,
  faBan,
  fas,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@heathmont/moon-themes';
import { store } from './utils/configureStore';
import { App } from './components/App';
import themeConfig from './themeConfig';

import './index.scss';

library.add(
  // @ts-ignore
  fas,
  faSpinner,
  faUsers,
  faUserPlus,
  faUserClock,
  faUser,
  faUserCircle,
  faBriefcase,
  faSuitcaseRolling,
  faPlaneDeparture,
  faPlaneArrival,
  faSignOutAlt,
  faHistory,
  faBusinessTime,
  faEdit,
  faInfoCircle,
  faHotel,
  faPlane,
  faAddressCard,
  faClipboardCheck,
  faIdCardAlt,
  faUserCheck,
  faSync,
  faCalendarAlt,
  faPlaneSlash,
  faArrowDown,
  faCity,
  faSearch,
  faClock,
  faBan
);

ReactDOM.render(
  <ThemeProvider theme={themeConfig}>
    <Provider store={store}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
