import { Hotel } from '../../enums/Hotel';
import { HotelFormData } from '../../enums/HotelFormData';
import { HOTEL_STAR_RATING } from '../../enums/HotelStarRating';
import { HOTEL_TYPES } from '../../enums/HotelType';
import Yup from '../../utils/validation';
import { convertTimeToDate } from './utils';

const VALIDATIONS = {
  LONGITUDE: {
    MIN: -180,
    MAX: 180,
  },
  LATITUDE: {
    MIN: -90,
    MAX: 90,
  },
  NAME: {
    MIN: 2,
    MAX: 255,
  },
  ADDRESS: {
    MIN: 2,
    MAX: 255,
  },
  PHONE: {
    MIN: 2,
    MAX: 255,
  },
  EMAIL: {
    MIN: 2,
    MAX: 255,
  },
};

const validateMessages = {
  regionId: {
    required: 'City cannot be empty',
  },
  address: {
    required: 'Address cannot be empty',
    min: `Address cannot be less than ${VALIDATIONS.ADDRESS.MIN} characters`,
    max: `Address cannot be longer than ${VALIDATIONS.ADDRESS.MAX} characters`,
  },
  name: {
    required: 'Name cannot be empty',
    min: `Name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `Name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  type: {
    required: 'Type cannot be empty',
  },
  starRating: {
    required: 'Star rating cannot be empty',
  },
  latitude: {
    required: 'Latitude cannot be empty',
    min: `Latitude cannot be less than ${VALIDATIONS.LATITUDE.MIN}`,
    max: `Latitude cannot be more than ${VALIDATIONS.LATITUDE.MAX}`,
  },
  longitude: {
    required: 'Longitude cannot be empty',
    min: `Longitude cannot be less than ${VALIDATIONS.LONGITUDE.MIN}`,
    max: `Longitude cannot be more than ${VALIDATIONS.LONGITUDE.MAX}`,
  },
  phone: {
    min: `Phone cannot be less than ${VALIDATIONS.PHONE.MIN} characters`,
    max: `Phone cannot be longer than ${VALIDATIONS.PHONE.MAX} characters`,
  },
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    valid: 'Email should be in format "user@example.com"',
    min: `Email cannot be less than ${VALIDATIONS.PHONE.MIN} characters`,
    max: `Email cannot be longer than ${VALIDATIONS.PHONE.MAX} characters`,
  },
  checkInTime: {
    required: 'Check in time cannot be empty',
  },
  checkOutTime: {
    required: 'Check out time cannot be empty',
  },
};

export const getInitialValues = (hotel?: Hotel): HotelFormData => {  
  return {
    checkOutTime: hotel?.checkOutTime ? convertTimeToDate(hotel.checkOutTime) : null,
    checkInTime: hotel?.checkInTime ? convertTimeToDate(hotel.checkInTime) : null,
    starRating: hotel?.starRating ? hotel.starRating : HOTEL_STAR_RATING.THREE,
    longitude:  hotel?.longitude ? hotel.longitude : null,
    latitude: hotel?.latitude ? hotel.latitude : null,
    regionId: hotel?.region?.id ? hotel.region.id : null,
    address: hotel?.address ? hotel.address : '',
    phone: hotel?.phone ? hotel.phone : '',
    email: hotel?.email ? hotel.email : '',
    name: hotel?.name ? hotel.name : '',
    type: hotel?.type ? hotel.type : HOTEL_TYPES.HOTEL,
  };
};

export const customHotelSchema = Yup.object().shape({
  regionId: Yup
    .number()
    .required(validateMessages.regionId.required)
    .typeError(validateMessages.regionId.required),
  address: Yup
    .string()
    .required(validateMessages.address.required)
    .min(VALIDATIONS.ADDRESS.MIN, validateMessages.address.min)
    .max(VALIDATIONS.ADDRESS.MAX, validateMessages.address.max)
    .withoutSpaces()
    .isInLatinCharacters(),
  name: Yup
    .string()
    .required(validateMessages.name.required)
    .min(VALIDATIONS.NAME.MIN, validateMessages.name.min)
    .max(VALIDATIONS.NAME.MAX, validateMessages.name.max)
    .withoutSpaces()
    .isInLatinCharacters(),
  phone: Yup
    .string()
    .min(VALIDATIONS.PHONE.MIN, validateMessages.phone.min)
    .max(VALIDATIONS.PHONE.MAX, validateMessages.phone.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .nullable(),
  email: Yup
    .string()
    .email(validateMessages.email.valid)
    .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
    .min(VALIDATIONS.EMAIL.MIN, validateMessages.email.min)
    .max(VALIDATIONS.EMAIL.MAX, validateMessages.email.max)
    .nullable(),
  type: Yup
    .string()
    .oneOf([
      HOTEL_TYPES.RESORT,
      HOTEL_TYPES.SANATORIUM,
      HOTEL_TYPES.GUESTHOUSE,
      HOTEL_TYPES.MINI_HOTEL,
      HOTEL_TYPES.CASTLE,
      HOTEL_TYPES.HOTEL,
      HOTEL_TYPES.BOUTIQUE_AND_DESIGN,
      HOTEL_TYPES.APARTMENT,
      HOTEL_TYPES.COTTAGES_AND_HOUSES,
      HOTEL_TYPES.FARM,
      HOTEL_TYPES.VILLAS_AND_BUNGALOWS,
      HOTEL_TYPES.CAMPING,
      HOTEL_TYPES.HOSTEL,
      HOTEL_TYPES.BNB,
      HOTEL_TYPES.APART_HOTEL,
      HOTEL_TYPES.GLAMPING,
    ])
    .required(validateMessages.type.required),
  starRating: Yup
    .number()
    .oneOf([
      HOTEL_STAR_RATING.ONE,
      HOTEL_STAR_RATING.TWO,
      HOTEL_STAR_RATING.THREE,
      HOTEL_STAR_RATING.FOUR,
      HOTEL_STAR_RATING.FIVE,
    ])
    .required(validateMessages.starRating.required)
    .typeError(validateMessages.starRating.required),
  latitude: Yup
    .number()
    .required(validateMessages.latitude.required)
    .min(VALIDATIONS.LATITUDE.MIN, validateMessages.latitude.min)
    .max(VALIDATIONS.LATITUDE.MAX, validateMessages.latitude.max)
    .typeError(validateMessages.latitude.required),
  longitude: Yup
    .number()
    .required(validateMessages.longitude.required)
    .min(VALIDATIONS.LONGITUDE.MIN, validateMessages.longitude.min)
    .max(VALIDATIONS.LONGITUDE.MAX, validateMessages.longitude.max)
    .typeError(validateMessages.longitude.required),
  checkInTime: Yup
    .date()
    .required(validateMessages.checkInTime.required)
    .typeError(validateMessages.checkInTime.required),
  checkOutTime: Yup
    .date()
    .required(validateMessages.checkOutTime.required)
    .typeError(validateMessages.checkOutTime.required),
});
