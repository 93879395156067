import Yup from '../../utils/validation';

const validateMessages = {
  title: {
    min: 'Name cannot be less than 1 characters',
    max: 'Name cannot be longer than 255 characters',
    required: 'Name cannot be empty',
  },
  plan: {
    max: 'Plan cannot be longer than 255 characters',
    required: 'Plan cannot be empty',
  },
  link: {
    max: 'Link cannot be longer than 255 characters',
    required: 'Link cannot be empty',
  },
  email: {
    max: 'Email cannot be longer than 255 characters',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  description: {
    max: 'Description cannot be longer than 1000 characters',
    required: 'Description cannot be empty',
  },
};

export const updatePurposeSchema = Yup.object().shape({
  title: Yup
    .string()
    .min(1, validateMessages.title.min)
    .max(255, validateMessages.title.max)
    .withoutSpaces()
    .required(validateMessages.title.required),
  email: Yup
    .string()
    .max(255, validateMessages.email.max)
    .email(validateMessages.email.valid)
    .withoutSpaces(),
  plan: Yup
    .string()
    .max(255, validateMessages.plan.max)
    .withoutSpaces(),
  link: Yup
    .string()
    .max(255, validateMessages.link.max)
    .withoutSpaces(),
  description: Yup
    .string()
    .max(1000, validateMessages.description.max)
    .withoutSpaces(),
});
