import { connect } from 'react-redux';
import Logout from './Logout.ui';
import { setIsAuthenticated } from '../../store/auth/actions';
import { setAppShallBeRedirectedUrl } from '../../store/app/actions';

const mapDispatchToProps = {
  setAppShallBeRedirectedUrl,
  setIsAuthenticated,
};

export default connect(null, mapDispatchToProps)(Logout);
