import { useMemo, useState } from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Button } from '@heathmont/moon-core';
import { IChangeTripDestinationDialogContent } from './types';
import request from '../../models/request';
import InfoBlock from '../InfoBlock'; 
import {
  TripReferenceAndTraveler,
  TripOverviewWrapper,
  TripInfoBlock,
  ButtonsWrapper,
  TripDates,
} from './styles';
import Yup from '../../utils/validation';
import CustomSelect from '../CustomSelect';
import TripReference from '../TripReference';
import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';

const getInitialValues = (trip) => {  
  return {
    newDestination: undefined,
    tripId: trip?.id || undefined,
  };
};

const schema = Yup.object().shape({
  newDestination: Yup
    .number()
    .required('Destination can not be empty'),
});

const ChangeTripDestinationDialogContent: React.FC<IChangeTripDestinationDialogContent> = ({
  loading,
  trip,
  onSubmit,
  onCancel,
}) => {
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const initialValues = useMemo(() => getInitialValues(trip), [trip?.id]);

  const formatDate = (date) => {
    return moment(date).format('DD MMM YYYY');
  };

  const formatDestinations = (cities) => {
    return cities.map((city) => ({
      title: `${city.name} (${city.country})`,
      value: city.id,
    }));
  };

  const fetchCities = async (search) => {
    if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
      setCitiesOptions([]);
      setIsCitiesLoading(true);
      try {
        const { data } = await request.get(`locations/cities`, { params: { search } });
        setCitiesOptions(formatDestinations(data.airports || []));
      } catch (err) {
        setCitiesOptions([]);
      } finally {
        setIsCitiesLoading(false);
      }
    }
  };

  const goToTrip = (reference) => {
    window.open(`/trips?search=${encodeURIComponent(reference)}`, '_blank');
  };

  return (
    <>
      <InfoBlock
        text={`
          The algorithm defines a trip's destination based on flight and accommodation data.
          The destination can be changed after flights or accommodations synchronization,
          merging, or attaching the external part. It's preferable to change the trip destination
          after it ends.
        `}
      />
      <TripOverviewWrapper>
        <TripInfoBlock>
          <TripReferenceAndTraveler>
            <TripReference reference={trip?.reference} onClick={() => goToTrip(trip?.reference)} /> {trip?.user?.fullName} to {trip?.destinationLabel}
          </TripReferenceAndTraveler>
          <TripDates>
            {formatDate(trip?.tripStartDate)} → {formatDate(trip?.tripEndDate)}
          </TripDates>
        </TripInfoBlock>
      </TripOverviewWrapper>
    
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <CustomSelect
                minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                currentValue={values.newDestination}
                isSearchable
                placeholder='Select new destination'
                isLoading={isCitiesLoading}
                isMulti
                isError={!!(touched?.newDestination && errors?.newDestination)}
                error={touched?.newDestination && errors?.newDestination}
                items={citiesOptions}
                onInputChange={debounce((search) => fetchCities(search), DEFAULT_DEBOUNCE_MS_COUNT)}
                onChange={(value) => setFieldValue('newDestination', value)}
              />
              <ButtonsWrapper>
                <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                <Button disabled={!isEmpty(errors) || loading} variant="primary" type="submit">Confirm</Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangeTripDestinationDialogContent;
