import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { PurposeTypes } from '../../enums/PurposeTypes';
import Yup from '../../utils/validation';

const validateMessages = {
  accommodationBookingMargin: {
    min: 'Accommodation booking margin can not be negative',
    max: 'Accommodation booking margin can not be more than 100',
  },
  flightBookingMargin: {
    min: 'Flight booking margin can not be negative',
    max: 'Flight booking margin can not be more than 100',
  },
  paymentTypes: {
    required: 'At least one payment type should be provided',
  },
  companies: {
    required: 'At least one company should be provided',
  },
  label: {
    min: 'Trip purpose title cannot be less than 3 characters',
    max: 'Trip purpose title cannot be longer than 40 characters',
    required: 'Trip purpose title cannot be empty',
  },
};

export const paymentTypeSchema = Yup
  .string()
  .oneOf(Object.values(PAYMENT_TYPE))
  .required();

export const purposeSchema = Yup.object().shape({
  label: Yup
    .string()
    .required(validateMessages.label.required)
    .min(3, validateMessages.label.min)
    .max(40, validateMessages.label.max)
    .withoutSpaces(),
  accommodationBookingMargin: Yup
    .number()
    .min(0, validateMessages.accommodationBookingMargin.min)
    .max(100, validateMessages.accommodationBookingMargin.max)
    .nullable(),
  flightBookingMargin: Yup
    .number()
    .min(0, validateMessages.flightBookingMargin.min)
    .max(100, validateMessages.flightBookingMargin.max)
    .nullable(),
  expensesRequired: Yup
    .bool()
    .required(),
  paymentRequired: Yup
    .bool()
    .required(),
  paymentTypes: Yup
    .array()
    .when('paymentRequired', {
      is: value => !!value,
      then: Yup
        .array()
        .min(1, validateMessages.paymentTypes.required)
        .of(paymentTypeSchema),
      otherwise: Yup
        .array()
        .of(paymentTypeSchema),
    }),
  companies: Yup
    .array()
    .min(1, validateMessages.companies.required)
    .of(Yup.number()),
  type: Yup
    .string()
    .oneOf(Object.values(PurposeTypes))
    .required(),
});



export const purposeUpdateSchema = Yup.object().shape({
  label: Yup
    .string()
    .required(validateMessages.label.required)
    .min(3, validateMessages.label.min)
    .max(40, validateMessages.label.max)
    .withoutSpaces(),
  accommodationBookingMargin: Yup
    .number()
    .min(0, validateMessages.accommodationBookingMargin.min)
    .max(100, validateMessages.accommodationBookingMargin.max)
    .nullable(),
  flightBookingMargin: Yup
    .number()
    .min(0, validateMessages.flightBookingMargin.min)
    .max(100, validateMessages.flightBookingMargin.max)
    .nullable(),
  expensesRequired: Yup
    .bool()
    .required(),
  paymentRequired: Yup
    .bool()
    .required(),
  paymentTypes: Yup
    .array()
    .when('paymentRequired', {
      is: value => !!value,
      then: Yup
        .array()
        .min(1, validateMessages.paymentTypes.required)
        .of(paymentTypeSchema),
      otherwise: Yup
        .array()
        .of(paymentTypeSchema),
    }),
});

export const getInitialValues = (): {
  accommodationBookingMargin: number,
  flightBookingMargin: number,
  expensesRequired: boolean,
  paymentRequired: boolean,
  paymentTypes: PAYMENT_TYPE[],
  companies: number[],
  label: string,
  type: PurposeTypes,
} => ({
  accommodationBookingMargin: null,
  flightBookingMargin: null,
  expensesRequired: false,
  paymentRequired: false,
  paymentTypes: [],
  companies: [],
  label: '',
  type: PurposeTypes.INTERNAL,
});

export const getInitialValuesOnUpdate = (data: {
  accommodationBookingMargin: number,
  flightBookingMargin: number,
  expensesRequired: boolean | number,
  paymentRequired: boolean | number,
  paymentTypes: PAYMENT_TYPE[],
  title: string,
}): {
  accommodationBookingMargin: number,
  flightBookingMargin: number,
  expensesRequired: boolean,
  paymentRequired: boolean,
  paymentTypes: PAYMENT_TYPE[],
  label: string,
} => ({
  accommodationBookingMargin: data?.accommodationBookingMargin ?? null,
  flightBookingMargin: data?.flightBookingMargin ?? null,
  expensesRequired: !!data?.expensesRequired ?? false,
  paymentRequired: !!data?.paymentRequired ?? false,
  paymentTypes: data?.paymentTypes ?? [],
  label: data?.title ?? '',
});
