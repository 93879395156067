import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextInputGroup } from '@heathmont/moon-select';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import DynamicFormFields from '../../../../../components/DynamicFormFields';
import { ICryptoWallet } from './types';
import { CryptoWalletsWrapper, DoubleFieldWrapper } from './styled';
import FormField from '../../../../../components/FormField';
import { ErrorMessage, ErrorMessageWrapper } from '../../styles';

const CryptoWallet: React.FC<ICryptoWallet> = ({ values, touched, errors, initialValues, options, setFieldValue, setFieldTouched }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.cryptoWallets, initialValues.cryptoWallets)) {
      dispatch(setIsChangedTabField('crypto', true));
    } else {
      dispatch(setIsChangedTabField('crypto', false));
    }
  }, [values.cryptoWallets, initialValues.cryptoWallets]);

  const getDefaultValue = () => ({ id: null, address: '', cryptoCurrencyId: null });

  return (
    <CryptoWalletsWrapper>
      <DynamicFormFields
        withoutLimit
        fieldName="cryptoWallets"
        touched={touched}
        errors={errors}
        data={values.cryptoWallets}
        setFieldValue={setFieldValue}
        getDefaultValue={getDefaultValue}
        renderItem={(fieldName, value, index) => {
          return (
            <DoubleFieldWrapper>
              <FormField
                component={TextInputGroup}
                orientation="vertical"
                type="text"
                inputProps={{
                  input1: {
                    type: 'text',
                    value: value.address,
                    onChange: ({ target: { value } }) => {
                      setFieldTouched('address', true);
                      setFieldValue(`${fieldName}.${index}.address`, value)
                    },
                    inputSize: 'xlarge',
                    label: 'Crypto wallet address',
                    id: 'address',
                    isError: errors?.cryptoWallets?.address,
                  },
                  input2: {
                    type: 'select',
                    value:  value.cryptoCurrency,
                    options: options.availableForCryptoWalletCurrencies,
                    onChange: (value) => setFieldValue(`${fieldName}.${index}.cryptoCurrency`, value),
                    inputSize: 'xlarge',
                    label: 'Crypto currency',
                    id: 'cryptoCurrency',
                  },
                }}
              />

              {errors?.cryptoWallets && errors?.cryptoWallets[index]?.address && touched.address && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{errors.cryptoWallets[index].address}</ErrorMessage>
                </ErrorMessageWrapper>
              )}

            </DoubleFieldWrapper>
          )
        }}
      />
    </CryptoWalletsWrapper>
  );
};

export default CryptoWallet;
