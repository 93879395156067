import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';

export const getNotificationStyles = (notificationType) => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.PENDING_TRIPS:
    case NOTIFICATION_TYPES.APPROVED_TRIPS:
    case NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES:
    case NOTIFICATION_TYPES.VERIFYING_EXPENSES:
      return `
            border: 1px solid #FFB319;
            background: #FFF7E8;
         `;
    case NOTIFICATION_TYPES.EXPIRING_TRIPS:
    case NOTIFICATION_TYPES.BOOKED_YESTERDAY:
    case NOTIFICATION_TYPES.EXPIRED_DOCUMENTS:
    case NOTIFICATION_TYPES.SUBSCRIPTIONS:
      return `
            border: 1px solid #FF1F39;
            background: #FFF2F2;
         `;
    case NOTIFICATION_TYPES.COMPLETED_TRIPS:
    case NOTIFICATION_TYPES.NEW_FEEDBACKS:
      return `
               border: 1px solid #0BC15A;
               background: #E9FBF1;
            `;
    default:
      return `
            border: 1px solid #E0E0E0;
            background: #FFFFFF;
         `;
  }
};
