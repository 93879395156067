import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const CronTextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CronText = styled(Text).attrs({
  size: 14,
  color: 'trunks.100',
})`
  line-height: 24px;
  margin: 15px;
`;
