import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { IEmployers } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { toolsEmployersDataMapper } from '../../../utils/tableHelpers';
import { initialColumns } from './suggestions';
import Dialog from '../../../components/Dialog';
import AddNewEmployer from '../../../components/AddNewEmployer';
import DownDrawer from '../../../components/DownDrawer';
import Drawer from '../../../components/Drawer';
import EmployersOverviewDrawerContent from '../../../components/EmployersOverviewDrawerContent';
import { removeEmployer } from '../../../store/tools/employers/api';
import { Spinner } from '../../../components/Spinner';

const Employers: React.FC<IEmployers> = ({
  isActiveAllEmployersCheckboxes,
  employersCheckboxesData,
  isDeleteEmployersModal,
  isOpenEmployerOverview,
  isUpdatedEmployerData,
  isOpenAddNewEmployer,
  overviewEmployerData,
  employersCompanies,
  deletableEmployers,
  totalEmployers,
  employers,
  filters,
  sortBy,
  page,
  setAllEmployersCheckboxData,
  closeDeleteEmployersModal,
  showDeleteEmployersModal,
  setEmployersCheckboxData,
  setIsOpenAddNewEmployer,
  closeEmployerOverview,
  showEmployerOverview,
  addToolsNewEmployer,
  fetchToolsEmployers,
  fetchAllCompanies,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const companiesList = useMemo(() => employersCompanies.map(({ id, name }) => ({ value: id, title: name })), [employersCompanies]);
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchToolsEmployers(params);
    closeEmployerOverview();
  }, [filters, page, sortBy, isUpdatedEmployerData]);

  useEffect(() => {
    fetchAllCompanies();

    return () => {
      closeEmployerOverview();
    };
  }, []);

  const totalItems = useRef(0);
  const queryParams = useRef('');

  useMemo(() => {
    totalItems.current = totalEmployers;
  }, [totalEmployers]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const columns = useMemo(() => initialColumns, []);
  const employersGroupedData = useMemo(() => toolsEmployersDataMapper(employers), [employers]);

  const handleAddNewEmployer = (newEmployer: any) => {
    setIsOpenAddNewEmployer(false);

    addToolsNewEmployer({
      ...newEmployer,
      registrationNumber: newEmployer.registrationNumber.trim(),
      name: newEmployer.name.trim(),
    }, params);
  };

  const handleRemoveEmployer = useCallback(async (data: any) => {
    await removeEmployer(data);
    fetchToolsEmployers(params);
    setEmployersCheckboxData();
    closeDeleteEmployersModal();
  }, []);

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showEmployerOverview(employers[row?.id] ?? {});
    },
    [employers]
  );

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete';

    if ((employersCheckboxesData && employersCheckboxesData.length > 1) || isActiveAllEmployersCheckboxes) {
      return `${title} these employers?`;
    }

    return `${title} this employer?`;
  }, [isActiveAllEmployersCheckboxes, employersCheckboxesData]);

  const tableContentRecieverProps = {
    isActiveAllEmployersCheckboxes,
    isToolsEmployersPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: employersCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    data: employersGroupedData,
    page,
    handleSetAllCheckboxs: setAllEmployersCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: showDeleteEmployersModal,
    handleSetCheckbox: setEmployersCheckboxData,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Employers</Title>

          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewEmployer(true)}>
            <ControlsPlus /> Add employer
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.EMPLOYERS}
          totalItems={totalItems}
          data={employers}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchToolsEmployers(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenAddNewEmployer && (
        <AddNewEmployer
          companies={companiesList}
          handleCloseDialog={() => setIsOpenAddNewEmployer(false)}
          handleConfirm={handleAddNewEmployer}
        />
      )}

      {isDeleteEmployersModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableEmployers}
          onSubmit={handleRemoveEmployer}
          onClose={closeDeleteEmployersModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {!!employersCheckboxesData.length && (
        <DownDrawer
          data={employersCheckboxesData}
          text="employer"
          handleRemoveDialog={showDeleteEmployersModal}
          handleSetCheckbox={setEmployersCheckboxData}
        />
      )}

      {isOpenEmployerOverview && (
        <div>
          <Drawer handleClose={closeEmployerOverview} title={overviewEmployerData?.name ?? ''}>
            <EmployersOverviewDrawerContent data={overviewEmployerData} companies={companiesList} />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Employers;
