import { useEffect, useState, useMemo } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button, Checkbox } from '@heathmont/moon-core';
import { getBoolStatusLabel } from '../../utils/tableHelpers';
import { IEmployerOverviewDrawerContent } from './types';
import { OverviewContent, OverviewContentName, OverviewContentValue, EditButtonWrapper } from './styles';
import EmployersOverviewDrawerEditContent from '../EmployersOverviewDrawerEditContent';
import InfoBlock from '../InfoBlock';
import { N_A } from '../../utils/commonFunctions';
import { getLabels, formatBusinessVerticalLabels } from '../../utils/tableHelpers';
import { EmployerTypes } from '../../enums/EmployerTypes';
import { isAccountantWithTheSameEmployerBusinessVertical } from '../../../src/utils/employers';

const EmployersOverviewDrawerContent: React.FC<IEmployerOverviewDrawerContent> = ({ data, companies }): JSX.Element => {
  const [isEditFormData, setIsEditFormData] = useState(false);
  const { id, registrationNumber, name, company, status, accountant, businessVerticals } = data;

  useEffect(() => {
    setIsEditFormData(false);
  }, [id]);

  const doesEmployerAndAccountantVerticalMatch = useMemo(() => {
    if (!data?.accountant?.id) {
      return true;
    }

    return isAccountantWithTheSameEmployerBusinessVertical(data?.businessVerticals, data?.accountant);
  }, [data]);

  return (
    <div>
      {accountant?.id && !accountant?.jiraId && (
        <InfoBlock
          text={`Jira ID is not provided for the employer's accountant. ETRs will be assigned to the company's senior accountant.`}
        />
      )}

      {!doesEmployerAndAccountantVerticalMatch && (
        <InfoBlock
          text={`Accountant's vertical doesn't match with employer's vertical`}
        />
      )}

      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <OverviewContent>
        <OverviewContentName>ID</OverviewContentName>
        <OverviewContentValue>{id}</OverviewContentValue>
      </OverviewContent>

      {!isEditFormData ? (
        <>
          <OverviewContent>
            <OverviewContentName>Employer</OverviewContentName>
            <OverviewContentValue>{name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Company</OverviewContentName>
            <OverviewContentValue>{company?.name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Business Verticals</OverviewContentName>
            <OverviewContentValue>{businessVerticals?.length ? formatBusinessVerticalLabels(businessVerticals, true) : null}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Registration number</OverviewContentName>
            <OverviewContentValue>{registrationNumber || N_A}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Accountant</OverviewContentName>
            <OverviewContentValue>{accountant?.id ? accountant.fullName : N_A}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Status</OverviewContentName>
            <OverviewContentValue>
              {getBoolStatusLabel(status)}
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>External</OverviewContentName>
            <OverviewContentValue>
              <Checkbox checked={data?.type === EmployerTypes.EXTERNAL} disabled />
            </OverviewContentValue>
          </OverviewContent>
        </>
      ) : (
        <EmployersOverviewDrawerEditContent
          companies={companies}
          data={data}
          setIsEditFormData={setIsEditFormData}
        />
      )}
    </div>
  );
};

export default EmployersOverviewDrawerContent;
