export enum NOTIFICATION_TYPES {
  NEW_FEEDBACKS = 'newFeedbacks',
  PENDING_TRIPS = 'pendingTrips',
  APPROVED_TRIPS = 'approvedTrips',
  COMPLETED_TRIPS = 'completedTrips',
  EXPIRING_TRIPS = 'expiringTrips',
  BOOKED_YESTERDAY = 'bookedYesterday',
  UNDER_CONSIDERATION_EXPENSES = 'underConsiderationExpenses',
  VERIFYING_EXPENSES = 'verifyingExpenses',
  EXPIRED_DOCUMENTS = 'expiredDocuments',
  SUBSCRIPTIONS = 'subscriptions',
  TRIPS_FOR_MERGING = 'tripsForMerging',
  UNASSIGNED_FLIGHTS = 'unassignedFlights',
  UNASSIGNED_ACCOMMODATIONS = 'unassignedAccommodations',
}
