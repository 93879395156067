import { createStore as createReduxStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../store';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');

  middlewares.push(logger);
}

const createStore = (initialState = {}) =>
  createReduxStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));

const store = createStore();

export { createStore, store };
