import { Trip } from '../../../enums/Trip';
import Yup from '../../../utils/validation';

const validateMessages = {
  purposeDetails: {
    required: 'Purpose details cannot be empty',
    max: 'Details cannot be longer than 1000 characters',
    min: 'Details cannot be less than 1 characters',
  },
}
export const purposeEditSchema = Yup.object().shape({
  purposeDetails: Yup.string()
    .required(validateMessages.purposeDetails.required)
    .max(1000, validateMessages.purposeDetails.max)
    .min(1, validateMessages.purposeDetails.min),
});

export const getPurposeInitialValues = (trip: Trip) => {
  return {
    purposeDetails: trip?.purposeDetails || '',
    purposeId: trip?.purpose?.id,
  };
};
