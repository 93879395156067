import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;
export const AddButtons = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  justify-content: flex-end;
`;
export const PopoverContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
`;

export const TicketWrapper = styled.div``;

export const ChangesHistoryLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #78787d;
  margin: 24px 0 12px;
`;

export const CostWrapper = styled.div`
  padding-top: 24px;
  margin-top: 24px;
  margin-bottom: 16px;
  box-shadow: 0px -1px 0px #dce1e5;
`

export const CostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ withMargin }) => withMargin && `
    margin-top: 8px;
  `}
`;

export const SubCostTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(120, 120, 125, 0.7);
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SubCost = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(24, 27, 28, 0.7);
`;

export const CostTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #78787d;
`;

export const Cost = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #181b1c;
`;

export const Transfer = styled.div`
  margin: 16px 0;

  & span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #78787d;
    margin-left: 2px;
  }
`;


export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 10px;
  & span {
    font-size: 16px;
  }
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const InfoBlocks = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 10px; 
`;
