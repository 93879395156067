import omit from 'lodash/omit';
import {
  UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA,
  UPDATE_BALANCED_INTEGRATION,
  SET_UPDATE_EXPENSES_STATUS,
  SET_EXPENSES_CHECKBOX_DATA,
  SET_EXPENSES_OPTIONS_DATA,
  SET_IS_UPLOADING_RECEIPTS,
  UPDATE_REIMBURSEMENT_INFO,
  SET_IS_OPEN_ADD_RECEIPTS,
  CLOSE_EXPENSES_OVERVIEW,
  SHOW_EXPENSES_OVERVIEW,
  SET_EXPENSES_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
  UPDATE_STATUS,
} from './actionTypes';

const initState = {
  options: {
    expensesStatuses: [],
    relativeDates: [],
    purposes: [],
    teams: [],
  },
  isUpdateExpensesStatus: false,
  isUploadingReceipts: false,
  isOpenAddReceipts: false,
  isOpenOverview: false,
  checkboxesData: {},
  isFiltersOpen: false,
  expensesData: [],
  overviewData: null,
  totalItems: null,
};

const expensesReducer = (state = initState, action) => {
  switch (action.type) {  
    case SET_EXPENSES_CHECKBOX_DATA:
      const { data, isRemoveAll } = action.payload;

        if (isRemoveAll) {
          return { ...state, checkboxesData: {} };
        }

        return {
          ...state,
          checkboxesData: state.checkboxesData[data?.id] 
            ? omit(state.checkboxesData, data?.id)
            : { ...state.checkboxesData, [data.id]: data },
        };
    
    case UPDATE_REIMBURSEMENT_INFO:
      return {
        ...state,
        expensesData: state.expensesData.map(data => {
          if (data.id === action.payload.tripId) {
            return {
              ...data,
              expensePaymentInfo: action.payload.expensePaymentInfo,
            }
          }

          return data;
        }),
        overviewData: {
          ...state.overviewData,
          trip: {
            ...state.overviewData.trip,
            expensePaymentInfo: action.payload.expensePaymentInfo,
          } 
        },
      };

    case UPDATE_BALANCED_INTEGRATION:
      return {
        ...state,
        expensesData: state.expensesData.map(data => {
          if (data.id === action.payload.tripId) {
            return {
              ...data,
              user: { 
                ...data.user,
                balancedId: action.payload.balancedIntegration.balancedId,
                employer: {
                  ...data.user.employer,
                  registrationNumber: action.payload.balancedIntegration.registrationNumber
                },
              },
            }
          }

          return data;
        }),
        overviewData: {
          ...state.overviewData,
          trip: {
            ...state.overviewData.trip,
            user: { 
              ...state.overviewData.trip.user,
              balancedId: action.payload.balancedIntegration.balancedId,
              employer: {
                ...state.overviewData.trip.user.employer,
                registrationNumber: action.payload.balancedIntegration.registrationNumber
              },
            },
          } 
        },
      };

    case UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA: 
      const updatedInvoiceCreationData = {
        expensesReportInvoiceCreatedAt: action.payload.createdAt,
        expensesReportInvoiceCreatedBy: action.payload.user,
      };

      return {
        ...state,
        expensesData: state.expensesData.map(data => {
          if (data.id === action.payload.tripId) {
            return { ...data, ...updatedInvoiceCreationData };
          }

          return data;
        }),
        overviewData: {
          ...state.overviewData,
          trip: { ...state.overviewData.trip, ...updatedInvoiceCreationData }, 
        },
      };
    
    case UPDATE_STATUS: 
      const updatedData = {
        expenseReportTicketNumber: action.payload.trip.expenseReportTicketNumber,
        expensesStatus: action.payload.trip.expensesStatus,
        status: action.payload.trip.expensesStatus,
        expensesReportSendToVerificationAt: action.payload.trip.expensesReportSendToVerificationAt,
        expensesReportSendToVerificationBy: action.payload.trip.expensesReportSendToVerificationBy,
        expensesReportTransferToToBePaidAt: action.payload.trip.expensesReportTransferToToBePaidAt,
        expensesReportTransferToToBePaidBy: action.payload.trip.expensesReportTransferToToBePaidBy,
        expensesReportInvoiceCreatedAt: action.payload.trip.expensesReportInvoiceCreatedAt,
        expensesReportInvoiceCreatedBy: action.payload.trip.expensesReportInvoiceCreatedBy,
        expensesReportRequestedAt: action.payload.trip.expensesReportRequestedAt,
        expensesReportRequestedBy: action.payload.trip.expensesReportRequestedBy,
        expensesReportSubmittedAt: action.payload.trip.expensesReportSubmittedAt,
        expensesReportSubmittedBy: action.payload.trip.expensesReportSubmittedBy,
        expensesReportApprovedAt: action.payload.trip.expensesReportApprovedAt,
        expensesReportApprovedBy: action.payload.trip.expensesReportApprovedBy,
        expensesReportRefundedAt: action.payload.trip.expensesReportRefundedAt,
        expensesReportRefundedBy: action.payload.trip.expensesReportRefundedBy,
        expensePaymentInfo: action.payload.trip.expensePaymentInfo,
      };
    
      return {
        ...state,
        expensesData: state.expensesData.map(data => {
          if (data.id === action.payload.trip.id) {
            return { ...data, ...updatedData };
          }

          return data;
        }),
        overviewData: {
          ...state.overviewData,
          trip: { ...state.overviewData.trip, ...updatedData },
        },
      };
    
    case SET_EXPENSES_OPTIONS_DATA:
      return {
        ...state,
        options: action.payload.data,
      };

    case SET_EXPENSES_DATA:
      return {
        ...state,
        expensesData: action.payload.data.records,
        totalItems: action.payload.data.total,
      };

    case CLOSE_FILTERS:
      return {
        ...state,
        isFiltersOpen: false,
        expensesData: state.expensesData.map((expense) => ({ ...expense, isSelected: false })),
      };

    case TOGGLE_FILTERS:
      return {
        ...state,
        isOpenOverview: false,
        isFiltersOpen: !state.isFiltersOpen,
        overviewData: null,
        expensesData: state.expensesData.map((expense) => ({ ...expense, isSelected: false })),
      };

    case SHOW_EXPENSES_OVERVIEW:
      return {
        ...state,
        isOpenOverview: true,
        isFiltersOpen: false,
        overviewData: action.payload.data,
        expensesData: state.expensesData.map((expense) => ({
          ...expense,
          isSelected: expense.id === action.payload.data.trip.id,
        })),
      };

    case CLOSE_EXPENSES_OVERVIEW:
      return {
        ...state,
        isOpenOverview: false,
        expensesData: state.expensesData.map((expense) => ({ ...expense, isSelected: false })),
      };

    case SET_UPDATE_EXPENSES_STATUS:
      return {
        ...state,
        isUpdateExpensesStatus: action.payload.data,
      };

    case SET_IS_OPEN_ADD_RECEIPTS:
      return {
        ...state,
        isOpenAddReceipts: action.payload.isOpen,
      };

    case SET_IS_UPLOADING_RECEIPTS:
      return {
        ...state,
        isUploadingReceipts: action.payload.isUploading,
      };
    default:
      return state;
  }
};

export default expensesReducer;
