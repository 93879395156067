import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

export const SelectWrapper = styled.div`
  max-width: 50%;
`;

export const DeleteButton = styled.div`
  button {
    background-color: ${({ theme }) => theme.color.chiChi['100']};
  }
`;

export const InputWrapper = styled.div`
  max-width: 50%;
`;

export const GroupInputWrapper = styled.div`
  display: flex;
  justify-items: center;
  gap: 5px;
  & > * {
    width: calc(70% - 2.5px);
  } 
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  heigth: 100%;
  padding-right: 5px;
  flex-grow: 1;
`;