import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ControlsCloseSmall } from '@heathmont/moon-icons';
import Button from '../Button';
import { HeathmontLogo } from '../HeathmontLogo';
import AccountDetailsTab from '../SignUpCompanyTabs/AccountDetails';
import { CompanyFormTabType } from '../../enums/FormTabType';
import useIsMobile from '../../hooks/useIsMobile';
import { signUpCompanySchema } from '../../pages/SignUpCompany/schema';
import { getIsDisabledNext } from '../../pages/SignUpCompany/suggestions';
import { NotificationWrapper, Notification, NotificationText } from '../../pages/EditProfile/DesktopEditProfile/styles';
import { setSignUpError } from '../../store/signUp/actions';
import SignUpCompanyFormTabs from '../SignUpCompanyFormTabs';
import TeamsTab from '../SignUpCompanyTabs/Teams';
import EmployersTab from '../SignUpCompanyTabs/Employers';
import ManagersTab from '../SignUpCompanyTabs/Managers';
import IntegrationsTab from '../SignUpCompanyTabs/Integrations';
import { Container, Footer, Header, HeaderWrapper, Wrapper } from '../../pages/SignUpCompany/styles';

const RenderActiveTab = ({ activeTab, ...props }) => {
  switch (activeTab) {
    case CompanyFormTabType.AccountDetails:
      return <AccountDetailsTab {...props} />;
    case CompanyFormTabType.Teams:
      return <TeamsTab {...props} />;
    case CompanyFormTabType.Employers:
      return <EmployersTab {...props} />;
    case CompanyFormTabType.Managers:
      return <ManagersTab {...props} />;
    case CompanyFormTabType.Integrations:
      return <IntegrationsTab {...props} />;
    default:
      return null;
  }
};

const SignUpCompanyForm: React.FC<any> = ({ initialValues, checkedTabs, handleSubmit, handleBack, handleNext }) => {
  const activeTab = useSelector((state: any) => state.signUpCompanyReducer.activeTab);
  const submitError = useSelector((state: any) => state.signUpCompanyReducer.submitError);

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  return (
    <Wrapper isMobile={isMobile}>
      <HeaderWrapper>
        <HeathmontLogo style={{ margin: 0 }} />
      </HeaderWrapper>

      <Header>Complete your sign up</Header>

      <SignUpCompanyFormTabs activeTab={activeTab} checkedTabs={checkedTabs} />

      {submitError.isError && (
        <NotificationWrapper>
          <Notification isUpdateError={submitError.isError}>
            <NotificationText>{submitError.message}</NotificationText>
            <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setSignUpError(false))} />
          </Notification>
        </NotificationWrapper>
      )}

      <Formik
        enableReinitialize
        validateOnBlur={true}
        validationSchema={signUpCompanySchema}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount
      >
        {(formProps: any): JSX.Element => {
          const { errors, values } = formProps;

          return (
            <Form>
              <Container maxHeight={270}>
                <RenderActiveTab activeTab={activeTab} {...formProps} />
              </Container>

              <Footer>
                {activeTab !== CompanyFormTabType.AccountDetails && (
                  <Button
                    label="Back"
                    variant="tertiary"
                    handleClick={() => handleBack()}
                    style={{ marginRight: 10 }}
                  />
                )}

                {activeTab !== CompanyFormTabType.Integrations && (
                  <Button
                    label="Next"
                    variant="primary"
                    handleClick={() => handleNext()}
                    isDisabled={getIsDisabledNext(errors, activeTab)}
                  />
                )}

                {activeTab === CompanyFormTabType.Integrations && (
                  <Button
                    label="Sign up"
                    variant="primary"
                    handleClick={() => handleSubmit(values)}
                    isDisabled={getIsDisabledNext(errors, activeTab)}
                  />
                )}
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default SignUpCompanyForm;
