import isEmpty from 'lodash/isEmpty';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import CustomSelect from '../CustomSelect';
import { getRoleLabelById } from '../../utils/tableHelpers';
import schema, { getDefaultRole, getInitialValues } from './schema';
import { IUserInvitationForm } from './types';
import {
  BusinessVerticalField,
  FormFieldsWrapper,
  CheckboxWrapper,
  ButtonsWrapper,
  FieldsGroup,
} from './styles';
import { useEffect, useState } from 'react';
import { TeamTypes } from '../../enums/TeamTypes';
import { EmployerTypes } from '../../enums/EmployerTypes';
import {
  filterItemsWithBusinessVerticalByVerticalId,
  filterItemsWithVerticalsByVerticalObjectId,
  filterBusinessVerticalsByType,
} from '../../utils/filters';
import { BusinessVerticalType } from '../../enums/BusinessVerticalType';

const UserInvitationForm: React.FC<IUserInvitationForm> = ({
  businessVerticals,
  employers,
  loading,
  teams,
  roles,
  onSubmit,
  onCancel,
}): JSX.Element => {
  const formik = useFormik({
    validationSchema: schema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValues(roles),
    onSubmit,
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, setValues } = formik;
  const [filteredEmployersByVertical, setFilteredEmployersByVertical] = useState([]);
  const [filteredTeamsByVertical, setFilteredTeamsByVertical] = useState([]);
  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);

  useEffect(() => {
    const type = values?.external ? BusinessVerticalType.External : BusinessVerticalType.Internal;
    const businessVerticalsByType = filterBusinessVerticalsByType(businessVerticals, type);
    setBusinessVerticalsByType(businessVerticalsByType);
  }, [businessVerticals, values?.external]);

  useEffect(() => {
    const employersByVertical = filterItemsWithVerticalsByVerticalObjectId(employers, values.businessVertical);
    setFilteredEmployersByVertical(employersByVertical);
  }, [employers, values.businessVertical]);

  useEffect(() => {
    const teamsByVertical = filterItemsWithBusinessVerticalByVerticalId(teams, values.businessVertical);
    setFilteredTeamsByVertical(teamsByVertical);
  }, [teams, values.businessVertical]);

  useEffect(() => {
    setValues({
      ...values,
      employer: null,
      team: null,
    });
  }, [values.businessVertical]);

  useEffect(() => {
    setValues({
      ...values,
      businessVertical: null,
      employer: null,
      team: null,
      role: getDefaultRole(roles),
    });
  }, [values.external]);

  useEffect(() => {
    if (!values?.role && roles.length) {
      setFieldValue('role', getDefaultRole(roles));
    }
  }, [roles]);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form>
          <FormFieldsWrapper>
            <FieldsGroup>
              <FormField
                placeholder="Email"
                fieldName="email"
                component={TextInput}
                inputSize="large"
                fieldId="email"
                isError={touched?.email && errors?.email}
                errors={touched?.email && errors}
                value={values.email}
                type="email"
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={() => setFieldTouched('email')}
              />
            
              <CustomSelect
                currentValue={values.role}
                placeholder="Role"
                isDisabled={values?.external}
                isError={!!(touched?.role && errors?.role)}
                error={(touched?.role && errors?.role) as string}
                items={roles}
                getItemLabel={(value) => getRoleLabelById(value, roles)}
                onChange={(value) => setFieldValue('role', value, true)}
                onBlur={() => setFieldTouched('role')}
              />
            </FieldsGroup>

            <CheckboxWrapper>
              <Checkbox
                checked={values?.external}
                label={'External user'}
                onChange={(event) => setFieldValue('external', event.target.checked)}
              />
            </CheckboxWrapper>

            <BusinessVerticalField>
              <CustomSelect
                currentValue={values.businessVertical}
                isSearchable
                placeholder="Business vertical"
                isClearable
                isError={!!(touched?.businessVertical && errors?.businessVertical)}
                error={(touched?.businessVertical && errors?.businessVertical) as string}
                items={businessVerticalsByType}
                onChange={(value) => {
                  if (!value) {
                    setFieldValue('team', value);
                    setFieldValue('employer', value);
                  }
                  setFieldValue('businessVertical', value, true);
                }}
                onBlur={() => setFieldTouched('businessVertical', true, false)}
              />
            </BusinessVerticalField>

            <FieldsGroup>
              <CustomSelect
                currentValue={values.team}
                isSearchable
                placeholder="Team"
                isClearable
                isError={!!(touched?.team && errors?.team)}
                error={(touched?.team && errors?.team) as string}
                items={(
                  filteredTeamsByVertical.filter(team => (
                    values?.external
                      ? team.type === TeamTypes.EXTERNAL
                      : team.type === TeamTypes.INTERNAL
                  ))
                )}
                isDisabled={!values.businessVertical}
                onChange={(value) => setFieldValue('team', value, true)}
                onBlur={() => setFieldTouched('team', true, false)}
              />

              <CustomSelect
                currentValue={values.employer}
                isSearchable
                isClearable
                placeholder="Employer"
                isError={!!(touched?.employer && errors?.employer)}
                error={(touched?.employer && errors?.employer) as string}
                items={(
                  filteredEmployersByVertical.filter(employer => (
                    values?.external
                      ? employer.type === EmployerTypes.EXTERNAL
                      : employer.type === EmployerTypes.INTERNAL
                  ))
                )}
                isDisabled={!values.businessVertical}
                onChange={(value) => setFieldValue('employer', value, true)}
                onBlur={() => setFieldTouched('employer', true, false)}
              />
            </FieldsGroup>

            <FieldsGroup>
              <FormField
                placeholder="First Name"
                fieldName="firstName"
                component={TextInput}
                inputSize="large"
                fieldId="firstName"
                isError={touched?.firstName && errors?.firstName}
                errors={touched?.firstName && errors}
                value={values.firstName}
                type="text"
                onChange={(e) => setFieldValue('firstName', e.target.value)}
                onBlur={() => setFieldTouched('firstName')}
              />

              <FormField
                placeholder="Last Name"
                fieldName="lastName"
                component={TextInput}
                inputSize="large"
                fieldId="lastName"
                isError={touched?.lastName && errors?.lastName}
                errors={touched?.lastName && errors}
                value={values.lastName}
                type="text"
                onChange={(e) => setFieldValue('lastName', e.target.value)}
                onBlur={() => setFieldTouched('lastName')}
              />
            </FieldsGroup>
          </FormFieldsWrapper>

          <ButtonsWrapper>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button disabled={!isEmpty(errors) || loading} variant="primary" type="submit">
              Invite
            </Button>
          </ButtonsWrapper>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default UserInvitationForm;
