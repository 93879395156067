import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextInputGroup } from '@heathmont/moon-select';
import PhoneInput from 'react-phone-number-input';
import { TextInput } from '@heathmont/moon-core';
import FormField from '../../../../../components/FormField';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import { IProfileSettings } from './types';
import { ErrorMessage, ErrorMessageWrapper } from '../../styles';
import { DoubleFieldWrapper, FieldWrapper, PhoneInputWrapper, InfoBlockWrapper } from './styled';
import InfoBlock from '../../../../../components/InfoBlock';

const ProfileSettings: React.FC<IProfileSettings> = ({ values, errors, setFieldValue, options, initialValues }) => {
  const [isFocusedPhone, setIsFocusedPhone] = useState(false);

  const { firstname, lastname, email, phone, birthDate, gender, title } = values.settings;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.settings, initialValues.settings)) {
      dispatch(setIsChangedTabField('settings', true));
    } else {
      dispatch(setIsChangedTabField('settings', false));
    }
  }, [values.settings, initialValues.settings]);

  return (
    <>
      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          type="text"
          inputProps={{
            input1: {
              type: 'text',
              value: firstname,
              onChange: ({ target: { value } }) => setFieldValue('settings.firstname', value),
              inputSize: 'xlarge',
              label: 'First name',
              isError: errors?.settings?.firstname,
            },
            input2: {
              type: 'text',
              value: lastname,
              onChange: ({ target: { value } }) => setFieldValue('settings.lastname', value),
              inputSize: 'xlarge',
              label: 'Last name',
              isError: errors?.settings?.lastname,
            },
          }}
        />

        {(errors?.settings?.firstname || errors?.settings?.lastname) && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.settings?.firstname}</ErrorMessage>

            <ErrorMessage>{errors?.settings?.lastname}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>

      <FormField
        value={email}
        component={TextInput}
        type="email"
        inputSize="xlarge"
        onChange={(e) => setFieldValue('settings.email', e.target.value)}
        errors={errors.settings?.email}
        isError={errors.settings?.email}
        label="Passenger email"
      />
  
      <PhoneInputWrapper
        isFocusedPhone={isFocusedPhone}
        isError={errors?.settings?.phone}
      >
        <FormField
          value={phone}
          component={PhoneInput}
          onChange={(value) => {
            value ? setFieldValue('settings.phone', value) : setFieldValue('settings.phone', '');
          }}
          onFocus={() => setIsFocusedPhone(true)}
          onBlur={() => setIsFocusedPhone(false)}
          placeholder="Passenger phone number"
          international
          defaultCountry="EE"
        />
      </PhoneInputWrapper>

      {errors?.settings?.email && (
        <ErrorMessageWrapper>
          <ErrorMessage>{errors?.settings?.email}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
   
      {errors?.settings?.phone && (
        <ErrorMessageWrapper>
          <ErrorMessage>{errors?.settings?.phone}</ErrorMessage>
        </ErrorMessageWrapper>
      )}

      {
        initialValues?.settings?.email !== email && (
          <InfoBlockWrapper>
            <InfoBlock text={`All your future trip calendar events will be resent to the new passenger email.`} />
          </InfoBlockWrapper>
        )
      }

      <FieldWrapper>
        <FormField
          dateFormat="dd MMM yyyy"
          component={TextInput}
          inputSize="xlarge"
          fieldId="birthDate"
          errors={errors.settings}
          value={birthDate}
          label="Birth date"
          type="date"
          onChange={({ target: { value } }) => setFieldValue('settings.birthDate', value)}
        />
      </FieldWrapper>
      
      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          type="text"
          inputProps={{
            input1: {
              type: 'select',
              value: (options.genders ?? []).find((item) => +item.value === +gender),
              onChange: ({ value }) => setFieldValue('settings.gender', value),
              inputSize: 'xlarge',
              label: 'Gender',
              options: options.genders,
              menuPlacement: 'top',
            },
            input2: {
              type: 'select',
              value: (options.titles ?? []).find((item) => +item.value === +title),
              onChange: ({ value }) => setFieldValue('settings.title', value),
              inputSize: 'xlarge',
              label: 'Title',
              options: options.titles,
              menuPlacement: 'top',
            },
          }}
        />
      </DoubleFieldWrapper>
    </>
  );
};

export default ProfileSettings;
