import styled from 'styled-components';

export const Wrapper = styled.div`
  & input {
    ${({ fontSize }) =>
    fontSize &&
      `
         font-size: ${fontSize}
      `}
  }
`;
