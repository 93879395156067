import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Button, TextInput } from '@heathmont/moon-core';
import { referralCodeGenerationSchema } from './schema';
import { IGenerateReferralCodeDialog } from './types';
import { FormFieldWrapper, ButtonsWrapper } from './styles';
import CustomSelect from '../CustomSelect';
import Dialog from '../Dialog';
import request from '../../models/request';
import FormField from '../FormField';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import InfoBlock from '../InfoBlock';
import moment from 'moment';
import { 
  filterItemsWithVerticalsByVerticalObjectId,
} from '../../utils/filters';

const GenerateReferralCodeDialog: React.FC<IGenerateReferralCodeDialog> = ({
  company,
  team,
  handleCloseDialog,
}) => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [referralLink, setReferralLink] = useState<string | null>(null);
  const [expiredAt, setExpiredAt] = useState<string | null>(null);
  const [generateReferralCodeLoading, setGenerateReferralCodeLoading] = useState<boolean>(false);
  const [isEmployersLoading, setIsEmployersLoading] = useState<boolean>(true);
  const [employers, setEmployers] = useState<any[]>([]);
  const formik = useFormik({
    validationSchema: referralCodeGenerationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      employer: null,
      company: company.id,
      team: team.id,
    },
    onSubmit: () => {},
  });
  const { touched, values, errors, setFieldValue, setFieldTouched } = formik;

  const handleFetchEmployers = async () => {
    try {
      setIsEmployersLoading(true);
      const { data } = await request.get(`tools/companies/employers/${company.id}`);
      const employers = filterItemsWithVerticalsByVerticalObjectId(data, team.businessVertical?.id);
      setEmployers(employers.map((employer) => ({ value: employer.id, title: employer.title })));
    } catch (error) {
      setEmployers([]);
    } finally {
      setIsEmployersLoading(false);
    };
  };

  const handleGenerateReferralCode = async () => {
    try {
      setGenerateReferralCodeLoading(true);
      const response = await request.post('tools/teams/referral-code', values);
      setReferralCode(response?.data?.referralCode);
      setReferralLink(response?.data?.referralLink);
      setExpiredAt(response?.data?.expiredAt);
    } catch (error) {
     getErrorStatusMessage({
        status: error?.response?.status,
        message: error?.response?.data?.error,
      });
    } finally {
      setGenerateReferralCodeLoading(false);
    };
  };

  const getInfoBlockText = () => {
    const expiredAtDate = moment(expiredAt).format('DD MMM YYYY HH:mm');
    return `The referral code and link can be used once and will be available until ${expiredAtDate}.`;
  };
  
  useEffect(() => {
    handleFetchEmployers();
  }, [company.id]);

  return (
    <Dialog
      title="Generate referral code"
      withFooter={false}
      onClose={handleCloseDialog}
      width={500}
    >
      <FormikProvider value={formik}>
        <Form>
          {
            !referralCode ? (
              <>
                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.company}
                    placeholder="Company"
                    isDisabled
                    label="Company"
                    size="xLarge"
                    items={[{ title: company.name, value: company.id }]}
                    onChange={() => {}}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.team}
                    placeholder="Team"
                    isDisabled
                    label="Team"
                    size="xLarge"
                    items={[{ title: team.name, value: team.id }]}
                    onChange={() => {}}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.employer}
                    isSearchable
                    isDisabled={isEmployersLoading}
                    placeholder="Employer"
                    size="xLarge"
                    isError={!!(touched.employer && errors.employer)}
                    error={(touched.employer && errors.employer) as string}
                    items={employers}
                    onChange={value => { 
                      setFieldValue('employer', value)
                      setFieldTouched('employer', true, false);
                    }}
                    onBlur={() => setFieldTouched('employer', true, false)}
                  />
                </FormFieldWrapper>
              </>
            ) : (
              <>
                <InfoBlock text={getInfoBlockText()} />
                <FormFieldWrapper>
                  <FormField
                    copyToClipboard
                    placeholder={'Referral code'}
                    component={TextInput}
                    className={'text-input'}
                    inputSize="xlarge"
                    value={referralCode}
                    type="text"
                    label={'Referral code'}
                    onChange={() => {}}
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FormField
                    copyToClipboard
                    placeholder={'Referral link'}
                    className={'text-input'}
                    component={TextInput}
                    inputSize="xlarge"
                    value={referralLink}
                    label={'Referral link'}
                    type="text"
                    onChange={() => {}}
                  />
                </FormFieldWrapper>
              </>
            ) 
          }

          <ButtonsWrapper>
            <Button variant="secondary" type="button" onClick={handleCloseDialog}>
              {referralCode ? 'Close': 'Cancel'}
            </Button>
            {!referralCode && (
              <Button variant="primary" type="button" disabled={!isEmpty(errors) || generateReferralCodeLoading} onClick={handleGenerateReferralCode}>
                Generate
              </Button>
            )}
          </ButtonsWrapper>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default GenerateReferralCodeDialog;
