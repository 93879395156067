import { useState, useCallback, useMemo } from 'react';
import { TextInput } from '@heathmont/moon-core';
import { ControlsChevronDown, ControlsChevronUp } from '@heathmont/moon-icons';
import ChangePagesPopover from '../ChangePagesPopover';
import { Wrapper, SelectedPagesWrapper, SelectedPages, Icon, PagesPlaceholder } from './styles';
import { IAddNewRole } from './types';
import { getLabels } from '../../utils/tableHelpers';

const MAX_ROLE_NAME_LENGTH = 100;
const MIN_ROLE_NAME_LENGTH = 2;

const AddNewRole: React.FC<IAddNewRole> = ({ pages, newRoleData, setNewRoleData }) => {
  const [isOpenPopoverDialog, setIsOpenPopoverDialog] = useState(false);
  const [isTouchedFieldName, setIsTouchedFieldName] = useState(false);

  const handleIsOpenPopoverDialog = useCallback((isOpen) => {
    setIsOpenPopoverDialog(isOpen);
  }, []);

  const handleAddPages = useCallback((selectedPages) => {
    setNewRoleData((prevState) => ({ ...prevState, pages: selectedPages }));
    handleIsOpenPopoverDialog(false);
  }, []);

  const roleNameErrorLabel = useMemo(() => {
    if (isTouchedFieldName && !newRoleData?.name) {
      return 'Role name cannot be empty';
    }

    if (isTouchedFieldName && newRoleData?.name.length < MIN_ROLE_NAME_LENGTH) {
      return 'Role name cannot be less than 2 characters';
    }

    if (isTouchedFieldName && newRoleData?.name.length > MAX_ROLE_NAME_LENGTH) {
      return 'Role name cannot be longer than 100 characters';
    }

    return '';
  }, [newRoleData, isTouchedFieldName]);

  return (
    <Wrapper>
      <TextInput
        onChange={(event) => setNewRoleData((prevState) => ({ ...prevState, name: event.target.value.toLowerCase() }))}
        onClick={() => setIsOpenPopoverDialog(false)}
        value={newRoleData.name}
        type="text"
        inputSize="xlarge"
        label="Role name"
        isError={isTouchedFieldName && !!roleNameErrorLabel.length}
        hintText={isTouchedFieldName && !!roleNameErrorLabel.length && <span>{roleNameErrorLabel}</span>}
        onBlur={() => setIsTouchedFieldName(true)}
      />

      <SelectedPagesWrapper isActive={isOpenPopoverDialog} onClick={() => setIsOpenPopoverDialog(!isOpenPopoverDialog)}>
        <SelectedPages>
          {!!newRoleData.pages.length ? getLabels(newRoleData.pages) : <PagesPlaceholder>Pages</PagesPlaceholder>}
        </SelectedPages>

        <Icon>{isOpenPopoverDialog ? <ControlsChevronUp /> : <ControlsChevronDown />}</Icon>
      </SelectedPagesWrapper>

      {isOpenPopoverDialog && (
        <ChangePagesPopover
          allPages={pages}
          usersPages={newRoleData.pages}
          handleAgree={handleAddPages}
          handleClose={handleIsOpenPopoverDialog}
        />
      )}
    </Wrapper>
  );
};

export default AddNewRole;
