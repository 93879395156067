import { useDispatch } from 'react-redux';
import { Footer } from '@heathmont/moon-core';
import { HeathmontLogo } from '../HeathmontLogo';
import Button from '../Button';
import { ISignUpCompanyError } from './types';
import { MessageContainer, FormContent, TertButtonWrapper, GenericClose, Heading, Text } from './styles';

const SignUpCompanyError: React.FC<ISignUpCompanyError> = ({
  errorMessage,
  heading = '',
  linkError,
  setErrorData,
  push,
}) => {
  const dispatch = useDispatch();

  return (
    <MessageContainer>
      <FormContent>
        <HeathmontLogo />
        <GenericClose fontSize="5rem" color="dodoria.100" />

        <Heading size={32}>{heading}</Heading>

        <Text linkError={linkError}>{errorMessage}</Text>
      </FormContent>

      {linkError ? (
        <Footer
          primButton={
            <TertButtonWrapper>
              <Button handleClick={() => push('/signin')} label="Sign in" fullWidth variant="default" size="small" />
            </TertButtonWrapper>
          }
          isDivider={true}
        />
      ) : (
        <Footer
          primButton={
            <Button
              handleClick={() => dispatch(setErrorData(false, '', ''))}
              label="Retry"
              fullWidth
              variant="primary"
              size="small"
            />
          }
          secButton={
            <Button handleClick={() => push('/signin')} label="Cancel" fullWidth variant="tertiary" size="small" />
          }
          isDivider={true}
        />
      )}
    </MessageContainer>
  );
};

export default SignUpCompanyError;
