import React from 'react';
import GoogleSvg from '../../static/icons/google.svg';
import { IGoogleLoginButton } from './types';
import { GoogleLoginButtonStyled, IconStyled, TextStyled } from './styles';

const GoogleLoginButton: React.FC<IGoogleLoginButton> = () => {
  const onClick = () => {
    const host = process.env.REACT_APP_API_URL;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    window.location.href = `${host}/api/v${apiVersion}/auth/google`;
  };

  return (
    <GoogleLoginButtonStyled onClick={onClick}>
      <IconStyled>
        <img src={GoogleSvg} />
      </IconStyled>
      <TextStyled>Continue with Google</TextStyled>
    </GoogleLoginButtonStyled>
  );
};
export default GoogleLoginButton;
