import moment from 'moment';
import { IOtherTransportOrder } from './types';
import { getFlightDuration } from '../../utils/commonFunctions';
import TripStepper from '../TripSteapper';

const OtherTransportOrder: React.FC<IOtherTransportOrder> = ({ otherTransport }) => {
  return (
    <TripStepper
      withOtherTransport
      startLabel={otherTransport.departureLocation?.city}
      startDate={moment.utc(otherTransport.departureDate).toString()}
      endLabel={otherTransport.arrivalLocation?.city}
      duration={getFlightDuration(otherTransport.arrivalDate, otherTransport.departureDate)}
      endDate={moment.utc(otherTransport.arrivalDate).toString()}
    />
  );
};

export default OtherTransportOrder;
