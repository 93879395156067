import { Fragment, useMemo } from 'react';
import { TravelAirplaneDown, GenericEdit } from '@heathmont/moon-icons';
import { GenericUser } from '@heathmont/moon-icons-tw';
import { Button } from '@heathmont/moon-core';
import { GenericInfo } from '@heathmont/moon-icons-tw';
import { ITripFlightBooking } from './types';
import { OverviewContentValue } from '../../TripsOverviewDrawerContent/styles';
import CancellationPenalty from '../../CancellationPenalty';
import FlightSegment from '../../FlightSegment';
import Accordion from '../../Accordion';
import InfoBlock from '../../InfoBlock';
import { isFlightCancelable } from '../../../utils/trips';
import {
  ChangesHistoryLabel,
  ButtonsWrapper,
  CostContainer,
  SubCostTitle,
  CostWrapper,
  InfoBlocks,
  CostTitle,
  Transfer,
  SubCost,
  Cost,
} from '../styles';
import {
  isFlightHasNotAppliedLoyaltyProgram,
  getFlightAdditionalServicesCost,
  getNotAppliedLoyaltyPrograms,
  getFlightLabel,
  getFlightCost,
  hasExtraBags,
} from './utils';
import { isFlightExternal, formatDate } from '../utils'
import { AdditionItem, AdditionItemsWrapper } from '../../../styled';
import TripEditButton from '../TripEditButton';
import { AdditionItemTitle } from '../../CancellationPenalty/styles';
import FlightPassengerDetails from '../FlightPassengerDetails';
import FlightAdditionalServicesPerPassenger from '../FlightAdditionalServicesPerPassenger';

const TripFlightBooking: React.FC<ITripFlightBooking> = ({
  hasAccessToSensitiveData,
  travelDocumentTypes,
  hasEditAccess,
  countries,
  flight,
  handleDelete,
  handleEdit,
}) => {
  const title = useMemo(() => {
    return `Flight reference ${flight.reference}`;
  }, [flight.reference]);
  const additionalServicesCost = useMemo(() => getFlightAdditionalServicesCost(flight), [flight]);

  return (
    <Accordion
      withoutPadding
      openByDefault
      title={(
        <OverviewContentValue isBold>
          {title}
        </OverviewContentValue>
      )}
    >
      <InfoBlocks>
        {isFlightHasNotAppliedLoyaltyProgram(flight) && (
          <InfoBlock
            text={`
              At least one of the passenger's loyalty program
              has not been added. Please proceed manually.
              Loyalty program affected: ${getNotAppliedLoyaltyPrograms(flight).join(', ')}.
            `}
          />
        )}

        {!!flight.isInvalidDataReceived && (
          <InfoBlock
            text={`
              [${flight.reference}] PNR for the flight can not be retrieved.
              Invalid data received. Synchronization with Amadeus will not be processed for this flight.
              Please get in touch with the development team for more details.
            `}
          />
        )}
      </InfoBlocks>  
    
      {hasEditAccess && (
        <TripEditButton handleEdit={() => handleEdit(flight.id)}/>
      )}
      
      {flight?.updatedBy?.id && flight.updatedAt && (
        <ChangesHistoryLabel>
          <GenericEdit fontSize="16px" /> Updated by {flight?.updatedBy?.fullName} at {formatDate(flight.updatedAt)}
        </ChangesHistoryLabel>
      )}
    
      {flight.itineraries.map((itinerary, itineraryIndex) => (
        <Fragment key={itinerary.id}>
          <OverviewContentValue isBold>
            {getFlightLabel(itineraryIndex, flight.type)}
          </OverviewContentValue>
          {itinerary.segments.map((segment, segmentIndex) => (
            <Fragment key={segment.id}>
              {segmentIndex > 0 && (
                <Transfer key={segment.id + '_transfer'}>
                  <TravelAirplaneDown /> <span> Flight Transfer</span>
                </Transfer>
              )}
              <FlightSegment
                passengers={flight.passengers}
                segment={segment}
              />
            </Fragment>
          ))}
        </Fragment>
      ))}

      {flight.passengers?.length > 0 && (
        <>
          <AdditionItemTitle>Passengers:</AdditionItemTitle>

          {flight.passengers.map((passenger) => (
            <Fragment key={passenger.id}>
              <AdditionItemsWrapper>
                <GenericUser fontSize="1.5rem" />
                <AdditionItem>
                  {hasAccessToSensitiveData ? (
                    <FlightPassengerDetails
                      travelDocumentTypes={travelDocumentTypes}
                      itineraries={flight.itineraries}
                      countries={countries}
                      passenger={passenger}
                    />
                  ) : (
                    <>{passenger.firstName} {passenger.lastName}</>
                  )}
                </AdditionItem>
              </AdditionItemsWrapper>
              
            </Fragment>
          ))}
        </>
      )}

      {!isFlightExternal(flight) && <CancellationPenalty flight={flight} />}

      <CostWrapper>
        {additionalServicesCost && hasExtraBags(flight) && (
          <>
            <CostContainer>
              <SubCostTitle>Flight cost</SubCostTitle>
              <SubCost>{getFlightCost(flight.price, additionalServicesCost)} EUR</SubCost>
            </CostContainer>
            <CostContainer>
              <SubCostTitle>
                Additional services cost
                <FlightAdditionalServicesPerPassenger flight={flight}>
                  <Button
                    iconOnly={<GenericInfo />}
                    variant='ghost'
                    size='small'
                  />
                </FlightAdditionalServicesPerPassenger>
              </SubCostTitle>
              <SubCost>{additionalServicesCost} EUR</SubCost>
            </CostContainer>
          </>
        )}
        <CostContainer withMargin>
          <CostTitle>Flight total cost</CostTitle>
          <Cost>{flight.price} EUR</Cost>
        </CostContainer>
      </CostWrapper>

      {hasEditAccess && isFlightCancelable(flight) && (
        <ButtonsWrapper>
          <Button animation="error" size="small" onClick={() => handleDelete(flight)}>
            Delete flight
          </Button>
        </ButtonsWrapper>
      )}
    </Accordion>
  );
};

export default TripFlightBooking;
