import {
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
} from '../styles';
import {
  calculateAccommodationsTotalCost,
  calculateExpenseReportTotalCost,
  calculateTransportsTotalCost,
  shouldExpensesCostsBeShow,
} from './utils';
import { ITripTotalCosts } from './types';
import { useMemo } from 'react';

const TripTotalCosts: React.FC<ITripTotalCosts> = ({
  isTravelerExternal,
  paymentRequired,
  trip,
}) => {
  const shouldShowTotalCostsBySections = useMemo(() => {
    return !isTravelerExternal && !paymentRequired;
  }, [isTravelerExternal, paymentRequired]);

  return (
    <>
      {shouldShowTotalCostsBySections && (
        <>
          <OverviewContent>
            <OverviewContentName>Transports cost, EUR</OverviewContentName>
            <OverviewContentValue>
              {trip.flights?.length || trip.otherTransports?.length ? calculateTransportsTotalCost(trip) : 'N/A'}
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Accommodations cost, EUR</OverviewContentName>
            <OverviewContentValue>
              {trip.accommodations?.length ? calculateAccommodationsTotalCost(trip) : 'N/A'}
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Travel expenses, EUR</OverviewContentName>
            <OverviewContentValue>
              {trip.expenses?.length && shouldExpensesCostsBeShow(trip?.expensesStatus) ? calculateExpenseReportTotalCost(trip) : 'N/A'}
            </OverviewContentValue>
          </OverviewContent>
        </>
      )}

      <OverviewContent>
        <OverviewContentName>Total cost, EUR</OverviewContentName>
        <OverviewContentValue>
          <strong>{trip?.totalCost}</strong>
        </OverviewContentValue>
      </OverviewContent>
    </>
  );
};

export default TripTotalCosts;
