import { REIMBURSEMENT_ADDRESSES } from '../../../constants';
import Yup from '../../../utils/validation';

const validateMessages = {
  reimburseTo: {
    required: 'Reimbursed to value can not be empty',
  },
  registrationNumber: {
    max: 'Employer registration number can not be longer than 40 characters',
  },
  balancedId: {
    max: 'Traveler integration number can not be longer than 40 characters',
  },
  cryptoWalletId: {
    required: 'Crypto wallet can not be empty',
  },
  cryptoCurrencyId: {
    required: 'Crypto currency can not be empty',
  },
};

export const reimbursementInfoSchema = Yup.object().shape({
  reimburseTo: Yup
    .string()
    .required(validateMessages.reimburseTo.required),
  cryptoWallet: Yup
    .object()
    .shape({
      id: Yup.number(),
      address: Yup.string(),
    })
    .nullable()
    .test('is-required-if-reimburse-to-crypto-wallet', validateMessages.cryptoWalletId.required, (value, ctx) => {
      return !(ctx.parent.reimburseTo === REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET && !value);
    }),
  cryptoCurrency: Yup
    .object()
    .shape({
      id: Yup.number(),
      title: Yup.string(),
    })
    .nullable()
    .test('is-required-if-crypto-wallet-exist', validateMessages.cryptoCurrencyId.required, (value, ctx) => {
      return !(ctx.parent.reimburseTo === REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET && !value);
    }),
});

export const balancedIntegrationSchema = Yup.object().shape({
  registrationNumber: Yup
    .string()
    .max(40, validateMessages.registrationNumber.max),
  balancedId: Yup
    .string()
    .max(40, validateMessages.balancedId.max),
});

