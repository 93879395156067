import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { TextInput } from '@heathmont/moon-components';
import { Button, Text } from '@heathmont/moon-core';
import { GenericPlus } from '@heathmont/moon-icons';
import {
  AddButtonsWrapper,
  FormItemsWrapper,
  ButtonsWrapper,
  FormWrapper,
  PriceLabel,
  FormItem,
} from '../styles';
import CustomSelect from '../../CustomSelect';
import { AddExternalFlightSchema } from '../schema';
import FormField from '../../FormField';
import { RadioGroup } from '../../RadioGroup';
import ExternalItineraryForm from './ExternalItineraryForm';
import { flightTypeOptions } from '../../../enums/FlightLabels';
import { FLIGHT_TYPE } from '../../../enums/FlightType';
import {
  MAX_NUMBER_OF_MULTI_DESTINATIONS,
  EXTERNAL_REFERENCE_PREFIX,
} from '../../../constants';
import { IExternalFlight } from '../types';
import { getFlightInitialValues } from '../utils';
import { PassengerOption } from '../../../enums/PassengerOption';

const ExternalFlightForm =({
  shouldBeScrollable = false,
  isExternalFlight = true,
  isEditMode = false,
  passengers,
  flightData,
  isLoading,
  handleSubmit,
  handleClose,
}: {
  shouldBeScrollable?: boolean;
  isExternalFlight?: boolean;
  isEditMode?: boolean;
  flightData?: IExternalFlight;
  passengers: PassengerOption[];
  isLoading?: boolean;
  handleSubmit: (values) => void;
  handleClose: () => void;
}) => {
  const currenciesOptions = useSelector((state: any) => state.tripsReducer.tripOptions.currencies);
  const selectedAirports = useSelector((state: any) => state.tripsReducer.externalTrip.selectedOptions.airports);
  const selectedAirlines = useSelector((state: any) => state.tripsReducer.externalTrip.selectedOptions.airlines);

  const getAirlineCodeFromAirlineId = (airlineId) => {
    return selectedAirlines.find((airline) => airline?.value === airlineId)?.code || '';
  };

  const formatFlightDateAndTime = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm');
  };

  const handleEditSegment = (segmentId, setFieldValue, values) => {
    setFieldValue('itineraries', values.itineraries.map(itinerary => ({
      ...itinerary,
      segments: itinerary.segments.map(segment => segment.id === segmentId ? {...segment, editMode: true} : segment)
    })));
  };

  const handleCloseEditSegmentMode = (segmentId, setFieldValue, values) => {
    setFieldValue('itineraries', values.itineraries.map(itinerary => ({
      ...itinerary,
      segments: itinerary.segments.map(segment => segment.id === segmentId ? {...segment, editMode: false} : segment)
    })));
  };

  const handleDeleteSegment = (segmentId, setFieldValue, values) => {
    setFieldValue('itineraries', values.itineraries.map(itinerary => ({
      ...itinerary,
      segments: itinerary.segments.filter((segment) => segment.id !== segmentId)
    })));
  };

  const handleFlightTypeChange = (flightType, values, setFieldValue) => {
    setFieldValue('type', flightType);

    if (flightType === FLIGHT_TYPE.ONE_WAY){
      setFieldValue('itineraries', [values.itineraries[0]]);
    } else if (flightType === FLIGHT_TYPE.ROUND) {
      setFieldValue('itineraries', [values.itineraries[0], { segments: [] }]);
    }
  };

  const handleAddItinerary = (values, setFieldValue) => {
    setFieldValue('itineraries', [...values.itineraries, { segments: [] }]);
  };

  const handleDeleteItinerary = (index, values, setFieldValue) => {
    setFieldValue('itineraries', values.itineraries.filter((itinerary, i) => i !== index));
  };

  return (
    <Formik
      validateOnBlur={true}
      validationSchema={AddExternalFlightSchema}
      initialValues={getFlightInitialValues(flightData, currenciesOptions)}
      onSubmit={(values, { resetForm }): void => {
        resetForm();
      }}
    >
      {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => {
        return (
          <FormWrapper shouldBeScrollable={shouldBeScrollable}>
            <strong>
              <Text size={16}>Flight direction</Text>{' '}
            </strong>
            <FormItemsWrapper>
              <RadioGroup
                isVerticalView={false}
                withAllOption={false}
                selectedValue={values.type}
                data={flightTypeOptions}
                name={'type'}
                onSelect={(value) => handleFlightTypeChange(value, values, setFieldValue)}
              />
            </FormItemsWrapper>
            <>
              {values?.itineraries?.map((itinerary, index) => (
                <ExternalItineraryForm
                  passengers={passengers}
                  airports={selectedAirports}
                  values={values}
                  index={index}
                  type={values.type}
                  getAirlineCodeFromAirlineId={getAirlineCodeFromAirlineId} 
                  handleCloseEditSegmentMode={handleCloseEditSegmentMode}
                  formatFlightDateAndTime={formatFlightDateAndTime}
                  handleDeleteItinerary={handleDeleteItinerary}
                  handleDeleteSegment={handleDeleteSegment}
                  handleEditSegment={handleEditSegment}
                  setFieldValue={setFieldValue}
                />
              ))}
            </>
            {
              values.type === FLIGHT_TYPE.MULTI_DESTINATION && values?.itineraries?.length < MAX_NUMBER_OF_MULTI_DESTINATIONS && (
                <AddButtonsWrapper>
                  <Button
                    variant="ghost"
                    size="small"
                    onClick={() => handleAddItinerary(values, setFieldValue)}
                    iconLeft={<GenericPlus fontSize="1.2rem" />}
                  >
                    Add itinerary
                  </Button>
                </AddButtonsWrapper>
              )
            }
            
            <PriceLabel>Price without margin</PriceLabel>
            <FormItemsWrapper>
              <FormItem>
                <FormField
                  value={values.priceWithoutAdditionalPercent}
                  fieldId="priceWithoutAdditionalPercent"
                  fieldName="priceWithoutAdditionalPercent"
                  component={TextInput}
                  className={'text-input'}
                  type="text"
                  placeholder={'Price without margin'}
                  inputSize="large"
                  onChange={(e) => setFieldValue('priceWithoutAdditionalPercent', e.target.value)}
                  onBlur={handleBlur}
                  isError={touched?.priceWithoutAdditionalPercent && errors?.priceWithoutAdditionalPercent}
                  errors={touched?.priceWithoutAdditionalPercent && errors}
                  errorPosition={-20}
                />
              </FormItem>
              <FormItem>
                <CustomSelect
                  isSearchable
                  isDisabled={true}
                  currentValue={values.currency}
                  placeholder="Select currency"
                  isError={!!(touched?.currency && errors?.currency)}
                  error={touched?.currency && errors?.currency}
                  items={currenciesOptions}
                  onChange={(value) => {
                    setFieldValue('currency', value);
                    setFieldTouched('currency', true, false);
                  }}
                />
              </FormItem>
            </FormItemsWrapper>
            <PriceLabel>Reference</PriceLabel>
            <FormItemsWrapper>
              <FormItem>
                <FormField
                  value={values.reference}
                  fieldId="reference"
                  fieldName="reference"
                  component={TextInput}
                  className={'text-input'}
                  type="text"
                  placeholder={'Reference'}
                  inputSize="large"
                  prefix={isExternalFlight && EXTERNAL_REFERENCE_PREFIX}
                  onChange={(e) => {
                    setFieldValue('reference', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
              </FormItem>
            </FormItemsWrapper>
            <ButtonsWrapper>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                {...(isLoading ? {animation:"progress"} : {})}
                onClick={() => handleSubmit(values)}
                variant="primary"
                disabled={!isEmpty(errors) || (!isEditMode && Object.values(touched).length < 1) || isLoading}
              >
                { isEditMode ? 'Update' : 'Save'}
              </Button>
            </ButtonsWrapper>
          </FormWrapper>
        );
      }}
    </Formik>
  );
}

export default ExternalFlightForm;
