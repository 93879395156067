import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { IBusinessVerticals } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { toolsBusinessVerticalsDataMapper, formatBusinessVerticalLabel } from '../../../utils/tableHelpers';
import { initialColumns } from './suggestions';
import Dialog from '../../../components/Dialog';
import AddNewBusinessVertical from '../../../components/AddNewBusinessVertical';
import DownDrawer from '../../../components/DownDrawer';
import Drawer from '../../../components/Drawer';
import BusinessVerticalsOverviewDrawerContent from '../../../components/BusinessVerticalOverviewDrawerContent';
import { removeBusinessVertical } from '../../../store/tools/businessVerticals/api';
import { Spinner } from '../../../components/Spinner';

const BusinessVerticals: React.FC<IBusinessVerticals> = ({
  isActiveAllBusinessVerticalsCheckboxes,
  businessVerticalsCheckboxesData,
  isDeleteBusinessVerticalsModal,
  isOpenBusinessVerticalOverview,
  isUpdatedBusinessVerticalData,
  overviewBusinessVerticalData,
  isOpenAddNewBusinessVertical,
  businessVerticalsCompanies,
  deletableBusinessVerticals,
  totalBusinessVerticals,
  businessVerticals,
  filters,
  sortBy,
  page,
  setAllBusinessVerticalsCheckboxData,
  closeDeleteBusinessVerticalsModal,
  showDeleteBusinessVerticalsModal,
  setIsOpenAddNewBusinessVertical,
  fetchBusinessVerticalsCompanies,
  setBusinessVerticalCheckboxData,
  closeBusinessVerticalOverview,
  showBusinessVerticalOverview,
  addToolsNewBusinessVertical,
  fetchToolsBusinessVerticals,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const companiesList = useMemo(() => businessVerticalsCompanies.map(({ id, name }) => ({ value: id, title: name })), [businessVerticalsCompanies]);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchToolsBusinessVerticals(params);
    closeBusinessVerticalOverview();
  }, [filters, page, sortBy, isUpdatedBusinessVerticalData]);

  useEffect(() => {
    fetchBusinessVerticalsCompanies();

    return () => {
      closeBusinessVerticalOverview();
    };
  }, []);

  const totalItems = useRef(0);
  const queryParams = useRef('');

  useMemo(() => {
    totalItems.current = totalBusinessVerticals;
  }, [totalBusinessVerticals]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const columns = useMemo(() => initialColumns, []);
  const businessVerticalsGroupedData = useMemo(() => toolsBusinessVerticalsDataMapper(businessVerticals), [businessVerticals]);

  const handleAddNewBusinessVertical = (newBusinessVertical: any) => {
    setIsOpenAddNewBusinessVertical(false);

    addToolsNewBusinessVertical({
      ...newBusinessVertical,
      name: newBusinessVertical.name.trim(),
    }, params);
  };

  const handleRemoveBusinessVertical = useCallback(async (data: any) => {

    await removeBusinessVertical(data);
    fetchToolsBusinessVerticals(params);
    setBusinessVerticalCheckboxData();
    closeDeleteBusinessVerticalsModal();
  }, []);

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showBusinessVerticalOverview(businessVerticals[row?.id] ?? {});
    },
    [businessVerticals]
  );

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete';

    if ((businessVerticalsCheckboxesData && businessVerticalsCheckboxesData.length > 1) || isActiveAllBusinessVerticalsCheckboxes) {
      return `${title} these business verticals?`;
    }

    return `${title} this business vertical?`;
  }, [isActiveAllBusinessVerticalsCheckboxes, businessVerticalsCheckboxesData]);

  const tableContentRecieverProps = {
    isActiveAllBusinessVerticalsCheckboxes,
    checkboxesData: businessVerticalsCheckboxesData,
    isToolsBusinessVerticalsPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TOOLS.BUSINESS_VERTICALS,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    data: businessVerticalsGroupedData,
    page,
    handleIsOverviewOpen,
    handleRemoveDialog: showDeleteBusinessVerticalsModal,
    handleSetAllCheckboxs: setAllBusinessVerticalsCheckboxData,
    handleSetCheckbox: setBusinessVerticalCheckboxData,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Business Verticals</Title>

          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewBusinessVertical(true)}>
            <ControlsPlus /> Add business vertical
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.BUSINESS_VERTICALS}
          totalItems={totalItems}
          data={businessVerticals}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => () => Promise.resolve([])}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {!!businessVerticalsCheckboxesData.length && (
        <DownDrawer
          data={businessVerticalsCheckboxesData}
          text="business vertical"
          handleRemoveDialog={showDeleteBusinessVerticalsModal} 
          handleSetCheckbox={setBusinessVerticalCheckboxData}
        />
      )}

      {isOpenAddNewBusinessVertical && (
        <AddNewBusinessVertical
          companies={companiesList}
          handleCloseDialog={() => setIsOpenAddNewBusinessVertical(false)}
          handleConfirm={handleAddNewBusinessVertical}
        />
      )}

      {isOpenBusinessVerticalOverview && (
        <div>
          <Drawer handleClose={closeBusinessVerticalOverview} title={overviewBusinessVerticalData?.name ?? ''}>
            <BusinessVerticalsOverviewDrawerContent data={overviewBusinessVerticalData} />
          </Drawer>
        </div>
      )}

      {isDeleteBusinessVerticalsModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableBusinessVerticals}
          onSubmit={handleRemoveBusinessVertical}
          onClose={closeDeleteBusinessVerticalsModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default BusinessVerticals;
