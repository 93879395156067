import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const RadioGroupButtons = styled.div`${({ isVerticalView }) => (!isVerticalView && 'display: flex; gap: 30px;')}`;

export const RadioLabel = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin-bottom: 0px;
  padding: 5px 0;
`;
