import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    id: 'expander',
    Header: () => null,
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'formattedHotelId',
    height: 70,
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 250,
    height: 70,
  },
  {
    Header: 'City',
    accessor: 'city',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Address',
    accessor: 'address',
    height: 70,
    minWidth: 250,
  },
  {
    Header: 'Type',
    accessor: 'type',
    height: 70,
    minWidth: 180,
  },
  {
    Header: 'Star rating',
    accessor: 'starRating',
    height: 70,
  },
  {
    Header: 'Contact email',
    accessor: 'email',
    height: 70,
  },
  {
    Header: 'Contact phone',
    accessor: 'phone',
    height: 70,
  },
  {
    Header: 'Check in time',
    accessor: 'checkInTime',
    height: 70,
    disableSortBy: true,
  },
  {
    Header: 'Check out time',
    accessor: 'checkOutTime',
    height: 70,
    disableSortBy: true,
  },
];
