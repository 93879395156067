import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { IHotelRecommendation } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import Drawer from '../../../components/Drawer';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { formatPurpose, toolsRecommendedHotelsDataMapper } from '../../../utils/tableHelpers';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';
import AddNewRecommendedHotel from '../../../components/AddNewRecommendedHotel';
import Dialog from '../../../components/Dialog';
import DownDrawer from '../../../components/DownDrawer';
import RecommendedHotelOverviewDrawerContent from '../../../components/RecommendedHotelOverviewDrawerContent';
import { LIMIT_PER_PAGE } from '../../../constants';
import { formAPIRecommendedHotelData } from './utils';
import { removeRecommendedHotel } from '../../../store/tools/recommendedHotels/api';

const RecommendedHotels: React.FC<IHotelRecommendation> = ({
  isOpenAddNewHotelRecommendation,
  filters,
  sortBy,
  page,
  isDeleteRecommendedHotelsModal,
  isActiveAllRecommendedHotelsCheckboxes,
  recommendedHotelsCheckboxesData,
  recommendedHotels,
  totalRecommendedHotels,
  companies,
  purposes,
  isOpenRecommendedHotelOverview,
  overviewRecommendedHotelData,
  isUpdatedRecommendedHotelsData,
  deletableRecommendedHotels,
  showDeleteRecommendedHotelsModal,
  closeDeleteRecommendedHotelsModal,
  setIsOpenAddNewHotelRecommendation,
  fetchRecommendedHotels,
  setRecommendedHotelsCheckboxData,
  fetchRecommendedHotelsCompanies,
  fetchRecommendedHotelsPurposes,
  setAllRecommendedHotelsCheckboxData,
  showRecommendedHotelOverview,
  closeRecommendedHotelOverview,
  addNewRecommendedHotel,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage, 
  updateRecommendedHotel,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const companiesList = useMemo(() => companies.map(({ id, label }) => ({ value: id, title: label })), [companies]);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchRecommendedHotels(params);
    fetchRecommendedHotelsCompanies();
    fetchRecommendedHotelsPurposes();
  }, [filters, page, sortBy, isUpdatedRecommendedHotelsData]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const mappedData = useMemo(() => toolsRecommendedHotelsDataMapper(recommendedHotels), [recommendedHotels]);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  useMemo(() => {
    totalItems.current = totalRecommendedHotels;
  }, [totalRecommendedHotels]);

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete ';

    if ((recommendedHotelsCheckboxesData && recommendedHotelsCheckboxesData.length > 1) || isActiveAllRecommendedHotelsCheckboxes) {
      return `${title} these recommended hotels?`;
    }

    return `${title} this recommended hotel?`;
  }, [isActiveAllRecommendedHotelsCheckboxes, recommendedHotelsCheckboxesData]);
  
  const handleIsOverviewOpen = useCallback(
    (row?: any) => showRecommendedHotelOverview(recommendedHotels[row?.id]),
    [recommendedHotels]
  );

  const tableContentRecieverProps = {
    isActiveAllRecommendedHotelsCheckboxes,
    isToolsRecommendedHotelsPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    checkboxesData: recommendedHotelsCheckboxesData,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TOOLS.RECOMMENDED_HOTELS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: mappedData,
    handleIsOverviewOpen,
    handleSetAllCheckboxs: setAllRecommendedHotelsCheckboxData,
    handleRemoveDialog: showDeleteRecommendedHotelsModal,
    handleSetCheckbox: setRecommendedHotelsCheckboxData,   
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  const getTripPurposeLabel = (value) => {
    const purpose = purposes.find((purpose) => purpose.value === value);
    return formatPurpose(purpose);
  };

  const handleAddNewHotelRecommendation = useCallback(async (data: any) => {
    setIsOpenAddNewHotelRecommendation(false);

    await addNewRecommendedHotel(formAPIRecommendedHotelData(data));
  }, []);

  const handleRemoveHotelRecommendation = useCallback(async (data: any) => {
    await removeRecommendedHotel(data);

    fetchRecommendedHotels(params);
    setRecommendedHotelsCheckboxData();
    closeDeleteRecommendedHotelsModal();
  }, []);

  const handleUpdateHotelRecommendation = useCallback(async (id, data: any) => {
    setIsOpenAddNewHotelRecommendation(false);
    closeRecommendedHotelOverview();
    await updateRecommendedHotel(id, formAPIRecommendedHotelData(data));
  }, []);

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Hotels recommendations</Title>
          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewHotelRecommendation(true)}>
            <ControlsPlus /> Add hotel recommendation
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.RECOMMENDED_HOTELS}
          totalItems={totalItems}
          data={recommendedHotels}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchRecommendedHotels(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenAddNewHotelRecommendation && (
        <AddNewRecommendedHotel
          companies={companiesList}
          handleCloseDialog={() => setIsOpenAddNewHotelRecommendation(false)}
          handleAgree={handleAddNewHotelRecommendation}
          purposes={purposes}
          getTripPurposeLabel={getTripPurposeLabel}
        />
      )}

       {isOpenRecommendedHotelOverview && (
        <div>
          <Drawer
            title={overviewRecommendedHotelData?.hotel?.name ?? ''}
            handleClose={closeRecommendedHotelOverview}
          >
            <RecommendedHotelOverviewDrawerContent
              data={overviewRecommendedHotelData}
              handleUpdate={handleUpdateHotelRecommendation}
              getTripPurposeLabel={getTripPurposeLabel}
              purposes={purposes}
              companies={companiesList}
            />
          </Drawer>
        </div>
      )}

      {isDeleteRecommendedHotelsModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableRecommendedHotels}
          onSubmit={handleRemoveHotelRecommendation}
          onClose={closeDeleteRecommendedHotelsModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {!!recommendedHotelsCheckboxesData.length && (
        <DownDrawer
          data={recommendedHotelsCheckboxesData}
          handleSetCheckbox={setRecommendedHotelsCheckboxData}
          handleRemoveDialog={showDeleteRecommendedHotelsModal}
          text="recommended hotel"
        />
      )}
    </>
  );
};

export default RecommendedHotels;
