import axios from 'axios';
import qs from 'qs';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { logout } from '../store/auth/actions';
import localStorageHelper from '../utils/localstorage';
import { store } from '../utils/configureStore';
import { STATUS_CODE  } from '../constants';

const request: any = {};

request.init = () => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v${process.env.REACT_APP_API_VERSION}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
};

request.setAuthorized = () => {
  axios.interceptors.request.use(
    (axiosConfig) => {
      const { origin } = new URL(axiosConfig.url, axiosConfig.baseURL);
      const allowedOrigins = [process.env.REACT_APP_API_URL];

      if (allowedOrigins.includes(origin)) {
        const token = localStorageHelper.get('token');
        axiosConfig.headers.authorization = `Bearer ${token}`;
      }

      axiosConfig.paramsSerializer = (params) => qs.stringify(params);
      
      return axiosConfig;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === STATUS_CODE.USER_UNAUTHORIZED) {
      const expired = ['api.auth.expired_token', 'api.auth.invalid_token', 'api.auth.token_needs_to_be_refreshed'];
      const errorText = error.response.data.error;
  
      if (expired.includes(errorText)) {
        const redirectUrl = errorText === 'api.auth.invalid_token' ? '/signin' : '/expired';
        store.dispatch(logout(redirectUrl));
        return Promise.resolve();
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  });
};

request.get = async (endpoint, params) =>
  axios.get(endpoint, params).then(
    (response) => response,
    (error) => {
      console.error(error.response);
      throw error;
    }
  );

request.post = async (endpoint, payload = {}, params = {}) =>
  axios.post(endpoint, payload, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.delete = async (endpoint, params = {}) =>
  axios.delete(endpoint, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.update = async (endpoint, payload = {}, params = {}) =>
  axios.put(endpoint, payload, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.extractErrors = (error) => {
  let errors = [];

  if (error?.response?.data?.error) {
    errors = error.response.data.error;
  } else if (error?.message) {
    errors = [{ message: error.message }];
  }
  
  return errors.length ? errors : null;
};

request.extractStatus = (error) => {
  return error?.response?.status;
};

request.getSuggestions = (queryParams, endpoint, dataSelectors) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(endpoint, {
        params: queryParams,
      });
      if (get(response, 'data.success') == true && get(response, 'data.total', 0) > 0) {
        const options = [];
        response.data.records.forEach((record) => {
          options.push({
            value: get(record, dataSelectors.id, 'id'),
            label: buildCombinedField(record, dataSelectors.label),
          });
        });
        resolve(options);
      } else {
        resolve([]);
      }
    } catch (error) {
      reject(error);
    }
  });

const buildCombinedField = (object, selectors, defaultValue = null) => {
  if (isString(selectors)) {
    return get(object, selectors, defaultValue);
  }

  if (isArray(selectors)) {
    const result = [];

    selectors.forEach((selector) => {
      result.push(get(object, selector, defaultValue));
    });

    return result.join(' ');
  }
};

export default request;
