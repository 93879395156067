import { Formik } from 'formik';
import { parseISO } from 'date-fns';
import moment from 'moment';
import React, { SetStateAction, useState } from 'react';
import { ISelectedFromOtherTransport } from '../types';
import { Button, Text } from '@heathmont/moon-core';
import { ButtonsWrapper, FormItem, FormItemLabel, FormItemLabelWrapper, FormItemsWrapper, FormWrapper, HotelInputWrapper, PriceLabel } from '../styles';
import CustomSelect from '../../CustomSelect';
import request from '../../../models/request';
import DatePicker from '../../../components/DatePicker';
import { useSelector } from 'react-redux';
import { isEmpty, debounce } from 'lodash';
import FormField from '../../FormField';
import { TextInput } from '@heathmont/moon-components';
import { AddOtherTransportSchema } from '../schema';

import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING, EXTERNAL_REFERENCE_PREFIX } from '../../../constants';

const citiesTypes = { departure: 'departure', arrival: 'arrival' };

const getInitialValues = (data: any = {}, currencies) => {
  const departureDate = data?.departureDate ? parseISO(moment.utc(data.departureDate).format('YYYY-MM-DD HH:mm')) : null;
  const arrivalDate = data?.arrivalDate ? parseISO(moment.utc(data.arrivalDate).format('YYYY-MM-DD HH:mm')) : null;
  const defaultCurrencyId = currencies.find((currency) => currency.default === 1)?.value;
  const priceWithoutAdditionalPercent = data.priceMeta ? JSON.parse(data.priceMeta).priceWithoutAdditionalPercent 
    : data.priceWithoutAdditionalPercent ? data.priceWithoutAdditionalPercent : null;

  return {
    id: data?.id ? data.id : null,
    departureDate,
    arrivalDate,
    departureCity: data.departureLocation?.id || data.departureCity || null,
    arrivalCity: data.arrivalLocation?.id || data.arrivalCity || null,
    priceWithoutAdditionalPercent,
    currency: data.currency || defaultCurrencyId,
    reference: data.reference ? data.reference.replace(new RegExp(EXTERNAL_REFERENCE_PREFIX), '') : null,
    type: data.type || null,
  };
};

function OtherTransport({
  isAddExternalTrip = false,
  handleClose,
  isEditMode = false,
  initialOtherTransportData,
  selectedFromOtherTransport,
  setSelectedFromOtherTransport,
  handleSubmit,
  isLoading,
}: {
  isAddExternalTrip?: boolean;
  handleClose: () => void;
  isEditMode?: boolean;
  initialOtherTransportData?: any;
  selectedFromOtherTransport?: ISelectedFromOtherTransport;
  setSelectedFromOtherTransport?: React.Dispatch<SetStateAction<ISelectedFromOtherTransport>>;
  handleSubmit: (values) => void;
  isLoading?: boolean;
}) {
  const departureCitiesOptionsInitState = [];
  const arrivalCitiesOptionsInitState = [];
  if(isEditMode){
    if(selectedFromOtherTransport){
      departureCitiesOptionsInitState.push(...selectedFromOtherTransport.cities);
      arrivalCitiesOptionsInitState.push(...selectedFromOtherTransport.cities);
    } else {
      departureCitiesOptionsInitState.push({ 
        title: `${initialOtherTransportData.departureLocation.city} (${initialOtherTransportData.departureLocation.country})`, 
        value: initialOtherTransportData.departureLocation?.id 
      });
      arrivalCitiesOptionsInitState.push({ 
        title: `${initialOtherTransportData.arrivalLocation.city} (${initialOtherTransportData.arrivalLocation.country})`, 
        value: initialOtherTransportData.arrivalLocation?.id 
      });
    }
  }
  const currenciesOptions = useSelector((state: any) => state.tripsReducer.tripOptions.currencies);
  const [departureCitiesOptions, setDepartureCitiesOptions] = useState(departureCitiesOptionsInitState);
  const [arrivalCitiesOptions, setArrivalCitiesOptions] = useState(arrivalCitiesOptionsInitState);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);

  const getFormattedCities = (cities) => cities
    .map((city) => ({
      title: `${city.name} (${city.country})`,
      value: city.id,
    }));

  const fetchCities = async (search, type, setFieldValue?) => {
    if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
      if(type === citiesTypes.departure){
        setDepartureCitiesOptions([]);
      } else {
        setArrivalCitiesOptions([]);
      }

      setIsCitiesLoading(true);
  
      try {
        const { data } = await request.get(`locations/cities`, { params: { search } });

        if(type === citiesTypes.departure){
          setDepartureCitiesOptions(getFormattedCities(data.airports || []));
          if (setFieldValue) setFieldValue('departureCity', null);
        } else {
          setArrivalCitiesOptions(getFormattedCities(data.airports || []));
          if (setFieldValue) setFieldValue('arrivalCity', null);
        }
      } catch (err) {
        if(type === citiesTypes.departure){
          setDepartureCitiesOptions([]);
        } else {
          setArrivalCitiesOptions([]);
        }
      } finally {
        setIsCitiesLoading(false);
      }
    }
  };

  const handleOtherTransportSubmit = (values) => {
    if (isAddExternalTrip) {
      const departureSelectedCity = departureCitiesOptions.find((city) => city.value === values.departureCity);
      const arrivalSelectedCity = arrivalCitiesOptions.find((city) => city.value === values.arrivalCity);

      setSelectedFromOtherTransport((prevState) => ({
        cities: departureSelectedCity && arrivalSelectedCity ? [...prevState.cities, departureSelectedCity, arrivalSelectedCity] : prevState.cities,
      }));
    }
    handleSubmit(values);
  };

  return (
    <Formik
      validateOnBlur={true}
      validationSchema={AddOtherTransportSchema}
      initialValues={getInitialValues(initialOtherTransportData, currenciesOptions)}
      onSubmit={(values, { resetForm }): void => {
        resetForm();
      }}
    >
      {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => {
        return (
          <FormWrapper>
            <strong>
              <Text size={16}>{isEditMode ? 'Edit' : 'Add'} other transport</Text>
            </strong>
            <FormItemsWrapper>
                <FormItem>
                  <DatePicker
                    format={'MMM dd, yyyy HH:mm'}
                    placeholder='Select departure date'
                    showTimeSelect
                    isEditView={true}
                    errors={touched?.departureDate && errors}
                    value={values.departureDate}
                    field="departureDate"
                    onChange={(field, value) => {
                      setFieldTouched(field, true);
                      setFieldValue(field, value);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <DatePicker
                    format={'MMM dd, yyyy HH:mm'}
                    placeholder='Select arrival date'
                    showTimeSelect
                    isEditView={true}
                    minDate={values.departureDate}
                    errors={touched?.arrivalDate && errors}
                    value={values.arrivalDate}
                    field="arrivalDate"
                    onChange={(field, value) => {
                      setFieldTouched(field, true);
                      setFieldValue(field, value);
                    }}
                  />
                </FormItem>
              </FormItemsWrapper>
              <FormItemsWrapper>
                <FormItem>
                  <CustomSelect
                    minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                    currentValue={values.departureCity}
                    isSearchable
                    placeholder='Select departure city'
                    isLoading={isCitiesLoading}
                    isError={touched?.departureCity && errors?.departureCity}
                    error={touched?.departureCity && errors?.departureCity}
                    items={departureCitiesOptions}
                    onInputChange={debounce((search) => fetchCities(search, citiesTypes.departure, setFieldValue), DEFAULT_DEBOUNCE_MS_COUNT)}
                    onChange={(value) => {
                      setFieldValue('departureCity', value);
                      setFieldTouched('departureCity', true, false);
                    }}
                  />
                </FormItem>
                <HotelInputWrapper>
                  <FormItem>
                   <CustomSelect
                      minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                      currentValue={values.arrivalCity}
                      isSearchable
                      placeholder='Select arrival city'
                      isLoading={isCitiesLoading}
                      items={arrivalCitiesOptions}
                      isError={touched?.arrivalCity && errors?.arrivalCity}
                      error={touched?.arrivalCity && errors?.arrivalCity}
                      onInputChange={debounce((search) => fetchCities(search, citiesTypes.arrival, setFieldValue), DEFAULT_DEBOUNCE_MS_COUNT)}
                      onChange={(value) => {
                        setFieldValue('arrivalCity', value);
                        setFieldTouched('arrivalCity', true, false);
                      }}
                    />
                  </FormItem>
                </HotelInputWrapper>
              </FormItemsWrapper>
              <PriceLabel>Price without margin</PriceLabel>
              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    value={values.priceWithoutAdditionalPercent}
                    fieldId="priceWithoutAdditionalPercent"
                    fieldName="priceWithoutAdditionalPercent"
                    component={TextInput}
                    className={'text-input'}
                    type="text"
                    placeholder={'Price without margin'}
                    inputSize="large"
                    onChange={(e) => setFieldValue('priceWithoutAdditionalPercent', e.target.value)}
                    onBlur={handleBlur}
                    isError={touched?.priceWithoutAdditionalPercent && errors?.priceWithoutAdditionalPercent}
                    errors={touched?.priceWithoutAdditionalPercent && errors}
                    errorPosition={-20}
                  />
                </FormItem>
                <FormItem>
                  <CustomSelect
                    isSearchable
                    isDisabled={true}
                    currentValue={values.currency}
                    placeholder="Select currency"
                    isError={!!(touched?.currency && errors?.currency)}
                    error={touched?.currency && errors?.currency}
                    items={currenciesOptions}
                    onChange={(value) => {
                      setFieldValue('currency', value);
                      setFieldTouched('currency', true, false);
                    }}
                  />
                </FormItem>
              </FormItemsWrapper>
              <FormItemLabelWrapper>
                <FormItemLabel>Reference</FormItemLabel>
                <FormItemLabel>Type</FormItemLabel>
              </FormItemLabelWrapper>
              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    value={values.reference}
                    fieldId="reference"
                    fieldName="reference"
                    component={TextInput}
                    className={'text-input'}
                    type="text"
                    placeholder={'Reference'}
                    inputSize="large"
                    prefix={EXTERNAL_REFERENCE_PREFIX}
                    onChange={(e) => {
                      setFieldValue('reference', e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                </FormItem>
                <HotelInputWrapper>
                  <FormItem>
                    <FormField
                      value={values.type}
                      fieldId="type"
                      fieldName="type"
                      component={TextInput}
                      className={'text-input'}
                      type="text"
                      placeholder={'Bus, train, etc.'}
                      inputSize="large"
                      onChange={(e) => setFieldValue('type', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.type && errors?.type}
                      errors={touched?.type && errors}
                      errorPosition={-20}
                    />
                  </FormItem>
                </HotelInputWrapper>
              </FormItemsWrapper>
              <ButtonsWrapper>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => handleOtherTransportSubmit(values)}
                  {...(isLoading ? {animation:"progress"} : {})}
                  variant="primary"
                  disabled={!isEmpty(errors) || (!isEditMode && Object.values(touched).length < 1) || isLoading}
                >
                  {isEditMode ? "Update": "Save"}
                </Button>
              </ButtonsWrapper>
          </FormWrapper>
        );
      }}
    </Formik>
  );
}

export default OtherTransport;
