import { ArrowsCrossLines } from '@heathmont/moon-icons-tw';
import Avatar from '../Avatar';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';
import { IDashboardOverview } from './types';
import { getUserFromItemByType, getItemInfoByType } from './suggestions';
import {
  InfoBlockMultiItemsWrapper,
  InfoBlockWrapper,
  OverviewWrapper,
  ContentWrapper,
  UpcomingItem,
  DateWrapper,
  MergeIcon,
  IconBlock,
  InfoBlock,
  SubTitle,
  Title,
} from './styles';
import { ControlsPlus, TravelAirplane, TravelHotel } from '@heathmont/moon-icons';

const DashboardOverview: React.FC<IDashboardOverview> = ({ data, type, onClick }) => {
  const renderItemDetails = (item, type) => {
    const { title, date, subTitle } = getItemInfoByType(item, type);
    return (
      <>
        <InfoBlock>
          <Title>{title}</Title>
          {
            subTitle && (
              <SubTitle>{subTitle}</SubTitle>
            )
          }
        </InfoBlock>
        {
          date && (
            <DateWrapper>{date}</DateWrapper>
          )
        }
      </>
    );
  };

  const handleOnClick = (item, type) => {
    if (type === NOTIFICATION_TYPES.EXPIRED_DOCUMENTS) {
      return onClick(`/users?search=${item.email}`);
    }
    
    if (type === NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES || type === NOTIFICATION_TYPES.VERIFYING_EXPENSES) {
      return onClick(`/expenses?search=${encodeURIComponent(item.reference)}`);
    } 
    
    if (type === NOTIFICATION_TYPES.TRIPS_FOR_MERGING) {
      return onClick([item, ...item.tripsForMerging].map(trip => trip.id));
    }

    if (type === NOTIFICATION_TYPES.UNASSIGNED_FLIGHTS) {
      return onClick(item);
    }

    if (type === NOTIFICATION_TYPES.UNASSIGNED_ACCOMMODATIONS) {
      return onClick(item);
    }

    if (type === NOTIFICATION_TYPES.NEW_FEEDBACKS) {
      return onClick(`/trips?search=${encodeURIComponent(item?.trip?.reference)}`);;
    }

    return onClick(`/trips?search=${encodeURIComponent(item.reference)}`);
  };

  const getInfoBlockContentByType = (type, item) => {
    switch (type) {
      case NOTIFICATION_TYPES.TRIPS_FOR_MERGING: {
        return (
          <InfoBlockMultiItemsWrapper>
            {[item, ...item.tripsForMerging].map((trip) => renderItemDetails(trip, type))}
            <MergeIcon>
              <ArrowsCrossLines />
            </MergeIcon>
          </InfoBlockMultiItemsWrapper>
        );
      }
      case NOTIFICATION_TYPES.UNASSIGNED_FLIGHTS: {
        return (
          <InfoBlockMultiItemsWrapper>
            {renderItemDetails(item, type)}
            <MergeIcon>
              <ControlsPlus />
            </MergeIcon>
          </InfoBlockMultiItemsWrapper>
        );
      }
      case NOTIFICATION_TYPES.UNASSIGNED_ACCOMMODATIONS: {
        return (
          <InfoBlockMultiItemsWrapper>
            {renderItemDetails(item, type)}
            <MergeIcon>
              <ControlsPlus />
            </MergeIcon>
          </InfoBlockMultiItemsWrapper>
        );
      }
      default: {
        return renderItemDetails(item, type);
      }
    }
  };

  const getInfoBlockAvatar = (type, item) => {
    switch (type) {
      case NOTIFICATION_TYPES.UNASSIGNED_FLIGHTS:
        return <TravelAirplane fontSize="1.5rem" />;
  
      case NOTIFICATION_TYPES.UNASSIGNED_ACCOMMODATIONS:
        return <TravelHotel fontSize="1.5rem" />;
    
      default:
        return (
          <Avatar
            size="small"
            name={getUserFromItemByType(item, type)}
            color="hit.100"
            avatarStyle={{ borderRadius: '8px' }}
            backgroundColor="piccolo.100"
          />
        );
    }
  };

  return (
    <OverviewWrapper>
      {data.map(item => (
        <UpcomingItem key={item.id} onClick={() => handleOnClick(item, type)}>
          <ContentWrapper>
            <IconBlock>
              {getInfoBlockAvatar(type,item)}
            </IconBlock>
            <InfoBlockWrapper>
              {getInfoBlockContentByType(type,item)}
            </InfoBlockWrapper>
          </ContentWrapper>
        </UpcomingItem>
      ))}
    </OverviewWrapper>
  );
};

export default DashboardOverview;
