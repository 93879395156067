import moment from 'moment';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';
import { defineTripTypeIcon } from '../../utils/tableHelpers';
import { Title, TripMonth } from './styles';
import { getAvgRate } from '../NotificationLayout/utils';
import StarRating from '../StarRating';
import TripReference from '../TripReference';

export const getTime = (date) => moment.utc(date).format('HH:mm');

export const getDate = (date) => moment.utc(date).format('MMM DD');

export const getDateAndYear = (date) => moment.utc(date).format('MMM DD, YYYY');

export const getDateAndTime = (date) => moment.utc(date).format('MMM DD, HH:mm');

export const getItemInfoByType = (data, notificationType) => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.NEW_FEEDBACKS:
      return {
        title: `${data?.traveler?.fullName} — ${data?.trip?.destinationLabel}`,
        subTitle: data?.comments ? data?.comments : null,
        date: <StarRating rate={getAvgRate(data)} size='small' readOnly />,
      };
    case NOTIFICATION_TYPES.EXPIRED_DOCUMENTS:
      return {
        title: data?.fullName,
        date: (
          <>
            <Title>Expiration date</Title>
            <TripMonth>{moment(data?.userDocument?.expiredDate ?? '').format('LL')}</TripMonth>
          </>
        ),
      };
    case NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES:
    case NOTIFICATION_TYPES.VERIFYING_EXPENSES:
      return {
        title: (
          <>
            <TripReference reference={data?.reference}/> {data?.user?.fullName}
          </>
        ),
        subTitle: `${data?.destinationLabel} — ${data?.expensesTotalCost} EUR`,
        date: (
          <>
            <Title>{getTime(data?.tripFinishDate)}</Title>
            <TripMonth>{getDate(data?.tripFinishDate)}</TripMonth>
          </>
        ),
      };
    case NOTIFICATION_TYPES.TRIPS_FOR_MERGING:
      return {
        title: (
          <>
            <TripReference reference={data?.reference}/> {data?.user?.fullName} {defineTripTypeIcon(data.tripType, '1.2rem')}
          </>
        ),
        subTitle: (
          <>{data?.destinationLabel} — {getDateAndTime(data?.tripStartDate)} → {getDateAndTime(data?.tripFinishDate)}</>
        ),
      };
    case NOTIFICATION_TYPES.UNASSIGNED_FLIGHTS:
      return {
        title: (
          <>
            <TripReference reference={data?.reference}/> {data?.traveler?.firstName} {data?.traveler?.lastName}
          </>
        ),
        subTitle: (
          <>{data?.returnCity} — {getDateAndYear(data?.outboundDate)} → {getDateAndYear(data?.returnDate)}</>
        ),
      };
    case NOTIFICATION_TYPES.UNASSIGNED_ACCOMMODATIONS:
      return {
        title: (
          <>
            <TripReference reference={data?.reference}/> {data?.hotel.name}, {data?.city}
          </>
        ),
        subTitle: (
          <>{data?.guest?.firstName} {data?.guest?.lastName} — {getDateAndYear(data?.checkIn)} → {getDateAndYear(data?.checkOut)}</>
        ),
      };
    default:
      return {
        title: (
          <>
            <TripReference reference={data?.reference}/> {data?.user?.fullName}
          </>
        ),
        subTitle: (
          <>{data?.destinationLabel} {defineTripTypeIcon(data.tripType, '1.2rem')}</>
        ),
        date: (
          <>
            <Title>{getTime(data?.tripStartDate)}</Title>
            <TripMonth>{getDate(data?.tripStartDate)}</TripMonth>
          </>
        ),
      };
  };
};

export const getUserFromItemByType = (data, type) => {
  let user;

  if (type === NOTIFICATION_TYPES.EXPIRED_DOCUMENTS) {
    user = data;
  } else if (type === NOTIFICATION_TYPES.NEW_FEEDBACKS) {
    user = data?.traveler;
  } else {
    user = data?.user;
  } 

  return `${(user?.firstname || user?.firstName)[0]}${(user?.lastname || user?.lastName)[0]}`
};
