import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const GoogleLoginButtonStyled = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease-out;

  &:hover {
    background-color: #d2d2d227;
  }
`;

export const IconStyled = styled.div`
  position: absolute;
  left: 14px;
`;

export const TextStyled = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;
