export enum FormTabType {
  AccountDetails = 1,
  ProfileSettings = 2,
  TravelDocument = 3,
  ChangePassword = 4,
}

export enum CompanyFormTabType {
  AccountDetails = 1,
  Teams = 2,
  Employers = 3,
  Managers = 4,
  Integrations = 5,
}

export enum DocumentType {
  Passport = 1,
}

export const tabNames = {
  [FormTabType.AccountDetails]: 'Account details',
  [FormTabType.ProfileSettings]: 'Profile settings',
  [FormTabType.TravelDocument]: 'Travel document',
  [FormTabType.ChangePassword]: 'Password',
};