import Yup from '../../utils/validation';

const validateMessages = {
  reference: {
    min: 'Reference cannot be less than 3 characters',
    max: 'Reference cannot be longer than 40 characters',
    required: 'Reference cannot be empty',
  },
};

export const recommendedHotelSchema = Yup.object().shape({
  reference: Yup.string()
    .required(validateMessages.reference.required)
    .min(3, validateMessages.reference.min)
    .max(40, validateMessages.reference.max)
    .withoutSpaces(),
});
