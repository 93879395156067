import moment from 'moment';
import { TripStatusType } from '../../../enums/TripStatusType';
import { Trip } from '../../../enums/Trip';

export const isFlightPulledFromAmadeusQueue = (trip: Trip) => {
  return !!trip.isPulledFromQueue
    && trip?.flights?.length === 1
    && !trip?.accommodations?.length;
};

export const isAccommodationPulledFromRateHawk = (trip: Trip) => {
  return !!trip.isPulledFromQueue
    && trip?.accommodations?.length === 1
    && !trip?.flights?.length;
};

export const isTripCanBeResendToVerification = (trip: Trip) => {
  const isTripStarted = moment(trip.tripStartDate).isBefore(moment(), 'minute');
  return trip.status === TripStatusType.PURCHASED
    && !trip.approvedBy?.id
    && !isTripStarted
    && !trip.expensesStatus;
};

