export const SET_RECORD_LOADING = 'table/SET_RECORD_LOADING';
export const SET_RECORD_DATA = 'table/SET_RECORD_DATA';
export const SET_LOADING = 'table/SET_LOADING';
export const SET_ACTIONS = 'table/SET_ACTIONS';
export const SET_FILTER = 'table/SET_FILTER';
export const SET_FIELDS = 'table/SET_FIELDS';
export const SET_READY = 'table/SET_READY';
export const SET_TOTAL = 'table/SET_TOTAL';
export const SET_NAME = 'table/SET_NAME';
export const SET_DATA = 'table/SET_DATA';
