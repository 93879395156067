import { useTranslation } from 'react-i18next';

export const usePageTitle = (langKey) => {
  const { t } = useTranslation();

  return t(langKey);
};

export const usePageName = () => {
  const { t } = useTranslation();

  return t('html.home.title');
};
