import { useEffect, useMemo, useState } from 'react';
import { IDailyAllowanceOverview } from './types';
import {
  ButtonsWrapper,
  EditButtonWrapper,
  GroupInputWrapper,
  OverviewItem,
  OverviewItemName,
  OverviewItemsWrapper,
  OptionsBottomElement,
} from './styles';
import { Button } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import FormField from '../FormField';
import { TextInput } from '@heathmont/moon-components';
import CustomSelect from '../CustomSelect';
import { getLabels } from '../../utils/tableHelpers';
import { allowanceOptionSchema, allowanceTypeSchema } from '../AddNewDailyAllowance/schema';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import Accordion from '../Accordion';
import DynamicFormFields from '../DynamicFormFields';
import EllipsisText from '../EllipsisText';
import { checkOnIsEqual } from '../../utils/commonFunctions';
import InfoBlock from '../InfoBlock';
import DailyAllowanceCustomRules from '../DailyAllowanceCustomRules';
import request from '../../models/request';

const getInitialValues = (data) => ({
  company: data?.company?.id || -1,
  currency: data?.currency?.id,
  default: data?.default || false
});

const INFO_TEXT = "Please, note! This is default daily allowance set, it will be used in expense report form, if no custom type for company is created.";

const DailyAllowanceOverviewDrawerContent: React.FC<IDailyAllowanceOverview> = ({
  handleUpdateAllowance,
  handleUpdateOptions,
  data,
}) => {
  const currencyList = useSelector((state: any) => state.expensesReducer.options.currencies);
  const companiesList = useSelector((state: any) => state.companiesReducer.companies);

  const [isEditOptionsData, setIsEditOptionsData] = useState(false);
  const [isEditFormData, setIsEditFormData] = useState(false);

  const initialValues = useMemo(() => getInitialValues(data), [data]);
  const companiesOptions = useMemo(
    () => companiesList.map((company) => ({ value: company.id, title: company.name })),
    [companiesList]
  );

  useEffect(() => {
    setIsEditFormData(false);
    setIsEditOptionsData(false);
  }, [data.id]);

  const handleSubmitType = (values, { resetForm }) => {
    handleUpdateAllowance(values);
    setIsEditFormData(false);
    resetForm();
  };
  const handleSubmitOptions = (values, { resetForm }) => {
    handleUpdateOptions(values);
    setIsEditOptionsData(false);
    resetForm();
  };

  return (
    <div>
      {!!data.default && <InfoBlock text={INFO_TEXT} />}
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
            onClick={() => setIsEditFormData(!isEditFormData)}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>ID</OverviewItemName>
        <OverviewItem>{data.id}</OverviewItem>
      </OverviewItemsWrapper>

      <Formik
        enableReinitialize
        validationSchema={allowanceTypeSchema}
        isInitialValid
        initialValues={initialValues}
        validateOnBlur={true}
        onSubmit={handleSubmitType}
      >
        {({
          errors,
          values,
          resetForm,
          setFieldValue,
          touched,
          handleBlur,
          dirty,
          setFieldTouched,
        }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemsWrapper>
                {data?.company?.name && <OverviewItemName>Company</OverviewItemName>}
                <OverviewItem>
                  {isEditFormData && !data.default ? (
                    <CustomSelect
                      currentValue={values.company}
                      placeholder="Company"
                      isError={touched?.company && errors?.company}
                      error={touched?.company && errors?.company}
                      items={companiesOptions}
                      onChange={(value) => {
                        setFieldValue('company', value);
                        setFieldTouched('company', true, false);
                      }}
                    />
                  ) : (
                    <OverviewItem>{data.company.name}</OverviewItem>
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>
              <OverviewItemsWrapper>
                <OverviewItemName>Currency</OverviewItemName>
                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.currency}
                      placeholder="Currency"
                      isError={touched?.currency && errors?.currency}
                      error={touched?.currency && errors?.currency}
                      items={currencyList}
                      onChange={(value) => {
                        setFieldValue('currency', value);
                        setFieldTouched('currency', true, false);
                      }}
                    />
                  ) : (
                    <OverviewItem>{data.currency.code.toUpperCase()}</OverviewItem>
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              {isEditFormData && (
                <ButtonsWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      setIsEditFormData(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button disabled={!isEmpty(errors)} type="submit">
                    Save
                  </Button>
                </ButtonsWrapper>
              )}
            </Form>
          );
        }}
      </Formik>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validationSchema={allowanceOptionSchema}
        initialValues={{ options: data.options }}
        onSubmit={handleSubmitOptions}
      >
        {({ errors, values, touched, resetForm, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <Accordion title="Options" openByDefault withoutPadding>
                {!isEditOptionsData && (
                  <EditButtonWrapper>
                    <Button
                      variant="ghost"
                      onClick={() => setIsEditOptionsData(!isEditOptionsData)}
                      iconLeft={<GenericEdit fontSize="1.2rem" />}
                    >
                      Edit
                    </Button>
                  </EditButtonWrapper>
                )}

                {isEditOptionsData ? (
                  <DynamicFormFields
                    maxItemsLength={10}
                    fieldName="options"
                    touched={touched}
                    errors={errors}
                    data={values.options}
                    setFieldValue={setFieldValue}
                    getDefaultValue={() => ({ id: uuidv4(), value: 0, description: '' })}
                    renderItem={(fieldName, value, index) => (
                      <GroupInputWrapper>
                        <FormField
                          placeholder={'Value'}
                          fieldName={`${fieldName}.${index}.value`}
                          inputSize="large"
                          component={TextInput}
                          fieldId={`${fieldName}.${index}.value`}
                          isError={
                            get(touched, [fieldName, index, 'value']) && get(errors, [fieldName, index, 'value'])
                          }
                          value={value.value}
                          type="text"
                          onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}.value`, value)}
                        />
                        <FormField
                          placeholder={'Description'}
                          fieldName={`${fieldName}.${index}.description`}
                          inputSize="large"
                          component={TextInput}
                          fieldId={`${fieldName}.${index}.description`}
                          isError={
                            get(touched, [fieldName, index, 'description']) &&
                            get(errors, [fieldName, index, 'description'])
                          }
                          value={value.description}
                          type="text"
                          onChange={({ target: { value } }) =>
                            setFieldValue(`${fieldName}.${index}.description`, value)
                          }
                        />
                      </GroupInputWrapper>
                    )}
                  />
                ) : (
                  <>
                    {data.options.map((option) => (
                      <OverviewItemsWrapper key={option.id}>
                        <OverviewItem>
                          <EllipsisText text={`${option.value} ${data.currency.code.toUpperCase()}`} />
                        </OverviewItem>
                        <OverviewItemName>{option.description}</OverviewItemName>
                      </OverviewItemsWrapper>
                    ))}
                    <OptionsBottomElement />
                  </>
                )}
                {isEditOptionsData && (
                  <ButtonsWrapper>
                    <Button
                      variant="secondary"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        setIsEditOptionsData(!isEditOptionsData);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button variant="primary" type="submit" disabled={checkOnIsEqual(values, data.options)}>
                      Save
                    </Button>
                  </ButtonsWrapper>
                )}
              </Accordion>
            </Form>
          );
        }}
      </Formik>
      {!data.default && (
        <Accordion title="Custom Rules" openByDefault withoutPadding>
          <DailyAllowanceCustomRules customRules={data.customRules} allowanceId={data.id}/>
        </Accordion>
      )}
    </div>
  );
};

export default DailyAllowanceOverviewDrawerContent;
