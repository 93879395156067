import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import FormField from '../FormField';
import { IDatePicker } from './types';

import './styles.scss';

const DataPicker: React.FC<IDatePicker> = ({
  showTimeSelectOnly = false,
  showTimeSelect,
  autoComplete = 'off',
  timeCaption,
  placeholder = "Select date",
  isEditView,
  viewFormat = 'MMM DD, yyyy',
  timeFormat="HH:mm",
  disabled = false,
  minDate,
  maxDate,
  isError,
  errors,
  format = 'MMM dd, yyyy',
  field,
  value,
  onChange,
  onBlur = () => {},
}) => {
  const date = new Date(value);

  if (!isEditView) {
    return <>{moment(value).format(viewFormat)}</>;
  }

  return (
    <FormField
      showTimeSelectOnly={showTimeSelectOnly}
      showMonthDropdown
      showYearDropdown
      showTimeSelect={showTimeSelect}
      placeholderText={placeholder}
      errorPosition={5}
      dropdownMode="select"
      autoComplete={autoComplete}
      timeCaption={timeCaption}
      dateFormat={format}
      timeFormat={timeFormat}
      component={ReactDatePicker}
      disabled={disabled}
      minDate={minDate ? minDate : null}
      maxDate={maxDate ? maxDate : null}
      fieldName={field}
      selected={value ? date : null}
      isError={isError}
      fieldId={field}
      errors={errors}
      value={date}
      type="date"
      onChange={(newValue) => onChange(field, newValue)}
      onBlur={onBlur}
    />
  );
};

export default DataPicker;