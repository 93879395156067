import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TopNav } from '../TopNav';
import { UserInfo } from '../UserInfo';
import { usePageName } from '../../models/page';

import './Header.scss';

const Header = ({ isAuthenticated, isCurrentUserLoaded, isAppReady }) => {
  const pageName = usePageName();

  if (!isAppReady) {
    return null;
  }

  if (isAuthenticated && isCurrentUserLoaded) {
    return (
      <header>
        <nav>
          <Link to="/">{pageName}</Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <TopNav />
            <UserInfo />
          </div>
        </nav>
      </header>
    );
  }

  return (
    <header>
      <nav>
        <Link to="/">{pageName}</Link>
      </nav>
    </header>
  );
};

Header.propTypes = {
  isCurrentUserLoaded: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isAppReady: PropTypes.bool,
};

Header.defaultProps = {
  isAuthenticated: false,
  isCurrentUserLoaded: false,
  isAppReady: false,
};

export default Header;
