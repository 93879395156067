import { connect } from 'react-redux';
import UserInfo from './UserInfo.ui';

const mapStateToProps = ({ currentUser, app }) => ({
  isCurrentUserLoaded: currentUser.isCurrentUserLoaded,
  currentUserData: currentUser.currentUserData,
  isAppReady: app.isAppReady,
});

export default connect(mapStateToProps)(UserInfo);
