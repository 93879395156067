import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import { IAddNewIgnoredAccommodationBooking } from './types';
import { Text, TextInput, Button } from '@heathmont/moon-core';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import FormField from '../FormField';
import {
  FormItemsWrapper,
  ButtonsWrapper,
  DialogWrapper,
  Background,
  CloseIcon,
  FormItem,
  Content,
  Wrapper,
  Footer,
  Title,
} from './styles';
import { recommendedHotelSchema } from './schema';
import InfoBlock from '../InfoBlock';

const initialValues = {
  reference: '',
};

const AddNewIgnoredAccommodationBooking: React.FC<IAddNewIgnoredAccommodationBooking> = ({
  loading,
  handleCloseDialog,
  handleAgree,
}) => {
  return (
    <>
      <Background onClick={() => handleCloseDialog()} />
      <DialogWrapper>
        <Wrapper>
          <Formik
            validateOnBlur={false}
            validationSchema={recommendedHotelSchema}
            initialValues={initialValues}
            onSubmit={(values): void => handleAgree(values)}
          >
            {({ errors, values, resetForm, setFieldValue, touched, handleBlur }: any): JSX.Element => (
              <Form>
                <Title>
                  <Text size={18}>Add new booking for ignore</Text>
                  <CloseIcon onClick={() => handleCloseDialog()}>
                    <CloseSvg />
                  </CloseIcon>
                </Title>
                
                <Content>     
                  <InfoBlock
                    text={`
                      Accommodation booking will be ignored during the next synchronization with RateHawk.
                      If you add an invalid reference, the system will not mark a booking as ignored.
                    `}
                  />                        
                  <FormItemsWrapper>
                    <FormItem fullWidth>
                      <FormField
                        value={values.reference}
                        fieldId="reference"
                        fieldName="reference"
                        component={TextInput}
                        type="text"
                        inputSize="xlarge"
                        onChange={(e) => setFieldValue('reference', e.target.value)}
                        onBlur={handleBlur}
                        errors={touched?.reference && errors}
                        errorPosition={-20}
                        label="Reference"
                      />
                    </FormItem>                      
                  </FormItemsWrapper>
                </Content>
                <Footer>
                  <ButtonsWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        resetForm();
                        handleCloseDialog();
                      }}
                    >Cancel</Button>
                    {handleAgree && (
                      <Button type="submit" disabled={!isEmpty(errors) || loading}>Add</Button>
                    )}
                  </ButtonsWrapper>
                </Footer>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </DialogWrapper>
    </>
  );
};

export default AddNewIgnoredAccommodationBooking;
