import {
  SET_SUBSCRIPTIONS,
  SHOW_SUBSCRIPTION_OVERVIEW,
  CLOSE_SUBSCRIPTION_OVERVIEW,
  SET_SUBSCRIPTION_LOGS,
} from './actionTypes';

export const setSubscriptions = (records) => {
  return { type: SET_SUBSCRIPTIONS, payload: { records } };
};

export const setSubscriptionLogs = (subscriptionLogs) => {
  return { type: SET_SUBSCRIPTION_LOGS, payload: { subscriptionLogs } };
};

export const showSubscriptionOverview = (subscription) => {
  return { type: SHOW_SUBSCRIPTION_OVERVIEW, payload: { data: subscription } };
};

export const closeSubscriptionOverview = () => {
  return { type: CLOSE_SUBSCRIPTION_OVERVIEW };
};