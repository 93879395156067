import styled from 'styled-components';

export const LoyaltyProgramsWrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const GroupInputWrapper = styled.div`
  display: flex;
  justify-items: center;
  gap: 5px;

  & > * {
    width: calc(50% - 2.5px);
  } 
`;