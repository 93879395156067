import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { TextInput } from '@heathmont/moon-core';
import { Wrapper } from './styles';
import { ISearchField } from './types';

const SearchField: React.FC<ISearchField> = ({ searchValue, placeholder, inputSize = 'xsmall', fontSize = '14px', handleChange }) => {
  const handleDebouncedSearch = (value) => handleChange(value);
  const debounceFn = useCallback(debounce(handleDebouncedSearch, 500), []);
  const handleSearchValue = (value) => {
    debounceFn(value);
  };

  return (
    <Wrapper fontSize={fontSize}>
      <TextInput
        defaultValue={searchValue}
        placeholder={placeholder}
        inputSize={inputSize}
        type="search"
        onChange={(event) => handleSearchValue(event.target.value)}
      />
    </Wrapper>
  );
};

export default SearchField;
