import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from "@heathmont/moon-components";
import TablePagination from '../../../components/TablePagination';
import InfoBlock from '../../../components/InfoBlock';
import Dialog from '../../../components/Dialog';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import { Spinner } from '../../../components/Spinner';
import ActionAndNotificationOverviewDrawerContent from '../../../components/ActionAndNotificationOverviewDrawerContent';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { getBoolStatusLabel, toolsActionsAndNotificationsDataMapper } from '../../../utils/tableHelpers';
import Drawer from '../../../components/Drawer';
import { IActionsAndNotifications } from './types';
import { PageHeader, TableWrapper, DialogContent, Title } from '../styles';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import request from '../../../models/request';

const ActionsAndNotifications: React.FC<IActionsAndNotifications> = ({
  isOpenActionAndNotificationOverview,
  overviewActionAndNotificationData,
  totalActionsAndNotifications,
  actionAndNotificationLogs,
  actionsAndNotifications,
  actionsCheckboxesData,
  isChangedAction,
  filters,
  sortBy,
  page,
  closeActionAndNotificationOverview,
  showActionAndNotificationOverview,
  fetchActionsAndNotificationsLogs,
  fetchActionsAndNotifications,
  updateActionAndSubscription,
  setAllActionsCheckboxData,
  setActionsCheckboxData,
  updateActionStatus,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isStartImmediatelyModalOpen, setIsStartImmediatelyModalOpen] = useState(false);
  const [actionForStartImmediately, setActionForStartImmediately] = useState(null);
  const [isStartImmediatelyLoading, setIsStartImmediatelyLoading] = useState(false);
  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchActionsAndNotifications(params);
  }, [filters, page, sortBy]);

  useEffect(() => {
    if (isChangedAction) {
      fetchActionsAndNotifications(params);
    }
  }, [isChangedAction]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const totalItems = useRef(0);
  const queryParams = useRef('');

  useMemo(() => {
    totalItems.current = totalActionsAndNotifications;
  }, [totalActionsAndNotifications]);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const columns = useMemo(() => initialColumns, []);

  const actionsAndSubscriptionsGroupedData = useMemo(
    () => toolsActionsAndNotificationsDataMapper(actionsAndNotifications),
    [actionsAndNotifications]
  );

  const handleIsOverviewOpen = useCallback((row?: any) => {
    fetchActionsAndNotificationsLogs(actionsAndNotifications[row?.id].id);
    showActionAndNotificationOverview(actionsAndNotifications[row?.id] ?? {});
  }, [actionsAndNotifications, isLoadingData]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const tableContentRecieverProps = {
    isShowTableFilters,
    initialColumns: columns,
    checkboxesData: actionsCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TOOLS.ACTIONS_AND_NOTIFICATIONS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    data: actionsAndSubscriptionsGroupedData,
    page,
    handleSetAllCheckboxs: setAllActionsCheckboxData,
    handleIsOverviewOpen,
    toggleTableFilters,
    handleSetCheckbox: setActionsCheckboxData,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  const handleStartImmediatelyModalOpen = (action: number) => {
    setActionForStartImmediately(action);
    setIsStartImmediatelyModalOpen(true);
  };

  const handleStartImmediatelyModalClose = () => {
    setActionForStartImmediately(null);
    setIsStartImmediatelyModalOpen(false);
  };

  const handleStartImmediately = async () => {
    const actionId = actionForStartImmediately?.id;
  
    try {
      setIsStartImmediatelyLoading(true);

      await request.post(`tools/actions-and-notifications/start-immediately/${actionId}`);
    
       getErrorStatusMessage({
        status: 200,
        message: `Actions proceed successfully!`,
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      fetchActionsAndNotificationsLogs(actionId);
      setIsStartImmediatelyLoading(false);
      handleStartImmediatelyModalClose();
    }
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Actions and notifications</Title>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.ACTIONS_AND_NOTIFICATIONS}
          totalItems={totalItems}
          data={actionsAndNotifications}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchActionsAndNotifications(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenActionAndNotificationOverview && (
        <div>
          <Drawer
            handleClose={closeActionAndNotificationOverview}
            title={overviewActionAndNotificationData?.name ?? ''}
            status={overviewActionAndNotificationData?.status}
            getStatusLabel={getBoolStatusLabel}
            withStatus
          >
            <ActionAndNotificationOverviewDrawerContent
              data={overviewActionAndNotificationData}
              logs={actionAndNotificationLogs}
              handleUpdateActionAndNotification={updateActionAndSubscription}
              handleStartImmediately={handleStartImmediatelyModalOpen}
              updateActionStatus={updateActionStatus}
            />
          </Drawer>
        </div>
      )}

      {isStartImmediatelyModalOpen && actionForStartImmediately?.id && (
        <Dialog
          submitButtonLabel="Confirm"
          isSubmitDisabled={isStartImmediatelyLoading}
          maxWidth={500}
          title="Start action immediately"
          onClose={handleStartImmediatelyModalClose}
          onSubmit={handleStartImmediately}
        >
          <DialogContent>
            <InfoBlock text={`The immediate start will not affect the current schedule.`}/>
            <Text size={16}>
              Are you sure you want to start action "<strong>{actionForStartImmediately?.name}</strong>" immediately?
            </Text>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ActionsAndNotifications;
