import { Avatar as MoonAvatar } from '@heathmont/moon-components';
import { Wrapper } from './styles';
import { IAvatar } from './types';

const Avatar: React.FC<IAvatar> = ({
  backgroundColor,
  avatarStyle,
  color,
  size = 'xsmall',
  name,
  img,
  handleClick,
}) => {
  return (
    <Wrapper avatarStyle={avatarStyle} onClick={handleClick} size={size}>
      <MoonAvatar imageUrl={img} size={size} name={name} backgroundColor={backgroundColor} color={color} />
    </Wrapper>
  );
};

export default Avatar;
