import { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { TextInput, Button, Text, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { getBoolStatusLabel } from '../../utils/tableHelpers';
import differenceBy from 'lodash/differenceBy';
import { IEmployersOverviewDrawerEditContent } from './types';
import { 
  OverviewBusinessVerticalWrapper,
  OverviewBusinessVerticalName,
  ConfirmDialogBodyWrapper,
  OverviewItemsWrapper,
  OverviewEmployerItem,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from './styles';
import { employerSchema } from './schema';
import { fetchAllCompanies, updateEmployer } from '../../store/tools/employers/actions';
import { employerStatusNames, EMPLOYER_TYPES } from '../../enums/EmployersTypes';
import CustomSelect from '../CustomSelect';
import { сheckOnSpaces } from '../../utils/commonFunctions'
import { onCompanyChangeHandler } from '../../pages/Tools/Employers/utils';
import { fetchCompanyBusinessVerticals } from '../../store/tools/businessVerticals/api';
import Dialog from '../../components/Dialog';
import InfoBlock from '../../components/InfoBlock';
import { EmployerTypes } from '../../enums/EmployerTypes';
import { getBusinessVerticalsAccountants, isAccountantWithTheSameEmployerBusinessVertical } from '../../../src/utils/employers';
import { filterBusinessVerticalsByType } from '../../utils/filters';

const getInitialValues = (data) => ({
  registrationNumber: data?.registrationNumber ?? '',
  businessVerticals: data?.businessVerticals?.map(item => item?.id) ?? [],
  accountant: isAccountantWithTheSameEmployerBusinessVertical(data?.businessVerticals, data?.accountant) ? data?.accountant?.id : null,
  company: data?.company?.id ?? null,
  status: data?.status ?? null,
  name: data?.name ?? '',
  replacementEmployers: [],
  type: data?.type ?? EmployerTypes.EXTERNAL,
});

const statuses = [
  { title: employerStatusNames[EMPLOYER_TYPES.DISABLED], value: EMPLOYER_TYPES.DISABLED },
  { title: employerStatusNames[EMPLOYER_TYPES.ACTIVE], value: EMPLOYER_TYPES.ACTIVE },
];

const EmployersOverviewDrawerEditContent: React.FC<IEmployersOverviewDrawerEditContent> = ({
  companies,
  data,
  setIsEditFormData,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [accountants, setAccountants] = useState([]);
  const [businessVerticals, setBusinessVerticals] = useState([]);
  const [deletedBusinessVerticals, setDeletedBusinessVerticals] = useState([]);
  const [isOpenedWarningAboutChangingBusinessVertical, setOpenedWarningAboutChangingBusinessVertical] = useState(false);
  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  const getDeletedBusinessVerticals = ({ initBusinessVerticals, currentVerticals })  => {
    return differenceBy(initBusinessVerticals, currentVerticals);
  };

  const handleDeletedBusinessVerticals = ({ 
    allBusinessVerticals, currentVerticals, initBusinessVerticals }) => {
    const verticalsDifference = getDeletedBusinessVerticals({ initBusinessVerticals, currentVerticals });
    setDeletedBusinessVerticals(allBusinessVerticals.filter(bv => verticalsDifference.includes(bv.value)));
  };

  const getEmployersForDeletedBusinessVertical = ({ businessVerticals, deletedBusinessVertical, editingEmployer }) => {
    const [employers] = businessVerticals.filter(bv => bv.value === deletedBusinessVertical).map(bv => bv.employers);
    return employers.filter(employer => employer.id !== editingEmployer).map(employer => ({value: employer.id, title: employer.name } ));
  };

  const setAccountantsForBusinessVertical = (businessVerticals, value, setFieldValue, accountant) => {
    const updatedAccounts = getBusinessVerticalsAccountants(businessVerticals, value);
    setAccountants(updatedAccounts);
    if (!isAvailableAccountantInNewVerticals(updatedAccounts, accountant)) {
      setFieldValue('accountant', null);
    }
  };

  const fetchBusinessVerticals = async () => {
    const businessVerticals = await fetchCompanyBusinessVerticals(initialValues.company);
    setBusinessVerticals(businessVerticals);
  };

  const isAvailableAccountantInNewVerticals = (accountants, currentAccountant) => {
    return accountants.some(accountant => accountant?.value === currentAccountant);
  };

  useEffect(() => {
    const businessVerticalsType = filterBusinessVerticalsByType(businessVerticals, data?.type);
    setBusinessVerticalsByType(businessVerticalsType);
    setAccountants(getBusinessVerticalsAccountants(businessVerticals, initialValues.businessVerticals));
  }, [businessVerticals]);

  useEffect(() => {
    fetchBusinessVerticals();
    dispatch(fetchAllCompanies());
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validationSchema={employerSchema}
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ errors, values, resetForm, setFieldValue, dirty, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemsWrapper>
                <OverviewItemName>Employer</OverviewItemName>

                <OverviewItem>
                  <FormField
                    value={values.name}
                    fieldId="name"
                    fieldName="name"
                    component={TextInput}
                    type="text"
                    inputSize="xlarge"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    errors={errors}
                    errorPosition={-20}
                    label="Employer name"
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Company</OverviewItemName>

                <OverviewItem>
                  <CustomSelect
                    currentValue={values.company}
                    items={companies}
                    isDisabled={true}
                    onChange={(newCompanyId) => onCompanyChangeHandler(values.company, newCompanyId, setFieldValue, setBusinessVerticals)}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Business Verticals</OverviewItemName>

                <OverviewItem>
                  <CustomSelect
                    currentValue={values.businessVerticals}
                    isSearchable
                    placeholder="Business verticals"
                    isClearable
                    isMulti
                    items={businessVerticalsByType}
                    onChange={(value) => {
                      setFieldTouched('businessVerticals', true, false);
                      setFieldValue('businessVerticals', value);
                      setAccountantsForBusinessVertical(businessVerticalsByType, value, setFieldValue, values.accountant);
                      handleDeletedBusinessVerticals({ allBusinessVerticals: businessVerticalsByType, currentVerticals: value, initBusinessVerticals: initialValues.businessVerticals })
                    }}
                    onBlur={() => setFieldTouched('businessVerticals', true, false)}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Registration number</OverviewItemName>

                <OverviewItem>
                  <FormField
                    value={values.registrationNumber}
                    fieldId="registrationNumber"
                    fieldName="registrationNumber"
                    component={TextInput}
                    type="text"
                    inputSize="xlarge"
                    onChange={(e) => setFieldValue('registrationNumber', e.target.value)}
                    errors={errors}
                    errorPosition={-20}
                    label="Registration number"
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Accountant</OverviewItemName>

                <OverviewItem>
                  <CustomSelect
                    currentValue={values.accountant}
                    isSearchable
                    isDisabled={!values.businessVerticals.length}
                    placeholder="Accountant"
                    isClearable
                    items={accountants}
                    onChange={(value) => setFieldValue('accountant', value)}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Status</OverviewItemName>
                <OverviewItem >
                  <CustomSelect
                    currentValue={values.status}
                    items={statuses}
                    getItemLabel={getBoolStatusLabel}
                    onChange={(value) => setFieldValue('status', value)}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>External</OverviewItemName>
                <OverviewItem>
                  <Checkbox checked={data?.type === EmployerTypes.EXTERNAL} disabled />
                </OverviewItem>
              </OverviewItemsWrapper>

              <ButtonsWrapper>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditFormData(false);
                    resetForm();
                  }}
                >Cancel</Button>

                <Button
                  variant="primary"
                  disabled={!dirty || errors?.name || сheckOnSpaces(values.name)}
                  onClick={() => {
                    const difference = getDeletedBusinessVerticals({ initBusinessVerticals: initialValues.businessVerticals, currentVerticals: values.businessVerticals });
                    if (difference?.length) {
                      setOpenedWarningAboutChangingBusinessVertical(true);
          
                      return;
                    }
                    dispatch(updateEmployer(data.id, values));
                    setIsEditFormData(false);
                    resetForm();
                  }}
                >Save</Button>
              </ButtonsWrapper>

              {isOpenedWarningAboutChangingBusinessVertical && (
                <Dialog
                  submitButtonLabel="Confirm"
                  maxWidth={500}
                  title="Confirm"
                  isSubmitDisabled={
                    values.replacementEmployers.length !== deletedBusinessVerticals.length}
                  onSubmit={() => {
                    setIsEditFormData(false);
                    dispatch(updateEmployer(data.id, values));
                    setOpenedWarningAboutChangingBusinessVertical(false);
                   }}
                  onClose={() => setOpenedWarningAboutChangingBusinessVertical(false)}
                >
                  <ConfirmDialogBodyWrapper>
                    {<InfoBlock text={`You want to change business vertical for the Employer who has users. Please, select a new employer to migrate current users to.`} />}
                    {deletedBusinessVerticals.map((bv, index) => {
                      return (
                      <OverviewBusinessVerticalWrapper>
                        <OverviewEmployerItem>
                          <OverviewBusinessVerticalName>{bv.title}</OverviewBusinessVerticalName>
                          <CustomSelect
                            key={index}
                            currentValue={values.replacementEmployers[index]?.employer}
                            isSearchable
                            placeholder="Employer"
                            isClearable
                            items={getEmployersForDeletedBusinessVertical({
                              businessVerticals, deletedBusinessVertical: bv.value, editingEmployer: data.id })}
                            onChange={(value) => {
                              setFieldValue('replacementEmployers',
                                [...values.replacementEmployers, { businessVertical: bv.value, employer: value }])
                            }}
                          />
                        </OverviewEmployerItem>
                      </OverviewBusinessVerticalWrapper>);
                    })}
                  </ConfirmDialogBodyWrapper>
                </Dialog>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmployersOverviewDrawerEditContent;
