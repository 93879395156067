import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';
import NotificationLayout from '../NotificationLayout';
import { INotifications } from './types';
import { Wrapper } from './styles';

const Notifications: React.FC<INotifications> = ({
  notifications,
  history: { push },
  openNotifications,
  handleMergeTripsDialog,
}) => {
  const {
    underConsiderationExpenses,
    verifyingExpenses,
    expiredDocuments,
    tripsForMerging,
    bookedYesterday,
    completedTrips,
    subscriptions,
    approvedTrips,
    expiringTrips,
    pendingTrips,
    newFeedbacks,
  } = notifications;

  const goToTrip = (reference) => {
    push(`/trips?search=${encodeURIComponent(reference)}`);
  };

  const goToExpense = (reference) => {
    push(`/expenses?search=${encodeURIComponent(reference)}`);
  };

  const goToUser = (email) => {
    push(`/users?search=${email}`);
  };

  const goToTools = () => {
    push('/tools/companies');
  };

  return (
    <Wrapper>
      <NotificationLayout
        subTitle={`${newFeedbacks.length} feedbacks(s) provided`}
        items={newFeedbacks}
        title="New feedbacks"
        type={NOTIFICATION_TYPES.NEW_FEEDBACKS}
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() =>
          openNotifications(newFeedbacks, 'New feedbacks', NOTIFICATION_TYPES.NEW_FEEDBACKS)
        }
      />
    
      <NotificationLayout
        subTitle={`${pendingTrips.length} trip(s) to be approved`}
        items={pendingTrips}
        title="Pending trips"
        type={NOTIFICATION_TYPES.PENDING_TRIPS}
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() => openNotifications(pendingTrips, 'Pending trips', NOTIFICATION_TYPES.PENDING_TRIPS)}
      />

      <NotificationLayout
        subTitle={`${approvedTrips.length} approved trip(s)`}
        items={approvedTrips}
        type={NOTIFICATION_TYPES.APPROVED_TRIPS}
        title="Approved trips"
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() => openNotifications(approvedTrips, 'Approved trips', NOTIFICATION_TYPES.APPROVED_TRIPS)}
      />

      <NotificationLayout
        subTitle={`${completedTrips.length} trip(s) completed`}
        items={completedTrips}
        title="Successfully completed trips"
        type={NOTIFICATION_TYPES.COMPLETED_TRIPS}
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() =>
          openNotifications(completedTrips, 'Successfully completed trips', NOTIFICATION_TYPES.COMPLETED_TRIPS)
        }
      />

      <NotificationLayout
        subTitle={`${expiringTrips.length} expired trip(s)`}
        items={expiringTrips}
        title="Expiring trips"
        type={NOTIFICATION_TYPES.EXPIRING_TRIPS}
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() => openNotifications(expiringTrips, 'Expiring trips', NOTIFICATION_TYPES.EXPIRING_TRIPS)}
      />

      <NotificationLayout
        subTitle={`${bookedYesterday.length} trip(s) was booked yesterday`}
        items={bookedYesterday}
        title="Booked yesterday"
        type={NOTIFICATION_TYPES.BOOKED_YESTERDAY}
        handleGoToItem={(id) => goToTrip(id)}
        handleShowAll={() =>
          openNotifications(bookedYesterday, 'Booked yesterday', NOTIFICATION_TYPES.BOOKED_YESTERDAY)
        }
      />

      <NotificationLayout
        subTitle={`${expiredDocuments.length} document(s) are expiring`}
        items={expiredDocuments}
        title="Expiring documents (in 3 months)"
        type={NOTIFICATION_TYPES.EXPIRED_DOCUMENTS}
        handleGoToItem={(id) => goToUser(id)}
        handleShowAll={() =>
          openNotifications(expiredDocuments, 'Expiring documents', NOTIFICATION_TYPES.EXPIRED_DOCUMENTS)
        }
      />

      <NotificationLayout
        subTitle={`${underConsiderationExpenses.length} expense(s)`}
        items={underConsiderationExpenses}
        title="Expenses under review"
        type={NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES}
        handleGoToItem={(id) => goToExpense(id)}
        handleShowAll={() =>
          openNotifications(
            underConsiderationExpenses,
            'Expenses under review',
            NOTIFICATION_TYPES.UNDER_CONSIDERATION_EXPENSES
          )
        }
      />

      <NotificationLayout
        subTitle={`${verifyingExpenses.length} expense(s)`}
        items={verifyingExpenses}
        title="Expenses verification"
        type={NOTIFICATION_TYPES.VERIFYING_EXPENSES}
        handleGoToItem={(id) => goToExpense(id)}
        handleShowAll={() =>
          openNotifications(verifyingExpenses, 'Expenses verification', NOTIFICATION_TYPES.VERIFYING_EXPENSES)
        }
      />

      <NotificationLayout
        subTitle={`${subscriptions.length} subscription(s) are not available`}
        items={subscriptions}
        title="Maintenance notifications"
        type={NOTIFICATION_TYPES.SUBSCRIPTIONS}
        handleGoToItem={goToTools}
        handleShowAll={goToTools}
      />

      <NotificationLayout
        subTitle={`${tripsForMerging.length} combination(s)`}
        items={tripsForMerging}
        title="Possible trips for merging"
        type={NOTIFICATION_TYPES.TRIPS_FOR_MERGING}
        handleMergeTripsDialog={handleMergeTripsDialog}
        handleShowAll={() =>
          openNotifications(tripsForMerging, 'Possible trips for merging', NOTIFICATION_TYPES.TRIPS_FOR_MERGING)
        }
      />
    </Wrapper>
  );
};
export default Notifications;
