import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DefaultLabel = styled.span`
  font-size: 14px;
`;