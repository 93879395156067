import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  & div:first-child {
    margin-left: 0;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 68px;
  padding: 12px 4px;
  margin: 0 18px;
`;

export const NumberName = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8697a2;
  width: 100%;
  white-space: nowrap;
`;

export const NumberCountWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const NumberCount = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const TravelerName = styled(NumberCount)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NumberChange = styled.span`
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 2px;
  color: ${({ isPositive }) => (isPositive ? '#0DE76C' : '#FF1F39')};
`;

export const Divider = styled.span`
  border-right: 1px solid #e0e0e0;
  height: 40px;
  background: #e0e0e0;
  margin: 0;
`;
