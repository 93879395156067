import { memo, useMemo } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { ElementSize } from '../../enums/ElementSize';
import { getFileTypeByUrl, isPdf } from '../../utils/commonFunctions';
import FileLoader from '../FileLoader';
import { IFileViewer } from './types';
import { PdfStyled, ImgStyled } from './styles';

const FileViewer: React.FC<IFileViewer> = ({
  preview = false,
  type,
  url,
}) => {
  const fileType = useMemo(() => type || getFileTypeByUrl(url), [url, type]);

  if (!fileType) {
    return null;
  }

  if (isPdf(fileType)) {
    return (
      <PdfStyled preview={preview}>
        <PDFViewer
          hideNavbar
          canvasCss="canvas-wrapper"
          document={{ url }}
          loader={<FileLoader size={preview ? ElementSize.SMALL : ElementSize.LARGE}/>}
          css="pdf-wrapper"
          key={url}
        />
      </PdfStyled>
    );
  }

  return (
    <ImgStyled
      preview={preview}
      src={url}
      alt="receipt"
    />
  );
};

export default memo(FileViewer);;
