import {
  SET_TOOLS_ALL_CUSTOM_HOTELS_CHECKBOX_DATA,
  SET_TOOLS_CUSTOM_HOTELS_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_CUSTOM_HOTELS,
  SET_IS_UPDATED_CUSTOM_HOTELS_DATA,
  CLOSE_DELETE_CUSTOM_HOTELS_MODAL,
  SHOW_DELETE_CUSTOM_HOTELS_MODAL,
  CLOSE_CUSTOM_HOTEL_OVERVIEW,
  SHOW_CUSTOM_HOTEL_OVERVIEW,
} from './actionTypes';

export const setIsOpenAddNewCustomHotel = (isOpen = false) => ({
  type: SET_IS_OPEN_ADD_NEW_CUSTOM_HOTELS,
  payload: isOpen,
});

export const showDeleteCustomHotelsModal = (data: any[] | any) => {
  const deletableCustomHotels = data?.length
    ? data.map((item) => item.customHotelId)
    : [data.customHotelId];
  
  return {
    type: SHOW_DELETE_CUSTOM_HOTELS_MODAL,
    payload: {
      deletableCustomHotels,
    },
  };
};

export const closeDeleteCustomHotelsModal = () => ({
  type: CLOSE_DELETE_CUSTOM_HOTELS_MODAL,
});

export const setCustomHotelsCheckboxData = (checkboxData?: any) => ({
  type: SET_TOOLS_CUSTOM_HOTELS_CHECKBOX_DATA,
  payload: {
    data: checkboxData,
  }
});

export const setAllCustomHotelsCheckboxData = (checkboxData = [], isActive = false) => ({
  type: SET_TOOLS_ALL_CUSTOM_HOTELS_CHECKBOX_DATA,
  payload: {
    isActive,
    data: checkboxData,
  },
});

export const showCustomHotelOverview = (data) => ({
  type: SHOW_CUSTOM_HOTEL_OVERVIEW,
  payload: {
    data,
  },
});

export const closeCustomHotelOverview = () => ({
  type: CLOSE_CUSTOM_HOTEL_OVERVIEW,
});

export const setIsUpdatedCustomHotelsData = (data = false) => ({
  type: SET_IS_UPDATED_CUSTOM_HOTELS_DATA,
  payload: {
    data,
  }
});
