import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 8px;
  width: 64px;
  background: #f6f5f4;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
`;

export const Logo = styled.span`
  cursor: pointer;

  & svg {
    width: 40px;
    height: 40px;
  }
`;

export const NavElements = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NavElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ isActive }) => isActive && 'rgba(253, 239, 196, 0.38)'};
  border-radius: 8px;
  margin: 16px 0px;
  padding: 8px;

  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
