import { Helmet } from 'react-helmet';
import { SuccessMessage } from '../../components/SuccessMessage';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBgNew';

export const Expired = () => {
  const renderForm = () => (
    <SuccessMessage
      linkText={'Login'}
      heading={'Session expired'}
      message={'Your session has ended due of inactivity. Please, Login to continue, we kept all of your filters and breakdowns in place.'}
      link={'/login'}
      type="info"
    />
  );

  return (
    <>
      <Helmet>
        <title>Expired</title>
      </Helmet>

      <FullScreenRandomBg renderComponent={renderForm()} />
    </>
  );
};
