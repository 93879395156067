import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 12px;
`;

export const ConfirmTextWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`;

export const FormItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #181b1c;
  text-align: start;

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}

  .text-input{
    height: 48px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  label {
    margin: 0;
  }
`;
