import { Button } from '@heathmont/moon-core';
import isEmpty from 'lodash/isEmpty';
import { IFormActions } from './types';
import {
  ActionsWrapper,
  ButtonsWrapper,
  DeleteButton,
} from './styles';

const FormActions: React.FC<IFormActions> = ({ errors, onDelete, onCancel }) => {
  return (
    <ActionsWrapper>
      {onDelete && (
        <DeleteButton>
          <Button onClick={onDelete}>Delete</Button>
        </DeleteButton>
      )}
      <ButtonsWrapper>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button disabled={!isEmpty(errors)} variant="primary" type="submit">Save</Button>
      </ButtonsWrapper>
    </ActionsWrapper>
  );
};

export default FormActions;
