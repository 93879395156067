import { useMemo } from 'react'
import { Form } from 'formik';
import { ContentWrapper, FlightInfoBlock, OverviewItem, Separator, TripOverviewWrapper } from './styles'
import CustomSelect from '../CustomSelect';
import { useSelector } from 'react-redux';
import moment from 'moment';

const UnassignedFlightsDialogContent = ({flightData,values,errors,setFieldValue,setFieldTouched, touched}) => {
  const companyUsers = useSelector((state: any) => state.tripsReducer.tripOptions.companyEmployees)
  
  const formatFlightDateAndTime = (date) => {
    return moment(date).format('DD MMM YYYY, HH:mm');
  };

  const usersOptions = useMemo(() => companyUsers.map((user) => ({value: user.value,title:`${user.title} (${user.email})`})),[companyUsers])

  return (
    <ContentWrapper>
      <Form>
        <TripOverviewWrapper>
          <FlightInfoBlock>
            <p>
              {`[`}
              <strong>{flightData.reference}</strong>
              {`]`}
              {` ${flightData.outboundCity} → ${flightData.returnCity} — `}
              <strong>{`${flightData.price.value} ${flightData.price.currency}`}</strong>
            </p>
            <p className='flight-dates'>{`${formatFlightDateAndTime(flightData.outboundDate)} → ${formatFlightDateAndTime(
              flightData.returnDate
            )}`}</p>
            {flightData.traveler.firstName && flightData.traveler.lastName && (
              <p>
              <strong>Traveler name: </strong>
              {`${flightData.traveler.firstName} ${flightData.traveler.lastName}`}
            </p>
            )}
            {flightData.traveler.email && (
              <p>
              <strong>Traveler email: </strong>{`${flightData.traveler.email}`}
            </p>
            )}
            {!!flightData.traveler.documents.length && (
              <p>
              <strong>Traveler document number: </strong>
              {`${flightData.traveler.documents[0].number}`}
            </p>
            )}
          </FlightInfoBlock>
        </TripOverviewWrapper>
        <Separator />
        <OverviewItem>
          <CustomSelect
            isSearchable
            currentValue={values.user}
            placeholder="Select traveler"
            isError={touched?.user && errors?.user}
            error={touched?.user && errors?.user}
            items={usersOptions}
            onChange={(value) => {
              setFieldValue('user', value);
              setFieldTouched('user', true, false);
            }}
          />
        </OverviewItem>
      </Form>
    </ContentWrapper>
  );
}

export default UnassignedFlightsDialogContent