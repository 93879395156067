import {
  SET_EMPLOYMENT_TYPES,
  SHOW_EMPLOYER_TYPE_OVERVIEW,
  CLOSE_EMPLOYER_TYPE_OVERVIEW,
  SHOW_DELETE_EMPLOYMENT_TYPES_MODAL,
  CLOSE_DELETE_EMPLOYMENT_TYPES_MODAL,
  SET_TOOLS_ALL_EMPLOYMENT_TYPES_CHECKBOX_DATA,
  SET_TOOLS_EMPLOYMENT_TYPES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_EMPLOYMENT_TYPE,
  SET_IS_CHANGED_EMPLOYMENT_TYPE,
} from './actionTypes';

export const setEmploymentTypesData = (records, total) => {
  return { type: SET_EMPLOYMENT_TYPES, payload: { records, total } };
};

export const showEmployerTypeOverview = (data) => {
  return { type: SHOW_EMPLOYER_TYPE_OVERVIEW, payload: { data } };
};

export const closeEmployerTypeOverview = () => {
  return { type: CLOSE_EMPLOYER_TYPE_OVERVIEW };
};

export const showDeleteEmploymentTypesModal = (data: any[] | any) => {
  const deletableData = data?.length ? data.map((item) => item.employmentTypeId) : [data?.employmentTypeId];

  return { type: SHOW_DELETE_EMPLOYMENT_TYPES_MODAL, payload: { data: deletableData } };
};

export const closeDeleteEmploymentTypesModal = () => {
  return { type: CLOSE_DELETE_EMPLOYMENT_TYPES_MODAL };
};

export const setEmploymentTypesCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_EMPLOYMENT_TYPES_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllEmploymentTypesCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_EMPLOYMENT_TYPES_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setIsOpenAddNewEmploymentType = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_EMPLOYMENT_TYPE, payload: isOpen };
};

export const setIsChangedEmploymentType = (isChanged) => {
  return { type: SET_IS_CHANGED_EMPLOYMENT_TYPE, payload: { isChanged } };
};
