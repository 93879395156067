export const REMOVE_PASSENGER_FROM_SELECTED = 'builder/REMOVE_PASSENGER_FROM_SELECTED';
export const ROUTE_SEGMENT_SET_DEPARTURE = 'builder/ROUTE_SEGMENT_SET_DEPARTURE';
export const CLEAR_PASSENGERS_AVAILABLE = 'builder/CLEAR_PASSENGERS_AVAILABLE';
export const SET_UPDATE_OFFER_REQUESTED = 'builder/SET_UPDATE_OFFER_REQUESTED';
export const ROUTE_SEGMENT_SET_ARRIVAL = 'builder/ROUTE_SEGMENT_SET_ARRIVAL';
export const SET_PASSENGERS_AVAILABLE = 'builder/SET_PASSENGERS_AVAILABLE';
export const SET_PASSENGERS_SELECTED = 'builder/SET_PASSENGERS_SELECTED';
export const ROUTE_SEGMENT_SET_SYNC = 'builder/ROUTE_SEGMENT_SET_SYNC';
export const ROUTE_SEGMENT_SET_DATE = 'builder/ROUTE_SEGMENT_SET_DATE';
export const SET_PASSENGERS_LOADED = 'builder/SET_PASSENGERS_LOADED';
export const SET_OFFERS_REQUESTED = 'builder/SET_OFFERS_REQUESTED';
export const ROUTE_SEGMENT_REMOVE = 'builder/ROUTE_SEGMENT_REMOVE';
export const SET_ROUTE_SHORT_VIEW = 'builder/SET_ROUTE_SHORT_VIEW';
export const SET_OFFERS_LOADING = 'builder/SET_OFFERS_LOADING';
export const SET_OFFER_SELECTED = 'builder/SET_OFFER_SELECTED';
export const SET_OFFER_PLACING = 'builder/SET_OFFER_PLACING';
export const ROUTE_SEGMENT_ADD = 'builder/ROUTE_SEGMENT_ADD';
export const SET_COMPANY = 'builder/SET_COMPANY';
export const OFFERS_DATA = 'builder/OFFERS_DATA';
export const SET_USER = 'builder/SET_USER';
