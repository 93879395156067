import styled from 'styled-components'

export const ContentWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  padding-top: 0;
`;

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  padding-right: 5px;
  line-height: 24px;
  max-width: 160px;
  width: 100%;
`;
export const Separator = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 190px;
  width: 100%;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}
`;

export const FlightInfoBlock = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const TripOverviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f6f5f4;
  border-radius: 16px;
  width: 100%;
  min-height: 32px;
  height: 100%;
  margin-bottom: 10px;
  padding: 12px;

  .flight-dates{
    color: #78787D;
  }
`;