import { Button } from '@heathmont/moon-core';
import { GenericInfo, GenericPlus } from '@heathmont/moon-icons';
import { IInfoBlock } from './types';
import {
  InfoBlockWrapper,
  ActionsWrapper,
  InfoBlockText,
  IconWrapper,
} from './styles';
import { trimLongText } from '../../utils/commonFunctions';

const InfoBlock: React.FC<IInfoBlock> = ({
  withoutMargin = false,
  actionLabel,
  text,
  onHandleAction,
  onClick,
}) => {
  return (
    <div>
      <InfoBlockWrapper withoutMargin={withoutMargin} >
        <IconWrapper>
          <GenericInfo />
        </IconWrapper>
        <InfoBlockText>{trimLongText(text)}</InfoBlockText>
        {actionLabel && (
          <ActionsWrapper>
            <Button variant="ghost" size="small" onClick={onHandleAction}>{actionLabel}</Button>
          </ActionsWrapper>
        )}
      </InfoBlockWrapper>
      {
        onClick && (
          <Button iconLeft={<GenericPlus />} variant="ghost" onClick={onClick}>
            Add integration
          </Button>
        )
      }
    </div>
  );
};

export default InfoBlock;
