import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { CompanyIntegrationInfo } from '../../pages/CompanyIntegrationInfo';
import { ForgotPassword } from '../../pages/ForgotPassword';
import { ResetPassword } from '../../pages/ResetPassword';
import { Expired } from '../../pages/Expired';
import { TextPage } from '../../pages/TextPage';
import { Login } from '../../pages/Login';
import SignUpCompany from '../../pages/SignUpCompany';
import SignUpSwitcher from '../../pages/SignUp';
import GoogleAuth from '../../pages/GoogleAuth';

const PublicRoutes = () => (
  <Switch>
    <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
    <Route exact path="/reset-password" render={(props) => <ResetPassword {...props} />} />
    <Route exact path="/expired" render={(props) => <Expired {...props} />} />
    <Route exact path="/signin" render={(props) => <Login {...props} />} />
    <Route exact path="/signup" render={(props) => <SignUpSwitcher {...props} />} />
    <Route exact path="/company-integration-info" render={(props) => <CompanyIntegrationInfo {...props} />} />
    <Route exact path="/company-signup" render={(props) => <SignUpCompany {...props} />} />
    <Route exact path="/signup/welcome" render={(props) => <TextPage {...props} pageId="signup-welcome" />} />
    <Route exact path="/google-auth" render={(props) => <GoogleAuth {...props} />} />
    <Route render={(props) => <Redirect {...props} to="/signin" />} />
  </Switch>
);

export default withRouter(PublicRoutes);
