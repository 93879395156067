import styled from 'styled-components';

export const CopyToClipboardStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const TextWrapper = styled.div`
  max-width: 120px;
  margin-right: 5px;
`;
