import { useState } from 'react';
import { TravelSuitcase } from '@heathmont/moon-icons-tw';
import { Popover } from '@heathmont/moon-popover';
import { Button } from '@heathmont/moon-core';
import { formatCountry, formatDateForDocument, formatDocumentType, formatGender } from './utils';
import { IFlightPassengerDetails } from './types';
import {
  PassengerDetailsSectionTitle,
  BaggageDetailsSubTitle,
  PassengerDetailsValue,
  PassengerDetailsLabel,
  PassengerDetailsItem,
  BaggageDetailsTitle,
  CloseButtonWrapper,
  PassengerDetails,
  PassengerTitle,
  BaggageDetails,
  BaggageItem,
  BaggageItems,
} from './styles';
import { extractExtraBags } from '../../FlightSegment/utils';

const FlightPassengerDetails: React.FC<IFlightPassengerDetails> = ({
  travelDocumentTypes,
  itineraries,
  countries,
  passenger,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderItem = (label, value) => (
    <PassengerDetailsItem>
      <PassengerDetailsLabel>
        {label}
      </PassengerDetailsLabel>
      <PassengerDetailsValue>
        {value}
      </PassengerDetailsValue>
    </PassengerDetailsItem>
  );

  const renderDocument = () => (
    <>
      <PassengerDetailsSectionTitle>
        Document
      </PassengerDetailsSectionTitle>
      {renderItem('Type', formatDocumentType(passenger.document.typeId, travelDocumentTypes))}
      {renderItem('Country', formatCountry(passenger.document.countryId, countries))}
      {renderItem('Number', passenger.document.number)}
      {renderItem('Expiration date', formatDateForDocument(passenger.document.validTill))}
    </>
  );

  const renderAdditionalServices = () => {    
    const itinerariesWithExtraBags = itineraries
      .map(itinerary => ({
        ...itinerary,
        segments: itinerary.segments.map(segment => ({
          ...segment,
          extraBags: extractExtraBags(segment.id, [passenger]),
        }))
      }));

    return (
      <>
        <PassengerDetailsSectionTitle>Additional services</PassengerDetailsSectionTitle>
        <BaggageItems>
          {itinerariesWithExtraBags.map((itinerary) => {
            return itinerary.segments.map((segment) => {
              const departure = segment.departure;
              const arrival = segment.arrival;

              if (!segment.extraBags.length) {
                return null;
              }
          
              return (
                <BaggageItem key={segment.id}>
                  <TravelSuitcase fontSize="1.5rem" />
                  <BaggageDetails>
                    {segment.extraBags.map((extraBag, index) => (
                      <BaggageDetailsTitle key={index}>
                        {`${extraBag.quantity} extra bag(s) up to ${extraBag.weight} ${extraBag.weightUnit}`}
                      </BaggageDetailsTitle>
                    ))}
                    <BaggageDetailsSubTitle>
                      {`${departure.location.city} (${departure.code}) → ${arrival.location.city} (${arrival.code})`}
                    </BaggageDetailsSubTitle>
                  </BaggageDetails>
                </BaggageItem>
              );
            })
          })}
        </BaggageItems>
      </>
    );
  };

  const renderPassengerDetails = () => (
    <PassengerDetails>
      <PassengerDetailsSectionTitle>Passenger details</PassengerDetailsSectionTitle>
      {renderItem('Firsts name', passenger.firstName)}
      {renderItem('Last name', passenger.lastName)}
      {renderItem('Email', passenger.email)}
      {!!passenger.gender && renderItem('Gender', formatGender(passenger.gender))}
      {!!passenger.birthday && renderItem('Birth date', formatDateForDocument(passenger.birthday))}
      {!!passenger.phone && renderItem('Phone', passenger.phone)}
      {passenger.document?.number && renderDocument()}
      {passenger.additionalServices?.length > 0 && renderAdditionalServices()}
      <CloseButtonWrapper>
        <Button variant='secondary' fullWidth onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </CloseButtonWrapper>
    </PassengerDetails>
  );

  return (
    <Popover
      closeOnClickOutside
      className="popover-dialog"
      placement="top"
      content={renderPassengerDetails()}
      isOpen={isOpen}
      onVisibilityChange={isVisible => setIsOpen(isVisible)}
    >
      <PassengerTitle isActive={isOpen} onClick={() => setIsOpen(true)}>
        {passenger.firstName} {passenger.lastName}
      </PassengerTitle>
    </Popover>
  )
};

export default FlightPassengerDetails;
