import { FieldArray } from 'formik';
import get from 'lodash/get';
import { GenericDelete, ControlsPlus } from '@heathmont/moon-icons';
import { TextInput, Button } from '@heathmont/moon-core';
import { ErrorMessage, ErrorMessageWrapper } from '../SignUpCompanyTabs/AccountDetails/styles';
import FormField from '../FormField';
import InfoBlock from '../InfoBlock';
import { checkOnError, MAX_ITEMS_LENGTH } from './suggestion';
import { IDynamicFormFields } from './types';
import { ItemWrapper, ItemFormWrapper, FormFieldWrapper, DeleteButton } from './styles';

const DynamicFormFields: React.FC<IDynamicFormFields> = ({
  isNotDeletable,
  withoutLimit,
  maxItemsLength = MAX_ITEMS_LENGTH,
  fieldName,
  infoText,
  touched,
  errors,
  label,
  data,
  getDefaultValue,
  setFieldValue,
  renderItem,
}) => {
  return (
    // @ts-ignore
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => {
        return (
          <div>
            {infoText && <InfoBlock text={infoText} />}

            {data.map((itemValue, index) => {
              const errorMessages = checkOnError(errors, index, fieldName);

              return (
                <FormFieldWrapper key={itemValue?.id}>
                  <ItemWrapper>
                    <ItemFormWrapper>
                      {
                        renderItem ? (
                          renderItem(fieldName, itemValue, index)
                        ) : (
                          <FormField
                            fieldName={`${fieldName}.${index}`}
                            inputSize="xlarge"
                            component={TextInput}
                            isError={get(touched, [fieldName, index]) && get(errors, [fieldName, index])}
                            fieldId={`${fieldName}.${index}`}
                            value={itemValue}
                            type="text"
                            label={label}
                            onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}`, value)}
                          />
                        )
                      }
                    </ItemFormWrapper>
                    <DeleteButton>
                      <Button
                        iconOnly={<GenericDelete fontSize="1.5rem" />}
                        variant="ghost"
                        type="button"
                        disabled={isNotDeletable}
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </DeleteButton>
                  </ItemWrapper>
                  

                  {get(touched, [fieldName, index]) && errorMessages.length ? (
                    <ErrorMessageWrapper>
                      {
                        errorMessages.map(msg => (
                          <ErrorMessage>{msg}</ErrorMessage>
                        ))
                      }
                    </ErrorMessageWrapper>
                  ) : null}
                </FormFieldWrapper>
              );
            })}

            {(withoutLimit || data.length < maxItemsLength) && (
              <Button
                iconLeft={<ControlsPlus />}
                variant="ghost"
                type="button"
                onClick={() => arrayHelpers.push(getDefaultValue ? getDefaultValue() : '')}
              >Add more</Button>
            )}
          </div>
        );
      }}
    />
  );
};

export default DynamicFormFields;
