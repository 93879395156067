import {
  SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL,
  SET_RECOMMENDED_HOTELS,
  SHOW_DELETE_RECOMMENDED_HOTELS_MODAL,
  CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL,
  SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA,
  SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA,
  SHOW_RECOMMENDED_HOTEL_OVERVIEW,
  CLOSE_RECOMMENDED_HOTEL_OVERVIEW,
  SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA,
} from './actionTypes';

const initState = {
  recommendedHotels: [],
  totalRecommendedHotels: 0,
  isOpenAddNewHotelRecommendation: false,
  isDeleteRecommendedHotelsModal: false,
  deletableRecommendedHotels: [],
  recommendedHotelsCheckboxesData: [],
  isActiveAllRecommendedHotelsCheckboxes: false,
  isOpenRecommendedHotelOverview: false,
  overviewRecommendedHotelData: null,
  isUpdatedRecommendedHotelsData: false,
};

const recommendedHotelsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_RECOMMENDED_HOTELS:
      return {
        ...state,
        recommendedHotels: action.payload.records,
        totalRecommendedHotels: action.payload.total,
      };
      
    case SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL:
      return {
        ...state,
        isOpenAddNewHotelRecommendation: action.payload,
      };

    case SHOW_DELETE_RECOMMENDED_HOTELS_MODAL:
      return {
        ...state,
        isDeleteRecommendedHotelsModal: true,
        deletableRecommendedHotels: action.payload.deletableRecommendedHotels,
      };

    case CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL:
      return {
        ...state,
        isDeleteRecommendedHotelsModal: false,
        deletableRecommendedHotels: [],
      };

    case SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllRecommendedHotelsCheckboxes: false,
          recommendedHotelsCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          recommendedHotelsCheckboxesData: [...state.recommendedHotelsCheckboxesData, data],
        };
      }

      return {
        ...state,
        recommendedHotelsCheckboxesData: 
          state.recommendedHotelsCheckboxesData.filter((item) => item.recommendedHotelId !== data.recommendedHotelId),
        isActiveAllRecommendedHotelsCheckboxes: false,
      };
    }

    case SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllRecommendedHotelsCheckboxes: isActive,
        recommendedHotelsCheckboxesData: data,
      };
    }

    case SHOW_RECOMMENDED_HOTEL_OVERVIEW:
      return {
        ...state,
        isOpenRecommendedHotelOverview: true,
        overviewRecommendedHotelData: action.payload.data,
      };

    case CLOSE_RECOMMENDED_HOTEL_OVERVIEW:
      return {
        ...state,
        isOpenRecommendedHotelOverview: false,
        overviewRecommendedHotelData: null,
      };

    case SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA:
      return {
        ...state,
        isUpdatedRecommendedHotelsData: action.payload.data,
      };

    default:
      return state;
  }
};

export default recommendedHotelsReducer;
