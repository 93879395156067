import { useState, useMemo, useEffect } from 'react';
import { Button } from '@heathmont/moon-core';
import { TextInput } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { getBoolStatusLabel, getRequiredValue } from '../../utils/tableHelpers';
import { schema } from './schema';
import FormField from '../FormField';
import CustomSelect from '../CustomSelect';
import { employmentTypeNames, EmploymentTypes } from '../../enums/EmploymentTypes';
import { expensesRequireStatuses } from '../../pages/Tools/EmploymentTypes/suggestions';
import { IEmploymentTypeOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper, ButtonsWrapper } from './styles';

const getInitialValues = (data) => ({
  label: data?.label ?? '',
  expensesRequired: data?.expensesRequired ?? '',
});

const statuses = [
  { title: employmentTypeNames[EmploymentTypes.DISABLED], value: EmploymentTypes.DISABLED },
  { title: employmentTypeNames[EmploymentTypes.ACTIVE], value: EmploymentTypes.ACTIVE },
];

const EmploymentTypeOverviewDrawerContent: React.FC<IEmploymentTypeOverviewDrawerContent> = ({
  data,
  handleUpdate,
  updateStatus,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Name</OverviewItemName>
            <OverviewItem>{data?.label ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Expense report</OverviewItemName>

            <OverviewItem>{getRequiredValue(data?.expensesRequired)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>
            <OverviewItem>
              <CustomSelect
                currentValue={data?.status}
                items={statuses}
                getItemLabel={getBoolStatusLabel}
                onChange={(value) => updateStatus(data?.id, value)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <Formik
          isInitialValid
          validateOnBlur={true}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({ errors, values, resetForm, setFieldValue, touched, handleBlur, dirty }: any): JSX.Element => {
            return (
              <Form>
                <OverviewItemsWrapper>
                  <OverviewItemName>Name</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.label}
                      fieldId="label"
                      fieldName="label"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('label', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.label && errors?.label}
                      errors={touched?.label && errors}
                      errorPosition={-20}
                      label="Name"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Expense report</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      currentValue={values.expensesRequired}
                      value={values.expensesRequired}
                      fieldId="expensesRequired"
                      fieldName="expensesRequired"
                      component={CustomSelect}
                      items={expensesRequireStatuses}
                      getItemLabel={getRequiredValue}
                      onChange={(value) => setFieldValue('expensesRequired', value)}
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Status</OverviewItemName>

                  <OverviewItem>{getBoolStatusLabel(data?.status)}</OverviewItem>
                </OverviewItemsWrapper>

                <ButtonsWrapper>
                  <Button
                    onClick={() => {
                      resetForm();
                      setIsEditFormData(false);
                    }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    disabled={!isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleUpdate(data.id, values);
                      setIsEditFormData(false);
                      resetForm();
                    }}
                  >
                    Save
                  </Button>
                </ButtonsWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EmploymentTypeOverviewDrawerContent;
