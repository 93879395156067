import { Checkbox } from '@heathmont/moon-core';
import { Spinner } from '../Spinner';
import RowActions from '../RowActions';
import { CheckboxWrapper } from './styles';
import { ISubRow } from './types';

const SubRows: React.FC<ISubRow> = ({
  visibleColumns,
  hoveredRow,
  rowProps,
  loading,
  groupBy,
  data,
  row,
  handleSetCheckbox,
  handleHoveredRow = () => {},
  isRowDisabled,
  isRowChecked,
  handleDelete,
  handleEdit,
}) => {
  if (loading) {
    return (
      <tr>
        <td />
        <td colSpan={visibleColumns.length - 1}>
          <Spinner />
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.map((subRow, i) => {
        const key = `${rowProps.key}-expanded-${i}`;
  
        return (
          <tr
            {...rowProps}
            key={key}
            onMouseEnter={() => handleHoveredRow({ ...subRow, key })}
            onMouseLeave={() => handleHoveredRow(null)}
          >
            {row.cells.map((cell) => {
              const lastCell = row?.cells[row?.cells.length - 1];

              if (cell.column.id === 'expander') {
                return (
                  <td key={cell.column.id}>{
                    handleSetCheckbox ? (
                      <CheckboxWrapper>
                        <Checkbox
                          disabled={isRowDisabled(subRow?.original)}
                          checked={isRowChecked(subRow?.original)}
                          onChange={() => handleSetCheckbox(subRow?.original)}
                        />
                      </CheckboxWrapper>
                    ) : (
                      i + 1
                    )
                  }</td>
                );
              }

              if (hoveredRow?.key === key && cell.column.id === lastCell?.column?.id) {
                return (
                  <td key={cell.column.id}>
                    <RowActions
                      handleIsOverviewOpen={handleEdit}
                      handleRemoveDialog={handleDelete}
                      editLabel="Edit"
                      row={subRow}
                    />
                  </td>
                );
              }

              return (
                <td
                  key={cell.column.id}
                  {...cell.getCellProps()}
                  className={cell.column.id === groupBy ? 'groupedBy' : null}
                >
                  {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                    value: cell.column.accessor && cell.column.accessor(subRow, i),
                    row: { ...row, original: subRow },
                  })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default SubRows;
