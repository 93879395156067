import { Text } from '@heathmont/moon-components';
import { ColorPreview, Item, TooltipContent } from './styles';
import { ILineChartTooltipContent } from './types';

const LineChartTooltipContent: React.FC<ILineChartTooltipContent> = ({ payload }) => {
  return payload.map((item) => {
    const key = item.payload.dataKey || item.dataKey;
    const color = item.payload.fill || item.color;

    const totalCostValue = item.payload?.tooltip[key]?.totalCost ?? '';
    const totalReservationValue = item?.payload?.tooltip[key]?.totalReservation ?? '';

    return (
      <Item key={key}>
        <ColorPreview style={{ background: color }} />

        <TooltipContent>
          <div>
            <Text size={12} as="span">
              Total cost
            </Text>{' '}
            • {totalCostValue} EUR
          </div>

          <div>
            <Text size={12} as="span">
              Reservation numbers
            </Text>{' '}
            • {totalReservationValue}
          </div>
        </TooltipContent>
      </Item>
    );
  });
};

export default LineChartTooltipContent;
