import { TravelAirplane, GenericEdit, GenericDelete } from '@heathmont/moon-icons-tw';
import { Button, Text } from '@heathmont/moon-core';
import moment from 'moment';
import {
  FlightInfoBlock,
  FlightSegments,
  FlightContent,
  LeftContent,
  Icons,
} from '../styles';
import { getAirportByCode } from '../../../utils/trips';
import { getFlightLabel } from '../../TripsTicketDrawerContent/TripFlightBooking/utils';
import { Fragment } from 'react/jsx-runtime';

interface IFlightOverview {
  currencies: any[];
  airports: any[];
  airlines: any[];
  flight: any;
  handleDelete: () => void; 
  handleEdit: () => void;
};

const FlightOverview = ({
  currencies,
  airports,
  airlines,
  flight,
  handleDelete,
  handleEdit,
}: IFlightOverview) => {
  const formatFlightDateAndTime = (date) => {
    return moment(date).format('DD MMM YYYY, HH:mm');
  };

  const getCurrencyCodeFromId = (currencyId) => {
    return currencies.find((currency) => currency.value === currencyId)?.title || '';
  };

  const getAirlineCodeFromAirlineId = (airlineId) => {
    return airlines.find((airline) => airline?.value === airlineId)?.code || '';
  };

  return (
    <FlightInfoBlock>
      <LeftContent>
        <TravelAirplane fontSize="1.6rem" />
        <FlightSegments>
          {flight.itineraries.map((itinerary, i) => itinerary.segments.length > 0 && (
            <Fragment key={i}>
              <Text size={12}>
                <strong>{getFlightLabel(i, flight.type)}</strong>
              </Text>
              {itinerary.segments.map((segment) => (
                <FlightContent>
                  <p>
                    <strong>{getAirlineCodeFromAirlineId(segment.airline)}-{segment.flightNumber.toUpperCase()}</strong>  {getAirportByCode(airports, segment.departureCode)} → {getAirportByCode(airports, segment.arrivalCode)}
                  </p>
                  <p className="flight-dates">
                    {formatFlightDateAndTime(segment.departureAt)} → {formatFlightDateAndTime(segment.arrivalAt)}
                  </p>
                </FlightContent>
              ))}
            </Fragment>
          ))}                      
          <Text size={12}>
            <strong>Price: </strong>{flight.priceWithoutAdditionalPercent} {getCurrencyCodeFromId(flight.currency)}
          </Text>
        </FlightSegments>
      </LeftContent>
      <Icons>
        <Button
          iconOnly={<GenericEdit fontSize="1.2rem"/>}
          variant="ghost"
          size="small"
          onClick={handleEdit}
        />
        <Button
          iconOnly={<GenericDelete fontSize="1.2rem"/>}
          variant="ghost"
          size="small"
          onClick={handleDelete}
        />
      </Icons>
    </FlightInfoBlock>
  );
}

export default FlightOverview;
