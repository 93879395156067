import { RadioButton } from '@heathmont/moon-core';
import { formatCustomLabel } from '../../utils/tableHelpers';
import { RangeCalendar } from '@heathmont/moon-datepicker';
import { RadioGroup } from '../RadioGroup';
import { IRadioDateGroup } from './types';
import { RangeCalendarWrapper } from './styles';
import { RadioLabel } from '../RadioGroup/styles';

const CUSTOM_DATE = 'custom';

const RadioDateGroup: React.FC<IRadioDateGroup> = ({
  isOpenDatePicker,
  withAllOption = true,
  tripTime,
  range,
  data,
  name,
  setIsOpenDatePicker,
  handleDateRange,
  handleTripDate,
}) => {
  return (
    <>
      <>
        <RadioGroup
          withAllOption={withAllOption}
          selectedValue={tripTime}
          data={data}
          name={name}
          onSelect={(value) => {
            handleDateRange({ startDate: null, endDate: null });
            handleTripDate(value);
          }}
        />
        <RadioButton
          name={name}
          value={name + CUSTOM_DATE}
          checked={tripTime === CUSTOM_DATE}
          onChange={() => {
            handleTripDate(CUSTOM_DATE);
            setIsOpenDatePicker(true);
          }}
          label={<RadioLabel>{formatCustomLabel(tripTime, range.startDate, range.endDate)}</RadioLabel>}
        />
      </>

      {isOpenDatePicker && (
        <RangeCalendarWrapper>
          <RangeCalendar
            startDate={range.startDate}
            endDate={range.endDate}
            setIsOpen={setIsOpenDatePicker}
            isOpenByDefault={isOpenDatePicker}
            onDateChange={({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
              handleDateRange({ startDate, endDate });
            }}
            config={{
              weekStartsOn: 1,
              withHoursAndMinutes: true,
              withFooter: true,
            }}
          />
        </RangeCalendarWrapper>
      )}
    </>
  );
};

export default RadioDateGroup;
