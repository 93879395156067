import { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { IBusinessVerticalOverviewDrawerEditContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, ButtonsWrapper } from './styles';
import { businessVerticalSchema } from './schema';
import { updateBusinessVertical } from '../../store/tools/businessVerticals/actions';
import CustomSelect from '../CustomSelect';
import { сheckOnSpaces } from '../../utils/commonFunctions'
import { fetchOptionsForCompany } from '../../store/tools/businessVerticals/api';
import { fetchCompanyAccountants } from '../../store/tools/employers/api';
import { BUSINESS_VERTICAl_TYPES } from '../../constants';
import isEmpty from 'lodash/isEmpty';
import { BusinessVerticalType } from '../../enums/BusinessVerticalType';

const getInitialValues = (data) => ({
  travelopses: data?.travelopses.map((item) => item.id) ?? [],
  accountants: data?.accountants.map((item) => item.id) ?? [],
  ceos: data?.ceos.map((item) => item.id) ?? [],
  type: data?.type ?? null,
  name: data?.name ?? '',
  company: data?.company?.id ?? null,
  isDefault: !!data?.isDefault ?? null,
});

const BusinessVerticalOverviewDrawerEditContent: React.FC<IBusinessVerticalOverviewDrawerEditContent> = ({
  data,
  setIsEditFormData,
  setBecameDefaultBusinessVertical,
}): JSX.Element => {
  const dispatch = useDispatch();

  const initialValues = useMemo(() => getInitialValues(data), [data]);
  const [companyOptions, setCompanyOptions] = useState({
    defaultTravelopses: [],
    defaultAccountants: [],
    defaultCeos: [],
    accountants: [],
    travelopses: [],
    ceos: [],
  });

  const fetchCompanyOptions = async () => {
    const options = await fetchOptionsForCompany(data?.company?.id)
    const accountants = await fetchCompanyAccountants(data?.company?.id);
    
    const { defaultTravelopses, defaultAccountants, defaultCeos, travelopses, managers } = options || {};
    setCompanyOptions({ defaultTravelopses, defaultAccountants, defaultCeos, travelopses, ceos: managers, accountants })
  };

  useEffect(() => {
    fetchCompanyOptions();
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={businessVerticalSchema}
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ errors, touched, values, resetForm, setFieldTouched, setFieldValue, dirty }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemsWrapper>
                <OverviewItemName>Business Vertical</OverviewItemName>
                <OverviewItem>
                  <FormField
                    value={values.name}
                    fieldId="name"
                    fieldName="name"
                    component={TextInput}
                    type="text"
                    inputSize="xlarge"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    errors={errors}
                    onBlur={() => setFieldTouched('name')}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>

             <OverviewItemsWrapper>
              <OverviewItemName>Company</OverviewItemName>
              <OverviewItem>{data.company?.name}</OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>CEO(s)</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  currentValue={values.ceos}
                  isSearchable
                  placeholder="CEO(s)"
                  isMulti
                  isClearable
                  isDisabled={values?.type === BUSINESS_VERTICAl_TYPES.EXTERNAL}
                  items={companyOptions.ceos}
                  onChange={(value) => {
                    setFieldTouched('ceos', true, false);
                    setFieldValue('ceos', value);
                  }}
                  isError={touched?.ceos && errors?.ceos}
                  error={(touched?.ceos && errors?.ceos) as string}
                  onBlur={() => setFieldTouched('ceos', true, false)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Travelopses</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  currentValue={values.travelopses}
                  isSearchable
                  placeholder="Travelopses"
                  isMulti
                  isClearable
                  items={companyOptions.travelopses}
                  onChange={(value) => {
                    setFieldTouched('travelopses', true, false);
                    setFieldValue('travelopses', value);
                  }}
                  isError={touched?.travelopses && errors?.travelopses}
                  error={(touched?.travelopses && errors?.travelopses) as string}
                  onBlur={() => setFieldTouched('travelopses', true, false)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Accountants</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  currentValue={values.accountants}
                  isSearchable
                  placeholder="Accountants"
                  isMulti
                  isClearable
                  items={companyOptions.accountants}
                  onChange={(value) => {
                    setFieldTouched('accountants', true, false);
                    setFieldValue('accountants', value);
                  }}
                  isError={touched?.accountants && errors?.accountants}
                  error={(touched?.accountants && errors?.accountants) as string}
                  onBlur={() => setFieldTouched('accountants', true, false)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
            <OverviewItemName>Only external users</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={data?.type === BusinessVerticalType.External} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>
          
          <OverviewItemsWrapper>
            <OverviewItemName>Default business vertical</OverviewItemName>
            <OverviewItem>
              <Checkbox 
                checked={values?.isDefault}
                disabled={data?.isDefault}
                onChange={() => {
                  setFieldValue('isDefault', !values.isDefault);
                  setBecameDefaultBusinessVertical(!values.isDefault);
                }}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

            <ButtonsWrapper>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Cancel</Button>

              <Button
                variant="primary"
                disabled={!dirty || !isEmpty(errors) || сheckOnSpaces(values.name)}
                onClick={() => {
                  dispatch(updateBusinessVertical(data.id, values));
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Save</Button>
            </ButtonsWrapper>
          </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessVerticalOverviewDrawerEditContent;
