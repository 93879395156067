import { GenericDownload, ControlsClose } from '@heathmont/moon-icons';
import Tooltip from '../Tooltip';
import { IDrawer } from './types';
import { Wrapper, Icon, Header, HeaderContent, Status, Content, Title, IconsWrapper, TitleWrapper } from './styles';

const Drawer: React.FC<IDrawer> = ({
  withStatus = false,
  isTicket,
  status,
  tripId,
  title,
  handleExportPdf,
  getStatusLabel,
  handleClose,
  children,
}) => {
  return (
    <Wrapper>
      <Header>
        <HeaderContent>
          <TitleWrapper>
            <Title>{title}</Title>
            {withStatus && <Status>{getStatusLabel(status)}</Status>}
          </TitleWrapper>

          <IconsWrapper isTicket={isTicket}>
            {isTicket && (
              <Tooltip label="Export PDF" position="bottom">
                <Icon onClick={() => handleExportPdf(tripId)}>
                  <GenericDownload />
                </Icon>
              </Tooltip>
            )}

            <Icon>
              <ControlsClose onClick={handleClose} />
            </Icon>
          </IconsWrapper>
        </HeaderContent>
      </Header>

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Drawer;
