import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 624px;
  max-height: 660px;
  height: 100%;
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100vw;
    height: calc(100vh - 100px);
    top: -40px;
  `}

  & form {
    height: 100%;
  }
`;

export const TabWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: ${({ isUpdated }) => (isUpdated ? '280px' : '380px')};
  padding: 0 40px;

  @media (max-width: 375px) {
    height: 210px;
  }

  @media (max-width: 414px) {
    height: 430px;
  }
`;

export const NotificationWrapper = styled.div`
  padding: 0 40px;
`;

export const ConfirmMessage = styled(Text).attrs({
  size: 16,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 16px;
  background: ${({ isUpdateError }) => (isUpdateError ? '#fddada' : '#dafde9')};
  border-radius: 8px;
  margin-bottom: 24px;

  & svg {
    cursor: pointer;
  }
`;

export const NotificationText = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  padding: 0 40px;
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;
  height: 65px;
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  background-color: #ffffff;
`;

export const Header = styled(Text).attrs({
  size: 32,
  color: 'bulma.100',
})`
  line-height: 40px;
  padding: 0 40px;
`;

export const Link = styled(RouterLink)`
  &:hover {
    * {
      text-shadow: 0 0 0.01px black;
    }
  }

  && {
    display: flex;
    align-items: center;

    & div {
      display: flex;
    }

    & button {
      color: #78787d;
    }
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff0000;
`;

export const TabFormContainerStyled = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden; 
`;

export const TabFormFieldsStyled = styled.div`
  padding: 0 40px;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
`;
