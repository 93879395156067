import {
  UPDATE_COMPANY_BALANCED_STATUS,
  UPDATE_COMPANY_STATUS,
} from '../tools/companies/actionTypes';
import {
  SET_CURRENT_USER_LOADING,
  SET_CURRENT_USER_LOADED,
  SET_CURRENT_USER,
} from './actionTypes';

const initState = {
  isCurrentUserLoading: false,
  isCurrentUserLoaded: false,
  currentUserData: null,
};

const currentUserReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_BALANCED_STATUS:
      if (action.payload.id !== state.currentUserData.company.id) {
        return state;
      }
  
      return {
        ...state,
        currentUserData: {
          ...state.currentUserData,
          company: {
            ...state.currentUserData.company,
            balancedStatus: action.payload.balancedStatus,
          }
        },
      };
    case UPDATE_COMPANY_STATUS:
      if (action.payload.id !== state.currentUserData.company.id) {
        return state;
      }
  
      return {
        ...state,
        currentUserData: {
          ...state.currentUserData,
          company: {
            ...state.currentUserData.company,
            status: action.payload.status,
          }
        },
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUserData: action.data.currentUserData,
      };
    case SET_CURRENT_USER_LOADED:
      return {
        ...state,
        isCurrentUserLoaded: action.data.isCurrentUserLoaded,
      };
    case SET_CURRENT_USER_LOADING:
      return {
        ...state,
        isCurrentUserLoading: action.data.isCurrentUserLoading,
      };
    default:
      return state;
  }
};

export default currentUserReducer;
