import isEmpty from 'lodash/isEmpty';

export const checkOnError = (errorsArr, index, fieldName) => {
  const fieldErrors = errorsArr[fieldName] && errorsArr[fieldName][index];

  if (fieldErrors && !isEmpty(fieldErrors)) {

    if (typeof fieldErrors === 'string') {
      return [fieldErrors];
    }

    return Object.keys(fieldErrors).map(field => fieldErrors[field]);
  }

  return [];
};


export const MAX_ITEMS_LENGTH = 5;