import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Button, TextInput } from '@heathmont/moon-core';
import {
  ButtonsWrapper,
  FormItemLabel,
  FormItem,
} from './styles';
import Yup from '../../utils/validation';
import FormField from '../FormField';
import { IEditTripDeclineReasonDialogContent } from './types';

const DECLINE_REASON_MIN_LENGTH = 2;
const DECLINE_REASON_MAX_LENGTH = 255;

const validateMessages = {
  declineReason: {
    required: 'Decline reason cannot be empty',
    min: `Decline reason cannot be less than ${DECLINE_REASON_MIN_LENGTH} characters`,
    max: `Decline reason cannot be longer than ${DECLINE_REASON_MAX_LENGTH} characters`,
  },
};

const getInitialValues = (trip) => {  
  return {
    declineReason: trip?.declineReason || '',
    tripId: trip?.id || undefined,
  };
};

const schema = Yup.object().shape({
  declineReason: Yup
    .string()
    .min(DECLINE_REASON_MIN_LENGTH, validateMessages.declineReason.min)
    .max(DECLINE_REASON_MAX_LENGTH, validateMessages.declineReason.max)
    .isInLatinCharacters()
    .required(validateMessages.declineReason.required),
  tripId: Yup
    .number()
    .required(),
});

const EditTripDeclineReasonDialogContent: React.FC<IEditTripDeclineReasonDialogContent> = ({
  loading,
  trip,
  onSubmit,
  onCancel,
}) => {
  const initialValues = useMemo(() => getInitialValues(trip), [trip?.id]);

  return (
    <>    
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <FormItem>
                <FormItemLabel>Decline reason</FormItemLabel>
                <FormField
                  placeholder={'Specify decline reason'}
                  fieldName='declineReason'
                  component={TextInput}
                  inputSize='large'
                  fieldId='declineReason'
                  isError={touched?.declineReason && errors?.declineReason}
                  errors={touched?.declineReason && errors}
                  value={values.declineReason}
                  type='text'
                  onChange={({ target: { value } }) => {
                    setFieldValue('declineReason', value);
                  }}
                  onBlur={() => setFieldTouched('declineReason', true, false)}
                />
              </FormItem>
              <ButtonsWrapper>
                <Button variant="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button disabled={!isEmpty(errors) || loading} variant="primary" type="submit">
                  Confirm
                </Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditTripDeclineReasonDialogContent;
