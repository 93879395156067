export const UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA = 'expenses/UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA';
export const UPDATE_BALANCED_INTEGRATION = 'expenses/UPDATE_BALANCED_INTEGRATION';
export const SET_EXPENSES_CHECKBOX_DATA = 'expenses/SET_EXPENSES_CHECKBOX_DATA';
export const SET_UPDATE_EXPENSES_STATUS = 'expenses/SET_UPDATE_EXPENSES_STATUS';
export const SET_EXPENSES_OPTIONS_DATA = 'expenses/SET_EXPENSES_OPTIONS_DATA';
export const SET_IS_UPLOADING_RECEIPTS = 'expenses/SET_IS_UPLOADING_RECEIPTS';
export const UPDATE_REIMBURSEMENT_INFO = 'expenses/UPDATE_REIMBURSEMENT_INFO';
export const SET_IS_OPEN_ADD_RECEIPTS = 'expenses/SET_IS_OPEN_ADD_RECEIPTS';
export const CLOSE_EXPENSES_OVERVIEW = 'expenses/CLOSE_EXPENSES_OVERVIEW';
export const SHOW_EXPENSES_OVERVIEW = 'expenses/SHOW_EXPENSES_OVERVIEW';
export const SET_EXPENSES_DATA = 'expenses/SET_EXPENSES_DATA';
export const TOGGLE_FILTERS = 'expenses/TOGGLE_FILTERS';
export const CLOSE_FILTERS = 'expenses/CLOSE_FILTERS';
export const UPDATE_STATUS = 'expenses/UPDATE_STATUS';
