import React from 'react';
import { TextInputGroup } from '@heathmont/moon-select';
import FormField from '../../FormField';
import { IAccountDetailsTab } from './types';
import { DoubleFieldWrapper } from '../styles';
import { ErrorMessageWrapper, ErrorMessage } from './styles';

const AccountDetailsTab: React.FC<IAccountDetailsTab> = ({
  touched,
  values,
  errors,
  setFieldValue,
  handleBlur,
}) => {
  const { companyName, accountantsChannel, travelGuideline, contactEmail } = values.details;

  const isErrorCompanyName = !!errors?.details?.companyName && touched?.details?.companyName;
  const isErrorContactEmail = !!errors?.details?.contactEmail && touched?.details?.contactEmail;
  const isErrorAccountantsChannel = !!errors?.details?.accountantsChannel && touched?.details?.accountantsChannel;
  const isErrorTravelGuideline = !!errors?.details?.travelGuideline && touched?.details?.travelGuideline;

  const companyNameField = {
    inputSize: 'xlarge',
    isError: isErrorCompanyName,
    value: companyName,
    label: 'Company name',
    type: 'text',
    id: 'details.companyName',
    onChange: ({ target: { value } }) => setFieldValue('details.companyName', value),
    onBlur: handleBlur,
  };

  const contactEmailField = {
    inputSize: 'xlarge',
    isError: isErrorContactEmail,
    value: contactEmail,
    label: 'Contact email',
    type: 'text',
    id: 'details.contactEmail',
    onChange: ({ target: { value } }) => setFieldValue('details.contactEmail', value),
    onBlur: handleBlur,
  };

  const accountantsChannelField = {
    inputSize: 'xlarge',
    isError: isErrorAccountantsChannel,
    value: accountantsChannel,
    label: 'Accountants channel',
    type: 'text',
    id: 'details.accountantsChannel',
    onChange: ({ target: { value } }) => setFieldValue('details.accountantsChannel', value),
    onBlur: handleBlur,
  };

  const travelGuidelineField = {
    inputSize: 'xlarge',
    isError: isErrorTravelGuideline,
    value: travelGuideline,
    label: 'Travel guideline',
    type: 'text',
    id: 'details.travelGuideline',
    onChange: ({ target: { value } }) => setFieldValue('details.travelGuideline', value),
    onBlur: handleBlur,
  };

  return (
    <>
      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: companyNameField,
            input2: contactEmailField,
          }}
        />

        {(isErrorCompanyName || isErrorContactEmail) && (
          <ErrorMessageWrapper>
            <ErrorMessage>{isErrorCompanyName && errors?.details?.companyName}</ErrorMessage>
            <ErrorMessage>{isErrorContactEmail && errors?.details?.contactEmail}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>

      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: accountantsChannelField,
            input2: travelGuidelineField,
          }}
        />

        {(isErrorAccountantsChannel || isErrorTravelGuideline) && (
          <ErrorMessageWrapper>
            <ErrorMessage>{isErrorAccountantsChannel && errors?.details?.accountantsChannel}</ErrorMessage>
            <ErrorMessage>{isErrorTravelGuideline && errors?.details?.travelGuideline}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </>
  );
};

export default AccountDetailsTab;
