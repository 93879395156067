import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const AccommodationOverviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f6f5f4;
  border-radius: 16px;
  width: 100%;
  min-height: 64px;
  height: 100%;
  margin-bottom: 10px;
  padding: 12px;
`;

export const AccommodationInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const AccommodationReferenceAndPrice = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
`;

export const AccommodationAddress = styled.a`
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
`;

export const AccommodationDates = styled(MoonText).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 14px;
  font-weight: 400;
`;

export const ModalCheckboxWrapper = styled.div`
  margin-top: 15px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;
