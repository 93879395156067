import { CompanyFormTabType } from '../../enums/FormTabType';
import {
  SET_SUCCESS_SIGN_UP_COMPANY,
  SET_SIGN_UP_COMPANY_ERROR,
  SET_ACTIVE_TAB,
  SET_IS_ACTIVE_JIRA_ACCORDION,
  SET_IS_ACTIVE_HIBOB_ACCORDION,
  SET_FORM_DATA,
  SET_SIGN_UP_COMPANY_URL_ERROR,
} from './actionTypes';

const initState = {
  successSignUpCompany: false,
  submitError: {
    isError: false,
    header: '',
    message: 'Something went wrong. Please, check entered data',
  },
  activeTab: CompanyFormTabType.AccountDetails,
  isEditJiraMode: false,
  isEditHibobMode: false,
  formData: null,
  linkError: false,
};

const signUpCompanyReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SUCCESS_SIGN_UP_COMPANY:
      return {
        ...state,
        successSignUpCompany: action.payload.isSuccess,
      };

    case SET_IS_ACTIVE_JIRA_ACCORDION:
      return {
        ...state,
        isEditJiraMode: action.payload.isActive,
      };

    case SET_SIGN_UP_COMPANY_URL_ERROR:
      return {
        ...state,
        linkError: action.payload.isValid,
      };

    case SET_IS_ACTIVE_HIBOB_ACCORDION:
      return {
        ...state,
        isEditHibobMode: action.payload.isActive,
      };

    case SET_SIGN_UP_COMPANY_ERROR:
      return {
        ...state,
        submitError: {
          isError: action.payload.isSubmitError,
          header: action.payload.header,
          message: action.payload.message,
        },
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };

    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload.formData,
      };

    default:
      return state;
  }
};

export default signUpCompanyReducer;
