import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const OverviewWrapper = styled.div``;

export const IconBlock = styled.div`
  background: #f6f5f4;
  border-radius: 16px 0 0 16px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f6f5f4;
  border-radius: 0 16px 16px 0;
  margin-left: 4px;
  width: 100%;
  min-height: 64px;
  height: 100%;
`;

export const DeleteIcon = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: calc(50% - 20px);
  color: #78787D;
  cursor: pointer;
`;

export const UpcomingItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  &:hover ${IconBlock}, &:hover ${InfoBlockWrapper} {
    background: #eaeaea !important;
  }

  &:hover ${DeleteIcon} {
    display: block;
  }
`;

export const UserDetailsWrapper = styled.div`
  width: 40%;
  margin-right: 10px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  padding: 0 0 0 12px;
  align-items: center;
`;

export const Title = styled(MoonText).attrs({
  size: 12,
  color: '#181B1C',
})`
  line-height: 16px;
  margin: 0;
  font-weight: 400;
`;

export const RoleWrapper = styled.span`
  font-weight: 600;
`;

export const ReasonsWrapper = styled.div`
  margin: 0;
  padding: 0 0 0 12px;
`;

export const Reasons = styled.ol`
  list-style-type: circle;
  margin: 0 0 0 30px;

  & li {
    font-size: 12px;
    width: fit-content;
    max-width: 250px;
  }

  & p {
    font-weight: bold;
    display: inline;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const InfoBlockMultiItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 5px;
  width: 100%;
  padding-right: 35px;
`;

export const Reason = styled(MoonText).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 16px;
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ maxWidth }) =>
  maxWidth &&
    `
      max-width: ${maxWidth};
   `}
`;

export const IconWrapper = styled.span`
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBlockText = styled.span`
  width: calc(100% - 36px);
  font-size: 12px;
  line-height: 20px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 64px;
  height: 100%;
`;

export const RoleLabelWrapper = styled.span`
  display: inline-block;
`;
