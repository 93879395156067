import { useMemo, useCallback } from 'react';
import SubRowAsync from './SubRowAsync';
import Table from './Table';
import SubRows from './SubRows';
import { ITableContentReciever } from './types';
import { TableContainer } from './styles';

const TableContentReciever: React.FC<ITableContentReciever> = ({
  isActiveAllAccommodationsForIgnoreCheckboxes,
  isActiveAllBusinessVerticalsCheckboxes,
  isActiveAllRecommendedHotelsCheckboxes,
  isActiveAllEmploymentTypesCheckboxes,
  isActiveAllCustomHotelsCheckboxes,
  isActiveAllAllowancesCheckboxes,
  isActiveAllEmployersCheckboxes,
  isActiveAllPurposesCheckboxes,
  isToolsRecommendedHotelsPage,
  isToolsBusinessVerticalsPage,
  isIgnoredAccommodationsPage,
  isToolsEmploymentTypesPage,
  isActiveAllTeamsCheckboxes,
  isActiveAllRolesCheckboxes,
  isToolsCustomHotelsPage,
  isToolsAllowancesPage,
  tableTripStatusModal,
  isToolsEmployersPage,
  isShowTableFilters,
  isToolsPurposePage,
  isToolsActionsPage,
  isToolsTeamsPage,
  isToolsRolesPage,
  withCheckboxRow,
  initialColumns,
  checkboxesData,
  limitPerPage,
  queryParams,
  hoveredRow,
  isExpenses = false,
  withHover,
  rowHeight,
  statuses,
  groupBy,
  sortBy,
  data,
  page,
  handleSetCheckboxSubRow,
  handleSetAllCheckboxs,
  handleIsOverviewOpen,
  isRowDisabledSubRow,
  handleDeleteSubRow,
  isRowCheckedSubRow,
  handleIsTicketOpen,
  handleRemoveDialog,
  handleSetCheckbox,
  handleHoveredRow,
  handleEditSubRow,
  getStatusLabel,
  handleAddNew,
  handleSortBy,
  checkIsRowDisabled,
}) => {
  const columns = useMemo(() => initialColumns, [groupBy]);

  const renderRowSubComponent = useCallback(({ row, rowProps, visibleColumns, data }) => {
    if (isExpenses) {
      return (
        <SubRows
          visibleColumns={visibleColumns}
          hoveredRow={hoveredRow}
          rowProps={rowProps}
          loading={false}
          groupBy={groupBy}
          data={data[row.index].expenses}
          row={row}
          handleSetCheckbox={handleSetCheckboxSubRow}
          handleHoveredRow={handleHoveredRow}
          isRowDisabled={isRowDisabledSubRow}
          isRowChecked={isRowCheckedSubRow}
          handleDelete={handleDeleteSubRow}
          handleEdit={handleEditSubRow}
        />
      );
    }

    return (
      <SubRowAsync
        visibleColumns={visibleColumns}
        queryParams={queryParams}
        rowProps={rowProps}
        groupBy={groupBy}
        data={data}
        row={row}
      />
    );
  }, [groupBy, queryParams, isExpenses, hoveredRow, isRowDisabledSubRow, isRowCheckedSubRow]);

  return (
    <TableContainer rowHeight={rowHeight} groupBy={groupBy}>
      <Table
        isActiveAllBusinessVerticalsCheckboxes={isActiveAllBusinessVerticalsCheckboxes}
        isActiveAllEmployersCheckboxes={isActiveAllEmployersCheckboxes}
        isActiveAllRolesCheckboxes={isActiveAllRolesCheckboxes}
        isActiveAllTeamsCheckboxes={isActiveAllTeamsCheckboxes}
        isActiveAllPurposesCheckboxes={isActiveAllPurposesCheckboxes}
        isActiveAllAccommodationsForIgnoreCheckboxes={isActiveAllAccommodationsForIgnoreCheckboxes}
        isActiveAllEmploymentTypesCheckboxes={isActiveAllEmploymentTypesCheckboxes}
        isActiveAllAllowancesCheckboxes={isActiveAllAllowancesCheckboxes}
        isToolsRecommendedHotelsPage={isToolsRecommendedHotelsPage}
        isActiveAllRecommendedHotelsCheckboxes={isActiveAllRecommendedHotelsCheckboxes}
        isActiveAllCustomHotelsCheckboxes={isActiveAllCustomHotelsCheckboxes}
        isToolsBusinessVerticalsPage={isToolsBusinessVerticalsPage}
        isIgnoredAccommodationsPage={isIgnoredAccommodationsPage}
        isToolsCustomHotelsPage={isToolsCustomHotelsPage}
        tableTripStatusModal={tableTripStatusModal}
        isToolsEmployersPage={isToolsEmployersPage}
        isShowTableFilters={isShowTableFilters}
        isToolsTeamsPage={isToolsTeamsPage}
        isToolsRolesPage={isToolsRolesPage}
        isToolsAllowancesPage={isToolsAllowancesPage}
        isToolsPurposePage={isToolsPurposePage}
        isToolsEmploymentTypesPage={isToolsEmploymentTypesPage}
        isToolsActionsPage={isToolsActionsPage}
        withCheckboxRow={withCheckboxRow}
        initialColumns={initialColumns}
        checkboxesData={checkboxesData}
        limitPerPage={limitPerPage}
        hoveredRow={hoveredRow}
        withHover={withHover}
        statuses={statuses}
        columns={columns}
        groupBy={groupBy}
        sortBy={sortBy}
        data={data}
        page={page}
        renderRowSubComponent={renderRowSubComponent}
        handleSetAllCheckboxs={handleSetAllCheckboxs}
        handleIsOverviewOpen={handleIsOverviewOpen}
        handleIsTicketOpen={handleIsTicketOpen}
        handleRemoveDialog={handleRemoveDialog}
        handleSetCheckbox={handleSetCheckbox}
        handleHoveredRow={handleHoveredRow}
        getStatusLabel={getStatusLabel}
        handleAddNew={handleAddNew}
        handleSortBy={handleSortBy}
        checkIsRowDisabled={checkIsRowDisabled}        
      />
    </TableContainer>
  );
};

export default TableContentReciever;
