import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
`;

export const Title = styled.span`
  font-weight: 700;
`;

export const SubTitle = styled.span`
  color: #78787d;
`;

export const CloseButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

