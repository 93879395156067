export const SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL = 'tools/SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL';
export const SET_RECOMMENDED_HOTELS = 'tools/SET_RECOMMENDED_HOTELS';
export const SHOW_DELETE_RECOMMENDED_HOTELS_MODAL = 'tools/SHOW_DELETE_RECOMMENDED_HOTELS_MODAL';
export const CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL = 'tools/CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL';
export const SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA = 'tools/SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA';
export const SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA = 'tools/SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA';
export const SHOW_RECOMMENDED_HOTEL_OVERVIEW = 'tools/SHOW_RECOMMENDED_HOTEL_OVERVIEW';
export const CLOSE_RECOMMENDED_HOTEL_OVERVIEW = 'tools/CLOSE_RECOMMENDED_HOTEL_OVERVIEW';
export const SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA = 'tools/SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA';

