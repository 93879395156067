import styled, { css } from 'styled-components';

export const ImgStyled = styled.img`
  ${(props) => {
    if (props.preview) {
      return css`
        width: 100%;
        height: 100%;
        object-fit: cover;
      `; 
    }
  
    return css`
      object-fit: contain;
      max-width: 650;
      max-height: 450px;
      width: auto;
      height: auto;
    `;
  }}
`;

export const PdfStyled = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex;
  
  .pdf-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .canvas-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      canvas {
        overflow: hidden;
        margin: 0 auto;
        max-width: 50% !important;
        max-height: 100% !important;
      }
    }
  }
`;

