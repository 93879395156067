import { EXTERNAL_REFERENCE_PREFIX } from '../../../constants';

export const formatAccommodationDataForForm = (accommodation) => {
  return {
    id: accommodation.id,
    checkIn: new Date(accommodation.checkIn),
    checkOut: new Date(accommodation.checkOut),
    city: accommodation.region.city,
    cityName: accommodation.region.code,
    price: accommodation.price,
    priceWithoutAdditionalPercent: accommodation.priceWithoutAdditionalPercent,
    currency: accommodation.currency.id,
    hotelId: accommodation.hotelId,
    hotelName: accommodation.name,
    reference: accommodation.reference.replace(new RegExp(EXTERNAL_REFERENCE_PREFIX), ''),
    regionId: accommodation.region.id
  }
};
