import Yup from '../../utils/validation';

const validateMessages = {
  adminComments: {
    max: 'Comment cannot be longer than 1000 characters',
  },
};

export const schema = Yup.object().shape({
  adminComments: Yup.string().max(1000, validateMessages.adminComments.max),
});
