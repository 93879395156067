import { Text } from '@heathmont/moon-components';
import { Item, TooltipContent } from './styles';
import { ILineChartTooltipContent } from './types';

const TabChartTooltipContent: React.FC<ILineChartTooltipContent> = ({ payload }) => {
  const key = payload[0]?.payload?.dataKey || payload[0]?.dataKey;

  const totalCost = payload[0]?.payload?.totalCost ?? '';
  const tripNumber = payload[0]?.payload?.tripNumber ?? '';
  const reservationsNumber = payload[0]?.payload?.reservationsNumber ?? '';

  return (
    <Item key={key}>
      <TooltipContent>
        <div>
          <Text size={12} as="span">
            Total cost
          </Text>{' '}
          • {totalCost} EUR
        </div>

        <div>
          <Text size={12} as="span">
            Trip number
          </Text>{' '}
          • {tripNumber}
        </div>

        <div>
          <Text size={12} as="span">
            Reservations number
          </Text>{' '}
          • {reservationsNumber}
        </div>
      </TooltipContent>
    </Item>
  );
};

export default TabChartTooltipContent;
