import request from '../../../models/request';

export const fetchCompanyOptions = async (companyId: number): Promise<
  { users: any[], accountants: any[], travelopses: any[], employees: any[], ceos: any[], businessVerticals: any[] }> => {
  try {
    const [
      { data: accountants },
      { data: users },
      { data: travelopses },
      { data: employees },
      { data: managers },
      { data: businessVerticals }
    ] = await Promise.all([
      request.get(`tools/accountants?companyId=${companyId}`),
      request.get(`tools/users?companyId=${companyId}`),
      request.get(`tools/travelopses?companyId=${companyId}`),
      request.get(`tools/companies/employees/${companyId}`),
      request.get(`tools/managers?companyId=${companyId}`),
      request.get(`tools/business-verticals?filters[company]=${companyId}`),
    ]);
  
    return {
      accountants: accountants.records.map((el) => ({ value: el.id, title: el.title })),
      users: users.records.map((el) => ({ value: el.id, title: el.title })),
      travelopses: travelopses.records.map((el) => ({ value: el.id, title: el.title })),
      employees: employees.employees.map((el) => ({ value: el.id, title: el.fullName })),
      ceos: managers.records.map((el) => ({ value: el.id, title: el.title })),
      businessVerticals: businessVerticals.records.map((el) => ({ value: el.id, title: el.name, type: el.type })),
    };
  } catch (error) {
    return {
      accountants: [],
      users: [],
      travelopses: [],
      employees: [],
      ceos: [],
      businessVerticals: [],
    };
  }
};