import styled from 'styled-components';

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 50%;
  width: 50%;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  width: 50%;
  max-width: 50%;
  text-align: start;
`;

export const ActionButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;

  button {
    width: calc(100% / 2 - 2.5px);
  }
`;

export const Link = styled.div`
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 0.01px black;
  }
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    font-size: 16px;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-top: 20px;

  & button {
    max-width: 123px;
    border-radius: 8px;
    margin-left: 10px;
  }
`;
