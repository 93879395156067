import { useMemo, useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import { Button, Checkbox } from '@heathmont/moon-core';
import { TextInput } from '@heathmont/moon-components';
import { recommendedHotelSchema } from '../AddNewRecommendedHotel/schema';
import { ITRecommendedHotelsOverviewDrawerEditContent } from './types';
import { Wrapper, OverviewItemsWrapper, OverviewItemName, OverviewItem, ButtonsWrapper } from './styles';
import CustomSelect from '../CustomSelect';
import DatePicker from '../../components/DatePicker';
import FormField from '../FormField';
import { teamsNames, TEAMS_TYPES } from '../../enums/TeamsTypes';
import { getBoolStatusLabel } from '../../utils/tableHelpers';
import { fetchCities, fetchHotels, fetchTeamsByCompany } from '../../pages/Tools/RecommendedHotels/utils';

import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';
import { PurposeTypes } from '../../enums/PurposeTypes';

const getInitialValues = (data) => ({
  applyToAllPurposes: !!data.applyToAllPurposes,
  applyToAllTeams: !!data.applyToAllTeams,
  applyToAllDates: !!data.applyToAllDates,
  cityId: data.city?.id ?? null,
  hotelId: data.hotel?.id ?? null,
  purposesIds: data.purposes?.length ? data.purposes.map(({ id }) => id) : [],
  startDate: data.startDate ?? null,
  endDate: data.endDate ?? null,
  companyId: data.company?.id ?? null,
  teamsIds: data.company?.teams?.length ? data.company?.teams.map(({ id }) => id) : [],
  comment: data.comment ?? null,
  status: data.status ?? null,
});

const statuses = [
  { title: teamsNames[TEAMS_TYPES.DISABLED], value: TEAMS_TYPES.DISABLED },
  { title: teamsNames[TEAMS_TYPES.ACTIVE], value: TEAMS_TYPES.ACTIVE },
];

const RecommendedHotelsOverviewDrawerEditContent: React.FC<ITRecommendedHotelsOverviewDrawerEditContent> = ({
  purposes,
  companies,
  data,
  setIsEditFormData,
  getTripPurposeLabel,
  handleUpdate,
}) => {
  const initialValues = useMemo(() => getInitialValues(data), [data]);
  const { city, hotel } = data;
  const initialCityItems = [{ value: city.id, title: `${city.name} (${city.countryName})` }];
  const initialHotelItems = [{ value: hotel.id, title: hotel.name }];
  const [citiesOptions, setCitiesOptions] = useState(initialCityItems);
  const [hotelsOptions, setHotelsOptions] = useState(initialHotelItems);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [isHotelsLoading, setIsHotelsLoading] = useState(false);
  const [teamsOptions, setTeamsOptions] = useState([]);

  useEffect(() => {
    fetchTeamsByCompany({ companyId: null, newCompanyId: data.company?.id, setTeamsOptions });
  }, []);

  return (
    <Wrapper>
      <Formik
        validateOnBlur={false}
        validationSchema={recommendedHotelSchema}
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ errors, values, resetForm, setFieldValue, touched, setFieldTouched, handleBlur }: any): JSX.Element => (
          <Form>
            <OverviewItemsWrapper>
              <OverviewItemName>City</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  currentValue={values.cityId}
                  isSearchable
                  placeholder='City'
                  isLoading={isCitiesLoading}
                  isError={touched?.cityId && errors?.cityId}
                  error={touched?.cityId && errors?.cityId}
                  items={citiesOptions}
                  onInputChange={debounce((search) => 
                      fetchCities({ search, setFieldValue, setCitiesOptions, setHotelsOptions, setIsCitiesLoading }), 
                    DEFAULT_DEBOUNCE_MS_COUNT)}
                  onChange={(value) => {
                    setFieldValue('cityId', value);
                    setFieldValue('hotelId', null);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Hotel</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  isSearchable
                  currentValue={values.hotelId}
                  placeholder='Hotel'
                  isDisabled={!values.cityId}
                  isLoading={isHotelsLoading}
                  isError={touched?.hotelId && errors?.hotelId}
                  error={(touched?.hotelId && errors?.hotelId)}
                  items={hotelsOptions}
                  onInputChange={debounce((search) => 
                      fetchHotels({ search, regionId: values.cityId, setFieldValue, setHotelsOptions, setIsHotelsLoading }), 
                    DEFAULT_DEBOUNCE_MS_COUNT)}
                  onChange={(value) => {
                    setFieldValue('hotelId', value)
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Start date</OverviewItemName>
              <OverviewItem>
                <DatePicker
                  placeholder='Start date'
                  isEditView={true}
                  errors={touched?.startDate && errors}
                  disabled={values?.applyToAllDates}
                  value={values.startDate}
                  field="startDate"
                  onChange={(field, value) => {
                    setFieldTouched(field, true);
                    setFieldValue(field, value);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>End date</OverviewItemName>
              <OverviewItem>
                <DatePicker
                  placeholder='End date'
                  isEditView={true}
                  disabled={values?.applyToAllDates}
                  errors={touched?.endDate && errors}
                  value={values.endDate}
                  field="endDate"
                  onChange={(field, value) => {
                    setFieldTouched(field, true);
                    setFieldValue(field, value);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Apply to all dates</OverviewItemName>
              <OverviewItem>
                <Checkbox
                  checked={values?.applyToAllDates}
                  onChange={() => {
                    setFieldTouched('applyToAllDates', true);
                    setFieldValue('startDate', null);
                    setFieldValue('endDate', null);
                    setFieldValue('applyToAllDates', !values?.applyToAllDates);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Purpose</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  isMulti
                  isSearchable
                  placeholder='Purpose'
                  currentValue={values.purposesIds}
                  isDisabled={values?.applyToAllPurposes}
                  items={purposes.filter(purpose => purpose.type === PurposeTypes.INTERNAL)}
                  isError={touched?.purposesIds && errors?.purposesIds}
                  error={(touched?.purposesIds && errors?.purposesIds)}
                  getItemLabel={getTripPurposeLabel}
                  onChange={(value) => {
                    setFieldValue('purposesIds', value);
                    setFieldTouched('purposesIds', true);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Apply to all purposes</OverviewItemName>
              <OverviewItem>
                <Checkbox
                  checked={values?.applyToAllPurposes}
                  onChange={() => {
                    setFieldTouched('applyToAllPurposes', true);
                    setFieldValue('purposesIds', []);
                    setFieldValue('applyToAllPurposes', !values?.applyToAllPurposes);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Company</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  isSearchable
                  currentValue={values.companyId}
                  placeholder='Company'
                  isError={touched?.companyId && errors?.companyId}
                  error={(touched?.companyId && errors?.companyId)}
                  items={companies}
                  onChange={(value) => {
                    setFieldValue('companyId', value);
                    setFieldValue('teamsIds', []);
                    setFieldTouched('companyId', true);
                    fetchTeamsByCompany({ companyId: values.companyId, newCompanyId: value, setTeamsOptions });
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Teams</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  currentValue={values.teamsIds}
                  isSearchable
                  placeholder='Teams'
                  isDisabled={!values.companyId || values?.applyToAllTeams}
                  isError={touched?.teamsIds && errors?.teamsIds}
                  error={(touched?.teamsIds && errors?.teamsIds)}
                  isMulti
                  items={teamsOptions}
                  onChange={(value) => {
                    setFieldValue('teamsIds', value);
                    setFieldTouched('teamsIds', true);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Apply to all teams</OverviewItemName>
              <OverviewItem>
                <Checkbox
                  checked={values?.applyToAllTeams}
                  onChange={() => {
                    setFieldTouched('applyToAllTeams', true);
                    setFieldValue('teamsIds', []);
                    setFieldValue('applyToAllTeams', !values?.applyToAllTeams);
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Comment</OverviewItemName>
              <OverviewItem>
                <FormField
                  placeholder={'Comment'}
                  value={values.comment}
                  fieldId="comment"
                  fieldName="comment"
                  component={TextInput}
                  type="text"
                  inputSize="xlarge"
                  onChange={(e) => setFieldValue('comment', e.target.value)}
                  onBlur={handleBlur}
                  errors={touched?.comment && errors}
                  errorPosition={-20}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Status</OverviewItemName>
              <OverviewItem>
                  <CustomSelect
                  currentValue={values.status}
                  items={statuses}
                  getItemLabel={getBoolStatusLabel}
                  onChange={(value) => setFieldValue('status', value)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
            <ButtonsWrapper>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Cancel</Button>

              <Button
                variant="primary"
                disabled={!isEmpty(errors)}
                onClick={() => {
                  handleUpdate(data.id, values);
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Save</Button>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default RecommendedHotelsOverviewDrawerEditContent;
