import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

const focusedFieldShadow = 'box-shadow: 0 0 0 0.125rem #181b1c12 inset, 0 0 0 0.125rem #e0e0e0 inset;';

export const Wrapper = styled.div``;

export const SelectedPagesWrapper = styled.div`
  min-height: 56px;
  border: none;
  margin-top: 24px;
  border-radius: 12px;
  box-shadow: 0 0 0 0.0625rem #e0e0e0 inset;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isActive }) => isActive && focusedFieldShadow}

  &:hover {
    ${focusedFieldShadow}
  }
`;

export const SelectedPages = styled.div`
  width: 85%;
`;

export const Icon = styled.span`
  padding: 0 16px;
`;

export const PagesPlaceholder = styled(Text).attrs({
  size: 14,
  color: 'trunks.100',
})`
  line-height: 24px;
  margin: 15px;
`;
