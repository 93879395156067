import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';
import { GenericClose as MoonGenericClose } from '@heathmont/moon-icons';
import { Heading as MoonHeading } from '@heathmont/moon-components';

export const FormContent = styled.div`
  padding: 2.5rem 2.5rem 0;
`;

export const MessageContainer = styled.div`
  color: ${({ theme }) => theme.color.piccolo['100']};
  background-color: ${({ theme }) => theme.color.hit['100']};
  border-radius: 16px;
  width: 100%;
  max-width: 624px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
`;

export const TertButtonWrapper = styled.div`
  padding: 0 16px;
  & button {
    border-color: transparent;
    text-decoration: underline;
  }
`;

export const GenericClose = styled(MoonGenericClose)`
  && {
    margin: 0 auto 10px;
    display: block;
  }
`;

export const Heading = styled(MoonHeading)`
  && {
    margin-bottom: 20;
    text-align: center;
  }
`;

export const Text = styled(MoonText).attrs({})`
  margin-bottom: 40px;
  text-align: center;
`;
