import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const PaginationInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
`;

export const VerticalLine = styled.span`
  display: block;
  height: 24px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.beerus['100']};
  margin: 0 10px;
`;
