export default {
  USER: {
    LOYALTY_PROGRAMS: {
      max: 5,
    },
    ASSISTANTS: {
      max: 3,
    },
    BALANCED_ID: {
      max: 40,
    }, 
    JIRA_ID: {
      min: 2,
      max: 255,
    }, 
    COMMENT: {
      max: 1000,
    },  
    DOCUMENT_NUMBER: {
      min: 5,
      max: 40,
    },
    PASSWORD: {
      min: 8,
      max: 50,
    },
    FIRST_NAME: {
      min: 2,
      max: 40,
    },
    LAST_NAME: {
      min: 2,
      max: 40,
    },
  },
};