import {
  CLEAR_BEFORE_LOGIN_URL,
  SET_BEFORE_LOGIN_URL,
  SET_ERROR_PAGE_DATA,
  CLEAR_LOGIN_EMAIL,
  SET_REDIRECT_URL,
  SET_LOGIN_EMAIL,
  SET_READY,
} from './actionTypes';
import { LOGOUT } from '../auth/actionTypes';

const initState = {
  shallBeRedirectedTo: null,
  isAppReady: false,
  errorPage: {},
  beforeLoginUrl: null,
  loginEmail: null,
  isActivePageFilters: {
    dashboard: false,
    trips: false,
    reports: false,
    expenses: false,
    users: false,
  },
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        shallBeRedirectedTo: action.payload.redirectUrl,
      };
    case SET_READY:
      return {
        ...state,
        isAppReady: action.data.isAppReady,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        shallBeRedirectedTo: action.data.shallBeRedirectedTo,
      };
    case SET_ERROR_PAGE_DATA:
      return {
        ...state,
        errorPage: action.data,
      };
    case SET_BEFORE_LOGIN_URL:
      return {
        ...state,
        beforeLoginUrl: action.data.beforeLoginUrl,
      };
    case CLEAR_BEFORE_LOGIN_URL:
      return {
        ...state,
        beforeLoginUrl: null,
      };
    case SET_LOGIN_EMAIL:
      return {
        ...state,
        loginEmail: action.data.loginEmail,
      };
    case CLEAR_LOGIN_EMAIL:
      return {
        ...state,
        loginEmail: null,
      };
    default:
      return state;
  }
};

export default appReducer;
