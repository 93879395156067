import {
  SET_RECORD_LOADING,
  SET_RECORD_DATA,
  SET_LOADING,
  SET_ACTIONS,
  SET_FILTER,
  SET_FIELDS,
  SET_READY,
  SET_TOTAL,
  SET_DATA,
  SET_NAME,
} from './actionTypes';

const initState = {
  isTableLoading: false,
  isTableReady: false,
  tableName: null,
  tableActions: [],
  tableFilters: {},
  tableDefaults: {
    perPage: {
      default: 25,
      values: [25, 50, 100],
    },
  },
  tableTotal: 0,
  tableData: [],
  tableFields: [],
  isRecordLoading: false,
  tableRecordData: {},
};

const tableReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isTableLoading: action.data.isTableLoading,
      };
    case SET_RECORD_LOADING:
      return {
        ...state,
        isRecordLoading: action.data.isRecordLoading,
      };
    case SET_RECORD_DATA:
      return {
        ...state,
        tableRecordData: action.data.tableRecordData,
      };
    case SET_READY:
      return {
        ...state,
        isTableReady: action.data.isTableReady,
      };
    case SET_ACTIONS:
      return {
        ...state,
        tableActions: action.data.tableActions,
      };
    case SET_FILTER:
      return {
        ...state,
        tableFilters: action.data.tableFilters,
      };
    case SET_TOTAL:
      return {
        ...state,
        tableTotal: action.data.tableTotal,
      };
    case SET_FIELDS:
      return {
        ...state,
        tableFields: action.data.tableFields,
      };
    case SET_DATA:
      return {
        ...state,
        tableData: action.data.tableData,
      };
    case SET_NAME:
      return {
        ...state,
        tableName: action.data.tableName,
      };
    default:
      return state;
  }
};

export default tableReducer;
