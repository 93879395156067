import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import { SET_RECOMMENDED_HOTELS } from './actionTypes';

export const fetchRecommendedHotels = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const { data: { records, total } } = await request.get('tools/recommended-hotels', { params });

    dispatch({ type: SET_RECOMMENDED_HOTELS, payload: { records, total } });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const removeRecommendedHotel = async (recommendedHotelIds) => {
  try {
    return request.delete('tools/recommended-hotels', { data: { ids: recommendedHotelIds } });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    getErrorStatusMessage({
      status: 200,
      message: `Recommended hotels with IDs ${recommendedHotelIds.join(", ").replace(/,([^,]*)$/, ' and$1')} have been successfully removed`,
    });
  }
};
