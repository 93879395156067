import { Button, Text } from '@heathmont/moon-core';
import { OtherRocket, GenericEdit, GenericDelete } from '@heathmont/moon-icons-tw';
import moment from 'moment';
import {
  FlightInfoBlock,
  FlightContent,
  LeftContent,
  Icons,
} from '../styles';

interface IOtherTransportOverview {
  otherTransport: any;
  currencies: any[]
  cities: any[];
  handleDelete: () => void; 
  handleEdit: () => void;
};

const OtherTransportOverview = ({
  otherTransport,
  currencies,
  cities,
  handleDelete,
  handleEdit,
}: IOtherTransportOverview) => {
  const formatFlightDateAndTime = (date) => {
    return moment(date).format('DD MMM YYYY, HH:mm');
  };

  const getCurrencyCodeFromId = (currencyId) => {
    return currencies.find((currency) => currency.value === currencyId)?.title || '';
  };

  const getCityNameFromId = (cityId, cities) => {
    return cities.find((city) => city?.value === cityId)?.title || '';
  };

  return (
    <FlightInfoBlock>
      <LeftContent>
        <OtherRocket fontSize="1.6rem" />
        <FlightContent>
          <p>
            {getCityNameFromId(otherTransport.departureCity, cities)} → {getCityNameFromId(otherTransport.arrivalCity,  cities)} — {otherTransport.type}
          </p>
          <p className="flight-dates">
            {formatFlightDateAndTime(otherTransport.departureDate)} → {formatFlightDateAndTime(otherTransport.arrivalDate)}
          </p>
          <Text size={12}>
            <strong>Price: </strong>{otherTransport.priceWithoutAdditionalPercent} {getCurrencyCodeFromId(otherTransport.currency)}
          </Text>
        </FlightContent>
      </LeftContent>
      <Icons>
        <Button
          iconOnly={<GenericEdit fontSize="1.2rem"/>}
          variant="ghost"
          size="small"
          onClick={handleEdit}
        />
        <Button
          iconOnly={<GenericDelete fontSize="1.2rem"/>}
          variant="ghost"
          size="small"
          onClick={handleDelete}
        />
      </Icons>
    </FlightInfoBlock>
  );
};

export default OtherTransportOverview;
