export const getPeriodName = (periodType, periodRange) => {
  switch (periodType) {
    case 'past_month':
      return 'Past month';
    case 'ALL':
      return 'All period';
    case 'current_month':
      return 'Current month';
    case 'current_year':
      return 'Current year';
    case 'past_year':
      return 'Past year';
    case 'custom':
      return periodRange;
    default:
      return '';
  }
};
