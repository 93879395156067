import { Fragment, useState } from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@heathmont/moon-core';
import { ControlsChevronUpSmall, ControlsChevronDownSmall } from '@heathmont/moon-icons';
import {
  getCryptoPaymentStatusLabel,
  getCardPaymentStatusLabel,
  getPaymentStatusLabel,
  formatPaymentMethod,
  formatDateAndTime,
  getOrderNumber,
} from '../../utils/tableHelpers';
import { ITripPayments } from './types';
import {
  ActionButtonsWrapper,
  MoreWrapper,
  MoreText,
  Link,
} from './styles';
import {
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
  BlockSubTitle,
} from '../TripsOverviewDrawerContent/styles';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import CustomSelect from '../CustomSelect';
import CopyToClipboard from '../CopyToClipboard';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import {
  CryptoTransactionStatusDescription,
  CryptoTransactionStatus,
} from '../../enums/CryptoTransaction';
import { PAYMENT_STATUS, paymentStatusOptions } from '../../enums/PaymentStatus';
import request from '../../models/request';
import {
  CreditCardOrderStatusDescription,
  CreditCardOrderStatus,
} from '../../enums/CreditCardOrder';
import InfoBlock from '../InfoBlock';
import PaymentProviderIcon from '../PaymentProviderIcon';
import { ArrowsRefreshRound } from '@heathmont/moon-icons-tw';

const MAX_PAYMENT_TO_SHOW_BY_DEFAULT = 1;

const TripPayments: React.FC<ITripPayments> = ({
  payments,
  tripId,
  onChangePaymentStatus,
  handleCreateInvoiceModalOpen,
}) => {
  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState<boolean>(false);
  const [statusChangeId, setStatusChangeId] = useState<string | null>(null);

  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const [invoiceLoadingPaymentId, setInvoiceLoadingPaymentId] = useState<string | null>(null);

  const [isActualizeStatusLoading, setIsActualizeStatusLoading] = useState<boolean>(false);
  const [actualizeStatusPaymentId, setActualizeStatusPaymentId] = useState<string | null>(null);

  const [isMoreData, setIsMoreData] = useState({
    itemsToShow: MAX_PAYMENT_TO_SHOW_BY_DEFAULT,
    isExpanded: false,
  });

  const goToPayment = (orderNumber: string) => {
    const search = `OrderNumber=${orderNumber}`;
    window.open(`/payments?search=${encodeURIComponent(search)}`, '_blank');
  };

  const showMore = () => {
    isMoreData.itemsToShow === MAX_PAYMENT_TO_SHOW_BY_DEFAULT
      ? setIsMoreData({ itemsToShow: payments.length, isExpanded: true })
      : setIsMoreData({ itemsToShow: MAX_PAYMENT_TO_SHOW_BY_DEFAULT, isExpanded: false });
  };

  const handlePaymentStatusChange = async (paymentId: string, newStatus: PAYMENT_STATUS) => {
    try {
      setIsStatusChangeLoading(true);
      setStatusChangeId(paymentId);

      const response = await request.post(`trips/${tripId}/payments/status/${paymentId}`, {
        status: newStatus,
      });

      if (response?.data?.payment) {
        onChangePaymentStatus({
          balancedIoInvoiceNumber: response?.data?.payment?.balancedIoInvoiceNumber,
          paymentId,
          status: newStatus,
        });

        getErrorStatusMessage({
          status: 200,
          message: 'Payment status successfully changed!',
        });
      } else {
        getErrorStatusMessage({
          message: 'Something went wrong. Try again later.',
        });
      }
      
      setIsStatusChangeLoading(false);
      setStatusChangeId(null);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      });
      setIsStatusChangeLoading(false);
      setStatusChangeId(null);
    }
  };

  const handleCheckActualStatus = async (paymentId: string) => {
     try {
      setIsActualizeStatusLoading(true);
      setActualizeStatusPaymentId(paymentId);

      const response = await request.get(`trips/${tripId}/payments/status/${paymentId}`);

      if (response?.data?.payment) {
        onChangePaymentStatus({
          balancedIoInvoiceNumber: response?.data?.payment?.balancedIoInvoiceNumber,
          externalStatus: response?.data?.payment?.externalStatus,
          paymentId,
          status: response?.data?.payment?.status,
        });

        getErrorStatusMessage({
          status: 200,
          message: 'Payment status successfully synchronized!',
        });
      } else {
        getErrorStatusMessage({
          message: 'Something went wrong. Try again later.',
        });
      }
      
      setActualizeStatusPaymentId(null);
      setIsActualizeStatusLoading(false);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      });
      setActualizeStatusPaymentId(null);
      setIsActualizeStatusLoading(false);
    }
  };

  const handleDownloadInvoice = async (paymentId: string) => {
    try {
      setIsInvoiceDownloading(true);
      setInvoiceLoadingPaymentId(paymentId);

      const response = await request.post(`trips/${tripId}/payments/invoice/${paymentId}`);

      if (response?.data?.invoice) {
        const ticketPdf = await fetch(response?.data?.invoice?.url);
        const blob = await ticketPdf.blob();
        saveAs(blob, `${response?.data?.invoice?.name}.pdf`)
      }

      setIsInvoiceDownloading(false);
      setInvoiceLoadingPaymentId(null);
    } catch (e) {
      setIsInvoiceDownloading(false);
      setInvoiceLoadingPaymentId(null);
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  return (
    <>
      {payments.slice(0, isMoreData.itemsToShow).map(payment => (
        <Fragment key={payment.id}>
          {payment.status === PAYMENT_STATUS.SUCCESS && !payment.balancedIoInvoiceNumber &&
            <InfoBlock
              actionLabel={'Create'}
              text={`The payment was successful. But the invoice for the payment was not created.`}
              onHandleAction={() => handleCreateInvoiceModalOpen({tripId, paymentId: payment.id})}
            />
          }
          <BlockSubTitle>
            Payment by {formatPaymentMethod([payment])}
          </BlockSubTitle>
          {(payment.paymentType === PAYMENT_TYPE.CRYPTO || payment.paymentType === PAYMENT_TYPE.CARD) && (
            <OverviewContent>
              <OverviewContentName>Provider</OverviewContentName>
              <OverviewContentValue>
                <PaymentProviderIcon provider={payment.provider} />
              </OverviewContentValue>
            </OverviewContent>
          )}
          {payment.key && (
            <OverviewContent>
              <OverviewContentName>Transaction ID (Key)</OverviewContentName>
              <OverviewContentValue>
                <CopyToClipboard text={payment.key} />
              </OverviewContentValue>
            </OverviewContent>
          )}
          {payment.balancedIoInvoiceNumber && (
            <OverviewContent>
              <OverviewContentName>Invoice #</OverviewContentName>
              <OverviewContentValue>{payment.balancedIoInvoiceNumber}</OverviewContentValue>
            </OverviewContent>
          )}
          <OverviewContent>
            <OverviewContentName>Order #</OverviewContentName>
            <OverviewContentValue>
              <Link onClick={() => goToPayment(getOrderNumber(payment.id))}>
                {getOrderNumber(payment.id)}
              </Link>
            </OverviewContentValue>
          </OverviewContent>
          <OverviewContent>
            <OverviewContentName>Status</OverviewContentName>
            <OverviewContentValue>
              {payment.paymentType === PAYMENT_TYPE.INVOICE ? (
                <CustomSelect
                  currentValue={payment.status}
                  isDisabled={isStatusChangeLoading && payment?.id === statusChangeId}
                  items={paymentStatusOptions}
                  getItemLabel={getPaymentStatusLabel}
                  onChange={(value) => handlePaymentStatusChange(payment.id, value as any)}
                />
              ) : (
                getPaymentStatusLabel(payment.status)
              )}
            </OverviewContentValue>
            
          </OverviewContent>
          {payment.paymentType === PAYMENT_TYPE.CARD && (
            <>
              <OverviewContent>
                <OverviewContentName>Payment status</OverviewContentName>
                <OverviewContentValue>
                  {getCardPaymentStatusLabel(payment.externalStatus as CreditCardOrderStatus)}
                </OverviewContentValue>
              </OverviewContent>
              <OverviewContent>
                <OverviewContentName>Payment status description</OverviewContentName>
                <OverviewContentValue>{CreditCardOrderStatusDescription[payment.externalStatus]}</OverviewContentValue>
              </OverviewContent>
            </>
          )}
          {payment.paymentType === PAYMENT_TYPE.CRYPTO && (
            <>
              <OverviewContent>
                <OverviewContentName>Payment status</OverviewContentName>
                <OverviewContentValue>
                  {getCryptoPaymentStatusLabel(payment.externalStatus as CryptoTransactionStatus)}
                </OverviewContentValue>
              </OverviewContent>
              <OverviewContent>
                <OverviewContentName>Payment status description</OverviewContentName>
                <OverviewContentValue>{CryptoTransactionStatusDescription[payment.externalStatus]}</OverviewContentValue>
              </OverviewContent>
            </>
          )}
          <OverviewContent>
            <OverviewContentName>Created at</OverviewContentName>
            <OverviewContentValue>{formatDateAndTime(payment.createdAt, true)}</OverviewContentValue>
          </OverviewContent>
          <OverviewContent>
            <OverviewContentName>Details</OverviewContentName>
            <OverviewContentValue>{payment.description}</OverviewContentValue>
          </OverviewContent>
          <OverviewContent>
            <OverviewContentName>
              {payment.status === PAYMENT_STATUS.SUCCESS ? 'Total paid' : 'Total'}, EUR
            </OverviewContentName>
            <OverviewContentValue>{payment.sum}</OverviewContentValue>
          </OverviewContent>
          <OverviewContent>
            <ActionButtonsWrapper>
              {
                (payment.status === PAYMENT_STATUS.SUCCESS || (payment.paymentType === PAYMENT_TYPE.INVOICE && payment.status !== PAYMENT_STATUS.FAILED)) && (
                  <Button
                    animation={isInvoiceDownloading && payment?.id === invoiceLoadingPaymentId ? 'progress' : undefined}
                    disabled={isInvoiceDownloading && payment?.id === invoiceLoadingPaymentId}
                    variant="secondary"
                    size="small"
                    onClick={() => handleDownloadInvoice(payment.id)}
                  >
                    Download invoice
                  </Button>
                )
              }
              {
                (payment.paymentType === PAYMENT_TYPE.CRYPTO || payment.paymentType === PAYMENT_TYPE.CARD) && (
                  <Button
                    animation={isActualizeStatusLoading && payment?.id === actualizeStatusPaymentId ? 'progress' : undefined}
                    disabled={isActualizeStatusLoading && payment?.id === actualizeStatusPaymentId}
                    variant="secondary"
                    size="small"
                    onClick={() => handleCheckActualStatus(payment.id)}
                  >
                    <ArrowsRefreshRound />
                    Sync payment
                  </Button>
                )
              }
            </ActionButtonsWrapper>
          </OverviewContent>
        </Fragment>
      ))}
      {payments.length > MAX_PAYMENT_TO_SHOW_BY_DEFAULT && (
        <MoreWrapper onClick={showMore}>
          <MoreText>{isMoreData.isExpanded ? 'Less payments' : 'More payments'}</MoreText>

          {isMoreData.isExpanded ? <ControlsChevronUpSmall /> : <ControlsChevronDownSmall />}
        </MoreWrapper>
      )}
    </>  
  );
};

export default TripPayments;
