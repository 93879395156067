import moment from 'moment';
import { getLogLabel } from '../../utils/tableHelpers';
import { ILog } from './types';
import {
  LogLabelWrapper,
  LogWrapper,
  LogText,
  LogDate,
} from './styles';

const Log: React.FC<ILog> = ({ createdAt, status, text }) => {
  return (
    <LogWrapper>
      <LogLabelWrapper>
        <LogDate>{moment(createdAt).format('lll')}</LogDate>
        <LogText>{text}</LogText>
      </LogLabelWrapper>
      <span>
        {getLogLabel(status)}
      </span>
    </LogWrapper>
  );
};

export { Log };
