import { TripStatusType } from '../../enums/TripStatusType';
import { TripStatusUpdateFields } from '../../enums/TripStatusUpdateFields';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { delay } from '../../utils/helpers';
import { updateFeedback as updateFeedbackAction } from './actions';
import { getStatusUpdatePath, isPaymentRequired, isTravelerExternal } from './utils';

export const handleUpdateTripsStatus = async ({
  declineReason,
  newStatus,
  trip,
} : {
  declineReason: string | null;
  newStatus: TripStatusType;
  trip: TripStatusUpdateFields;
}) => {
  try {  
    await request.post(getStatusUpdatePath(trip), {
      declineReason: newStatus === TripStatusType.DECLINED ? declineReason : null,
      status: newStatus,
    });

    // TODO: Current approach for updating statuses for internal trips is not straightforward.
    // We send request to API -> API sends request to Slack API -> Slack API sends request to API.
    // But we get response immediately from API.
    // Because of this trips statuses are not changed at this moment.
    // And we had to add some delay. This approach must be refactored.  
    if (!isTravelerExternal(trip) && !isPaymentRequired(trip)) {
      const DELAY_TIME = newStatus === TripStatusType.PURCHASED ? 5000 : 3000;
      await delay(DELAY_TIME);
    }

    getErrorStatusMessage({
      status: 200,
      message: `The trip status with Trip ID - ${trip.tripId} successfully changed.`,
    });

    return;
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `The trip status with Trip ID - ${trip.tripId} can't be changed.`,
    });
  }
};

export const updateTripStatus = async ({
  declineReason,
  newStatus,
  trips,
  setIsLoading,
} : {
  declineReason: string | null;
  newStatus: TripStatusType;
  trips: TripStatusUpdateFields[];
  setIsLoading: (isLoading: boolean) => void;
}) => {
  setIsLoading(true);

  await Promise.allSettled(trips.map(trip => handleUpdateTripsStatus({
    declineReason,
    newStatus,
    trip,
  })));

  setIsLoading(false);
};

export const deleteTripData = (tripIds) => {
  return Promise.allSettled(tripIds.map((tripId) => {
    return request
      .delete(`trips/${tripId}`, { tripId: tripId })
      .then(() => {
        getErrorStatusMessage({
          status: 200,
          message: `The trip with Trip ID - ${tripId} successfully removed.`,
        });
      })
      .catch((e) => {
        getErrorStatusMessage({
          status: e?.response?.status,
          message: `The trip with Trip ID - ${tripId} can't be removed.`,
        });
        console.error(e);
      });
  }))
};

export const updateFeedback = (tripId, feedbackId, data) => async (dispatch) => {
  try {
    const { data: { feedback } } = await request.update(`trips/feedback/${tripId}/${feedbackId}`, data);

    dispatch(updateFeedbackAction(tripId, feedbackId, feedback));
    getErrorStatusMessage({
      status: 200,
      message: `Feedback successfully updated!`,
    });
  } catch(err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};
