import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Button } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  flex: 1 auto;

  & div {
    & div {
      & input {
        font-size: ${({ isMobile }: any) => (isMobile ? '16px' : '14px')};
      }
      & label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 32px);
      }
    }
  }
`;

export const Label = styled.div`
  font-size: ${rem(14)};
  font-weight: 600;
  line-height: ${rem(20)};
  letter-spacing: 0.2px;
  width: 160px;

  && {
    width: 160px;
    ${({ labelPadding }: any) =>
      labelPadding &&
      `
     padding: ${labelPadding} !important;
  `}

    & span {
      margin-left: 12px;
      color: rgb(146, 150, 159);
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      font-weight: 700;
      letter-spacing: 0.2px;
    }
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ff0000;
`;

export const WithSwapIcon = styled.div`
  display: flex;
  position: relative;
  z-index: 9 !important;

  & div {
    position: absolute;

    @media screen and (max-width: 768px) {
      right: 20px;
      top: -88px;
    }

    @media screen and (min-width: 768px) {
      left: 0;
      right: 0;
      top: -52px;
      margin: auto;
    }
    
  }
`;

export const WithPrefix = styled.div`
  display: flex;
  position: relative;

  & span {
    font-size: 16px;
    color: #181B1C;
    z-index: 1 !important;
    position: absolute;
    top: 12px;
    left: 17px;
  }

  & .text-input {
    position: relative;

    input {
      padding-left: 55px;
    }
  }
`;

export const WithCopyToClipboard = styled.div`
  display: flex;
  position: relative;

  input {
    pointer-events: none;
    width: 100%;
    padding-right: 55px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CopyToClipboardButton = styled(Button)`
  z-index: 1 !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 17px;
`;

export const WithPostfix = styled.div`
  display: flex;
  position: relative;

  & span {
    font-size: 12px;
    color: #78787d;
    z-index: 1 !important;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 17px;
    opacity: ${({ disabled }) => disabled ? '0.3' : '1'};
  }

  & .text-input {
    position: relative;

    input {
      padding-right: 40px;
    }
  }
`;

export const SubmissionButton = styled(Button)`
  height: 24px;
  width: 24px;
  z-index: 1 !important;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  font-size: 16px;

  svg {
    width: auto !important;
    height: auto !important;
  }
`;

export const WithSubmission = styled.div`
  display: flex;
  position: relative;

  & .text-input {
    input {
      padding-right: 48px;
    }
  }
`;
