import { useMemo } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Form, Formik } from 'formik';
import { TextInput } from '@heathmont/moon-core';
import FormField from '../FormField';
import CustomSelect from '../CustomSelect';
import Button from '../Button';
import schema from './schema';
import { 
  FormFieldsWrapper,
  ButtonsWrapper,
  DatesWrapper,
} from './styles';
import { IExpenseForm } from './types';
import { getLabels } from '../../utils/tableHelpers';

const formatDate = (expense) => {
  const DATE_FORMAT = 'yyyy-MM-DD';
  return moment(expense?.date).format(DATE_FORMAT);
};

const getInitialValues = (expense, currencies, expenseTypes) => {
  const defaultCurrency = currencies.find(currency => currency.default);
  const defaultType = expenseTypes.find(type => type.default);
  
  return {
    description: expense?.description || undefined,
    currency: expense?.currencyId || defaultCurrency?.value,
    type: expense?.type || defaultType?.value,
    cost: +expense?.cost || undefined,
    date: expense?.date ? formatDate(expense) : undefined,
  };
};

const ExpenseForm: React.FC<IExpenseForm> = ({
  expenseTypes,
  initialData,
  currencies,
  expenses,
  loading,
  isMulti,
  onSubmit,
  onCancel,
}): JSX.Element => {
  const initialValues = useMemo(() => getInitialValues(initialData, currencies, expenseTypes), [initialData, currencies, expenseTypes]);

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, values, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <FormFieldsWrapper>
                {!isMulti ? (
                  <FormField
                    fieldName="date"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldId="date"
                    errors={errors}
                    value={values.date}
                    label="Payment date"
                    type="date"
                    onChange={(e) => setFieldValue('date', e.target.value)}
                  />
                ) : (
                  <DatesWrapper>
                    {getLabels(expenses.map(expense => ({ ...expense, date: formatDate(expense) })), 'date')}
                  </DatesWrapper>
                )}
                
                <CustomSelect
                  currentValue={values.type}
                  isDisabled={isMulti}
                  items={expenseTypes}
                  error={errors['type']}
                  onChange={(value) => setFieldValue('type', value)}
                />

                <FormField
                  fieldName="cost"
                  component={TextInput}
                  inputSize="xlarge"
                  fieldId="cost"
                  errors={errors}
                  value={values.cost}
                  label="Amount"
                  type="number"
                  onChange={(e) => setFieldValue('cost', e.target.value)}
                />

                <CustomSelect
                  currentValue={values.currency}
                  error={errors['currency']}
                  items={currencies}
                  onChange={(value) => setFieldValue('currency', value)}
                />

                <FormField
                  fieldName="description"
                  component={TextInput}
                  inputSize="xlarge"
                  fieldId="description"
                  errors={errors}
                  value={values.description}
                  label="Description"
                  type="text"
                  onChange={(e) => setFieldValue('description', e.target.value)}
                />
              </FormFieldsWrapper>

              <ButtonsWrapper>
                <Button variant="tertiary" label="Close" handleClick={onCancel} />
                <Button isDisabled={!isEmpty(errors) || loading} variant="primary" label="Save" type="submit"/>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExpenseForm;
