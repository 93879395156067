import React, { useMemo, useState } from 'react'
import { Form, Formik } from 'formik';
import { ContentWrapper, AccomodationInfoBlock, OverviewItem, OverviewItemName, OverviewItemsWrapper, Separator, TripOverviewWrapper } from './styles'
import CustomSelect from '../CustomSelect';
import { useSelector } from 'react-redux';
import moment from 'moment';


const UnassignedAccommodationDialogContent = ({accommodationData,values,errors,setFieldValue,setFieldTouched, touched}) => {
 
  const companyUsers = useSelector((state: any) => state.tripsReducer.tripOptions.companyEmployees)
  
  const formatAccommodationDateAndTime = (date) => {
    return moment(date).format('DD MMM YYYY');
  };

  const usersOptions = useMemo(() => companyUsers.map((user) => ({value: user.value,title:`${user.title} (${user.email})`})),[companyUsers])

  return (
    <ContentWrapper>
      <Form>
        <TripOverviewWrapper>
          <AccomodationInfoBlock>
            <p>
              <strong>{`[${accommodationData.reference}]`}</strong>
              {` ${accommodationData.hotel.name}, ${accommodationData.city} — `}
              <strong>{`${accommodationData.price.value} ${accommodationData.price.currency}`}</strong>
            </p>
            <p>
              <span className='flight-dates'>
                {`${formatAccommodationDateAndTime(accommodationData.checkIn)} → ${formatAccommodationDateAndTime(accommodationData.checkOut)}`}
              </span>
            </p>
            <p>
              <strong>Guest: </strong>
              {`${accommodationData.guest.firstName} ${accommodationData.guest.lastName}`}
            </p>
          </AccomodationInfoBlock>
        </TripOverviewWrapper>
        <Separator />
        <OverviewItem>
          <CustomSelect
            isSearchable
            currentValue={values.user}
            placeholder="Select traveler"
            isError={touched?.user && errors?.user}
            error={touched?.user && errors?.user}
            items={usersOptions}
            onChange={(value) => {
              setFieldValue('user', value);
              setFieldTouched('user', true, false);
            }}
          />
        </OverviewItem>
      </Form>
    </ContentWrapper>
  );
}

export default UnassignedAccommodationDialogContent