import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { saveAs } from 'file-saver';
import { FilesExport } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import request from '../../../models/request';
import { IFeedbacks } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import Drawer from '../../../components/Drawer';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { toolFeedbacksDataMapper } from '../../../utils/tableHelpers';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';
import FeedbackOverviewDrawerContent from '../../../components/FeedbackOverviewDrawerContent';

const Feedbacks: React.FC<IFeedbacks> = ({
  isOpenFeedbackOverview,
  overviewFeedbackData,
  totalFeedbacks,
  feedbacks,
  filters,
  sortBy,
  page,
  closeFeedbackOverview,
  showFeedbackOverview,
  setQueryParams,
  fetchFeedbacks,
  updateFeedback,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchFeedbacks(params);
  }, [filters, page, sortBy]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);


  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const mappedData = useMemo(() => toolFeedbacksDataMapper(feedbacks), [feedbacks]);

  const handleExport = async () => {
    try {
      const { data } = await request.get(`tools/feedbacks/export-as-excel`, {
        params,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, `Travel app feedback.xlsx`);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: 'Something went wrong. Try again later',
      });
    }
  };

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showFeedbackOverview(feedbacks[row?.id]);
    },
    [feedbacks]
  );

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  useMemo(() => {
    totalItems.current = totalFeedbacks;
  }, [totalFeedbacks]);
  
  const tableContentRecieverProps = {
    isShowTableFilters,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: mappedData,
    handleIsOverviewOpen,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Feedbacks</Title>

          <Button
            iconLeft={<FilesExport fontSize="1.6rem" />}
            disabled={!totalFeedbacks}
            variant="primary"
            onClick={handleExport}
          >
            Export as excel
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.FEEDBACKS}
          totalItems={totalItems}
          data={feedbacks}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchFeedbacks(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenFeedbackOverview && (
        <div>
          <Drawer
            title={overviewFeedbackData?.traveler?.fullName ?? ''}
            handleClose={closeFeedbackOverview}
          >
            <FeedbackOverviewDrawerContent
              data={overviewFeedbackData}
              handleUpdate={updateFeedback}
            />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Feedbacks;
