import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const FieldWrapper = styled.div``;

export const EmailSign = styled(Text).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 16px;
  font-weight: 400;
  margin-top: 8px;
`;

export const DoubleFieldWrapper = styled.div`
  margin: 24px 0;

  & div {
    & div {
      &::after {
        z-index: 0;
      }

      & div {
        & div {
          & div {
            & div {
              & div:nth-child(2) {
                & div:nth-child(1) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
