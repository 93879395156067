import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { ControlsCloseSmall } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { setSignUpError } from '../../../store/signUp/actions';
import { HeathmontLogo } from '../../../components/HeathmontLogo';
import SignUpFormTabs from '../../../components/SignUpFormTabs';
import { FormTabType } from '../../../enums/FormTabType';
import useIsMobile from '../../../hooks/useIsMobile';
import { isDisableSubmit } from '../suggestions';
import { Container, Footer, Header, HeaderWrapper, Wrapper } from '../styles';
import { NotificationWrapper, Notification, NotificationText } from '../../EditProfile/DesktopEditProfile/styles';
import { ActiveTab } from '../ActiveTab';
import { signUpSchema } from '../schema';
import { ISignUp } from '../types';

const SignUp: React.FC<ISignUp> = ({
  initialValues,
  checkedTabs,
  options,
  handleSubmit,
  handleBack,
  handleNext,
}) => {
  const activeTab = useSelector((state: any) => state.signUpReducer.activeTab);
  const submitError = useSelector((state: any) => state.signUpReducer.submitError);

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const isValidNewPassword = useSelector((state: any) => state.signUpReducer.isValidNewPassword);

  return (
    <Wrapper isMobile={isMobile}>
      <HeaderWrapper>
        <HeathmontLogo style={{ margin: 0 }} />
      </HeaderWrapper>

      <Header>Complete your sign up</Header>

      <SignUpFormTabs activeTab={activeTab} checkedTabs={checkedTabs} />

      {submitError.isError && (
        <NotificationWrapper>
          <Notification isUpdateError={submitError.isError}>
            <NotificationText>{submitError.message}</NotificationText>
            <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setSignUpError(false))} />
          </Notification>
        </NotificationWrapper>
      )}

      <Formik
        enableReinitialize
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={signUpSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps: any): JSX.Element => {
          const { errors } = formProps;
          return (
            <Form>
              <Container>
                <ActiveTab activeTab={activeTab} options={options} {...formProps} />
              </Container>

              <Footer>
                {activeTab > 1 && (
                  <Button
                    variant="secondary"
                    style={{ marginRight: 10 }}
                    type="button"
                    onClick={handleBack}
                  >Back</Button>
                )}

                {activeTab === FormTabType.ChangePassword && (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isDisableSubmit(errors, isValidNewPassword, activeTab)}
                  >Sign up</Button>
                )}

                {activeTab !== FormTabType.ChangePassword && (
                  <Button
                    variant="primary"
                    disabled={isDisableSubmit(errors, isValidNewPassword, activeTab)}
                    type="button"
                    onClick={handleNext}
                  >Next</Button>
                )}
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default SignUp;
