import React from 'react';
import { ControlsChevronRight } from '@heathmont/moon-icons';
import { EDIT_PROFILE_TABS, TAB_NAMES } from '../../../../components/Routes/suggestions';
import { HeMobileTabName, MobileTab } from '../styles';
import { IMobileEditProfileTabs } from '../../types';

const MOBILE_TABS = [
  EDIT_PROFILE_TABS.DETAILS,
  EDIT_PROFILE_TABS.SETTINGS,
  EDIT_PROFILE_TABS.DOCUMENT,
  EDIT_PROFILE_TABS.CRYPTO_WALLET,
  EDIT_PROFILE_TABS.LOYALTY_PROGRAMS,
  EDIT_PROFILE_TABS.FAVORITE_HOTELS,
  EDIT_PROFILE_TABS.SECURITY,
];

const MobileEditProfileTabs: React.FC<IMobileEditProfileTabs> = ({ push }) => {
  return (
    <div>
      {MOBILE_TABS.map(path => (
        <MobileTab key={path} onClick={() => push(path)}>
          <HeMobileTabName>{TAB_NAMES[path]}</HeMobileTabName>
          <ControlsChevronRight />
        </MobileTab>
      ))}
    </div>
  );
};

export default MobileEditProfileTabs;
