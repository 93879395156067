import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import { setSubscriptionLogs, setSubscriptions, showSubscriptionOverview } from './actions';

export const fetchSubscriptions = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const {
      data: { records },
    } = await request.get('tools/subscriptions', { params });

    dispatch(setSubscriptions(records));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const fetchSubscriptionsLogs = (subscriptionId) => async (dispatch) => {
  try {
    const {
      data: { records },
    } = await request.get(`tools/subscriptions/logs/${subscriptionId}`);

    dispatch(setSubscriptionLogs(records));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateSubscription = (subscriptionId, data) => (dispatch, getState) => {
  dispatch(setIsLoadingData(true));

  const {
    subscriptionsReducer: { overviewSubscriptionsData, subscriptions },
  } = getState();

  request
    .update(`tools/subscriptions/${subscriptionId}`, {
      ...data,
    })
    .then(({ data: { records } }) => {
      const updatedOverview = { ...overviewSubscriptionsData, ...records };
      const updatedSubscription = subscriptions.map((item) => {
        if (item.id === subscriptionId) {
          return { ...item, ...records };
        }

        return item;
      });

      dispatch(showSubscriptionOverview(updatedOverview));
      dispatch(setSubscriptions(updatedSubscription));

      getErrorStatusMessage({
        status: 200,
        message: `Purpose successfully updated`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });

      console.error(e);
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
    });
};
