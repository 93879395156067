import { Fragment, useState } from 'react';
import { Button, Checkbox } from '@heathmont/moon-core';
import { ControlsChevronUpSmall, ControlsChevronDownSmall } from '@heathmont/moon-icons';
import {
  formatDateAndTime,
  getBooleanLabel,
} from '../../utils/tableHelpers';
import { ITripPaymentRequests } from './types';
import {
  ActionButtonsWrapper,
  MoreWrapper,
  MoreText,
} from './styles';
import {
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
  BlockSubTitle,
} from '../TripsOverviewDrawerContent/styles';
import CopyToClipboard from '../CopyToClipboard';
import { formatFlightTitle } from '../RequestPaymentDialog/utils';
import { requestPaymentReasonOptions } from '../../enums/RequestPaymentReason';
import InfoBlock from '../InfoBlock';
import { isUserMakeDeposit } from './utils';
import { N_A } from '../../utils/commonFunctions';

const MAX_PAYMENT_REQUESTS_TO_SHOW_BY_DEFAULT = 1;

const TripPaymentRequests: React.FC<ITripPaymentRequests> = ({
  paymentRequests,
  flights,
  tripId,
  handleReSendRequest,
}) => {
  const [isMoreData, setIsMoreData] = useState({
    itemsToShow: MAX_PAYMENT_REQUESTS_TO_SHOW_BY_DEFAULT,
    isExpanded: false,
  });

  const showMore = () => {
    isMoreData.itemsToShow === MAX_PAYMENT_REQUESTS_TO_SHOW_BY_DEFAULT
      ? setIsMoreData({ itemsToShow: paymentRequests.length, isExpanded: true })
      : setIsMoreData({ itemsToShow: MAX_PAYMENT_REQUESTS_TO_SHOW_BY_DEFAULT, isExpanded: false });
  };

  return (
    <>
      {paymentRequests.slice(0, isMoreData.itemsToShow).map(paymentRequest => {
        const flight = flights.find(flight => flight.id === paymentRequest.flightId);
        const reason = requestPaymentReasonOptions.find(reason => reason.value === paymentRequest.reason);
        const withDeposit = isUserMakeDeposit(paymentRequest.payments);

        return (
          <Fragment key={paymentRequest.id}>
            <BlockSubTitle>
              Payment request ID {paymentRequest.id}
            </BlockSubTitle>

            {
              <OverviewContent>
                {!flight ? (
                  <InfoBlock text={'The flight for payment request was deleted. A payment link will not work anymore.'} />
                ) : (
                  <>
                    <OverviewContentName>Flight</OverviewContentName>
                    <OverviewContentValue>
                      {formatFlightTitle(flight)}
                    </OverviewContentValue>
                  </>
                )}
              </OverviewContent>
            }
            
            <OverviewContent>
              <OverviewContentName>Reason</OverviewContentName>
              <OverviewContentValue>
                {reason?.title || paymentRequest.reason}
              </OverviewContentValue>
            </OverviewContent>
            <OverviewContent>
              <OverviewContentName>Details</OverviewContentName>
              <OverviewContentValue>
                {paymentRequest.details}
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Passenger</OverviewContentName>
              <OverviewContentValue>
                {paymentRequest.passenger || N_A}
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Apply to all passengers</OverviewContentName>
              <OverviewContentValue>
                <Checkbox checked={!!paymentRequest.applyToAllPassengers} disabled />
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Sum, EUR</OverviewContentName>
              <OverviewContentValue>{paymentRequest.sum}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Link</OverviewContentName>
              <OverviewContentValue>
                <CopyToClipboard text={paymentRequest.link} />
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Status</OverviewContentName>
              <OverviewContentValue>
                {getBooleanLabel(withDeposit, { trueLabel: 'paid', falseLabel: 'not paid' })}
              </OverviewContentValue>
            </OverviewContent>
          
            <OverviewContent>
              <OverviewContentName>Created at</OverviewContentName>
              <OverviewContentValue>{formatDateAndTime(paymentRequest.createdAt, true)}</OverviewContentValue>
            </OverviewContent>
            
            <OverviewContent>
              <OverviewContentName>Last sent at</OverviewContentName>
              <OverviewContentValue>{formatDateAndTime(paymentRequest.lastSentAt, true)}</OverviewContentValue>
            </OverviewContent>

            {
              !!flight && !withDeposit && (
                <OverviewContent>
                  <ActionButtonsWrapper>
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={() => handleReSendRequest(tripId, paymentRequest.id)}
                    >
                      Resend
                    </Button>
                  </ActionButtonsWrapper>
                </OverviewContent>
              )
            }
          </Fragment>
        );
      })}
      {paymentRequests.length >MAX_PAYMENT_REQUESTS_TO_SHOW_BY_DEFAULT && (
        <MoreWrapper onClick={showMore}>
          <MoreText>{isMoreData.isExpanded ? 'Less payment requests' : 'More payment requests'}</MoreText>

          {isMoreData.isExpanded ? <ControlsChevronUpSmall /> : <ControlsChevronDownSmall />}
        </MoreWrapper>
      )}
    </>  
  );
};

export default TripPaymentRequests;
