import styled from 'styled-components';
import { Button } from '@heathmont/moon-core';

export const ReceiptWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  max-height: 450px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  padding: 0 50px;
`;

export const SliderArrow = styled(Button).attrs({
  variant: 'ghost',
  size: 'large',
})`
  position: absolute;
  top: calc(50% - 25px);
  border-radius: 50%;
`;

export const ArrowRight = styled(SliderArrow)`
  right: 10px; 
`;

export const ArrowLeft = styled(SliderArrow)`
  left: 10px; 
`;

export const ReceiptsCount = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: -20px;
`;