import { EXPENSES_TYPE } from '../../../enums/ExpensesType';
import { Trip } from '../../../enums/Trip';

export const shouldExpensesCostsBeShow = (expensesStatus: EXPENSES_TYPE | null) => {
  return [ EXPENSES_TYPE.CHECKED, EXPENSES_TYPE.TO_BE_PAID, EXPENSES_TYPE.REFUNDED ].includes(expensesStatus);
};

const formatTotalCost = (totalCost: number) => {
  return totalCost.toFixed(2);
};

export const calculateExpenseReportTotalCost = (trip: Trip) => {
  const totalCost = trip.expenses.reduce((acc, { costInEUR }) => {
    const parsedPrice = parseFloat(costInEUR);
    acc += isNaN(parsedPrice) ? 0 : parsedPrice;
    return acc;
  }, 0);
  return formatTotalCost(totalCost);
};

export const calculateTransportsTotalCost = (trip: Trip) => {
  const totalCost = [...trip.flights, ...trip.otherTransports].reduce((acc, { price }) => {
    const parsedPrice = parseFloat(price);
    acc += isNaN(parsedPrice) ? 0 : parsedPrice;
    return acc;
  }, 0);
  return formatTotalCost(totalCost);
};

export const calculateAccommodationsTotalCost = (trip: Trip) => {
  const totalCost = trip.accommodations.reduce((acc, { price }) => {
    const parsedPrice = parseFloat(price);
    acc += isNaN(parsedPrice) ? 0 : parsedPrice;
    return acc;
  }, 0);
  return formatTotalCost(totalCost);
};
