import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { EXTERNAL_REFERENCE_PREFIX, } from '../../constants';
import {
  IExternalAccommodation,
  IExternalFlightSegment,
  IExternalFlight,
  IOtherTransport,
} from '../../components/AddExternalTripModalContent/types';
import { TripStatusUpdateFields } from '../../enums/TripStatusUpdateFields';
import { PassengerOption } from '../../enums/PassengerOption';
import { UserStatuses } from '../../enums/UserStatuses';
import { TripUser } from '../../enums/TripUser';
import { ExtraBag } from '../../enums/ExtraBag';

const normalizeReference = (value, isExternal = true) => {
  let reference = value ? value.trim() : value;

  if (!value) {
    return undefined;
  }

  if (isExternal) {
    reference = `${EXTERNAL_REFERENCE_PREFIX}${reference}`;
  }

  return reference
};

export const normalizeOtherTransport = (data: IOtherTransport) => {
  const { id, editMode, priceWithoutAdditionalPercent, reference, ...rest } = data;
  return { 
    ...rest,
    departureDate: moment(data.departureDate).format('YYYY-MM-DD HH:mm:ss'),
    arrivalDate: moment(data.arrivalDate).format('YYYY-MM-DD HH:mm:ss'),
    reference: normalizeReference(reference, true),
    price: +priceWithoutAdditionalPercent,
  }
};

export const normalizeAccommodation = (accommodationData: IExternalAccommodation) => {
  const { id, hotelName, editMode, priceWithoutAdditionalPercent, reference, ...rest } = accommodationData;
  return { 
    ...rest,
    ...(hotelName && {hotelName}),
    reference: normalizeReference(reference, true),
    checkOut: moment(accommodationData.checkOut).format('YYYY-MM-DD'),
    checkIn: moment(accommodationData.checkIn).format('YYYY-MM-DD'),
    price: priceWithoutAdditionalPercent,
  }
};

const isLocalId = (id: string | number) => {
  return String(id).startsWith('LOCAL');
};

const normalizeFlightSegmentAdditionalServices = (additionalServices: ExtraBag[]) => {
  return additionalServices.map((extraBag) => {
    const { passengerId, segmentId, quantity, weight, weightUnit, id } = extraBag;
    return {
      ...(!isLocalId(passengerId) && { passengerId }),
      ...(!isLocalId(segmentId) && { segmentId }),
      ...(!isLocalId(id) && { id }),
      weightUnit,
      quantity,
      weight,
    };
  });
};

export const normalizeFlightSegment = (flightSegmentData: IExternalFlightSegment) => {
  const { id, airline, airlineType, editMode, additionalServices, ...rest } = flightSegmentData;
  return { 
    ...rest,
    ...(!isLocalId(id) && { id }),
    additionalServices: additionalServices?.length > 0
      ? normalizeFlightSegmentAdditionalServices(additionalServices)
      : [],
    flightNumber: +flightSegmentData.flightNumber, 
    departureAt: moment(flightSegmentData.departureAt).format('YYYY-MM-DD HH:mm:ss'),
    airlineType: airlineType ? airlineType : 'internal',
    airlineId: airline,
    arrivalAt: moment(flightSegmentData.arrivalAt).format('YYYY-MM-DD HH:mm:ss'),
  };
};

export const normalizeFlight = (flightData: IExternalFlight, options: { isExternal: boolean }) => {
  const { id, editMode, priceWithoutAdditionalPercent, reference, ...rest } = flightData;
  return { 
    ...rest,
    itineraries: flightData.itineraries.reduce((acc, itinerary) => {
      if (itinerary.segments.length) {
        acc.push({
          segments: itinerary.segments.map((segment) => normalizeFlightSegment(segment)),
          id: itinerary.id,
        });
      };
      return acc;
    },[]),
    reference: normalizeReference(reference, options?.isExternal),
    price: priceWithoutAdditionalPercent,
  };
};

export const getTripMultiPassengersFlights = (trip) => {
  if (!trip.flights?.length) {
    return [];
  }
  
  return trip.flights
    .filter(({ isMultiPassengers }) => isMultiPassengers)
    .map(({ reference }) => reference);
};

export const getUserTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const isManagerApprovalRequired = (trip: TripStatusUpdateFields): boolean => {
  const isTravelerExternal = trip.userType === UserStatuses.External;
  const paymentRequired = trip.paymentRequired;
  return !isTravelerExternal && !paymentRequired;
};

export const generateLocalId = () => {
  return `LOCAL-${uuidv4()}`;
};

export const userToPassengerOption = (user: TripUser): PassengerOption => {
  return {
    value: generateLocalId(),
    title: `${user.firstname} ${user.lastname}`,
  };
};
