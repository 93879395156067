import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './AlertBlock.scss';

const AlertBlock = ({ message, stack }) => {
  const { t } = useTranslation();
  return (
    <div className="alertBlock">
      <div>
        {' '}
        <h2>{t('errors.default.header')}</h2>
        <p>{message}</p>
        {stack && process.env.NODE_ENV === 'development' ? <pre>{stack}</pre> : null}
      </div>
    </div>
  );
};

AlertBlock.propTypes = {
  message: PropTypes.string.isRequired,
  stack: PropTypes.string,
};

AlertBlock.defaultProps = {
  stack: null,
};

export default AlertBlock;
