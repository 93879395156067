import styled from 'styled-components';

export const DoubleFieldWrapper = styled.div`
  margin: ${({ margin }) => margin || 0};

  & div {
    & div { 
      &::after {
        z-index: 0;
      }

      & div {
        & div {
          & div {
            & div {
              & div:nth-child(2) {
                & div:nth-child(1) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CryptoWalletsWrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;