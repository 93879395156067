import Yup from '../../utils/validation';

const validateMessages = {
  description: {
    required: 'Description can not be empty',
    max: 'Description can not be longer than 255 characters',  
  },
  currency: {
    required: 'Currency can not be empty',
  },
  type: {
    required: 'Expense type can not be empty',
  },
  cost: {
    required: 'Amount can not be empty',
    min: 'Amount can not be negative',
    max: 'Amount can not greater than 999,999',
  },
  date: {
    required: 'Payment date can not be empty',
  },
};

export default Yup.object().shape({
  description: Yup
    .string()
    .required(validateMessages.description.required)
    .matches(/^(?!\s+$)/, validateMessages.description.required)
    .max(255, validateMessages.description.max),
  currency: Yup
    .string()
    .required(validateMessages.currency.required),
  type: Yup
    .string()
    .required(validateMessages.type.required),
  cost: Yup
    .number()
    .required(validateMessages.cost.required)
    .min(0, validateMessages.cost.min)
    .max(999999, validateMessages.cost.max),
  date: Yup
    .string()
    .required(validateMessages.date.required),
});
