import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_BAGGAGE_WEIGHT_UNIT } from '../../constants';
import { Baggage } from '../../enums/Baggage';
import { FLIGHT_TYPE } from '../../enums/FlightType';
import { IExternalFlight, IExternalFlightSegment } from './types';
import { PassengerOption } from '../../enums/PassengerOption';

export const formatAirports = (airports) => {
  return airports.map((airport) => ({
    title: `${airport.name} (${airport.code ? airport.code : ''})`,
    value: airport.code,
    city: airport.city,
  }));
};

const getInitialIncludedCheckedBagsValues = (includedCheckedBags?: Baggage) => {
  return {
    quantity: includedCheckedBags?.quantity
      ? includedCheckedBags?.quantity
      : null, 
    weight: includedCheckedBags?.weight
      ? includedCheckedBags?.weight
      : null,
    weightUnit: includedCheckedBags?.weightUnit
      ? includedCheckedBags?.weightUnit
      : DEFAULT_BAGGAGE_WEIGHT_UNIT,
  };
};

export const generateDefaultExtraBag = (passengers: PassengerOption[], segmentId: string | number) => {
  return {
    passengerId: passengers?.length === 1 
      ? passengers[0].value
      : null,
    weightUnit: DEFAULT_BAGGAGE_WEIGHT_UNIT,
    segmentId,
    quantity: null,
    weight: null,
    id: generateLocalId(),
  };
};

export const getSegmentInitialValues = (segmentData: IExternalFlightSegment) => {
  return {
    includedCheckedBags: getInitialIncludedCheckedBagsValues(segmentData?.includedCheckedBags),
    additionalServices: segmentData?.additionalServices?.length
      ? segmentData?.additionalServices
      : [],
    departureCode: segmentData?.departureCode ? segmentData.departureCode : null,
    flightNumber: segmentData?.flightNumber ? segmentData.flightNumber : null,
    departureAt: segmentData?.departureAt || null,
    arrivalCode: segmentData?.arrivalCode ? segmentData.arrivalCode : null,
    arrivalAt: segmentData?.arrivalAt || null,
    airline: segmentData?.airline ? segmentData.airline : null,
    meals: segmentData?.meals ? segmentData?.meals : null,
    cabin: segmentData?.cabin ? segmentData.cabin : null,
    id: segmentData?.id ? segmentData?.id : null,
  };
};

export const getInitialAirlines = (segmentData, selectedAirlines) => {
  const isEditSegmentMode = segmentData?.editMode;
  const airlineOptionsInitState = [];

  if (isEditSegmentMode) {
    const airline = selectedAirlines.find(({ value }) => +value === +segmentData.airline);

    if (airline){
      airlineOptionsInitState.push(airline);
    }
  }

  return airlineOptionsInitState;
};

const defineFlightType = (flightData: IExternalFlight) => {
  return flightData?.type ? flightData?.type : FLIGHT_TYPE.ROUND;
};

const formatItineraries = (flightData) => {
  if (flightData?.itineraries?.length) {
    return flightData.itineraries;
  }

  return [{ segments: [] }, { segments: [] }];
};

export const getFlightInitialValues = (flightData: IExternalFlight, currencies) => {
  const defaultCurrencyId = currencies.find((currency) => currency.default === 1)?.value;
  return {
    priceWithoutAdditionalPercent: flightData?.priceWithoutAdditionalPercent ? flightData.priceWithoutAdditionalPercent : null,
    itineraries: formatItineraries(flightData),
    reference: flightData?.reference ? flightData.reference : null,
    currency: flightData?.currency ? flightData.currency : defaultCurrencyId,
    type: defineFlightType(flightData),
    id: flightData?.id ? flightData.id : null,
  };
};

export const generateLocalId = () => {
  return `LOCAL-${uuidv4()}`;
};

const formatAccommodationDate = (date) => {
  return  moment.utc(date).format('YYYY-MM-DD');
};

export const getAccommodationInitialValues = (accommodation, currencies) => {
  const defaultCurrencyId = currencies.find((currency) => currency.default === 1)?.value;
  return {
    priceWithoutAdditionalPercent: accommodation?.priceWithoutAdditionalPercent
      ? accommodation.priceWithoutAdditionalPercent
      : null,
    reference: accommodation?.reference ? accommodation.reference : null,
    hotelName: accommodation?.hotelName ? accommodation.hotelName : '',
    currency: accommodation?.currency ? accommodation.currency : defaultCurrencyId,
    checkOut: accommodation?.checkOut
      ? formatAccommodationDate(accommodation.checkOut)
      : null,
    hotelId: accommodation?.hotelId ? accommodation.hotelId : null,
    checkIn: accommodation?.checkIn
      ? formatAccommodationDate(accommodation.checkIn)
      : null,
    price: accommodation?.price ? accommodation.price : null,
    city: accommodation?.regionId
      ? accommodation.regionId
      : accommodation?.city
        ? accommodation.city
        : null,
    id: accommodation?.id ? accommodation.id : null,
  };
};

export const preparePassengersOptions = (traveler: number, users: any[]): PassengerOption[] => {
  return users
    .filter(companyUser => companyUser.value === traveler)
    .map(companyUser => ({ title: companyUser.title, value: generateLocalId() }));
};
