import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBgNew';
import { SuccessMessage } from '../../components/SuccessMessage';
import SignUpCompanyForm from '../../components/SignUpCompanyForm';
import SignUpCompanyError from '../../components/SignUpCompanyError';
import { getFormData, getInitialValues } from './suggestions';
import { ISignUpCompany } from './types';
import {
  setActiveTab,
  setSignUpCompanyError,
  setSignUpUrlError,
  setSignUpData,
} from '../../store/signUpCompany/actions';

const SignUpCompany: React.FC<ISignUpCompany> = ({ history: { push } }) => {
  const [checkedTabs, setCheckedTabs] = useState([]);

  const dispatch = useDispatch();

  const { search } = useLocation();

  const { code } = queryString.parse(search);

  useEffect(() => {
    if (!code) {
      dispatch(setSignUpUrlError(true));
      dispatch(
        setSignUpCompanyError(
          true,
          'Url is not valid, please check the link carefully.',
          'Registration URL is not valid'
        )
      );
      return;
    }
  }, []);

  const successSignUpCompany = useSelector((state: any) => state.signUpCompanyReducer.successSignUpCompany);
  const activeTab = useSelector((state: any) => state.signUpCompanyReducer.activeTab);
  const savedFormData = useSelector((state: any) => state.signUpCompanyReducer.formData);
  const submitError = useSelector((state: any) => state.signUpCompanyReducer.submitError);
  const linkError = useSelector((state: any) => state.signUpCompanyReducer.linkError);

  const initialValues = useMemo(() => getInitialValues(savedFormData), [savedFormData]);

  const handleSubmit = useCallback(
    (values) => {
      const formData = getFormData(values, code);

      dispatch(setSignUpData(formData, values));
    },
    [code]
  );

  const renderForm = () => {
    if (successSignUpCompany) {
      return (
        <SuccessMessage
          message=""
          link="/signin"
          linkText="Login"
          isExternal
        />
      );
    }

    if (!submitError.isError) {
      return (
        <SignUpCompanyForm
          initialValues={initialValues}
          checkedTabs={checkedTabs}
          handleSubmit={handleSubmit}
          handleBack={() => dispatch(setActiveTab(activeTab - 1))}
          handleNext={() => {
            dispatch(setActiveTab(activeTab + 1));
            setCheckedTabs((prev) => [...prev, activeTab]);
          }}
        />
      );
    }

    return (
      <SignUpCompanyError
        errorMessage={submitError.message}
        heading={submitError.header}
        linkError={linkError}
        setErrorData={setSignUpCompanyError}
        push={push}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Company sign up</title>
      </Helmet>

      <FullScreenRandomBg renderComponent={renderForm()} />
    </>
  );
};

export default SignUpCompany;
