import moment from 'moment';
import {
  DEFAULT_BAGGAGE_WEIGHT_UNIT,
  EXTERNAL_REFERENCE_PREFIX,
} from '../../../constants';
import { Passenger } from '../../../enums/Passenger';
import { ExtraBag } from '../../../enums/ExtraBag';

export const extractTimeZone = (date) => {
  const withoutTimeZone = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  return new Date(withoutTimeZone);
};

export const normalizeMealsForSegment = (meals = []) => {
  return Array.isArray(meals) 
    ? meals.map(item => item?.label).join(', ')
    : null;
};

const formatIncludedCheckedBags = (includedCheckedBags) => {
  return {
    quantity: includedCheckedBags?.quantity
      ? includedCheckedBags?.quantity
      : null, 
    weight: includedCheckedBags?.weight
      ? includedCheckedBags?.weight
      : null,
    weightUnit: includedCheckedBags?.weightUnit
      ? includedCheckedBags?.weightUnit
      : DEFAULT_BAGGAGE_WEIGHT_UNIT,
  };
};

const extractAdditionalServicesBySegment = (segmentId, passengers: Passenger[]): ExtraBag[] => {
  const additionalServices = [];

  passengers.forEach(passenger => {
    passenger.additionalServices.forEach(extraBag => {
      if (extraBag.segmentId === segmentId) {
        additionalServices.push(extraBag);
      }
    });
  });

  return additionalServices;
};

export const formatFlightSegmentForForm = (segment, passengers) => {
  return {
    includedCheckedBags: formatIncludedCheckedBags(segment?.includedCheckedBags),
    additionalServices: extractAdditionalServicesBySegment(segment.id, passengers),
    departureCode: segment.departure.code,
    flightNumber: segment.number,
    arrivalCode: segment.arrival.code,
    departureAt: extractTimeZone(segment.departure.timestamp),
    arrivalAt: extractTimeZone(segment.arrival.timestamp),
    airline: segment.airline.id,
    cabin: segment?.cabin ? segment.cabin : null,
    meals: segment?.meals ? normalizeMealsForSegment(segment.meals) : null,
    id: segment.id,
  };
};

const formatFlightPrice = (flight) => {
  return flight.priceMeta
    ? JSON.parse(flight.priceMeta).priceWithoutAdditionalPercent
    : null;
};

const formatReference = (flight) => {
  return flight.reference.replace(new RegExp(EXTERNAL_REFERENCE_PREFIX), '')
};

const formatItineraries = (flight) => {
  return flight.itineraries.reduce((itineraries, itinerary) => {
    const segments = itinerary.segments.map((segment) => formatFlightSegmentForForm(segment, flight.passengers));
    return [ ...itineraries, { segments, id: itinerary.id } ];
  }, []);
};

export const formatFlightDataForForm = (flight) => {
  return {
    priceWithoutAdditionalPercent: formatFlightPrice(flight),
    itineraries: formatItineraries(flight),
    reference: formatReference(flight),
    currency: flight.currency.id,
    price: flight.price,
    type: flight.type,
    id: flight.id,
  };
};
