import styled from 'styled-components';
import { rem } from '@heathmont/moon-utils';

export const TooltipWrapper = styled.div(({ theme }) => ({
  display: 'inline-block',
  color: theme.colorNew.goten,
  background: theme.colorNew.gohan,
  fontSize: rem(12),
  lineHeight: rem(16),
  padding: rem(12),
  borderRadius: rem(4),
  position: 'relative',
  boxShadow: theme.newTokens.boxShadow.large,
  pointerEvents: 'none',
}));

export const Item = styled.div(({ theme }) => ({
  position: 'relative',
  padding: `${rem(2)} 0 ${rem(2)} ${theme.newTokens.space.small}`,
  color: theme.colorNew.trunks,
}));

export const Date = styled.div(({ theme }) => ({
  padding: `${rem(2)} 0`,
  color: theme.colorNew.trunks,
}));

export const ColorPreview = styled.div({
  width: rem(8),
  height: rem(8),
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
});

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;
