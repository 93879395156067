import styled from 'styled-components';

export const RowActionsWrapper = styled.div`
  display: flex;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  font-size: 24px;
  color: ${({ theme }) => theme.color.text['100']};

  &:hover {
    background: linear-gradient(0deg, rgba(24, 27, 28, 0.12), rgba(24, 27, 28, 0.12)), #fce9b0;
    cursor: pointer;
  }
`;

export const ActionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ActionGhostContent = styled.span`
  color: transparent;
  width: 0;
`;

export const ActionTooltipWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  z-index: 15;
`;