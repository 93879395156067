import { CompanyFormTabType } from '../../enums/FormTabType';

export const getInitialValues = (data) => ({
  details: {
    feedbackChannelName: data?.details?.feedbackChannelName ?? '',
    accountantsChannel: data?.details?.accountantsChannel ?? '',
    travelGuideline: data?.details?.travelGuideline ?? '',
    contactEmail: data?.details?.contactEmail ?? '',
    companyName: data?.details?.companyName ?? '',
  },
  teams: data?.teams ?? [''],
  employers: data?.employers ?? [''],
  managers: data?.managers ?? [''],
  integrations: {
    jira: data?.integrations?.jira ?? null,
    hibob: data?.integrations?.hibob ?? null,
  },
});

export const getFormData = (data, code) => ({
  basicInfo: {
    travelopsesChannelName: data.details.travelopsesChannelName,
    accountantsChannelName: data.details.accountantsChannelName,
    feedbackChannelName: data.details.feedbackChannelName,
    travelGuideline: data.details.travelGuideline,
    contactEmail: data.details.contactEmail,
    name: data.details.companyName,
  },
  employers: data.employers,
  admins: data.managers,
  teams: data.teams,
  code,
  jira: !!data.integrations.jira
    ? {
        accessToken: data.integrations.jira.accessToken,
        project: data.integrations.jira.projectKey,
        email: data.integrations.jira.adminEmail,
        host: data.integrations.jira.host,
      }
    : null,
  hibob: !!data.integrations.hibob
    ? {
        accessToken: data.integrations.hibob.accessToken,
        accessId: data.integrations.hibob.accessId,
      }
    : null,
});

export const TabHelperText = {
  teams:
    'Add your team in order to see other users in your team. Each team has Travelops who will approve their bookings.',
  employers: 'Add your employer to see trip statistics and more.',
  travelManagers:
    'Travel manager issues trip requests and checks expense reports. Please add Travel manager here! You can also skip this step and add one later.',
  integrations: {
    jira: 'Add your admin email address, password, host and project key in order to integrate your jira with our BO. Weekly report task will be created automatically.',
    hibob: 'Connect to Hibob to update employee’s data automatically.',
  },
};

export const getIsDisabledNext = (errors, currentTab) => {
  switch (currentTab) {
    case CompanyFormTabType.AccountDetails: {
      if (!errors?.details) {
        return false;
      }

      return true;
    }
    case CompanyFormTabType.Teams: {
      if (!errors?.teams) {
        return false;
      }

      return true;
    }
    case CompanyFormTabType.Employers: {
      if (!errors?.employers) {
        return false;
      }

      return true;
    }
    case CompanyFormTabType.Managers: {
      if (!errors?.managers) {
        return false;
      }

      return true;
    }
    case CompanyFormTabType.Integrations: {
      if (!errors?.integrations) {
        return false;
      }

      return true;
    }

    default:
      return true;
  }
};

export const addJiraFields = (setFieldValue) => {
  setFieldValue('integrations.jira.accessToken', '');
  setFieldValue('integrations.jira.projectKey', '');
  setFieldValue('integrations.jira.adminEmail', '');
  setFieldValue('integrations.jira.host', '');
};
