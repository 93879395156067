import styled from 'styled-components';

export const InfoBlockWrapper = styled.div`
  padding: 12px 16px;
  background: rgba(253, 239, 196, 0.38);
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  ${({ withoutMargin }) => !withoutMargin && `
    margin-bottom: 16px;
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const InfoBlockText = styled.span`
  width: calc(100% - 36px);
  font-size: 14px;
  line-height: 24px;
`;

export const IconWrapper = styled.span`
  width: 24px;
`;
