import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const Header = styled(Text).attrs({
  size: 32,
  color: 'bulma.100',
})`
  line-height: 40px;
  margin: 0 0 32px 0;
`;

export const NotificationWrapper = styled.div`
  position: absolute;
  top: 3%;
  width: 88%;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 16px;
  background: ${({ isUpdateError }) => (isUpdateError ? '#fddada' : '#dafde9')};
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);

  & svg {
    cursor: pointer;
  }
`;

export const NotificationText = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const Footer = styled.div`
  height: 65px;
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #ffffff;

`;

export const ButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const RestStepText = styled(Text).attrs({
  size: 16,
  color: 'trunks.100',
})`
  line-height: 24px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
