import { useState, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { OtherPlug } from '@heathmont/moon-icons';
import { TextInput, Button, Loader } from '@heathmont/moon-core';
import request from '../../models/request';
import { N_A, getErrorStatusMessage } from '../../utils/commonFunctions';
import FormField from '../FormField';
import EllipsisText from '../EllipsisText';
import CustomSelect from '../CustomSelect';
import FormActions from './FormActions';
import TokenField from '../TokenField';
import EditButton from './EditButton';
import InfoBlock from '../InfoBlock';
import { ICompanyJiraIntegration } from './types';
import {
  OverviewContentValueSelect,
  OverviewContentWrapper,
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
} from './styles';
import { jiraIntegrationSchema } from './schema';


const getInitialValues = (jira) => ({
  participantsFieldName: jira?.participantsFieldName || '',
  seniorAccountant: jira?.seniorAccountant?.id || null,
  travelManager: jira?.travelManager?.id || null,
  accessToken: jira?.accessToken || '',
  project: jira?.project || '',
  email: jira?.email || '',
  host: jira?.host || '',
});

const CompanyJiraIntegration: React.FC<ICompanyJiraIntegration> = ({
  accountants,
  companyId,
  users,
  jira,
  updateJiraIntegration,
  deleteJiraIntegration,
  addJiraIntegration,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCheckingConnectionLoading, setIsCheckingConnectionLoading] = useState(false);
  const initialValues = useMemo(() => getInitialValues(jira), [jira]);

  useEffect(() => {
    setIsEditMode(false);
  }, [companyId]);

  const onCancel = (resetForm) => {
    setIsEditMode(false);
    resetForm();
  };

  const onDelete = (resetForm) => {
    setIsEditMode(false);
    resetForm();
    deleteJiraIntegration(companyId);
  };

  const onSubmit = (values, { resetForm }) => {
    setIsEditMode(false);
    resetForm();

    if (jira?.accessToken) {
      return updateJiraIntegration(companyId, values);
    } else {
      return addJiraIntegration(companyId, values);
    }
  };

  const toEditMode = () => {
    setIsEditMode(true)
  };

  const testConnectionJira = async (companyId) => {
    try {
      setIsCheckingConnectionLoading(true);
      await request.get(`tools/companies/test-connection/jira/${companyId}`);
      setIsCheckingConnectionLoading(false);
      getErrorStatusMessage({
        status: 200,
        message: `Connection established!`,
      });
    } catch (err) {
      setIsCheckingConnectionLoading(false);
      getErrorStatusMessage({
        status: err?.response?.status,
        message: 'Connection could not be established!',
      });
    }
  };

  if (!isEditMode && !jira?.accessToken) {
    return (
      <InfoBlock
        text={`
          Add your admin email address, password, host and project key in order to integrate your jira with our BO. Weekly report task will be created automatically.
        `}
        onClick={toEditMode}
      />
    );
  }

  return (
    <OverviewContentWrapper>
      {!isEditMode && <EditButton onClick={toEditMode} />}

      <Formik
        enableReinitialize
        validationSchema={jiraIntegrationSchema}
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ errors, values, resetForm, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <OverviewContent>
                <OverviewContentName>Admin email</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="email"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="email"
                      errors={errors}
                      label="Admin email"
                      value={values.email}
                      type="email"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={jira?.email}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Access token</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="accessToken"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="accessToken"
                      errors={errors}
                      label="Access token"
                      value={values.accessToken}
                      type="password"
                      onChange={(e) => setFieldValue('accessToken', e.target.value)}
                    />
                  ) : (
                    <OverviewContentValue>
                      <TokenField token={jira?.accessToken}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Host</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="host"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="host"
                      errors={errors}
                      label="Host"
                      value={values.host}
                      type="text"
                      onChange={(e) => setFieldValue('host', e.target.value)}
                    />
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={jira?.host}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Project key</OverviewContentName>
                {
                  isEditMode ? (
                    <FormField
                      fieldName="project"
                      component={TextInput}
                      inputSize="xlarge"
                      fieldId="project"
                      errors={errors}
                      label="Project key"
                      value={values.project}
                      type="text"
                      onChange={(e) => setFieldValue('project', e.target.value)}
                    />
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={jira?.project}/>
                    </OverviewContentValue>
                  ) 
                }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Travel Manager</OverviewContentName>
                  {
                    isEditMode ? (
                      <OverviewContentValueSelect>
                        <CustomSelect
                          currentValue={values.travelManager}
                          isSearchable
                          isClearable
                          placeholder="Travel Manager"
                          error={errors?.travelManager}
                          items={users}
                          onChange={(travelManager) => setFieldValue('travelManager', travelManager)}
                        />
                      </OverviewContentValueSelect>
                    ) : (
                      <OverviewContentValue>
                        <EllipsisText text={jira?.travelManager?.fullName || N_A}/>
                      </OverviewContentValue>
                    ) 
                  }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Senior Accountant</OverviewContentName>
                {
                  isEditMode ? (
                    <OverviewContentValueSelect>
                      <CustomSelect
                        currentValue={values.seniorAccountant}
                        isSearchable
                        isClearable
                        placeholder="Senior Accountant"
                        error={errors?.seniorAccountant}
                        items={accountants}
                        onChange={(seniorAccountant) => setFieldValue('seniorAccountant', seniorAccountant)}
                      />
                    </OverviewContentValueSelect>
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={jira?.seniorAccountant?.fullName || N_A}/>
                    </OverviewContentValue>
                  ) 
                  }
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Participants field name</OverviewContentName>
                {
                  isEditMode ? (
                    <OverviewContentValueSelect>
                      <FormField
                        fieldName="participantsFieldName"
                        component={TextInput}
                        inputSize="xlarge"
                        fieldId="participantsFieldName"
                        errors={errors}
                        label="Participants field name"
                        value={values.participantsFieldName}
                        type="text"
                        onChange={(e) => setFieldValue('participantsFieldName', e.target.value)}
                      />
                    </OverviewContentValueSelect>
                  ) : (
                    <OverviewContentValue>
                      <EllipsisText text={jira?.participantsFieldName || N_A}/>
                    </OverviewContentValue>
                  ) 
                  }
              </OverviewContent>

              {isEditMode ? (
                <FormActions
                  errors={errors}
                  onCancel={() => onCancel(resetForm)}
                  onDelete={jira?.accessToken ? () => onDelete(resetForm) : null}
                />
              ) : (
                <Button
                  disabled={isCheckingConnectionLoading}
                  variant="ghost"
                  type="button"
                  onClick={() => testConnectionJira(companyId)}
                >
                  {isCheckingConnectionLoading ? <Loader size="twoxsmall" color="trunks.100" />: <OtherPlug />}
                  Test connection
                </Button>
              )}
            </Form>
          )
        }}
      </Formik>
    </OverviewContentWrapper>
  );
};

export default CompanyJiraIntegration;
