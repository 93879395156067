import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA,
  SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL,
  SET_TOOLS_EMPLOYERS_CHECKBOX_DATA,
  SET_TOOLS_ALL_COMPANIES_DATA,
  CLOSE_DELETE_EMPLOYERS_MODAL,
  SHOW_DELETE_EMPLOYERS_MODAL,
  SET_TOOLS_EMPLOYERS_DATA,
  CLOSE_EMPLOYER_OVERVIEW,
  SHOW_EMPLOYER_OVERVIEW,
} from './actionTypes';

const setEmployersData = (data) => ({ type: SET_TOOLS_EMPLOYERS_DATA, payload: data });

export const showEmployerOverview = (employer) => {
  return { type: SHOW_EMPLOYER_OVERVIEW, payload: { data: employer } };
};

export const closeEmployerOverview = () => {
  return { type: CLOSE_EMPLOYER_OVERVIEW };
};

export const fetchToolsEmployers = (params: any) => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .get('tools/employers', { params })
    .then(({ data }) => dispatch(setEmployersData(data)))
    .catch((e) => console.error(e))
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const showDeleteEmployersModal = (data: any[] | any) => {
  const deletableEmployers = data?.length ? data.map((item) => item.employerId) : [data.employerId];
  return { type: SHOW_DELETE_EMPLOYERS_MODAL, payload: { deletableEmployers } };
};

export const closeDeleteEmployersModal = () => {
  return { type: CLOSE_DELETE_EMPLOYERS_MODAL };
};

export const setEmployersCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_EMPLOYERS_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllEmployersCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setAllCompanies = (data) => {
  return { type: SET_TOOLS_ALL_COMPANIES_DATA, payload: { data } };
};

export const fetchAllCompanies = () => (dispatch) => {
  request
    .get('/tools/companies')
    .then(({ data }) => dispatch(setAllCompanies(data.records)))
    .catch((e) => console.error(e));
};

export const addToolsNewEmployer = (data, params) => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .post('tools/employers', data)
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: `New employer successfully added`,
      });

      dispatch(fetchToolsEmployers(params));
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    })
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const setIsOpenAddNewEmployer = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL, payload: isOpen };
};

export const setIsUpdatedEmployer = (isUpdated = false) => {
  return { type: SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA, payload: { isUpdated } };
};

export const updateEmployer = (employerId, data) => (dispatch) => {
  dispatch(setIsUpdatedEmployer(true));

  request
    .update(`/tools/employers/${employerId}`, { ...data, name: data.name.trim() })
    .then(() => {
      dispatch(closeEmployerOverview());

      getErrorStatusMessage({
        status: 200,
        message: `Employer ID - ${employerId} successfully updated.`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsUpdatedEmployer(false));
    });
};
