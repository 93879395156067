import { formatAccommodationGuests, getLabels } from '../../utils/tableHelpers';
import { IBookingForIgnoreOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem } from './styles';
import { useMemo } from 'react';

const BookingForIgnoreOverviewDrawerContent: React.FC<IBookingForIgnoreOverviewDrawerContent> = ({
  data,
}) => {
  const guests = useMemo(() => {
    return formatAccommodationGuests(data);
  }, [data?.guests]);

  return (
    <div>
      <OverviewItemsWrapper>
        <OverviewItemName>ID</OverviewItemName>
        <OverviewItem>{data?.id}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Reference</OverviewItemName>
        <OverviewItem>{data?.reference}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Guests</OverviewItemName>
        <OverviewItem>
          {getLabels(guests, 'fullName')}
        </OverviewItem>
      </OverviewItemsWrapper>
    </div>
  );
};

export default BookingForIgnoreOverviewDrawerContent;
