import { useCallback, useState } from 'react';
import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  Legend as RechartLegend,
} from 'recharts';
import Legend from '../Components/Legend';
import { Tooltip } from '../Components/Tooltip';
import { ILineChart } from './types';
import { Wrapper, Header } from './styles';
import { LeftChartSign, BottomChartSign, HeaderWrapper, Period } from '../BarChart/styles';
import { getPeriodName } from '../suggestions';

const LineChart: React.FC<ILineChart> = ({ chartData, options, periodType, periodRange }) => {
  const [isHovered, setIsHoveredItem] = useState(false);

  const setIsHovered = useCallback(() => {
    setIsHoveredItem((prev) => !prev);
  }, []);

  const initialActiveOptions = options.filter(({ isActive }) => isActive).map(({ dataKey }) => dataKey);

  const [activeOptions, setActiveOptions] = useState(initialActiveOptions);

  const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={15} y={0} dy={16} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-35} y={-12} dy={16} fill="#666" fontSize={12} textAnchor="start">
          {payload.value}
        </text>
      </g>
    );
  };

  const handleSelectorChange = (dataKey: string, isActive: boolean) => {
    const newActiveOptions = isActive
      ? [...activeOptions, dataKey]
      : activeOptions.filter((option) => option !== dataKey);

    setActiveOptions(newActiveOptions);
  };

  const displayLines = useCallback(
    () =>
      activeOptions.map((option) => {
        const activeOption = options.find(({ dataKey }) => dataKey === option);

        if (!activeOption) {
          return null;
        }

        return (
          <Line
            type="linear"
            key={activeOption.dataKey}
            dataKey={activeOption.dataKey}
            name={activeOption.label}
            stroke={activeOption.color}
            yAxisId={activeOption.yAxisId}
            dot={false}
          />
        );
      }),
    [activeOptions]
  );

  return (
    <Wrapper onMouseEnter={setIsHovered} onMouseLeave={setIsHovered}>
      <HeaderWrapper>
        <Header>Numbers</Header>
        <Period>{getPeriodName(periodType, periodRange)}</Period>
      </HeaderWrapper>

      {isHovered && (
        <>
          <LeftChartSign>Count</LeftChartSign>
          <BottomChartSign>Time (days)</BottomChartSign>
        </>
      )}

      <ResponsiveContainer height="80%" minWidth={645}>
        <RechartLineChart
          data={chartData}
          margin={{
            top: 20,
            right: 60,
            left: -15,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" height={60} tick={<CustomizedXAxisTick />} />
          <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick />} />
          <RechartTooltip content={<Tooltip isLineChart />} />

          <RechartLegend
            verticalAlign="bottom"
            align="center"
            content={<Legend onChange={handleSelectorChange} options={options} activeOptions={activeOptions} />}
          />

          {displayLines()}
        </RechartLineChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default LineChart;
