/* eslint-disable react/prop-types */
import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Applicable to',
    accessor: 'company.name',
  },
  {
    Header: 'Currency Code',
    accessor: 'currency.code',
  },
  {
    Header: 'Number Of Options',
    accessor: 'numberOfOptions',
  }
];