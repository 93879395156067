import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const ContentWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

export const TripOverviewWrapper = styled.div`
  display: flex;
  background: #f6f5f4;
  border-radius: 16px;
  width: 100%;
  min-height: 64px;
  height: 100%;
  margin-bottom: 10px;
  padding: 12px;

  &:hover {
    cursor: pointer;
    background-color: rgb(234, 234, 234);
  }
`;

export const TripInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const TravelerAndTripType = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const TripType = styled.div`
  display: flex;
`;

export const TravelerFullName = styled(MoonText).attrs({
  size: 14,
  color: '#181B1C',
})`
  line-height: 18px;
  font-weight: 400;
`;

export const TripCityAndDates = styled(MoonText).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 14px;
  font-weight: 400;
`;

export const PurposeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto; 
  margin-right: 0;
`;

export const ModalCheckboxWrapper = styled.div`
  margin-top: 15px;
`;

export const TripRadioButtonWrapper = styled.div`
  padding-top: 8px;
  display: flex;
`;

export const TTRLinkWrapper = styled(MoonText).attrs({
  size: 12,
  color: '#78787D',
})`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TTRFieldName = styled.span`
  size: 12px;
  color: #78787D;
  font-weight: 400;
  line-height: 14px;
  font-weight: 400;
`;

export const TTRFieldValue = styled.span`
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  font-size: 12px;
  line-height: 14px;
  color: #181b1c;
  text-align: start;
  width: calc(55% - 2.5px);
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 0.01px black;
  }
`;

export const TripInfoBlockTitle = styled(MoonText).attrs({
  size: 20,
  color: '#181B1C',
})`
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const PurposeDetailsWrapper = styled(MoonText).attrs({
  size: 14,
  color: '#78787D',
})`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #78787D;
  font-weight: 400;
  line-height: 14px;
  font-weight: 400;
`;

export const PurposeDetailsField = styled.span`
  text-wrap: nowrap;
`;

export const PurposeDetailsValue = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  align-self: ${({ isTraveler }) => (isTraveler ? 'center' : 'start')};
`;