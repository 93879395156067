import moment from 'moment';
import airports from 'airport-timezone';
import cronstrue from 'cronstrue';
import momentTimezone from 'moment-timezone';
import mime  from 'mime-types';
import { toast, TypeOptions } from 'react-toastify';
import { isEqual } from 'lodash';
import { CurrentUser } from '../enums/CurrentUser';
import { getErrorMessages } from './errors';

export const convertTimeToFlightLocalTime = (date, iata) => {
  const withoutTimeZone = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  const airport = airports.find((airport) => airport.code.toUpperCase() === iata.toUpperCase());
  let timezone;

  if (airport && airport.timezone) {
    timezone = airport.timezone;
  }

  return momentTimezone.tz(withoutTimeZone, timezone).toString();
};

export const getFlightDuration = (endDate, startDate) => {
  const date = moment.duration(moment(endDate).diff(moment(startDate)));
  const days = date.days();
  const hrs = date.hours();
  const mins = date.minutes();
  let duration = '';

  if (days) duration = `${days}d`;
  if (hrs) duration += ` ${hrs}h`;
  if (mins) duration += ` ${mins}m`;

  return duration;
};

export const getUserInitials = (currentUserData: CurrentUser): string =>
  `${currentUserData?.user?.firstname[0]}${currentUserData?.user?.lastname[0]}`;

export const N_A = 'N/A';

export const formatTotalPrice = (price: number): string => {
  const [natural, decimal] = String(Math.round(price * 100) / 100).split('.');

  const decimalFormatted = decimal?.padEnd(2, '0');

  return `${natural}.${decimalFormatted ?? '00'}`;
};

export const setTimeToDate = (date, time = '12:00:00') => {
  const dateWithoutTime = moment.utc(date).format('YYYY-MM-DD');
  return moment.utc(`${dateWithoutTime} ${time || '12:00:00'}`).toString();
};

export const getErrorStatusMessage = (data) => {
  const { status, message } = data;

  switch (status) {
    case 200:
      return toast.success(message);
    case 422:
      const errorMessages = getErrorMessages(message);
      return toast.error(errorMessages.join(' '));
    case 403:
      return toast.error('Access forbidden');
    case 500:
      return toast.error('Something went wrong. Try again later');
    default:
      return toast.error('Something went wrong');
  }
};

export const showToast = (message, type: TypeOptions = 'error') => {
  return toast(message, { type });
};

export const strToUpperCase = (str) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const formatStringToNormal = (str) => {
  if (!str) return str;

  const updatedStr = str.toLowerCase();

  return updatedStr[0].toUpperCase() + updatedStr.slice(1);
};

export const getHomePagePathByRolePages = (pages = []) => {
  if (!pages.length) {
    return '/';
  }

  const dashboard = pages.find((page) => page.path === '/');
  
  return dashboard ? dashboard.path : pages[0].path;
};

export const checkOnIsEqual = (values: any, initial: any) => isEqual(values, initial);

export const сheckOnSpaces = (str) => str.trim() === '';

export const getCronText = (cronExpression) => {
  try {
    return cronstrue.toString(cronExpression);
  } catch (e) {
    
  }
};

export const trimLongText = (text: string): string => text.replace(/\s+/g, ' ').trim();

export const getFileTypeByUrl = (url: string):string => {
  const extension = url.split(/[#?]/)[0].split('.').pop().trim();
  return mime.lookup(extension);
};

export const isPdf = (fileType: string):boolean => fileType === 'application/pdf'