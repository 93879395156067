import styled from 'styled-components';

export const TabWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: ${({ height }) => (height ? `${height}px` : '380px')};
  padding: 0 40px;

  @media (max-width: 375px) {
    height: 210px;
  }

  @media (max-width: 414px) {
    height: 430px;
  }
`;

export const DoubleFieldWrapper = styled.div`
  margin: ${({ margin }) => margin || 0};

  & div {
    & div {
      &::after {
        z-index: 0;
      }

      & div {
        & div {
          & div {
            & div {
              & div:nth-child(2) {
                & div:nth-child(1) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
