import { ITripReference } from './types';
import { TripReferenceStyled } from './styles';

const REFERENCE_MAX_LENGTH = 10;

const TripReference: React.FC<ITripReference> = ({
  reference,
  onClick = () => {},
}) => {
  if (!reference) {
    return null;
  }

  const referenceText = reference.length > REFERENCE_MAX_LENGTH
    ? `${reference.substring(0, REFERENCE_MAX_LENGTH)}...`
    : reference;
 
  return (
    <TripReferenceStyled title={reference} onClick={onClick}>
      [{referenceText}]
    </TripReferenceStyled>
  )
};

export default TripReference;
