import { Payment } from '../../../enums/Payment';
import Yup from '../../../utils/validation';

const DEFAULT_STRING_MIN_VALUE = 2;
const DEFAULT_STRING_MAX_VALUE = 255;

const validateMessages = {
  payerRegistrationNumber: {
    required: 'Payer registration number cannot be empty',
    min: `Payer registration number cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer registration number cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerVatNumber: {
    required: 'Payer VAT number cannot be empty',
    min: `Payer VAT number cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer VAT number cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
};

export const payerSchema = Yup.object().shape({
  payerRegistrationNumber: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerRegistrationNumber.min)
    .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerRegistrationNumber.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.payerRegistrationNumber.required),
  payerVatNumber: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerVatNumber.min)
    .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerVatNumber.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.payerVatNumber.required),
});

export const getInitialValues = (payment: Payment) => {
  return {
    payerRegistrationNumber: payment.payerRegistrationNumber || '',
    payerVatNumber: payment.payerVatNumber || '',
  };
};
