import request from '../../models/request';
import {
  GET_SIGN_UP_OPTIONS_SUCCESS,
  GET_SIGN_UP_OPTIONS_ERROR,
  SET_ERROR_SIGN_UP_MESSAGE,
  SET_SIGN_UP_URL_VALIDITY,
  SET_IS_VALID_PASSWORD,
  GET_SIGN_UP_OPTIONS,
  SET_SUCCESS_SIGN_UP,
  SET_SIGN_UP_ERROR,
  SET_ACTIVE_TAB,
} from './actionTypes';
import { getErrorMessages } from '../../utils/errors';

export const setErrorMessage = (message = '') => ({ type: SET_ERROR_SIGN_UP_MESSAGE, payload: { message } });

export const setSignUpUrlValidity = (isValid = false) => ({ type: SET_SIGN_UP_URL_VALIDITY, payload: { isValid } });

export const setIsValidPassword = (isValid) => ({ type: SET_IS_VALID_PASSWORD, payload: { isValid } });

export const setActiveTab = (activeTab) => ({ type: SET_ACTIVE_TAB, payload: { activeTab } });


export const fetchOptions = (email, code, translate) => async (dispatch) => {
  dispatch({ type: GET_SIGN_UP_OPTIONS });

  try {
    const { data } = await request.post('auth/signup/handshake', { email, code });
    dispatch({ type: GET_SIGN_UP_OPTIONS_SUCCESS, payload: { data, translate } });
  } catch (err) {
    const error = translate(err?.response?.data?.error);

    dispatch({
      type: GET_SIGN_UP_OPTIONS_ERROR,
      payload: {
        error: error ? translate(error) : translate('api.oops'),
      },
    });
  }
};

export const setSuccessSignUp = (isSuccess = false) => ({ type: SET_SUCCESS_SIGN_UP, payload: { isSuccess } });

export const setSignUpError = (isSubmitError = false, message = '') => ({
  type: SET_SIGN_UP_ERROR,
  payload: { isSubmitError, message },
});

export const setSignUpData = (formData) => async (dispatch) => {
  try {
    await request .post('auth/signup', formData);
    dispatch(setSuccessSignUp(true))
  } catch (err) {
    const status = request.extractStatus(err);
    const messages = request.extractErrors(err);
    let errorMsg = 'Something went wrong. Try again later!';

    if (status === 422 && messages) {
      const errorMsgs = getErrorMessages(messages);
      errorMsg = errorMsgs.join(' ');
    }
    dispatch(setSignUpError(true, errorMsg));
  }
};
