import { FC, useEffect, useState } from 'react';
import { TextInputGroup } from '@heathmont/moon-select';
import FormField from '../../FormField';
import { EmailSign } from '../../../pages/EditProfile/DesktopEditProfile/Tabs/AccountDetails/styled';
import { ErrorMessage } from '../../../pages/SignUp/styles';
import { IAccountDetailsTab } from './types';
import { DoubleFieldWrapper } from '../styles';
import { ErrorMessageWrapper } from './styles';
import MoonSelect from '../../MoonSelect';
import { changeFieldId } from '../../../pages/SignUp/suggestions';
import { filterItemsWithBusinessVerticalByVerticalId, filterItemsWithVerticalsByVerticalObjectId } from '../../../utils/filters';
import { BUSINESS_VERTICAl_TYPES } from '../../../constants';

const AccountDetailsTab: FC<IAccountDetailsTab> = ({
  values,
  errors,
  setFieldValue,
  options,
  handleBlur,
  touched,
  initialValues,
}) => {
  const { email, company, employer, team, role, businessVertical } = values.details;
  const [employers, setEmployers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [businessVerticals, setBusinessVerticals] = useState([]);

  const isErrorEmployer = !!errors?.details?.employer && touched?.employer;
  const isErrorTeam = !!errors?.details?.team && touched?.team;
  const initialBusinessVertical = initialValues?.details?.businessVertical;

  const assignEmployersByVertical = (employers, businessVertical) => {
    setEmployers(filterItemsWithVerticalsByVerticalObjectId(employers, businessVertical));
  };

  const assignTeamsByVertical = (teams, businessVertical) => {
    setTeams(filterItemsWithBusinessVerticalByVerticalId(teams, businessVertical));
  };

  const assignBusinessVerticalByType = (businessVerticals = [], type) => {
    setBusinessVerticals(businessVerticals.filter(bv => bv.type === type));
  };

  const getCurrentEmployerValue = (employers, employer) => {
    return  employers.find((item) => +item?.value === employer) ?? null;
  };

  const getCurrentTeamValue = (teams, team) => {
    return  teams.find((item) => +item?.value === team) ?? null;
  };

  useEffect(() => {
    assignEmployersByVertical(options?.employer?.options ?? [], businessVertical);
    assignTeamsByVertical(options?.team?.options ?? [], businessVertical);
    assignBusinessVerticalByType(options?.businessVertical?.options ?? [], BUSINESS_VERTICAl_TYPES.INTERNAL);
  }, []);

  useEffect(() => {
    assignEmployersByVertical(options?.employer?.options ?? [], businessVertical);
    assignTeamsByVertical(options?.team?.options ?? [], businessVertical);
    setFieldValue('employer', null)
    setFieldValue('team', null)
  }, [businessVertical]);

  const employerField = {
    type: 'select',
    value: getCurrentEmployerValue(employers, employer),
    onChange: ({ value }) => setFieldValue('details.employer', +value),
    inputSize: 'xlarge',
    placeholderSlot: 'Select employer',
    label: 'Your employer',
    options: employers,
    isSearchable: true,
    menuPlacement: 'top',
    maxMenuHeight: 170,
    onBlur: handleBlur,
    isError: isErrorEmployer,
    ref: (ref) => ref && changeFieldId(ref, 'employer'),
    isDisabled: options?.employer?.isEditable,
  };

  const teamField = {
    type: 'select',
    value: getCurrentTeamValue(teams, team),
    onChange: ({ value }) => setFieldValue('details.team', +value),
    inputSize: 'xlarge',
    placeholderSlot: 'Select team',
    label: 'Your team',
    options: teams,
    isSearchable: true,
    menuPlacement: 'top',
    maxMenuHeight: 170,
    onBlur: handleBlur,
    isError: isErrorTeam,
    ref: (ref) => ref && changeFieldId(ref, 'team'),
    isDisabled: options?.team?.isEditable,
  };

  const emailField = {
    type: 'text',
    value: email,
    inputSize: 'xlarge',
    label: 'E-mail address',
    disabled: options?.email?.isEditable,
  };

  const companyField = {
    type: 'select',
    value: (options.company?.options ?? []).find((item) => +item.value === company),
    inputSize: 'xlarge',
    label: 'Company',
    options: options?.company?.options ?? [],
    isDisabled: options?.company?.isEditable,
  };

  return (
    <>
      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: emailField,
            input2: companyField,
          }}
        />

        <EmailSign>We'll email you trip confirmations and receipts.</EmailSign>
      </DoubleFieldWrapper>

      <FormField
        value={(options.role?.options ?? []).find((item) => +item.value === role)}
        onChange={({ value }) => setFieldValue('details.role', +value)}
        fieldId="role"
        fieldName="role"
        component={MoonSelect}
        type="select"
        size="xLarge"
        menuPlacement="top"
        maxMenuHeight={140}
        label="Role"
        options={options?.role?.options ?? []}
        isDisabled={options?.role?.isEditable}
      />

      <DoubleFieldWrapper margin="24px 0">
        <FormField
          value={businessVerticals.find((item) => +item.value === businessVertical)}
          onChange={({ value }) => {
            setFieldValue('details.team', null);
            setFieldValue('details.employer', null);
            setFieldValue('details.businessVertical', +value);
          }}
          fieldId="businessVertical"
          fieldName="businessVertical"
          component={MoonSelect}
          placeholderSlot={'Select business vertical'}
          type="select"
          size="xLarge"
          menuPlacement="top"
          maxMenuHeight={140}
          label="Your business vertical"
          options={businessVerticals}
          isDisabled={initialBusinessVertical}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper margin="24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: employerField,
            input2: teamField,
          }}
        />

        {isErrorEmployer && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.details?.employer}</ErrorMessage>
          </ErrorMessageWrapper>
        )}

        {isErrorTeam && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.details?.team}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </>
  );
};

export default AccountDetailsTab;
