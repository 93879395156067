import { Popover } from '@heathmont/moon-popover';
import { Footer, Heading } from '@heathmont/moon-core';
import Button from '../Button';
import { IPopoverDialog } from './types';

const PopoverDialog: React.FC<IPopoverDialog> = ({
  withTertButton = false,
  confirmLabel = 'Change',
  cancelLabel = 'Close',
  tertLabel = '',
  isDivider = true,
  children,
  loading = false,
  isOpen,
  header = '',
  onVisibilityChange = () => {},
  handleAgree,
  handleClose,
  maxWidth,
}): JSX.Element => (
  <Popover
    onVisibilityChange={onVisibilityChange}
    className="popover-dialog"
    placement="bottom"
    isOpen={isOpen}
    maxWidth={maxWidth}
    content={
      <div>
        <div>
          <Heading size={20}>{header}</Heading>
          {children}
        </div>

        <Footer
          primButton={
            <Button
              progress={!!loading}
              isDisabled={!!loading}
              variant="primary"
              label={confirmLabel}
              handleClick={handleAgree}
            />
          }
          secButton={<Button isDisabled={!!loading} variant="tertiary" label={cancelLabel} handleClick={handleClose} />}
          tertButton={
            withTertButton && (
              <Button isDisabled={!!loading} variant="default" label={tertLabel} handleClick={handleClose} />
            )
          }
          isDivider={isDivider}
          size="small"
        />
      </div>
    }
  ></Popover>
);

export default PopoverDialog;
