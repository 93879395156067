import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 295px;
  height: 128px;
  border-radius: 16px;
  padding: 16px;
  margin-right: 16px;

  ${({ notificationStyles }) => notificationStyles}
`;

export const Header = styled(MoonText).attrs({
  size: 16,
  color: '#181B1C',
})`
  line-height: 24px;
  margin: 0;
  font-weight: 600;
`;

export const Content = styled.div`
  margin-bottom: 5px;
`;

export const ContentText = styled(MoonText).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 16px;
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ maxWidth }) =>
    maxWidth &&
    `
      max-width: ${maxWidth};
   `}
`;

export const Description = styled.ol`
  margin: 0;
  padding: 0 13px;
  list-style-type: circle;

  & li {
    font-size: 12px;
    cursor: pointer;
    width: fit-content;
    max-width: 250px;

    &:hover {
      text-decoration: underline;
      text-shadow: 0 0 0.01px black;
    }
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: flex-end;

  & p {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-shadow: 0 0 0.01px black;
    }
  }
`;

export const SubscriptionItem = styled.div`
  display: flex;
`;

export const SubscriptionDateWrapper = styled.div`
  display: flex;
  width: 70px;
`;

export const SubscriptionDate = styled.span`
  font-size: 11px;
  color: #78787d;
  margin-left: 5px;
`;
