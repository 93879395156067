import request from '../../models/request';
import { notificationTypes, graphicsLinkData } from '../suggestions';
import {
  CLOSE_ALL_NOTIFICATION_ITEMS,
  SHOW_ALL_NOTIFICATION_ITEMS,
  CLOSE_UPCOMING_TRIPS,
  SHOW_UPCOMING_TRIPS,
  SET_UPCOMING_TRIPS,
  SET_NOTIFICATIONS,
  SET_GRAPHICS_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
  SET_OPTIONS,
  SET_NUMBERS,
} from './actionTypes';

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const showUpcomingTrips = () => ({
  type: SHOW_UPCOMING_TRIPS,
});

export const closeUpcomingTrips = () => ({
  type: CLOSE_UPCOMING_TRIPS,
});

export const showNotificationAllItems = (items = [], title = '', notificationType: '') => ({
  type: SHOW_ALL_NOTIFICATION_ITEMS,
  payload: { data: items, title, notificationType },
});

export const closeNotificationAllItems = () => ({
  type: CLOSE_ALL_NOTIFICATION_ITEMS,
});

const setOptions = (data) => ({ type: SET_OPTIONS, payload: { data } });

export const fetchOptions = () => (dispatch) => {
  request
    .get('dashboard/options')
    .then(({ data }) => dispatch(setOptions(data)))
    .catch((e) => console.error(e));
};

const setNumbers = (data) => ({ type: SET_NUMBERS, payload: { data } });

export const fetchNumbers = (params) => (dispatch) => {
  request
    .get('dashboard/numbers', { params: params.filters })
    .then(({ data }) => dispatch(setNumbers(data)))
    .catch((e) => console.error(e));
};

const setUpcomingTrips = (data) => ({ type: SET_UPCOMING_TRIPS, payload: { data } });

export const fetchUpcomingTrips = () => (dispatch) => {
  request
    .get('dashboard/upcoming-trips')
    .then(({ data }) => dispatch(setUpcomingTrips(data.records)))
    .catch((e) => console.error(e));
};

const setNotifications = (data, notificationName) => ({ type: SET_NOTIFICATIONS, payload: { data, notificationName } });

export const fetchNotifications = () => (dispatch) => {
  notificationTypes.forEach(({ notificationName, link }) => {
    request
      .get(`dashboard/notifications/${link}`)
      .then(({ data }) => dispatch(setNotifications(data.records, notificationName)))
      .catch((e) => console.error(e));
  });
};

const setGraphicsData = (data, graphicName) => ({ type: SET_GRAPHICS_DATA, payload: { data, graphicName } });

export const fetchGraphicsData = (params) => (dispatch) => {
  graphicsLinkData.forEach(({ graphicName, link }) => {
    request
      .get(`dashboard/graphics/${link}`, { params: params.filters })
      .then(({ data }) => dispatch(setGraphicsData(data[graphicName], graphicName)))
      .catch((e) => console.error(e));
  });
};
