import { Trip } from '../../enums/Trip';
import request from '../../models/request';

export const fetchTrips = async (ids: number[]): Promise<Trip[]> => {
  try {
    const { data } = await request.get('/trips/for-merging', {
      params: {
        ids, 
      },
    });
    return data.trips;
  } catch (err) {
    return null
  }
};

