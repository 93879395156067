export enum PAYMENT_PROVIDER {
  SWAPIN = 'swapin',
  REVOLUT = 'revolut',
  INTERNAL = 'internal',
};

export enum PAYMENT_PROVIDER_LABELS {
  swapin = 'Swapin',
  revolut = 'Revolut',
};
