import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { IIgnoreListAccommodations } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import Drawer from '../../../components/Drawer';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import DownDrawer from '../../../components/DownDrawer';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import Dialog from '../../../components/Dialog';
import { ignoreListAccommodationsDataMapper } from '../../../utils/tableHelpers';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';
import BookingForIgnoreOverviewDrawerContent from '../../../components/BookingForIgnoreOverviewDrawerContent';
import AddNewIgnoredAccommodationBooking from '../../../components/AddNewIgnoredAccommodationBooking';

const IgnoreListAccommodations: React.FC<IIgnoreListAccommodations> = ({
  isActiveAllAccommodationsForIgnoreCheckboxes,
  accommodationsForIgnoreCheckboxesData,
  ignoreListAccommodationsTotalCount,
  bookingForIgnoreDeleteModalOpen,
  bookingForIgnoreAddingModalOpen,
  isOpenBookingForIgnoreOverview,
  bookingForIgnoreOverviewData,
  bookingForIgnoreDeleteData,
  ignoreListAccommodations,
  isCreationLoading,
  isDeletionLoading,
  filters,
  sortBy,
  page,
  removeBookingFromAccommodationsIgnoreList,
  addBookingToAccommodationsIgnoreList,
  closeBookingForIgnoreCreationModal,
  setBookingForIgnoreDeletionLoading,
  setAllBookingForIgnoreCheckboxData,
  setBookingForIgnoreCreationLoading,
  showBookingForIgnoreCreationModal,
  closeBookingForIgnoreDeleteModal,
  setBookingForIgnoreCheckboxData,
  showBookingForIgnoreDeleteModal,
  closeBookingForIgnoreOverview,
  showBookingForIgnoreOverview,
  getAccommodationsIgnoreList,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    getAccommodationsIgnoreList(params);
  }, [filters, page, sortBy]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const mappedData = useMemo(() => ignoreListAccommodationsDataMapper(ignoreListAccommodations), [ignoreListAccommodations]);

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showBookingForIgnoreOverview(ignoreListAccommodations[row?.id]);
    },
    [ignoreListAccommodations]
  );

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  useMemo(() => {
    totalItems.current = ignoreListAccommodationsTotalCount;
  }, [ignoreListAccommodationsTotalCount]);

  const handleAddBookingToIgnoreList = async (data) => {
    await addBookingToAccommodationsIgnoreList(data, setBookingForIgnoreCreationLoading, () => {
      getAccommodationsIgnoreList(params);
      setBookingForIgnoreCheckboxData();
      closeBookingForIgnoreCreationModal();
      closeBookingForIgnoreOverview();
    });
  };

  const handleRemoveBookingFromIgnoreList = async (data) => {
    await removeBookingFromAccommodationsIgnoreList(data, setBookingForIgnoreDeletionLoading, () => {
      getAccommodationsIgnoreList(params);
      setBookingForIgnoreCheckboxData();
      closeBookingForIgnoreDeleteModal();
      closeBookingForIgnoreOverview();
    });
  };
  
  const tableContentRecieverProps = {
    isActiveAllAccommodationsForIgnoreCheckboxes,
    isIgnoredAccommodationsPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: accommodationsForIgnoreCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    data: mappedData,
    page,
    handleSetAllCheckboxs: setAllBookingForIgnoreCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: showBookingForIgnoreDeleteModal,
    handleSetCheckbox: setBookingForIgnoreCheckboxData,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete ';

    if ((accommodationsForIgnoreCheckboxesData && accommodationsForIgnoreCheckboxesData.length > 1) || isActiveAllAccommodationsForIgnoreCheckboxes) {
      return `${title} these bookings from ignore list?`;
    }

    return `${title} this booking from ignore list?`;
  }, [isActiveAllAccommodationsForIgnoreCheckboxes, accommodationsForIgnoreCheckboxesData]);

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Ignored accommodations</Title>

          <Button
            iconLeft={<ControlsPlus fontSize="1.6rem" />}
            variant="primary"
            onClick={showBookingForIgnoreCreationModal}
          >
            Add new booking
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.IGNORE_LIST_ACCOMMODATIONS}
          totalItems={totalItems}
          data={ignoreListAccommodations}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => getAccommodationsIgnoreList(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {!!accommodationsForIgnoreCheckboxesData.length && (
        <DownDrawer
          data={accommodationsForIgnoreCheckboxesData}
          handleSetCheckbox={setBookingForIgnoreCheckboxData}
          handleRemoveDialog={showBookingForIgnoreDeleteModal}
          text="booking"
        />
      )}

      {isOpenBookingForIgnoreOverview && (
        <div>
          <Drawer
            title={bookingForIgnoreOverviewData?.reference ?? ''}
            handleClose={closeBookingForIgnoreOverview}
          >
            <BookingForIgnoreOverviewDrawerContent
              data={bookingForIgnoreOverviewData}
            />
          </Drawer>
        </div>
      )}

      {bookingForIgnoreDeleteModalOpen && (
        <Dialog
          submitButtonLabel="Delete"
          isLoading={isDeletionLoading}
          title="Delete"
          data={bookingForIgnoreDeleteData}
          onSubmit={handleRemoveBookingFromIgnoreList}
          onClose={closeBookingForIgnoreDeleteModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {bookingForIgnoreAddingModalOpen && (
        <AddNewIgnoredAccommodationBooking
          loading={isCreationLoading}
          handleAgree={handleAddBookingToIgnoreList}
          handleCloseDialog={closeBookingForIgnoreCreationModal}
        />
      )}
    </>
  );
};

export default IgnoreListAccommodations;
