import styled from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  margin: 1rem 0;
  overflow: auto;
  min-height: 300px;
  height: calc(85% - 2rem);

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};
    font-size: 14px;
    width: 100%;

    & thead {
      position: sticky;
      top: 0;
      background: #ffffff;
      z-index: 10;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};

        &.grouped {
          background-color: #f6f5f4;
          &:not(:first-of-type) {
            border-top: 8px solid ${({ theme }) => theme.color.goku['100']};
          }
          td {
            border-right: transparent;
            &.groupedBy {
              background-color: #f9f3e2;
              border-left: 1px solid ${({ theme }) => theme.color.beerus['100']};
              border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};
            }
          }
        }

        &.highlighted,
        &:hover {
          background-color: #fef9e9;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem;
      border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};

      :last-child,
      :first-child {
        border-right: 0;
      }
      &.groupedBy {
        background-color: ${({ theme }) => theme.color.ghost['100']};
      }
    }
    th {
      font-weight: 400;
      font-size: 12px;
      color: ${({ theme }) => theme.color.trunks['100']};
      white-space: nowrap;
    }
    tbody {
      tr {
        td {
          height: 70px;
          padding-top: ${({ rowHeight }) => rowHeight};
          padding-bottom: ${({ rowHeight }) => rowHeight};

          &:last-child {
            min-width: 156px;
          }
        }
      }
    }
  }

  table th {
    border-top: 1px solid ${({ theme }) => theme.color.beerus['100']};
    border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};
  }

  table td {
    border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};
    border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};
  }
`;

export const CheckboxWrapper = styled.span`
  & label {
    margin: 0;
  }
`;
