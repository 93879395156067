import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Button, Text } from '@heathmont/moon-core';
import { ControlsPlus } from '@heathmont/moon-icons';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/TablePagination';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import { Spinner } from '../../../components/Spinner';
import TableContentReciever from '../../../components/TableContentReciever';
import { getBoolStatusLabel, toolsEmploymentTypesDataMapper } from '../../../utils/tableHelpers';
import Drawer from '../../../components/Drawer';
import EmploymentTypeOverviewDrawerContent from '../../../components/EmploymentTypeOverviewDrawerContent';
import Dialog from '../../../components/Dialog';
import DownDrawer from '../../../components/DownDrawer';
import AddNewEmploymentType from '../../../components/AddNewEmploymentType';
import { initialColumns } from './suggestions';
import { IEmploymentTypes } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';

const EmploymentTypes: React.FC<IEmploymentTypes> = ({
  isActiveAllEmploymentTypesCheckboxes,
  employmentTypesCheckboxesData,
  isOpenEmploymentTypeOverview,
  isDeleteEmploymentTypesModal,
  overviewEmploymentTypeData,
  deletableEmploymentTypes,
  isOpenAddNewEmployerType,
  isChangedEmploymentType,
  totalEmploymentTypes,
  employmentTypes,
  filters,
  sortBy,
  page,
  setAllEmploymentTypesCheckboxData,
  closeDeleteEmploymentTypesModal,
  setEmploymentTypesCheckboxData,
  showDeleteEmploymentTypesModal,
  setIsOpenAddNewEmploymentType,
  updateEmploymentTypeStatus,
  closeEmployerTypeOverview,
  showEmployerTypeOverview,
  fetchEmploymentTypes,
  removeEmploymentType,
  updateEmploymentType,
  addNewEmploymentType,
  setQueryParams,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchEmploymentTypes(params);
  }, [filters, page, sortBy]);

  useEffect(() => {
    if (isChangedEmploymentType) {
      fetchEmploymentTypes(params);
    }
  }, [isChangedEmploymentType]);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  useMemo(() => {
    totalItems.current = totalEmploymentTypes;
  }, [totalEmploymentTypes]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const employmentTypesGroupedData = useMemo(() => toolsEmploymentTypesDataMapper(employmentTypes), [employmentTypes]);

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showEmployerTypeOverview(employmentTypes[row?.id]);
    },
    [employmentTypes]
  );

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const handleRemoveEmploymentTypes = useCallback(async (data: any) => {
    await removeEmploymentType(data);
    fetchEmploymentTypes(params);
    setEmploymentTypesCheckboxData();
    closeDeleteEmploymentTypesModal();
    closeEmployerTypeOverview();
  }, []);

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete ';

    if (
      (employmentTypesCheckboxesData && employmentTypesCheckboxesData.length > 1) ||
      isActiveAllEmploymentTypesCheckboxes
    ) {
      return `${title} these employment types?`;
    }

    return `${title} this employment type?`;
  }, [isActiveAllEmploymentTypesCheckboxes, employmentTypesCheckboxesData]);

  const handleCloseAddNewEmploymentType = useCallback(() => {
    setIsOpenAddNewEmploymentType(false);
  }, []);

  const handleAddNewEmploymentType = useCallback((data: any) => {
    setIsOpenAddNewEmploymentType(false);
    addNewEmploymentType(data);
  }, []);

  const handleUpdateEmploymentType = useCallback(async (employmentTypeId, data: any) => {
    await updateEmploymentType(employmentTypeId, data);
  }, []);

  const tableContentRecieverProps = {
    isShowTableFilters,
    isActiveAllEmploymentTypesCheckboxes,
    isToolsEmploymentTypesPage: true,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: employmentTypesCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TOOLS.EMPLOYMENT_TYPES,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    page,
    data: employmentTypesGroupedData,
    handleSetAllCheckboxs: setAllEmploymentTypesCheckboxData,
    handleSetCheckbox: setEmploymentTypesCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: showDeleteEmploymentTypesModal,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Employment types</Title>

          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewEmploymentType(true)}>
            <ControlsPlus /> Add employer type
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.EMPLOYMENT_TYPES}
          totalItems={totalItems}
          data={employmentTypes}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchEmploymentTypes(params)}
          nextPage={nextPage}
          prevPage={prevPage}
          callback={setAllEmploymentTypesCheckboxData}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenEmploymentTypeOverview && (
        <div>
          <Drawer
            handleClose={closeEmployerTypeOverview}
            title={overviewEmploymentTypeData?.label ?? ''}
            status={overviewEmploymentTypeData?.status}
            getStatusLabel={getBoolStatusLabel}
            withStatus
          >
            <EmploymentTypeOverviewDrawerContent
              data={overviewEmploymentTypeData}
              handleUpdate={handleUpdateEmploymentType}
              updateStatus={updateEmploymentTypeStatus}
            />
          </Drawer>
        </div>
      )}

      {isDeleteEmploymentTypesModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableEmploymentTypes}
          onSubmit={handleRemoveEmploymentTypes}
          onClose={closeDeleteEmploymentTypesModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {!!employmentTypesCheckboxesData.length && (
        <DownDrawer
          data={employmentTypesCheckboxesData}
          handleSetCheckbox={setEmploymentTypesCheckboxData}
          handleRemoveDialog={showDeleteEmploymentTypesModal}
          text="employment type"
        />
      )}

      {isOpenAddNewEmployerType && (
        <AddNewEmploymentType
          handleAgree={handleAddNewEmploymentType}
          secondButtonLabel="Add"
          handleCloseDialog={handleCloseAddNewEmploymentType}
          label="Add employment type"
        />
      )}
    </>
  );
};

export default EmploymentTypes;
