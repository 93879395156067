import styled from 'styled-components';
import { AdditionItem } from '../../styled';

export const CancellationPenaltyWrapper = styled.div`
`;

export const AdditionItemTitle = styled(AdditionItem)`
  color: #000;
  font-weight: bold;
`;
