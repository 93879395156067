
import { IEditOtherTransportDialog } from './types';
import InfoBlock from '../../InfoBlock';
import Dialog from '../../Dialog';
import OtherTransport from '../../AddExternalTripModalContent/forms/OtherTransportForm';
import { DialogContent } from '../styles';

const EditOtherTransportDialog: React.FC<IEditOtherTransportDialog> = ({
  editedOtherTransport,
  isLoading,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog
      withFooter={false}
      width={600}
      title={`[${editedOtherTransport.reference}] Edit other transport`}
      onClose={onClose}
    >
      <DialogContent>
        <InfoBlock text={`
            After a manual update, the traveler will be notified,
            and TTR will be updated automatically. 
          `}
        />
        <OtherTransport
          handleClose={onClose}
          handleSubmit={onSubmit}
          isEditMode
          initialOtherTransportData={editedOtherTransport}
          isLoading={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditOtherTransportDialog;
