import moment from 'moment';
import Yup from '../../utils/validation';
import validationSchema from '../../utils/validation-schema';

const validateMessages = {
  required: 'Field cannot be empty',
  phone: 'Field phone cannot be empty',
  firstName: {
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${validationSchema.USER.FIRST_NAME.min} characters`,
    max: `First name cannot be longer than ${validationSchema.USER.FIRST_NAME.max} characters`,
  },
  lastName: {
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${validationSchema.USER.LAST_NAME.min} characters`,
    max: `Last name cannot be longer than ${validationSchema.USER.LAST_NAME.max} characters`,
  },
  email: {
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  country: {
    required: 'Country is cannot be empty',
  },
  documentNumber: {
    required: 'Passport number is cannot be empty',
    min: `Passport number is cannot be less than ${validationSchema.USER.DOCUMENT_NUMBER.min} characters`,
    max: `Passport number is cannot be longer than ${validationSchema.USER.DOCUMENT_NUMBER.max} characters`,
  },
  password: {
    min: `Password cannot be less than ${validationSchema.USER.PASSWORD.min} characters`,
    max: `Password cannot be longer than ${validationSchema.USER.PASSWORD.max} characters`,
  },
  loyaltyNumbers: {
    max: `Max number of loyalty programs is ${validationSchema.USER.LOYALTY_PROGRAMS.max}.`,
    number: {
      required: 'Loyalty number cannot be empty',
      min: 'Loyalty number is cannot be less than 1 characters',
      max: 'Loyalty number is cannot be longer than 255 characters',
    },
    airline: {
      required: 'Airline cannot be empty',
      min: 'Airline number is cannot be less than 1',
    },
  },
  favoriteHotels: {
    regionId: {
      required: 'City cannot be empty',
    },
    hotelId: {
      required: 'Hotel cannot be empty',
    },
    userId: {
      required: 'User cannot be empty',
    },
  },
  cryptoWallets: {
    address: {
      min: 'Wallet address cannot be less than 1 characters',
      max: 'Wallet address cannot be longer than 1000 characters',
      required: 'Wallet address cannot be empty',
    },
    value: {
      required: 'Wallet crypto currency cannot be empty',
    },
  },
};

export const editProfileSchema = Yup.object().shape({
  details: Yup.object().shape({
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .required(validateMessages.required),
    employer: Yup
      .number()
      .required(validateMessages.required),
    company: Yup
      .string()
      .required(validateMessages.required),
    role: Yup
      .string()
      .required(validateMessages.required),
    team: Yup
      .number()
      .required(validateMessages.required),
  }),
  settings: Yup.object().shape({
    firstname: Yup
      .string()
      .required(validateMessages.firstName.required)
      .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstName.min)
      .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstName.max)
      .withoutSpaces()
      .isInLatinCharacters(),
    lastname: Yup
      .string()
      .required(validateMessages.lastName.required)
      .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastName.min)
      .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastName.max)
      .withoutSpaces()
      .isInLatinCharacters(),
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .withoutSpaces()
      .isEmailInLatinCharacters()
      .required(validateMessages.email.required),
    phone: Yup
      .string()
      .required(validateMessages.phone)
      .isValidPhoneNumber(),
    birthDate: Yup
      .string()
      .nullable()
      .test('DOB', 'Age cannot be less then 18 years old', (value) => moment().diff(moment(value), 'years') >= 18)
      .required(validateMessages.required),
    gender: Yup
      .number()
      .required(validateMessages.required),
    title: Yup
      .number()
      .required(validateMessages.required),
  }),
  document: Yup.object().shape({
    country: Yup
      .number()
      .required(validateMessages.country.required)
      .nullable(),
    number: Yup
      .string()
      .min(validationSchema.USER.DOCUMENT_NUMBER.min, validateMessages.documentNumber.min)
      .max(validationSchema.USER.DOCUMENT_NUMBER.max, validateMessages.documentNumber.max)
      .withoutSpaces()
      .required(validateMessages.documentNumber.required),
    expirationDate: Yup
      .string()
      .nullable()
      .test('DOB', 'Document expiration date cannot be today or earlier', (value) => !moment().isSameOrAfter(value))
      .required(),
  }),
  favoriteHotels: Yup.array().of(Yup.object().shape({
    userId: Yup
      .number()
      .required(validateMessages.favoriteHotels.userId.required),
    regionId: Yup
      .number()
      .required(validateMessages.favoriteHotels.regionId.required),
    hotelId: Yup
      .string()
      .required(validateMessages.favoriteHotels.hotelId.required),
  })),
  loyaltyNumbers: Yup.array().of(Yup.object().shape({
    number: Yup.string()
      .min(1, validateMessages.loyaltyNumbers.number.min)
      .max(255, validateMessages.loyaltyNumbers.number.max)
      .withoutSpaces()
      .required(validateMessages.loyaltyNumbers.number.required),
    airline: Yup.number()
      .required(validateMessages.loyaltyNumbers.airline.required)
      .min(1, validateMessages.loyaltyNumbers.airline.min),
  })).max(validationSchema.USER.LOYALTY_PROGRAMS.max, validateMessages.loyaltyNumbers.max),
  security: Yup.object().shape(
    {
      currentPassword: Yup.string().when('newPassword', {
        is: (newPassword) => !!newPassword,
        then: Yup.string().required(validateMessages.required),
        otherwise: Yup.string().notRequired(),
      }),
      newPassword: Yup.string()
        .min(validationSchema.USER.PASSWORD.min, validateMessages.password.min)
        .max(validationSchema.USER.PASSWORD.max, validateMessages.password.max)
        .when('currentPassword', {
          is: (currentPassword) => !!currentPassword,
          then: Yup.string().required(validateMessages.required),
          otherwise: Yup.string().notRequired(),
        }),
    },
    // @ts-ignore
    ['currentPassword', 'newPassword']
  ),
  cryptoWallets: Yup.array().of(Yup.object().shape({
    address: Yup
      .string()
      .min(1, validateMessages.cryptoWallets.address.min)
      .max(1000, validateMessages.cryptoWallets.address.max)
      .required(validateMessages.cryptoWallets.address.required)
      .isInLatinCharacters(),
    cryptoCurrency: Yup.object().shape({
      value: Yup
        .number()
        .required(validateMessages.cryptoWallets.value.required),
    }),
  })),
});
