import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '@heathmont/moon-core';
import FormField from '../../FormField';
import { PasswordValidator } from '../../PasswordValidator';
import { setIsValidPassword } from '../../../store/signUp/actions';
import { ErrorMessage, ErrorMessageWrapper } from '../../../pages/SignUp/styles';
import { IPasswordTab } from './types';
import { TogglePasswordText } from '../../TogglePasswordText';

const PasswordTab: React.FC<IPasswordTab> = ({ values, setFieldValue, errors, touched, handleBlur }) => {
  const dispatch = useDispatch();
  const isValidNewPassword = useSelector((state: any) => state.signUpReducer.isValidNewPassword);

  const isErrorPassword = !!errors?.security?.password && touched?.password;

  const { password } = values.security;

  return (
    <>
      <FormField
        fieldId="password"
        fieldName="password"
        value={password}
        component={TextInput}
        type="password"
        inputSize="xlarge"
        onChange={({ target: { value } }) => setFieldValue('security.password', value)}
        isError={isErrorPassword || (password && !isValidNewPassword)}
        label="Password"
        showPasswordText={<TogglePasswordText />}
        onBlur={handleBlur}
      />

      {isErrorPassword && (
        <ErrorMessageWrapper>
          <ErrorMessage>{errors?.security?.password}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
      <PasswordValidator password={password} setPasswordValid={(isValid) => dispatch(setIsValidPassword(isValid))} />
    </>
  );
};

export default PasswordTab;
