import { connect } from 'react-redux';
import { setAppShallBeRedirectedUrl, setBeforeLoginUrl, setLoginEmail } from '../../store/app/actions';
import Routes from './Routes.ui';

const mapStateToProps = ({ currentUser, app, auth }) => ({
  isCurrentUserLoading: currentUser.isCurrentUserLoading,
  shallBeRedirectedTo: app.shallBeRedirectedTo,
  isCurrentUserLoaded: currentUser.isCurrentUserLoaded,
  isAuthenticated: auth.isAuthenticated,
  isAppReady: app.isAppReady,
  beforeLoginUrl: app.beforeLoginUrl,
});

const mapDispatchToProps = {
  setAppShallBeRedirectedUrl,
  setBeforeLoginUrl,
  setLoginEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
