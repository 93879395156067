import {
  CLOSE_FEEDBACK_OVERVIEW,
  SHOW_FEEDBACK_OVERVIEW,
  UPDATE_FEEDBACK_DATA,
  SET_FEEDBACKS,
} from './actionTypes';

export const showFeedbackOverview = (data) => {
  return { type: SHOW_FEEDBACK_OVERVIEW, payload: { data } };
};

export const closeFeedbackOverview = () => {
  return { type: CLOSE_FEEDBACK_OVERVIEW };
};

export const setFeedbacks = (records, total) => {
  return { type: SET_FEEDBACKS, payload: { records, total } };
};

export const updateFeedback = (feedbackId, data) => {
  return { type: UPDATE_FEEDBACK_DATA, payload: { feedbackId, data } };
};
