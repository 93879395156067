import { connect } from 'react-redux';
import Tools from './Tools.ui';
import {
  setAllRolesCheckboxData,
  closeDeleteRolesModal,
  showDeleteRolesModal,
  setRolesCheckboxData,
  fetchToolsRolesData,
  setIsOpenAddNewRole,
  closeRoleOverview,
  showRoleOverview,
  fetchPagesData,
  updateRole,
} from '../../store/tools/roles/actions';
import {
  fetchToolsTeamsCompanies,
  setAllTeamsCheckboxData,
  closeDeleteTeamsModal,
  showDeleteTeamsModal,
  setTeamsCheckboxData,
  fetchToolsTeamsData,
  setIsOpenAddNewTeam,
  changeTeamsStatus,
  closeTeamOverview,
  showTeamOverview,
  setTeamsOptions,
  addNewTeam,
  updateTeam,
} from '../../store/tools/teams/actions';
import {
  setAllEmployersCheckboxData,
  closeDeleteEmployersModal,
  showDeleteEmployersModal,
  setEmployersCheckboxData,
  setIsOpenAddNewEmployer,
  closeEmployerOverview,
  showEmployerOverview,
  addToolsNewEmployer,
  fetchToolsEmployers,
  fetchAllCompanies,
} from '../../store/tools/employers/actions';

import {
  setAllBusinessVerticalsCheckboxData,
  closeDeleteBusinessVerticalsModal,
  showDeleteBusinessVerticalsModal,
  setBusinessVerticalCheckboxData,
  setIsOpenAddNewBusinessVertical,
  fetchBusinessVerticalsCompanies,
  closeBusinessVerticalOverview,
  showBusinessVerticalOverview,
  addToolsNewBusinessVertical,
  fetchToolsBusinessVerticals,
} from '../../store/tools/businessVerticals/actions';


import {
  closeDeletePurposeModal,
  closePurposeOverview,
  setAllPurposesCheckboxData,
  setIsOpenAddNewPurpose,
  setPurposesCheckboxData,
  showDeletePurposeModal,
  showPurposeOverview,
} from '../../store/tools/purposes/actions';
import {
  setIsOpenAddNewHotelRecommendation,
  showDeleteRecommendedHotelsModal,
  closeDeleteRecommendedHotelsModal,
  setRecommendedHotelsCheckboxData,
  setAllRecommendedHotelsCheckboxData,
  showRecommendedHotelOverview,
  closeRecommendedHotelOverview,
  addNewRecommendedHotel,
  updateRecommendedHotel,
} from '../../store/tools/recommendedHotels/actions';
import { addNewPurpose, fetchPurposes, removePurpose, updatePurpose } from '../../store/tools/purposes/api';
import { fetchRecommendedHotels } from '../../store/tools/recommendedHotels/api';
import { fetchSubscriptions, fetchSubscriptionsLogs, updateSubscription } from '../../store/tools/subscriptions/api';
import { closeSubscriptionOverview, showSubscriptionOverview } from '../../store/tools/subscriptions/actions';
import {
  fetchActionsAndNotifications,
  fetchActionsAndNotificationsLogs,
  updateActionAndSubscription,
  updateActionStatus,
} from '../../store/tools/actionsAndNotifications/api';
import {
  closeActionAndNotificationOverview,
  setActionsCheckboxData,
  setAllActionsCheckboxData,
  showActionAndNotificationOverview,
} from '../../store/tools/actionsAndNotifications/actions';
import {
  addNewEmploymentType,
  fetchEmploymentTypes,
  removeEmploymentType,
  updateEmploymentType,
  updateEmploymentTypeStatus,
} from '../../store/tools/employmentTypes/api';
import {
  closeDeleteEmploymentTypesModal,
  closeEmployerTypeOverview,
  setAllEmploymentTypesCheckboxData,
  setEmploymentTypesCheckboxData,
  setIsOpenAddNewEmploymentType,
  showDeleteEmploymentTypesModal,
  showEmployerTypeOverview,
} from '../../store/tools/employmentTypes/actions';
import {
  closeFeedbackOverview,
  showFeedbackOverview,
} from '../../store/tools/feedbacks/actions';
import {
  fetchFeedbacks,
  updateFeedback,
} from '../../store/tools/feedbacks/api';
import {
  setAllBookingForIgnoreCheckboxData,
  setBookingForIgnoreCheckboxData,
  closeBookingForIgnoreCreationModal,
  setBookingForIgnoreDeletionLoading,
  setBookingForIgnoreCreationLoading,
  showBookingForIgnoreCreationModal,
  closeBookingForIgnoreDeleteModal,
  showBookingForIgnoreDeleteModal,
  closeBookingForIgnoreOverview,
  showBookingForIgnoreOverview,
} from '../../store/tools/ignoreListAccommodations/actions';
import {
  removeBookingFromAccommodationsIgnoreList,
  addBookingToAccommodationsIgnoreList,
  getAccommodationsIgnoreList,
} from '../../store/tools/ignoreListAccommodations/api';
import {
  setAllCustomHotelsCheckboxData,
  closeDeleteCustomHotelsModal,
  setIsUpdatedCustomHotelsData,
  setCustomHotelsCheckboxData,
  showDeleteCustomHotelsModal,
  setIsOpenAddNewCustomHotel,
  showCustomHotelOverview,
  closeCustomHotelOverview,
} from '../../store/tools/customHotels/actions';
import {
  fetchCustomHotels,
  removeCustomHotel,
  addNewCustomHotel,
  updateCustomHotel,
} from '../../store/tools/customHotels/api';


const mapStateToProps = ({
  ignoreListAccommodationsReducer,
  actionsAndNotificationsReducer,
  businessVerticalsReducer,
  recommendedHotelsReducer,
  employmentTypesReducer,
  subscriptionsReducer,
  customHotelsReducer,
  employersReducer,
  companiesReducer,
  feedbacksReducer,
  purposesReducer,
  rolesReducer,
  teamsReducer,
}) => ({
  // Roles
  roles: rolesReducer.roles,
  totalRoles: rolesReducer.totalRoles,
  rolesCheckboxesData: rolesReducer.rolesCheckboxesData,
  isOpenRolesOverview: rolesReducer.isOpenRolesOverview,
  overviewRolesData: rolesReducer.overviewRolesData,
  pages: rolesReducer.pages,
  isDeleteRolesModal: rolesReducer.isDeleteRolesModal,
  deletableRoles: rolesReducer.deletableRoles,
  isOpenAddNewRole: rolesReducer.isOpenAddNewRole,
  isActiveAllRolesCheckboxes: rolesReducer.isActiveAllRolesCheckboxes,

  // Teams
  teams: teamsReducer.teams,
  totalTeams: teamsReducer.totalTeams,
  isOpenTeamsOverview: teamsReducer.isOpenTeamsOverview,
  overviewTeamsData: teamsReducer.overviewTeamsData,
  isUpdateTeamsStatus: teamsReducer.isUpdateTeamsStatus,
  teamsCheckboxesData: teamsReducer.teamsCheckboxesData,
  isActiveAllTeamsCheckboxes: teamsReducer.isActiveAllTeamsCheckboxes,
  isDeleteTeamsModal: teamsReducer.isDeleteTeamsModal,
  deletableTeams: teamsReducer.deletableTeams,
  isOpenAddNewTeam: teamsReducer.isOpenAddNewTeam,
  teamsOptions: teamsReducer.teamsOptions,
  companies: teamsReducer.companies,
  isUpdatedTeamData: teamsReducer.isUpdatedTeamData,

  // Employers
  employers: employersReducer.employers,
  totalEmployers: employersReducer.totalEmployers,
  isActiveAllEmployersCheckboxes: employersReducer.isActiveAllEmployersCheckboxes,
  employersCheckboxesData: employersReducer.employersCheckboxesData,
  isOpenAddNewEmployer: employersReducer.isOpenAddNewEmployer,
  employersCompanies: employersReducer.employersCompanies,
  isDeleteEmployersModal: employersReducer.isDeleteEmployersModal,
  deletableEmployers: employersReducer.deletableEmployers,
  isOpenEmployerOverview: employersReducer.isOpenEmployerOverview,
  overviewEmployerData: employersReducer.overviewEmployerData,
  isUpdatedEmployerData: employersReducer.isUpdatedEmployerData,

  // Business Verticals
  businessVerticals: businessVerticalsReducer.businessVerticals,
  totalBusinessVerticals: businessVerticalsReducer.totalBusinessVerticals,
  isActiveAllBusinessVerticalsCheckboxes: businessVerticalsReducer.isActiveAllBusinessVerticalsCheckboxes,
  businessVerticalsCheckboxesData: businessVerticalsReducer.businessVerticalsCheckboxesData,
  isOpenBusinessVerticalOverview: businessVerticalsReducer.isOpenBusinessVerticalOverview,
  overviewBusinessVerticalData: businessVerticalsReducer.overviewBusinessVerticalData,
  isOpenAddNewBusinessVertical: businessVerticalsReducer.isOpenAddNewBusinessVertical,
  businessVerticalsCompanies: businessVerticalsReducer.businessVerticalsCompanies,
  isUpdatedBusinessVerticalData: businessVerticalsReducer.isUpdatedBusinessVerticalData,
  isDeleteBusinessVerticalsModal: businessVerticalsReducer.isDeleteBusinessVerticalsModal, 
  deletableBusinessVerticals: businessVerticalsReducer.deletableBusinessVerticals,
  
  // Companies
  isOpenCompanyOverview: companiesReducer.isOverviewOpen,
  isUpdatedCompanyData: companiesReducer.isUpdatedCompanyData,

  // Purposes
  purposes: purposesReducer.purposes,
  totalPurposes: purposesReducer.totalPurposes,
  isActiveAllPurposesCheckboxes: purposesReducer.isActiveAllPurposesCheckboxes,
  purposesCheckboxesData: purposesReducer.purposesCheckboxesData,
  isOpenAddNewPurpose: purposesReducer.isOpenAddNewPurpose,
  isChangedPurpose: purposesReducer.isChangedPurpose,
  isDeletePurposeModal: purposesReducer.isDeletePurposeModal,
  deletablePurposes: purposesReducer.deletablePurposes,
  isOpenPurposeOverview: purposesReducer.isOpenPurposeOverview,
  overviewPurposeData: purposesReducer.overviewPurposeData,

  // Recommended hotels
  isOpenAddNewHotelRecommendation: recommendedHotelsReducer.isOpenAddNewHotelRecommendation,
  recommendedHotels: recommendedHotelsReducer.recommendedHotels,
  totalRecommendedHotels: recommendedHotelsReducer.totalRecommendedHotels,
  isDeleteRecommendedHotelsModal: recommendedHotelsReducer.isDeleteRecommendedHotelsModal,
  recommendedHotelsCheckboxesData: recommendedHotelsReducer.recommendedHotelsCheckboxesData,
  isActiveAllRecommendedHotelsCheckboxes: recommendedHotelsReducer.isActiveAllRecommendedHotelsCheckboxes,
  isOpenRecommendedHotelOverview: recommendedHotelsReducer.isOpenRecommendedHotelOverview,
  overviewRecommendedHotelData: recommendedHotelsReducer.overviewRecommendedHotelData,
  showRecommendedHotelOverview: recommendedHotelsReducer.showRecommendedHotelOverview,
  closeRecommendedHotelOverview: recommendedHotelsReducer.closeRecommendedHotelOverview,
  isUpdatedRecommendedHotelsData: recommendedHotelsReducer.isUpdatedRecommendedHotelsData,
  deletableRecommendedHotels: recommendedHotelsReducer.deletableRecommendedHotels,

  // Subscriptions
  subscriptions: subscriptionsReducer.subscriptions,
  overviewSubscriptionsData: subscriptionsReducer.overviewSubscriptionsData,
  isOpenSubscriptionsOverview: subscriptionsReducer.isOpenSubscriptionsOverview,
  subscriptionLogs: subscriptionsReducer.subscriptionLogs,

  // Feedbacks
  isOpenFeedbackOverview: feedbacksReducer.isOpenFeedbackOverview,
  overviewFeedbackData: feedbacksReducer.overviewFeedbackData,
  totalFeedbacks: feedbacksReducer.totalFeedbacks,
  feedbacks: feedbacksReducer.feedbacks,

  // Actions and subscriptions
  actionsAndNotifications: actionsAndNotificationsReducer.actionsAndNotifications,
  totalActionsAndNotifications: actionsAndNotificationsReducer.totalActionsAndNotifications,
  isOpenActionAndNotificationOverview: actionsAndNotificationsReducer.isOpenActionAndNotificationOverview,
  overviewActionAndNotificationData: actionsAndNotificationsReducer.overviewActionAndNotificationData,
  actionAndNotificationLogs: actionsAndNotificationsReducer.actionAndNotificationLogs,
  actionsCheckboxesData: actionsAndNotificationsReducer.actionsCheckboxesData,
  isActiveAllActionsCheckboxes: actionsAndNotificationsReducer.isActiveAllActionsCheckboxes,
  isChangedAction: actionsAndNotificationsReducer.isChangedAction,

  // Employment types
  employmentTypes: employmentTypesReducer.employmentTypes,
  totalEmploymentTypes: employmentTypesReducer.totalEmploymentTypes,
  isOpenEmploymentTypeOverview: employmentTypesReducer.isOpenEmploymentTypeOverview,
  overviewEmploymentTypeData: employmentTypesReducer.overviewEmploymentTypeData,
  isDeleteEmploymentTypesModal: employmentTypesReducer.isDeleteEmploymentTypesModal,
  deletableEmploymentTypes: employmentTypesReducer.deletableEmploymentTypes,
  employmentTypesCheckboxesData: employmentTypesReducer.employmentTypesCheckboxesData,
  isActiveAllEmploymentTypesCheckboxes: employmentTypesReducer.isActiveAllEmploymentTypesCheckboxes,
  isOpenAddNewEmployerType: employmentTypesReducer.isOpenAddNewEmployerType,
  isChangedEmploymentType: employmentTypesReducer.isChangedEmploymentType,

  // Ignore list accommodations
  isActiveAllAccommodationsForIgnoreCheckboxes: ignoreListAccommodationsReducer.isActiveAllAccommodationsForIgnoreCheckboxes,
  accommodationsForIgnoreCheckboxesData: ignoreListAccommodationsReducer.accommodationsForIgnoreCheckboxesData,
  bookingForIgnoreDeleteModalOpen: ignoreListAccommodationsReducer.bookingForIgnoreDeleteModalOpen,
  bookingForIgnoreDeleteData: ignoreListAccommodationsReducer.bookingForIgnoreDeleteData,
  bookingForIgnoreAddingModalOpen: ignoreListAccommodationsReducer.bookingForIgnoreAddingModalOpen,
  isOpenBookingForIgnoreOverview: ignoreListAccommodationsReducer.isOpenBookingForIgnoreOverview,
  bookingForIgnoreOverviewData: ignoreListAccommodationsReducer.bookingForIgnoreOverviewData,
  ignoreListAccommodationsTotalCount: ignoreListAccommodationsReducer.ignoreListAccommodationsTotalCount,
  ignoreListAccommodations: ignoreListAccommodationsReducer.ignoreListAccommodations,
  isCreationLoading: ignoreListAccommodationsReducer.isCreationLoading,
  isDeletionLoading: ignoreListAccommodationsReducer.isCreationLoading,

  // Custom hotels
  isActiveAllCustomHotelsCheckboxes: customHotelsReducer.isActiveAllCustomHotelsCheckboxes,
  customHotelsCheckboxesData: customHotelsReducer.customHotelsCheckboxesData,
  isDeleteCustomHotelsModal: customHotelsReducer.isDeleteCustomHotelsModal,
  isOpenCustomHotelOverview: customHotelsReducer.isOpenCustomHotelOverview,
  isUpdatedCustomHotelsData: customHotelsReducer.isUpdatedCustomHotelsData,
  overviewCustomHotelData: customHotelsReducer.overviewCustomHotelData,
  isOpenAddNewCustomHotel: customHotelsReducer.isOpenAddNewCustomHotel,
  deletableCustomHotels: customHotelsReducer.deletableCustomHotels,
  totalCustomHotels: customHotelsReducer.totalCustomHotels,
  customHotels: customHotelsReducer.customHotels,
});

const mapDispatchToProps = {
  // Roles
  setAllRolesCheckboxData,
  closeDeleteRolesModal,
  showDeleteRolesModal,
  setRolesCheckboxData,
  setIsOpenAddNewRole,
  closeRoleOverview,
  showRoleOverview,
  fetchPagesData,
  fetchRoles: fetchToolsRolesData,
  updateRole,

  // Teams
  setAllTeamsCheckboxData,
  closeDeleteTeamsModal,
  showDeleteTeamsModal,
  setTeamsCheckboxData,
  setIsOpenAddNewTeam,
  closeTeamOverview,
  changeTeamsStatus,
  showTeamOverview,
  setTeamsOptions,
  fetchCompanies: fetchToolsTeamsCompanies,
  fetchTeams: fetchToolsTeamsData,
  addNewTeam,
  updateTeam,

  // Employers
  setAllEmployersCheckboxData,
  closeDeleteEmployersModal,
  showDeleteEmployersModal,
  setEmployersCheckboxData,
  setIsOpenAddNewEmployer,
  closeEmployerOverview,
  showEmployerOverview,
  fetchToolsEmployers,
  addToolsNewEmployer,
  fetchAllCompanies,

  //Business Verticals
  setAllBusinessVerticalsCheckboxData,
  closeDeleteBusinessVerticalsModal,
  showDeleteBusinessVerticalsModal,
  setIsOpenAddNewBusinessVertical,
  fetchBusinessVerticalsCompanies,
  setBusinessVerticalCheckboxData,
  closeBusinessVerticalOverview,
  showBusinessVerticalOverview,
  fetchToolsBusinessVerticals,
  addToolsNewBusinessVertical,

  // Feedbacks
  closeFeedbackOverview,
  showFeedbackOverview,
  fetchFeedbacks,
  updateFeedback,

  // Purposes
  fetchPurposes,
  setPurposesCheckboxData,
  setAllPurposesCheckboxData,
  setIsOpenAddNewPurpose,
  addNewPurpose,
  showDeletePurposeModal,
  closeDeletePurposeModal,
  removePurpose,
  updatePurpose,
  showPurposeOverview,
  closePurposeOverview,

  // Recommended hotels
  setIsOpenAddNewHotelRecommendation,
  fetchRecommendedHotels,
  showDeleteRecommendedHotelsModal,
  closeDeleteRecommendedHotelsModal,
  setRecommendedHotelsCheckboxData,
  setAllRecommendedHotelsCheckboxData,
  fetchRecommendedHotelsCompanies: fetchToolsTeamsCompanies,
  fetchRecommendedHotelsPurposes: fetchPurposes,
  showRecommendedHotelOverview,
  closeRecommendedHotelOverview,
  addNewRecommendedHotel,
  updateRecommendedHotel,

  // Subscriptions
  fetchSubscriptions,
  showSubscriptionOverview,
  closeSubscriptionOverview,
  updateSubscription,
  fetchSubscriptionsLogs,

  // Actions and subscriptions
  fetchActionsAndNotifications,
  showActionAndNotificationOverview,
  closeActionAndNotificationOverview,
  fetchActionsAndNotificationsLogs,
  setActionsCheckboxData,
  setAllActionsCheckboxData,
  updateActionAndSubscription,
  updateActionStatus,

  // Employment types
  fetchEmploymentTypes,
  showEmployerTypeOverview,
  closeEmployerTypeOverview,
  showDeleteEmploymentTypesModal,
  closeDeleteEmploymentTypesModal,
  setEmploymentTypesCheckboxData,
  setAllEmploymentTypesCheckboxData,
  setIsOpenAddNewEmploymentType,
  removeEmploymentType,
  updateEmploymentType,
  addNewEmploymentType,
  updateEmploymentTypeStatus,

  // Ignore list accommodations
  setAllBookingForIgnoreCheckboxData,
  setBookingForIgnoreCheckboxData,
  removeBookingFromAccommodationsIgnoreList,
  addBookingToAccommodationsIgnoreList,
  getAccommodationsIgnoreList,
  closeBookingForIgnoreCreationModal,
  showBookingForIgnoreCreationModal,
  closeBookingForIgnoreDeleteModal,
  showBookingForIgnoreDeleteModal,
  closeBookingForIgnoreOverview,
  showBookingForIgnoreOverview,
  setBookingForIgnoreDeletionLoading,
  setBookingForIgnoreCreationLoading,

  // Custom hotels 
  setAllCustomHotelsCheckboxData,
  closeDeleteCustomHotelsModal,
  setIsUpdatedCustomHotelsData,
  setCustomHotelsCheckboxData,
  showDeleteCustomHotelsModal,
  setIsOpenAddNewCustomHotel,
  closeCustomHotelOverview,
  showCustomHotelOverview,
  fetchCustomHotels,
  removeCustomHotel,
  addNewCustomHotel,
  updateCustomHotel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
