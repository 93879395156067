import styled from 'styled-components';

export const Wrapper = styled.div``;

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 164px;
  width: 100%;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 164px;
  width: 100%;
  text-align: left;

  ${({ withHover }) =>
    withHover && `
      cursor: pointer;
   `}

  & input {
    &::placeholder {
      font-size: 11px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-top: 20px;

  & button {
    max-width: 123px;
    border-radius: 8px;
    margin-left: 10px;
  }
`;
