import {
  SET_DOWN_DRAWER_TRIP_STATUS_MODAL,
  CLOSE_POSSIBLE_TRIPS_FOR_MERGING,
  OPEN_POSSIBLE_TRIPS_FOR_MERGING,
  CLOSE_UNASSIGNED_ACCOMMODATIONS,
  SET_OVERVIEW_TRIP_STATUS_MODAL,
  OPEN_UNASSIGNED_ACCOMMODATIONS,
  SET_FLIGHT_SELECTED_AIRLINES,
  SET_FLIGHT_SELECTED_AIRPORTS,
  SET_TABLE_TRIP_STATUS_MODAL,
  SET_TRIPS_FOR_MERGING_DATA,
  CLOSE_UNASSIGNED_FLIGHTS,
  SET_TRIPS_CHECKBOX_DATA,
  OPEN_UNASSIGNED_FLIGHTS,
  CLOSE_DELETE_TRIP_MODAL,
  OPEN_DELETE_TRIP_MODAL,
  SET_TRIPS_OPTIONS_DATA,
  DELETE_OTHER_TRANSPORT,
  UPDATE_PAYMENT_INVOICE,
  UPDATE_PAYMENT_STATUS,
  SET_DELETE_TRIP_DATA,
  DELETE_ACCOMMODATION,
  CLOSE_TRIP_OVERVIEW,
  OPEN_TRIP_OVERVIEW,
  CLOSE_TRIP_TICKET,
  OPEN_TRIP_TICKET,
  UPDATE_FEEDBACK,
  SET_TRIPS_DATA,
  TOGGLE_FILTERS,
  DELETE_FLIGHT,
  CLOSE_FILTERS,
  OPEN_FILTERS,
} from './actionTypes';
import { updatePaymentStatus } from './utils';

const initState = {
  totalItems: 0,
  tripsData: [],
  tripsForMergingData: [],
  isOpenOverview: false,
  isPossibleTripsForMergingOpen: false,
  overviewData: null,
  isFiltersOpen: false,
  isUnassignedFlightsOpen: false,
  isUnassignedAccommodationsOpen: false,
  isOpenTicket: false,
  ticketData: {},
  tripOptions: {
    travelDocumentTypes: [],
    companyEmployees: [],
    typeOfTravelers: [],
    relativeDates: [],
    currencies: [],
    feedbacks: [],
    countries: [],
    tripTypes: [],
    employers: [],
    statuses: [],
    purposes: [],
    cabins: [],
    teams: [],
  },
  checkboxesData: [],
  isDeleteModal: false,
  deletableTrips: [],
  tableTripStatusModal: {
    status: false,
    tableKey: null,
  },
  isOverviewStatusModal: false,
  isDownDrawerStatusModal: false,
  externalTrip: {
    selectedOptions :{
      airports: [],
      airlines: [],
    }
  }
};

const tripsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TRIPS_DATA:
      return {
        ...state,
        tripsData: action.payload.data,
        totalItems: action.payload.total,
      };

    case SET_TRIPS_FOR_MERGING_DATA:
      return {
        ...state,
        tripsForMergingData: action.payload.data,
      };

    case SET_TABLE_TRIP_STATUS_MODAL:
      return {
        ...state,
        isOverviewStatusModal: false,
        isDownDrawerStatusModal: false,
        tableTripStatusModal: {
          status: action.payload.status,
          tableKey: action.payload.tableKey,
        },
      };

    case SET_OVERVIEW_TRIP_STATUS_MODAL:
      return {
        ...state,
        isOverviewStatusModal: action.payload.status,
        isDownDrawerStatusModal: false,
        tableTripStatusModal: {
          status: false,
          tableKey: null,
        },
      };

    case SET_DOWN_DRAWER_TRIP_STATUS_MODAL:
      return {
        ...state,
        isDownDrawerStatusModal: action.payload.status,
        isOverviewStatusModal: false,
        tableTripStatusModal: {
          status: false,
          tableKey: null,
        },
      };

    case OPEN_DELETE_TRIP_MODAL:
      return {
        ...state,
        isDeleteModal: true,
        deletableTrips: action.payload.deletableTrips,
      };

    case CLOSE_DELETE_TRIP_MODAL:
      return {
        ...state,
        isDeleteModal: false,
        deletableTrips: [],
      };
    
    case OPEN_UNASSIGNED_FLIGHTS: 
      return {
        ...state,
        isFiltersOpen: false,
        isUnassignedFlightsOpen: true,
        isUnassignedAccommodationsOpen: false,
        isOpenOverview: false,
        isOpenTicket: false,
        isPossibleTripsForMergingOpen: false,
      }
    case CLOSE_UNASSIGNED_FLIGHTS: 
      return {
        ...state,
        isUnassignedFlightsOpen: false,
      }
    case OPEN_UNASSIGNED_ACCOMMODATIONS: 
    return {
      ...state,
      isFiltersOpen: false,
      isUnassignedFlightsOpen: false,
      isUnassignedAccommodationsOpen: true,
      isOpenOverview: false,
      isOpenTicket: false,
      isPossibleTripsForMergingOpen: false,
    }
    case CLOSE_UNASSIGNED_ACCOMMODATIONS: 
      return {
        ...state,
        isUnassignedAccommodationsOpen: false,
      }
    case TOGGLE_FILTERS:
      return {
        ...state,
        isFiltersOpen: !state.isFiltersOpen,
        isOpenOverview: false,
        isOpenTicket: false,
        isPossibleTripsForMergingOpen: false,
        isUnassignedFlightsOpen: false,
        isUnassignedAccommodationsOpen: false,
        overviewData: {},
        ticketData: {},
        tripsData: state.tripsData.map((trip) => ({ ...trip, isSelected: false })),
      };

    case CLOSE_FILTERS:
      return {
        ...state,
        isFiltersOpen: false,
      };

    case OPEN_FILTERS:
      return {
        ...state,
        isFiltersOpen: true,
        isUnassignedFlightsOpen: false,
        isUnassignedAccommodationsOpen: false,
        isOpenOverview: false,
        isOpenTicket: false,
        isPossibleTripsForMergingOpen: false,
        overviewData: {},
        ticketData: {},
        tripsData: state.tripsData.map((trip) => ({ ...trip, isSelected: false })),
      };

    case OPEN_TRIP_OVERVIEW:
      return {
        ...state,
        isOpenOverview: true,
        isUnassignedFlightsOpen: false,
        isUnassignedAccommodationsOpen: false,
        overviewData: action.payload.data,
        tripsData: state.tripsData.map((trip) => ({
          ...trip,
          isSelected: trip.id === action.payload.data.id,
        })),
        isFiltersOpen: false,
        isOpenTicket: false,
        isPossibleTripsForMergingOpen: false,
        ticketData: {},
      };

    case CLOSE_TRIP_OVERVIEW:
      return {
        ...state,
        isOpenOverview: false,
        overviewData: {},
        tripsData: state.tripsData.map((trip) => ({ ...trip, isSelected: false })),
      };

    case OPEN_TRIP_TICKET:
      return {
        ...state,
        isOpenTicket: true,
        ticketData: action.payload.data,
        tripsData: state.tripsData.map((trip) => ({
          ...trip,
          isSelected: trip.id === action.payload.data.id,
        })),
        isOpenOverview: false,
        isFiltersOpen: false,
        isUnassignedFlightsOpen: false,
        isUnassignedAccommodationsOpen: false,
        isPossibleTripsForMergingOpen: false,
        overviewData: {},
      };

    case CLOSE_TRIP_TICKET:
      return {
        ...state,
        isOpenTicket: false,
        ticketData: {},
        tripsData: state.tripsData.map((trip) => ({ ...trip, isSelected: false })),
      };

    case SET_TRIPS_OPTIONS_DATA:
      return {
        ...state,
        tripOptions: action.payload.data,
      };

    case SET_DELETE_TRIP_DATA:
      return {
        ...state,
        deletableTrips: [],
        checkboxesData: [],
        isOpenOverview: false,
        isDeleteModal: false,
        isOpenTicket: false,
        totalItems: state.totalItems - 1,
        tripsData: state.tripsData.filter((trip) => trip.id !== action.payload.data),
      };
    case SET_TRIPS_CHECKBOX_DATA:
      const { data, isRemoveAll } = action.payload;

      if (isRemoveAll) {
        return {
          ...state,
          checkboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          checkboxesData: [...state.checkboxesData, data],
        };
      }

      return {
        ...state,
        checkboxesData: state.checkboxesData.filter((item) => item.tripId !== data.tripId),
      };

    case DELETE_FLIGHT:
    case DELETE_ACCOMMODATION:
    case DELETE_OTHER_TRANSPORT:
      return {
        ...state,
        isOpenOverview: false,
        isOpenTicket: false,
        overviewData: {},
        ticketData: {},
      };
    case OPEN_POSSIBLE_TRIPS_FOR_MERGING:
      return {
        ...state,
        isOpenTicket: false,
        isUnassignedFlightsOpen: false,
        isUnassignedAccommodationsOpen: false,
        isOpenOverview: false,
        isFiltersOpen: false,
        isPossibleTripsForMergingOpen: true,
      };
    case CLOSE_POSSIBLE_TRIPS_FOR_MERGING:
      return {
        ...state,
        isPossibleTripsForMergingOpen: false,
      };
      case SET_FLIGHT_SELECTED_AIRPORTS:{
        return {
          ...state,
          externalTrip: {
            selectedOptions :{
              ...state.externalTrip.selectedOptions,
              airports: [...state.externalTrip.selectedOptions.airports, ...action.payload]
            }
          }
        }
      }
      case SET_FLIGHT_SELECTED_AIRLINES:{
        return {
          ...state,
          externalTrip: {
            selectedOptions :{
              ...state.externalTrip.selectedOptions,
              airlines: [...state.externalTrip.selectedOptions.airlines, ...action.payload]
            }
          }
        }
      }   

      case UPDATE_FEEDBACK:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            feedbacks: state.overviewData.feedbacks.map(feedback => {
              if (feedback.id !== action.payload.feedbackId) {
                return feedback;
              }

              return action.payload.data;
            }),
          },
        };

      case UPDATE_PAYMENT_STATUS:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            paymentRequests: state.overviewData.paymentRequests.map(paymentRequest => ({
              ...paymentRequest,
              payments: updatePaymentStatus({
                ...action.payload,
                payments: paymentRequest.payments,
              }),
            })),
            payments: updatePaymentStatus({
              ...action.payload,
              payments: state.overviewData.payments,
            }),
          },
        };

    case UPDATE_PAYMENT_INVOICE:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          payments: state.overviewData.payments.map(payment => {
            if (payment.id === action.payload.paymentId) {
              return {
                ...payment,
                balancedIoInvoiceNumber: action.payload.balancedIoInvoiceNumber,
              };
            }
            
            return payment;
          }),
        },
      };

    default:
      return state;
  }
};

export default tripsReducer;
