import { useEffect, useState } from 'react';
import { Checkbox } from '@heathmont/moon-core';
import { IRecommendedHotelOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper } from './styles';
import { formatHotelsRecommendationsDates, formatLabelForApplyToAll, formatPurposes, getLabels, getTeamStatusLabel } from '../../utils/tableHelpers';
import Button from '../Button';
import { GenericEdit } from '@heathmont/moon-icons';
import RecommendedHotelsOverviewDrawerEditContent from '../RecommendedHotelsOverviewDrawerEditContent';
import { PurposeTypes } from '../../enums/PurposeTypes';

const RecommendedHotelOverviewDrawerContent: React.FC<IRecommendedHotelOverviewDrawerContent> = ({
  data,
  purposes,
  companies,
  getTripPurposeLabel,
  handleUpdate,
}) => {
  const {
    applyToAllPurposes,
    applyToAllTeams,
    applyToAllDates,
    city,
    hotel,
    purposes: itemPurposes,
    startDate,
    endDate,
    company,
    comment,
    status,
  } = data;

  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data.id]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            handleClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="default"
            label="Edit"
          />
        </EditButtonWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>ID</OverviewItemName>
        <OverviewItem>{data.id}</OverviewItem>
      </OverviewItemsWrapper>

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>City</OverviewItemName>
            <OverviewItem>{city ? `${city.name} (${city.countryName})` : ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Hotel</OverviewItemName>
            <OverviewItem>{hotel?.name ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Dates</OverviewItemName>
            <OverviewItem>{
              applyToAllDates
                ? formatLabelForApplyToAll('dates')
                : formatHotelsRecommendationsDates(startDate, endDate)
            }</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Apply to all dates</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={!!applyToAllDates} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Purpose</OverviewItemName>
            <OverviewItem>{
              applyToAllPurposes
                ? formatLabelForApplyToAll('purposes')
                : formatPurposes(itemPurposes.filter(purpose => purpose.type === PurposeTypes.INTERNAL))
            }</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Apply to all purposes</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={!!applyToAllPurposes} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Company</OverviewItemName>
            <OverviewItem>{company?.name ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Teams</OverviewItemName>
            <OverviewItem>{
              applyToAllTeams 
                ? formatLabelForApplyToAll('teams')
                : getLabels(company.teams, 'name')
            }</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Apply to all teams</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={!!applyToAllTeams} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Comment</OverviewItemName>
            <OverviewItem>{comment ?? 'N/A'}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>
            <OverviewItem>{getTeamStatusLabel(status)}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <RecommendedHotelsOverviewDrawerEditContent
          data={data}
          setIsEditFormData={setIsEditFormData}
          handleUpdate={handleUpdate}
          purposes={purposes}
          getTripPurposeLabel={getTripPurposeLabel}
          companies={companies}
        />
      )}
    </div>
  );
};

export default RecommendedHotelOverviewDrawerContent;
