import { GenericHome, TravelAirplane, OtherRocket } from '@heathmont/moon-icons';
import { ReactComponent as CircleSvg } from '../../static/icons/circle.svg';
import { ITripStepper } from './types';
import { getTime, getDate } from './utils';
import { ContentItem, DateInfo, Duration, Wrapper, Content, Label, Icon, Date, Time, Children } from './styles';

const TripStepper: React.FC<ITripStepper> = ({
  startLabel,
  startDate,
  endLabel,
  duration,
  endDate,
  withAccommodation = false,
  withOtherTransport = false,
  children,
}) => {
  return (
    <Wrapper>
      <Content>
        <ContentItem>
          <Icon color="#78787D">
            <CircleSvg />
          </Icon>

          <DateInfo>
            <div>
              <Time>{getTime(startDate)}</Time>
              <Date>{getDate(startDate)}</Date>
            </div>

            <Label>{startLabel}</Label>
          </DateInfo>
        </ContentItem>

        <ContentItem>
          <Icon withMargin>
            {withAccommodation ? <GenericHome fontSize="21px" /> : withOtherTransport ? <OtherRocket fontSize="21px" /> : <TravelAirplane fontSize="21px" />}
          </Icon>

          <Duration>{duration}</Duration>
        </ContentItem>

        <ContentItem>
          <Icon color="#78787D">
            <CircleSvg />
          </Icon>

          <DateInfo>
            <div>
              <Time>{getTime(endDate)}</Time>
              <Date>{getDate(endDate)}</Date>
            </div>

            <Label>{endLabel}</Label>
          </DateInfo>
        </ContentItem>
      </Content>

      <Children>{children}</Children>
    </Wrapper>
  );
};

export default TripStepper;
