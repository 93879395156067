import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserRoleType } from '../../enums/UserRoleTypes';

import './Footer.scss';

const Footer = ({ isAuthenticated, isAppReady, userRole }) => {
  const { t } = useTranslation();

  if (!isAppReady) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <footer>
        <div className="footer-contacts">
          <div>
            <NavLink to="/signin">{t('footer.links.unauthorized.signin')}</NavLink>
            <NavLink to="/forgot-password">{t('footer.links.unauthorized.forgot-password')}</NavLink>
            <NavLink to="/signup">{t('footer.links.unauthorized.signup')}</NavLink>
          </div>
          <div>
            <a href="#">
              <FontAwesomeIcon icon="life-ring" />
              {t('footer.links.unauthorized.support')}
            </a>
          </div>
          <div className="ms-auto">
            <div>@{process.env.NODE_ENV}</div>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer>
      <div className="footer-contacts">
        {[UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Travelops].includes(userRole) && (
          <div>
            <NavLink to="/trips/create">
              <strong>{t('footer.links.authorized.trips_create')}</strong>
            </NavLink>
            <NavLink to="/trips">{t('footer.links.authorized.trips')}</NavLink>
          </div>
        )}
        {[UserRoleType.Admin, UserRoleType.Travelops] && (
          <>
            <div>
              <NavLink to="/companies">{t('footer.links.authorized.companies')}</NavLink>
              <NavLink to="/users">{t('footer.links.authorized.users')}</NavLink>
              <NavLink to="/domains">{t('footer.links.authorized.domains')}</NavLink>
              <NavLink to="/roles">{t('footer.links.authorized.roles')}</NavLink>
            </div>
            <div>
              <NavLink to="/teams">{t('footer.links.authorized.teams')}</NavLink>
              <NavLink to="/employers">{t('footer.links.authorized.employers')}</NavLink>
              <NavLink to="/invitations">{t('footer.links.authorized.invitations')}</NavLink>
            </div>
          </>
        )}
        <div>
          <a href="#">
            <FontAwesomeIcon icon="life-ring" />
            {t('footer.links.unauthorized.support')}
          </a>
        </div>
        <div className="ms-auto">
          <div>@{process.env.NODE_ENV}</div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAppReady: PropTypes.bool,
};

Footer.defaultProps = {
  isAuthenticated: false,
  isAppReady: false,
};

export default Footer;
