import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Guests',
    accessor: 'guests',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Reference',
    accessor: 'reference',
    minWidth: 180,
  },
];
