import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 576px;
  min-height: 180px;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #ffffff;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 24px 24px 0 24px;
`;

export const HeaderTitle = styled(Text).attrs({
  size: 18,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const RemoveIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  border: 1px solid #e9e7e7;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #4f545c;
  background-color: #ffffff;
  border: 1px dashed #e9e7e7;
  border-radius: 4px;
  width: 122px;
  height: 122px;
  cursor: pointer;
  margin: 10px 10px 0 0;

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        border: 1px dashed #275adb;
      }
  `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 10px 24px;
  margin-top: 15px;
  border-top: 1px solid #e0e0e0;
  gap: 10px;
`;

export const BodyWrapper = styled.div`
  padding: 0 24px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: 10px 10px 0 0;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const PreviewImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const FileWrapper = styled.div`
  width: 100px;
  height: 100px;
`;

export const ErrorStyled = styled.p`
  color: ${({ theme }) => theme.color.chiChi['100']};
  font-size: 1rem;
  font-weight: 400;
`;
