export const SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA = 'tools/SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA';
export const UPDATE_COMPANY_HIBOB_INTEGRATION = 'tools/UPDATE_COMPANY_HIBOB_INTEGRATION';
export const DELETE_COMPANY_HIBOB_INTEGRATION = 'tools/DELETE_COMPANY_HIBOB_INTEGRATION';
export const UPDATE_COMPANY_JIRA_INTEGRATION = 'tools/UPDATE_COMPANY_JIRA_INTEGRATION';
export const DELETE_COMPANY_JIRA_INTEGRATION = 'tools/DELETE_COMPANY_JIRA_INTEGRATION';
export const UPDATE_COMPANY_BALANCED_STATUS = 'tools/UPDATE_COMPANY_BALANCED_STATUS';
export const ADD_COMPANY_HIBOB_INTEGRATION = 'tools/ADD_COMPANY_HIBOB_INTEGRATION';
export const ADD_COMPANY_JIRA_INTEGRATION = 'tools/ADD_COMPANY_JIRA_INTEGRATION';
export const UPDATE_COMPANY_BASIC_INFO = 'tools/UPDATE_COMPANY_BASIC_INFO';
export const FETCH_COMPANIES_SUCCESS = 'tools/FETCH_COMPANIES_SUCCESS';
export const CLOSE_COMPANY_OVERVIEW = 'tools/CLOSE_COMPANY_OVERVIEW';
export const FETCH_COMPANIES_ERROR = 'tools/FETCH_COMPANIES_ERROR';
export const UPDATE_COMPANY_STATUS = 'tools/UPDATE_COMPANY_STATUS';
export const OPEN_COMPANY_OVERVIEW = 'tools/OPEN_COMPANY_OVERVIEW';
export const FETCH_COMPANIES = 'tools/FETCH_COMPANIES';
