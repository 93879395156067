import React, { useCallback, useEffect } from 'react';
import { FormikProvider, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@heathmont/moon-core';
import { Checkbox } from '@heathmont/moon-core';
import { TextInputGroup } from '@heathmont/moon-select';
import useCustomFormik from '../../hooks/useCustomFormik';
import FormField from '../FormField';
import { loginFormSchema } from './schema';
import { HeathmontLogo } from '../HeathmontLogo';
import GoogleLoginButton from '../GoogleLoginButton';
import { TogglePasswordText } from '../TogglePasswordText';
import { loginUser } from '../../store/auth/api';
import { ILoginForm } from './types';
import {
  Content,
  Header,
  HeaderWrapper,
  Wrapper,
  Splitter,
  SplitterText,
  SplitterWrapper,
  CheckboxWrapper,
  Footer,
  ButtonLabel,
  ErrorMessageWrapper,
  ErrorMessage,
} from './styles';
import { useTranslation } from 'react-i18next';
import { setAuthError } from '../../store/auth/actions';

const initialValues = {
  remember: false,
  password: '',
  email: '',
};

const LoginForm: React.FC<ILoginForm> = ({ isMobile }) => {
  const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const loginErrorText = useSelector((state: any) => state.auth.error);

  const handleSubmit = useCallback(async (values) => {
    await dispatch(loginUser(values));
  }, []);

  useEffect(() => {
    if (loginErrorText) {
      let timer1 = setTimeout(() => dispatch(setAuthError(null)), 6000);

      return () => clearTimeout(timer1);
    }
  }, [loginErrorText]);

  const formik = useCustomFormik(initialValues, loginFormSchema, handleSubmit);

  const isErrorEmail = !!formik.errors?.email && formik.touched?.email;
  const isErrorPassword = !!formik.errors?.password && formik.touched?.password;

  return (
    <Wrapper isMobile={isMobile}>
      <HeaderWrapper>
        <HeathmontLogo style={{ margin: 0 }} />
      </HeaderWrapper>

      <Header>Log in to your account</Header>

      <Content>
        <GoogleLoginButton />

        <SplitterWrapper>
          <Splitter>
            <SplitterText>Or continue with</SplitterText>
          </Splitter>
        </SplitterWrapper>

        <FormikProvider value={formik}>
          <Form>
            <FormField
              component={TextInputGroup}
              orientation="vertical"
              inputProps={{
                input1: {
                  id: 'email',
                  type: 'email',
                  value: formik.values.email,
                  inputSize: 'xlarge',
                  label: 'Email address',
                  onChange: (e) => formik.setFieldValue('email', e.target.value),
                  onBlur: formik.handleBlur,
                  isError: isErrorEmail,
                },
                input2: {
                  id: 'password',
                  type: 'password',
                  value: formik.values.password,
                  inputSize: 'xlarge',
                  label: 'Password',
                  onChange: (e) => formik.setFieldValue('password', e.target.value),
                  onBlur: formik.handleBlur,
                  showPasswordText: <TogglePasswordText isLogin />,
                  isError: isErrorPassword,
                },
              }}
            />

            {(!loginErrorText && (isErrorEmail || isErrorPassword)) && (
              <ErrorMessageWrapper>
                {isErrorEmail && <ErrorMessage>{formik.errors?.email}</ErrorMessage>}
                {isErrorPassword && !isErrorEmail && <ErrorMessage>{formik.errors?.password}</ErrorMessage>}
              </ErrorMessageWrapper>
            )}

            {loginErrorText && (
              <ErrorMessageWrapper>
                <ErrorMessage>{t(loginErrorText)}</ErrorMessage>
               
              </ErrorMessageWrapper>
            )}
          </Form>
        </FormikProvider>

        <CheckboxWrapper>
          <Checkbox label="Remember me" onChange={(e) => formik.setFieldValue('remember', e.target.checked)} />
        </CheckboxWrapper>

        <Footer isMobile={isMobile}>
          <div>
            <Link to="/forgot-password">
              <ButtonLabel>Forgot your password?</ButtonLabel>
            </Link>
          </div>

          <div>
            <Button
              onClick={() => formik.handleSubmit()}
              fullWidth
              variant="primary"
              size={isMobile ? 'large' : 'medium'}
              /* @ts-ignore */
              disabled={isErrorEmail || isErrorPassword}
            >
              Log in
            </Button>
          </div>
        </Footer>
      </Content>
    </Wrapper>
  );
};

export default LoginForm;
