import moment from 'moment';
import _ from 'lodash';

import { ACCOMMODATION_PROVIDERS, FLIGHTS_PROVIDERS, FLIGHT_SOURCE } from '../constants'; 

export const getCancellationPolicies = (accommodation) => {
  try {
    return JSON.parse(accommodation.cancellationPenalty);
  } catch (err) {
    return [];
  }
};

export const formatCancellationDate = (date) => moment(date).format('MMM DD, YYYY HH:mm');

export  const getCancellationFeeText = (policy) => {
  const startAt = policy.start_at ? formatCancellationDate(policy.start_at) : null;
  const endAt = policy.end_at ? formatCancellationDate(policy.end_at) : null;
  const isFreeCancellation = !+policy.amount_show;
  const fee = `${policy.amount_show} EUR`;
  let text;

  if (isFreeCancellation) {
    text = `Free cancellation until ${endAt}`; 
  } else {
    text = `Cancellation fee ${fee}`;

    if (startAt) {
      text += ` from ${startAt}`;
    }

    if (endAt) {
      text += ` to ${endAt}`;
    }
  }

  return text;
};

export const getFlightDelayToCancel = (flight) => {
  if (flight && flight.delay_to_cancel) {
    return formatCancellationDate(flight.delay_to_cancel);
  }

  return null;
}

export const isFlightCancelable = (flight) => {
  if (flight.provider?.keyword === FLIGHTS_PROVIDERS.EXTERNAL) {
    return true
  }

  if (!flight.source || !getFlightDelayToCancel(flight)) {
    return false
  }

  const flightSources = flight.source ? flight.source.split('/') : [];

  return flightSources.every(source => source === FLIGHT_SOURCE.GDS);
};

export const isAccommodationCancelable = (accommodation) => {
  if (accommodation.provider?.keyword === ACCOMMODATION_PROVIDERS.EXTERNAL) {
    return true
  }

  const cancellationPolicies = getCancellationPolicies(accommodation);
  return cancellationPolicies && cancellationPolicies.length > 0;
};

export const getTripTime = (trip) => {
  const tripStartDate = moment(trip?.tripStartDate).format('MMM D, YYYY');
  const tripFinishDate = moment(trip?.tripFinishDate).format('MMM D, YYYY');

  return `${tripStartDate} - ${tripFinishDate}`;
};

export const defineFlightDepartureCity = (flight) => {
  const outboundItinerarySegments = _.get(flight, 'itineraries.0.segments', []);
  const firstSegment = _.get(outboundItinerarySegments, '0', {});
  return _.get(firstSegment, 'departure.location.city');
};

export const defineFlightReturnCity = (flight) => {
  const outboundItinerarySegments = _.get(flight, 'itineraries.0.segments', []);
  const lastSegment = _.get(outboundItinerarySegments, outboundItinerarySegments.length - 1, {});
  return _.get(lastSegment, 'arrival.location.city');
};

export const getAirportByCode = (selectedAirports, airportCode) => {
  const foundAirport = selectedAirports.find((airport) => airport?.value === airportCode);

  if (foundAirport?.city) {
    return `${foundAirport.city} (${airportCode})`;
  }; 

  return foundAirport?.title || '';
};

export const getTripDuration = (trip: { tripStartDate: Date, tripFinishDate: Date }): number => {
  const startDate = moment(trip.tripStartDate);
  const endDate = moment(trip.tripFinishDate);
  const duration = moment.duration(endDate.diff(startDate));
  return duration.asDays();
};

export const getFlightEndDate = (flightOrders = []) => {
  const finishDates = [];

  flightOrders.forEach(flightOrder => {
    const lastItinerary = flightOrder.itineraries[flightOrder.itineraries.length - 1];
    const lastSegment = lastItinerary.segments[lastItinerary.segments.length - 1];
    finishDates.push(moment(lastSegment.arrival.at));
  });
  
  return moment.max(finishDates).toDate();
};

export const sortTripsByCreatedAt= (trips:any = []) => {
  return _.orderBy(trips, (trip) => new Date(trip.createdAt), ['desc']);
}