import React from 'react';
import { getCronText } from '../../utils/commonFunctions';
import { ICronParser } from './types';
import { CronTextWrapper, CronText } from './styles';

const CronParser: React.FC<ICronParser> = ({ cronExpression }) => {
  const text = getCronText(cronExpression);

  return <CronTextWrapper>{text ? <CronText>{text}</CronText> : <CronText>Is not valid cron value</CronText>}</CronTextWrapper>;
};

export default CronParser;
