import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { BusinessVerticalType } from '../../../../src/enums/BusinessVerticalType';
import { 
  fetchCompaniesDefaultTravelopses,
  fetchCompaniesTripPurposes,
  fetchCompaniesUsersByRole
} from '../teams/api';

export const removeBusinessVertical = (businessVerticalIds) => {
  return Promise.allSettled(businessVerticalIds.map((businessVerticalId) => {
    return request
      .delete(`tools/business-verticals/${businessVerticalId}`)
      .then(() => {
        getErrorStatusMessage({
          status: 200,
          message: `Business vertical with ID - ${businessVerticalId} successfully removed`,
        });
      })
      .catch((e) => {
        getErrorStatusMessage({
          status: e?.response?.status,
          message: `Business vertical with ID - ${businessVerticalId} can't be removed`,
        });
      });
  }));
};

export const fetchCompanyBusinessVerticals = async (companyId: number): Promise<any[]> => {
  try {
    const url = `tools/business-verticals?filters[company]=${companyId}`;
    const { data } = await request.get(url);
    return data.records.map((el) => ({ 
        value: el.id,
        title: el.name,
        accountants: el.accountants,
        type: el.type,
        travelopses: el.travelopses,
        employers: el.employers,
      }));
  } catch (error) {
    return [];
  }
};

export const fetchCompaniesDefaultAccountants = async (companyId: number) => {
  try {
    const { data } = await request.get(`tools/companies/default-accountants/${companyId}`);
    return data.map((el) => ({ value: el.id, title: `${el.firstname} ${el.lastname}`, businessVertical: el.businessVertical }));
  } catch (error) {
    return [];
  }
};

export const fetchCompaniesDefaultCeos = async (companyId: number) => {
  try {
    const { data } = await request.get(`tools/companies/default-ceos/${companyId}`);
    return data.map((el) => ({ value: el.id, title: `${el.firstname} ${el.lastname}`, businessVertical: el.businessVertical }));
  } catch (error) {
    return [];
  }
};

export const fetchOptionsForCompany = async (companyId: number): Promise<{
  defaultTravelopses: any[];
  defaultAccountants: any[];
  defaultCeos: any[];
  businessVerticals: any[];
  tripPurposes: any[];
  travelopses: any[];
  managers: any[];
}> => {
  const [defaultTravelopses, defaultAccountants, defaultCeos, tripPurposes, travelopses, managers, businessVerticals ] = await Promise.all([
    fetchCompaniesDefaultTravelopses(companyId),
    fetchCompaniesDefaultAccountants(companyId),
    fetchCompaniesDefaultCeos(companyId),
    fetchCompaniesTripPurposes(companyId),
    fetchCompaniesUsersByRole(companyId, 'travelopses'),
    fetchCompaniesUsersByRole(companyId, 'managers'),
    fetchCompanyBusinessVerticals(companyId),
  ]);

  return {
    defaultTravelopses,
    defaultAccountants,
    defaultCeos,
    businessVerticals,
    tripPurposes,
    travelopses,
    managers,
  };
};