import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'teamId',
    minWidth: 60,
    disableSortBy: false,
  },
  {
    Header: 'Team',
    accessor: 'teamName',
    minWidth: 120,
    disableSortBy: false,
  },
  {
    Header: 'Company',
    accessor: 'company',
    minWidth: 120,
    disableSortBy: false,
  },
  {
    Header: 'Business Vertical',
    accessor: 'businessVertical',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Managers',
    accessor: 'manager',
    minWidth: 150,
    disableSortBy: true,
  },
  {
    Header: 'Travelopses',
    accessor: 'travelops',
    minWidth: 150,
    disableSortBy: true,
  },
  {
    Header: 'Trip purposes',
    accessor: 'tripPurposes',
    minWidth: 180,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    minWidth: 100,
    disableSortBy: true,
  },
];
