import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateAll } from 'indicative/validator';
import { Heading, Button } from '@heathmont/moon-components';
import { Footer, TextInput, Text } from '@heathmont/moon-core';
import { Helmet } from 'react-helmet';
import request from '../../models/request';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBg';
import { HeathmontLogo } from '../../components/HeathmontLogo';
import { Form, FormContent, TertButtonWrapper } from '../../styled';
import { SuccessMessage } from '../../components/SuccessMessage';

const validationRules = {
  email: 'required|email',
};

const ForgotPassword = ({ loginEmail }) => {
  const [email, setEmail] = useState(loginEmail || '');
  const [validationError, setValidationError] = useState(null);
  const [backendErrorMessage, setBackendErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    try {
      event?.preventDefault();
      setBackendErrorMessage(null);
      setValidationError(null);
      try {
        await validateAll({ email }, validationRules);
      } catch (errors) {
        const formattedErrors = {};
        errors.forEach((error) => {
          formattedErrors[error.field] = error.message;
        });
        setValidationError(formattedErrors);
        return;
      }
      const response = await request.post('auth/forgot-password', {
        email,
      });
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      const errorInfo = request.extractErrors(error);
      console.error('login error', errorInfo);
      setBackendErrorMessage(Array.isArray(errorInfo) ? Object.values(errorInfo[0])[0] : t(errorInfo));
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <FullScreenRandomBg>
        {success ? (
          <SuccessMessage
            heading="Thanks, check your email for instructions to reset your password"
            message="If you haven't received an email in 5 minutes, check your spam or resend"
            link="/signin"
            linkText="Return to sign in"
            isTertiary
          />
        ) : (
          <Form onSubmit={async (event) => await handleSubmit(event)}>
            <FormContent>
              <HeathmontLogo />
              <Heading style={{ marginBottom: 20 }} size={32}>
                Forgot your password?
              </Heading>
              <Text size={16} color="bulma.100" style={{ marginBottom: 24 }}>
                Enter the email address associated with your account, and we’ll email you a link to reset your password.
              </Text>
              <TextInput
                onChange={({ target }) => {
                  setEmail(target.value);
                }}
                type="text"
                inputSize="xlarge"
                label="E-mail address"
                value={email}
                isError={validationError?.email}
                hintText={
                  (validationError?.email || backendErrorMessage) && (
                    <span style={{ marginLeft: 12 }}>{validationError?.email || backendErrorMessage}</span>
                  )
                }
              />
              <br />
            </FormContent>
            <Footer
              primButton={
                <Button
                  // disabled={!stepThreeChecked}
                  type="submit"
                  fullWidth
                  variant="primary"
                  size="small"
                >
                  Submit
                </Button>
              }
              tertButton={
                <TertButtonWrapper>
                  <Link to="/signin">
                    <Button type="button" fullWidth variant="tertiary" size="small">
                      Return to sign in
                    </Button>
                  </Link>
                </TertButtonWrapper>
              }
              isDivider
            />
          </Form>
        )}
      </FullScreenRandomBg>
    </>
  );
};

export default ForgotPassword;
