import {
  SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA,
  DELETE_COMPANY_HIBOB_INTEGRATION,
  UPDATE_COMPANY_HIBOB_INTEGRATION,
  UPDATE_COMPANY_JIRA_INTEGRATION,
  DELETE_COMPANY_JIRA_INTEGRATION,
  UPDATE_COMPANY_BALANCED_STATUS,
  ADD_COMPANY_HIBOB_INTEGRATION,
  ADD_COMPANY_JIRA_INTEGRATION,
  UPDATE_COMPANY_BASIC_INFO,
  FETCH_COMPANIES_SUCCESS,
  CLOSE_COMPANY_OVERVIEW,
  FETCH_COMPANIES_ERROR,
  UPDATE_COMPANY_STATUS,
  OPEN_COMPANY_OVERVIEW,
  FETCH_COMPANIES,
} from './actionTypes';

const initState = {
  isUpdatedCompanyData: false,
  overviewCompanyData: null,
  isOverviewOpen: false,
  isLoadingData: false,
  companies: [],
};

const employersReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        isLoadingData: true,
      };
    
    case FETCH_COMPANIES_ERROR:
      return {
        ...state,
        isLoadingData: false,
        data: [],
      };

    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        companies: action.payload.data,
      };

    case OPEN_COMPANY_OVERVIEW: {
      return {
        ...state,
        overviewCompanyData: state.companies.find(({ id }) => id === action.payload.id),
        isOverviewOpen: true,
      };
    }

    case CLOSE_COMPANY_OVERVIEW: {
      return {
        ...state,
        overviewCompanyData: null,
        isOverviewOpen: false,
      };
    }

    case SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA: 
    return {
      ...state,
      isUpdatedCompanyData: action.payload.isUpdated,
    };

    case UPDATE_COMPANY_BASIC_INFO: {
      const forUpdate = {
        accommodationAdditionalPricePercent: action.payload.company.accommodationAdditionalPricePercent,
        flightAdditionalPricePercent: action.payload.company.flightAdditionalPricePercent,
        accountantsChannelName: action.payload.company.accountantsChannelName,
        travelopsesChannelName: action.payload.company.travelopsesChannelName,
        feedbackChannelName: action.payload.company.feedbackChannelName,
        travelGuideline: action.payload.company.travelGuideline,
        contactEmail: action.payload.company.contactEmail,
        admins: action.payload.company.admins,
        defaultTravelopses: action.payload.company.defaultTravelopses,
        name: action.payload.company.name,
      };

      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, ...forUpdate },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, ...forUpdate };
          }

          return company;
        }),
      };
    }

    case UPDATE_COMPANY_BALANCED_STATUS: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, balancedStatus: action.payload.balancedStatus },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, balancedStatus: action.payload.balancedStatus };
          }

          return company;
        }),
      };
    }

    case UPDATE_COMPANY_STATUS: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, status: action.payload.status },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, status: action.payload.status };
          }

          return company;
        }),
      };
    }

    case DELETE_COMPANY_HIBOB_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, hibob: null },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, hibob: null };
          }

          return company;
        }),
      };
    }

    case ADD_COMPANY_HIBOB_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, hibob: action.payload.hibob },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, hibob: action.payload.hibob };
          }

          return company;
        }),
      };
    }

    case UPDATE_COMPANY_HIBOB_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, hibob: action.payload.hibob },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, hibob: action.payload.hibob };
          }

          return company;
        }),
      };
    }

    case DELETE_COMPANY_JIRA_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, jira: null },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, jira: null };
          }

          return company;
        }),
      };
    }

    case ADD_COMPANY_JIRA_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, jira: action.payload.jira },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, jira: action.payload.jira };
          }

          return company;
        }),
      };
    }

    case UPDATE_COMPANY_JIRA_INTEGRATION: {
      return {
        ...state,
        overviewCompanyData: { ...state.overviewCompanyData, jira: action.payload.jira },
        companies: state.companies.map(company => {
          if (company.id === action.payload.id) {
            return { ...company, jira: action.payload.jira };
          }

          return company;
        }),
      };
    }

    default:
      return state;
  }
};

export default employersReducer;
