import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const OverviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
`;

export const OverviewContentName = styled(Text).attrs({
  size: 14,
  color: 'trunks.100',
})`
  line-height: 24px;
  max-width: 164px;
`;

export const OverviewContentValue = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  max-width: 164px;
  border: 2px solid transparent;
  width: 160px;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 10px;
  margin-bottom: 30px;

  & span {
    font-size: 16px;
  }
`;
