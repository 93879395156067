import styled from 'styled-components';
import { Button } from '@heathmont/moon-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  background: #f6f5f4;
  box-shadow: 0px -4px 16px rgba(170, 182, 190, 0.2), inset 0px 1px 0px #e6e9eb;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 0px 8px 20px;
  z-index: 1000;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled.span`
  width: 14px;
  height: 14px;
  margin-right: 14px;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  & button {
    border-radius: 8px;
    padding: 8px 12px;
    margin: 0px 8px;
  }
`;

export const DataWrapper = styled.div``;

export const SecondaryButton = styled(Button)`
  background: inherit;
  border-color: #e6e9eb;
`;

export const DangerButton = styled(Button)`
  background: inherit;
  color: ${({ theme }) => theme.color.chiChi['100']};
  border-color: ${({ theme }) => theme.color.chiChi['100']};
`;

export const Text = styled(MoonText).attrs({
  size: 14,
})`
  color: ${({ color }) => color || '#181B1C'};
  line-height: 24px;
  max-width: 164px;
  display: inline-block;
  margin-right: 4px;
`;
