import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span style={{ width: '50px' }} {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'businessVerticalId',
    disableSortBy: false,
    minWidth: 80,
  },
  {
    Header: 'Business Vertical',
    accessor: 'name',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Company',
    accessor: 'company',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'CEO(s)',
    accessor: 'ceo',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Travelopses',
    accessor: 'travelops',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Accountants',
    accessor: 'accountants',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Employees',
    accessor: 'employees',
    disableSortBy: false,
    minWidth: 180,
    height: 200,
  },
];
