import styled from 'styled-components';

export const SecurityStyled = styled.div`
  margin-bottom: 20px;
`;

export const DoubleFieldWrapper = styled.div`
  margin: 24px 0;

  & div {
    & div {
      font-size: 14px;

      &::after {
        z-index: 0;
      }
    }
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;

  & input {
    background-color: transparent;
  }
`;
