import { SET_AUTHENTICATED, SET_PROCESSING, SET_TOKEN, LOGOUT, SET_AUTH_ERROR } from './actionTypes';
import localStorageHelper from '../../utils/localstorage';

const initState = {
  isAuthenticated: !!localStorageHelper.get('token'),
  isAuthFormProcessing: false,
  authToken: localStorageHelper.get('token', null),
  error: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGOUT: 
      return {
        ...state,
        isAuthFormProcessing: false,
        isAuthenticated: false,
        authToken: null,
      };
    case SET_TOKEN:
      return {
        ...state,
        authToken: action.data.authToken,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.data.isAuthenticated,
      };
    case SET_PROCESSING:
      return {
        ...state,
        isAuthFormProcessing: action.data.isAuthFormProcessing,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
