import { TripStatusUpdateFields } from '../../enums/TripStatusUpdateFields';
import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import { UserStatuses } from '../../enums/UserStatuses';
import { Payment } from '../../enums/Payment';

export const updatePaymentStatus = ({
  balancedIoInvoiceNumber,
  externalStatus,
  paymentId,
  payments,
  status,
}: {
  balancedIoInvoiceNumber?: string | null,
  externalStatus: string,
  paymentId: string,
  payments: Payment[],
  status: PAYMENT_STATUS,
}): Payment[] => {
  return payments.map(payment => {
    if (payment.id === paymentId) {
      return {
        ...payment,
        balancedIoInvoiceNumber: balancedIoInvoiceNumber
          ? balancedIoInvoiceNumber
          : payment.balancedIoInvoiceNumber,
        externalStatus: externalStatus 
          ? externalStatus 
          : payment.externalStatus,
        status: status,
      };
    }

    return payment;
  });
};

export const isTravelerExternal = (trip: TripStatusUpdateFields): boolean => {
  return trip.userType === UserStatuses.External;
};

export const isPaymentRequired = (trip: TripStatusUpdateFields): boolean => {
  return !!trip.paymentRequired;
};

export const getStatusUpdatePath = (trip: TripStatusUpdateFields): string => {
  const path = ['trips'];

  if (isTravelerExternal(trip) || isPaymentRequired(trip)) {
    path.push('external');
  }

  path.push('status', `${trip.tripId}`);

  return path.join('/');
};
