import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Heading, Button } from '@heathmont/moon-components';
import { Footer, TextInput, Text } from '@heathmont/moon-core';
import { Helmet } from 'react-helmet';
import request from '../../models/request';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBg';
import { HeathmontLogo } from '../../components/HeathmontLogo';
import { PasswordValidator } from '../../components/PasswordValidator';
import { Form, FormContent } from '../../styled';
import { SuccessMessage } from '../../components/SuccessMessage';
import { TogglePasswordText } from '../../components/TogglePasswordText';

const ResetPassword = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { code } = queryString.parse(search);

  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!code) {
      history.push('/signin');
      
    }
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setBackendErrorMessage(null);

      const response = await request.post('auth/reset-password', {
        passwordConfirmation: password,
        password,
        code,
      });
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      const errorInfo = request.extractErrors(error);
      console.error('login error', errorInfo);
      setBackendErrorMessage(Array.isArray(errorInfo) ? Object.values(errorInfo[0])[0] : t(errorInfo));
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <FullScreenRandomBg>
        {success ? (
          <SuccessMessage
            heading="Thanks, check your email for instructions to reset your password"
            message=""
            link="/signin"
            linkText="Move to Sign In"
          />
        ) : (
          <Form onSubmit={async (event) => await handleSubmit(event)}>
            <FormContent>
              <HeathmontLogo />
              <Heading style={{ marginBottom: 20 }} size={32}>
                Reset your password
              </Heading>
              <Text size={16} color="bulma.100" style={{ marginBottom: 24 }}>
                Strong passwords include numbers, letters, and punctuation marks.
              </Text>
              <TextInput
                onChange={({ target }) => {
                  setPassword(target.value);
                }}
                value={password}
                type="password"
                inputSize="xlarge"
                label="Password"
                showPasswordText={<TogglePasswordText />}
              />
              <PasswordValidator password={password} setPasswordValid={setPasswordValid} />
              {backendErrorMessage && (
                <Text size={16} color="bulma.100" style={{ marginBottom: 24 }}>
                  {backendErrorMessage}
                </Text>
              )}
              <br />
            </FormContent>
            <Footer
              primButton={
                <Button disabled={!passwordValid} type="submit" fullWidth variant="primary" size="small">
                  Reset password
                </Button>
              }
              isDivider
            />
          </Form>
        )}
      </FullScreenRandomBg>
    </>
  );
};

export default ResetPassword;
