import { useMemo, useState } from 'react';
import { Button, Text } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import Accordion from '../Accordion';
import CustomSelect from '../CustomSelect';
import { N_A, getErrorStatusMessage } from '../../utils/commonFunctions';
import { UserRoleType } from '../../enums/UserRoleTypes';
import {
  formatStartAndEndDates,
  defineTripTypeIcon,
  formatDateAndTime,
  formatTtrLink,
  formatManager,
  getUserLabelSuperscript,
} from '../../utils/tableHelpers';
import { IOverviewDrawerContent } from './types';
import { TripStatusType } from '../../enums/TripStatusType';
import {
  OverviewContentWrapper,
  OverviewContentValue,
  OverviewContentName,
  OverviewWrapper,
  UserNameWrapper,
  OverviewContent,
  PurposeWrapper,
  DialogContent,
  EditableText,
  UserNameText,
} from './styles';
import { getPurposesAvailableForSelection, getAvailableStatuses, isUserShouldHaveLabel } from './utils';
import Dialog from '../Dialog';
import request from '../../models/request';
import { UserStatuses } from '../../enums/UserStatuses';
import TripChangePurposeDialog from './TripChangePurposeDialog';
import TripPaymentRequests from '../TripPaymentRequests';
import TripApprovalFlow from './TripApprovalFlow';
import TripTotalCosts from './TripTotalCosts';
import TripInfoBlocks from './TripInfoBlocks';
import EllipsisText from '../EllipsisText';
import TripPayments from '../TripPayments';
import TripFeedback from './TripFeedback';
import TripActions from './TripActions';

const OverviewDrawerContent: React.FC<IOverviewDrawerContent> = ({
  userRole,
  statuses,
  purposes,
  data,
  handleOpenReSendPaymentRequestModal,
  handleEditDeclineReasonModalOpen,
  handleRequestPaymentModalOpen,
  requestToSubmitExpensesReport,
  handleCreateInvoiceModalOpen,
  handleSendTripToVerification,
  handleChangeTripDestination,
  handleEditCommentModalOpen,
  handleChangeTripPurpose,
  handleChangeTripStatus,
  onChangePaymentStatus,
  getTripPurposeLabel,
  handleSyncUpdates,
  requestFeedback,
  getStatusLabel,
  updateFeedback,
  createTTR,
}) => {
  const [isTripPurposeEditModalOpen, setIsTripPurposeEditModalOpen] = useState<boolean>(false);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState<boolean>(false);
  const isDeclined = useMemo(() => data.status === TripStatusType.DECLINED, [data.status]);
  const isApproved =  useMemo(() => data.status === TripStatusType.APPROVED || data.status === TripStatusType.PURCHASED, [data.status]);
  const isPurchased = useMemo(() => data.status === TripStatusType.PURCHASED, [data.status]);
  const isPending = useMemo(() => data.status === TripStatusType.PENDING, [data.status]);
  const isCurrentUserTravelops = useMemo(() => userRole === UserRoleType.Travelops, [userRole]);
  const isTravelerExternal = useMemo(() =>  data?.userType === UserStatuses.External, [data?.userType]); 
  const isTravelerActive = useMemo(() =>  data?.user?.status === UserStatuses.Registered, [data?.user]); 
  const paymentRequired = useMemo(() => !!data?.purpose?.paymentRequired, [data?.purpose?.paymentRequired]);
  const shouldShowApprovalFlow = useMemo(() => isDeclined || isApproved || isPurchased, [isDeclined, isApproved, isPurchased]);
  const isManagerApprovalRequired = useMemo(() => !isTravelerExternal && !paymentRequired, [isTravelerExternal, paymentRequired]);
  const tripStatuses = useMemo(() => getAvailableStatuses(statuses, isManagerApprovalRequired), [statuses, isManagerApprovalRequired]);
  const tripPurposes = useMemo(() => getPurposesAvailableForSelection(purposes), [purposes]);
  const canEditTripPurpose = useMemo(() => (isCurrentUserTravelops && !paymentRequired && !isTravelerExternal), [
    isCurrentUserTravelops, paymentRequired, isTravelerExternal,
  ]);

  const onChangeTripPurpose = (purpose: { purposeDetails: string, purposeId: number }) => {
    handleChangeTripPurpose(data, purpose);
    setIsTripPurposeEditModalOpen(false);
  };

  const handleOpenNotifyConfirmationModal = () => {
    setIsSendNotificationModalOpen(true);
  };

  const closeNotifyModal = () => {
    setIsSendNotificationModalOpen(false)
  };

  const handleNotifyManagers = async () => {
    try {
      await request.post(`trips/notify-to-approve/${data?.id}`);
  
      getErrorStatusMessage({
        status: 200,
        message: 'Notification to approve sent.',
      });
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    } finally {
      closeNotifyModal();
    }
  };

  return (
    <>
      <OverviewWrapper>
        <Accordion title="General information" openByDefault withoutPadding>
          <OverviewContentWrapper>
            {/* Info blocks */}
            <TripInfoBlocks
              isCurrentUserTravelops={isCurrentUserTravelops}
              isTravelerExternal={isTravelerExternal}
              isTravelerActive={isTravelerActive}
              paymentRequired={paymentRequired}
              isPurchased={isPurchased}
              isApproved={isApproved}
              trip={data}
              requestToSubmitExpensesReport={requestToSubmitExpensesReport}
              handleSendTripToVerification={handleSendTripToVerification}
              createTTR={createTTR}
            />

            <OverviewContent>
              <OverviewContentName>Trip ID</OverviewContentName>
              <OverviewContentValue>{data?.id}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>User</OverviewContentName>
              <OverviewContentValue>
                <UserNameWrapper withLabel={isUserShouldHaveLabel(data?.user?.status)}>
                  <UserNameText>
                    {data?.user?.fullName}
                  </UserNameText>
                  {getUserLabelSuperscript(data?.user, true)}
                </UserNameWrapper>
              </OverviewContentValue>
            </OverviewContent>

            {
              !!data?.contactEmail && (
                <OverviewContent>
                  <OverviewContentName>Private contact email</OverviewContentName>
                  <OverviewContentValue>
                    <EllipsisText text={data?.contactEmail} />
                  </OverviewContentValue>
                </OverviewContent>
              )
            }

            <OverviewContent>
              <OverviewContentName>Team</OverviewContentName>
              <OverviewContentValue>{data?.user?.team?.name ?? ''}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Type</OverviewContentName>
              <OverviewContentValue>{defineTripTypeIcon(data.tripType)}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Destination</OverviewContentName>
              <OverviewContentValue>
                {
                  isCurrentUserTravelops ? (
                    <EditableText>
                      {data?.destinationLabel}
                      <Button
                        iconOnly={<GenericEdit />}
                        size="small"
                        variant="ghost"
                        type="button"
                        onClick={() => handleChangeTripDestination(data)}
                      />
                    </EditableText>
                  ) : (
                    data?.destinationLabel
                  )
                }
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Trip time</OverviewContentName>
              <OverviewContentValue>{formatStartAndEndDates(data.tripStartDate, data.tripFinishDate)}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Purpose</OverviewContentName>
              <OverviewContentValue>
                <EditableText>
                  <PurposeWrapper withEditIcon={canEditTripPurpose}>
                    {getTripPurposeLabel(data.purpose.id)}
                  </PurposeWrapper>
                  {
                    canEditTripPurpose && (
                      <Button
                        iconOnly={<GenericEdit />}
                        variant="ghost"
                        size="small"
                        type="button"
                        onClick={() => setIsTripPurposeEditModalOpen(true)}
                      />
                    )
                  }
                </EditableText>
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Purpose details</OverviewContentName>
              <OverviewContentValue>
                {data?.purposeDetails || N_A}
              </OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Status</OverviewContentName>
              <OverviewContentValue>
                {isDeclined ? (
                  getStatusLabel(data?.status)
                ) : (
                  <CustomSelect
                    currentValue={data?.status}
                    items={tripStatuses}
                    getItemLabel={getStatusLabel}
                    onChange={(value) => {
                      const tripStatusUpdateFields = {
                        paymentRequired: data?.purpose?.paymentRequired,
                        userType: data?.userType,
                        tripId: data?.id,
                      };
                      return handleChangeTripStatus(tripStatusUpdateFields, value);
                    }}
                  />
                )}
              </OverviewContentValue>
            </OverviewContent>

            {
              isDeclined && (
                <OverviewContent>
                  <OverviewContentName>Decline reason</OverviewContentName>
                  <OverviewContentValue>
                    {
                      isCurrentUserTravelops ? (
                        <EditableText>
                          {data?.declineReason || N_A}
                          <Button
                            iconOnly={<GenericEdit />}
                            size="small"
                            variant="ghost"
                            type="button"
                            onClick={() => handleEditDeclineReasonModalOpen(data)}
                          />
                        </EditableText>
                      ) : (
                        data?.declineReason || N_A
                      )
                    }
                  </OverviewContentValue>
                </OverviewContent>
              )
            }

            {!isTravelerExternal && (
              <OverviewContent>
                <OverviewContentName>Manager</OverviewContentName>
                <OverviewContentValue>{formatManager(data?.user?.manager)}</OverviewContentValue>
              </OverviewContent>
            )}
            
            {!isTravelerExternal && data?.jiraTicketNumber && (
              <OverviewContent>
                <OverviewContentName>TTR link</OverviewContentName>
                <OverviewContentValue>
                  {formatTtrLink(data?.jiraTicketNumber, data?.companyJiraHost)}
                </OverviewContentValue>
              </OverviewContent>
            )}

            <OverviewContent>
              <OverviewContentName>Created at</OverviewContentName>
              <OverviewContentValue>{formatDateAndTime(data.createdAt, true)}</OverviewContentValue>
            </OverviewContent>

            <OverviewContent>
              <OverviewContentName>Comment</OverviewContentName>
              <OverviewContentValue>
                {
                  isCurrentUserTravelops ? (
                    <EditableText>
                      {data?.comment || N_A}
                      <Button
                        iconOnly={<GenericEdit />}
                        size="small"
                        variant="ghost"
                        type="button"
                        onClick={() => handleEditCommentModalOpen(data)}
                      />
                    </EditableText>
                  ) : (
                    data?.comment || N_A
                  )
                }
              </OverviewContentValue>
            </OverviewContent>

            <TripTotalCosts
              isTravelerExternal={isTravelerExternal}
              paymentRequired={paymentRequired}
              trip={data}
            />

            {/* Actions */}
            {
              isCurrentUserTravelops && (
                <TripActions
                  isTravelerExternal={isTravelerExternal}
                  paymentRequired={paymentRequired}
                  isPurchased={isPurchased}
                  isApproved={isApproved}
                  isPending={isPending}
                  notifyToApprove={() => handleOpenNotifyConfirmationModal()}
                  requestPayment={() => handleRequestPaymentModalOpen(data)}
                  syncUpdates={() => handleSyncUpdates(data.id)}
                />
              )
            }
          </OverviewContentWrapper>
        </Accordion>

        {/* Approval flow */}
        {shouldShowApprovalFlow && (
          <Accordion title="Approval flow" openByDefault withoutPadding>
            <TripApprovalFlow trip={data} />
          </Accordion>
        )}
      
        {isCurrentUserTravelops && (
          <>
            {/* Payments */}
            {data?.payments?.length > 0 && (
              <Accordion title={`Payment details`} openByDefault withoutPadding>
                <TripPayments
                  payments={data?.payments}
                  tripId={data?.id}
                  onChangePaymentStatus={onChangePaymentStatus}
                  handleCreateInvoiceModalOpen={handleCreateInvoiceModalOpen}
                />
              </Accordion>
            )}

            {/* Payment requests  */}
            {data?.paymentRequests?.length > 0 && (
              <Accordion title={`Payment requests`} openByDefault withoutPadding>
                <TripPaymentRequests
                  paymentRequests={data?.paymentRequests}
                  flights={data?.flights}
                  tripId={data?.id}
                  handleReSendRequest={handleOpenReSendPaymentRequestModal}
                />
              </Accordion>
            )}
          </>
        )}

        {/* Feedback */}
        <Accordion title="Feedbacks" openByDefault withoutPadding>
          <TripFeedback
            isCurrentUserTravelops={isCurrentUserTravelops}
            isTravelerExternal={isTravelerExternal}
            isTravelerActive={isTravelerActive}
            paymentRequired={paymentRequired}
            isPurchased={isPurchased}
            trip={data}
            requestFeedback={requestFeedback}
            updateFeedback={updateFeedback}
          />
        </Accordion>
      </OverviewWrapper>
  
      {/* Dialogs */}
      {isSendNotificationModalOpen && (
        <Dialog
          submitButtonLabel="Send"
          title="Notify to approve"
          onSubmit={handleNotifyManagers}
          onClose={closeNotifyModal}
        >
          <DialogContent>
            <Text size={16}>Do you want to resend notifications about the trip to the manager?</Text>
          </DialogContent>
        </Dialog>
      )}

      {isTripPurposeEditModalOpen && (
        <TripChangePurposeDialog
          tripPurposes={tripPurposes}
          trip={data}
          handleChangeTripPurpose={onChangeTripPurpose}
          getTripPurposeLabel={getTripPurposeLabel}
          onClose={() => setIsTripPurposeEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default OverviewDrawerContent;
