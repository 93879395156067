import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  position: relative;
  max-width: 624px;
  width: 100%;

  background: #ffffff;
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  ${({ isMobile }) =>
    isMobile
      ? `
    height: 100%;
    padding: 24px;
  `
      : `
    max-height: 584px;
    height: 100%;
    padding: 40px;
  `}
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

export const Content = styled.div`
  width: 100%;
`;

export const SplitterWrapper = styled.div`
  margin: 32px 0 32px 0;
`;

export const CheckboxWrapper = styled.div`
  margin: 16px 0 16px 0;
`;

export const Splitter = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

export const SplitterText = styled(Text).attrs({
  size: 12,
  color: 'bulma.100',
})`
  position: absolute;
  line-height: 16px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 0 10px;
  color: #78787d;
`;

export const Header = styled(Text).attrs({
  size: 32,
  color: 'bulma.100',
})`
  line-height: 40px;
  margin: 0 0 32px 0;
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;

  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #ffffff;

  ${({ isMobile }) =>
    isMobile
      ? `
      padding: 0 24px;
      bottom: 0px;
      height: 100px;
  `
      : `
      bottom: -20px;
      padding: 0 40px;
      height: 65px;
  `}
`;

export const ButtonLabel = styled.span`
  border-bottom: 1px solid #181b1c;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  transition: all .3s ease-out;

  &:hover {
    cursor: pointer;
    text-shadow: 0 0 0.01px black;
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  height: 40px;
  margin: 5px 0;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff0000;
  padding: 5px 5px 0 0;
  margin-top: -5px;
`;
