import Navigation from '../components/Navigation/Navigation';
import { NavBlock, NavWrapper } from '../styled';

const withNavigation = (Component: any) => (props: any) => {
  return (
    <NavWrapper>
      <NavBlock>
        <Navigation {...props} />
      </NavBlock>
      <Component {...props} />
    </NavWrapper>
  );
};

export default withNavigation;
