import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { IRoles } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import TablePagination from '../../../components/TablePagination';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { toolsRolesDataMapper } from '../../../utils/tableHelpers';
import Drawer from '../../../components/Drawer';
import { strToUpperCase, сheckOnSpaces } from '../../../utils/commonFunctions';
import RolesOverviewDrawerContent from '../../../components/RolesOverviewDrawerContent';
import DownDrawer from '../../../components/DownDrawer';
import Dialog from '../../../components/Dialog';
import AddNewRole from '../../../components/AddNewRole';
import { addNewRole, removeRole } from '../../../store/tools/roles/api';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';

const defaultRoleNameData = {
  isOpen: false,
  pages: [],
  name: '',
  id: null,
};

const Roles: React.FC<IRoles> = ({
  isActiveAllRolesCheckboxes,
  rolesCheckboxesData,
  isOpenRolesOverview,
  isDeleteRolesModal,
  overviewRolesData,
  isOpenAddNewRole,
  deletableRoles,
  totalRoles,
  filters,
  sortBy,
  roles,
  pages,
  page,
  setAllRolesCheckboxData,
  closeDeleteRolesModal,
  showDeleteRolesModal,
  setRolesCheckboxData,
  setIsOpenAddNewRole,
  closeRoleOverview,
  showRoleOverview,
  setQueryParams,
  fetchPagesData,
  fetchRoles,
  updateRole,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [newRoleData, setNewRoleData] = useState({
    name: '',
    pages: [],
  });
  const [roleNameData, setRoleNameData] = useState(defaultRoleNameData);

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);

  const handleChangeOverviewRoleData = useCallback((data) => {
    setRoleNameData((prevState) => ({ ...prevState, ...data }));
  }, []);

  useMemo(() => {
    setRoleNameData(defaultRoleNameData);
  }, []);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchPagesData();

    return () => {
      closeRoleOverview();
    };
  }, []);

  useEffect(() => {
    fetchRoles(params);
    closeRoleOverview();
  }, [filters, page, sortBy]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const handleAddNewRole = useCallback(
    async (data: any) => {
      setIsOpenAddNewRole(false);

      const updatedData = { ...data, name: data.name.trim(), pages: data.pages.map((item) => item.id) };

      await addNewRole(updatedData);
      await fetchRoles(params);

      setNewRoleData({
        name: '',
        pages: [],
      });
    },
    [setIsOpenAddNewRole]
  );

  const handleRemoveRole = useCallback(
    async (data: any) => {
      setIsOpenAddNewRole(false);

      await removeRole(data);
      await fetchRoles(params);

      setRolesCheckboxData();
      closeDeleteRolesModal();
    },
    [setIsOpenAddNewRole]
  );

  const handleUpdateRole = useCallback(
    async (data: any) => {
      await updateRole(data);
      await fetchRoles(params);

      handleChangeOverviewRoleData({ isOpen: false });
    },
    [setIsOpenAddNewRole]
  );

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showRoleOverview(roles[row?.id]);
    },
    [roles]
  );

  const handleCloseAddNewRole = useCallback(() => {
    setNewRoleData({
      name: '',
      pages: [],
    });

    setIsOpenAddNewRole(false);
  }, []);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const rolesGroupedData = useMemo(() => toolsRolesDataMapper(roles), [roles]);

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete';

    if ((rolesCheckboxesData && rolesCheckboxesData.length > 1) || isActiveAllRolesCheckboxes) {
      return `${title} these roles?`;
    }

    return `${title} this role?`;
  }, [isActiveAllRolesCheckboxes, rolesCheckboxesData]);

  useMemo(() => {
    totalItems.current = totalRoles;
  }, [totalRoles]);
  
  const tableContentRecieverProps = {
    isActiveAllRolesCheckboxes,
    isShowTableFilters,
    isToolsRolesPage: true,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: rolesCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    page,
    data: rolesGroupedData,
    handleSetAllCheckboxs: setAllRolesCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: !hoveredRow?.original?.isDefaultRole && showDeleteRolesModal,
    toggleTableFilters,
    handleSetCheckbox: setRolesCheckboxData,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Roles</Title>

          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewRole(true)}>
            <ControlsPlus /> Add role
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.ROLES}
          totalItems={totalItems}
          data={roles}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchRoles(params)}
          nextPage={nextPage}
          prevPage={prevPage}
          callback={setAllRolesCheckboxData}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isDeleteRolesModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableRoles}
          onClose={closeDeleteRolesModal}
          onSubmit={handleRemoveRole}
        >
          <Text size={16}>{deleteDialogText}</Text>
        </Dialog>
      )}

      {isOpenAddNewRole && (
        <Dialog
          submitButtonLabel="Add"
          isSubmitDisabled={сheckOnSpaces(newRoleData.name) || !newRoleData.name || newRoleData.name.length < 2 || newRoleData.name.length > 100}
          title="Add role"
          data={newRoleData}
          onSubmit={handleAddNewRole}
          onClose={handleCloseAddNewRole}
        >
          <AddNewRole pages={pages} newRoleData={newRoleData} setNewRoleData={setNewRoleData} />
        </Dialog>
      )}

      {isOpenRolesOverview && (
        <div>
          <Drawer handleClose={closeRoleOverview} title={strToUpperCase(overviewRolesData?.name) ?? ''}>
            <RolesOverviewDrawerContent
              id={overviewRolesData?.id}
              amount={overviewRolesData?.amount}
              usersPages={overviewRolesData?.pages}
              allPages={pages}
              roleNameData={roleNameData}
              currentRoleName={strToUpperCase(overviewRolesData?.name) ?? ''}
              handleChangeOverviewRoleData={handleChangeOverviewRoleData}
              handleUpdateRole={handleUpdateRole}
              isDefaultRole={!!overviewRolesData.default}
            />
          </Drawer>
        </div>
      )}

      {!!rolesCheckboxesData.length && (
        <DownDrawer
          data={rolesCheckboxesData}
          handleSetCheckbox={setRolesCheckboxData}
          handleRemoveDialog={showDeleteRolesModal}
          text="role"
        />
      )}
    </>
  );
};

export default Roles;
