import { useState } from 'react';
import { ControlsChevronUpSmall, ControlsChevronDownSmall } from '@heathmont/moon-icons';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@heathmont/moon-core';
import { ICheckboxGroup, IOptionsItem } from './types';
import { CheckboxLabel, MoreText, MoreWrapper, Wrapper } from './styles';

const CheckboxGroup: React.FC<ICheckboxGroup> = ({
  selectedValues,
  data = [],
  renderCheckboxLabel,
  onSelect,
}) => {
  const [isMoreData, setIsMoreData] = useState({ itemsToShow: 4, isExpanded: false });

  const { t, i18n } = useTranslation();

  const showMore = () => {
    isMoreData.itemsToShow === 4
      ? setIsMoreData({ itemsToShow: data.length, isExpanded: true })
      : setIsMoreData({ itemsToShow: 4, isExpanded: false });
  };

  return (
    <Wrapper>
      {data.slice(0, isMoreData.itemsToShow).map(({ value, title }: IOptionsItem) => (
        <Checkbox
          key={value}
          checked={(selectedValues ?? []).some((selectedValue) => selectedValue === value)}
          label={renderCheckboxLabel ? (
            renderCheckboxLabel(title)
          ) : (
            <CheckboxLabel>{i18n.exists(title) ? t(title) : title}</CheckboxLabel>
          )}
          onChange={() => onSelect(value)}
        />
      ))}

      {data.length > 4 && (
        <MoreWrapper onClick={showMore}>
          <MoreText>{isMoreData.isExpanded ? 'Less' : 'More'}</MoreText>

          {isMoreData.isExpanded ? <ControlsChevronUpSmall /> : <ControlsChevronDownSmall />}
        </MoreWrapper>
      )}
    </Wrapper>
  );
};

export default CheckboxGroup;
