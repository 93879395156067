import { useEffect, useMemo, useState } from 'react';
import { GenericEdit, ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import { ICompanies } from './types';
import Drawer from '../../../components/Drawer';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import InvitationCompanyForm from '../../../components/InviteCompanyForm';
import CompanyDrawerContent from '../../../components/CompanyDrawerContent';
import InfoBlock from '../../../components/InfoBlock';
import { Spinner } from '../../../components/Spinner';
import Tooltip from '../../../components/Tooltip';
import Dialog from '../../../components/Dialog';
import request from '../../../models/request';
import { TableWrapper, PageHeader } from '../styles';
import { getTeamStatusLabel } from '../../../utils/tableHelpers';
import {
  CompanyCardEditButton,
  CompanyCardBlock,
  CompanyCardTitle,
  CompaniesWrapper,
  CompanyCardLabel,
  ItemSeparator,
  CompanyCard,
  Title,
} from './styles';

const Companies: React.FC<ICompanies> = ({
  isUpdatedCompanyData,
  overviewCompanyData,
  isOverviewOpen,
  isLoadingData,
  companies,
  filters,
  updateCompanyBalancedStatus,
  updateCompanyBasicInfo,
  updateHibobIntegration,
  deleteHibobIntegration,
  updateJiraIntegration,
  deleteJiraIntegration,
  closeCompanyOverview,
  addHibobIntegration,
  openCompanyOverview,
  updateCompanyStatus,
  addJiraIntegration,
  setQueryParams,
  fetchCompanies,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActivationPopupOpen, setIsActivationPopupOpen] = useState(false);
  const [isDeactivationPopupOpen, setIsDeactivationPopupOpen] = useState(false);
  const [isActivationBalancedPopupOpen, setIsActivationBalancedPopupOpen] = useState(false);
  const [isDeactivationBalancedPopupOpen, setIsDeactivationBalancedPopupOpen] = useState(false);
  const [isInviteCompanyModalOpen, setIsInviteCompanyModalOpen] = useState(false);
  const [statusForUpdate, setStatusForUpdate] = useState(null);
  const [balancedStatusForUpdate, setBalancedStatusForUpdate] = useState(null);

  const params = useMemo(() => setQueryParams(), [filters]);

  useEffect(() => {
    fetchCompanies(params);
  }, [filters, isUpdatedCompanyData]);

  const handleCloseInviteCompanyModal = () => {
    setIsInviteCompanyModalOpen(false);
  };

  const handleOpenInviteCompanyModal = () => {
    setIsInviteCompanyModalOpen(true);
  };

  const handleInviteCompany = async ({ email }) => {
    try {
      setIsLoading(true);
      await request.post('tools/companies/invitation', { email });
      handleCloseInviteCompanyModal();
      setIsLoading(false);
      getErrorStatusMessage({
        status: 200,
        message: `Invitation was sent successfully!`,
      });
    } catch (err) {
      handleCloseInviteCompanyModal();
      setIsLoading(false);
      getErrorStatusMessage({
        status: err?.response?.status,
        message: 'Invitation was not sent!',
      });
    }
  };

  const closeDeactivationPopup = () => {
    setIsDeactivationPopupOpen(false);
    setStatusForUpdate(null);
  };

  const closeActivationPopup = () => {
    setIsActivationPopupOpen(false);
    setStatusForUpdate(null);
  };

  const openUpdateStatusConfirmPopup = (status) => {
    if (status) {
      setIsActivationPopupOpen(true);
    } else {
      setIsDeactivationPopupOpen(true);
    }

    setStatusForUpdate(status);
  };

  const handleUpdateCompanyStatus = async (status) => {
    await updateCompanyStatus(overviewCompanyData?.id, status);
    closeDeactivationPopup();
    closeActivationPopup();
  };

  const closeDeactivationBalancedPopup = () => {
    setIsDeactivationBalancedPopupOpen(false);
    setBalancedStatusForUpdate(null);
  };

  const closeActivationBalancedPopup = () => {
    setIsActivationBalancedPopupOpen(false);
    setBalancedStatusForUpdate(null);
  };

  const openUpdateBalancedStatusConfirmPopup = (balancedStatus) => {
    if (balancedStatus) {
      setIsActivationBalancedPopupOpen(true);
    } else {
      setIsDeactivationBalancedPopupOpen(true);
    }

    setBalancedStatusForUpdate(balancedStatus);
  };

  const handleUpdateCompanyBalancedStatus = async (balancedStatus) => {
    await updateCompanyBalancedStatus(overviewCompanyData?.id, balancedStatus);
    closeDeactivationBalancedPopup();
    closeActivationBalancedPopup();
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Companies</Title>

          <Button variant="primary" size="small" onClick={handleOpenInviteCompanyModal}>
            <ControlsPlus /> Invite company
          </Button>
        </PageHeader>

        {isLoadingData ? (
          <Spinner />
        ) : (
          <CompaniesWrapper>
            {companies?.length ? (
              companies.map((company) => (
                <CompanyCard key={company.id} active={company.id === overviewCompanyData?.id}>
                  <CompanyCardTitle>{company.name}</CompanyCardTitle>
                  <CompanyCardBlock>
                    <CompanyCardLabel>{company?.admins?.length} admins</CompanyCardLabel>
                    <CompanyCardLabel>{company?.defaultTravelopses?.length} default travelopses</CompanyCardLabel>
                  </CompanyCardBlock>
                  <CompanyCardBlock>
                    {getTeamStatusLabel(company.status)}
                    <ItemSeparator>•</ItemSeparator>
                    <CompanyCardLabel>{company?.employees} employees</CompanyCardLabel>
                    <ItemSeparator>•</ItemSeparator>
                    <CompanyCardLabel>{company?.trips} successful trips</CompanyCardLabel>
                  </CompanyCardBlock>

                  <CompanyCardEditButton>
                    <Tooltip label="Edit">
                      <Button iconOnly size="small" onClick={() => openCompanyOverview(company.id)}>
                        <GenericEdit />
                      </Button>
                    </Tooltip>
                  </CompanyCardEditButton>
                </CompanyCard>
              ))
            ) : (
              <Text size={14} textAlign="center" styles={{ marginBottom: 0 }}>
                No records were found
              </Text>
            )}
          </CompaniesWrapper>
        )}
      </TableWrapper>

      {isOverviewOpen && (
        <div>
          <Drawer
            withStatus
            status={overviewCompanyData?.status}
            title={overviewCompanyData?.name}
            getStatusLabel={getTeamStatusLabel}
            handleClose={closeCompanyOverview}
          >
            <CompanyDrawerContent
              company={overviewCompanyData}
              updateCompanyBalancedStatus={openUpdateBalancedStatusConfirmPopup}
              updateCompanyBasicInfo={updateCompanyBasicInfo}
              updateHibobIntegration={updateHibobIntegration}
              deleteHibobIntegration={deleteHibobIntegration}
              updateJiraIntegration={updateJiraIntegration}
              deleteJiraIntegration={deleteJiraIntegration}
              addHibobIntegration={addHibobIntegration}
              updateCompanyStatus={openUpdateStatusConfirmPopup}
              addJiraIntegration={addJiraIntegration}
            />
          </Drawer>
        </div>
      )}

      {isActivationPopupOpen && (
        <Dialog
          submitButtonLabel="Activate"
          maxWidth={500}
          title="Company activation"
          data={statusForUpdate}
          onSubmit={handleUpdateCompanyStatus}
          onClose={closeActivationPopup}
        >
          <InfoBlock
            text={`
              Travel bot will be available to employees of this company
              and managers will have access to the back office.
              The company administrator will be notified by email about company activation.  
            `}
          />
          <Text size={16}>Are you sure you want to activate this company?</Text>
        </Dialog>
      )}

      {isDeactivationPopupOpen && (
        <Dialog
          submitButtonLabel="Deactivate"
          maxWidth={500}
          title="Company deactivation"
          data={statusForUpdate}
          onSubmit={handleUpdateCompanyStatus}
          onClose={closeDeactivationPopup}
        >
          <InfoBlock
            text={`
              Travel bot will no longer be available to employees of this company,
              as well as access to the back office.
              Company admins will be notified by email about company deactivation.
            `}
          />
          <Text size={16}>Are you sure you want to deactivate this company?</Text>
        </Dialog>
      )}

      {isActivationBalancedPopupOpen && (
        <Dialog
          submitButtonLabel="Activate"
          maxWidth={500}
          title="Balanced activation"
          data={balancedStatusForUpdate}
          onSubmit={handleUpdateCompanyBalancedStatus}
          onClose={closeActivationBalancedPopup}
        >
          <InfoBlock
            text={`Integration with balanced will be activated for this company.`}
          />
          <Text size={16}>Are you sure you want to activate balanced?</Text>
        </Dialog>
      )}

      {isDeactivationBalancedPopupOpen && (
        <Dialog
          submitButtonLabel="Deactivate"
          maxWidth={500}
          title="Balanced deactivation"
          data={balancedStatusForUpdate}
          onSubmit={handleUpdateCompanyBalancedStatus}
          onClose={closeDeactivationBalancedPopup}
        >
          <InfoBlock
            text={`Integration with balanced will no longer be available for this company.`}
          />
          <Text size={16}>Are you sure you want to deactivate balanced?</Text>
        </Dialog>
      )}

      {isInviteCompanyModalOpen && (
        <Dialog
          submitButtonLabel="Send"
          withFooter={false}
          maxWidth={400}
          title="Invite company"
          onClose={handleCloseInviteCompanyModal}
        >
          <InvitationCompanyForm
            isLoading={isLoading}
            onSubmit={handleInviteCompany}
            onCancel={handleCloseInviteCompanyModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default Companies;
