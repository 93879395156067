import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_DAILY_ALLOWANCES,
  ADD_DAILY_ALLOWANCE,
  DELETE_DAILY_ALLOWANCE,
  UPDATE_DAILY_ALLOWANCE,
  UPDATE_DAILY_ALLOWANCE_OPTIONS,
  SET_DAILY_ALLOWANCE_CHECKBOX_DATA,
  SET_ALL_DAILY_ALLOWANCE_CHECKBOX_DATA,
  ADD_NEW_CUSTOM_RULE,
  UPDATE_CUSTOM_RULE,
  DELETE_CUSTOM_RULE,
  SET_SHOULD_REFRESH_ON,
  SET_SHOULD_REFRESH_OFF,
} from './actionTypes';



const setDailyAllowances = (data) => ({ type: SET_DAILY_ALLOWANCES, payload: data });
const addNewDailyAllowance = (data) => ({ type: ADD_DAILY_ALLOWANCE, payload: data });
export const setShouldRefreshOn = () => ({ type: SET_SHOULD_REFRESH_ON });
export const setShouldRefreshOff = () => ({ type: SET_SHOULD_REFRESH_OFF });
const updateDailyAllowance = (data) => ({ type: UPDATE_DAILY_ALLOWANCE, payload: data });
const updateDailyAllowanceOptions = (data) => ({ type: UPDATE_DAILY_ALLOWANCE_OPTIONS, payload: data });
export const removeAllowance = (allowanceId) => ({ type: DELETE_DAILY_ALLOWANCE, payload: allowanceId });
export const setAllowancesCheckboxData = (checkboxData) => ({ type: SET_DAILY_ALLOWANCE_CHECKBOX_DATA, payload: { data: checkboxData } });

export const setAllAllowancesCheckboxData = (checkboxData = [], isActive = false) => ({
  type: SET_ALL_DAILY_ALLOWANCE_CHECKBOX_DATA,
  payload: { data: checkboxData, isActive },
});



export const fetchDailyAllowances = (params) => (dispatch) => {
  dispatch(setIsLoadingData(true));
  request
    .get('tools/daily-allowances', {params})
    .then(({ data }) => dispatch((setDailyAllowances(data))))
    .catch((e) => console.error(e))
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const addNewAllowance = ({ company, currency }) => async (dispatch) => {
  try {
    dispatch(addNewDailyAllowance({ company, currency }))
    await request
      .post('tools/daily-allowances', { company, currency })
    getErrorStatusMessage({
      status: 200,
      message: 'New daily allowance successfully added.',
    });
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};
export const updateAllowance = ({ allowanceId, company, currency }) => async (dispatch) => {
  try {
    dispatch(updateDailyAllowance({ company, currency }))
    await request
      .update(`tools/daily-allowances/${allowanceId}`, { company, currency })

    getErrorStatusMessage({
      status: 200,
      message: 'Daily allowance successfully updated.',
    });
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateAllowanceOptions = ({ allowanceId, options }) => async (dispatch) => {
  try {
    const updatedAllowance = await request
      .post(`tools/daily-allowances/${allowanceId}/options`, { options })
    getErrorStatusMessage({
      status: 200,
      message: 'Daily allowance options successfully updated.',
    });
    dispatch(updateDailyAllowanceOptions(updatedAllowance))
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const deleteAllowance = (allowancesId) => async (dispatch) => Promise.allSettled(allowancesId.map((allowanceId) => request
  .delete(`tools/daily-allowances/${allowanceId}`)
  .then(() => {
    getErrorStatusMessage({
      status: 200,
      message: 'Allowance Type successfully deleted.',
    })
    dispatch(setShouldRefreshOn())
  })
  .catch((err) => getErrorStatusMessage({
    status: err?.response?.status,
    message: err?.response?.data?.error || 'Something went wrong. Try again later.',
  }))));

export const handleAddNewCustomRule = (values, allowanceId, handleCancel) => async (dispatch) =>
  request
    .post(`/tools/daily-allowances/custom-rules/${allowanceId}`, {
      customRule: values,
    })
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: 'Custom rule successfully added.',
      });
      handleCancel()
      dispatch(setShouldRefreshOn())
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: 'Custom rule couldn\'t be added',
      });
    });

export const handleUpdateCustomRule = (values, allowanceId, id, handleCancel) => async (dispatch) =>  {
  await request.update(`/tools/daily-allowances/custom-rules/${allowanceId}/${id}`, {
    customRule: values
  })
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: 'Custom rule successfully updated.',
      });
      handleCancel()
      dispatch(setShouldRefreshOn())
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: 'Custom rule couldn\'t be updated',
      });
    })
}
export const handleDeleteCustomRule = (allowanceId, ruleId, handleCancel) => async (dispatch) =>  {
  await request.delete(`/tools/daily-allowances/custom-rules/${allowanceId}/${ruleId}`)
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: 'Custom rule successfully deleted.',
      });
      handleCancel();
      dispatch(setShouldRefreshOn())
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: 'Custom rule couldn\'t be deleted',
      });
    })
}
