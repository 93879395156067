import { useState, useEffect } from 'react';
import { debounce, isEmpty } from 'lodash';
import { useFormik, FormikProvider } from 'formik';
import { Text, TextInput, Button } from '@heathmont/moon-core';
import { IAddNewCustomHotel } from './types';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import {
  FormItemsWrapper,
  ButtonsWrapper,
  DialogWrapper,
  Background,
  FieldLabel,
  CloseIcon,
  FormItem,
  Content,
  Wrapper,
  Footer,
  Title,
} from './styles';
import { customHotelSchema, getInitialValues } from './schema';
import DatePicker from '../DatePicker';
import { CityOption, fetchCities, getInitialCitiesOptions } from './utils';
import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';
import StarRating from '../StarRating';
import { hotelTypesOptions } from '../../enums/HotelType';

const AddNewCustomHotel: React.FC<IAddNewCustomHotel> = ({
  handleCloseDialog,
  handleAgree,
}) => {
  const formik = useFormik({
    validationSchema: customHotelSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: handleAgree,
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik;
  const [citiesOptions, setCitiesOptions] = useState<CityOption[]>(getInitialCitiesOptions());
  const [isCitiesLoading, setIsCitiesLoading] = useState<boolean>(false);
  const hotelTypes = hotelTypesOptions.map(option => ({ ...option, title: option.label }));

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    validateForm();
  }, [values.starRating]);

  return (
    <>
      <Background onClick={() => handleCloseDialog()} />
      <DialogWrapper>
        <Wrapper>
           <FormikProvider value={formik}>
            <Title>
              <Text size={18}>Add custom hotel</Text>
              <CloseIcon onClick={() => handleCloseDialog()}>
                <CloseSvg />
              </CloseIcon>
            </Title>
            
            <Content>          
              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Name"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldName="name"
                    fieldId="name"
                    isError={!!(touched?.name && errors?.name)}
                    errors={touched?.name && errors}
                    label="Name"
                    value={values.name}
                    type="text"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    onBlur={() => setFieldTouched('name')}
                  />
                </FormItem>  
                <FormItem>
                  <CustomSelect
                    currentValue={values.type}
                    isError={!!(touched?.type && errors?.type)}
                    error={touched?.type && errors?.type}
                    items={hotelTypes}
                    label="Type"
                    size="xLarge"
                    onChange={(value) => setFieldValue('type', value)}
                    onBlur={() => setFieldTouched('type')}
                  />
                </FormItem>
              </FormItemsWrapper>

              <FormItemsWrapper>
                <FormItem>
                  <CustomSelect
                    minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                    currentValue={values.regionId}
                    isSearchable
                    placeholder='Search...'
                    isLoading={isCitiesLoading}
                    isError={!!(touched?.regionId && errors?.regionId)}
                    error={touched?.regionId && errors?.regionId}
                    items={citiesOptions}
                    label="City"
                    size="xLarge"
                    onInputChange={debounce((search) => fetchCities({ search, setFieldValue, setCitiesOptions, setIsCitiesLoading }), DEFAULT_DEBOUNCE_MS_COUNT)}
                    onChange={(value) => setFieldValue('regionId', value)}
                    onBlur={() => setFieldTouched('regionId', true, false)}
                  />
                </FormItem>
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Address"
                    component={TextInput}
                    fieldName="address"
                    inputSize="xlarge"
                    fieldId="address"
                    isError={!!(touched?.address && errors?.address)}
                    errors={touched?.address && errors}
                    value={values.address}
                    label="Address"
                    type="text"
                    onChange={(e) => setFieldValue('address', e.target.value)}
                    onBlur={() => setFieldTouched('address')}
                  />
                </FormItem>                      
              </FormItemsWrapper>

              <FieldLabel>Coordinates</FieldLabel>

              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Latitude"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldName="latitude"
                    fieldId="latitude"
                    isError={!!(touched?.latitude && errors?.latitude)}
                    errors={touched?.latitude && errors}
                    value={values.latitude}
                    label="Latitude"
                    type="number"
                    onChange={(e) => setFieldValue('latitude', e.target.value)}
                    onBlur={() => setFieldTouched('latitude')}
                  />
                </FormItem>   
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Longitude"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldName="longitude"
                    fieldId="longitude"
                    isError={!!(touched?.longitude && errors?.longitude)}
                    errors={touched?.longitude && errors}
                    label="Longitude"
                    value={values.longitude}
                    type="number"
                    onChange={(e) => setFieldValue('longitude', e.target.value)}
                    onBlur={() => setFieldTouched('longitude')}
                  />
                </FormItem>                   
              </FormItemsWrapper>

              <FieldLabel>Star rating</FieldLabel>

              <FormItemsWrapper>
                <FormItem>
                  <StarRating
                    precision={1}
                    error={touched?.starRating && errors?.starRating ? errors.starRating : null}
                    rate={values.starRating}
                    onChange={(value) => {
                      setFieldValue('starRating', value);
                      setFieldTouched('starRating', true, false);
                    }}
                  />
                </FormItem>
              </FormItemsWrapper>

              <FormItemsWrapper>
                <FormItem>
                  <DatePicker
                    showTimeSelectOnly
                    showTimeSelect
                    placeholder={'Check in time'}
                    isEditView={true}
                    isError={!!(touched?.checkInTime && errors?.checkInTime)}
                    format={'HH:mm'}
                    errors={touched?.checkInTime && errors}
                    value={values.checkInTime}
                    field="checkInTime"
                    onChange={(field, value) => setFieldValue(field, value)}
                    onBlur={() => setFieldTouched('checkInTime')}
                  />
                </FormItem>
                <FormItem>
                  <DatePicker
                    showTimeSelectOnly
                    showTimeSelect
                    placeholder={'Check out time'}
                    isEditView={true}
                    isError={!!(touched?.checkOutTime && errors?.checkOutTime)}
                    format={'HH:mm'}
                    errors={touched?.checkOutTime && errors}
                    value={values.checkOutTime}
                    field="checkOutTime"
                    onChange={(field, value) => setFieldValue(field, value)}
                    onBlur={() => setFieldTouched('checkOutTime')}
                  />
                </FormItem>
              </FormItemsWrapper>

              <FieldLabel>Contact info (optional)</FieldLabel>

              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Email"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldName="email"
                    fieldId="email"
                    isError={!!(touched?.email && errors?.email)}
                    errors={touched?.email && errors}
                    value={values.email}
                    label="Email"
                    type="email"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    onBlur={() => setFieldTouched('email')}
                  />
                </FormItem>   
                <FormItem>
                  <FormField
                    errorPosition={-20}
                    placeholder="Phone"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldName="phone"
                    fieldId="phone"
                    isError={!!(touched?.phone && errors?.phone)}
                    errors={touched?.phone && errors}
                    label="Phone"
                    value={values.phone}
                    type="text"
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                    onBlur={() => setFieldTouched('phone')}
                  />
                </FormItem>                   
              </FormItemsWrapper>
            </Content>
            <Footer>
              <ButtonsWrapper>
                <Button type="button" variant="secondary" onClick={handleCloseDialog}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isEmpty(errors)} onClick={() => handleAgree(values)}>
                  Add
                </Button>
              </ButtonsWrapper>
            </Footer>
          </FormikProvider>
        </Wrapper>
      </DialogWrapper>
    </>
  );
};

export default AddNewCustomHotel;
