import { Fragment, useState } from 'react';
import { TravelNoSuitcase, TravelSuitcase } from '@heathmont/moon-icons-tw';
import { Popover } from '@heathmont/moon-popover';
import { Button } from '@heathmont/moon-core';
import { hasBaggagePerFlight } from './utils';
import { IFlightAdditionalServicesPerPassenger } from './types';
import {
  CloseButtonWrapper,
  SubTitle,
  Wrapper,
  Title,
  Item,
} from './styles';

const FlightAdditionalServicesPerPassenger: React.FC<IFlightAdditionalServicesPerPassenger> = ({
  children,
  flight,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderAdditionalServicesByPassenger = () => (
    <Wrapper>
      <Title>Extra bag(s)</Title>
      {flight.itineraries.map(itinerary => {
        const departure = itinerary.segments[0].departure;
        const arrival = itinerary.segments[itinerary.segments.length - 1].arrival;
        return (
          <Fragment key={itinerary.id}>
            <SubTitle>{`${departure.location.city} (${departure.code}) → ${arrival.location.city} (${arrival.code})`}</SubTitle>
            <>
              {flight.passengers.map(passenger => (
                <Item key={passenger.id}>
                  <span>{passenger.firstName} {passenger.lastName}</span>
                  {hasBaggagePerFlight(itinerary, passenger) ? (
                    <TravelSuitcase fontSize="1.5rem" />
                  ): (
                    <TravelNoSuitcase fontSize="1.5rem" />
                  )}
                </Item>
              ))}
            </>
          </Fragment>
        );
      })}
      <CloseButtonWrapper>
        <Button variant='secondary' fullWidth onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </CloseButtonWrapper>
    </Wrapper>
  );

  return (
    <Popover
      closeOnClickOutside
      className="popover-dialog"
      placement="top"
      content={renderAdditionalServicesByPassenger()}
      isOpen={isOpen}
      onVisibilityChange={isVisible => setIsOpen(isVisible)}
    >
      {children}
    </Popover>
  );
};

export default FlightAdditionalServicesPerPassenger;
