import { TabWrapper, TabButton, TabButtonIcon } from '../../styled';
import useIsMobile from '../../hooks/useIsMobile';
import { getTabsData } from './suggestions';
import { TabName, TabNameWrapper } from './styles';

const SignUpFormTabs = ({ activeTab, checkedTabs }) => {
  const isMobile = useIsMobile();

  const tabs = getTabsData(activeTab, checkedTabs);

  return (
    <TabWrapper isMobile={isMobile}>
      {tabs.map(({ checked, active, tabNumber, tabName }) => (
        <TabButton isMobile={isMobile} type="button" checked={checked} active={active} disabled>
          <TabNameWrapper>
            <TabButtonIcon withoutMargin checked={checked} active={active}>
              <span>{tabNumber}</span>
            </TabButtonIcon>

            <TabName>{tabName}</TabName>
          </TabNameWrapper>
        </TabButton>
      ))}
    </TabWrapper>
  );
};

export default SignUpFormTabs;
