import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  setActionsAndNotifications,
  setActionsAndNotificationsLogs,
  setIsChangedAction,
  showActionAndNotificationOverview,
} from './actions';

export const fetchActionsAndNotifications = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const {
      data: { records, total },
    } = await request.get('tools/actions-and-notifications', { params });

    dispatch(setActionsAndNotifications(records, total));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const fetchActionsAndNotificationsLogs = (id) => async (dispatch) => {
  try {
    const {
      data: { records },
    } = await request.get(`tools/actions-and-notifications/logs/${id}`);

    dispatch(setActionsAndNotificationsLogs(records));
  } catch (e) {
    dispatch(setActionsAndNotificationsLogs([]));

    console.error(e);
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const updateActionAndSubscription = (actionId, actionsData) => (dispatch, getState) => {
  dispatch(setIsChangedAction(true));
  dispatch(setIsLoadingData(true));

  const {
    actionsAndNotificationsReducer: { overviewActionAndNotificationData },
  } = getState();

  request
    .update(`tools/actions-and-notifications/${actionId}`, {
      ...actionsData,
    })
    .then(({ data }) => {
      const updatedOverview = { ...overviewActionAndNotificationData, ...data };

      dispatch(showActionAndNotificationOverview(updatedOverview));
      getErrorStatusMessage({
        status: 200,
        message: `Action successfully updated`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });

      console.error(e);
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsChangedAction(false));
    });
};

export const updateActionStatus = (id, status) => async (dispatch, getState) => {
  dispatch(setIsChangedAction(true));

  const {
    actionsAndNotificationsReducer: { overviewActionAndNotificationData },
  } = getState();

  try {
    const { data } = await request.update(`tools/actions-and-notifications/status/${id}`, { status });
    
    const updatedOverview = { ...overviewActionAndNotificationData, ...data };

    dispatch(showActionAndNotificationOverview(updatedOverview));

    getErrorStatusMessage({
      status: 200,
      message: `Action status successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsChangedAction(false));
  }
};
