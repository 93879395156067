import { setToken } from '../../models/auth';
import request from '../../models/request';
import { getHomePagePathByRolePages } from '../../utils/commonFunctions';
import { clearBeforeLoginUrl, setAppShallBeRedirectedUrl } from '../app/actions';
import { setCurrentUser } from '../currentUser/actions';
import { setAuthError, setIsAuthenticated } from './actions';

export const loginUser = (data) => async (dispatch, getState) => {
  const {
    app: { beforeLoginUrl },
  } = getState();

  const { email, password, remember } = data;

  try {
    const response = await request.post('auth/login', {
      remember,
      password,
      email,
    });

    if (!response.data.token) {
      return console.error('!response.data.token', !response.data.token);
    }

    setToken(response.data.token);
    dispatch(setIsAuthenticated(true));
    dispatch(refetchUser());

    if (beforeLoginUrl) {
      dispatch(setAppShallBeRedirectedUrl(beforeLoginUrl));

      return dispatch(clearBeforeLoginUrl());
    }

    dispatch(setAppShallBeRedirectedUrl(getHomePagePathByRolePages(response.data.pages)));
  } catch (e) {
    const errorInfo = request.extractErrors(e);
    Array.isArray(errorInfo) ? dispatch(setAuthError(Object.values(errorInfo[0])[0])) : dispatch(setAuthError(errorInfo))
  }
};

export const refetchUser = () => async (dispatch) => {
  try {
    const {
      data: { user },
    } = await request.get('user/me');

    dispatch(setCurrentUser(user));
  } catch (error) {
    console.error(error);
  }
};
