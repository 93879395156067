import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const Header = styled(Text).attrs({
  size: 32,
  color: 'bulma.100',
})`
  line-height: 40px;
  margin: 0;
`;

export const MobileTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  height: 40px;
  padding: 10px;

  &:hover {
    background-color: #e0e0e06b;
    border-radius: 6px;
    cursor: pointer;
  }
`;

export const HeMobileTabName = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;
  height: 65px;
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #ffffff;

  & div {
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ConfirmMessage = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: ${({ isUpdateError }) => (isUpdateError ? '#fddada' : '#dafde9')};
  border-radius: 8px;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);

  & svg {
    cursor: pointer;
  }
`;

export const NotificationText = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
`;

export const MobileFormContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  margin-top: 20px;
`;

export const MobileEditProfileStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden; 
`;

export const TabFormContainerStyled = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden; 
`;

export const TabContentFields = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
`;

export const TabsStyled = styled.div`
  flex-direction: column;
  height: 100%;
  display: flex;
  margin-top: 20px;
`;

export const TabsListStyled = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
  margin-bottom: 20px;
`;
