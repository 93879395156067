import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import CustomSelect from '../CustomSelect';
import { ISelectWithExternalDataSource } from './types';

const MIN_LENGTH_TO_START_SEARCHING = 3;
const DEBOUNCE_IN_MILLISECONDS = 500;

const SelectWithExternalDataSource: React.FC<ISelectWithExternalDataSource> = ({
  dependOnFieldValue,
  noOptionsMessage,
  isDependedFiled,
  currentValue,
  isClearable,
  placeholder,
  isDisabled,
  position,
  isError,
  label,
  error,
  fetchOptions,
  getItemLabel,
  resetValues,
  onChange,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    if (isDependedFiled) {
      setOptions([]);
    }
  }, [dependOnFieldValue, isDependedFiled])

  const handleFetchOptions = async ({ search, id }: { search?: string, id?: number | string }) => {
    setOptions([]);
    setIsLoading(true);
  
    try {
      const options = await fetchOptions({ search, id });
      setOptions(options);
      return search;
    } catch (err) {
      setOptions([]);
      return search;
    } finally {
      setIsLoading(false);
      return search;
    }
  };

  const handleInit = async () => {
    if (currentValue) {
      await handleFetchOptions({ id: `${currentValue}` });
    }
    
    return setIsInitialized(true);
  };

  const handleSearch = async (search?: string) => {
    if (search?.length >= MIN_LENGTH_TO_START_SEARCHING) {
      resetValues && resetValues();
      return handleFetchOptions({ search });
    }

    return search;
  };

  return (
    <CustomSelect
      minLengthToStartSearching={MIN_LENGTH_TO_START_SEARCHING}
      noOptionsMessage={noOptionsMessage}
      currentValue={isInitialized ? currentValue : 'Loading...'}
      isSearchable
      isClearable={isClearable}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={isLoading}
      position={position}
      isError={isError}
      label={label}
      error={error}
      items={options}
      onInputChange={debounce((search) => {
        handleSearch(search)
      }, DEBOUNCE_IN_MILLISECONDS)}
      getItemLabel={getItemLabel}
      onChange={onChange}
    />
  )
};

export default SelectWithExternalDataSource;