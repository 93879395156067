import { connect } from 'react-redux';
import {
  closeNotificationAllItems,
  showNotificationAllItems,
  fetchUpcomingTrips,
  fetchNotifications,
  closeUpcomingTrips,
  showUpcomingTrips,
  fetchGraphicsData,
  toggleFilters,
  closeFilters,
  fetchNumbers,
  fetchOptions,
} from '../../store/dashboard/actions';
import Dashboard from './Dashboard.ui';

const mapStateToProps = ({
  dashboardReducer: {
    isOpenNotificationData,
    isOpenNotification,
    isOpenUpcoming,
    notifications,
    isFiltersOpen,
    upcomingTrips,
    chartsData,
    options,
    numbers,
  },
}) => ({
  isOpenNotificationData,
  isOpenNotification,
  isOpenUpcoming,
  notifications,
  upcomingTrips,
  isFiltersOpen,
  chartsData,
  options,
  numbers,
});

const mapDispatchToProps = {
  closeNotificationAllItems,
  showNotificationAllItems,
  fetchUpcomingTrips,
  fetchNotifications,
  closeUpcomingTrips,
  showUpcomingTrips,
  fetchGraphicsData,
  toggleFilters,
  closeFilters,
  fetchNumbers,
  fetchOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
