import { Checkbox, Button } from '@heathmont/moon-components';
import { IDownDrawer } from './types';
import {
  SecondaryButton,
  CheckboxWrapper,
  ButtonsWrapper,
  DangerButton,
  DataWrapper,
  Wrapper,
  Content,
  Text,
} from './styles';

const getPluralTextValue = (data, text = '') => (data.length > 1 ? `${text}s` : text);

const DownDrawer: React.FC<IDownDrawer> = ({
  data,
  text = '',
  handleMergeTripsDialog,
  handleRemoveDialog,
  handleChangeStatus,
  handleSetCheckbox,
  handleEdit,
}) => {
  return (
    <Wrapper>
      <Content>
        <CheckboxWrapper>
          <Checkbox checked={true} onChange={() => handleSetCheckbox(undefined, true)} />
        </CheckboxWrapper>

        <DataWrapper>
          <Text>{`${data.length} ${getPluralTextValue(data, text)}`}</Text>
          <Text color="#78787D">selected</Text>
        </DataWrapper>
      </Content>

      <ButtonsWrapper>
        <SecondaryButton variant="secondary" onClick={() => handleSetCheckbox(undefined, true)}>
          Cancel
        </SecondaryButton>

        <DangerButton variant="secondary" onClick={() => handleRemoveDialog(data)}>
          Delete
        </DangerButton>

        {handleMergeTripsDialog && data?.length > 1 && (
          <Button variant="primary" onClick={() => handleMergeTripsDialog([], false)}>
            Merge trips
          </Button>
        )}

        {handleChangeStatus && (
          <Button variant="primary" onClick={() => handleChangeStatus(true)}>
            Change status
          </Button>
        )}

        {handleEdit && (
          <Button variant="primary" onClick={() => handleEdit(true)}>
            Edit selected
          </Button>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default DownDrawer;
