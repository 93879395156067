import moment from 'moment';
import { GENDER, genderOptions } from '../../../enums/GenderType';
import { IOption } from '../../../pages/Trips/types';

export const formatDateForDocument = (expiryAt) => {
  return moment.utc(expiryAt).format('MMM DD, YYYY')
};

export const formatGender = (gender: GENDER) => {
  const genderOption = genderOptions.find(({ value }) => value === gender);
  return genderOption ? genderOption.label : gender;
};

export const formatCountry = (countryId: number, countries: IOption[]) => {
  const country = countries.find(({ value }) => value === countryId);
  return country ? country.title : countryId; 
};

export const formatDocumentType = (typeId: number, travelDocumentTypes: IOption[]) => {
  const documentType = travelDocumentTypes.find(({ value }) => value === typeId);
  return documentType ? documentType.title : typeId; 
};
