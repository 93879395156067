import { useEffect, useState } from 'react';
import moment from 'moment';
import { IMergeTripsDialogContent } from './types';
import InfoBlock from '../InfoBlock'; 
import {
  TripRadioButtonWrapper,
  PurposeDetailsField,
  PurposeDetailsValue,
  TravelerAndTripType,
  TripOverviewWrapper,
  TripInfoBlockTitle,
  TripCityAndDates,
  TravelerFullName,
  TTRLinkWrapper,
  ContentWrapper,
  PurposeWrapper,
  TripInfoBlock,
  TTRFieldValue,
  TTRFieldName,
  TripType,
} from './styles';
import { defineTripTypeIcon, formatPurpose, formatTtrLink } from '../../utils/tableHelpers';
import { fetchTrips } from './api';
import TripReference from '../TripReference';
import { RadioButton } from '@heathmont/moon-core';
import { sortTripsByCreatedAt} from '../../utils/trips';
import { Trip } from '../../enums/Trip';

const MergeTripsDialogContent: React.FC<IMergeTripsDialogContent> = ({ tripIds, showInfo = false, setBaseTripId, baseTripId }) => {
  const [trips, setTrips] = useState<Trip[]>([]);

  const handleFetchTrips = async () => {
    try {
      const trips = await fetchTrips(tripIds);
      const orderedByCreatedTimeTrips = sortTripsByCreatedAt(trips);

      setBaseTripId(orderedByCreatedTimeTrips[0].id);
      setTrips(trips);
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    handleFetchTrips();
  }, []);

  const formatDate = (date) => {
    return moment(date).format('MMM D, YYYY');
  };

  const goToTrip = (reference) => {
    window.open(`/trips?search=${encodeURIComponent(reference)}`, '_blank');
  };

  return (
    <ContentWrapper>
      {showInfo && (
        <InfoBlock
          text={`
            You can merge trips with the same traveler and trip status
            and only trips without expense reports provided.
            There are also additional merging rules that check
            trip dates and cities. You can ignore additional rules
            by setting the checkbox "Ignore additional merging rules" to check.
          `}
        />
      )}
      <TripInfoBlockTitle>
        Select a base trip for merging
      </TripInfoBlockTitle>
      {trips.map((trip) => {
          if (!trip) {
            return null;
          }

          return (
              <TripOverviewWrapper
                onClick={() => setBaseTripId(trip.id)}
                key={trip.id}
              >
              <TripRadioButtonWrapper>
                <RadioButton
                  name='baseTrip'
                  key={trip.id}
                  value={trip.id}
                  checked={trip.id === baseTripId}
                  onChange={(value: any) => setBaseTripId(+value?.target?.value)}
                />
              </TripRadioButtonWrapper>
              <TripInfoBlock>
                <TravelerAndTripType>
                  <TripReference
                    reference={trip.reference}
                    onClick={() => goToTrip(trip.reference)}
                  />
                  <TravelerFullName>{trip.user.fullName}</TravelerFullName>
                  <TripType>{defineTripTypeIcon(trip.tripType, '1.25rem')}</TripType>
                </TravelerAndTripType>
                <TripCityAndDates>
                  {trip.destinationLabel} — {formatDate(trip.tripStartDate)} → {formatDate(trip.tripFinishDate)}
                </TripCityAndDates>
                <TTRLinkWrapper>
                  <PurposeDetailsField>Purpose details:</PurposeDetailsField>
                  <PurposeDetailsValue>{trip.purposeDetails ||'N/A'}</PurposeDetailsValue>
                </TTRLinkWrapper>
                {trip?.jiraTicketNumber && (
                  <TTRLinkWrapper>
                    <TTRFieldName>TTR link:</TTRFieldName>
                    <TTRFieldValue>
                      {formatTtrLink(trip?.jiraTicketNumber, trip?.companyJiraHost)}
                    </TTRFieldValue>
                  </TTRLinkWrapper>
                )}
              </TripInfoBlock>
              <PurposeWrapper>
                {formatPurpose(trip.purpose)}
              </PurposeWrapper>
            </TripOverviewWrapper>
          );
        })
      }
    </ContentWrapper>
  );
};

export default MergeTripsDialogContent;
