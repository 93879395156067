import { useEffect, useState, useRef, useMemo } from 'react';
import { saveAs } from 'file-saver';
import { Helmet } from 'react-helmet';
import omit from 'lodash/omit';
import moment from 'moment';
import { Dialog } from '@heathmont/moon-components';
import { Text } from '@heathmont/moon-core';
import { FilesExport, SoftwareSorting } from '@heathmont/moon-icons';
import request from '../../models/request';
import { LIMIT_PER_PAGE, DEFAULT_SORTING, REPORT_GROUP_BY_FIELDS } from '../../constants';
import { Container, PageHeader, TableWrapper } from './styles';
import { ButtonsListWrapper, FilterHeaderWrapper } from '../../styled';
import { reportsDataMapper, reportsDataMapperByGroup } from '../../utils/tableHelpers';
import { initialColumns, RowHeight } from './suggestions';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import TablePagination from '../../components/TablePagination';
import { ReportFilters } from '../../components/ReportFilters';
import Button from '../../components/Button';
import TableContentReciever from '../../components/TableContentReciever';
import { useCustomTable } from '../../hooks/useCustomTable';
import { useQuery } from '../../hooks/useQuery';
import { useFilters, initReportFilters } from '../../hooks/useFilters';
import { prepareFilters } from '../../utils/filters';
import { Spinner } from '../../components/Spinner';

const defaultGroupBy = REPORT_GROUP_BY_FIELDS.find((field) => field.default).value;

const Reports = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();
  const defaultFilters = omit(query, 'groupBy');
  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(defaultFilters, initReportFilters);
  const [refetch, setRefetch] = useState(false);
  const [reports, setReports] = useState([]);
  const [options, setOptions] = useState({
    typeOfTravelers: [],
    relativeDates: [],
    tripTypes: [],
    employers: [],
    purposes: [],
    teams: [],
  });
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.REPORTS);
  const [groupBy, setGroupBy] = useState(query.groupBy || defaultGroupBy);
  const totalItems = useRef(0);
  const { perPage, page, nextPage, prevPage, setPage } = useCustomTable(totalItems, LIMIT_PER_PAGE.REPORTS);
  const [showDialog, setShowDialog] = useState(false);
  const columns = useMemo(() => initialColumns, [groupBy]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const getQueryParams = (options = { downloadAllReports: false }) => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    if (groupBy) {
      params.groupBy = groupBy;
    }

    if (options?.downloadAllReports) {
      params.downloadAllReports = true;
    }

    return params;
  };

  const setQueryParams = (filters = {}, groupBy) => {
    const queryParams: any = filters || {};

    if (groupBy) {
      queryParams.groupBy = groupBy;
    }

    setQuery(queryParams);
  };

  const refetchData = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data } = await request.get('reports/options');
        setOptions(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      setIsLoadingData(true);
      try {
        const params = getQueryParams();
        const { data } = await request.get('reports', { params });

        totalItems.current = data.total;

        if (groupBy) {
          setReports(reportsDataMapperByGroup(data.records, groupBy));
        } else {
          setReports(reportsDataMapper(data.records));
        }

        setQueryParams(params.filters, params.groupBy);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchReports();
  }, [filters, page, sortBy, refetch, groupBy]);

  const handleExport = async ({ downloadAllReports }) => {
    try {
      const { data } = await request.get(`reports/file`, {
        responseType: 'arraybuffer',
        params: getQueryParams({ downloadAllReports }),
      });

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, `[${moment().format('DD.MM.YYYY')}]report.xlsx`);
      setShowDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const hadleStartExportOrShowOptions = () => {
    if (totalItems.current > LIMIT_PER_PAGE.REPORTS) {
      setShowDialog(true);
    } else {
      handleExport({ downloadAllReports: true });
    }
  };

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const toggleFilters = () => setIsFiltersOpen((prevState: boolean) => !prevState);

  const setFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    setFilter(field, value);
  };

  const toggleFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    toggleFilter(field, value);
  };

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Container>
        {isFiltersOpen && (
          <ReportFilters
            filters={filters}
            options={options}
            // @ts-ignore
            groupBy={groupBy}
            toggleFilter={toggleFiltersAndToFirstPage}
            setFilter={setFiltersAndToFirstPage}
            setGroupBy={setGroupBy}
          />
        )}

        <TableWrapper>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />

              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Reports
              </Text>
            </FilterHeaderWrapper>

            <Button
              handleClick={hadleStartExportOrShowOptions}
              iconLeft={<FilesExport fontSize="1.6rem" />}
              variant="primary"
              label="Export as excel"
              isDisabled={!totalItems.current}
            />
          </PageHeader>

          <TablePagination
            isShowTableFilters={isShowTableFilters}
            limitPerPage={LIMIT_PER_PAGE.REPORTS}
            totalItems={totalItems}
            data={reports}
            page={page}
            toggleTableFilters={toggleTableFilters}
            changeRowHeight={changeRowHeight}
            refetchData={refetchData}
            nextPage={nextPage}
            prevPage={prevPage}
          />

          {isLoadingData ? (
            <Spinner />
          ) : (
            <TableContentReciever
              isShowTableFilters={isShowTableFilters}
              initialColumns={columns}
              limitPerPage={LIMIT_PER_PAGE.REPORTS}
              queryParams={getQueryParams()}
              rowHeight={rowHeight}
              // @ts-ignore
              groupBy={groupBy}
              sortBy={sortBy}
              data={reports}
              page={page}
              handleSortBy={setSortBy}
            />
          )}
        </TableWrapper>
      </Container>

      {showDialog && (
        <Dialog maxWidth="360px" position="CENTER" isOpen={showDialog} onDismiss={() => setShowDialog(false)}>
          <ButtonsListWrapper>
            <Button
              handleClick={() => handleExport({ downloadAllReports: false })}
              iconLeft={<FilesExport fontSize="1.6rem" />}
              variant="primary"
              label="Export displayed records"
            />
            <Button
              handleClick={() => handleExport({ downloadAllReports: true })}
              iconLeft={<FilesExport fontSize="1.6rem" />}
              variant="primary"
              label="Export all found records"
            />
          </ButtonsListWrapper>
        </Dialog>
      )}
    </>
  );
};

export default Reports;
