import styled from 'styled-components';

export const PassengerDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
`;

export const PassengerDetailsSectionTitle = styled.span`
  font-weight: 700;
`;

export const PassengerDetailsItem = styled.div`
  display: flex;
  gap: 10px;
`

export const PassengerDetailsLabel = styled.span`
  color: #78787d;
  width: ${({ fullWidth }) => fullWidth ? '100%' : '86px'};
  text-align: left;
`;

export const BaggageItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BaggageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BaggageDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
`;

export const BaggageDetailsTitle = styled.span`
  color: #000000;
  text-align: left;
`;

export const BaggageDetailsSubTitle = styled.span`
  color: #78787d;
  text-align: left;
`;

export const PassengerDetailsValue = styled.span`
`;

export const PassengerTitle = styled.span`
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 0.01px black;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;
