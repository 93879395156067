import { SoftwarePlate } from '@heathmont/moon-icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoSvg } from './../../static/logo.svg';
import Avatar from '../Avatar';
import { INavigation } from './types';
import { links } from '../TopNav/links';
import { TOOLS_PAGES } from '../Routes/suggestions';
import includes from 'lodash/includes';
import useIsMobile from '../../hooks/useIsMobile';
import { Wrapper, Logo, NavElements, NavElement, Footer } from './styles';

const isActiveNavToolsIcon = (history: any) => includes(TOOLS_PAGES, history.location.pathname);
const isActiveNavIcon = (history: any, to: string) => history.location.pathname === to;

const Navigation: React.FC<INavigation> = ({
  currentUserData,
  pages,
}) => {
  const userInitials = `${currentUserData?.user?.firstname[0]}${currentUserData?.user?.lastname[0]}`;
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleRedirectToWebPortal = () => {
    window.open(process.env.REACT_APP_WEB_PORTAL_URL);
  };

  return (
    <Wrapper>
      <Logo onClick={handleRedirectToWebPortal}>
        <LogoSvg />
      </Logo>

      <NavElements>
        {links.map(({ label, icon, to }) => {
          const iconColor = isActiveNavIcon(history, to) ? '#275ADB' : '#78787D';
          const isPathAvailable = pages.some((page) => page.path === to);

          return (
            isPathAvailable && (
              <NavElement key={label} onClick={() => history.push(to)} isActive={isActiveNavIcon(history, to)}>
                {icon(iconColor)}
              </NavElement>
            )
          );
        })}
      </NavElements>

      <Footer>
        {!!currentUserData.user.isAdmin && (
          <NavElement onClick={() => history.push(TOOLS_PAGES.COMPANIES)} isActive={isActiveNavToolsIcon(history)}>
            <SoftwarePlate fontSize="2rem" color={isActiveNavToolsIcon(history) ? '#275ADB' : '#78787D'} />
          </NavElement>
        )}

        <Avatar
          size="medium"
          name={userInitials}
          color="hit.100"
          avatarStyle={{ borderRadius: '8px' }}
          backgroundColor="piccolo.100"
          handleClick={() => isMobile ? history.push('/edit-profile') : history.push('/edit-profile/details')}
        />
      </Footer>
    </Wrapper>
  );
};

const mapStateToProps = ({ currentUser }) => ({
  currentUserData: currentUser.currentUserData,
  pages: currentUser?.currentUserData?.pages ? currentUser.currentUserData.pages : [],
});

export default connect(mapStateToProps)(Navigation);
