import request from '../../models/request';
import {
  SET_SUCCESS_SIGN_UP_COMPANY,
  SET_ACTIVE_TAB,
  SET_SIGN_UP_COMPANY_ERROR,
  SET_IS_ACTIVE_JIRA_ACCORDION,
  SET_IS_ACTIVE_HIBOB_ACCORDION,
  SET_FORM_DATA,
  SET_SIGN_UP_COMPANY_URL_ERROR,
} from './actionTypes';

export const setSuccessSignUpCompany = (isSuccess = false) => ({
  type: SET_SUCCESS_SIGN_UP_COMPANY,
  payload: { isSuccess },
});

export const setSignUpCompanyError = (isSubmitError = false, message = '', header = 'Registration failed') => ({
  type: SET_SIGN_UP_COMPANY_ERROR,
  payload: { isSubmitError, header, message },
});

export const setSignUpUrlError = (isValid = false) => ({ type: SET_SIGN_UP_COMPANY_URL_ERROR, payload: { isValid } });

export const setActiveTab = (activeTab) => ({ type: SET_ACTIVE_TAB, payload: { activeTab } });

export const setIsActiveJiraAccordion = (isActive = false) => ({
  type: SET_IS_ACTIVE_JIRA_ACCORDION,
  payload: { isActive },
});

export const setIsActiveHibobAccordion = (isActive = false) => ({
  type: SET_IS_ACTIVE_HIBOB_ACCORDION,
  payload: { isActive },
});

export const setFormData = (formData = null) => ({
  type: SET_FORM_DATA,
  payload: { formData },
});

export const setSignUpData = (formData, values) => async (dispatch) => {
  try {
    await request.post('auth/company-signup', formData);

    dispatch(setFormData(null));
    dispatch(setSuccessSignUpCompany(true));
  } catch (e) {
    dispatch(setFormData(values));
    dispatch(
      setSignUpCompanyError(true, 'Please go back and double check your information and make sure that it is valid.')
    );
    console.error(e);
  }
};
