import { useTranslation } from 'react-i18next';
import { RadioButton } from '@heathmont/moon-core';
import { IRadioGroup, IOptionsItem } from './types';
import { ALL_VALUE } from '../../utils/filters';
import { RadioLabel, RadioGroupButtons } from './styles';

const RadioGroup: React.FC<IRadioGroup> = ({ selectedValue, withAllOption = true, data, name, onSelect, isVerticalView = true }) => {
  const { t, i18n } = useTranslation();

  return (
    <RadioGroupButtons isVerticalView={isVerticalView}>
      {withAllOption && (
        <RadioButton
          name={name}
          value={ALL_VALUE}
          checked={selectedValue === ALL_VALUE}
          onChange={() => onSelect(ALL_VALUE)}
          label={<RadioLabel>All</RadioLabel>}
        />
      )}
      {data.map(({ value, title }: IOptionsItem) => (
        <RadioButton
          key={value}
          name={name}
          value={name + value}
          checked={selectedValue === value}
          onChange={() => onSelect(value)}
          label={<RadioLabel>{i18n.exists(title) ? t(title) : title}</RadioLabel>}
        />
      ))}
    </RadioGroupButtons>
  );
};

export default RadioGroup;
