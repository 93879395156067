import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Button, TextInput, Checkbox } from '@heathmont/moon-core';
import {
  ButtonsWrapper,
  FormItemLabel,
  FormFooter,
  FormItem,
} from './styles';
import Yup from '../../utils/validation';
import FormField from '../FormField';
import { IEditTripCommentDialogContent } from './types';
import { UserStatuses } from '../../enums/UserStatuses';

const getInitialValues = (trip) => {  
  return {
    notifyTravelerAndManager: false,
    comment: trip?.comment || '',
    tripId: trip?.id || undefined,
  };
};

const schema = Yup.object().shape({
  notifyTravelerAndManager: Yup
    .bool()
    .required(),
  comment: Yup
    .string()
    .nullable()
    .min(2, 'Comment cannot be less than 2 characters')
    .max(255, 'Comment cannot be longer than 255 characters')
    .isInLatinCharacters(),
  tripId: Yup
    .number()
    .required(),
});

const EditTripCommentDialogContent: React.FC<IEditTripCommentDialogContent> = ({
  loading,
  trip,
  onSubmit,
  onCancel,
}) => {
  const isTravelerExternal = useMemo(() => trip?.userType === UserStatuses.External, [trip?.userType]);
  const paymentRequired = useMemo(() => !!trip?.purpose?.paymentRequired, [trip?.purpose?.paymentRequired])
  const initialValues = useMemo(() => getInitialValues(trip), [trip?.id]);

  return (
    <>    
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <FormItem>
                <FormItemLabel>Comment</FormItemLabel>
                <FormField
                  placeholder={'Add comment'}
                  fieldName='comment'
                  component={TextInput}
                  inputSize='large'
                  fieldId='comment'
                  isError={touched?.comment && errors?.comment}
                  errors={touched?.comment && errors}
                  value={values.comment}
                  type='text'
                  onChange={({ target: { value } }) => {
                    setFieldValue('comment', value);

                    if (!value) {
                      setFieldValue('notifyTravelerAndManager', false, false);
                    }
                  }}
                  onBlur={() => setFieldTouched('comment', true, false)}
                />
              </FormItem>
              <FormFooter>
                {
                  !isTravelerExternal && !paymentRequired && (
                    <Checkbox
                      disabled={!values?.comment}
                      checked={values.notifyTravelerAndManager}
                      label={'Notify traveler and manager'}
                      onChange={() => setFieldValue('notifyTravelerAndManager', !values.notifyTravelerAndManager)}
                    />
                  ) 
                }
                <ButtonsWrapper>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button disabled={!isEmpty(errors) || loading} variant="primary" type="submit">
                    Confirm
                  </Button>
                </ButtonsWrapper>
              </FormFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditTripCommentDialogContent;
