import { useEffect, useState } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { ICustomHotelOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper } from './styles';
import {
  formatHotelAddress,
  formatHotelType,
  formatHotelCity,
} from '../../utils/tableHelpers';
import { N_A } from '../../utils/commonFunctions';
import StarRating from '../StarRating';
import EllipsisText from '../EllipsisText';
import CustomHotelOverviewDrawerEditContent from '../CustomHotelOverviewDrawerEditContent';

const CustomHotelOverviewDrawerContent: React.FC<ICustomHotelOverviewDrawerContent> = ({
  hotel,
  handleUpdate,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
  }, [hotel.id]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >Edit</Button>
        </EditButtonWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>ID</OverviewItemName>
        <OverviewItem>
          <EllipsisText text={hotel.id} />
        </OverviewItem>
      </OverviewItemsWrapper>

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Name</OverviewItemName>
            <OverviewItem>{hotel.name}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Type</OverviewItemName>
            <OverviewItem>
              <EllipsisText text={formatHotelType(hotel.type)}/>
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>City</OverviewItemName>
            <OverviewItem>{formatHotelCity(hotel)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Address</OverviewItemName>
            <OverviewItem>{formatHotelAddress(hotel)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Latitude</OverviewItemName>
            <OverviewItem>{hotel.latitude}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Longitude</OverviewItemName>
            <OverviewItem>{hotel.longitude}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Star rating</OverviewItemName>
            <OverviewItem>
              {hotel?.starRating ? <StarRating rate={+hotel.starRating} readOnly /> : N_A}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Check in time</OverviewItemName>
            <OverviewItem>{hotel?.checkInTime || N_A}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Check out time</OverviewItemName>
            <OverviewItem>{hotel?.checkOutTime || N_A}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Email</OverviewItemName>
            <OverviewItem>{hotel?.email ?  <EllipsisText text={hotel?.email}/> : N_A}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Phone</OverviewItemName>
            <OverviewItem>{hotel?.phone ?  <EllipsisText text={hotel?.phone}/> : N_A}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <CustomHotelOverviewDrawerEditContent
          hotel={hotel}
          setIsEditFormData={setIsEditFormData}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default CustomHotelOverviewDrawerContent;
