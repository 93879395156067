export const notificationTypes = [
  { notificationName: 'newFeedbacks', link: 'new-feedbacks' },
  { notificationName: 'pendingTrips', link: 'pending-trips' },
  { notificationName: 'approvedTrips', link: 'approved-trips' },
  { notificationName: 'completedTrips', link: 'completed-trips' },
  { notificationName: 'expiringTrips', link: 'expiring-trips' },
  { notificationName: 'bookedYesterday', link: 'booked-yesterday' },
  { notificationName: 'underConsiderationExpenses', link: 'under-consideration-expenses' },
  { notificationName: 'verifyingExpenses', link: 'verifying-expenses' },
  { notificationName: 'expiredDocuments', link: 'expired-documents' },
  { notificationName: 'subscriptions', link: 'subscriptions' },
  { notificationName: 'tripsForMerging', link: 'trips-for-merging' },
];

export const graphicsLinkData = [
  { graphicName: 'numbers', link: 'numbers' },
  { graphicName: 'tripStatistics', link: 'trip-statistics' },
];
