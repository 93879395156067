import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioDateGroup } from '../../components/RadioDateGroup';
import { EXPENSES_TYPE } from '../../enums/ExpensesType';

export const getUpdateStatusInfoText = (newExpenseStatus) => {
  switch (newExpenseStatus) {
    case EXPENSES_TYPE.PENDING:
      return 'The traveler has already been notified about the expenses. If you would like the traveler to complete the expense form again, please delete the expenses for this trip. And send the expenses form again.';

    case EXPENSES_TYPE.UNDER_CONSIDERATION:
      return 'The traveler will no longer be able to complete the expenses form on their own. The expenses will be sent to the travelops for confirmation.';

    case EXPENSES_TYPE.VERIFYING:
      return 'The traveler will no longer be able to complete the expenses form on their own. The expenses will be sent to the manager for confirmation.';
  
    case EXPENSES_TYPE.CHECKED:
      return 'The traveler will be notified that the expenses has already been checked. Accountants will be notified that there are new expenses to be reimbursed.';  

    case EXPENSES_TYPE.TO_BE_PAID:
      return 'The task in Jira will be created for the senior accountant.';  

    case EXPENSES_TYPE.REFUNDED:
      return 'The traveler will be notified that the expenses have already been refunded.';
  
    default:
      return;
  }
};

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      return row?.original?.expenses?.length ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
        </span>
      ) : null;
    },
    SubCell: () => null,
  },
  {
    Header: 'Traveler name',
    accessor: 'travelerName',
  },
  {
    Header: 'Payment date',
    accessor: 'paymentDate',
    disableSortBy: true,
  },
  {
    Header: 'Description',
    accessor: 'description',
    disableSortBy: true,
  },
  {
    Header: 'Expense',
    accessor: 'expense',
    disableSortBy: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableSortBy: true,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    disableSortBy: true,
  },
  {
    Header: 'Amount, EUR & Exchange rate',
    accessor: 'amountAndRate',
    disableSortBy: true,
  },
];

export const getExpensesFilters = (
  isOpenDatePicker,
  openDatePickerField,
  filters,
  options,
  handleDatePicketOpen,
  setFilter,
  toggleFilter
): any => {
  return [
    {
      title: 'Trip start time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripStartTime'}
          tripTime={filters.tripStartTime}
          range={filters.tripStartTimeRange}
          data={options.relativeDates}
          name="tripStartTime"
          setIsOpenDatePicker={handleDatePicketOpen('tripStartTime')}
          handleDateRange={(value) => setFilter('tripStartTimeRange', value)}
          handleTripDate={(value) => setFilter('tripStartTime', value)}
        />
      ),
    },
    {
      title: 'Trip end time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripEndTime'}
          tripTime={filters.tripEndTime}
          range={filters.tripEndTimeRange}
          data={options.relativeDates}
          name="tripEndTime"
          setIsOpenDatePicker={handleDatePicketOpen('tripEndTime')}
          handleDateRange={(value) => setFilter('tripEndTimeRange', value)}
          handleTripDate={(value) => setFilter('tripEndTime', value)}
        />
      ),
    },
    {
      title: `Team • ${options?.teams?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.teams}
          data={options?.teams}
          onSelect={(value) => toggleFilter('teams', value)}
        />
      ),
    },
    {
      title: `Status • ${options?.expensesStatuses?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.statuses}
          data={options?.expensesStatuses}
          onSelect={(value) => toggleFilter('statuses', value)}
        />
      ),
    },
  ];
};
