import styled from 'styled-components';

export const DoubleFieldWrapper = styled.div`
  margin-bottom: 24px;

  & div {
    & div {
      &::after {
        z-index: 0;
      }

      & div {
        & div {
          & div {
            & div {
              & div:nth-child(2) {
                & div:nth-child(1) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const InfoBlockWrapper = styled.div`
  margin: 12px 0;
`;

export const FieldWrapper = styled.div`
  margin: 24px 0;
`;

export const PhoneInputWrapper = styled.div`
  border: none;
  box-shadow: ${({ isError }) => (isError ? '0 0 0 0.125rem #ff4e64 inset' : '0 0 0 0.0625rem #e0e0e0 inset')};
  padding: 10px 16px;
  border-radius: 0 0 14px 14px;
  margin-top: -7px;
  height: 56px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;

  & .PhoneInput {
    font-size: 14px;

    & .PhoneInputInput {
      outline: none !important;
      border: none !important;
    }
  }

  &:hover {
    ${({ isFocusedPhone, isError }) =>
      !isFocusedPhone &&
      !isError &&
      `
       box-shadow: 0 0 0 0.125rem #181b1c12 inset, 0 0 0 0.125rem #e0e0e0 inset;
    `}
  }

  ${({ isFocusedPhone, isError }) =>
    isFocusedPhone &&
    !isError &&
    `
    box-shadow: 0 0 0 0.125rem #275adb inset;
  `}
`;
