import { connect } from 'react-redux';
import {
  closePaymentOverview,
  showPaymentOverview,
  updatePayerDetails,
  syncPaymentStatus,
  setInvoiceNumber,
  fetchPayments,
  toggleFilters,
  closeFilters,
  fetchOptions,
} from '../../store/payments/actions';
import Payments from './Payments.ui';

const mapStateToProps = ({
  paymentsReducer: {
    isOpenOverview,
    isFiltersOpen,
    overviewData,
    paymentsData,
    options,
    total,
  },
  loadingReducer,
  currentUser,
}) => ({
  currentUserData: currentUser.currentUserData,
  isOpenOverview,
  isFiltersOpen,
  isLoadingData: loadingReducer?.isLoadingData,
  overviewData,
  paymentsData,
  options,
  total,
});

const mapDispatchToProps = {
  closePaymentOverview,
  showPaymentOverview,
  updatePayerDetails,
  syncPaymentStatus,
  setInvoiceNumber,
  fetchPayments,
  toggleFilters,
  closeFilters,
  fetchOptions,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Payments);
