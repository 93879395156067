import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import {
  SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA,
  DELETE_COMPANY_HIBOB_INTEGRATION,
  UPDATE_COMPANY_HIBOB_INTEGRATION,
  UPDATE_COMPANY_JIRA_INTEGRATION,
  DELETE_COMPANY_JIRA_INTEGRATION,
  UPDATE_COMPANY_BALANCED_STATUS,
  ADD_COMPANY_HIBOB_INTEGRATION,
  ADD_COMPANY_JIRA_INTEGRATION,
  UPDATE_COMPANY_BASIC_INFO,
  FETCH_COMPANIES_SUCCESS,
  CLOSE_COMPANY_OVERVIEW,
  FETCH_COMPANIES_ERROR,
  UPDATE_COMPANY_STATUS,
  OPEN_COMPANY_OVERVIEW,
  FETCH_COMPANIES,
} from './actionTypes';

export const openCompanyOverview = (id) => {
  return { type: OPEN_COMPANY_OVERVIEW, payload: { id } };
};

export const closeCompanyOverview = () => {
  return { type: CLOSE_COMPANY_OVERVIEW };
};

export const fetchCompanies = (params?: any) => async (dispatch) => {
  dispatch({ type: FETCH_COMPANIES });

  try {
    const { data } = await request.get('tools/companies', { params });
    dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: { data: data.records } });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
    dispatch({ type: FETCH_COMPANIES_ERROR });
  }
};

export const updateCompanyStatus = (id, status) => async (dispatch) => {
  try {
    await request.update(`tools/companies/status/${id}`, { status });
    dispatch({ type: UPDATE_COMPANY_STATUS, payload: { id, status } });
  
    getErrorStatusMessage({
      status: 200,
      message: `Company status successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateCompanyBalancedStatus = (id, balancedStatus) => async (dispatch) => {
  try {
    await request.update(`tools/companies/balanced-status/${id}`, { balancedStatus });
    dispatch({ type: UPDATE_COMPANY_BALANCED_STATUS, payload: { id, balancedStatus } });
  
    getErrorStatusMessage({
      status: 200,
      message: `Company balanced status successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const setIsUpdatedCompanyData = (isUpdated = false) => {
  return { type: SET_TOOLS_COMPANY_OVERVIEW_IS_UPDATED_DATA, payload: { isUpdated } };
};

export const updateCompanyBasicInfo = (id, company) => async (dispatch) => {
  try {
    dispatch(setIsUpdatedCompanyData(true));
    
    const { data } = await request.update(`tools/companies/basic-info/${id}`, { company });
    dispatch({ type: UPDATE_COMPANY_BASIC_INFO, payload: { id, company: data.company } });
    dispatch(closeCompanyOverview());
    getErrorStatusMessage({
      status: 200,
      message: `Company data successfully updated.`,
    });

    dispatch(setIsUpdatedCompanyData(false));
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateHibobIntegration = (id, hibob) => async (dispatch) => {
  try {
    await request.update(`tools/companies/hibob/${id}`, { hibob });
    dispatch({ type: UPDATE_COMPANY_HIBOB_INTEGRATION, payload: { id, hibob } });
    getErrorStatusMessage({
      status: 200,
      message: `Company hibob integration successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const addHibobIntegration = (id, hibob) => async (dispatch) => {
  try {
    await request.post(`tools/companies/hibob/${id}`, { hibob });
    dispatch({ type: ADD_COMPANY_HIBOB_INTEGRATION, payload: { id, hibob } });
    getErrorStatusMessage({
      status: 200,
      message: `Company hibob integration successfully added.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const deleteHibobIntegration = (id) => async (dispatch) => {
  try {
    await request.delete(`tools/companies/hibob/${id}`);
    dispatch({ type: DELETE_COMPANY_HIBOB_INTEGRATION, payload: { id } });
    getErrorStatusMessage({
      status: 200,
      message: `Company hibob integration successfully deleted.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateJiraIntegration = (id, jira) => async (dispatch) => {
  try {
    const response = await request.update(`tools/companies/jira/${id}`, { jira });

    dispatch({
      type: UPDATE_COMPANY_JIRA_INTEGRATION,
      payload: {
        id,
        jira: response.data.jira,
      },
    });
    getErrorStatusMessage({
      status: 200,
      message: `Company jira integration successfully updated.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const addJiraIntegration = (id, jira) => async (dispatch) => {
  try {
    const response = await request.post(`tools/companies/jira/${id}`, { jira });

    dispatch({
      type: ADD_COMPANY_JIRA_INTEGRATION,
      payload: {
        id,
        jira: response.data.jira,
      },
    });
    getErrorStatusMessage({
      status: 200,
      message: `Company jira integration successfully added.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const deleteJiraIntegration = (id) => async (dispatch) => {
  try {
    await request.delete(`tools/companies/jira/${id}`);
    dispatch({ type: DELETE_COMPANY_JIRA_INTEGRATION, payload: { id } });
    getErrorStatusMessage({
      status: 200,
      message: `Company jira integration successfully deleted.`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

