import styled from 'styled-components';

export const Line = styled.div`
  position: absolute;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  ${({ styles }) => styles && styles}
`;

export const CurrentStepLine = styled.div`
  position: absolute;
  width: ${({ steps, activeStep }) => `${(100 / steps) * activeStep}%`};
  border-top: 1px solid #275ADB;
  transition: all .3s ease-out;
  ${({ styles }) => styles && styles}
`;
