import React from 'react';
import { IEmployersTab } from './types';
import DynamicFormFields from '../../DynamicFormFields';
import { TabHelperText } from '../../../pages/SignUpCompany/suggestions';

const EmployersTab: React.FC<IEmployersTab> = ({ values, errors, setFieldValue, touched }) => {
  return (
    <DynamicFormFields
      data={values.employers}
      setFieldValue={setFieldValue}
      touched={touched}
      label="Employer"
      fieldName="employers"
      infoText={TabHelperText.employers}
      errors={errors}
    />
  );
};

export default EmployersTab;
