import { connect } from 'react-redux';
import App from './App.ui';
import { setAppReady } from '../../store/app/actions';
import { setCurrentUserLoaded, setCurrentUserLoading, setCurrentUser } from '../../store/currentUser/actions';

const mapStateToProps = ({ currentUser, auth }) => ({
  isCurrentUserLoading: currentUser.isCurrentUserLoading,
  isAuthenticated: auth.isAuthenticated,
  isCurrentUserLoaded: currentUser.isCurrentUserLoaded,
});

const mapDispatchToProps = {
  setCurrentUserLoading,
  setCurrentUserLoaded,
  setAppReady,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
