import request from '../../models/request';

export const updateAccountDetails = (userId, details) => {
  const { companyId, team, employer } = details;

  return request.update(`user/change-account-details/${userId}`, {
    userId,
    companyId: +companyId,
    employerId: +employer,
    teamId: +team,
  });
};

export const updateProfileSettings = (userId, settings) => {
  const {
    firstname,
    lastname,
    gender,
    title,
    birthDate: birthdate,
    phone: passenger_mobile_number,
    email: passenger_email,
  } = settings;

  return request.update(`user/change-profile-settings/${userId}`, {
    userId,
    firstname,
    lastname,
    gender,
    title,
    birthdate,
    passenger_mobile_number,
    passenger_email,
  });
};

export const updateTravelDocument = (userId, document) => {
  const { passengerId, country, number: document_number, expirationDate: document_valid } = document;

  return request.update(`user/change-travel-document/${userId}`, {
    passengerId,
    country: +country,
    document_number,
    document_valid,
  });
};

export const updatePassword = (userId, security) => {
  const { currentPassword, newPassword } = security;
  return request.update(`user/change-password/${userId}`, { userId, currentPassword, newPassword });
};

export const updateLoyaltyNumbers = (userId, loyaltyNumbers) => {
  return request.post(`user/loyalty-numbers/${userId}`, { loyaltyNumbers });
};

export const updateFavoriteHotels = (userId, favorites) => {
  const favoriteHotels = (favorites?.length ? favorites : []).map(item => ({ userId, hotelId: item?.hotelId }));
  return request.post(`user/favorite-hotels/${userId}`, { favoriteHotels });
};

export const updateCryptoWallets = (userId, cryptoWallets) => {
  const apiCryptoWallets = (cryptoWallets?.length ? cryptoWallets : [])
    .map(({ id, address, cryptoCurrency }) => ({ id, address, cryptoCurrencyId: +cryptoCurrency.value }));
  return request.post(`user/crypto-wallets/${userId}`, { cryptoWallets: apiCryptoWallets });
};
