import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { usePageTitle } from '../../models/page';

import './TextPage.scss';

const TextPage = ({ pageId, isAuthenticated }) => {
  const { t } = useTranslation();
  const pageTitle = usePageTitle(`page.${pageId}.meta.title`);

  return (
    <div className="text-page">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <h2>{t(`page.${pageId}.header`)}</h2>
      <div>{t(`page.${pageId}.text`)}</div>
      <div>
        {!isAuthenticated ? (
          <NavLink to="/signin">{t('page.elements.sign_in')}</NavLink>
        ) : (
          <NavLink to="/">{t('page.elements.main')}</NavLink>
        )}
      </div>
    </div>
  );
};

TextPage.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default TextPage;
