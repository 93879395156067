import { useState } from 'react'; 
import { Button } from '@heathmont/moon-core';
import { ControlsEye, ControlsEyeCrossed } from '@heathmont/moon-icons';
import { ITokenField } from './types';
import { TokenFieldWrapper } from './styles';
import EllipsisText from '../EllipsisText';

const TokenField: React.FC<ITokenField> = ({
  token = '',
}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <TokenFieldWrapper>
      {
        isShown ? (
          <>
            <EllipsisText text={token} />
            <Button
              iconOnly
              variant="ghost"
              size="small"
              type="button"
              onClick={() => setIsShown(false)}
            >
              <ControlsEyeCrossed />
            </Button>
            
          </>
        ) : (
          <>
            <>************</>
            <Button 
              iconOnly
              variant="ghost"
              size="small"
              type="button"
              onClick={() => setIsShown(true)}
            >
              <ControlsEye />
            </Button>
          </>
        ) 
      }
    </TokenFieldWrapper>
  );
};

export default TokenField;
