import Yup from '../../utils/validation';

const validateMessages = {
  email: {
    email: 'Email email must be a valid email', 
    required: 'Email can not be empty',  
  },
};

export default Yup.object().shape({
  email: Yup
    .string()
    .email(validateMessages.email.email)
    .required(validateMessages.email.required),
});
