import { ITripInfoBlocks } from './types';
import InfoBlock from '../../InfoBlock';
import {
  isAccommodationPulledFromRateHawk,
  isTripCanBeResendToVerification,
  isFlightPulledFromAmadeusQueue,
} from './utils';
import { useMemo } from 'react';

const TripInfoBlocks: React.FC<ITripInfoBlocks> = ({
  isCurrentUserTravelops,
  isTravelerExternal,
  isTravelerActive,
  paymentRequired,
  isPurchased,
  isApproved,
  trip,
  requestToSubmitExpensesReport,
  handleSendTripToVerification,
  createTTR,
}) => {
  const isFlightCanBeResendToVerification = useMemo(() => {
    return isCurrentUserTravelops && isTripCanBeResendToVerification(trip) && isFlightPulledFromAmadeusQueue(trip);
  }, [isCurrentUserTravelops, trip]);

  const isAccommodationCanBeResendToVerification = useMemo(() => {
    return isCurrentUserTravelops && isTripCanBeResendToVerification(trip) && isAccommodationPulledFromRateHawk(trip);
  }, [isCurrentUserTravelops, trip]);

  const isExpenseReportCanBeRequested = useMemo(() => {
    return isPurchased
      && isCurrentUserTravelops
      && !trip.expensesStatus
      && !isTravelerExternal
      && isTravelerActive
      && !!trip.purpose?.expensesRequired
  }, [trip, isPurchased, isCurrentUserTravelops, isTravelerExternal, isTravelerActive]);

  const isTTRCanBeCreated = useMemo(() => {
    return isApproved 
      && isCurrentUserTravelops 
      && !trip?.jiraTicketNumber 
      && trip?.companyJiraHost 
      && !isTravelerExternal
      && !paymentRequired;
  }, [trip, isApproved, isCurrentUserTravelops, isTravelerExternal, paymentRequired]);

  return (
    <>
      {isFlightCanBeResendToVerification && (
        <InfoBlock
          actionLabel="Send"
          text={`  
            Flight was pulled from the Amadeus sales platform with issued status. 
            You can send this trip to additional manager's verification.
          `}
          onHandleAction={() => handleSendTripToVerification(trip?.id)}
        />
      )}

      {isAccommodationCanBeResendToVerification && (
        <InfoBlock
          actionLabel="Send"
          text={`  
            Accommodation was pulled from the RateHawk website with issued status. 
            You can send this trip to additional manager's verification.
          `}
          onHandleAction={() => handleSendTripToVerification(trip?.id)}
        />
      )}

      {isExpenseReportCanBeRequested && (
        <InfoBlock
          actionLabel="Request"
          text={`  
            The trip is already confirmed. You can request the traveler
            to add expenses to the trip before the end of the trip.
          `}
          onHandleAction={() => requestToSubmitExpensesReport(trip?.id)}
        />
      )}

      {isTTRCanBeCreated && (
        <InfoBlock
          actionLabel="Create"
          text="The trip is already approved. But TTR was not created."
          onHandleAction={() => createTTR(trip?.id)}
        />
      )}

      {
        !!trip.isTransferRequired && (
          <InfoBlock text={'Traveler requested transfer'} />
        )
      }
    </>
  );
};

export default TripInfoBlocks;
