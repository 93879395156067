import styled from 'styled-components';

export const LogWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 15px;
  background: #80808021;
`;

export const LogLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogDate = styled.span`
  font-size: 14px;
  color: #78787d;
`;

export const LogText = styled.span`
  font-size: 14px;
`;
