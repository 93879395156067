import { FormTabType } from '../../enums/FormTabType';
import { optionsMapper, optionsBusinessVerticalMapper } from '../../pages/EditProfile/suggestions';
import {
  GET_SIGN_UP_OPTIONS_SUCCESS,
  GET_SIGN_UP_OPTIONS_ERROR,
  SET_ERROR_SIGN_UP_MESSAGE,
  SET_SIGN_UP_URL_VALIDITY,
  SET_IS_VALID_PASSWORD,
  GET_SIGN_UP_OPTIONS,
  SET_SUCCESS_SIGN_UP,
  SET_ACTIVE_TAB,
  SET_SIGN_UP_ERROR
} from './actionTypes';

const initState = {
  optionsLoading: false,
  optionsError: false,
  options: {
    firstname: '',
    lastname: '',
    passengerEmail: {
      isEditable: null,
      defaultValue: '',
    },
    passengerNumber: {
      isEditable: null,
      defaultValue: '',
    },
    company: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    role: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    employer: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    email: {
      isEditable: null,
      defaultValue: '',
    },
    team: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    businessVertical: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    gender: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    title: {
      isEditable: null,
      defaultValue: null,
      options: [],
    },
    countries: [],
    isValidNewPassword: false,
    successSignUp: false,
    errorMessage: '',
    signUpUrlValidity: false,
  },
  activeTab: FormTabType.AccountDetails,
  submitError: {
    isError: false,
    message: 'Something went wrong. Please, check entered data',
  },
};

const signUpReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SIGN_UP_OPTIONS: 
      return {
        ...state,
        optionsLoading: true,
        optionsError: false,
      };
  
    case GET_SIGN_UP_OPTIONS_SUCCESS:
      const { data, translate } = action.payload;
      const { user, passenger, document, contacts } = data.fields;
      const { company, employer, team, email, role, businessVertical } = user.fields;
      const { firstname, lastname, gender, title, birthdate } = passenger.fields;
      const { country, document_valid, document_number } = document.fields;
      const { passenger_email, passenger_mobile_number } = contacts.fields;

      return {
        ...state,
        optionsLoading: false,
        optionsError: false,
        options: {
          firstname: firstname?.value,
          lastname: lastname?.value,
          passengerEmail: {
            defaultValue: passenger_email.value,
            isEditable: !passenger_email?.editable,
          },
          passengerNumber: {
            defaultValue: passenger_mobile_number.value,
            isEditable: !passenger_mobile_number?.editable,
          },
          company: {
            defaultValue: company.options.find(({ id }) => id === company.value),
            isEditable: !company?.editable,
            options: optionsMapper(company.options, translate),
          },
          role: {
            defaultValue: role.options.find(({ id }) => id === role.value),
            isEditable: !role?.editable,
            options: optionsMapper(role.options, translate),
          },
          email: {
            defaultValue: email.value,
            isEditable: !company?.editable,
          },
          employer: {
            defaultValue: employer.options.find(({ id }) => id === employer.value),
            isEditable: !employer?.editable,
            options: optionsMapper(employer.options, translate),
          },
          team: {
            defaultValue: team.options.find(({ id }) => id === team.value),
            isEditable: !team?.editable,
            options: optionsMapper(team.options, translate),
          },
          businessVertical: {
            defaultValue: businessVertical.options.find(({ id }) => id === businessVertical.value),
            isEditable: !businessVertical?.editable,
            options: optionsBusinessVerticalMapper(businessVertical.options, translate),
          },
          birthDate: {
            defaultValue: birthdate?.value,
          },
          gender: {
            defaultValue: gender.options.find(({ id }) => id === gender.value),
            isEditable: !gender?.editable,
            options: optionsMapper(gender.options, translate),
          },
          title: {
            defaultValue: title.options.find(({ id }) => id === title.value),
            isEditable: !title?.editable,
            options: optionsMapper(title.options, translate),
          },
          country: {
            defaultValue: country.options.find(({ id }) => id === country.value),
            isEditable: !country?.editable,
            options: optionsMapper(country.options, translate),
          },
          documentNumber: {
            defaultValue: document_number.value,
            isEditable: !document_number?.editable,
          },
          documentValidTill: {
            defaultValue: document_valid.value,
            isEditable: !document_valid?.editable,
          },
        },
      };
    
    case GET_SIGN_UP_OPTIONS_ERROR: 
      return {
        ...state,
        optionsLoading: false,
        optionsError: action.payload.error,
      };

    case SET_IS_VALID_PASSWORD:
      return {
        ...state,
        isValidNewPassword: action.payload.isValid,
      };

    case SET_SUCCESS_SIGN_UP:
      return {
        ...state,
        successSignUp: action.payload.isSuccess,
      };

    case SET_ERROR_SIGN_UP_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.message,
      };

    case SET_SIGN_UP_URL_VALIDITY:
      return {
        ...state,
        signUpUrlValidity: action.payload.isValid,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };

    case SET_SIGN_UP_ERROR:
      return {
        ...state,
        submitError: {
          isError: action.payload.isSubmitError,
          message: action.payload.message,
        },
      };

    default:
      return state;
  }
};

export default signUpReducer;
