import { ITripFilters } from './types';
import SearchField from '../SearchField';
import { TableFilters } from '../TableFilters';

const FiltersReciever: React.FC<ITripFilters> = ({ filterComponents, isOpenDatePicker, placeholder, filters, setFilter }) => {
  return (
    <TableFilters
      isOpenDatePicker={isOpenDatePicker}
      searchField={
        <SearchField placeholder={placeholder} searchValue={filters.search} handleChange={(value) => setFilter('search', value)} />
      }
      filters={filterComponents}
    />
  );
};

export default FiltersReciever;
