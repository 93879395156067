import { GenericDelete, GenericEdit, TravelHotel } from '@heathmont/moon-icons-tw';
import { Button, Text } from '@heathmont/moon-core';
import moment from 'moment';
import {
  FlightInfoBlock,
  FlightContent,
  LeftContent,
  Icons,
} from '../styles';

interface IAccommodationOverview {
  accommodation: any;
  currencies: any[]
  cities: any[];
  hotels: any[];
  handleDelete: () => void; 
  handleEdit: () => void;
};

const AccommodationOverview = ({
  accommodation,
  currencies,
  cities,
  hotels,
  handleEdit,
  handleDelete
}: IAccommodationOverview) => {
  const formatAccommodationDate = (date) => {
    return moment(date).format('DD MMM YYYY');
  };

  const getCurrencyCodeFromId = (currencyId) => {
    return currencies.find((currency) => currency.value === currencyId)?.title || '';
  };

  const getCityNameFromId = (cityId, cities) => {
    return cities.find((city) => city?.value === cityId)?.title || '';
  };

  const getHotelName = (hotelId, hotelName) => {
    if (!hotelId) {
      return hotelName;
    }
    return hotels.find((hotel) => hotel?.value === hotelId)?.title || '';
  };

  return (
    <FlightInfoBlock>
      <LeftContent>
        <TravelHotel fontSize="1.6rem" />
        <FlightContent>
          <p>
            {getCityNameFromId(accommodation.city, cities)} — {getHotelName(accommodation.hotelId, accommodation.hotelName)}
          </p>
          <p className="flight-dates">
            {formatAccommodationDate(accommodation.checkIn)} → {formatAccommodationDate(accommodation.checkOut)}
          </p>
          <Text size={12}>
            <strong>Price: </strong>{`${accommodation.priceWithoutAdditionalPercent} ${getCurrencyCodeFromId(accommodation.currency)}`}
          </Text>
        </FlightContent>
      </LeftContent>
      <Icons>
        <Button
          variant="ghost"
          size="small"
          onClick={handleEdit}
          iconOnly={<GenericEdit fontSize="1.2rem"/>}
        />
        <Button
          variant="ghost"
          size="small"
          onClick={handleDelete}
          iconOnly={<GenericDelete fontSize="1.2rem"/>}
        />
      </Icons>
    </FlightInfoBlock>
  );
}

export default AccommodationOverview;
