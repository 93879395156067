import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import TablePagination from '../../../components/TablePagination';
import { Spinner } from '../../../components/Spinner';
import TableContentReciever from '../../../components/TableContentReciever';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import { toolsPurposesDataMapper } from '../../../utils/tableHelpers';
import { initialColumns } from './suggestions';
import { fetchCompanies } from '../../../store/tools/companies/actions';
import AddNewPurpose from '../../../components/AddNewPurpose';
import { ITripPurposes } from './types';
import { PageHeader, TableWrapper, Title } from '../styles';
import DownDrawer from '../../../components/DownDrawer';
import Dialog from '../../../components/Dialog';
import Drawer from '../../../components/Drawer';
import PurposeOverviewDrawerContent from '../../../components/PurposesOverviewDrawerContent';

const TripPurposes: React.FC<ITripPurposes> = ({
  isActiveAllPurposesCheckboxes,
  purposesCheckboxesData,
  isOpenPurposeOverview,
  isDeletePurposeModal,
  isOpenAddNewPurpose,
  overviewPurposeData,
  deletablePurposes,
  isChangedPurpose,
  totalPurposes,
  purposes,
  filters,
  sortBy,
  page,
  setAllPurposesCheckboxData,
  closeDeletePurposeModal,
  setPurposesCheckboxData,
  setIsOpenAddNewPurpose,
  showDeletePurposeModal,
  closePurposeOverview,
  showPurposeOverview,
  setQueryParams,
  addNewPurpose,
  removePurpose,
  fetchPurposes,
  updatePurpose,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);
  const dispatch = useDispatch();

  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const companies = useSelector((state: any) => state.companiesReducer.companies);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    fetchPurposes(params);
  }, [filters, page, sortBy, isChangedPurpose]);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  useMemo(() => {
    totalItems.current = totalPurposes;
  }, [totalPurposes]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const handleRemovePurpose = useCallback(async (data: any) => {
    await removePurpose(data);
    fetchPurposes(params);
    setPurposesCheckboxData();
    closeDeletePurposeModal();
    closePurposeOverview();
  }, []);

  const columns = useMemo(() => initialColumns, []);
  const purposesGroupedData = useMemo(() => toolsPurposesDataMapper(purposes), [purposes, companies]);

  const handleCloseAddNewPurpose = useCallback(() => {
    setIsOpenAddNewPurpose(false);
  }, []);

  const handleAddNewPurpose = useCallback((data: any) => {
    setIsOpenAddNewPurpose(false);
    addNewPurpose(data);
  }, []);

  const handleUpdatePurpose = async (purposeId, data: any) => {
    await updatePurpose(purposeId, data);
  };

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showPurposeOverview(purposes[row?.id]);
    },
    [purposes]
  );

  const tableContentRecieverProps = {
    isShowTableFilters,
    isActiveAllPurposesCheckboxes,
    isToolsPurposePage: true,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: purposesCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    rowHeight,
    withHover: true,
    sortBy,
    page,
    data: purposesGroupedData,
    handleSetAllCheckboxs: setAllPurposesCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: !hoveredRow?.original?.isDefaultPurpose && showDeletePurposeModal,
    toggleTableFilters,
    handleSetCheckbox: setPurposesCheckboxData,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete ';

    if ((purposesCheckboxesData && purposesCheckboxesData.length > 1) || isActiveAllPurposesCheckboxes) {
      return `${title} these trip purposes?`;
    }

    return `${title} this trip purpose?`;
  }, [isActiveAllPurposesCheckboxes, purposesCheckboxesData]);

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Trip purposes</Title>
          <Button variant="primary" size="small" onClick={() => setIsOpenAddNewPurpose(true)}>
            <ControlsPlus /> Add trip purpose
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.PURPOSES}
          totalItems={totalItems}
          data={purposes}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchPurposes(params)}
          nextPage={nextPage}
          prevPage={prevPage}
          callback={setAllPurposesCheckboxData}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenAddNewPurpose && (
        <AddNewPurpose
          secondButtonLabel="Add"
          companyList={companies}
          label="Add trip purpose"
          handleCloseDialog={handleCloseAddNewPurpose}
          handleAgree={handleAddNewPurpose}
        />
      )}

      {!!purposesCheckboxesData.length && (
        <DownDrawer
          data={purposesCheckboxesData}
          handleSetCheckbox={setPurposesCheckboxData}
          handleRemoveDialog={showDeletePurposeModal}
          text="trip purpose"
        />
      )}

      {isDeletePurposeModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletablePurposes}
          onSubmit={handleRemovePurpose}
          onClose={closeDeletePurposeModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {isOpenPurposeOverview && (
        <div>
          <Drawer
            title={overviewPurposeData?.title ?? ''}
            handleClose={closePurposeOverview}
          >
            <PurposeOverviewDrawerContent
              data={overviewPurposeData}
              handleUpdatePurpose={handleUpdatePurpose}
            />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default TripPurposes;
