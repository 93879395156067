import { Loader } from '@heathmont/moon-core';
import { ElementSize } from '../../enums/ElementSize';
import { IFileLoader } from './types';
import { LoaderStyled } from './styles';

const FileLoader: React.FC<IFileLoader> = ({ size = ElementSize.SMALL, color = 'trunks.100' }) => {
  return (
    <LoaderStyled>
      <Loader size={size} color={color} />
    </LoaderStyled>
  );
};

export default FileLoader;
