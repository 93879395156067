import { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { TextInput, Button } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { Checkbox } from '@heathmont/moon-core';
import { formatLabelForApplyToAll, formatPaymentTypes, getLabels } from '../../utils/tableHelpers';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import { getInitialValuesOnUpdate, purposeUpdateSchema } from '../AddNewPurpose/schema';
import { IPurposeOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper, ButtonsWrapper } from './styles';
import { PurposeTypes } from '../../enums/PurposeTypes';
import { N_A } from '../../utils/commonFunctions';
import { PAYMENT_TYPE, paymentTypeOptions } from '../../enums/PaymentTypes';

const PurposeOverviewDrawerContent: React.FC<IPurposeOverviewDrawerContent> = ({
  data,
  handleUpdatePurpose,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: purposeUpdateSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValuesOnUpdate(data),
    onSubmit: () => {},
  });
  const { touched, values, errors, dirty, resetForm, setFieldValue, setFieldTouched, setValues } = formik;
  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data.value]);

  const handlePaymentRequiredChange = (checked: boolean) => {
    setValues({
      ...values,
      expensesRequired: false,  
      paymentRequired: checked,
      paymentTypes: checked
        ? [PAYMENT_TYPE.CRYPTO, PAYMENT_TYPE.CARD]
        : []
    });
  };

  const handleCancel = () => {
    resetForm();
    setIsEditFormData(false);
  };

  const handleSave = () => {
    handleUpdatePurpose(data.value, values);
    setIsEditFormData(false);
    resetForm();
  };

  return (
    <div>
      {!isEditFormData && !data.isDefault && (
        <EditButtonWrapper>
          <Button iconLeft={<GenericEdit fontSize="1.2rem" />} variant="ghost" onClick={() => setIsEditFormData(!isEditFormData)}>
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          <OverviewItemsWrapper>
            <OverviewItemName>ID</OverviewItemName>
            <OverviewItem>{data.value}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Title</OverviewItemName>
            <OverviewItem>
              {
                isEditFormData ? (
                  <FormField
                    errorPosition={-20}
                    fieldName="label"
                    component={TextInput}
                    inputSize="xlarge"
                    isError={touched?.label && errors?.label}
                    fieldId="label"
                    errors={touched?.label && errors}
                    label="Purpose title"
                    value={values.label}
                    type="text"
                    onChange={(e) => setFieldValue('label', e.target.value)}
                    onBlur={() => setFieldTouched('label')}
                  />
                ) : (
                  data.title
                )
              }
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>For external bookings</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={data.type === PurposeTypes.EXTERNAL} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Companies</OverviewItemName>
            <OverviewItem>
              {!!data.isDefault ? formatLabelForApplyToAll('companies') : getLabels(data.companies, 'name')}
            </OverviewItem>  
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Payment required</OverviewItemName>
            <OverviewItem>
              <Checkbox
                disabled={!isEditFormData}
                checked={values.paymentRequired}
                onChange={(e) => handlePaymentRequiredChange(e.target.checked)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Payment types</OverviewItemName>
            <OverviewItem>
              {
                isEditFormData ? (
                  <CustomSelect
                    currentValue={values.paymentTypes}
                    placeholder='Payment types'
                    isDisabled={!values.paymentRequired}
                    isMulti
                    isError={!!(touched?.paymentTypes && errors?.paymentTypes)} 
                    error={(touched?.paymentTypes && errors?.paymentTypes) as string}
                    items={paymentTypeOptions.map(paymentTypeOption => ({ ...paymentTypeOption, title: paymentTypeOption.label }))}
                    onChange={(value) => setFieldValue('paymentTypes', value)}
                    onBlur={() => setFieldTouched('paymentTypes', true, false)}
                  />
                ) : (
                  data.paymentTypes?.length ? formatPaymentTypes(data.paymentTypes) : N_A
                )
              }
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Expense report required</OverviewItemName>
            <OverviewItem>
              <Checkbox
                disabled={!isEditFormData || data.type === PurposeTypes.EXTERNAL || values?.paymentRequired}
                checked={values.expensesRequired}
                onChange={(e) => setFieldValue('expensesRequired', e.target.checked)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Flight booking margin</OverviewItemName>
            <OverviewItem>
              {
                isEditFormData ? (
                  <FormField
                    errorPosition={-20}
                    fieldName="flightBookingMargin"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldId="flightBookingMargin"
                    isError={touched?.flightBookingMargin && errors?.flightBookingMargin}
                    errors={touched?.flightBookingMargin && errors}
                    label="Flight booking margin"
                    value={values.flightBookingMargin}
                    type="number"
                    onChange={(e) => setFieldValue('flightBookingMargin', e.target.value)}
                    onBlur={() => setFieldTouched('flightBookingMargin')}
                  />
                ) : (
                  data.flightBookingMargin || N_A
                )
              }
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Accommodation booking margin</OverviewItemName>
            <OverviewItem>
              {
                isEditFormData ? (
                  <FormField
                    errorPosition={-20}
                    fieldName="accommodationBookingMargin"
                    component={TextInput}
                    inputSize="xlarge"
                    fieldId="accommodationBookingMargin"
                    isError={touched?.accommodationBookingMargin && errors?.accommodationBookingMargin}
                    errors={touched?.accommodationBookingMargin && errors}
                    label="Accommodation booking margin"
                    value={values.accommodationBookingMargin}
                    type="number"
                    onChange={(e) => setFieldValue('accommodationBookingMargin', e.target.value)}
                    onBlur={() => setFieldTouched('accommodationBookingMargin')}
                  />
                ) : (
                  data.accommodationBookingMargin || N_A
                )
              }
            </OverviewItem>
          </OverviewItemsWrapper>

          {
            isEditFormData && (
              <ButtonsWrapper>
                <Button variant="secondary" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button disabled={!isEmpty(errors) || !dirty} type="button" onClick={handleSave}>
                  Save
                </Button>
              </ButtonsWrapper>
            )
          }
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PurposeOverviewDrawerContent;
