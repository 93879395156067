import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioDateGroup } from '../../components/RadioDateGroup';
import { RadioGroup } from '../../components/RadioGroup';

export const tripsColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
        </span>
      );
    },
    SubCell: () => null,
  },
  {
    Header: 'User & Team',
    accessor: 'userAndTeam',
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    Header: 'Destination',
    accessor: 'destination',
    disableSortBy: true,
  },
  {
    Header: 'Trip time',
    accessor: 'tripTime',
    minWidth: 225,
  },
  {
    Header: 'Purpose',
    accessor: 'purpose',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
  },
  {
    Header: 'Feedback',
    accessor: 'avgFeedback',
  },
  {
    Header: 'Total cost, EUR',
    accessor: 'totalCost',
  },
];

export const getTripFilters = (
  isOpenDatePicker,
  openDatePickerField,
  filters,
  options,
  handleDatePicketOpen,
  setFilter,
  toggleFiltersAndToFirstPage
): any => {
  return [
    {
      title: 'Type of traveler',
      openByDefault: true,
      component: (
        <RadioGroup
          selectedValue={filters?.typeOfTraveler}
          data={options?.typeOfTravelers}
          name="typeOfTraveler"
          onSelect={(value) => setFilter('typeOfTraveler', value)}
        />
      ),
    },
    {
      title: 'Trip start time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripStartTime'}
          tripTime={filters.tripStartTime}
          range={filters.tripStartTimeRange}
          data={options.relativeDates}
          name="tripStartTime"
          setIsOpenDatePicker={handleDatePicketOpen('tripStartTime')}
          handleDateRange={(value) => setFilter('tripStartTimeRange', value)}
          handleTripDate={(value) => setFilter('tripStartTime', value)}
        />
      ),
    },
    {
      title: 'Trip end time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripEndTime'}
          tripTime={filters.tripEndTime}
          range={filters.tripEndTimeRange}
          data={options.relativeDates}
          name="tripEndTime"
          setIsOpenDatePicker={handleDatePicketOpen('tripEndTime')}
          handleDateRange={(value) => setFilter('tripEndTimeRange', value)}
          handleTripDate={(value) => setFilter('tripEndTime', value)}
        />
      ),
    },
    {
      title: 'Creation time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'creationTime'}
          tripTime={filters?.creationTime}
          range={filters?.creationTimeRange}
          name="creationTime"
          data={options?.relativeDates}
          setIsOpenDatePicker={handleDatePicketOpen('creationTime')}
          handleDateRange={(value) => setFilter('creationTimeRange', value)}
          handleTripDate={(value) => setFilter('creationTime', value)}
        />
      ),
    },
    {
      title: 'Type',
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.tripTypes}
          data={options?.tripTypes}
          onSelect={(value) => toggleFiltersAndToFirstPage('tripTypes', value)}
        />
      ),
    },
    {
      title: `Employer • ${options?.employers.length} `,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.employers}
          data={options?.employers}
          onSelect={(value) => toggleFiltersAndToFirstPage('employers', value)}
        />
      ),
    },
    {
      title: `Team • ${options?.teams.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.teams}
          data={options?.teams}
          onSelect={(value) => toggleFiltersAndToFirstPage('teams', value)}
        />
      ),
    },
    {
      title: `Status • ${options?.statuses.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.statuses}
          data={options?.statuses}
          onSelect={(value) => toggleFiltersAndToFirstPage('statuses', value)}
        />
      ),
    },
    {
      title: `Purpose • ${options?.purposes.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.purposes}
          data={options?.purposes}
          onSelect={(value) => toggleFiltersAndToFirstPage('purposes', value)}
        />
      ),
    },
    {
      title: 'Feedback',
      openByDefault: true,
      component: (
        <RadioGroup
          selectedValue={filters?.avgFeedback}
          data={options?.feedbacks}
          name="avgFeedback"
          onSelect={(value) => setFilter('avgFeedback', value)}
        />
      ),
    },
  ];
};
