import { useState, useCallback } from 'react';

const useBlockHover = () => {
  const [isHovered, setIsHoveredItem] = useState(false);

  const setIsHovered = useCallback(() => {
    setIsHoveredItem(prev => !prev)
  }, [])

  return [isHovered, setIsHovered]
}

export default useBlockHover;