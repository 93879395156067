
import moment from 'moment';
import request from '../../models/request';
import { DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';
import { HotelRegion } from '../../enums/HotelRegion';

export interface CityOption {
  title: string;
  value: number;
}; 

const getFormattedCities = (cities: any[]): CityOption[] => {
  return cities.map((city) => ({
    title: `${city.name} (${city.country})`,
    value: city.id,
  }));
};

export const fetchCities = async ({
  search,
  setIsCitiesLoading,
  setCitiesOptions,
  setFieldValue,
}: {
  search: string;
  setIsCitiesLoading: (isLoading: boolean) => void;
  setCitiesOptions: (options: CityOption[]) => void;
  setFieldValue: (field: string, value: any) => void;
}) => {
  if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
    setCitiesOptions([]);
    setIsCitiesLoading(true);

    try {
      const { data } = await request.get(`locations/cities`, { params: { search } });

      setCitiesOptions(getFormattedCities(data.airports || []));
      
      if (setFieldValue) {
        setFieldValue('regionId', null);
      }
    } catch (err) {
      setCitiesOptions([]);
    } finally {
      setIsCitiesLoading(false);
    }
  }
};

export const convertTimeToDate = (time: string) => {
  return moment(time, 'HH:mm:ss').toISOString()
};

export const getInitialCitiesOptions = (region?: HotelRegion): CityOption[] => {
  if (region) {
    return [
      {
        title: region.name,
        value: region.id,
      },
    ];
  }

  return [];
};
