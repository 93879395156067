import Yup from '../../utils/validation';

const validateMessages = {
  traveler: {
    required: 'Traveler must be provided',
  },
  purpose: {
    required: 'Purpose must be provided',
  },
  purposeDetails: {
    required: 'Purpose details must be provided',
    max: 'Details cannot be longer than 1000 characters',
  },
  flight: {
    departureAt: {
      required: 'Departure date must be provided',
    },
    arrivalAt: {
      required: 'Arrival date must be provided',
      afterDeparture: 'Arrival date must be after departure',
    },
    departureCode: {
      required: 'Departure airport must be provided',
      notEqualArrivalCode: 'The departure airport cannot be the same as the arrival airport',
    },
    arrivalCode: {
      required: 'Arrival airport must be provided',
      notEqualDepartureCode: 'The arrival airport cannot be the same as the departure airport',
    },
    airline: {
      required: 'Airline must be provided',
    },
    flightNumber: {
      required: 'Flight number must be provided',
      type: 'Flight number must be numeric',
      max: 'Flight number cannot have more than 5 digits'
    },
    priceWithoutAdditionalPercent: {
      required: 'Price must be provided',
      min: 'Price cannot be less than 0',
      type: 'Price must be numeric',
    },
    currency: {
      required: 'Currency must be provided',
    },
    meals: {
      max: 'Meals details cannot be longer than 255 characters',
    },
    cabin: {
      max: 'Cabin details cannot be longer than 255 characters',
    },
    passenger: {
      required: 'Passenger must be provided',
    },
    baggageQuantity: {
      required: 'Quantity must be provided',
      type: 'Quantity must be numeric',
      min: 'Quantity cannot be less than 0',
      max: 'Quantity cannot be more than 10',
    },
    baggageWeight: {
      required: 'Weight must be provided',
      type: 'Weight must be numeric',
      min: 'Weight cannot be less than 0',
      max: 'Weight cannot be more than 100',
    },
    baggageWeightUnit: {
      required: 'Wright unit must be provided',
    },
  },
  accommodation: {
    checkIn: {
      required: 'Check in time must be provided',
    },
    checkOut: {
      required: 'Check out time must be provided',
      beforeCheckIn: 'Check out date must be after check in time',
    },
    city: {
      required: 'City must be provided',
    },
    hotelId: {
      required: 'Hotel must be provided',
    },
    hotelName: {
      required: 'Hotel must be provided',
    },
    priceWithoutAdditionalPercent: {
      required: 'Price must be provided',
      min: 'Price cannot be less than 0',
      type: 'Price must be numeric',
    },
    currency: {
      required: 'Currency must be provided',
    },
  },
  flights: {
    required: 'External trip must have at least one flight or accommodation or other transport',
  },
  accommodations: {
    required: 'External trip must have at least one flight or accommodation or other transport',
  },
  otherTransports: {
    required: 'External trip must have at least one flight or accommodation or other transport',
  },
  otherTransport: {
    departureDate: {
      required: 'Departure date and time must be provided',
    },
    arrivalDate: {
      required: 'Arrival date and time must be provided',
      afterDeparture: 'Arrival date must be after departure',
    },
    departureCity: {
      required: 'Departure city must be provided',
    },
    arrivalCity: {
      required: 'Arrival city must be provided',
    },
    priceWithoutAdditionalPercent: {
      required: 'Price must be provided',
      min: 'Price cannot be less than 0',
      type: 'Price must be numeric',
    },
    type: {
      required: 'Type must be provided',
    },
  },
};

export const AddExternalTripSchema = Yup.object().shape({
  traveler: Yup
    .number()
    .required(validateMessages.traveler.required)
    .typeError(validateMessages.traveler.required),
  purpose: Yup
    .number()
    .required(validateMessages.purpose.required)
    .typeError(validateMessages.traveler.required),
  purposeDetails: Yup
    .string()
    .required(validateMessages.purposeDetails.required)
    .max(1000,validateMessages.purposeDetails.max),
  flights: Yup.array().test(
    'is-at-least-one-flight-or-accommodation-or-other-transport',
    validateMessages.flights.required,
    (value, ctx) => {
      const arrayIsEmpty = value.length < 1;
      const accommodationsAreNotEmpty = ctx.parent.accommodations.length >= 1;
      const otherTransportsAreNotEmpty = ctx.parent.otherTransports.length >= 1;
      if (!arrayIsEmpty || (arrayIsEmpty && (accommodationsAreNotEmpty || otherTransportsAreNotEmpty))) {
        return true;
      }
    }
  ),
  accommodations: Yup.array().test(
    'is-at-least-one-flight-or-accommodation-or-other-ransport',
    validateMessages.accommodations.required,
    (value, ctx) => {
      const arrayIsEmpty = value.length < 1;
      const flightsAreNotEmpty = ctx.parent.flights.length >= 1;
      const otherTransportsAreNotEmpty = ctx.parent.otherTransports.length >= 1;
      if (!arrayIsEmpty || (arrayIsEmpty && (flightsAreNotEmpty || otherTransportsAreNotEmpty))) {
        return true;
      }
    }
  ),
  otherTransports: Yup.array().test(
    'is-at-least-one-flight-or-accommodation-or-other-transport',
    validateMessages.otherTransports.required,
    (value, ctx) => {
      const arrayIsEmpty = value.length < 1;
      const flightsAreNotEmpty = ctx.parent.flights.length >= 1;
      const accommodationsAreNotEmpty = ctx.parent.accommodations.length >= 1;
      if (!arrayIsEmpty || (arrayIsEmpty && (flightsAreNotEmpty || accommodationsAreNotEmpty))) {
        return true;
      }
    }
  )
});

export const AddExternalFlightSchema = Yup.object().shape({
  segments: Yup
    .array()
    .min(1, 'Flight must have at least one segment'),
  priceWithoutAdditionalPercent: Yup
    .number()
    .required(validateMessages.flight.priceWithoutAdditionalPercent.required)
    .min(0, validateMessages.flight.priceWithoutAdditionalPercent.min)
    .typeError(validateMessages.flight.priceWithoutAdditionalPercent.type),
  currency: Yup
    .number()
    .required(validateMessages.flight.currency.required)
    .typeError(validateMessages.flight.currency.required),
  reference: Yup
    .string()
    .nullable(),
});

export const ExtraBagSchema = Yup.object({
  passengerId: Yup
    .string()
    .required(validateMessages.flight.passenger.required)
    .typeError(validateMessages.flight.passenger.required),
  segmentId: Yup
    .string()
    .nullable(),
  quantity: Yup
    .number()
    .required(validateMessages.flight.baggageQuantity.required)
    .typeError(validateMessages.flight.baggageQuantity.required)
    .min(0, validateMessages.flight.baggageQuantity.min)
    .max(10, validateMessages.flight.baggageQuantity.max),
  weight: Yup
    .number()
    .required(validateMessages.flight.baggageWeight.required)
    .typeError(validateMessages.flight.baggageWeight.required)
    .min(0, validateMessages.flight.baggageWeight.min)
    .max(100, validateMessages.flight.baggageWeight.max),
  weightUnit: Yup
    .string()
    .required(validateMessages.flight.baggageWeight.required),
});

export const BaggageSchema = Yup.object().shape({
  quantity: Yup
    .number()
    .nullable()
    .typeError(validateMessages.flight.baggageQuantity.type)
    .min(0, validateMessages.flight.baggageQuantity.min)
    .max(10, validateMessages.flight.baggageQuantity.max),
  weight: Yup
    .number()
    .nullable()
    .typeError(validateMessages.flight.baggageWeight.type)
    .min(0, validateMessages.flight.baggageWeight.min)
    .max(100, validateMessages.flight.baggageWeight.max),
  weightUnit: Yup
    .string()
    .nullable(),
});

export const AddFlightSegmentSchema = Yup.object().shape({
  includedCheckedBags: BaggageSchema.nullable(),
  additionalServices: Yup
    .array()
    .of(ExtraBagSchema),
  departureAt: Yup
    .date()
    .required(validateMessages.flight.departureAt.required)
    .typeError(validateMessages.flight.departureAt.required),
  arrivalAt: Yup
    .date()
    .required(validateMessages.flight.arrivalAt.required)
    .typeError(validateMessages.flight.arrivalAt.required)
    .test('is-after-departure', validateMessages.flight.arrivalAt.afterDeparture, (value, ctx) => new Date(value).getTime() > new Date(ctx.parent.departureAt).getTime()),
  departureCode: Yup.string()
    .required(validateMessages.flight.departureCode.required)
    .typeError(validateMessages.flight.departureCode.required)
    .test('is-not-equal-arrival-code', validateMessages.flight.departureCode.notEqualArrivalCode, (value, ctx) => value !== ctx.parent.arrivalCode),
  arrivalCode: Yup.string()
    .required(validateMessages.flight.arrivalCode.required)
    .typeError(validateMessages.flight.arrivalCode.required)
    .test('is-not-equal-departure-code', validateMessages.flight.arrivalCode.notEqualDepartureCode, (value, ctx) => value !== ctx.parent.departureCode),
  airline: Yup
    .number()
    .required(validateMessages.flight.airline.required)
    .typeError(validateMessages.flight.airline.required),
  flightNumber: Yup
    .number()
    .required(validateMessages.flight.flightNumber.required)
    .typeError(validateMessages.flight.flightNumber.type)
    .max(99999, validateMessages.flight.flightNumber.max),
  meals: Yup
    .string()
    .nullable()
    .max(255, validateMessages.flight.meals.max),
  cabin: Yup
    .string()
    .nullable()
    .max(255, validateMessages.flight.cabin.max),
});

export const AddExternalAccommodationSchema = Yup.object().shape({
  checkIn: Yup
    .date()
    .required(validateMessages.accommodation.checkIn.required)
    .typeError(validateMessages.accommodation.checkIn.required),
  checkOut: Yup
    .date()
    .required(validateMessages.accommodation.checkOut.required)
    .typeError(validateMessages.accommodation.checkOut.required)
    .test('is-after-check-in', validateMessages.accommodation.checkOut.beforeCheckIn, (value, ctx) => new Date(value).getTime() > new Date(ctx.parent.checkIn).getTime()),
  city: Yup
    .number()
    .required(validateMessages.accommodation.city.required),
  hotelId: Yup
    .string()
    .nullable()
    .test('is-hotel-name-provided', validateMessages.accommodation.hotelId.required, (value, ctx) => {
      const customHotelName = ctx.parent.hotelName
      if (value || (!value && customHotelName)) {
        return true;
      }
      return false;
    }),
  hotelName: Yup
    .string()
    .nullable()
    .test('is-hotel-name-provided', validateMessages.accommodation.hotelName.required, (value, ctx) => {
      const selectedHotelId = ctx.parent.hotelId
      if (value || (!value && selectedHotelId)) {
        return true;
      }
      return false;
    }),
  priceWithoutAdditionalPercent: Yup
    .number()
    .required(validateMessages.accommodation.priceWithoutAdditionalPercent.required)
    .min(0, validateMessages.accommodation.priceWithoutAdditionalPercent.min)
    .typeError(validateMessages.accommodation.priceWithoutAdditionalPercent.type),
  currency: Yup
    .number()
    .required(validateMessages.accommodation.currency.required)
    .typeError(validateMessages.accommodation.currency.required),
});

export const AddOtherTransportSchema = Yup.object().shape({
  departureDate: Yup
    .date()
    .required(validateMessages.otherTransport.departureDate.required)
    .typeError(validateMessages.otherTransport.departureDate.required),
  arrivalDate: Yup
    .date()
    .required(validateMessages.otherTransport.arrivalDate.required)
    .typeError(validateMessages.otherTransport.arrivalDate.required)
    .test('is-after-departure', validateMessages.otherTransport.arrivalDate.afterDeparture, (value, ctx) => new Date(value).getTime() > new Date(ctx.parent.departureDate).getTime()),
  departureCity: Yup
    .number()
    .nullable()
    .required(validateMessages.otherTransport.departureCity.required),
  arrivalCity: Yup
    .number()
    .nullable()
    .required(validateMessages.otherTransport.arrivalCity.required),  
  priceWithoutAdditionalPercent: Yup
    .number()
    .required(validateMessages.otherTransport.priceWithoutAdditionalPercent.required)
    .min(0, validateMessages.otherTransport.priceWithoutAdditionalPercent.min)
    .typeError(validateMessages.otherTransport.priceWithoutAdditionalPercent.type),
  reference: Yup
    .string()
    .nullable(),
  type: Yup
    .string()
    .required(validateMessages.otherTransport.type.required)
    .typeError(validateMessages.otherTransport.type.required),
});
