import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';

export const addNewRole = async (data) => {
  try {
    await request.post('tools/roles', data);

    getErrorStatusMessage({
      status: 200,
      message: 'New role successfully added',
    });
  
    return;
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const removeRole = async (roleIds) => {
  for (const roleId of roleIds) {
    try {
      await request.delete(`tools/roles/${roleId}`);

      getErrorStatusMessage({
        status: 200,
        message: `Role with ID - ${roleId} successfully removed`,
      });
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: `Role with ID - ${roleId} can't be removed`,
      });
    }
  }
};
