import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';

export const removeEmployer = (employerIds) => {
  return Promise.allSettled(employerIds.map((employerId) => {
    return request
      .delete(`tools/employers/${employerId}`)
      .then(() => {
        getErrorStatusMessage({
          status: 200,
          message: `Employer with ID - ${employerId} successfully removed`,
        });
      })
      .catch((e) => {
        getErrorStatusMessage({
          status: e?.response?.status,
          message: `Employer with ID - ${employerId} can't be removed`,
        });
      });
  }));
};

export const fetchCompanyAccountants = async (companyId: number): Promise<any[]> => {
  try {
    const { data } = await request.get(`tools/accountants?companyId=${companyId}`);
    return data.records.map((el) => ({ value: el.id, title: el.title, businessVerticalId: el.businessVerticalId }));
  } catch (error) {
    return [];
  }
};