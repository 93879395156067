import { useMemo, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import { TextInput } from '@heathmont/moon-components';
import { Button } from '@heathmont/moon-core';
import { ICustomHotelOverviewDrawerEditContent } from './types';
import { Wrapper, OverviewItemsWrapper, OverviewItemName, OverviewItem, ButtonsWrapper } from './styles';
import CustomSelect from '../CustomSelect';
import StarRating from '../StarRating';
import DatePicker from '../DatePicker';
import FormField from '../FormField';
import { DEFAULT_DEBOUNCE_MS_COUNT, DEFAULT_MIN_LENGTH_TO_START_SEARCHING } from '../../constants';
import { customHotelSchema, getInitialValues } from '../AddNewCustomHotel/schema';
import { hotelTypesOptions } from '../../enums/HotelType';
import { CityOption, fetchCities, getInitialCitiesOptions } from '../AddNewCustomHotel/utils';

const CustomHotelOverviewDrawerEditContent: React.FC<ICustomHotelOverviewDrawerEditContent> = ({
  hotel,
  setIsEditFormData,
  handleUpdate,
}) => {
  const initialValues = useMemo(() => getInitialValues(hotel), [hotel]);
  const [citiesOptions, setCitiesOptions] = useState<CityOption[]>(getInitialCitiesOptions(hotel.region));
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const hotelTypes = hotelTypesOptions.map(option => ({ ...option, title: option.label }));

  return (
    <Wrapper>
      <Formik
        validateOnBlur={false}
        validationSchema={customHotelSchema}
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ errors, values, resetForm, setFieldValue, touched, setFieldTouched, handleBlur }: any): JSX.Element => (
          <Form>
            <OverviewItemsWrapper>
              <OverviewItemName>Name</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Name"
                  component={TextInput}
                  inputSize="large"
                  fieldName="name"
                  fieldId="name"
                  isError={!!(touched?.name && errors?.name)}
                  errors={touched?.name && errors}
                  value={values.name}
                  type="text"
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  onBlur={() => setFieldTouched('name')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Type</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  currentValue={values.type}
                  isError={!!(touched?.type && errors?.type)}
                  error={touched?.type && errors?.type}
                  items={hotelTypes}
                  onChange={(value) => setFieldValue('type', value)}
                  onBlur={() => setFieldTouched('type')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>City</OverviewItemName>
              <OverviewItem>
                <CustomSelect
                  minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                  currentValue={values.regionId}
                  isSearchable
                  placeholder='Search...'
                  isLoading={isCitiesLoading}
                  isError={!!(touched?.regionId && errors?.regionId)}
                  error={touched?.regionId && errors?.regionId}
                  items={citiesOptions}
                  onInputChange={debounce((search) => fetchCities({ search, setFieldValue, setCitiesOptions, setIsCitiesLoading }), DEFAULT_DEBOUNCE_MS_COUNT)}
                  onChange={(value) => setFieldValue('regionId', value)}
                  onBlur={() => setFieldTouched('regionId', true, false)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Address</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Address"
                  component={TextInput}
                  fieldName="address"
                  inputSize="large"
                  fieldId="address"
                  isError={!!(touched?.address && errors?.address)}
                  errors={touched?.address && errors}
                  value={values.address}
                  type="text"
                  onChange={(e) => setFieldValue('address', e.target.value)}
                  onBlur={() => setFieldTouched('address')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Latitude</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Latitude"
                  component={TextInput}
                  inputSize="large"
                  fieldName="latitude"
                  fieldId="latitude"
                  isError={!!(touched?.latitude && errors?.latitude)}
                  errors={touched?.latitude && errors}
                  value={values.latitude}
                  type="number"
                  onChange={(e) => setFieldValue('latitude', e.target.value)}
                  onBlur={() => setFieldTouched('latitude')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Longitude</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Longitude"
                  component={TextInput}
                  inputSize="large"
                  fieldName="longitude"
                  fieldId="longitude"
                  isError={!!(touched?.longitude && errors?.longitude)}
                  errors={touched?.longitude && errors}
                  value={values.longitude}
                  type="number"
                  onChange={(e) => setFieldValue('longitude', e.target.value)}
                  onBlur={() => setFieldTouched('longitude')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Star rating</OverviewItemName>
              <OverviewItem>
                <StarRating
                  precision={1}
                  error={touched?.starRating && errors?.starRating ? errors.starRating : null}
                  rate={values.starRating}
                  onChange={(value) => {
                    setFieldValue('starRating', value);
                    setFieldTouched('starRating');
                  }}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Check in time</OverviewItemName>
              <OverviewItem>
                <DatePicker
                  showTimeSelectOnly
                  showTimeSelect
                  placeholder={'Check in time'}
                  isEditView={true}
                  isError={!!(touched?.checkInTime && errors?.checkInTime)}
                  format={'HH:mm'}
                  errors={touched?.checkInTime && errors}
                  value={values.checkInTime}
                  field="checkInTime"
                  onChange={(field, value) => setFieldValue(field, value)}
                  onBlur={() => setFieldTouched('checkInTime')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Check out time</OverviewItemName>
              <OverviewItem>
                <DatePicker
                  showTimeSelectOnly
                  showTimeSelect
                  placeholder={'Check out time'}
                  isEditView={true}
                  isError={!!(touched?.checkOutTime && errors?.checkOutTime)}
                  format={'HH:mm'}
                  errors={touched?.checkOutTime && errors}
                  value={values.checkOutTime}
                  field="checkOutTime"
                  onChange={(field, value) => setFieldValue(field, value)}
                  onBlur={() => setFieldTouched('checkOutTime')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Email</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Email"
                  component={TextInput}
                  inputSize="large"
                  fieldName="email"
                  fieldId="email"
                  isError={!!(touched?.email && errors?.email)}
                  errors={touched?.email && errors}
                  value={values.email}
                  type="email"
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  onBlur={() => setFieldTouched('email')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <OverviewItemsWrapper>
              <OverviewItemName>Phone</OverviewItemName>
              <OverviewItem>
                <FormField
                  errorPosition={-20}
                  placeholder="Phone"
                  component={TextInput}
                  inputSize="large"
                  fieldName="phone"
                  fieldId="phone"
                  isError={!!(touched?.phone && errors?.phone)}
                  errors={touched?.phone && errors}
                  value={values.phone}
                  type="text"
                  onChange={(e) => setFieldValue('phone', e.target.value)}
                  onBlur={() => setFieldTouched('phone')}
                />
              </OverviewItem>
            </OverviewItemsWrapper>

            <ButtonsWrapper>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Cancel</Button>
              <Button
                variant="primary"
                disabled={!isEmpty(errors)}
                onClick={() => {
                  handleUpdate(hotel.id, values);
                  setIsEditFormData(false);
                  resetForm();
                }}
              >Save</Button>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default CustomHotelOverviewDrawerEditContent;
