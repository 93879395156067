import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span style={{ width: '50px' }} {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'employerId',
    disableSortBy: false,
    minWidth: 80,
  },
  {
    Header: 'Employer',
    accessor: 'employerName',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Company',
    accessor: 'companyName',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Business Verticals',
    accessor: 'businessVerticals',
    disableSortBy: true,
    minWidth: 180,
  },
  {
    Header: 'Registration Number',
    accessor: 'registrationNumber',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Accountant',
    accessor: 'accountant',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
    minWidth: 180,
  },
];
