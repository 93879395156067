import { TextInput, Checkbox, Text } from '@heathmont/moon-core';
import { FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { IRequestPaymentDialog } from './types';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
import Dialog from '../Dialog';
import { CheckboxWrapper, FieldsWrapper, FormItem } from './styles';
import { paymentRequestSchema, getInitialValues } from './schema';
import { findAndFormatPassengers, formatFlightOptions, normalizePaymentRequest } from './utils';
import { requestPaymentReasonOptions } from '../../enums/RequestPaymentReason';
import { useMemo } from 'react';
import Tooltip from '../Tooltip';
import { NotificationsQuestionMark } from '@heathmont/moon-icons-tw';

const RequestPaymentDialog: React.FC<IRequestPaymentDialog> = ({
  isLoading,
  trip,
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: paymentRequestSchema,
    validateOnMount: true,
    initialValues: getInitialValues(trip),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched } = formik;

  const passengers = useMemo(() => {
    return findAndFormatPassengers(trip, values.flightId);
  }, [values.flightId]);

  const handleFlightChange = (value) => {
    const flight = trip.flights.find(flight => flight.id === value);
    const passenger = flight && flight.passengers.length === 1 ? flight.passengers[0] : null;
    setValues({
      ...values,
      passengerId: passenger ? passenger.id : null,
      flightId: flight ? flight.id : null,
    });
  };

  return (
    <Dialog
      submitButtonLabel='Submit'
      isSubmitDisabled={!isEmpty(errors)}
      withoutMinWidth
      isLoading={isLoading}
      width={500}
      title={`Request payment — ID ${trip.id}`}
      data={trip}
      onSubmit={() => onSubmit(normalizePaymentRequest(values, passengers))}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <FieldsWrapper>        
          <FormItem>
            <CustomSelect
              currentValue={values.flightId}
              placeholder='Select flight'
              isError={!!(touched?.flightId && errors?.flightId)}
              label='Flight'
              items={formatFlightOptions(trip.flights)}
              error={touched?.flightId && errors?.flightId}
              size='xLarge'
              onChange={handleFlightChange}
              onBlur={() => setFieldTouched('flightId', true, false)}
            />
          </FormItem>
          <FormItem>
            <CustomSelect
              currentValue={values.reason}
              placeholder='Select reason'
              isError={!!(touched?.reason && errors?.reason)}
              items={requestPaymentReasonOptions}
              error={touched?.reason && errors?.reason}
              label='Reason'
              size='xLarge'
              onChange={value => setFieldValue('reason', value)}
              onBlur={() => setFieldTouched('reason', true, false)}
            />
          </FormItem>
          <FormItem>
            <FormField
              placeholder={'Specify details'}
              fieldName='details'
              component={TextInput}
              inputSize='xlarge'
              fieldId='details'
              isError={touched?.details && errors?.details}
              errors={touched?.details && errors}
              label={'Details'}
              value={values.details}
              type='text'
              onChange={({ target: { value } }) => setFieldValue('details', value)}
              onBlur={() => setFieldTouched('details', true, false)}
            />
          </FormItem>
          <FormItem>
            <CustomSelect
              currentValue={values.passengerId}
              placeholder='Select passenger'
              isDisabled={!!values?.applyToAllPassengers}
              isError={!!(touched?.passengerId && errors?.passengerId)}
              items={passengers}
              error={touched?.passengerId && errors?.passengerId}
              label='Passenger'
              size='xLarge'
              onChange={value => setFieldValue('passengerId', value)}
              onBlur={() => setFieldTouched('passengerId', true, false)}
            />
          </FormItem>
          <FormItem>
            <CheckboxWrapper>
              <Checkbox
                checked={values?.applyToAllPassengers}
                label={'Apply to all passengers'}
                onChange={() => {
                  setFieldTouched('applyToAllPassengers', true);
                  setFieldValue('passengerId', null);
                  setFieldValue('applyToAllPassengers', !values?.applyToAllPassengers);
                }}
              />
              <Tooltip label={'Total amount will be split between all passengers equally'}>
                <NotificationsQuestionMark fontSize={24} />
              </Tooltip>
            </CheckboxWrapper>
          </FormItem>
          <FormItem>
            <FormField
              placeholder={'Specify total sum'}
              fieldName='sum'
              component={TextInput}
              inputSize='xlarge'
              fieldId='sum'
              isError={touched?.sum && errors?.sum}
              errors={touched?.sum && errors}
              label={'Total sum without margin, EUR'}
              value={values.sum}
              step="any"
              type='number'
              onChange={({ target: { value } }) => setFieldValue('sum', value)}
              onBlur={() => setFieldTouched('sum', true, false)}
            />
          </FormItem>
        </FieldsWrapper>
      </FormikProvider>
    </Dialog>
  );
};

export default RequestPaymentDialog;
