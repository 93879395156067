import Yup from '../../utils/validation';

const validateMessages = {
  email: {
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  password: {
    min: 'Password cannot be less than 8 characters',
    required: 'Password cannot be empty',
  },
};

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().email(validateMessages.email.valid).required(validateMessages.email.required),
  password: Yup.string()
    .required(validateMessages.password.required)
    .min(8, validateMessages.password.min)
    .when('currentPassword', {
      is: (currentPassword) => !!currentPassword,
      then: Yup.string().required(validateMessages.password.required),
      otherwise: Yup.string().notRequired(),
    }),
});
