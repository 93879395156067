import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
  gap: 7.5px;
  margin: 15px 40px 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc(100% - 80px);
  height: 72px;
  
  ${({ isMobile }) => isMobile && `
     flex-wrap: initial;
  `}
`;

export const TabButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  border: 1px solid ${({ isActive }) => (isActive ? '#FCE9B0' : '#e0e0e0')};
  background-color: ${({ isActive }) => (isActive ? 'rgba(253, 239, 196, 0.38)' : '#ffffff')};
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    text-shadow: 0 0 0.01px black;
    border: 1px solid ${({ isActive }) => (isActive ? '#f6dc8f' : '#838383')};
  }
`;

export const TabButtonEdit = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ isActive }) => (isActive ? '#181B1C' : '#78787d')};

  ${({ isMobile }) => isMobile && `
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  `}
`;
