export const SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA = 'tools/SET_TOOLS_EMPLOYERS_OVERVIEW_IS_UPDATED_DATA';
export const SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA = 'tools/SET_TOOLS_ALL_EMPLOYERS_CHECKBOX_DATA';
export const SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL = 'tools/SET_IS_OPEN_ADD_NEW_EMPLOYER_MODAL';
export const SET_TOOLS_EMPLOYERS_CHECKBOX_DATA = 'tools/SET_TOOLS_EMPLOYERS_CHECKBOX_DATA';
export const SET_TOOLS_ALL_COMPANIES_DATA = 'tools/SET_TOOLS_ALL_COMPANIES_DATA';
export const CLOSE_DELETE_EMPLOYERS_MODAL = 'tools/CLOSE_DELETE_EMPLOYERS_MODAL';
export const SHOW_DELETE_EMPLOYERS_MODAL = 'tools/SHOW_DELETE_EMPLOYERS_MODAL';
export const SET_TOOLS_EMPLOYERS_DATA = 'tools/SET_TOOLS_EMPLOYERS_DATA';
export const CLOSE_EMPLOYER_OVERVIEW = 'tools/CLOSE_EMPLOYER_OVERVIEW';
export const SHOW_EMPLOYER_OVERVIEW = 'tools/SHOW_EMPLOYER_OVERVIEW';
