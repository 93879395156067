import { useReducer } from 'react';
import { UserStatuses } from '../enums/UserStatuses';

const ACTIONS = {
  TOGGLE_VALUE: 'TOGGLE_VALUE',
  SET_VALUE: 'SET_VALUE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_VALUE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case ACTIONS.TOGGLE_VALUE:
      const selectedValues = state[action.field];
      return {
        ...state,
        [action.field]: selectedValues.includes(action.value)
          ? selectedValues.filter((value) => value !== action.value)
          : [...selectedValues, action.value],
      };
    default:
      throw new Error();
  }
};

const normalizeDate = (date) => (typeof date === 'string' ? new Date(date) : date);

const normalizeNumbers = (values) => values.map((value) => +value);

export const initTripFilters = (initialFilters = {}, isStringStatuses = false) => {
  const filters = {
    creationTime: null,
    creationTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripStartTime: null,
    tripStartTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripEndTime: null,
    tripEndTimeRange: {
      startDate: null,
      endDate: null,
    },
    avgFeedback: null,
    tripTypes: [],
    search: '',
    statuses: [],
    employers: [],
    teams: [],
    purposes: [],
    ...initialFilters,
  };

  return {
    ...filters,
    creationTimeRange: {
      startDate: normalizeDate(filters.creationTimeRange.startDate),
      endDate: normalizeDate(filters.creationTimeRange.endDate),
    },
    tripStartTimeRange: {
      startDate: normalizeDate(filters.tripStartTimeRange.startDate),
      endDate: normalizeDate(filters.tripStartTimeRange.endDate),
    },
    tripEndTimeRange: {
      startDate: normalizeDate(filters.tripEndTimeRange.startDate),
      endDate: normalizeDate(filters.tripEndTimeRange.endDate),
    },
    statuses: isStringStatuses ? filters.statuses : normalizeNumbers(filters.statuses),
    employers: normalizeNumbers(filters.employers),
    teams: normalizeNumbers(filters.teams),
    search: decodeURIComponent(filters.search),
  };
};

export const initReportFilters = (initialFilters = {}) => {
  const filters = {
    paymentDate: null,
    paymentDateRange: {
      startDate: null,
      endDate: null,
    },
    tripStartTime: null,
    tripStartTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripEndTime: null,
    tripEndTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripTypes: [],
    search: '',
    employers: [],
    purposes: [],
    teams: [],
    ...initialFilters,
  };

  return {
    ...filters,
    paymentDateRange: {
      startDate: normalizeDate(filters.paymentDateRange.startDate),
      endDate: normalizeDate(filters.paymentDateRange.endDate),
    },
    tripStartTimeRange: {
      startDate: normalizeDate(filters.tripStartTimeRange.startDate),
      endDate: normalizeDate(filters.tripStartTimeRange.endDate),
    },
    tripEndTimeRange: {
      startDate: normalizeDate(filters.tripEndTimeRange.startDate),
      endDate: normalizeDate(filters.tripEndTimeRange.endDate),
    },
    employers: normalizeNumbers(filters.employers),
    purposes: normalizeNumbers(filters.purposes),
    teams: normalizeNumbers(filters.teams),
  };
};

export const initExpensesFilters = (initialFilters = {}) => {
  const filters = {
    creationTime: null,
    creationTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripStartTime: null,
    tripStartTimeRange: {
      startDate: null,
      endDate: null,
    },
    tripEndTime: null,
    tripEndTimeRange: {
      startDate: null,
      endDate: null,
    },
    search: '',
    statuses: [],
    teams: [],
    ...initialFilters,
  };

  return {
    ...filters,
    creationTimeRange: {
      startDate: normalizeDate(filters.creationTimeRange.startDate),
      endDate: normalizeDate(filters.creationTimeRange.endDate),
    },
    tripStartTimeRange: {
      startDate: normalizeDate(filters.tripStartTimeRange.startDate),
      endDate: normalizeDate(filters.tripStartTimeRange.endDate),
    },
    tripEndTimeRange: {
      startDate: normalizeDate(filters.tripEndTimeRange.startDate),
      endDate: normalizeDate(filters.tripEndTimeRange.endDate),
    },
    statuses: filters.statuses,
    teams: normalizeNumbers(filters.teams),
    search: decodeURIComponent(filters.search),
  };
};

export const initUsersFilters = (initialFilters = {}) => {
  const filters = {
    employers: [],
    status: UserStatuses.Registered,
    search: '',
    roles: [],
    teams: [],
    businessVerticals: [],
    ...initialFilters,
  };

  return {
    ...filters,
    employers: normalizeNumbers(filters.employers),
    search: decodeURIComponent(filters.search),
    roles: normalizeNumbers(filters.roles),
    teams: normalizeNumbers(filters.teams),
  };
};

export const initPaymentsFilters = (initialFilters = {}) => {
  const filters = {
    paymentTypes: [],
    payerType: null,
    statuses: [],
    search: '',
    ...initialFilters,
  };

  return {
    ...filters,
    search: decodeURIComponent(filters.search),
  };
};

export const initToolsFilters = (initialFilters = {}) => {
  const filters = {
    search: '',
    ...initialFilters,
  };

  return {
    ...filters,
    search: decodeURIComponent(filters.search),
  };
};

export const initDashboardFilters = (initialFilters = {}) => {
  const filters = {
    search: '',
    teams: [],
    employers: [],
    purposes: [],
    period: null,
    periodRange: {
      startDate: null,
      endDate: null,
    },
    ...initialFilters,
  };

  return {
    ...filters,
    periodRange: {
      startDate: normalizeDate(filters.periodRange.startDate),
      endDate: normalizeDate(filters.periodRange.endDate),
    },
    teams: normalizeNumbers(filters.teams),
    employers: normalizeNumbers(filters.employers),
    purposes: normalizeNumbers(filters.purposes),
    search: decodeURIComponent(filters.search),
  };
};

export const useFilters = (initialFilters = {}, init) => {
  const [filters, dispatch] = useReducer(reducer, initialFilters, init);

  const filtersCount = Object.entries(filters).reduce((acc, [_, value]: any) => {
    if (value?.length) acc += 1;
    return acc;
  }, 0);

  return {
    filtersCount,
    filters,
    toggleFilter: (field, value) => dispatch({ type: ACTIONS.TOGGLE_VALUE, field, value }),
    setFilter: (field, value) => dispatch({ type: ACTIONS.SET_VALUE, field, value }),
  };
};
