import styled from 'styled-components';

export const OverviewWrapper = styled.div``;

export const OverviewContentWrapper = styled.div``;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const TripActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  button {
    width: calc(100% / 2 - 2.5px);
  }
`;

export const FeedbackLabel = styled.h5`
  color: #181b1c;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
`;

export const FeedbackEmptyLabel = styled.h5`
  color: #181b1c;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 16px;
`;

export const OverviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
  gap: 5px;
`;

export const OverviewContentName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: calc(45% - 2.5px);
`;

export const OverviewContentValue = styled.span`
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  text-align: start;
  width: calc(55% - 2.5px);

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}
`;

export const PurposeWrapper = styled.span`
  flex-shrink: 1;
  max-width: ${({ withEditIcon }) => withEditIcon ? 'calc(100% - 36px)' : '100%'};
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;

  span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
`;

export const EditableText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  
  > * {
    flex-shrink: 0;
  }
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const NoFeedbackBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BlockSubTitle = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  font-weight: 700;
`;

export const UserNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const UserNameText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ withLabel }) => withLabel && `
    max-width: calc(100% - 36px);
  `}
`;
