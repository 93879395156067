import { IEditAccommodationDialog } from './types';
import InfoBlock from '../../InfoBlock';
import Dialog from '../../Dialog';
import ExternalAccommodation from '../../AddExternalTripModalContent/forms/ExternalAccommodation';
import { DialogContent } from '../styles';
import { formatAccommodationDataForForm } from './utils';

const EditAccommodationDialog: React.FC<IEditAccommodationDialog> = ({
  editedAccommodation,
  isLoading,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog
      withFooter={false}
      width={600}
      title={`[${editedAccommodation.reference}] Edit accommodation`}
      onClose={onClose}
    >
      <DialogContent>
        <InfoBlock text={`
            After a manual update, the traveler will be notified,
            and TTR and calendar events will be updated automatically. 
          `}
        />
        <ExternalAccommodation
          isEditMode
          accommodationData={formatAccommodationDataForForm(editedAccommodation)}
          handleClose={onClose}
          handleSubmit={onSubmit}
          isLoading={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditAccommodationDialog;
