import styled from 'styled-components';

export const TogglerWithCountStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background: rgba(253, 239, 196, 0.38);
  }

  ${({ isActive }) =>
    isActive &&
    `
    background: rgba(253, 239, 196, 0.38);
  `}
`;

export const PageFiltersCount = styled.span`
  position: absolute;
  top: 0;
  right: -5px;
  width: 16px;
  height: 16px;
  background: #fce9b0;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-size: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
`;
