import Yup from '../../utils/validation';

const validateMessages = {
  cityId: {
    required: 'City must be provided',
  },
  hotelId: {
    required: 'Hotel must be provided',
  },
  endDate: {
    afterStartDate: 'End date must be after start date',
  },
  companyId: {
    required: 'Company must be provided',
  },
  purposesIds: {
    required: 'Hotel recommendation must have at least one trip purpose',
  },
  teamsIds: {
    required: 'Hotel recommendation must have at least one company team',
  },
};

export const recommendedHotelSchema = Yup.object().shape({
  applyToAllPurposes: Yup.bool(),
  applyToAllTeams: Yup.bool(),
  applyToAllDates: Yup.bool(),
  cityId: Yup
    .number()
    .required(validateMessages.cityId.required)
    .typeError(validateMessages.cityId.required),
  hotelId: Yup
    .string()
    .required(validateMessages.hotelId.required)
    .typeError(validateMessages.hotelId.required),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable().test('is-after-start-date', validateMessages.endDate.afterStartDate, (value, ctx) => {
    if (!value) return true;

    if (ctx.parent.startDate) {
      return new Date(value).getTime() > new Date(ctx.parent.startDate).getTime()
    } 
    return true;
  }),
  companyId: Yup
    .number()
    .required(validateMessages.companyId.required)
    .typeError(validateMessages.companyId.required),
  purposesIds: Yup.array().of(Yup.number()).test(
    'is-at-least-one-purpose',
    validateMessages.purposesIds.required,
    (value, ctx) => (
      (!ctx.parent.applyToAllPurposes && !!value.length)
      || ctx.parent.applyToAllPurposes
    ),
  ),
  teamsIds: Yup.array().of(Yup.number()).test(
    'is-at-least-one-team',
    validateMessages.teamsIds.required,
    (value, ctx) => (
      (!ctx.parent.applyToAllTeams && !!value.length)
      || ctx.parent.applyToAllTeams
    ),
  ),
  comment: Yup.string().nullable(),
});
