export const SET_UPDATE_OVERVIEW_DETAILS = 'users/SET_UPDATE_OVERVIEW_DETAILS';
export const SET_USERS_OPTIONS_DATA = 'users/SET_USERS_OPTIONS_DATA';
export const SET_LOADING_USERS_OPTIONS_DATA = 'users/SET_LOADING_USERS_OPTIONS_DATA';
export const CLOSE_DELETE_USER_MODAL = 'users/CLOSE_DELETE_USER_MODAL';
export const OPEN_DELETE_USER_MODAL = 'users/OPEN_DELETE_USER_MODAL';
export const OPEN_DELETE_UNEMPLOYED_USER_MODAL = 'users/OPEN_DELETE_UNEMPLOYED_USER_MODAL';
export const SET_UPDATE_USER_ROLE = 'users/SET_UPDATE_USER_ROLE';
export const CLOSE_USER_OVERVIEW = 'users/CLOSE_USER_OVERVIEW';
export const SHOW_USER_OVERVIEW = 'users/SHOW_USER_OVERVIEW';
export const SET_USERS_DATA = 'users/SET_USERS_DATA';
export const TOGGLE_FILTERS = 'users/TOGGLE_FILTERS';
export const CLOSE_FILTERS = 'users/CLOSE_FILTERS';
export const DELETE_USER = 'users/DELETE_USER';
export const SET_EMPLOYEES_FOR_DELETION_DATA = 'users/SET_EMPLOYEES_FOR_DELETION_DATA';
export const CLOSE_DELETE_UNEMPLOYED_USER_MODAL = 'users/CLOSE_DELETE_UNEMPLOYED_USER_MODAL';
export const OPEN_UNEMPLOYED_USERS_LIST = 'users/OPEN_UNEMPLOYED_USERS_LIST';
export const CLOSE_UNEMPLOYED_USERS_LIST = 'users/CLOSE_UNEMPLOYED_USERS_LIST';
export const SET_ANONYMIZE_USER = 'users/SET_ANONYMIZE_USER';
