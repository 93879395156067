import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Spinner } from '../Spinner';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const Routes = ({
  isAuthenticated,
  shallBeRedirectedTo,
  isAppReady,
  isCurrentUserLoaded,
  isCurrentUserLoading,
  setAppShallBeRedirectedUrl,
  setBeforeLoginUrl,
  beforeLoginUrl,
  setLoginEmail,
}) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (
      !isAuthenticated &&
      !beforeLoginUrl &&
      pathname !== '/signin' &&
      pathname !== '/login' &&
      pathname !== '/logout' &&
      pathname !== '/forgot-password'
    ) {
      setBeforeLoginUrl(pathname);
      if (search) {
        const { email } = queryString.parse(search);
        if (email) {
          setLoginEmail(email);
        }
      }
    }
  });

  useEffect(() => {
    if (shallBeRedirectedTo) {
      setAppShallBeRedirectedUrl(null);
    }
  }, [shallBeRedirectedTo]);

  if (shallBeRedirectedTo) {
    return <Redirect push to={shallBeRedirectedTo} />;
  }

  if (!isAppReady || (isAuthenticated && isCurrentUserLoading)) {
    return <Spinner />;
  }

  if (isAuthenticated && !isCurrentUserLoaded) {
    return <Spinner />;
  }

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

Routes.propTypes = {
  setAppShallBeRedirectedUrl: PropTypes.func.isRequired,
  setBeforeLoginUrl: PropTypes.func.isRequired,
  setLoginEmail: PropTypes.func.isRequired,
};

export default Routes;
