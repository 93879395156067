import { CryptoTransactionStatus } from '../../enums/CryptoTransaction';
import { Payment } from '../../enums/Payment';
import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';

export const isCryptoProcessingStatus = (transactionStatus) => {
  return [
    CryptoTransactionStatus.FULL,
    CryptoTransactionStatus.GREATER_AMOUNT,
    CryptoTransactionStatus.PROCESSING,
  ].includes(transactionStatus);
};

export const isUserMakeDeposit = (payments: Payment[]) => {
  return payments.some(payment => (
    payment?.status === PAYMENT_STATUS.SUCCESS || (
      payment.paymentType === PAYMENT_TYPE.CRYPTO
      && isCryptoProcessingStatus(payment.externalStatus)
  )));
};
