import React from 'react';
import { TextInputGroup } from '@heathmont/moon-select';
import PhoneInput from 'react-phone-number-input';
import { TextInput } from '@heathmont/moon-core';
import FormField from '../../FormField';
import { IProfileSettingsTab } from './types';
import { FieldWrapper, PhoneInputWrapper } from './styles';
import { DoubleFieldWrapper } from '../styles';
import { ErrorMessage, ErrorMessageWrapper } from '../../../pages/SignUp/styles';
import 'react-phone-number-input/style.css';
import { changeFieldId } from '../../../pages/SignUp/suggestions';

const ProfileSettingsTab: React.FC<IProfileSettingsTab> = ({
  options,
  touched,
  values,
  errors,
  setFieldValue,
  handleBlur,
}) => {
  const { firstname, lastname, email, passengerNumber, birthDate, gender, title } = values.settings;

  const isErrorFirstName = !!errors?.settings?.firstname && touched?.firstname;
  const isErrorLastName = !!errors?.settings?.lastname && touched?.lastname;
  const isErrorEmail = !!errors?.settings?.email && touched?.email;
  const isErrorPhone = !!errors?.settings?.passengerNumber && touched?.passengerNumber;
  const isErrorBirthday = !!errors?.settings?.birthDate && touched?.birthDate;
  const isErrorGender = !!errors?.settings?.gender && touched?.gender;
  const isErrorTitle = !!errors?.settings?.title && touched?.title;

  const firstNameField = {
    inputSize: 'xlarge',
    isError: isErrorFirstName,
    value: firstname,
    label: 'First name',
    type: 'text',
    id: 'firstname',
    onChange: ({ target: { value } }) => setFieldValue('settings.firstname', value),
    onBlur: handleBlur,
  };

  const lastNameField = {
    inputSize: 'xlarge',
    isError: isErrorLastName,
    value: lastname,
    label: 'Last name',
    type: 'text',
    id: 'lastname',
    onChange: ({ target: { value } }) => setFieldValue('settings.lastname', value),
    onBlur: handleBlur,
  };

  const genderField = {
    placeholderSlot: 'Select gender',
    menuPlacement: 'top',
    maxMenuHeight: 170,
    inputSize: 'xlarge',
    isDisabled: options?.gender?.isEditable,
    options: options?.gender?.options ?? [],
    isError: !!errors?.settings?.gender && touched?.gender,
    label: 'Gender',
    value: (options?.gender?.options ?? []).find((item) => +item.value === gender),
    type: 'select',
    onChange: ({ value }) => setFieldValue('settings.gender', +value),
    onBlur: handleBlur,
    ref: (ref) => ref && changeFieldId(ref, 'gender'),
  };

  const titleField = {
    placeholderSlot: 'Select title',
    menuPlacement: 'top',
    maxMenuHeight: 170,
    isDisabled: options?.title?.isEditable,
    inputSize: 'xlarge',
    options: options?.title?.options ?? [],
    isError: !!errors?.settings?.title && touched?.title,
    value: (options.title?.options ?? []).find((item) => +item.value === title),
    label: 'Title',
    type: 'select',
    onChange: ({ value }) => setFieldValue('settings.title', +value),
    onBlur: handleBlur,
    ref: (ref) => ref && changeFieldId(ref, 'title'),
  };

  return (
    <>
      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          inputProps={{
            input1: firstNameField,
            input2: lastNameField,
          }}
        />

        {(isErrorFirstName || isErrorLastName) && (
          <ErrorMessageWrapper>
            <ErrorMessage>{isErrorFirstName && errors?.settings?.firstname}</ErrorMessage>

            <ErrorMessage>{isErrorLastName && errors?.settings?.lastname}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>

      <div>
        <FormField
          value={email}
          fieldId="email"
          fieldName="email"
          component={TextInput}
          type="email"
          inputSize="xlarge"
          onChange={({ target: { value } }) => setFieldValue('settings.email', value)}
          errorPosition={-20}
          label="E-mail address"
          onBlur={handleBlur}
          isError={isErrorEmail}
          disabled={options?.passengerEmail?.isEditable}
        />

        {isErrorEmail && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.settings?.email}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </div>

      <div>
        <PhoneInputWrapper isError={isErrorPhone}>
          <FormField
            defaultCountry="EE"
            international
            placeholder="Passenger phone number"
            fieldName="passengerNumber"
            component={PhoneInput}
            disabled={passengerNumber?.isEditable}
            fieldId="passengerNumber"
            value={passengerNumber}
            onChange={(value) => {
              value ? setFieldValue('settings.passengerNumber', value) : setFieldValue('settings.passengerNumber', '');
            }}
            onBlur={handleBlur}
          />
        </PhoneInputWrapper>

        {isErrorPhone && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.settings?.passengerNumber}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </div>

      <FieldWrapper>
        <FormField
          fieldId="birthDate"
          fieldName="birthDate"
          value={birthDate}
          component={TextInput}
          type="date"
          inputSize="xlarge"
          onChange={({ target: { value } }) => setFieldValue('settings.birthDate', value)}
          label="Birth date"
          dateFormat="dd MMM yyyy"
          onBlur={handleBlur}
          isError={isErrorBirthday}
        />

        {isErrorBirthday && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.settings?.birthDate}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          type="text"
          inputProps={{
            input1: genderField,
            input2: titleField,
          }}
        />

        {(isErrorGender || isErrorTitle) && (
          <ErrorMessageWrapper>
            <ErrorMessage>{isErrorGender && errors?.settings?.gender}</ErrorMessage>

            <ErrorMessage>{isErrorTitle && errors?.settings?.title}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </>
  );
};

export default ProfileSettingsTab;
