import moment from 'moment';
import {
  ACCOMMODATION_PROVIDERS,
  FLIGHTS_PROVIDERS,
} from '../../constants';

export const formatDate = (date) => {
  return moment(date).format('DD MMM YYYY HH:mm');
};

export const extractAirlinesAndAirportsFromFlightBooking = (flightId, flights) => {
  const foundFlight = flights.find((flight) => flight.id === flightId);

  if (foundFlight) {
    const segments = foundFlight?.itineraries.length > 1
      ? foundFlight?.itineraries.reduce((acc, itinerary) => {
          acc.push(...itinerary.segments);
          return acc;
        }, [])
      : foundFlight.itineraries[0].segments;
  
    const selectedAirports = segments.reduce((acc: any[],segment) => {
      const tempAirports = [...acc];
    
      if (!tempAirports.find((airport) => airport.value === segment.departure.code)) {
        const {title,code} = segment.departure;
        tempAirports.push({
          value: code,
          title,
          city: segment.departure.location.city
        });
      }
      if (!tempAirports.find((airport) => airport.value === segment.arrival.code)) {
        const {title,code} = segment.arrival;
        tempAirports.push({
          value: code,
          title,
          city: segment.arrival.location.city
        });
      }
      return tempAirports;
    },[]);
  
    const selectedAirlines = segments.reduce((acc,segment) => {
      const tempAirlines = [...acc];
      if (!acc.find((airline) => airline.value === segment.airline.id)) {
        const {id, title, code} = segment.airline;
        tempAirlines.push({
          value: id,
          title,
          code
        });
      }
      return tempAirlines;
    },[]);

    return { airlines: selectedAirlines, airports: selectedAirports };
  }

  return { airlines: [], airports: [] };
};

export const isAccommodationExternal = (accommodation) => {
  return accommodation?.provider?.keyword === ACCOMMODATION_PROVIDERS.EXTERNAL;
};

export const isFlightExternal = (flight) => {
  return flight?.provider?.keyword === FLIGHTS_PROVIDERS.EXTERNAL;
};
