import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  width: 624px;
  max-height: 745px;
  height: 100%;
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100vw;
    height: calc(100vh - 100px);
    top: -40px;
  `}

  & form {
    height: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  padding: 0 40px;
`;

export const Header = styled(Text).attrs({
  size: 32,
  color: 'bulma.100',
})`
  line-height: 40px;
  padding: 0 40px;
  margin: 24px 0;
`;

export const Container = styled.div`
  padding: 0 40px;
  box-sizing: border-box;
  max-height: ${({ maxHeight }) => (maxHeight ? 'calc(100% - 280px)' : 'calc(100% - 240px)')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Footer = styled.div`
  border-top: 1px solid #e0e0e0;
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  background-color: #ffffff;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  max-height: 40px;

  ${({ isVerticalView }) =>
    isVerticalView &&
    `
    flex-direction: column;
  `}
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff0000;
  padding: 8px 5px 0 0;
  margin-top: -10px;
`;
