import { Helmet } from 'react-helmet';
import LoginForm from '../../components/LoginFormNew';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBg';
import useIsMobile from '../../hooks/useIsMobile';

const Login = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      {isMobile ? (
        <LoginForm isMobile={isMobile} />
      ) : (
        <FullScreenRandomBg>
          <LoginForm isMobile={isMobile} />
        </FullScreenRandomBg>
      )}
    </>
  );
};

export default Login;
