import { Button as MoonButton } from '@heathmont/moon-components';
import { Wrapper } from './styles';
import { IButton } from './types';

const Button: React.FC<IButton> = ({
  isDisabled,
  progress,
  variant = 'default',
  label,
  style,
  size = 'small',
  handleClick = () => {},
  ...props
}) => {
  return (
    <Wrapper>
      <MoonButton
        progress={progress}
        variant={variant}
        size={size}
        onClick={handleClick}
        disabled={isDisabled}
        style={style}
        {...props}
      >
        {label}
      </MoonButton>
    </Wrapper>
  );
};

export default Button;
