import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'roleId',
    minWidth: 180,
    disableSortBy: false,
  },
  {
    Header: 'Role',
    accessor: 'roleName',
    minWidth: 180,
    disableSortBy: false,
  },
  {
    Header: 'Pages',
    accessor: 'pages',
    disableSortBy: true,
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Amount',
    accessor: 'rolesAmount',
    disableSortBy: false,
    minWidth: 180,
  },
];
