import { Passenger } from '../../enums/Passenger';
import { Baggage } from '../../enums/Baggage';

export const extractExtraBags = (segmentId: number, passengers: Passenger[]): Baggage[] => {
  const extraBags: Baggage[] = [];

  passengers.forEach(passenger => {
    const additionalServicesPerSegment = passenger.additionalServices.filter(additionalService => (
      additionalService.segmentId === segmentId
    ));

    additionalServicesPerSegment.forEach(additionalService => {
      const extraBag = extraBags.find(extraBag => (
        extraBag.weight === additionalService.weight
        && extraBag.weightUnit === additionalService.weightUnit
      ));

      if (extraBag) {
        extraBag.quantity = extraBag.quantity + additionalService.quantity;
      } else {
        extraBags.push({ 
          weightUnit: additionalService.weightUnit,
          quantity: additionalService.quantity,
          weight: additionalService.weight,
        });
      }
    });
  });

  return extraBags;
};
