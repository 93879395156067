import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { SoftwareSorting } from '@heathmont/moon-icons';
import Helmet from 'react-helmet';
import { Text } from '@heathmont/moon-core';
import { initPaymentsFilters, useFilters } from '../../hooks/useFilters';
import { useCustomTable } from '../../hooks/useCustomTable';
import { useQuery } from '../../hooks/useQuery';
import { getOrderNumber, getPaymentStatusLabel, paymentsDataMapper } from '../../utils/tableHelpers';
import { prepareFilters } from '../../utils/filters';
import { usePageTitle } from '../../models/page';
import TableContentReciever from '../../components/TableContentReciever';
import { FiltersReciever } from '../../components/FiltersReciever';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import TablePagination from '../../components/TablePagination';
import { Spinner } from '../../components/Spinner';
import Drawer from '../../components/Drawer';
import { DEFAULT_SORTING, LIMIT_PER_PAGE } from '../../constants';
import { RowHeight } from '../Reports/suggestions';
import PaymentDrawerContent from './PaymentDrawerContent';
import { FilterHeaderWrapper } from '../../styled';
import { getPaymentsFilters, initialColumns } from './suggestions';
import { Wrapper, TableWrapper, PageHeader } from './styles';
import { IPayments } from './types';

const Payments: React.FC<IPayments> = ({
  currentUserData,
  isOpenOverview,
  isFiltersOpen,
  isLoadingData,
  overviewData,
  paymentsData,
  options,
  total,
  closePaymentOverview,
  showPaymentOverview,
  updatePayerDetails,
  syncPaymentStatus,
  setInvoiceNumber,
  fetchPayments,
  toggleFilters,
  closeFilters,
  fetchOptions,
}) => {
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.PAYMENTS);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { getQuery, setQuery } = useQuery();
  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(getQuery(), initPaymentsFilters);
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable([], LIMIT_PER_PAGE.PAYMENTS);

  const setQueryParams = () => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    setQuery(params.filters);

    return params;
  };

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchPayments(params);
    closePaymentOverview();
  }, [filters, page, sortBy]);


  useEffect(() => {
    fetchOptions();
    return () => {
      closePaymentOverview();
      closeFilters();
    };
  }, []);

  const columns = useMemo(() => initialColumns, []);
  const paymentsGroupedData = useMemo(() => paymentsDataMapper(paymentsData), [paymentsData]);
  const queryParams = useRef('');
  const totalItems = useRef(0);
  const pageTitle = usePageTitle('html.payments.title');

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const setFiltersAndToFirstPage = (field: string, value: any) => {
    setPage(1);
    setFilter(field, value);
  };

  const toggleFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    toggleFilter(field, value);
  };

  const filterComponents = useMemo(() => {
    return getPaymentsFilters(filters, options, toggleFiltersAndToFirstPage, setFiltersAndToFirstPage);
  }, [filters, options, toggleFiltersAndToFirstPage]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const handleIsOverviewOpen = async (row?: any) => {
    showPaymentOverview(paymentsData[row?.id]);
  };

  useMemo(() => {
    totalItems.current = total;
  }, [total]);
    
  const tableContentRecieverProps = {
    isShowTableFilters,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.PAYMENTS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: paymentsGroupedData,
    handleIsOverviewOpen,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper>
        {isFiltersOpen && (
          <FiltersReciever
            filterComponents={filterComponents}
            isOpenDatePicker={false}
            placeholder="Search for invoice, reference and more"
            filters={filters}
            setFilter={setFiltersAndToFirstPage}
          />
        )}

        <TableWrapper>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />
              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Payments
              </Text>
            </FilterHeaderWrapper>
          </PageHeader>

          <TablePagination
            isShowTableFilters={isShowTableFilters}
            limitPerPage={LIMIT_PER_PAGE.PAYMENTS}
            totalItems={totalItems}
            data={paymentsData}
            page={page}
            toggleTableFilters={toggleTableFilters}
            changeRowHeight={changeRowHeight}
            refetchData={() => fetchPayments(params)}
            nextPage={nextPage}
            prevPage={prevPage}
          />
          {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
        </TableWrapper>

        {isOpenOverview && (
          <div>
            <Drawer
              withStatus
              status={overviewData?.status as any}
              title={getOrderNumber(overviewData?.id)}
              getStatusLabel={getPaymentStatusLabel}
              handleClose={closePaymentOverview}
            >
              <PaymentDrawerContent
                currentUserData={currentUserData}
                payment={overviewData}
                updatePayerDetails={updatePayerDetails}
                syncPaymentStatus={syncPaymentStatus}
                setInvoiceNumber={setInvoiceNumber}
              />
            </Drawer>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Payments;
