import { ITooltip } from './types';
import { TooltipWrapper, Date, Item, ColorPreview, TooltipContent } from './styles';
import LineChartTooltipContent from './LineChartTooltipContent';
import TabChartTooltipContent from './TabChartTooltipContent';

export const Tooltip: React.FC<ITooltip> = ({ payload, active, isLineChart, isBarChart }) => {
  if (!active || !payload) {
    return null;
  }

  const date = payload[0] && payload[0].payload.tooltip.date;

  return (
    <TooltipWrapper>
      {date && <Date>{date}</Date>}

      {isLineChart && <LineChartTooltipContent payload={payload} />}

      {isBarChart && <TabChartTooltipContent payload={payload} />}
    </TooltipWrapper>
  );
};
