import { GenericAlarmRound } from '@heathmont/moon-icons-tw';
import { GenericDelete } from '@heathmont/moon-icons';
import {
  InfoBlockMultiItemsWrapper,
  InfoBlockWrapper,
  RoleLabelWrapper,
  OverviewWrapper,
  ContentWrapper,
  WarningWrapper,
  InfoBlockText,
  UpcomingItem,
  IconWrapper,
  RoleWrapper,
  DeleteIcon,
  InfoBlock,
  IconBlock,
  Reasons,
  Title,
} from './styles';
import { getUsersRoleLabel } from '../../utils/tableHelpers';
import Avatar from '../Avatar';
import { IUnemployedUsersOverview } from './types';
import { formatPreventDeletionReason, getUserFullName } from './utils';
import { PreventDeletionReason } from '../../enums/PreventDeletionReason';

const UnemployedUsersOverview: React.FC<IUnemployedUsersOverview> = ({ data, onClick }) => {
  const renderItemDetails = (item) => {
    const { data, reasons } = item;
    const reasonsToPreventDeletion = (reasons ? Object.entries(reasons) : null) as PreventDeletionReason[];
    return (
      <>
        <InfoBlock>
          <Title>
            <RoleWrapper>{data?.fullName}</RoleWrapper> — <RoleLabelWrapper>{getUsersRoleLabel(data?.role)}</RoleLabelWrapper>
          </Title>
        </InfoBlock>
        {reasonsToPreventDeletion ? (
          <Reasons>
            {reasonsToPreventDeletion.map((reason, i) => (
              <li key={i}>
                {formatPreventDeletionReason(reason)}{Array.isArray(reason[1]) && <p>{reason[1].join(', ')}</p>}
              </li>
            ))}
          </Reasons>
        ) : (
          <WarningWrapper>
            <IconWrapper>
              <GenericAlarmRound />
            </IconWrapper>
            <InfoBlockText>
              No reasons preventing the user from being deleted. You can delete the user manually, or the user will be automatically deleted during the next synchronization with Hibob.
            </InfoBlockText>
          </WarningWrapper>
        )}
      </>
    );
  };
  
  return (
    <OverviewWrapper>
      {data.map(item => (
        <UpcomingItem key={item?.data?.id}>
          <ContentWrapper onClick={() => onClick(item)}>
            <IconBlock>
              <Avatar
                size="small"
                name={getUserFullName(item?.data)}
                color="hit.100"
                avatarStyle={{ borderRadius: '8px' }}
                backgroundColor="piccolo.100"
              />
            </IconBlock>
            <InfoBlockWrapper>
              <InfoBlockMultiItemsWrapper>
                {renderItemDetails(item)}
                <DeleteIcon>
                  <GenericDelete />
                </DeleteIcon>
              </InfoBlockMultiItemsWrapper>
            </InfoBlockWrapper>
          </ContentWrapper>
        </UpcomingItem>
      ))}
    </OverviewWrapper>
  );
};

export default UnemployedUsersOverview;
