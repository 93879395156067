import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const DashboardContent = styled.div`
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow: auto;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const IconsWrapper = styled.div``;

export const IconWrapper = styled.span`
  width: 38px;
  height: 38px;
  background: ${({ isActive }) => isActive && 'rgba(253, 239, 196, 0.38)'};
  border-radius: 8px;
  margin: 16px 0px;
  padding: 8px;

  cursor: pointer;

  &:hover {
    background-color: rgba(253, 239, 196, 0.38);
  }

  ${({ isOpen }) =>
    isOpen &&
    `
      background-color: rgba(253, 239, 196, 0.38)
   `}
`;

export const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
`;
