import { AdditionalServiceType } from '../../../enums/AdditionalServiceType';
import { FLIGHT_TYPE } from '../../../enums/FlightType';

export const isFlightHasNotAppliedLoyaltyProgram = (flight) => {
  return flight.loyaltyPrograms.some(loyaltyProgram => !loyaltyProgram.isApplied);
};

export const getNotAppliedLoyaltyPrograms = (flight) => {
  return flight.loyaltyPrograms
    .filter(loyaltyProgram => !loyaltyProgram.isApplied)
    .map(loyaltyProgram => loyaltyProgram.id);
};

export const getFlightLabel = (index: number, flightType: FLIGHT_TYPE): string => {
  if (flightType === FLIGHT_TYPE.ROUND || flightType === FLIGHT_TYPE.ONE_WAY) {
    return index === 0 ? 'Outbound flight' : 'Return flight';
  }
  return `Flight ${index + 1}`;
};

export const formatPrice = (price: number): string => {
  const [natural, decimal] = String(Math.round(price * 100) / 100).split('.');

  const decimalFormatted = decimal?.padEnd(2, '0');

  return `${natural}.${decimalFormatted ?? '00'}`;
};

export const getFlightCost = (totalCost, additionalServicesCost) => {
  const flightCost = +totalCost - +additionalServicesCost;
  return formatPrice(flightCost);
};

export const getFlightAdditionalServicesCost = (flight) => {
  try {
    const priceMetaParsed = JSON.parse(flight?.priceMeta);
    if (priceMetaParsed?.additionalServices?.length) {
      const additionalServicesCost = priceMetaParsed.additionalServices.reduce((total, additionalService) => {
        return total + +(additionalService?.amount);
      }, 0);
      return formatPrice(additionalServicesCost);
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const hasExtraBags = (flight) => {
  try {
    const priceMetaParsed = JSON.parse(flight?.priceMeta);
    if (priceMetaParsed?.additionalServices?.length) {
      return priceMetaParsed?.additionalServices.some(additionalService => (
        +additionalService.amount > 0
        && additionalService.type === AdditionalServiceType.CHECKED_BAGS
      ));
    }
    return false;
  } catch (err) {
    return false;
  }
};
