import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'Name',
    accessor: 'label',
    minWidth: 180,
    disableSortBy: false,
  },
  {
    Header: 'Expense report',
    accessor: 'expensesRequired',
    minWidth: 180,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Users',
    accessor: 'users',
    disableSortBy: true,
    minWidth: 180,
  },
];

export const expensesRequireStatuses = [
  { title: 'Yes', value: 1 },
  { title: 'No', value: 0 },
];
