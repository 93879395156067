import React, { useEffect, useState } from 'react';
import { MapsMarker } from '@heathmont/moon-icons';

import maldives from '../../static/bg/maldives.png';
import antelope from '../../static/bg/antelope.png';
import banff from '../../static/bg/banff.png';
import beaufort from '../../static/bg/beaufort.png';
import botswana from '../../static/bg/botswana.png';
import bucana from '../../static/bg/bucana.png';
import hautes from '../../static/bg/hautes.png';
import brienz from '../../static/bg/brienz.png';
import powell from '../../static/bg/powell.png';
import seattle from '../../static/bg/seattle.png';
import { Container, FullScreenBg, HeadingCustom, ImageText } from './styled';
import useIsMobile from '../../hooks/useIsMobile';

const wallpapersArray = [
  { image: maldives, title: 'Gehmiskih Higun, Fuvahmulah, Maldives' },
  { image: brienz, title: 'Lake Brienz, Switzerland' },
  { image: seattle, title: 'Seattle, WA, USA' },
  { image: beaufort, title: 'Beaufort, Savoie, France' },
  { image: banff, title: 'Banff national park, Canada' },
  { image: antelope, title: 'Upper Antelope Canyon, United States' },
  { image: hautes, title: 'Hautes-Pyrénées, France' },
  { image: bucana, title: 'Bucana, El Nido, Palawan, Philippines' },
  { image: powell, title: 'Lake Powell, AZ, USA' },
  { image: botswana, title: 'Botswana, Southern Africa' },
];

const FullScreenRandomBg = ({ renderComponent }) => {
  const [randomWallpaper, setRandomWallpaper] = useState(wallpapersArray[0]);
  const isMobile = useIsMobile();

  const getRandomWallpaper = () => {
    const index = Math.floor(Math.random() * wallpapersArray.length);
    return wallpapersArray[index] || wallpapersArray[0];
  };

  useEffect(() => {
    setRandomWallpaper(getRandomWallpaper());
  }, []);

  return (
    <FullScreenBg isMobile={isMobile} image={randomWallpaper.image}>
      <Container isMobile={isMobile}>
        {renderComponent}
        <HeadingCustom isMobile={isMobile}>Your next chapter, made possible by traveling</HeadingCustom>
      </Container>

      {!isMobile && (
        <ImageText>
          <MapsMarker fontSize="20.8px" color="goku.100" />
          {randomWallpaper.title}
        </ImageText>
      )}
    </FullScreenBg>
  );
};

export default FullScreenRandomBg;
