import { fetchOptionsForCompany } from '../../../store/tools/businessVerticals/api';
import { fetchCompanyAccountants } from '../../../store/tools/employers/api';

export const onCompanyChangeHandler = async (companyId, newCompanyId, setFieldValue, setBusinessVerticalOptions) => {
  const isCompanyChanged = newCompanyId !== companyId;

  if (isCompanyChanged) {
    const options = await fetchOptionsForCompany(newCompanyId);
    const accountants = await fetchCompanyAccountants(newCompanyId);

    const { defaultTravelopses, defaultAccountants, defaultCeos, travelopses, managers } = options || {};

    setBusinessVerticalOptions({
      defaultTravelopses,
      defaultAccountants,
      defaultCeos,
      travelopses,
      ceos: managers,
      accountants 
    });
    setFieldValue('ceos', defaultCeos.map(({ value }) => value));
    setFieldValue('travelopses', defaultTravelopses.map(({ value }) => value));
    setFieldValue('accountants', defaultAccountants.map(({ value }) => value));
  }
  
  setFieldValue('company', newCompanyId);
};