import { useEffect, useState } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button, Checkbox } from '@heathmont/moon-core';
import { IBusinessVerticalsOverviewDrawerContent } from './types';
import { OverviewContent, OverviewContentName, OverviewContentValue, EditButtonWrapper } from './styles';
import BusinessVerticalOverviewDrawerEditContent from '../BusinessVerticalOverviewDrawerEditContent';
import { getLabels } from '../../utils/tableHelpers';
import { BusinessVerticalType } from '../../enums/BusinessVerticalType';
import { N_A } from '../../utils/commonFunctions';
import InfoBlock from '../InfoBlock';

const BusinessVerticalsOverviewDrawerContent: React.FC<IBusinessVerticalsOverviewDrawerContent> = ({ data }): JSX.Element => {
  const [isEditFormData, setIsEditFormData] = useState(false);
  const [isBecameDefaultBusinessVertical, setBecameDefaultBusinessVertical] = useState(false);
  const { id, travelopses, name, type, ceos, accountants, company, isDefault } = data;

  useEffect(() => {
    setIsEditFormData(false);
  }, [id]);

  return (
    <div>
      {(isDefault || isBecameDefaultBusinessVertical) &&
        <InfoBlock
          text={`Default business vertical will be set to all teams and users without business vertical`}
        />
      }

      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <OverviewContent>
        <OverviewContentName>ID</OverviewContentName>
        <OverviewContentValue>{id}</OverviewContentValue>
      </OverviewContent>

      {!isEditFormData ? (
        <>
          <OverviewContent>
            <OverviewContentName>Business Vertical</OverviewContentName>
            <OverviewContentValue>{name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Company</OverviewContentName>
            <OverviewContentValue>{company?.name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>CEO(s)</OverviewContentName>
            <OverviewContentValue>
              {ceos?.length ? getLabels(ceos, 'fullName') : N_A}
            </OverviewContentValue>
          </OverviewContent>
        
          <OverviewContent>
            <OverviewContentName>Travelopses</OverviewContentName>
            <OverviewContentValue>
              {travelopses?.length ? getLabels(travelopses, 'fullName') : N_A}
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Accountants</OverviewContentName>
            <OverviewContentValue>
              {accountants?.length ? getLabels(accountants, 'fullName') : N_A}
            </OverviewContentValue>
          </OverviewContent>
          
          <OverviewContent>
            <OverviewContentName>Only external users</OverviewContentName>
            <OverviewContentValue>
              <Checkbox checked={data?.type === BusinessVerticalType.External} disabled />
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Default business vertical</OverviewContentName>
            <OverviewContentValue>
              <Checkbox checked={data?.isDefault} disabled />
            </OverviewContentValue>
          </OverviewContent>
        </>
      ) : (
        <BusinessVerticalOverviewDrawerEditContent
          data={data}
          setIsEditFormData={setIsEditFormData}
          setBecameDefaultBusinessVertical={setBecameDefaultBusinessVertical}
        />
      )}
    </div>
  );
};

export default BusinessVerticalsOverviewDrawerContent;
