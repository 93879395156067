import { getNotificationStyles } from '../Notifications/suggestions';
import { INotificationLayout } from './types';
import { Notification, ContentText, Description, Content, Details, Header } from './styles';
import { getItemText, getSearchValue } from './utils';
import useBlockHover from '../../hooks/useBlockHover';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';

const NOTIFICATION_MAX_ITEM = 2;

const NotificationLayout: React.FC<INotificationLayout> = ({
  subTitle,
  title,
  items,
  type,
  handleGoToItem,
  handleShowAll,
  handleMergeTripsDialog,
}) => {
  const [isHovered, setIsHovered] = useBlockHover();

  return (
    !!items.length && (
      <Notification
        notificationStyles={getNotificationStyles(type)}
        onMouseEnter={setIsHovered}
        onMouseLeave={setIsHovered}
      >
        <div>
          <Header>{title}</Header>

          <Content>
            <ContentText>{subTitle}</ContentText>
          </Content>

          <Description>
            {items.slice(0, NOTIFICATION_MAX_ITEM).map((item, i) => (
              <li
                key={i}
                onClick={
                  type === NOTIFICATION_TYPES.TRIPS_FOR_MERGING
                    ? () => handleMergeTripsDialog([item.id, ...item.tripsForMerging.map(({ id }) => id)])
                    : () => handleGoToItem(getSearchValue(item, type))
                }
              >
                <ContentText>{getItemText(item, type)}</ContentText>
              </li>
            ))}
          </Description>
        </div>

        {items.length > NOTIFICATION_MAX_ITEM && isHovered && (
          <Details>
            <ContentText onClick={handleShowAll}>Show all</ContentText>
          </Details>
        )}
      </Notification>
    )
  );
};

export default NotificationLayout;
