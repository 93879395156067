import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { setIsLoadingData } from '../spinner/actions';
import {
  SET_DOWN_DRAWER_TRIP_STATUS_MODAL,
  CLOSE_POSSIBLE_TRIPS_FOR_MERGING,
  OPEN_POSSIBLE_TRIPS_FOR_MERGING,
  CLOSE_UNASSIGNED_ACCOMMODATIONS,
  SET_OVERVIEW_TRIP_STATUS_MODAL,
  OPEN_UNASSIGNED_ACCOMMODATIONS,
  SET_FLIGHT_SELECTED_AIRLINES,
  SET_FLIGHT_SELECTED_AIRPORTS,
  SET_TABLE_TRIP_STATUS_MODAL,
  SET_TRIPS_FOR_MERGING_DATA,
  CLOSE_UNASSIGNED_FLIGHTS,
  SET_TRIPS_CHECKBOX_DATA,
  CLOSE_DELETE_TRIP_MODAL,
  OPEN_UNASSIGNED_FLIGHTS,
  OPEN_DELETE_TRIP_MODAL,
  SET_TRIPS_OPTIONS_DATA,
  DELETE_OTHER_TRANSPORT,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_INVOICE,
  DELETE_ACCOMMODATION,
  CLOSE_TRIP_OVERVIEW,
  OPEN_TRIP_OVERVIEW,
  CLOSE_TRIP_TICKET,
  OPEN_TRIP_TICKET,
  UPDATE_FEEDBACK,
  SET_TRIPS_DATA,
  TOGGLE_FILTERS,
  DELETE_FLIGHT,
  CLOSE_FILTERS,
} from './actionTypes';

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const openTripOverview = (trip) => ({
  type: OPEN_TRIP_OVERVIEW,
  payload: { data: trip },
});

export const closeTripOverview = () => ({ type: CLOSE_TRIP_OVERVIEW });

export const updateFeedback = (tripId, feedbackId, data) => ({
  type: UPDATE_FEEDBACK,
  payload: { tripId, feedbackId, data },
});

export const openTripTicket = (trip) => ({
  type: OPEN_TRIP_TICKET,
  payload: { data: trip },
});

export const openUnassignedFlights = () => ({
  type: OPEN_UNASSIGNED_FLIGHTS,
});
export const closeUnassignedFlights = () => ({
  type: CLOSE_UNASSIGNED_FLIGHTS,
});
export const openUnassignedAccommodations = () => ({
  type: OPEN_UNASSIGNED_ACCOMMODATIONS,
});
export const closeUnassignedAccommodations = () => ({
  type: CLOSE_UNASSIGNED_ACCOMMODATIONS,
});

export const openPossibleTripsForMerging = () => ({
  type: OPEN_POSSIBLE_TRIPS_FOR_MERGING,
});

export const closePossibleTripsForMerging = () => ({
  type: CLOSE_POSSIBLE_TRIPS_FOR_MERGING,
});

export const closeTripTicket = () => ({ type: CLOSE_TRIP_TICKET });

export const fetchTripTicket = (ticketId, callback) => async (dispatch) => {
  const response = await request.get(`/trips/details/${ticketId}`);
  dispatch(callback(response?.data?.trip)); 
};

export const setTripsCheckboxData = (checkboxData, isRemoveAll = false) => {
  return {
    type: SET_TRIPS_CHECKBOX_DATA,
    payload: {
      isRemoveAll,
      data: checkboxData,
    },
  };
};

const setTripsData = (data, total) => ({ type: SET_TRIPS_DATA, payload: { data, total } });

export const fetchTripsData = (params) => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .get('trips', { params })
    .then(({ data }) => dispatch(setTripsData(data.records, data.total)))
    .catch((e) => {
      console.error(e);
    })
    .finally(() => dispatch(setIsLoadingData(false)));
};

const setTripsForMergingData = (data) => ({ type: SET_TRIPS_FOR_MERGING_DATA, payload: { data } });

export const fetchTripsForMergingData = () => async (dispatch) => {
  dispatch(setIsLoadingData(true));

  await request
    .get('trips/trips-for-merging')
    .then(({ data }) => dispatch(setTripsForMergingData(data.records)))
    .catch((e) => {
      console.error(e);
    })
    .finally(() => dispatch(setIsLoadingData(false)));
};

const setOptionsData = (data) => ({ type: SET_TRIPS_OPTIONS_DATA, payload: { data } });

export const fetchOptionsData = () => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .get('trips/options')
    .then(({ data }) => dispatch(setOptionsData(data)))
    .catch((e) => console.error(e))
    .finally(() => dispatch(setIsLoadingData(false)));
};

const setDeleteFlight = (flight) => ({ type: DELETE_FLIGHT, payload: { flight } });

export const deleteFlight = (flight, declineReason: string | null, callback: () => void) => async (dispatch) => {
  dispatch(setIsLoadingData(true));

  try {
    await request.post(`trips/delete-flight/${flight.tripId}/${flight.id}`, {
      declineReason,
    });
  
    getErrorStatusMessage({
      status: 200,
      message: `Flight [${flight.reference}] successfully deleted.`,
    });
  
    dispatch(setDeleteFlight(flight));
    callback();
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `Flight [${flight.reference}] can't be deleted. ${e?.response?.data?.error || 'Unknown error.'}`,
    });
    callback();
  } finally {
    dispatch(setIsLoadingData(false))
  }
};

const setDeleteAccommodation = (accommodation) => ({ type: DELETE_ACCOMMODATION, payload: { accommodation } });

export const deleteAccommodation = (accommodation, declineReason: string | null, callback: () => void) => async (dispatch) => {
  dispatch(setIsLoadingData(true));

  try {
    await request.post(`trips/delete-accommodation/${accommodation.tripId}/${accommodation.id}`, {
      declineReason,
    });
  
    getErrorStatusMessage({
      status: 200,
      message: `Accommodation [${accommodation.reference}] successfully deleted.`,
    });

    dispatch(setDeleteAccommodation(accommodation));
    callback();
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `Accommodation [${accommodation.reference}] can't be deleted. ${
        e?.response?.data?.error || 'Unknown error.'
      }`,
    });
    callback();
  } finally {
    dispatch(setIsLoadingData(false))
  }
};

const setDeleteOtherTransport = (otherTransport) => ({ type: DELETE_OTHER_TRANSPORT, payload: { otherTransport } });

export const deleteOtherTransport = (otherTransport: any, declineReason: string | null, callback: () => void) => async (dispatch) => {
  dispatch(setIsLoadingData(true));

  try {
    await request.post(`trips/delete-other-transport/${otherTransport.tripId}/${otherTransport.id}`, {
      declineReason,
    });
  
    getErrorStatusMessage({
      status: 200,
      message: `Other transport [${otherTransport.reference}] successfully deleted.`,
    });

    dispatch(setDeleteOtherTransport(otherTransport));
    callback();
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `Other transport [${otherTransport.reference}] can't be deleted. ${
        e?.response?.data?.error || 'Unknown error.'
      }`,
    });
    callback();
  } finally {
    dispatch(setIsLoadingData(false))
  }
};

export const openDeleteTripModal = (trips: any) => {
  const deletableTrips = trips?.length ? trips.map((item) => item.tripId) : [trips.tripId];
  return { type: OPEN_DELETE_TRIP_MODAL, payload: { deletableTrips } };
};

export const closeDeleteTripModal = () => {
  return { type: CLOSE_DELETE_TRIP_MODAL };
};

export const setTableTripStatusModal = (status = false, tableKey = null) => ({
  type: SET_TABLE_TRIP_STATUS_MODAL,
  payload: { status, tableKey },
});

export const setOverviewTripStatusModal = (status = false) => ({
  type: SET_OVERVIEW_TRIP_STATUS_MODAL,
  payload: { status },
});

export const setDownDrawerTripStatusModal = (status = false) => ({
  type: SET_DOWN_DRAWER_TRIP_STATUS_MODAL,
  payload: { status },
});

export const setFlightSelectedAirports = (airports) => (dispatch) => {
  dispatch({
    type: SET_FLIGHT_SELECTED_AIRPORTS,
    payload: airports,
  });
};

export const setFlightSelectedAirlines = (airline) => (dispatch) =>  {
  dispatch({
    type: SET_FLIGHT_SELECTED_AIRLINES,
    payload: airline,
  });
};

export const onChangePaymentStatus = ({
  balancedIoInvoiceNumber,
  externalStatus,
  paymentId,
  status,
}: {
  balancedIoInvoiceNumber?: any;
  externalStatus?: any;
  paymentId: string;
  status: PAYMENT_STATUS;
}) => ({
  type: UPDATE_PAYMENT_STATUS,
  payload: {
    balancedIoInvoiceNumber,
    externalStatus,
    paymentId,
    status,
  },
});

export const onChangePaymentInvoice = (paymentId: string, balancedIoInvoiceNumber: string) => ({
  type: UPDATE_PAYMENT_INVOICE,
  payload: { paymentId, balancedIoInvoiceNumber },
});