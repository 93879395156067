import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../Header/Header.ui';
import Footer from '../Footer/Footer';
import { AlertBlock } from '../AlertBlock';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    // @ts-ignore
    const { isAuthenticated, children } = this.props;
    // @ts-ignore
    const { error } = this.state;

    if (error) {
      const { message, stack } = error;

      return (
        <>
          <Helmet>
            <title>OOPS</title>
          </Helmet>
          <Header />
          {/* @ts-ignore */}
          <main>{isAuthenticated && <AlertBlock header={error} message={message} stack={stack} />}</main>
          <Footer />
        </>
      );
    }

    return children;
  }
}

// @ts-ignore
ErrorBoundary.defaultProps = {
  isAuthenticated: false,
};

// @ts-ignore
ErrorBoundary.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default withRouter(ErrorBoundary);
