import { Container, List, ItemWrapper, ColorPreview, Label } from './styles';
import { ILegend } from './types';

const Legend: React.FC<ILegend> = ({ options, activeOptions, onChange }) => {
  return (
    <Container>
      <List>
        {options.map((option) => {
          const isActive = activeOptions.includes(option.dataKey);

          return (
            <ItemWrapper isActive={isActive} key={option.dataKey} onClick={() => onChange(option.dataKey, !isActive)}>
              <ColorPreview color={option.color} />
              <Label>{option.label}</Label>
            </ItemWrapper>
          );
        })}
      </List>
    </Container>
  );
};

export default Legend;
