import { ControlsPlus } from '@heathmont/moon-icons';
import { Text } from '@heathmont/moon-core';
import Button from '../../../components/Button';
import { IEmployers } from './types';
import { PageHeader } from '../styles';

const Countries: React.FC<IEmployers> = () => {
  return (
    <div>
      <PageHeader>
        <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
          Countries
        </Text>

        <Button
          handleClick={() => {}}
          iconLeft={<ControlsPlus fontSize="1.6rem" />}
          variant="primary"
          label="Add country"
          isDisabled
        />
      </PageHeader>
    </div>
  );
};

export default Countries;
