import { useState, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { TextInput } from '@heathmont/moon-core';
import { ReactComponent as SlackIcon } from '../../static/icons/slackIcon.svg';
import { getBoolStatusLabel, getLabels } from '../../utils/tableHelpers';
import { N_A } from '../../utils/commonFunctions';
import { teamsNames, TEAMS_TYPES } from '../../enums/TeamsTypes';
import CustomSelect from '../CustomSelect';
import EllipsisText from '../EllipsisText';
import FormField from '../FormField';
import FormActions from './FormActions';
import EditButton from './EditButton';
import { ICompanyBasicInformation } from './types';
import { companyBasicInformationSchema } from './schema';
import {
  OverviewContentWrapper,
  OverviewContentValue,
  SlackChannelWrapper,
  OverviewContentName,
  OverviewContent,
} from './styles';

const formatDefaultUsersOptions = (users) => users.map(({ id, firstname, lastname }) => ({ id, label: `${firstname} ${lastname}` }));
const formatDefaultBusinessVerticalOptions = (businessVerticals) => businessVerticals?.map(({ value, title }) => ({ id: value, label: `${title}` }));
const filterBusinessVerticalById = (businessVerticals, id) => businessVerticals?.filter(({ value }) => value === id);

const getInitialValues = (company) => {
  return {
    accommodationAdditionalPricePercent: +company.accommodationAdditionalPricePercent,
    flightAdditionalPricePercent: +company.flightAdditionalPricePercent,
    accountantsChannelName: company?.accountantsChannelName || null,
    travelopsesChannelName: company?.travelopsesChannelName || null,
    feedbackChannelName: company?.feedbackChannelName || null,
    travelGuideline: company?.travelGuideline || null,
    contactEmail: company?.contactEmail || null,
    admins: company?.admins?.map(({ id }) => id),
    defaultTravelopses: company?.defaultTravelopses?.map(({ id }) => id),
    defaultCeos: company?.defaultCeos?.map(({ id }) => id),
    defaultAccountants: company?.defaultAccountants?.map(({ id }) => id),
    name: company?.name || null,
  }
};

const statuses = [
  { title: teamsNames[TEAMS_TYPES.DISABLED], value: TEAMS_TYPES.DISABLED },
  { title: teamsNames[TEAMS_TYPES.ACTIVE], value: TEAMS_TYPES.ACTIVE },
];

const CompanyBasicInformation: React.FC<ICompanyBasicInformation> = ({
  company,
  companyTravelopses,
  companyAccountants,
  companyCeos,
  companyEmployees,
  companyInternalBusinessVerticals,
  companyExternalBusinessVerticals,
  updateCompanyBalancedStatus,
  updateCompanyBasicInfo,
  updateCompanyStatus,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const initialValues = useMemo(() => getInitialValues(company), [company]);

  const onCancel = (resetForm) => {
    setIsEditMode(false);
    resetForm();
  };

  const onSubmit = (values, { resetForm }) => {
    setIsEditMode(false);
    resetForm();
    updateCompanyBasicInfo(company.id, values);
  };

  const toEditMode = () => {
    setIsEditMode(true)
  };

  return (
    <OverviewContentWrapper>
      {!isEditMode && <EditButton onClick={toEditMode} />}

      <Formik
        enableReinitialize
        validationSchema={companyBasicInformationSchema}
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ errors, values, resetForm, setFieldValue, setFieldTouched, touched }: any): JSX.Element => {
          return (
            <Form>
              <OverviewContent>
                <OverviewContentName>ID</OverviewContentName>
                <OverviewContentValue>{company?.id}</OverviewContentValue>
              </OverviewContent>
            
              <OverviewContent>
                <OverviewContentName>Name</OverviewContentName>
                  <OverviewContentValue>
                    {
                      isEditMode ? (
                        <FormField
                          fieldName="name"
                          component={TextInput}
                          inputSize="xlarge"
                          fieldId="name"
                          errors={errors}
                          label="Company name"
                          value={values.name}
                          type="text"
                          onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                      ) : (
                        <EllipsisText text={company?.name}/>
                      )
                    }
                  </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Status</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      getBoolStatusLabel(company?.status)
                    ) : (
                      <CustomSelect
                        currentValue={company?.status}
                        items={statuses}
                        getItemLabel={getBoolStatusLabel}
                        onChange={(value) => updateCompanyStatus(value)}
                      />
                    ) 
                  }
                </OverviewContentValue>
                
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Admins</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                    <CustomSelect
                        currentValue={values.admins}
                        isSearchable
                        placeholder="Admins"
                        isMulti
                        isError={errors?.admins}
                        error={errors?.admins}
                        items={companyEmployees}
                        onChange={value => setFieldValue('admins', value)}
                      />
                    ) : (
                      company.admins?.length ? getLabels(formatDefaultUsersOptions(company.admins), 'label') : N_A
                    )
                  }
                </OverviewContentValue>
              </OverviewContent>
              

              <OverviewContent>
                <OverviewContentName>Accountants channel</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="accountantsChannelName"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="accountantsChannelName"
                        errors={errors}
                        value={values.accountantsChannelName}
                        type="text"
                        label="Channel name"
                        onChange={(e) => setFieldValue('accountantsChannelName', e.target.value)}
                      />
                    ) : (
                      company?.accountantsChannelName ? (
                        <SlackChannelWrapper>
                          <SlackIcon />
                          <EllipsisText text={company?.accountantsChannelName}/>
                        </SlackChannelWrapper>
                      ) : (
                        N_A
                      )
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Travelopses channel</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="travelopsesChannelName"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="travelopsesChannelName"
                        errors={errors}
                        value={values.travelopsesChannelName}
                        type="text"
                        label="Channel name"
                        onChange={(e) => setFieldValue('travelopsesChannelName', e.target.value)}
                      />
                    ) : (
                      company?.travelopsesChannelName ? (
                        <SlackChannelWrapper>
                          <SlackIcon />
                          <EllipsisText text={company?.travelopsesChannelName}/>
                        </SlackChannelWrapper>
                      ) : (
                        N_A
                      )
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Feedback channel</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="feedbackChannelName"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="feedbackChannelName"
                        errors={errors}
                        value={values.feedbackChannelName}
                        type="text"
                        label="Channel name"
                        onChange={(e) => setFieldValue('feedbackChannelName', e.target.value)}
                      />
                    ) : (
                      company?.feedbackChannelName ? (
                        <SlackChannelWrapper>
                          <SlackIcon />
                          <EllipsisText text={company?.feedbackChannelName}/>
                        </SlackChannelWrapper>
                      ) : (
                        N_A
                      )
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Travel guideline</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="travelGuideline"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="travelGuideline"
                        errors={errors}
                        value={values.travelGuideline}
                        type="text"
                        label="Travel guideline"
                        onChange={(e) => setFieldValue('travelGuideline', e.target.value)}
                      />
                    ) : (
                      company?.travelGuideline ? (
                        <a target="_blank"  style={{ whiteSpace: 'nowrap' }} href={`${company.travelGuideline}`}>
                          <EllipsisText text={company.travelGuideline}/>
                        </a>
                      ) : (
                        N_A
                      )
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Contact email</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="contactEmail"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="contactEmail"
                        errors={errors}
                        value={values.contactEmail}
                        type="email"
                        label="Contact email"
                        onChange={(e) => setFieldValue('contactEmail', e.target.value)}
                      />
                    ) : (
                      company?.contactEmail ? (
                        <EllipsisText text={company?.contactEmail}/>
                      ) : (
                        N_A
                      )
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Accommodation margin</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="accommodationAdditionalPricePercent"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="accommodationAdditionalPricePercent"
                        errors={errors}
                        value={values.accommodationAdditionalPricePercent}
                        type="number"
                        label="Accommodation margin"
                        onChange={(e) => setFieldValue('accommodationAdditionalPricePercent', e.target.value)}
                      />
                    ) : (
                      company?.accommodationAdditionalPricePercent
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Flight margin</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <FormField
                        fieldName="flightAdditionalPricePercent"
                        inputSize="xlarge"
                        component={TextInput}
                        fieldId="flightAdditionalPricePercent"
                        errors={errors}
                        value={values.flightAdditionalPricePercent}
                        type="number"
                        label="Flight margin"
                        onChange={(e) => setFieldValue('flightAdditionalPricePercent', e.target.value)}
                      />
                    ) : (
                      company?.flightAdditionalPricePercent
                    ) 
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Balanced Integration</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      getBoolStatusLabel(company?.balancedStatus)
                    ) : (
                      <CustomSelect
                        currentValue={company?.balancedStatus}
                        items={statuses}
                        getItemLabel={getBoolStatusLabel}
                        onChange={(value) => updateCompanyBalancedStatus(value)}
                      />
                    ) 
                  }
                </OverviewContentValue>
                
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Default Travelopses</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <CustomSelect
                        currentValue={values.defaultTravelopses}
                        isSearchable
                        placeholder="Travelopses"
                        isMulti
                        isError={touched?.defaultTravelopses && errors?.defaultTravelopses}
                        error={touched?.defaultTravelopses && errors?.defaultTravelopses}
                        items={companyTravelopses}
                        onChange={value => {
                          setFieldTouched('defaultTravelopses', true);
                          setFieldValue('defaultTravelopses', value);
                        }}
                      />
                    ) : (
                      company.defaultTravelopses?.length ? getLabels(formatDefaultUsersOptions(company.defaultTravelopses), 'label') : N_A
                    )
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Default CEO(s)</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <CustomSelect
                        currentValue={values.defaultCeos}
                        isSearchable
                        placeholder="CEO(s)"
                        isMulti
                        isError={touched?.defaultCeos && errors?.defaultCeos}
                        error={touched?.defaultCeos && errors?.defaultCeos}
                        items={companyCeos}
                        onChange={value => {
                          setFieldTouched('defaultCeos', true);
                          setFieldValue('defaultCeos', value);
                        }}
                      />
                    ) : (
                      company.defaultCeos?.length ? getLabels(formatDefaultUsersOptions(company.defaultCeos), 'label') : N_A
                    )
                  }
                </OverviewContentValue>
              </OverviewContent>

              <OverviewContent>
                <OverviewContentName>Default Accountants</OverviewContentName>
                <OverviewContentValue>
                  {
                    isEditMode ? (
                      <CustomSelect
                        currentValue={values.defaultAccountants}
                        isSearchable
                        placeholder="Accountants"
                        isMulti
                        isError={touched?.defaultAccountants && errors?.defaultAccountants}
                        error={touched?.defaultAccountants && errors?.defaultAccountants}
                        items={companyAccountants}
                        onChange={value => {
                          setFieldTouched('defaultAccountants', true);
                          setFieldValue('defaultAccountants', value);
                        }}
                      />
                    ) : (
                      company.defaultAccountants?.length ? getLabels(formatDefaultUsersOptions(company.defaultAccountants), 'label') : N_A
                    )
                  }
                </OverviewContentValue>
              </OverviewContent>

              { isEditMode && <FormActions errors={errors} onCancel={() => onCancel(resetForm)} /> }
            </Form>
          )
        }}
      </Formik>
    </OverviewContentWrapper>
  );
};

export default CompanyBasicInformation;
