import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@heathmont/moon-core';
import {
  TravelSuitcase,
  TravelAirplane,
  GenericDelete,
  TravelSeats,
  GenericEdit,
  GenericPlus,
  TravelMeal,
} from '@heathmont/moon-icons';
import FlightSegmentForm from './FlightSegmentForm';
import {
  FlightSegmentExtraMetaItem,
  FlightSegmentExtraMeta,
  ItineraryTitleWrapper,
  AddButtonsWrapper,
  FlightInfoBlock,
  FlightContent,
  LeftContent,
  Icons,
} from '../styles';
import InfoBlock from '../../InfoBlock';
import { getAirportByCode } from '../../../utils/trips';
import { getFlightLabel } from '../../TripsTicketDrawerContent/TripFlightBooking/utils';
import { FLIGHT_TYPE } from '../../../enums/FlightType';
import { IExternalFlight } from '../types';
import { PassengerOption } from '../../../enums/PassengerOption';

const ExternalItineraryForm = ({
  passengers,
  airports,
  values,
  index,
  type,
  getAirlineCodeFromAirlineId, 
  handleCloseEditSegmentMode, 
  formatFlightDateAndTime,
  handleDeleteItinerary,
  handleDeleteSegment,
  handleEditSegment,
  setFieldValue,
}: {
  passengers: PassengerOption[];
  airports: any[];
  values: IExternalFlight;
  index: number;
  type: FLIGHT_TYPE;
  getAirlineCodeFromAirlineId,
  handleCloseEditSegmentMode, 
  formatFlightDateAndTime,
  handleDeleteItinerary,
  handleDeleteSegment,
  handleEditSegment,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [isNewSegmentAddFormOpen, setIsNewSegmentAddFormOpen] = useState<boolean>(!values.itineraries[index].segments?.length);
  return (
    <>
      <ItineraryTitleWrapper>
        <strong>
          <Text size={16} style={{ marginBottom: '5px' }}>
            {getFlightLabel(index, type)}
          </Text>
        </strong>
        {
          type === FLIGHT_TYPE.MULTI_DESTINATION && values?.itineraries?.length > 1 && (
            <Button
              variant="ghost"
              size="small"
              onClick={() => handleDeleteItinerary(index, values, setFieldValue)}
              iconLeft={<GenericDelete fontSize="1.2rem"/>}
            >Delete itinerary</Button>
          ) 
        }
      </ItineraryTitleWrapper>
      {values.itineraries[index]?.segments?.length > 0 && (
        <>
          {values.itineraries[index].segments.map((segmentData) => 
            segmentData.editMode ? 
            (
              <FlightSegmentForm
                flightItineraryIndex={index}
                segmentData={segmentData}
                passengers={passengers}
                handleClose={() => handleCloseEditSegmentMode(segmentData.id, setFieldValue, values)}
              />
            ) :
            (
              <FlightInfoBlock key={segmentData.id}>
              <LeftContent>
                <TravelAirplane fontSize="1.6rem" />
                <FlightContent>
                  <p>
                    <strong>{getAirlineCodeFromAirlineId(segmentData.airline)}-{segmentData.flightNumber.toUpperCase()}</strong> {getAirportByCode(airports, segmentData.departureCode)} → {getAirportByCode(airports, segmentData.arrivalCode)}
                  </p>
                  <p className="flight-dates">
                    {formatFlightDateAndTime(segmentData.departureAt)} → {formatFlightDateAndTime(segmentData.arrivalAt)}
                  </p>
                  <FlightSegmentExtraMeta>
                    {segmentData?.cabin && (
                      <FlightSegmentExtraMetaItem>
                        <TravelSeats fontSize="1.5rem" /> {t(`trips.lists.cabins.${segmentData?.cabin}` || segmentData?.cabin)}
                      </FlightSegmentExtraMetaItem>
                    )}

                    {segmentData?.meals && (
                      <FlightSegmentExtraMetaItem>
                        <TravelMeal fontSize="1.5rem" /> {segmentData?.meals ?? ''}
                      </FlightSegmentExtraMetaItem>
                    )}

                    {segmentData?.includedCheckedBags?.quantity && segmentData?.includedCheckedBags?.weight && (
                      <FlightSegmentExtraMetaItem>
                        <TravelSuitcase fontSize="1.5rem" /> {`${segmentData?.includedCheckedBags?.quantity} checked bag(s) up to ${segmentData?.includedCheckedBags?.weight} ${segmentData?.includedCheckedBags?.weightUnit} per passenger`}
                      </FlightSegmentExtraMetaItem>
                    )}

                    {segmentData?.additionalServices?.length > 0 && (
                      <>
                        <strong>Additional services:</strong>

                        {segmentData.additionalServices.map(extraBag => (
                          <FlightSegmentExtraMetaItem>
                            <TravelSuitcase fontSize="1.5rem" /> {`${extraBag.quantity} extra bag(s) up to ${extraBag.weight} ${extraBag.weightUnit}`}
                          </FlightSegmentExtraMetaItem>
                        ))}
                      </>
                    )}
                  </FlightSegmentExtraMeta>
                </FlightContent>
              </LeftContent>
              <Icons>
                <Button
                  variant="ghost"
                  size="small"
                  onClick={() => handleEditSegment(segmentData.id, setFieldValue, values)}
                  iconOnly={<GenericEdit fontSize="1.2rem"/>}
                />
                <Button
                  variant="ghost"
                  size="small"
                  onClick={() => handleDeleteSegment(segmentData.id, setFieldValue, values)}
                  iconOnly={<GenericDelete fontSize="1.2rem"/>}
                />
              </Icons>
              </FlightInfoBlock>
            )
          )}
        </>
      )}

      {values.itineraries[index].segments.length < 1 && !isNewSegmentAddFormOpen && (
        <InfoBlock text='Flight must have at least one segment'/>
      )}

      {isNewSegmentAddFormOpen ? (
        <FlightSegmentForm
          flightItineraryIndex={index}
          passengers={passengers}
          handleClose={() => setIsNewSegmentAddFormOpen(false)}
        />
      ) : (
        <AddButtonsWrapper>
          <Button
            variant="ghost"
            size="small"
            onClick={() => setIsNewSegmentAddFormOpen(true)}
            iconLeft={<GenericPlus fontSize="1.2rem" />}
          >
            Add segment
          </Button>
        </AddButtonsWrapper>
      )}
    </>
  );
};

export default ExternalItineraryForm;
