import styled from 'styled-components';

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 50%;
  width: 50%;
`;

export const OverviewItem = styled.span`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  width: 50%;
  max-width: 50%;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}

  & input {
    font-size: 14px;
  }

  > div {
    width: 100%;
  }
  > span.user-assistants{
    width: 100%;
    >span {
      display:block;
    }
    div {
     width: fit-content;
     max-width: 100%;
     margin-top: 0.4rem;
    }
  }
  }
`;

export const HiddenRolePopoverBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-top: 20px;

  & button {
    max-width: 123px;
    border-radius: 8px;
    margin-left: 10px;
  }
`;

export const ChangeRolePopoverContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const GroupInputWrapper = styled.div`
  display: flex;
  justify-items: center;
  gap: 5px;

  & > * {
    width: calc(50% - 2.5px);
  }
`;

export const ConfirmDialogBodyWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`;
