import {
  getTripApprovedByTitle,
  formatDateAndTime,
  getBooleanLabel,
} from '../../../utils/tableHelpers';
import { ITripApprovalFlow } from './types';
import {
  OverviewContentValue,
  OverviewContentName,
  OverviewContent,
} from '../styles';

const TripApprovalFlow: React.FC<ITripApprovalFlow> = ({
  trip,
}) => {
  return (
    <>
      {trip?.approvedAt && (
        <OverviewContent>
          <OverviewContentName>Approved at</OverviewContentName>
          <OverviewContentValue>{formatDateAndTime(trip.approvedAt, true)}</OverviewContentValue>
        </OverviewContent>
      )}

      {(trip?.approvedBy?.fullName || trip?.tripApprovedAutomatically) && (
        <OverviewContent>
          <OverviewContentName>Approved by</OverviewContentName>
          <OverviewContentValue>{getTripApprovedByTitle(trip.approvedBy)}</OverviewContentValue>
        </OverviewContent>
      )}

      {trip?.confirmedAt && (
        <OverviewContent>
          <OverviewContentName>Confirmed at</OverviewContentName>
          <OverviewContentValue>{formatDateAndTime(trip.confirmedAt, true)}</OverviewContentValue>
        </OverviewContent>
      )}

      {trip?.confirmedBy?.fullName && (
        <OverviewContent>
          <OverviewContentName>Confirmed by</OverviewContentName>
          <OverviewContentValue>{trip.confirmedBy.fullName}</OverviewContentValue>
        </OverviewContent>
      )}

      {trip?.declinedAt && (
        <OverviewContent>
          <OverviewContentName>Declined at</OverviewContentName>
          <OverviewContentValue>{formatDateAndTime(trip.declinedAt, true)}</OverviewContentValue>
        </OverviewContent>
      )}

      {trip?.declinedBy?.fullName && (
        <OverviewContent>
          <OverviewContentName>Declined by</OverviewContentName>
          <OverviewContentValue>{trip.declinedBy.fullName}</OverviewContentValue>
        </OverviewContent>
      )}

      {trip?.declinedAt && (
        <OverviewContent>
          <OverviewContentName>Automatically declined</OverviewContentName>
          <OverviewContentValue>{getBooleanLabel(trip.isAutoDeclined)}</OverviewContentValue>
        </OverviewContent>
      )}
    </>
  );
};

export default TripApprovalFlow;
