import styled from 'styled-components';

export const FormFieldsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;
