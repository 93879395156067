import { useEffect, Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { useTable, useRowSelect, useSortBy, useExpanded } from 'react-table';
import { Text } from '@heathmont/moon-components';
import { Checkbox } from '@heathmont/moon-core';
import { ArrowsDown, ArrowsUp } from '@heathmont/moon-icons';
import { ITable } from './types';
import { CheckboxWrapper } from './styles';
import RowActions from '../RowActions';
import { TableColumnsFilter } from '../../styled';

const Table: React.FC<ITable> = ({
  isActiveAllAccommodationsForIgnoreCheckboxes,
  isActiveAllRecommendedHotelsCheckboxes,
  isActiveAllBusinessVerticalsCheckboxes,
  isActiveAllEmploymentTypesCheckboxes,
  isActiveAllCustomHotelsCheckboxes,
  isActiveAllAllowancesCheckboxes,
  isActiveAllEmployersCheckboxes,
  isActiveAllPurposesCheckboxes,
  isToolsBusinessVerticalsPage,
  isToolsRecommendedHotelsPage,
  isIgnoredAccommodationsPage,
  isActiveAllRolesCheckboxes,
  isActiveAllTeamsCheckboxes,
  isToolsEmploymentTypesPage,
  isToolsCustomHotelsPage,
  isToolsAllowancesPage,
  isToolsEmployersPage,
  isToolsPurposePage,
  isShowTableFilters,
  isToolsActionsPage,
  isToolsTeamsPage,
  isToolsRolesPage,
  withCheckboxRow,
  checkboxesData,
  initialColumns,
  limitPerPage,
  hoveredRow,
  withHover,
  groupBy,
  columns,
  sortBy: initialSortBy,
  page,
  data,
  renderRowSubComponent,
  handleSetAllCheckboxs,
  handleIsOverviewOpen,
  handleRemoveDialog,
  handleIsTicketOpen,
  handleSetCheckbox,
  handleHoveredRow = () => {},
  handleAddNew,
  handleSortBy,
  checkIsRowDisabled,
}) => {
  const handleCheckedAllData = useCallback((e, data) => {
    if (isToolsRolesPage) {
      const roleIds = data.reduce((acc, curr) => {
        if (!curr.original.isDefaultRole) {
          acc.push({ roleId: curr.original.roleId, isActive: e.target.checked });
        }

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(roleIds, e.target.checked);
      }
    }

    if (isToolsTeamsPage) {
      const teamIds = data.reduce((acc, curr) => {
        acc.push({ teamId: curr.original.teamId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(teamIds, e.target.checked);
      }
    }

    if (isToolsEmployersPage) {
      const employerIds = data.reduce((acc, curr) => {
        acc.push({ employerId: curr.original.employerId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(employerIds, e.target.checked);
      }
    }

    if (isToolsBusinessVerticalsPage) {
      const businessVerticalsIds = data.reduce((acc, curr) => {
        acc.push({ businessVerticalId: curr.original.businessVerticalId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(businessVerticalsIds, e.target.checked);
      }
    }

    if (isToolsActionsPage) {
      const actionIds = data.reduce((acc, curr) => {
        acc.push({ actionsAndNotificationsId: curr.original.actionsAndNotificationsId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(actionIds, e.target.checked);
      }
    }

    if (isToolsPurposePage) {
      const purposeIds = data.reduce((acc, curr) => {
        if (!curr.original.isDefaultPurpose) {
          acc.push({ purposeId: curr.original.purposeId, isActive: e.target.checked });
        }

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(purposeIds, e.target.checked);
      }
    }

    if (isToolsEmploymentTypesPage) {
      const employmentTypesIds = data.reduce((acc, curr) => {
        acc.push({ employmentTypeId: curr.original.employmentTypeId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(employmentTypesIds, e.target.checked);
      }
    }
    if (isToolsAllowancesPage) {
      const allowancesTypesIds = data.reduce((acc, curr) => {
        acc.push({ allowanceId: curr.original.id, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(allowancesTypesIds, e.target.checked);
      }
    }

    if (isToolsRecommendedHotelsPage) {
      const recommendedHotelsIds = data.reduce((acc, curr) => {
        acc.push({ recommendedHotelId: curr.original.recommendedHotelId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(recommendedHotelsIds, e.target.checked);
      }
    }

    if (isToolsCustomHotelsPage) {
      const customHotelsIds = data.reduce((acc, curr) => {
        acc.push({ customHotelId: curr.original.customHotelId, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(customHotelsIds, e.target.checked);
      }
    }

    if (isIgnoredAccommodationsPage) {
      const ignoredAccommodationsIds = data.reduce((acc, curr) => {
        acc.push({ id: curr.original.id, isActive: e.target.checked });

        return acc;
      }, []);

      if (e.target.checked) {
        return handleSetAllCheckboxs(ignoredAccommodationsIds, e.target.checked);
      }
    }

    return handleSetAllCheckboxs();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
    state: { sortBy },
  }: any = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: { sortBy: initialSortBy },
    } as any,
    useSortBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    handleSortBy(sortBy);
  }, [sortBy]);

  const handleChangeCheckbox = useCallback((e, row) => {
    if (isToolsRolesPage) {
      return handleSetCheckbox({ roleId: row.original.roleId, isActive: e.target.checked });
    }

    if (isToolsTeamsPage) {
      return handleSetCheckbox({ teamId: row.original.teamId, isActive: e.target.checked });
    }

    if (isToolsEmployersPage) {
      return handleSetCheckbox({ employerId: row.original.employerId, isActive: e.target.checked });
    }

    if (isToolsBusinessVerticalsPage) {
      return handleSetCheckbox({ businessVerticalId: row.original.businessVerticalId, isActive: e.target.checked });
    }

    if (isToolsPurposePage) {
      return handleSetCheckbox({ purposeId: row.original.purposeId, isActive: e.target.checked });
    }

    if (isToolsEmploymentTypesPage) {
      return handleSetCheckbox({ employmentTypeId: row.original.employmentTypeId, isActive: e.target.checked });
    }
    if (isToolsAllowancesPage) {
      return handleSetCheckbox({ allowanceId: row.original.id, isActive: e.target.checked });
    }

    if (isToolsActionsPage) {
      return handleSetCheckbox({
        actionsAndNotificationsId: row.original.actionsAndNotificationsId,
        isActive: e.target.checked,
      });
    }

    if (isToolsRecommendedHotelsPage) {
      return handleSetCheckbox({ recommendedHotelId: row.original.recommendedHotelId, isActive: e.target.checked });
    }

    if (isToolsCustomHotelsPage) {
      return handleSetCheckbox({ customHotelId: row.original.customHotelId, isActive: e.target.checked });
    }

    if (isIgnoredAccommodationsPage) {
      return handleSetCheckbox({ id: row.original.id, isActive: e.target.checked });
    }

    return handleSetCheckbox({
      paymentRequired: row.original.paymentRequired,
      statusId: row.original.statusId,
      isActive: e.target.checked,
      userType: row.original.userType,
      tripId: row.original.tripId,
    });
  }, []);

  const checkOnIsChecked = useCallback(
    (row) => {
      if (isToolsRolesPage) {
        return checkboxesData.some((itemValue: any) => itemValue.roleId === row.original.roleId);
      }

      if (isToolsTeamsPage) {
        return checkboxesData.some((itemValue: any) => itemValue.teamId === row.original.teamId);
      }

      if (isToolsEmployersPage) {
        return checkboxesData.some((itemValue: any) => itemValue.employerId === row.original.employerId);
      }

      if (isToolsBusinessVerticalsPage) {
        return checkboxesData.some((itemValue: any) => itemValue.businessVerticalId === row.original.businessVerticalId);
      }

      if (isToolsPurposePage) {
        return checkboxesData.some((itemValue: any) => itemValue.purposeId === row.original.purposeId);
      }

      if (isToolsEmploymentTypesPage) {
        return checkboxesData.some((itemValue: any) => itemValue.employmentTypeId === row.original.employmentTypeId);
      }
      if (isToolsAllowancesPage) {
        return checkboxesData.some((itemValue: any) => itemValue.allowanceId === row.original.id);
      }

      if (isToolsActionsPage) {
        return checkboxesData.some(
          (itemValue: any) => itemValue.actionsAndNotificationsId === row.original.actionsAndNotificationsId
        );
      }

      if (isToolsRecommendedHotelsPage) {
        return checkboxesData.some((itemValue: any) => itemValue.recommendedHotelId === row.original.recommendedHotelId);
      }

      if (isToolsCustomHotelsPage) {
        return checkboxesData.some((itemValue: any) => itemValue.customHotelId === row.original.customHotelId);
      }

      if (isIgnoredAccommodationsPage) {
        return checkboxesData.some((itemValue: any) => itemValue.id === row.original.id);
      }

      return checkboxesData.some((itemValue: any) => itemValue.tripId === row.original.tripId);
    },
    [checkboxesData]
  );

  const isRowDisabled = (row) => {
    if(row.isDefaultRole){
      return true;
    }

    if(row.isDefaultPurpose){
      return true;
    }

    if(checkIsRowDisabled){
      return checkIsRowDisabled(row);
    }

    return false;
  }

  return (
    <>
      {isShowTableFilters && (
        <TableColumnsFilter>
          {allColumns
            .filter(({ id }) => id !== 'expander')
            .map((column) => (
              <Checkbox
                style={{ display: 'block' }}
                key={column.id}
                {...column.getToggleHiddenProps()}
                label={
                  <Text size={14} styles={{ marginBottom: 0 }}>
                    {initialColumns.find(({ accessor }) => accessor === column.id)?.Header}
                  </Text>
                }
              />
            ))}
        </TableColumnsFilter>
      )}
      {data?.length ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: { minWidth: column.minWidth, maxWidth: column.maxWidth },
                      })}
                      className={column.id === groupBy ? 'groupedBy' : null}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowsDown fontSize="1.2rem" />
                          ) : (
                            <ArrowsUp fontSize="1.2rem" />
                          )
                        ) : (
                          ''
                        )}
                      </span>

                      {(isToolsRolesPage ||
                        isToolsTeamsPage ||
                        isToolsEmployersPage ||
                        isToolsPurposePage ||
                        isToolsActionsPage ||
                        isToolsEmploymentTypesPage||
                        isToolsAllowancesPage ||
                        isToolsBusinessVerticalsPage ||
                        isToolsRecommendedHotelsPage ||
                        isToolsCustomHotelsPage|| 
                        isIgnoredAccommodationsPage
                        ) &&
                        column.id === 'expander' && (
                          <CheckboxWrapper>
                            <Checkbox
                              checked={
                                isActiveAllRolesCheckboxes ||
                                isActiveAllTeamsCheckboxes ||
                                isActiveAllPurposesCheckboxes ||
                                isActiveAllEmploymentTypesCheckboxes ||
                                isActiveAllAllowancesCheckboxes ||
                                isActiveAllEmployersCheckboxes ||
                                isActiveAllRecommendedHotelsCheckboxes ||
                                isActiveAllCustomHotelsCheckboxes ||
                                isActiveAllBusinessVerticalsCheckboxes ||
                                isActiveAllAccommodationsForIgnoreCheckboxes
                              }
                              onChange={(e) => {
                                handleCheckedAllData(e, rows);
                              }}
                            />
                          </CheckboxWrapper>
                        )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <Fragment key={rowProps.key}>
                  <tr
                    className={classnames({
                      highlighted: row?.original?.isSelected,
                      grouped: groupBy,
                    })}
                    onMouseEnter={() => handleHoveredRow(row)}
                    onMouseLeave={() => handleHoveredRow(null)}
                    {...rowProps}
                  >
                    {row.cells.map((cell) => {
                      const lastCell = row?.cells[row?.cells.length - 1];

                      if (cell.column.id === 'expander' && !groupBy) {
                        return (
                          <td key={cell.column.id}>
                            {withCheckboxRow ? (
                              <CheckboxWrapper>
                                <Checkbox
                                  checked={checkOnIsChecked(row)}
                                  onChange={(e) => handleChangeCheckbox(e, row)}
                                  disabled={isRowDisabled(row?.original)}
                                />
                              </CheckboxWrapper>
                            ) : (
                              <span>{i + 1 + (page - 1) * limitPerPage}</span>
                            )}
                          </td>
                        );
                      }

                      if (withHover && hoveredRow?.id === row.id && cell.column.id === lastCell?.column?.id) {
                        return (
                          <td key={cell.column.id}>
                            <RowActions
                              handleIsOverviewOpen={handleIsOverviewOpen}
                              handleRemoveDialog={handleRemoveDialog}
                              handleIsTicketOpen={handleIsTicketOpen}
                              handleAddNew={handleAddNew}
                              row={row}
                            />
                          </td>
                        );
                      }

                      return (
                        <td
                          key={cell.column.id}
                          className={cell.column.id === groupBy ? 'groupedBy' : null}
                          style={{ position: 'relative' }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, data })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Text size={14} textAlign="center" styles={{ marginBottom: 0 }}>
          No records were found
        </Text>
      )}
    </>
  );
};

export default Table;
