import { useEffect, useState } from 'react';
import Accordion from '../Accordion';
import CompanyBasicInformation from './CompanyBasicInformation';
import CompanyHibobIntegration from './CompanyHibobIntegration';
import CompanyJiraIntegration from './CompanyJiraIntegration';
import { ICompanyDrawerContent } from './types';
import { OverviewWrapper } from './styles';
import { fetchCompanyOptions } from '../../store/tools/companies/api';
import { BUSINESS_VERTICAl_TYPES } from '../../constants';

const CompanyDrawerContent: React.FC<ICompanyDrawerContent> = ({
  company,
  updateCompanyBalancedStatus,
  updateCompanyBasicInfo,
  updateHibobIntegration,
  deleteHibobIntegration,
  updateJiraIntegration,
  deleteJiraIntegration,
  addHibobIntegration,
  updateCompanyStatus,
  addJiraIntegration,
}) => {
  const [companyOptions, setCompanyOptions] = useState({
    accountants: [],
    users: [],
    travelopses: [],
    employees: [],
    ceos: [],
    businessVerticals: [],
  });

  const getCompanyOptions = async () => {
    const options = await fetchCompanyOptions(company.id);
    setCompanyOptions(options);
  }
  
  useEffect(() => {
    getCompanyOptions();
  }, [company?.id]);

  return (
    <OverviewWrapper>
      <CompanyBasicInformation
        company={company}
        companyTravelopses={companyOptions.travelopses}
        companyEmployees={companyOptions.employees}
        companyCeos={companyOptions.ceos}
        companyAccountants={companyOptions.accountants}
        companyInternalBusinessVerticals={companyOptions.businessVerticals?.filter(bv => bv.type === BUSINESS_VERTICAl_TYPES.INTERNAL)}
        companyExternalBusinessVerticals={companyOptions.businessVerticals?.filter(bv => bv.type === BUSINESS_VERTICAl_TYPES.EXTERNAL)}
        updateCompanyBalancedStatus={updateCompanyBalancedStatus}
        updateCompanyBasicInfo={updateCompanyBasicInfo}
        updateCompanyStatus={updateCompanyStatus}
      />

      <Accordion title="Jira" openByDefault withoutPadding>
        <CompanyJiraIntegration
          accountants={companyOptions.accountants}
          companyId={company.id}
          users={companyOptions.users}
          jira={company.jira}
          updateJiraIntegration={updateJiraIntegration}
          deleteJiraIntegration={deleteJiraIntegration}
          addJiraIntegration={addJiraIntegration}
        />
      </Accordion>

      <Accordion title="Hibob" openByDefault withoutPadding>
        <CompanyHibobIntegration
          companyId={company.id}
          hibob={company.hibob}
          updateHibobIntegration={updateHibobIntegration}
          deleteHibobIntegration={deleteHibobIntegration}
          addHibobIntegration={addHibobIntegration}
        />
      </Accordion>
    </OverviewWrapper>
  );
};

export default CompanyDrawerContent;
