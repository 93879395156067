import {
  SET_PAYMENTS_OPTIONS_DATA,
  CLOSE_PAYMENT_OVERVIEW,
  SHOW_PAYMENT_OVERVIEW,
  UPDATE_PAYER_DETAILS,
  SYNC_PAYMENT_STATUS,
  SET_INVOICE_NUMBER,
  SET_PAYMENTS_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
} from './actionTypes';

const initState = {
  options: {
    payerDocumentTypes: [],
    paymentTypes: [],
    payerTypes: [],
    countries: [],
    statuses: [],
  },
  isOpenOverview: false,
  isFiltersOpen: false,
  overviewData: null,
  paymentsData: [],
  total: null,
};

const paymentsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PAYMENTS_DATA:
      return {
        ...state,
        paymentsData: action.payload.data.records,
        total: action.payload.data.total,
      };

    case SET_PAYMENTS_OPTIONS_DATA:
      return {
        ...state,
        options: action.payload.data,
      };

    case SHOW_PAYMENT_OVERVIEW:
      return {
        ...state,
        isOpenOverview: true,
        isFiltersOpen: false,
        overviewData: action.payload.payment,
        paymentsData: state.paymentsData.map((payment) => ({ ...payment, isSelected: payment.id === action.payload.payment.id })),
      };

    case CLOSE_PAYMENT_OVERVIEW:
      return {
        ...state,
        isOpenOverview: false,
        paymentsData: state.paymentsData.map((payment) => ({ ...payment, isSelected: false })),
      };

    case TOGGLE_FILTERS:
      return {
        ...state,
        isFiltersOpen: !state.isFiltersOpen,
        isOpenOverview: false,
        overviewData: null,
        paymentsData: state.paymentsData.map((payment) => ({ ...payment, isSelected: false })),
      };

    case CLOSE_FILTERS:
      return {
        ...state,
        isFiltersOpen: false,
        paymentsData: state.paymentsData.map((payment) => ({ ...payment, isSelected: false })),
      };

    case SET_INVOICE_NUMBER:
      return {
        ...state,
        overviewData: state.overviewData && state.overviewData?.id === action.payload.paymentId
          ? { ...state.overviewData, balancedIoInvoiceNumber: action.payload.balancedIoInvoiceNumber }
          : state.overviewData,
        paymentsData: state.paymentsData.map((payment) => {
          if (payment.id === action.payload.paymentId) {
            return {
              ...payment,
              balancedIoInvoiceNumber: action.payload.balancedIoInvoiceNumber,
            };
          }

          return payment;
        }),
      };

    case SYNC_PAYMENT_STATUS: 
      return {
        ...state,
        overviewData: state.overviewData && state.overviewData?.id === action.payload.id
          ? ({
            ...state.overviewData,
            balancedIoInvoiceNumber: action.payload.balancedIoInvoiceNumber,
            externalStatus: action.payload.externalStatus,
            status: action.payload.status,
          })
          : state.overviewData,
        paymentsData: state.paymentsData.map((payment) => {
          if (payment.id === action.payload.id) {
            return {
              ...payment,
              balancedIoInvoiceNumber: action.payload.balancedIoInvoiceNumber,
              externalStatus: action.payload.externalStatus,
              status: action.payload.status,
            };
          }

          return payment;
        }),
      };

    case UPDATE_PAYER_DETAILS: 
      return {
        ...state,
        overviewData: state.overviewData && state.overviewData?.id === action.payload.id
          ? ({
            ...state.overviewData,
            payerRegistrationNumber: action.payload.payerRegistrationNumber,
            payerVatNumber: action.payload.payerVatNumber,
          })
          : state.overviewData,
        paymentsData: state.paymentsData.map((payment) => {
          if (payment.id === action.payload.id) {
            return {
              ...payment,
              payerRegistrationNumber: action.payload.payerRegistrationNumber,
              payerVatNumber: action.payload.payerVatNumber,
            };
          }

          return payment;
        }),
      };
    
    default:
      return state;
  }
};

export default paymentsReducer;
