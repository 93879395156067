import moment from 'moment';
import Yup from '../../../utils/validation';
import validationSchema from '../../../utils/validation-schema';

const validateMessages = {
  required: 'Field cannot be empty',
  id: 'Field must be a valid ID',
  firstName: {
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${validationSchema.USER.FIRST_NAME.min} characters`,
    max: `First name cannot be longer than ${validationSchema.USER.FIRST_NAME.max} characters`,
  },
  lastName: {
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${validationSchema.USER.LAST_NAME.min} characters`,
    max: `Last name cannot be longer than ${validationSchema.USER.LAST_NAME.max} characters`,
  },
  documentNumber: {
    required: 'Document number cannot be empty',
    min: `Document number cannot be less than ${validationSchema.USER.DOCUMENT_NUMBER.min} characters`,
    max: `Document number cannot be longer than ${validationSchema.USER.DOCUMENT_NUMBER.max} characters`,
  },
  loyaltyNumbers: {
    max: `Max number of loyalty programs is ${validationSchema.USER.LOYALTY_PROGRAMS.max}.`,
    number: {
      required: 'Loyalty number cannot be empty',
      min: 'Loyalty number cannot be less than 1 characters',
      max: 'Loyalty number cannot be longer than 255 characters',
    },
    airline: {
      required: 'Airline cannot be empty',
      min: 'Airline number cannot be less than 1',
    },
  },
  favoriteHotels: {
    regionId: {
      required: 'City cannot be empty',
    },
    hotelId: {
      required: 'Hotel cannot be empty',
    },
    userId: {
      required: 'User cannot be empty',
    },
  },
  assistants: {
    max: `Max number of assistants is ${validationSchema.USER.ASSISTANTS.max}.`,
  },
  balancedId: {
    max: `Balanced ID cannot be longer than ${validationSchema.USER.BALANCED_ID.max} characters`,
  },
  jiraId: {
    min: `Jira ID cannot be less than ${validationSchema.USER.JIRA_ID.min} characters`,
    max: `Jira ID cannot be longer than ${validationSchema.USER.JIRA_ID.max} characters`,
  },
  comment: {
    max: `Comment cannot be longer than ${validationSchema.USER.COMMENT.max} characters`,
  },
  email: {
    email: 'Email email must be a valid email', 
    required: 'Email can not be empty',  
  },
  cryptoWallets: {
    address: {
      min: 'Wallet address cannot be less than 1 characters',
      max: 'Wallet address cannot be longer than 1000 characters',
      required: 'Wallet address cannot be empty',
    },
    value: {
      required: 'Wallet crypto currency cannot be empty',
    },
  },
  businessVerticalId:  {
    required: 'Business vertical cannot be empty',
  },
};

export const userOverviewSchema = Yup.object().shape({
  balancedId: Yup
    .string()
    .max(validationSchema.USER.BALANCED_ID.max, validateMessages.balancedId.max)
    .withoutSpaces(),
  firstname: Yup
    .string()
    .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstName.min)
    .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.firstName.required),
  lastname: Yup
    .string()
    .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastName.min)
    .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastName.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.lastName.required),
  employerId: Yup
    .number()
    .typeError(validateMessages.id)
    .min(1)
    .required(validateMessages.required),
  teamId: Yup
    .number()
    .typeError(validateMessages.id)
    .min(1)
    .required(validateMessages.required),
  assistants: Yup
    .array()
    .of(Yup.number())
    .max(validationSchema.USER.ASSISTANTS.max, validateMessages.assistants.max),
  jiraId: Yup
    .string()
    .min(validationSchema.USER.JIRA_ID.min, validateMessages.jiraId.min)
    .max(validationSchema.USER.JIRA_ID.max, validateMessages.jiraId.max)
    .withoutSpaces(),
  comment: Yup
    .string()
    .max(validationSchema.USER.COMMENT.max, validateMessages.comment.max),
  businessVerticalId: Yup
    .number()
    .min(1)
    .required(validateMessages.businessVerticalId),
});

export const passengerSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstName.min)
    .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.firstName.required),
  lastname: Yup.
    string()
    .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastName.min)
    .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.lastName.required),
  passenger_email: Yup
    .string()
    .email(validateMessages.email.email)
    .withoutSpaces()
    .isEmailInLatinCharacters()
    .required(validateMessages.email.required),
  passenger_mobile_number: Yup
    .string()
    .isValidPhoneNumber()
    .required(validateMessages.required),
  birthdate: Yup
    .string()
    .nullable()
    .test('DOB', 'Age cannot be less then 18 years old', (value) => moment().diff(moment(value), 'years') >= 18)
    .required(validateMessages.required),
  gender: Yup
    .number()
    .required(validateMessages.required),
  title: Yup
    .number()
    .required(validateMessages.required),
});

export const userOverviewPassportSchema = Yup.object().shape({
  country: Yup
    .number()
    .required(validateMessages.required),
  document_number: Yup
    .string()
    .min(validationSchema.USER.DOCUMENT_NUMBER.min, validateMessages.documentNumber.min)
    .max(validationSchema.USER.DOCUMENT_NUMBER.max, validateMessages.documentNumber.max)
    .withoutSpaces()
    .required(validateMessages.documentNumber.required),
  document_valid: Yup
    .string()
    .nullable()
    .test('DOB', 'Document expiration date cannot be today or earlier', (value) => !moment().isSameOrAfter(value))
    .required(),
});

export const userLoyaltyNumbersSchema = Yup.object().shape({
  loyaltyNumbers: Yup.array().of(Yup.object().shape({
    number: Yup
      .string()
      .min(1, validateMessages.loyaltyNumbers.number.min)
      .max(255, validateMessages.loyaltyNumbers.number.max)
      .withoutSpaces()
      .required(validateMessages.loyaltyNumbers.number.required),
    airline: Yup
      .number()
      .min(1, validateMessages.loyaltyNumbers.airline.min)
      .required(validateMessages.loyaltyNumbers.airline.required),
  })).max(validationSchema.USER.LOYALTY_PROGRAMS.max, validateMessages.loyaltyNumbers.max),
});

export const userFavoriteHotelsSchema = Yup.object().shape({
  favoriteHotels: Yup.array().of(Yup.object().shape({
    userId: Yup
      .number()
      .required(validateMessages.favoriteHotels.userId.required),
    regionId: Yup
      .number()
      .required(validateMessages.favoriteHotels.regionId.required),
    hotelId: Yup
      .string()
      .required(validateMessages.favoriteHotels.hotelId.required),
  })),
});

export const userCryptoWalletsSchema = Yup.object().shape({
  cryptoWallets: Yup.array().of(Yup.object().shape({
    address: Yup
      .string()
      .min(1, validateMessages.cryptoWallets.address.min)
      .max(1000, validateMessages.cryptoWallets.address.max)
      .required(validateMessages.cryptoWallets.address.required)
      .isInLatinCharacters(),
    cryptoCurrencyId: Yup
      .number()
      .required(validateMessages.cryptoWallets.value.required),
  })),
});

export const userOverviewDocumentSchema = Yup.object().shape({
  country: Yup
    .number()
    .required(validateMessages.required),
  document_type: Yup
    .number()
    .required(validateMessages.required),
  document_number: Yup
    .string()
    .min(validationSchema.USER.DOCUMENT_NUMBER.min, validateMessages.documentNumber.min)
    .max(validationSchema.USER.DOCUMENT_NUMBER.max, validateMessages.documentNumber.max)
    .withoutSpaces()
    .required(validateMessages.documentNumber.required),
  document_valid: Yup
    .string()
    .nullable()
    .test('DOB', 'Document expiration date cannot be today or earlier', (value) => !moment().isSameOrAfter(value))
    .required(),
});
