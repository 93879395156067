import { FlightItinerary } from '../../../enums/FlightItinerary';
import { Passenger } from '../../../enums/Passenger';

export const hasBaggagePerFlight = (itinerary: FlightItinerary, passenger: Passenger) => {
  return itinerary.segments.some(segment => {
    return passenger.additionalServices?.some(additionalService => (
      additionalService.segmentId === segment.id
    ));
  });
};
