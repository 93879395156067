import styled from 'styled-components';

export const PhoneInputWrapper = styled.div`
  border: ${({ theme, isError }) =>
    isError ? `2px solid ${theme.color.chiChi['100']}` : `1px solid ${theme.color.beerus['100']}`};
  padding: 10px 16px;
  border-radius: 11px;
  margin-top: 24px;
  height: 56px;
  display: flex;
  align-items: center;

  & .PhoneInput {
    font-size: 14px;

    & .PhoneInputInput {
      outline: none !important;
      border: none !important;
    }
  }
`;

export const FieldWrapper = styled.div`
  margin: 24px 0;
`;
