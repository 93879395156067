import { Route, Switch, withRouter } from 'react-router-dom';
import ActionsAndNotifications from '../../pages/Tools/ActionsAndNotifications';
import Companies from '../../pages/Tools/Companies';
import Countries from '../../pages/Tools/Countries';
import Employers from '../../pages/Tools/Employers';
import BusinessVerticals from '../../pages/Tools/BusinessVerticals';
import EmploymentTypes from '../../pages/Tools/EmploymentTypes';
import Roles from '../../pages/Tools/Roles';
import Subscriptions from '../../pages/Tools/Subscriptions';
import Teams from '../../pages/Tools/Teams';
import TripPurposes from '../../pages/Tools/TripPurposes';
import { TOOLS_PAGES } from './suggestions';
import DailyAllowances from '../../pages/Tools/DailyAllowances';
import Feedbacks from '../../pages/Tools/Feedbacks';
import RecommendedHotels from '../../pages/Tools/RecommendedHotels';
import IgnoreListAccommodations from '../../pages/Tools/IgnoreListAccommodations';
import CustomHotels from '../../pages/Tools/CustomHotels';

const ToolsRoutes = ({ data }) => (
  <Switch>
    <Route exact path={TOOLS_PAGES.COMPANIES} render={(props) => <Companies {...data.companiesPage} {...props} />} />
    <Route exact path={TOOLS_PAGES.EMPLOYERS} render={(props) => <Employers {...data.employersPage} {...props} />} />
    <Route exact path={TOOLS_PAGES.BUSINESS_VERTICALS} render={(props) => <BusinessVerticals {...data.businessVerticals} {...props} />} />    
    <Route exact path={TOOLS_PAGES.TEAMS} render={(props) => <Teams {...data.teamsPage} {...props} />} />
    <Route exact path={TOOLS_PAGES.ROLES} render={(props) => <Roles {...data.rolesPage} {...props} />} />
    <Route exact path={TOOLS_PAGES.PURPOSES} render={(props) => <TripPurposes {...data.purposesPage} {...props} />} />
    <Route
      exact
      path={TOOLS_PAGES.DAILY_ALLOWANCES}
      render={(props) => <DailyAllowances {...data.allowancesPage} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.SUBSCRIPTIONS}
      render={(props) => <Subscriptions {...data.subscriptionsPage} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.ACTIONS_AND_NOTIFICATIONS}
      render={(props) => <ActionsAndNotifications {...data.actionsAndNotifications} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.EMPLOYMENT_TYPES}
      render={(props) => <EmploymentTypes {...data.employmentTypes} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.FEEDBACKS}
      render={(props) => <Feedbacks {...data.feedbacks} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.COUNTRIES}
      render={(props) => <Countries {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.RECOMMENDED_HOTELS}
      render={(props) => <RecommendedHotels {...data.recommendedHotels} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.IGNORE_LIST_ACCOMMODATIONS}
      render={(props) => <IgnoreListAccommodations {...data.ignoreListAccommodations} {...props} />}
    />
    <Route
      exact
      path={TOOLS_PAGES.CUSTOM_HOTELS}
      render={(props) => <CustomHotels {...data.customHotels} {...props} />}
    />
  </Switch>
);

export default withRouter(ToolsRoutes);
