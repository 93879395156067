import { Fragment, useMemo, useState } from 'react';
import { Button } from '@heathmont/moon-core';
import { v4 as uuidv4 } from 'uuid';
import { AddButtonsWrapper, Wrapper } from './styles';
import { GenericPlus } from '@heathmont/moon-icons-tw';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import ExternalFlightForm from './forms/ExternalFlightForm';
import TravelerForm from './forms/TravelerForm';
import {
  ISelectedFromOtherTransport,
  ISelectedFromAccommodation,
  IExternalAccommodation,
  IFormikAddExternalForm,
  IExternalFlight,
  IOtherTransport,
} from './types';
import ExternalAccommodation from './forms/ExternalAccommodation';
import { useFormikContext } from 'formik';
import OtherTransport from './forms/OtherTransportForm';
import AccommodationOverview from './overview/AccommodationOverview';
import OtherTransportOverview from './overview/OtherTransportOverview';
import FlightOverview from './overview/FlightOverview';
import { preparePassengersOptions } from './utils';

function AddExternalTripModalContent() {
  const currenciesOptions = useSelector((state: any) => state.tripsReducer.tripOptions.currencies);
  const selectedOptions = useSelector((state: any) => state.tripsReducer.externalTrip.selectedOptions);
  const companyUsers = useSelector((state: any) => state.tripsReducer.tripOptions.companyEmployees);
  const purposes = useSelector((state: any) => state.tripsReducer.tripOptions.purposes);
  const [isAddNewFlightOpen, setIsAddNewFlightOpen] = useState<boolean>(false);
  const [isAddNewAccommodationOpen, setIsAddNewAccommodationOpen] = useState<boolean>(false);
  const [selectedFromAccommodation, setSelectedFromAccommodation] = useState<ISelectedFromAccommodation>({
    cities: [],
    hotels: [],
  });
  const [selectedFromOtherTransport, setSelectedFromOtherTransport] = useState<ISelectedFromOtherTransport>({
    cities: [],
  });
  const [isAddNewOtherTransportOpen, setIsAddNewOtherTransportOpen] = useState<boolean>(false);
  const travelerForm = useFormikContext<IFormikAddExternalForm>();
  const { values, setFieldValue } = useFormikContext<IFormikAddExternalForm>();

  const passengersOptions = useMemo(() => {
    return preparePassengersOptions(travelerForm.values.traveler, companyUsers);
  }, [travelerForm.values.traveler, companyUsers]);

  const handleAddFlight = (flightData: IExternalFlight, setFieldValue, values: IFormikAddExternalForm) => {
    const newFlights = [...values.flights, { ...flightData, id: uuidv4() }];
    setFieldValue('flights', newFlights);
    setIsAddNewFlightOpen(false);
  };

  const handleAddAccommodation = (
    accommodationData: IExternalAccommodation,
    setFieldValue,
    values: IFormikAddExternalForm,
  ) => {
    const newAccommodations = [...values.accommodations, { ...accommodationData, id: uuidv4() }];
    setFieldValue('accommodations', newAccommodations);
    setIsAddNewAccommodationOpen(false);
  };

  const handleDeleteFlight = (values, flightData, setFieldValue) => {
    const { id } = flightData;
    const newFlights = values.flights.filter((flight) => flight.id !== id);
    setFieldValue('flights', newFlights);
  };

  const handleDeleteAccommodation = (values, accommodationData, setFieldValue) => {
    const { id } = accommodationData;
    const newAccommodations = values.accommodations.filter((accomm) => accomm.id !== id);
    setFieldValue('accommodations', newAccommodations);
  };

  const handleOpenAddFlight = () => {
    setIsAddNewFlightOpen(true);
    setIsAddNewAccommodationOpen(false);
    setIsAddNewOtherTransportOpen(false);
  };

  const handleOpenAddAccommodation = () => {
    setIsAddNewAccommodationOpen(true);
    setIsAddNewFlightOpen(false);
    setIsAddNewOtherTransportOpen(false);
  };

  const handleEditSegmentMode = (values, flightId, setFieldValue, editMode) => {
    const updatedFlights =  values.flights.map((flight) => flight.id === flightId ? {...flight, editMode} : flight);
    setFieldValue('flights', updatedFlights);
  };

  const handleEditFlight = (flightData: IExternalFlight, setFieldValue, values: IFormikAddExternalForm) => {
    const updatedFlights = values.flights.map((flight) => flight.id === flightData.id ? flightData : flight);
    setFieldValue('flights', updatedFlights);
  };

  const handleEditAccommodationMode = (values, accommodationId, setFieldValue, editMode) => {
    const updatedAccommodations = values.accommodations.map(
      (accommodation) => accommodation.id === accommodationId ? { ...accommodation, editMode } : accommodation
    );
    setFieldValue('accommodations', updatedAccommodations);
  };

  const handleEditAccommodation = (accommodationData: IExternalAccommodation, setFieldValue, values: IFormikAddExternalForm) => {
    const updatedAccommodations = values.accommodations.map(
      (accommodation) => accommodation.id === accommodationData.id ? accommodationData : accommodation
    );
    setFieldValue('accommodations', updatedAccommodations);
  };

  const handleOpenAddOtherTransport = () => {
    setIsAddNewOtherTransportOpen(true);
    setIsAddNewAccommodationOpen(false);
    setIsAddNewFlightOpen(false);
  };

  const handleAddOtherTransport = (data, setFieldValue, values) => {
    setFieldValue('otherTransports', [...values.otherTransports, { ...data, id: uuidv4() }]);
    setIsAddNewOtherTransportOpen(false);
  };

  const handleDeleteOtherTransport = (values, otherTransportId, setFieldValue) => {
    setFieldValue('otherTransports', values.otherTransports.filter((otherTransport) => otherTransport.id !== otherTransportId));
  };

  const handleEditOtherTransportMode = (values, otherTransportId, setFieldValue, editMode) => {
    setFieldValue('otherTransports', values.otherTransports.map(
      (otherTransport) => otherTransport.id === otherTransportId ? { ...otherTransport, editMode } : otherTransport
    ));
  };

  const handleEditOtherTransport = (data, setFieldValue, values) => {
    setFieldValue('otherTransports', values.otherTransports.map((otherTransport) => otherTransport.id === data.id ? data : otherTransport));
  };

  return (
    <Wrapper>
      <Form>
        {/* Traveler, trip purpose and trip purpose details */}
        <TravelerForm
          travelerForm={travelerForm}
          companyUsers={companyUsers}
          purposes={purposes}
        />

        {/* Flights */}
        {values.flights.length > 0 && values.flights.map((flightData: IExternalFlight) => (
          <Fragment key={uuidv4()}>
            {flightData.editMode ? (
              <ExternalFlightForm
                flightData= {flightData}
                passengers={passengersOptions}
                isEditMode
                handleSubmit={(flightData) => handleEditFlight(flightData, setFieldValue, values)}
                handleClose={() => handleEditSegmentMode(values, flightData.id, setFieldValue, false)}
              />
            ) : (
              <FlightOverview
                currencies={currenciesOptions}
                airports={selectedOptions.airports}
                airlines={selectedOptions.airlines}
                flight={flightData}
                handleDelete={() => handleDeleteFlight(values, flightData, setFieldValue)}
                handleEdit={() => handleEditSegmentMode(values, flightData.id, setFieldValue, true)}
              />
            )}
          </Fragment>
        ))}
  
        {isAddNewFlightOpen && (
          <ExternalFlightForm
            passengers={passengersOptions}
            handleSubmit={(flightData) => handleAddFlight(flightData, setFieldValue, values)}
            handleClose={() => setIsAddNewFlightOpen(false)}
          />
        )}

        {!isAddNewFlightOpen && !isAddNewAccommodationOpen && !isAddNewOtherTransportOpen && (
          <AddButtonsWrapper>
            <Button
              variant="ghost"
              size="small"
              onClick={() => handleOpenAddFlight()}
              iconLeft={<GenericPlus fontSize="1.2rem" />}
            >
              Add flight
            </Button>
          </AddButtonsWrapper>
        )}
    
        {/* Accommodations */}
        {values.accommodations.length > 0 && values.accommodations.map((accommodationData: IExternalAccommodation) => (
          <Fragment key={uuidv4()}>
            {accommodationData.editMode ? (
              <ExternalAccommodation
                selectedFromAccommodation={selectedFromAccommodation}
                accommodationData={accommodationData}
                isAddExternalTrip
                isEditMode
                setSelectedFromAccommodation={setSelectedFromAccommodation}
                handleSubmit={(accommodationData) => handleEditAccommodation(accommodationData, setFieldValue, values)}
                handleClose={() => handleEditAccommodationMode(values, accommodationData.id, setFieldValue, false)}
              />
            ) : (
              <AccommodationOverview
                accommodation={accommodationData}
                currencies={currenciesOptions}
                cities={selectedFromAccommodation.cities}
                hotels={selectedFromAccommodation.hotels}
                handleDelete={() => handleDeleteAccommodation(values, accommodationData, setFieldValue)}
                handleEdit={() => handleEditAccommodationMode(values, accommodationData.id, setFieldValue, true)}
              />
            )}
          </Fragment>
        ))}

        {isAddNewAccommodationOpen && (
          <ExternalAccommodation
            isAddExternalTrip
            setSelectedFromAccommodation={setSelectedFromAccommodation}
            handleSubmit={(accommodationData) => handleAddAccommodation(accommodationData, setFieldValue, values)}
            handleClose={() => setIsAddNewAccommodationOpen(false)}
          />
        )}

        {!isAddNewFlightOpen && !isAddNewAccommodationOpen && !isAddNewOtherTransportOpen && (
          <AddButtonsWrapper>
            <Button
              iconLeft={<GenericPlus fontSize="1.2rem" />}
              variant="ghost"
              size="small"
              onClick={() => handleOpenAddAccommodation()}
            >
              Add accommodation
            </Button>
          </AddButtonsWrapper>
        )}
    
        {/* Other transports */}
        {values.otherTransports?.length > 0 && values.otherTransports?.map((otherTransport: IOtherTransport) => (
          <Fragment key={uuidv4()}>
            {
              otherTransport.editMode ? (
                <OtherTransport 
                  selectedFromOtherTransport={selectedFromOtherTransport}
                  initialOtherTransportData={otherTransport}
                  isAddExternalTrip
                  isEditMode
                  setSelectedFromOtherTransport={setSelectedFromOtherTransport}
                  handleClose={() => handleEditOtherTransportMode(values, otherTransport.id, setFieldValue, false)}
                  handleSubmit={(data) => handleEditOtherTransport(data, setFieldValue, values)}
                />
              ) : (
                <OtherTransportOverview
                  otherTransport={otherTransport}
                  currencies={currenciesOptions}
                  cities={selectedFromOtherTransport.cities}
                  handleDelete={() => handleDeleteOtherTransport(values, otherTransport.id, setFieldValue)}
                  handleEdit={() => handleEditOtherTransportMode(values, otherTransport.id, setFieldValue, true)}
                />
              )
            }
          </Fragment>
        ))}

        {isAddNewOtherTransportOpen && (
          <OtherTransport 
            isAddExternalTrip
            setSelectedFromOtherTransport={setSelectedFromOtherTransport}
            handleSubmit={(data) => handleAddOtherTransport(data, setFieldValue, values)}
            handleClose={() => setIsAddNewOtherTransportOpen(false)}
          />
        )}
    
        {!isAddNewFlightOpen && !isAddNewAccommodationOpen && !isAddNewOtherTransportOpen && (
          <AddButtonsWrapper>
            <Button
              iconLeft={<GenericPlus fontSize="1.2rem" />}
              variant="ghost"
              size="small"
              onClick={() => handleOpenAddOtherTransport()}
            >
              Add other transport
            </Button>
          </AddButtonsWrapper>
        )}
      </Form>
    </Wrapper>
  );
}

export default AddExternalTripModalContent;
