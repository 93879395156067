import { useMemo } from 'react';
import { ArrowsRefreshRound, ShopCryptoCoin } from '@heathmont/moon-icons-tw';
import { MailSend } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { TripActionsWrapper } from '../styles';
import { ITripActions } from './types';

const TripActions: React.FC<ITripActions> = ({
  isTravelerExternal,
  paymentRequired,
  isPurchased,
  isApproved,
  isPending,
  notifyToApprove,
  requestPayment,
  syncUpdates,
}) => {
  const canSyncUpdates = useMemo(() => {
    return isApproved;
  }, [isApproved]);

  const canNotifyToApprove = useMemo(() => {
    return isPending && !isTravelerExternal && !paymentRequired;
  }, [isPending, isTravelerExternal, paymentRequired]);

  const canRequestPayment = useMemo(() => {
    return (isPending || isPurchased) && paymentRequired;
  }, [isPending, isPurchased, paymentRequired]);

  return (
    <TripActionsWrapper>
      {canSyncUpdates && (
        <Button variant="secondary" type="button" size="small" onClick={syncUpdates}>
          <ArrowsRefreshRound />
          Sync updates
        </Button>
      )}

      {canNotifyToApprove && (
        <Button variant="secondary" type="button" size="small" onClick={notifyToApprove}>
          <MailSend />
          Notify to approve
        </Button>
      )}

      {canRequestPayment && (
        <Button variant="secondary" type="button" size="small" onClick={requestPayment}>
          <ShopCryptoCoin />
          Request payment
        </Button>
      )}
    </TripActionsWrapper>
  );
};

export default TripActions;
