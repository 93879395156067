import Yup from '../../utils/validation';
import { TripStatusType } from '../../enums/TripStatusType';

const DECLINE_REASON_MIN_LENGTH = 2;
const DECLINE_REASON_MAX_LENGTH = 255;

const validateMessages = {
  status: {
    required: 'Status cannot be empty',
  },
  declineReason: {
    required: 'Decline reason cannot be empty',
    min: `Decline reason cannot be less than ${DECLINE_REASON_MIN_LENGTH} characters`,
    max: `Decline reason cannot be longer than ${DECLINE_REASON_MAX_LENGTH} characters`,
  },
};

export const getInitialValues = (status: TripStatusType) => ({
  declineReason: '',
  status,
});

export const tripStatusSchema = Yup.object().shape({
  status: Yup
    .number()
    .oneOf([
      TripStatusType.PENDING,
      TripStatusType.APPROVED,
      TripStatusType.PURCHASED,
      TripStatusType.DECLINED,
    ])
    .required(validateMessages.status.required),
  declineReason: Yup
    .string()
    .when('status', {
      is: value => value === TripStatusType.DECLINED,
      then: Yup
        .string()
        .min(DECLINE_REASON_MIN_LENGTH, validateMessages.declineReason.min)
        .max(DECLINE_REASON_MAX_LENGTH, validateMessages.declineReason.max)
        .isInLatinCharacters()
        .required(validateMessages.declineReason.required),
      otherwise: Yup
        .string()
        .min(DECLINE_REASON_MIN_LENGTH, validateMessages.declineReason.min)
        .max(DECLINE_REASON_MAX_LENGTH, validateMessages.declineReason.max)
        .isInLatinCharacters()
        .nullable(),
    }),
});

