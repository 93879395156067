import { useState } from 'react';
import { saveAs } from 'file-saver';
import useKeypress from 'react-use-keypress';
import { Button, Text } from '@heathmont/moon-core';
import { ControlsChevronRight, ControlsChevronLeft, ControlsMinus } from '@heathmont/moon-icons';
import FileViewer from '../FileViewer';
import Dialog from '../Dialog';

import { IExpenseReportReceiptsModal } from './types';
import {
  ArrowRight,
  ArrowLeft,
  ReceiptWrapper,
  ReceiptsCount,
} from './styles';

const ExpenseReportReceiptsModal: React.FC<IExpenseReportReceiptsModal> = ({
  receiptId,
  receipts,
  handleRemoveReceipt,
  handleCloseReceipt,
}) => {
  const [activeIndex, setActiveIndex] = useState(receipts.findIndex(receipt => receipt.id === receiptId));

  const handleDownloadReceipt = () => {
    saveAs(receipts[activeIndex].url);
  };

  const moveLeft = () => {
    if (activeIndex === 0) {
      setActiveIndex(receipts.length - 1)
    } else {
      setActiveIndex((prevValue) => prevValue - 1);
    }
  };

  const moveRight = () => {
    if (activeIndex === receipts.length - 1) {
      setActiveIndex(0)
    } else {
      setActiveIndex((prevValue) => prevValue + 1);
    }
  };

  useKeypress(['Escape', 'ArrowLeft', 'ArrowRight'], (event) => {
    if (event.key === 'ArrowLeft') {
      return moveLeft();
    }
    
    if (event.key === 'ArrowRight') {
      return moveRight();
    }

    if (event.key === 'Escape') {
      return handleCloseReceipt();
    }
  });

  if (!receipts[activeIndex]) {
    return null;
  }

  return (
    <Dialog
      submitButtonLabel="Download"
      title={'Expense report receipts'}
      renderAdditionalActions={() => (
        <Button
          animation="error"
          onClick={() => handleRemoveReceipt(receipts[activeIndex].id)}
        >Delete</Button>
      )}
      onSubmit={handleDownloadReceipt}
      onClose={handleCloseReceipt}
    >
      <ReceiptWrapper>
        <FileViewer url={receipts[activeIndex].url} />
      </ReceiptWrapper>
      <ArrowLeft
        iconOnly={<ControlsChevronLeft />}
        onClick={moveLeft}
      />
      <ArrowRight
        iconOnly={<ControlsChevronRight />}
        onClick={moveRight}
      />
      <ReceiptsCount>
        <Text color="trunks.100">{activeIndex + 1}</Text>
        <ControlsMinus color="trunks.100"/>
        <Text color="trunks.100">{receipts.length}</Text>
      </ReceiptsCount>
    </Dialog>
  );
};

export default ExpenseReportReceiptsModal;
