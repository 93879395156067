import { PurposeTypes } from '../../enums/PurposeTypes';
import { TripStatusType } from '../../enums/TripStatusType';
import { UserStatuses } from '../../enums/UserStatuses';
import { IPurposes, IStatuses } from '../../pages/Trips/types';

export const getAvailableStatuses = (statuses: IStatuses[], isManagerApprovalRequired: boolean): IStatuses[] => {
  if (isManagerApprovalRequired) {
    return statuses;
  }
  return statuses.filter((status) => status.value !== TripStatusType.APPROVED);
};

export const getPurposesAvailableForSelection = (purposes: IPurposes[]): IPurposes[] => {
  return purposes.filter(purpose => (
    purpose.type === PurposeTypes.INTERNAL
    && !purpose.paymentRequired
  ));
};

export const isUserShouldHaveLabel = (userStatus: UserStatuses): boolean => {
  return [UserStatuses.External, UserStatuses.Deleted].includes(userStatus); 
};
