import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import request from '../../models/request';
import { setIsLoadingData } from '../spinner/actions';
import {
  SET_PAYMENTS_OPTIONS_DATA,
  CLOSE_PAYMENT_OVERVIEW,
  SHOW_PAYMENT_OVERVIEW,
  UPDATE_PAYER_DETAILS,
  SYNC_PAYMENT_STATUS,
  SET_INVOICE_NUMBER,
  SET_PAYMENTS_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
} from './actionTypes';

const setPaymentsData = (data) => ({
  type: SET_PAYMENTS_DATA,
  payload: {
    data,
  },
});

export const showPaymentOverview = (payment) => ({
  type: SHOW_PAYMENT_OVERVIEW,
  payload: {
    payment,
  },
});

export const closePaymentOverview = () => ({
  type: CLOSE_PAYMENT_OVERVIEW,
});

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const fetchPayments = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const response = await request.get('payments', { params });
    dispatch(setPaymentsData(response.data));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

const setOptionsData = (data) => ({
  type: SET_PAYMENTS_OPTIONS_DATA,
  payload: {
    data,
  },
});

export const fetchOptions = () => async (dispatch) => {
  try {
    const response = await request.get('payments/options');
    dispatch(setOptionsData(response.data));
  } catch (e) {
    console.error(e);
  }
};

export const setInvoiceNumber = (paymentId: string, balancedIoInvoiceNumber: string) => ({
  type: SET_INVOICE_NUMBER,
  payload: {
    balancedIoInvoiceNumber,
    paymentId,
  },
});

export const syncPaymentStatus = ({
  balancedIoInvoiceNumber,
  externalStatus,
  status,
  id,
}: {
  balancedIoInvoiceNumber: string;
  externalStatus: string;
  status: PAYMENT_STATUS;
  id: string;
}) => ({
  type: SYNC_PAYMENT_STATUS,
  payload: {
    balancedIoInvoiceNumber,
    externalStatus,
    status,
    id,
  },
});

export const updatePayerDetails = ({
  payerRegistrationNumber,
  payerVatNumber,
  id,
}: {
  payerRegistrationNumber: string;
  payerVatNumber: string;
  id: string;
}) => ({
  type: UPDATE_PAYER_DETAILS,
  payload: {
    payerRegistrationNumber,
    payerVatNumber,
    id,
  },
});
