import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { fetchCompanyBusinessVerticals } from '../../../store/tools/businessVerticals/api';
import { BusinessVerticalType } from '../../../../src/enums/BusinessVerticalType';

export const removeTeam = (teamIds) => {
  return Promise.allSettled(teamIds.map((teamId) => {
    return request
      .delete(`tools/teams/${teamId}`)
      .then(() => {
        getErrorStatusMessage({
          status: 200,
          message: `Team with ID - ${teamId} successfully removed`,
        });
      })
      .catch((e) => {
        getErrorStatusMessage({
          status: e?.response?.status,
          message: `Team with ID - ${teamId} can't be removed`,
        });
      });
  }))
};

export const fetchCompaniesUsersByRole = async (companyId: number, role: 'travelopses' | 'managers') => {
  try {
    const { data } = await request.get(`tools/${role}?companyId=${companyId}`);
    return data.records.map((el) => ({ value: el.id, title: el.title, businessVerticals: el.businessVerticals }));
  } catch (error) {
    return [];
  }
};

export const fetchCompaniesDefaultTravelopses = async (companyId: number) => {
  try {
    const { data } = await request.get(`tools/companies/default-travelopses/${companyId}`);
    return data.map((el) => ({ value: el.id, title: `${el.firstname} ${el.lastname}`, businessVertical: el.businessVertical }));
  } catch (error) {
    return [];
  }
};

export const fetchCompaniesTripPurposes = async (companyId: number) => {
  try {
    const { data } = await request.get(`tools/companies/trip-purposes/${companyId}`);
    return data.map((tripPurpose) => ({ value: tripPurpose.id, title: tripPurpose.label }));
  } catch (error) {
    return [];
  }
};

export const fetchOptionsForCompany = async (companyId: number): Promise<{
  defaultTravelopses: any[];
  businessVerticals: any[];
  tripPurposes: any[];
  travelopses: any[],
  managers: any[];
}> => {
  const [defaultTravelopses, tripPurposes, travelopses, managers, businessVerticals ] = await Promise.all([
    fetchCompaniesDefaultTravelopses(companyId),
    fetchCompaniesTripPurposes(companyId),
    fetchCompaniesUsersByRole(companyId, 'travelopses'),
    fetchCompaniesUsersByRole(companyId, 'managers'),
    fetchCompanyBusinessVerticals(companyId),
  ]);

  return {
    defaultTravelopses,
    businessVerticals,
    tripPurposes,
    travelopses,
    managers,
  };
};