import Yup from '../../utils/validation';

const validateMessages = {
  currency: {
    required: 'Currency should be provided.',
  },
  company: {
    required: 'Company should be provided.',
  },
  options:{
    value: {
      required: 'Value should be provided.',
      min: 'Value has to be greater than 0.',
      max: 'Value cannot be greater than 10000.',
    },
  }
};

export const allowanceTypeSchema = Yup.object().shape({
  currency: Yup.string()
    .required(validateMessages.currency.required),
  company: Yup.string()
    .required(validateMessages.company.required),
});

export const allowanceOptionSchema = Yup.object().shape({
  options: Yup.array().of(Yup.object().shape({
    value: Yup.number()
      .typeError('Value has to be a number')
      .min(0, validateMessages.options.value.min)
      .max(10000, validateMessages.options.value.max)
      .required(validateMessages.options.value.required),
    description: Yup.string().min(0).max(255)
  }))
});