import {
  SET_ACTIONS_AND_NOTIFICATIONS,
  SHOW_ACTION_AND_NOTIFICATION_OVERVIEW,
  CLOSE_ACTION_AND_NOTIFICATION_OVERVIEW,
  SET_ACTION_AND_NOTIFICATION_LOGS,
  SET_TOOLS_ALL_ACTIONS_CHECKBOX_DATA,
  SET_TOOLS_ACTIONS_CHECKBOX_DATA,
  SET_TOOLS_IS_CHANGED_ACTION,
} from './actionTypes';

const initState = {
  actionsAndNotifications: [],
  totalActionsAndNotifications: 0,
  isOpenActionAndNotificationOverview: false,
  overviewActionAndNotificationData: null,
  actionAndNotificationLogs: [],
  actionsCheckboxesData: [],
  isActiveAllActionsCheckboxes: false,
  isChangedAction: false,
};

const actionsAndNotificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ACTIONS_AND_NOTIFICATIONS:
      return {
        ...state,
        actionsAndNotifications: action.payload.records,
        totalActionsAndNotifications: action.payload.total,
      };

    case SHOW_ACTION_AND_NOTIFICATION_OVERVIEW:
      return {
        ...state,
        isOpenActionAndNotificationOverview: true,
        overviewActionAndNotificationData: action.payload.data,
      };

    case CLOSE_ACTION_AND_NOTIFICATION_OVERVIEW:
      return {
        ...state,
        isOpenActionAndNotificationOverview: false,
        overviewActionAndNotificationData: null,
      };

    case SET_ACTION_AND_NOTIFICATION_LOGS:
      return {
        ...state,
        actionAndNotificationLogs: action.payload.records,
      };

    case SET_TOOLS_IS_CHANGED_ACTION:
      return {
        ...state,
        isChangedAction: action.payload.isChangedAction,
      };

    case SET_TOOLS_ALL_ACTIONS_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllActionsCheckboxes: isActive,
        actionsCheckboxesData: data,
      };
    }

    case SET_TOOLS_ACTIONS_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllActionsCheckboxes: false,
          actionsCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          actionsCheckboxesData: [...state.actionsCheckboxesData, data],
        };
      }

      return {
        ...state,
        actionsCheckboxesData: state.actionsCheckboxesData.filter((item) => item.purposeId !== data.purposeId),
        isActiveAllActionsCheckboxes: false,
      };
    }

    default:
      return state;
  }
};

export default actionsAndNotificationsReducer;
