import styled from 'styled-components';
import { Text as MoonText } from '@heathmont/moon-core';

export const OverviewWrapper = styled.div``;

export const IconBlock = styled.div`
  background: #f6f5f4;
  border-radius: 16px 0 0 16px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f6f5f4;
  border-radius: 0 16px 16px 0;
  margin-left: 4px;
  width: 100%;
  min-height: 64px;
  height: 100%;
`;

export const InfoBlockMultiItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 5px;
  width: 100%;
  padding-right: 30px;
`;

export const MergeIcon = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: calc(50% - 20px);
  color: #78787D;
`;

export const UpcomingItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover ${IconBlock}, &:hover ${InfoBlockWrapper} {
    background: #eaeaea !important;
  }

  &:hover ${MergeIcon} {
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 12px;
`;

export const TripInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const TripsInfo = styled.div`
  display: block;
  align-items: center;
  padding: 3px 0;
`;

export const TripType = styled.div`
  font-size: 12px;
  margin-left: 10px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px 0;
`;

export const Title = styled(MoonText).attrs({
  size: 12,
  color: '#181B1C',
})`
  line-height: 16px;
  margin: 0;
  font-weight: 400;
`;

export const TripMonth = styled(Title).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  display: flex;
  justify-content: flex-end;
`;

export const SubTitle = styled(Title).attrs({
  size: 12,
  color: '#78787D',
})`
  line-height: 16px;
  font-weight: 400;
  margin: 0;
`;

export const MergeButton = styled.div`
  background-color: #275adb;
  color: white;
  padding: 2px 5px;
  font-size: 14px;
  border-radius: 8px;
`;
