import { useMemo } from 'react';
import { TextInput, Text } from '@heathmont/moon-core';
import { FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import InfoBlock from '../InfoBlock';
import FormField from '../FormField';
import Dialog from '../Dialog';
import { DialogContent, FormItemLabel, FormItem } from './styles';
import { IDeleteTripPartDialog } from './types';
import { getInitialValues, initDeclineSchema } from './schema';

const DeleteTripPartDialog: React.FC<IDeleteTripPartDialog> = ({
  otherTransportToDelete,
  isConfirmationLoading,
  accommodationToDelete,
  flightToDelete,
  trip,
  handleConfirmDeleteClose,
  handleConfirmDelete,
}) => {
  const type = useMemo(() => {
    return flightToDelete
      ? 'flight'
      : accommodationToDelete
        ? 'accommodation'
        : 'other transport';
  }, [flightToDelete, accommodationToDelete, otherTransportToDelete]);

  const reference = useMemo(() => {
    return flightToDelete
      ? flightToDelete.reference
      : accommodationToDelete
        ? accommodationToDelete.reference
        : otherTransportToDelete.reference;
  }, [flightToDelete, accommodationToDelete, otherTransportToDelete]);

  const isLastPart = useMemo(() => {
    return (trip.flights.length + trip.accommodations.length + trip.otherTransports.length) <= 1;
  }, [trip]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: initDeclineSchema(isLastPart),
    validateOnMount: true,
    initialValues: getInitialValues(),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, setTouched } = formik;

  const onSubmit = () => {
    const declineReason = isLastPart ? values.declineReason : null;
    handleConfirmDelete(declineReason);
  };

  return (
    <Dialog
      submitButtonLabel='Confirm'
      isLoading={isConfirmationLoading}
      width={500}
      title={`Delete ${type} — [${reference}]`}
      isSubmitDisabled={!isEmpty(errors)}
      onSubmit={onSubmit}
      onClose={handleConfirmDeleteClose}
    >
      <DialogContent>
        {flightToDelete && !!flightToDelete.isMultiPassengers && 
          <InfoBlock
            text={`
              This flight belongs to the multi passengers booking. 
              In case of deleting this flight, actual flight booking will be cancelled in Amadeus.
            `}
          />
        }
        {isLastPart && (
          <InfoBlock text={`
              The trip does not include other or external flights, accommodations, or transport.
              The trip will be cancelled.
            `}
          />
        )}
        <Text size={16}>Are you sure you want to delete the {type}?</Text>

        {
          isLastPart && (
            <FormikProvider value={formik}>
              <FormItem>
                <FormItemLabel>Decline reason</FormItemLabel>
                <FormField
                  placeholder={'Specify decline reason'}
                  fieldName='declineReason'
                  component={TextInput}
                  inputSize='large'
                  fieldId='declineReason'
                  isError={touched?.declineReason && errors?.declineReason}
                  errors={touched?.declineReason && errors}
                  value={values.declineReason}
                  type='text'
                  onChange={({ target: { value } }) => setFieldValue('declineReason', value)}
                  onBlur={() => setFieldTouched('declineReason', true, false)}
                />
              </FormItem>
            </FormikProvider>
          )
        }
      </DialogContent>
    </Dialog>
  );
};

export default DeleteTripPartDialog;
