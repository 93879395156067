import React from 'react';
import { IFormikAddExternalForm } from '../types';
import { Formik } from 'formik';
import { AddExternalTripSchema } from '../schema';
import Dialog from '../../Dialog';
import AddExternalTripModalContent from '../index';
import { isEmpty } from 'lodash';

const initialValues: IFormikAddExternalForm = {
  isManagerVerificationRequired: false,
  otherTransports: [],
  accommodations: [],
  purposeDetails: '',
  traveler: null,
  purpose: null,
  flights: [],
};

const ExternalTripContentWrapper = ({ isLoading, handleSubmit, handleClose }) => {
  return (
    <Formik
      validateOnBlur={true}
      validationSchema={AddExternalTripSchema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }): void => {
        resetForm();
      }}
    >
      {({ values, errors, touched}: any): JSX.Element => {
        return (
          <Dialog
            withConfirmation
            submitButtonLabel="Save"
            width={600}
            isLoading={isLoading}
            title="Add external trip"
            isSubmitDisabled={!isEmpty(errors) || Object.values(touched).length < 1}
            onSubmit={() => handleSubmit(values)}
            onClose={handleClose}
          >
            <AddExternalTripModalContent/>
          </Dialog>
        );
      }}
    </Formik>
  );
}

export default ExternalTripContentWrapper;
