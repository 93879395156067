import { ITableFilters } from './types';
import Accordion from '../Accordion';
import { FiltersWrapper, Divider, Filters } from './styles';

const TableFilters: React.FC<ITableFilters> = ({ isOpenDatePicker, searchField, filters = [] }) => {
  return (
    <FiltersWrapper isOpenDatePicker={isOpenDatePicker}>
      {searchField}
      <Filters>
        {filters.map(({ title, openByDefault, component }, index) => {
          return [
            <Accordion key={index} title={title} openByDefault={openByDefault}>
              {component}
            </Accordion>,
            index !== filters.length - 1 && <Divider key={index + '_divider'} />,
          ];
        })}
      </Filters>
    </FiltersWrapper>
  );
};

export default TableFilters;
