import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import codes from './codes';
import { SuccessMessage } from '../../components/SuccessMessage';
import { ErrorMessage } from '../../components/ErrorMessage';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBgNew';

export const CompanyIntegrationInfo = () => {
  const { search } = useLocation();
  const { code } = queryString.parse(search);

  const renderForm = () => {
    if (+code >= 200 && +code < 300) {
      return (
        <SuccessMessage
          linkText={'Sign in'}
          heading={codes[+code]?.title}
          message={codes[+code]?.message}
          link={'/login'}
        />
      );
    }

    return (
      <ErrorMessage
        withReturnBtn
        errorMessage={codes[+code]?.message || 'Unexpected error!'}
        heading={codes[+code]?.title || 'Please contact administrator'}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Company integration information</title>
      </Helmet>

      <FullScreenRandomBg renderComponent={renderForm()} />
    </>
  );
};
