import styled from 'styled-components';

export const FormFieldWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const ItemFormWrapper = styled.div`
  flex-grow: 1;
  max-width: calc(100% - 50px);
`;

export const DeleteButton = styled.div`
  width: 40px;
`;
