import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'City',
    accessor: 'city',
    minWidth: 120,
    height: 70,
  },
  {
    Header: 'Hotel',
    accessor: 'hotel',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Dates',
    accessor: 'dates',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Purposes',
    accessor: 'purposes',
    disableSortBy: true,
    minWidth: 250,
    height: 70,
  },
  {
    Header: 'Company',
    accessor: 'company',
    minWidth: 120,
    height: 70,
  },
  {
    Header: 'Teams',
    accessor: 'teams',
    disableSortBy: true,
    minWidth: 250,
    height: 70,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    minWidth: 180,
    height: 70,
  },
  {
    Header: 'Status',
    accessor: 'status',
    minWidth: 100,
    height: 70,
  },
];
