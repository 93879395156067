import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioGroup } from '../../components/RadioGroup';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
        </span>
      );
    },
    SubCell: () => null,
  },
  {
    Header: 'Payment ID',
    accessor: 'id',
  },
  {
    Header: 'Payment type',
    accessor: 'paymentType',
  },
  {
    Header: 'Provider',
    accessor: 'provider',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Transaction ID (Key)',
    accessor: 'key',
  },
  {
    Header: 'Invoice #',
    accessor: 'balancedIoInvoiceNumber',
     minWidth: 125,
  },
  {
    Header: 'Order #',
    accessor: 'orderNumber',
    minWidth: 125,
  },
  {
    Header: 'Booking reference',
    accessor: 'bookingReference',
  },
  {
    Header: 'Details',
    accessor: 'description',
    disableSortBy: true,
    minWidth: 300,
  },
  {
    Header: 'Payer type',
    accessor: 'payerType',
  },
  {
    Header: 'Payer name',
    accessor: 'payerName',
  },
  {
    Header: 'Payer email',
    accessor: 'payerEmail',
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
  },
  {
    Header: 'Total paid, EUR',
    accessor: 'sum',
  },
];

export const getPaymentsFilters = (filters, options, toggleFilter, setFilter): any => {
  return [
    {
      title: 'Status',
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.statuses}
          data={options?.statuses}
          onSelect={(value) => toggleFilter('statuses', value)}
        />
      ),
    },
    {
      title: 'Type',
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.paymentTypes}
          data={options?.paymentTypes}
          onSelect={(value) => toggleFilter('paymentTypes', value)}
        />
      ),
    },
    {
      title: 'Payer type',
      openByDefault: true,
      component: (
        <RadioGroup
          withAllOption
          selectedValue={filters?.payerType}
          data={options.payerTypes}
          name="payerType"
          onSelect={(value) => setFilter('payerType', value)}
        />
      ),
    },
  ];
};
