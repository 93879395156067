import { useFormik } from 'formik';

const useCustomFormik = (initialValues, schema, handleSubmit, ...rest) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
    validationSchema: schema,
    validateOnBlur: true,
    enableReinitialize: true,
    ...rest,
  });

  return formik;
};

export default useCustomFormik;