import {
  SET_UPDATE_TOOLS_TEAMS_STATUS,
  SET_IS_OPEN_ADD_NEW_TEAM_MODAL,
  SET_TEAMS_ALL_CHECKBOX_DATA,
  SET_TOOLS_TEAMS_COMPANIES,
  CLOSE_DELETE_TEAMS_MODAL,
  SET_IS_UPDATED_TEAM_DATA,
  SHOW_DELETE_TEAMS_MODAL,
  SET_TEAMS_CHECKBOX_DATA,
  SET_TOOLS_TEAMS_OPTIONS,
  SET_TOOLS_TEAMS_DATA,
  CLOSE_TEAM_OVERVIEW,
  SHOW_TEAM_OVERVIEW,
} from './actionTypes';

const initState = {
  teams: [],
  totalTeams: 0,
  isOpenTeamsOverview: false,
  overviewTeamsData: null,
  isUpdateTeamsStatus: false,
  teamsCheckboxesData: [],
  isActiveAllTeamsCheckboxes: false,
  isDeleteTeamsModal: false,
  deletableRoles: [],
  isOpenAddNewTeam: false,
  teamsOptions: {
    travelopses: [],
    managers: [],
    accountants: [],
  },
  companies: [],
  isUpdatedTeamData: false,
  deletableTeams: [],
};

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TOOLS_TEAMS_DATA:
      return {
        ...state,
        teams: action.payload.records,
        totalTeams: action.payload.total,
      };

    case SHOW_TEAM_OVERVIEW:
      return {
        ...state,
        isOpenTeamsOverview: true,
        overviewTeamsData: action.payload.data,
        teamsOptions: {
          travelopses: [],
          managers: [],
          accountants: [],
        },
        teams: state.teams.map((team) => ({
          ...team,
          isSelected: team.id === action.payload.data.id,
        })),
      };

    case CLOSE_TEAM_OVERVIEW:
      return {
        ...state,
        isOpenTeamsOverview: false,
        overviewTeamsData: null,
        teams: state.teams.map((team) => ({ ...team, isSelected: false })),
      };

    case SET_UPDATE_TOOLS_TEAMS_STATUS:
      return {
        ...state,
        isUpdateTeamsStatus: action.payload.data,
      };

    case SET_TEAMS_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllTeamsCheckboxes: false,
          teamsCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          teamsCheckboxesData: [...state.teamsCheckboxesData, data],
        };
      }

      return {
        ...state,
        teamsCheckboxesData: state.teamsCheckboxesData.filter((item) => item.teamId !== data.teamId),
        isActiveAllTeamsCheckboxes: false,
      };
    }

    case SET_TEAMS_ALL_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllTeamsCheckboxes: isActive,
        teamsCheckboxesData: data,
      };
    }

    case SHOW_DELETE_TEAMS_MODAL:
      return {
        ...state,
        isDeleteTeamsModal: true,
        deletableTeams: action.payload.deletableTeams,
      };

    case CLOSE_DELETE_TEAMS_MODAL:
      return {
        ...state,
        isDeleteTeamsModal: false,
        deletableTeams: [],
      };

    case SET_IS_OPEN_ADD_NEW_TEAM_MODAL:
      return {
        ...state,
        isOpenAddNewTeam: action.payload,
        teamsOptions: {
          travelopses: [],
          managers: [],
          accountants: [],
        },
      };

    case SET_TOOLS_TEAMS_OPTIONS:
      const { data, optionName } = action.payload;

      if (!!data.length && optionName) {
        return {
          ...state,
          teamsOptions: {
            ...state.teamsOptions,
            [optionName]: data,
          },
        };
      }

      return {
        ...state,
        teamsOptions: {
          travelopses: [],
          managers: [],
          accountants: [],
        },
      };

    case SET_TOOLS_TEAMS_COMPANIES:
      const companies = action.payload.data.map((company) => ({
        id: company.id,
        label: company.name,
      }));

      return {
        ...state,
        companies,
      };

    case SET_IS_UPDATED_TEAM_DATA:
      if (action.payload.data) {
        return {
          ...state,
          isUpdatedTeamData: action.payload.data,
          teamsOptions: {
            travelopses: [],
            managers: [],
            accountants: [],
          },
        };
      }
      return {
        ...state,
        isUpdatedTeamData: action.payload.data,
      };

    default:
      return state;
  }
};

export default teamsReducer;
