export enum HOTEL_TYPES {
  RESORT = 'Resort',
  SANATORIUM = 'Sanatorium',
  GUESTHOUSE = 'Guesthouse',
  MINI_HOTEL = 'Mini-hotel',
  CASTLE = 'Castle',
  HOTEL = 'Hotel',
  BOUTIQUE_AND_DESIGN = 'Boutique_and_Design',
  APARTMENT = 'Apartment',
  COTTAGES_AND_HOUSES = 'Cottages_and_Houses',
  FARM = 'Farm',
  VILLAS_AND_BUNGALOWS = 'Villas_and_Bungalows',
  CAMPING = 'Camping',
  HOSTEL = 'Hostel',
  BNB = 'BNB',
  APART_HOTEL = 'Apart-hotel',
  GLAMPING = 'Glamping',
};

export enum HOTEL_TYPES_LABELS {
  RESORT = 'Resort',
  SANATORIUM = 'Sanatorium',
  GUESTHOUSE = 'Guesthouse',
  MINI_HOTEL = 'Mini-hotel',
  CASTLE = 'Castle',
  HOTEL = 'Hotel',
  BOUTIQUE_AND_DESIGN = 'Boutique and Design',
  APARTMENT = 'Apartment',
  COTTAGES_AND_HOUSES = 'Cottages and Houses',
  FARM = 'Farm',
  VILLAS_AND_BUNGALOWS = 'Villas and Bungalows',
  CAMPING = 'Camping',
  HOSTEL = 'Hostel',
  BNB = 'BNB',
  APART_HOTEL = 'Apart-hotel',
  GLAMPING = 'Glamping',
}

export const hotelTypesOptions = [
  {
    value: HOTEL_TYPES.RESORT,
    label: HOTEL_TYPES_LABELS.RESORT,
  },
  {
    value: HOTEL_TYPES.SANATORIUM,
    label: HOTEL_TYPES_LABELS.SANATORIUM,
  },
  {
    value: HOTEL_TYPES.GUESTHOUSE,
    label: HOTEL_TYPES_LABELS.GUESTHOUSE,
  },
  {
    value: HOTEL_TYPES.MINI_HOTEL,
    label: HOTEL_TYPES_LABELS.MINI_HOTEL,
  },
  {
    value: HOTEL_TYPES.CASTLE,
    label: HOTEL_TYPES_LABELS.CASTLE,
  },
  {
    value: HOTEL_TYPES.HOTEL,
    label: HOTEL_TYPES_LABELS.HOTEL,
  },
  {
    value: HOTEL_TYPES.BOUTIQUE_AND_DESIGN,
    label: HOTEL_TYPES_LABELS.BOUTIQUE_AND_DESIGN,
  },
  {
    value: HOTEL_TYPES.APARTMENT,
    label: HOTEL_TYPES_LABELS.APARTMENT,
  },
  {
    value: HOTEL_TYPES.COTTAGES_AND_HOUSES,
    label: HOTEL_TYPES_LABELS.COTTAGES_AND_HOUSES,
  },
  {
    value: HOTEL_TYPES.FARM,
    label: HOTEL_TYPES_LABELS.FARM,
  },
  {
    value: HOTEL_TYPES.VILLAS_AND_BUNGALOWS,
    label: HOTEL_TYPES_LABELS.VILLAS_AND_BUNGALOWS,
  },
  {
    value: HOTEL_TYPES.CAMPING,
    label: HOTEL_TYPES_LABELS.CAMPING,
  },
  {
    value: HOTEL_TYPES.HOSTEL,
    label: HOTEL_TYPES_LABELS.HOSTEL,
  },
  {
    value: HOTEL_TYPES.BNB,
    label: HOTEL_TYPES_LABELS.BNB,
  },
  {
    value: HOTEL_TYPES.APART_HOTEL,
    label: HOTEL_TYPES_LABELS.APART_HOTEL,
  },
  {
    value: HOTEL_TYPES.GLAMPING,
    label: HOTEL_TYPES_LABELS.GLAMPING,
  },
];
