import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  min-width: 608px;
  height: calc(100vw / 3.5);
  min-height: 448px;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 1356px) {
    height: calc(100vw / 2);
  }

  .recharts-responsive-container {
    min-width: calc(100vw / 2 - 75px) !important;
  }

  .recharts-legend-wrapper {
    left: -15px !important;
  }
`;

export const LeftChartSign = styled.span`
  position: absolute;
  transform: rotate(-90deg) translate(-50%, 0%);
  font-size: 10px;
  top: 44%;
  left: 5px;
`;

export const RightChartSign = styled.span`
  position: absolute;
  transform: rotate(90deg) translate(-50%, 0%);
  font-size: 10px;
  top: 56%;
  right: -22px;
`;

export const BottomChartSign = styled.span`
  position: absolute;
  font-size: 10px;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Period = styled(Text).attrs({
  size: 14,
  color: '#78787D',
})`
  line-height: 24px;
  margin: 0;
  font-weight: 400;
`;

export const Header = styled(Text).attrs({
  size: 24,
  color: '#181B1C',
})`
  line-height: 32px;
  margin: 0 0 28px;
  font-weight: 400;
`;
