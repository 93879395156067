import Yup from '../../utils/validation';

const validateMessages = {
  min: 'Field cannot be less than 2 characters',
  max: 'Field cannot be longer than 40 characters',
  details: {
    companyName: {
      required: 'Company name cannot be empty',
      min: 'Company name cannot be less than 2 characters',
      max: 'Company name cannot be longer than 40 characters',
    },
    contactEmail: {
      required: 'Contact email cannot be empty',
      valid: 'Contact email should be in format "user@example.com"',
    },
    accountantsChannel: {
      min: 'Accountants channel cannot be less than 2 characters',
      max: 'Accountants channel cannot be longer than 40 characters',
    },
    travelGuideline: {
      min: 'Travel guideline cannot be less than 2 characters',
      max: 'Travel guideline cannot be longer than 255 characters',
      url: 'Travel guideline must be a valid URL',
    },
  },
  teams: {
    min: 'Please, add 1 or more team members',
    required: 'Team cannot be empty',
  },
  employers: {
    min: 'Please, add 1 or more employer members',
    required: 'Employer cannot be empty',
  },
  managers: {
    min: 'Please, add 1 or more manager members',
    email: {
      required: 'Manager cannot be empty',
      valid: 'Manager should be in format "user@example.com"',
    },
  },
  integrations: {
    adminEmail: {
      required: 'Admin email cannot be empty',
      email: 'Admin email should be in format "user@example.com',
    },
    accessToken: {
      required: 'Access token cannot be empty',
      min: 'Access token cannot be less than 2 characters',
      max: 'Access token cannot be longer than 255 characters',
    },
    host: {
      required: 'Host cannot be empty',
      min: 'Host cannot be less than 2 characters',
      max: 'Host cannot be longer than 255 characters',
    },
    projectKey: {
      required: 'Project key cannot be empty',
      min: 'Project key cannot be less than 2 characters',
      max: 'Project key cannot be longer than 255 characters',
    },
  },
};

export const signUpCompanySchema = Yup.object().shape({
  details: Yup.object().shape({
    companyName: Yup.string()
      .min(2, validateMessages.details.companyName.min)
      .max(40, validateMessages.details.companyName.max)
      .withoutSpaces()
      .required(validateMessages.details.companyName.required),
    contactEmail: Yup.string()
      .email(validateMessages.details.contactEmail.valid)
      .required(validateMessages.details.contactEmail.required)
      .withoutSpaces(),
    accountantsChannel: Yup.string()
      .notRequired()
      .min(2, validateMessages.details.accountantsChannel.min)
      .max(40, validateMessages.details.accountantsChannel.max)
      .withoutSpaces()
      .nullable(),
    travelGuideline: Yup
      .string()
      .url(validateMessages.details.travelGuideline.url)
      .notRequired()
      .min(2, validateMessages.details.travelGuideline.min)
      .max(255, validateMessages.details.travelGuideline.max)
      .withoutSpaces()
      .nullable(),
  }),
  teams: Yup.array()
    .min(1, validateMessages.teams.min)
    .of(
      Yup.string()
        .min(2, validateMessages.min)
        .max(40, validateMessages.max)
        .withoutSpaces()
        .required(validateMessages.teams.required)
    ),
  employers: Yup.array()
    .min(1, validateMessages.employers.min)
    .of(
      Yup.string()
        .min(2, validateMessages.min)
        .max(40, validateMessages.max)
        .withoutSpaces()
        .required(validateMessages.employers.required)
    ),
  managers: Yup.array()
    .min(1, validateMessages.managers.min)
    .of(
      Yup.string()
        .email(validateMessages.managers.email.valid)
        .withoutSpaces()
        .required(validateMessages.managers.email.required)
    ),
  integrations: Yup.object().shape({
    jira: Yup.object()
      .shape({
        adminEmail: Yup.string()
          .email(validateMessages.integrations.adminEmail.email)
          .withoutSpaces()
          .required(validateMessages.integrations.adminEmail.required),
        accessToken: Yup.string()
          .required(validateMessages.integrations.accessToken.required)
          .min(2, validateMessages.integrations.accessToken.min)
          .max(255, validateMessages.integrations.accessToken.max)
          .withoutSpaces(),
        host: Yup.string()
          .required(validateMessages.integrations.host.required)
          .matches(/^(?!\s+$)/, validateMessages.integrations.host.required)
          .min(2, validateMessages.integrations.host.min)
          .max(255, validateMessages.integrations.host.max)
          .withoutSpaces(),
        projectKey: Yup.string()
          .required(validateMessages.integrations.projectKey.required)
          .matches(/^(?!\s+$)/, validateMessages.integrations.projectKey.required)
          .min(2, validateMessages.integrations.projectKey.min)
          .max(255, validateMessages.integrations.projectKey.max)
          .withoutSpaces(),
      })
      .nullable(),
    hibob: Yup.object()
      .shape({
        accessToken: Yup.string()
          .required(validateMessages.integrations.accessToken.required)
          .min(2, validateMessages.integrations.accessToken.min)
          .max(255, validateMessages.integrations.accessToken.max)
          .withoutSpaces(),
      })
      .nullable(),
  }),
});
