import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 24px;

  ${({ isOpenDatePicker }) =>
    !isOpenDatePicker &&
    `
      position: relative;
   `}
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

export const Filters = styled.div`
  & [class^='CheckboxCaption']:before {
    top: 33%;
  }

  & [class^='RadioButtonLabel']:before {
    margin-bottom: 33% !important;
  }

  & [class^='CheckboxLabel'] {
    display: block;
  }
`;
