import styled from 'styled-components'

export const Wrapper = styled.div`
  max-height: 70vh;
  padding: 0px 10px;
  overflow-y: scroll;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AddButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  margin-bottom: 8px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ shouldBeScrollable }) => (shouldBeScrollable ? '60vh' : null)};
  overflow-y: ${({ shouldBeScrollable }) => (shouldBeScrollable ? 'scroll' : null)};
  padding: 0 5px 10px 0;
`;

export const FormItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  width: 50%;
  color: #181b1c;
  text-align: start;
  
  ${({ fullWidth }) => fullWidth && `
    flex-shrink: 0;
  `}

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}

  .text-input {
    input {
      height: 48px;
    }
  }
`;

export const FormItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 164px;
  width: 100%;
  align-self: ${({ isTraveler }) => (isTraveler ? 'center' : 'start')};
`;

export const FormItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

export const Icons = styled.div`
  align-self:center;
  display: flex;
`;

export const Icon = styled.div`
  cursor: pointer;
  align-self: center;
  color: #181B1C;

  &:hover {
    background-color: #181B1C12;
    border-radius: 10px;
  }
`;

export const PriceLabel = styled.span`
  color: #78787d;
  font-size: 12px;
  margin-bottom: -5px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  & button {
    margin-left: 8px;
  }
`;

export const ButtonsWrapperTrip = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;

  & button {
    margin-left: 8px;
  }
`;

export const FlightContent = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlightSegmentExtraMeta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px; 
  margin-top: 5px;
`;

export const FlightSegmentExtraMetaItem = styled.div`
  width: 100%;
`;

export const FlightSegments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FlightInfoBlock = styled.div`
  display: flex;
  gap: 10px;
  position:relative;
  justify-content: space-between;
  background: #f6f5f4;
  border-radius: 16px;
  width: 100%;
  margin: 10px 0;
  padding: 17px 12px;

  .flight-dates{
    color: #78787D;
  }

  .icon{
    width: 10%;
  }
`;
export const HotelInputWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  > *{ 
    width: 100%;
  }
`;

export const FormItemLabelWrapper = styled.span`
  display: inline-block;
  margin: -10px 0;
`;

export const FormItemLabel = styled.span`
  color: #78787d;
  font-size: 12px;
  width: 50%;
  display: inline-block;
`;

export const ItineraryTitleWrapper = styled.div`
  width: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  width: 24px;
  margin-top: -14px;
`;

export const DeleteButtonWrapper = styled.div`
  flex-shrink: 0;
`;
