import { TabWrapper, TabButton, TabButtonIcon } from '../../styled';
import { FormTabType } from '../../enums/FormTabType';
import useIsMobile from '../../hooks/useIsMobile';

const SignUpFormTabs = ({ activeTab, checkedTabs }) => {
  const isMobile = useIsMobile();

  const stepOneChecked = checkedTabs.includes(FormTabType.AccountDetails);
  const stepTwoChecked = checkedTabs.includes(FormTabType.ProfileSettings);
  const stepThreeChecked = checkedTabs.includes(FormTabType.TravelDocument);
  const stepFourChecked = checkedTabs.includes(FormTabType.ChangePassword);

  return (
    <TabWrapper isMobile={isMobile}>
      <TabButton
        isMobile={isMobile}
        type="button"
        checked={stepOneChecked}
        active={activeTab === FormTabType.AccountDetails}
        disabled
      >
        <TabButtonIcon checked={stepOneChecked} active={activeTab === FormTabType.AccountDetails}>
          <span>{FormTabType.AccountDetails}</span>
        </TabButtonIcon>
        Account details
      </TabButton>

      <TabButton
        isMobile={isMobile}
        type="button"
        checked={stepTwoChecked}
        active={activeTab === FormTabType.ProfileSettings}
        disabled
      >
        <TabButtonIcon checked={stepTwoChecked} active={activeTab === FormTabType.ProfileSettings}>
          <span>{FormTabType.ProfileSettings}</span>
        </TabButtonIcon>
        Profile settings
      </TabButton>

      <TabButton
        isMobile={isMobile}
        type="button"
        checked={stepThreeChecked}
        active={activeTab === FormTabType.TravelDocument}
        disabled
      >
        <TabButtonIcon checked={stepThreeChecked} active={activeTab === FormTabType.TravelDocument}>
          <span>{FormTabType.TravelDocument}</span>
        </TabButtonIcon>
        Travel document
      </TabButton>

      <TabButton
        isMobile={isMobile}
        type="button"
        checked={stepThreeChecked}
        active={activeTab === FormTabType.ChangePassword}
        disabled
      >
        <TabButtonIcon checked={stepFourChecked} active={activeTab === FormTabType.ChangePassword}>
          <span>{FormTabType.ChangePassword}</span>
        </TabButtonIcon>
        Password
      </TabButton>
    </TabWrapper>
  );
};

export default SignUpFormTabs;
