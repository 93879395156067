import styled from 'styled-components';

export const TabNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabName = styled.span`
  margin-top: 8px;
`;
