import { useEffect, useCallback, useState } from 'react';
import { GenericEdit, ControlsCloseSmall, GenericCheckAlternative } from '@heathmont/moon-icons';
import { TextInput, Text } from '@heathmont/moon-core';
import { HiddenRolePopoverBackground } from '../../styled';
import { getLabels } from '../../utils/tableHelpers';
import { IRolesOverviewDrawerContent } from './types';
import ChangePagesPopover from '../ChangePagesPopover';
import PopoverDialog from '../PopoverDialog';
import {
  OverviewContent,
  OverviewContentName,
  OverviewContentValue,
  EditRoleNameIcon,
  InputWrapper,
  InputIconsGroup,
  Icon,
} from './styles';
import Button from '../Button';
import { UserRoleType } from '../../enums/UserRoleTypes';
import { сheckOnSpaces } from '../../utils/commonFunctions';

const RolesOverviewDrawerContent: React.FC<IRolesOverviewDrawerContent> = ({
  id,
  amount,
  allPages,
  usersPages,
  roleNameData,
  currentRoleName,
  handleChangeOverviewRoleData,
  handleUpdateRole,
  isDefaultRole,
}): JSX.Element => {
  const [isOpenPopoverDialog, setIsOpenPopoverDialog] = useState(false);
  const [isOpenChangeRoleNamePopover, setIsOpenChangeRoleNamePopover] = useState(false);

  useEffect(() => {
    handleChangeOverviewRoleData({ name: currentRoleName.toLowerCase(), pages: usersPages, id, isOpen: false });
  }, [currentRoleName, id, usersPages]);

  const handleCloseChangeRoleName = useCallback(() => {
    setIsOpenChangeRoleNamePopover(false);
  }, []);

  const handleCloseRoleDialog = useCallback((isOpen) => {
    setIsOpenPopoverDialog(isOpen);
  }, []);

  const handleUpdateRoleData = useCallback(() => {
    handleUpdateRole({ ...roleNameData, name: roleNameData.name.trim() });

    setIsOpenPopoverDialog(false);
    setIsOpenChangeRoleNamePopover(false);
  }, [roleNameData]);

  const handleAddPages = useCallback(
    (selectedPages) => {
      handleUpdateRole({ ...roleNameData, pages: selectedPages });
      handleCloseRoleDialog(false);
      handleCloseChangeRoleName();
    },
    [roleNameData, handleChangeOverviewRoleData]
  );

  return (
    <>
      {(isOpenPopoverDialog || isOpenChangeRoleNamePopover) && (
        <HiddenRolePopoverBackground
          onClick={() => {
            handleCloseRoleDialog(false);
            setIsOpenChangeRoleNamePopover(false);
          }}
        />
      )}

      <div>
        <OverviewContent>
          <OverviewContentName>ID</OverviewContentName>
          <OverviewContentValue>{id}</OverviewContentValue>
        </OverviewContent>

        <OverviewContent>
          <OverviewContentName>Amount</OverviewContentName>
          <OverviewContentValue>{amount}</OverviewContentValue>
        </OverviewContent>

        <OverviewContent>
          <OverviewContentName>Role name</OverviewContentName>

          {!roleNameData.isOpen && (
            <OverviewContentValue
              withHover={!isDefaultRole && !roleNameData.isOpen}
              onClick={() => !isDefaultRole && handleChangeOverviewRoleData({ isOpen: true })}
            >
              {currentRoleName}

              {!isDefaultRole && (
                <EditRoleNameIcon>
                  <GenericEdit />
                </EditRoleNameIcon>
              )}
            </OverviewContentValue>
          )}

          {roleNameData.isOpen && !isDefaultRole && (
            <OverviewContentValue withHover={!roleNameData.isOpen}>
              <InputWrapper>
                <TextInput
                  onChange={(event) => handleChangeOverviewRoleData({ name: event.target.value.toLowerCase() })}
                  onClick={() => setIsOpenPopoverDialog(false)}
                  value={roleNameData.name}
                  type="text"
                  inputSize="small"
                  isError={!roleNameData?.name}
                  hintText={!roleNameData?.name && <span>Field name can't be empty</span>}
                />

                <InputIconsGroup>
                  <Icon>
                    <ControlsCloseSmall
                      fontSize="1.5rem"
                      onClick={() => handleChangeOverviewRoleData({ isOpen: false })}
                    />
                  </Icon>

                  {(roleNameData?.name !== currentRoleName.toLowerCase() && !сheckOnSpaces(roleNameData?.name)) && (
                    <Icon>
                      <GenericCheckAlternative fontSize="1.5rem" onClick={() => setIsOpenChangeRoleNamePopover(true)} />
                    </Icon>
                  )}
                </InputIconsGroup>
              </InputWrapper>
            </OverviewContentValue>
          )}
        </OverviewContent>

        <PopoverDialog
          isOpen={isOpenChangeRoleNamePopover}
          handleClose={handleCloseChangeRoleName}
          handleAgree={handleUpdateRoleData}
          header="Change role name"
        >
          <div>
            <Text size={14}>Are you sure to change role name?</Text>
          </div>
        </PopoverDialog>

        <div>
          <OverviewContent>
            <OverviewContentName>Pages</OverviewContentName>

            {!!usersPages.length ? (
              <OverviewContentValue
                isActive={isOpenPopoverDialog}
                withHover={!isDefaultRole}
                onClick={() => !isDefaultRole && handleCloseRoleDialog(true)}
              >
                {getLabels(usersPages)}
              </OverviewContentValue>
            ) : (
              <OverviewContentValue>
                {isDefaultRole && !usersPages.length ? (
                  <Text size={14} style={{ margin: 0 }}>
                    No available pages
                  </Text>
                ) : (
                  <Button label="Add pages" handleClick={() => handleCloseRoleDialog(true)} variant="tertiary" />
                )}
              </OverviewContentValue>
            )}
          </OverviewContent>

          {isOpenPopoverDialog && !isDefaultRole && (
            <ChangePagesPopover
              usersPages={usersPages}
              allPages={allPages}
              handleAgree={(selectedPages) => handleAddPages(selectedPages)}
              handleClose={handleCloseRoleDialog}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RolesOverviewDrawerContent;
