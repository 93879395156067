export enum TripStatusType {
  DECLINED,
  PENDING,
  PURCHASED,
  APPROVED,
}

export const tripStatusNames = {
  [TripStatusType.DECLINED]: 'DECLINED',
  [TripStatusType.PENDING]: 'PENDING',
  [TripStatusType.PURCHASED]: 'PURCHASED',
  [TripStatusType.APPROVED]: 'APPROVED',
};
