import { useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { ControlsPlus, ControlsClose, ControlsCloseSmall } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import InfoBlock from '../InfoBlock';
import FileViewer from '../FileViewer';
import { RECEIPTS_SETTINGS } from '../../constants';
import { convertBytesToMbs, getErrorTest } from './utils';
import { IAddNewReceiptModal } from './types';
import {
  PreviewImagesWrapper,
  HeaderWrapper,
  ImageWrapper,
  HeaderTitle,
  FileWrapper,
  IconWrapper,
  ErrorStyled,
  BodyWrapper,
  RemoveIcon,
  CloseIcon,
  Wrapper,
  Footer,
} from './styles';

const AddNewReceiptModal: React.FC<IAddNewReceiptModal> = ({ isProgress, handleAgree, handleClose }) => {
  const [error, setError] = useState(null);
  const [receipts, setReceipts] = useState([]);

  const handleSelectImage = (imageList: ImageListType) => {
    setError(null);
    setReceipts(imageList as never[]);
  };

  const handleOnError = (error) => {
    setError(error);
  };

  const onCLoseModal = () => {
    return !isProgress && handleClose(false);
  };

  const onSubmitModal = () => {
    return handleAgree(receipts.map((image) => image.file));
  };

  const isUpdateReceipt = useSelector((state: any) => state.expensesReducer.isUploadingReceipts);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>Add receipts</HeaderTitle>
        <CloseIcon onClick={onCLoseModal}>
          <ControlsClose fontSize="1.5rem" />
        </CloseIcon>
      </HeaderWrapper>

      <BodyWrapper>
        <InfoBlock
          text={`Add image or PDF, max size ${convertBytesToMbs(RECEIPTS_SETTINGS.MAX_FILE_SIZE)}Mb.`}
        />

        <ImageUploading
          allowNonImageType
          maxFileSize={RECEIPTS_SETTINGS.MAX_FILE_SIZE}
          acceptType={RECEIPTS_SETTINGS.ACCEPTED_TYPES}
          maxNumber={RECEIPTS_SETTINGS.MAX_LENGTH}
          multiple
          value={receipts} 
          onChange={handleSelectImage}
          onError={handleOnError}
        >
          {({ imageList, onImageUpload, onImageRemove }) => (
            <PreviewImagesWrapper>
              {imageList.map((receipt, index) => (
                <ImageWrapper key={index}>
                  <RemoveIcon onClick={() => onImageRemove(index)}>
                    <ControlsCloseSmall />
                  </RemoveIcon>
                  <FileWrapper>
                    <FileViewer
                      preview
                      type={receipt.file.type}
                      url={receipt.dataURL}
                    />
                  </FileWrapper>
                </ImageWrapper>
              ))}

              <IconWrapper disabled={isUpdateReceipt} onClick={() => !isUpdateReceipt && onImageUpload()}>
                <ControlsPlus fontSize="2rem" />
              </IconWrapper>
            </PreviewImagesWrapper>
          )}
        </ImageUploading>

        {error && <ErrorStyled>{getErrorTest(error)}</ErrorStyled>}
      </BodyWrapper>

      <Footer>
        <Button disabled={isProgress} variant="secondary" onClick={onCLoseModal}>Cancel</Button>
        <Button disabled={!receipts.length || isProgress} variant="primary" onClick={onSubmitModal}>Save</Button>
      </Footer>
    </Wrapper>
  );
};

export default AddNewReceiptModal;
