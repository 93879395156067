import { useState } from 'react';

export const useCustomTable = (totalItems, perPage) => {
  const [page, setPage] = useState(1);

  const nextPage = () => {
    if (page >= Math.ceil(totalItems.current / perPage)) {
      return;
    }
    setPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (page === 1) {
      return;
    }
    setPage((prev) => prev - 1);
  };

  return {
    perPage,
    page,
    nextPage,
    prevPage,
    setPage,
  };
};
