export enum REQUEST_PAYMENT_REASON {
  ADDITIONAL_SERVICE_FEE = 'additional_service_fee',
  CHANGE_FEE = 'change_fee',
  OTHER = 'other',
};

export enum REQUEST_PAYMENT_REASON_LABEL {
  ADDITIONAL_SERVICE_FEE = 'Additional service fee',
  CHANGE_FEE = 'Change fee',
  OTHER = 'Other',
}

export const requestPaymentReasonOptions = [
  {
    value: REQUEST_PAYMENT_REASON.CHANGE_FEE,
    title: REQUEST_PAYMENT_REASON_LABEL.CHANGE_FEE,
  },
  {
    value: REQUEST_PAYMENT_REASON.ADDITIONAL_SERVICE_FEE,
    title: REQUEST_PAYMENT_REASON_LABEL.ADDITIONAL_SERVICE_FEE,
  },
  {
    value: REQUEST_PAYMENT_REASON.OTHER,
    title: REQUEST_PAYMENT_REASON_LABEL.OTHER,
  },
];
