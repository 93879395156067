import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TextInput } from '@heathmont/moon-core';
import { TextInputGroup, Select } from '@heathmont/moon-select';
import FormField from '../../../../../components/FormField';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import { IAccountDetails } from './types';
import { FieldWrapper, DoubleFieldWrapper, EmailSign } from './styled';
import { 
  filterItemsWithBusinessVerticalByVerticalId,
  filterItemsWithVerticalsByVerticalObjectId,
} from '../../../../../utils/filters';
import InfoBlock from '../../../../../components/InfoBlock';

const AccountDetails: React.FC<IAccountDetails> = ({ values, setFieldValue, options, initialValues }) => {
  const { email, company, employer, team, role, businessVertical } = values.details;

  const dispatch = useDispatch();
  const [employersByBusinessVertical, setEmployersByBusinessVertical] = useState([]);
  const [teamsByBusinessVertical, setTeamsByBusinessVertical] = useState([]);

  useEffect(() => {
    if (!checkOnIsEqual(values.details, initialValues.details)) {
      dispatch(setIsChangedTabField('details', true));
    } else {
      dispatch(setIsChangedTabField('details', false));
    }
  }, [values.details, initialValues.details]);

  const assignVerticalTeams = (teams, businessVerticalId) => {
    setTeamsByBusinessVertical(filterItemsWithBusinessVerticalByVerticalId(teams, businessVerticalId));
  };

  const assignVerticalEmployers = (employers, businessVerticalId) => {
    setEmployersByBusinessVertical(filterItemsWithVerticalsByVerticalObjectId(employers, businessVerticalId));
  };

  useEffect(() => {
    assignVerticalEmployers(options.employers, businessVertical);
    assignVerticalTeams(options.teams, businessVertical);
  }, [options]);

  const getCurrentTeam = (teams, team) => {
    return teams.find((item) => +item.value === team) || null;
  };

  const getCurrentEmployer = (employers, employer) => {
    return employers.find((item) => +item?.value === employer) || null;
  };

  useEffect(() => {
    if (employersByBusinessVertical?.length && !getCurrentEmployer(employersByBusinessVertical, employer)) {
      setFieldValue('details.employer', null);
    }
    if(teamsByBusinessVertical?.length && !getCurrentTeam(teamsByBusinessVertical, team)) {
      setFieldValue('details.team', null);
    }
  }, [teamsByBusinessVertical, employersByBusinessVertical, values.details]);

  const doesUserAndTeamVerticalMatch = useMemo(() => {
    const currentTeam = getCurrentTeam(teamsByBusinessVertical, team);
    const isMatch = currentTeam?.businessVerticals?.includes(businessVertical);

    if (isMatch) {
      return isMatch;
    }
    return isMatch;
  }, [team, businessVertical, teamsByBusinessVertical, options]);

  const doesUserAndEmployerVerticalMatch = useMemo(() => {
    const currentEmployer = getCurrentEmployer(employersByBusinessVertical, employer);
    if (!currentEmployer) {
      return false;
    }
    const [employerWithCurrentBusinessVertical] = filterItemsWithVerticalsByVerticalObjectId([currentEmployer],  businessVertical);

    if (employerWithCurrentBusinessVertical) {
      return true;
    }
    
    return false;
  }, [employer, businessVertical, employersByBusinessVertical, options]);

  return (
    <>
      {!doesUserAndTeamVerticalMatch && (
        <InfoBlock
          text={`Team's vertical doesn't match with user's vertical`}
        />
      )}

      {!doesUserAndEmployerVerticalMatch && (
        <InfoBlock
          text={`Employer's vertical doesn't match with user's vertical`}
        />
      )} 

      <FieldWrapper>
        <FormField
          value={email}
          fieldId="email"
          fieldName="email"
          component={TextInput}
          type="email"
          inputSize="xlarge"
          onChange={(e) => setFieldValue('details.email', e.target.value)}
          errorPosition={-20}
          label="E-mail address"
          disabled
        />

        <EmailSign>We'll email you trip confirmations and receipts.</EmailSign>
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: {
              type: 'text',
              value: role,
              inputSize: 'xlarge',
              label: 'Role',
              disabled: true,
            },
            input2: {
              type: 'text',
              value: company,
              inputSize: 'xlarge',
              label: 'Company',
              disabled: true,
            },
          }}
        />
      </DoubleFieldWrapper>

      <FieldWrapper>
        <FormField
          value={(options.businessVerticals ?? []).find((item) => +item.value === businessVertical)}
          component={Select}
          size="xLarge"
          onChange={({ value }) => setFieldValue('details.businessVertical', +value)}
          label="Your business vertical"
          options={options.businessVerticals ?? []}
          isSearchable={true}
          isDisabled={true}
        />
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: {
              type: 'select',
              value: getCurrentEmployer(employersByBusinessVertical, employer),
              onChange: ({ value }) => setFieldValue('details.employer', +value),
              inputSize: 'xlarge',
              label: 'Your employer',
              options: employersByBusinessVertical,
              isSearchable: true,
              menuPlacement: 'top',
            },
            input2: {
              type: 'select',
              value: getCurrentTeam(teamsByBusinessVertical, team),
              onChange: ({ value }) => setFieldValue('details.team', +value),
              inputSize: 'xlarge',
              label: 'Your team',
              options: teamsByBusinessVertical,
              isSearchable: true,
              menuPlacement: 'top',
            },
          }}
        />
      </DoubleFieldWrapper>
    </>
  );
};

export default AccountDetails;
