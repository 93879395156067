import { CustomAccordion } from './styles';
import { IAccordion } from './types';

const Accordion: React.FC<IAccordion> = ({
  withoutPadding = false,
  openByDefault = true,
  headerContent = '',
  disableOpen = false,
  className = '',
  withArrow = true,
  children,
  title,
}) => {
  return (
    <CustomAccordion
      withoutPadding={withoutPadding}
      headerContent={headerContent}
      openByDefault={openByDefault}
      disableOpen={disableOpen}
      className={className}
      withArrow={withArrow}
      title={title}
    >
      {children}
    </CustomAccordion>
  );
};

export default Accordion;
