import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

const hoveredFieldStyle = `
  cursor: pointer;
  border: 2px solid #285adb;
  border-radius: 9px;
`;

export const OverviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
`;

export const EditRoleNameIcon = styled.span`
  margin-left: 5px;
`;

export const InputWrapper = styled.div`
  & input {
    font-size: 14px;
  }
`;

export const InputIconsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Icon = styled.span`
  cursor: pointer;
`;

export const OverviewContentName = styled(Text).attrs({
  size: 14,
  color: 'trunks.100',
})`
  line-height: 24px;
  max-width: 164px;
`;

export const OverviewContentValue = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
})`
  line-height: 24px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  max-width: 164px;
  border: 2px solid transparent;
  width: 160px;
  text-align: start;

  ${({ withHover }) =>
    withHover &&
    `
    padding: 5px;
    &:hover {${hoveredFieldStyle}}
  `}

  ${({ isActive }) => isActive && hoveredFieldStyle}
`;
