import React, { useState, useEffect } from 'react';
import { Text } from '@heathmont/moon-core';
import { ControlsCloseSmall, GenericCheckAlternative } from '@heathmont/moon-icons';
import { DashWrapper, Dash } from '../../styled';

const PasswordValidator = ({ password, setPasswordValid }) => {
  const [includesLength, setIncludesLength] = useState(false);
  const [includesUppercase, setIncludesUppercase] = useState(false);
  const [includesLowercase, setIncludesLowercase] = useState(false);
  const [includesDigit, setIncludesDigit] = useState(false);
  const [includesSpecialSymbol, setIncludesSpecialSymbol] = useState(false);

  const config = [
    { text: 'Password must contain 8 characters minimum', checked: includesLength },
    { text: 'Password must contain an Uppercase English character: A-Z', checked: includesUppercase },
    { text: 'Password must contain a Lowercase English character: a-z', checked: includesLowercase },
    { text: 'Password must contain a Digit: 0-9', checked: includesDigit },
    { text: 'Password must contain a Special character: !#$%&()*+-?~@=', checked: includesSpecialSymbol },
  ];

  useEffect(() => {
    setIncludesLength(password.length >= 8);
    setIncludesUppercase(/[A-Z]/.test(password));
    setIncludesLowercase(/[a-z]/.test(password));
    setIncludesDigit(/\d/.test(password));
    setIncludesSpecialSymbol(/(?=.*[!#$%&()*+\-\?\~@=])/.test(password));
    const passwordValid = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!#$%&()*+\-\?\~@=])(.{8,})$/.test(password);
    setPasswordValid(passwordValid);
  }, [password]);

  return (
    <>
      <DashWrapper>
        {config
          .reduce((acc, item) => {
            item.checked ? acc.unshift(item) : acc.push(item);
            return acc;
          }, [])
          .map(({ checked, text }, i, arr) => {
            const checkedArray = arr.filter(({ checked }) => checked);

            const checkedPercent = checkedArray.length / arr.length;

            return <Dash key={text} checked={checked} checkedPercent={checkedPercent} />;
          })}
      </DashWrapper>
      {!includesLength || !includesUppercase || !includesLowercase || !includesDigit || !includesSpecialSymbol ? (
        config.map(({ text, checked }, i) => (
          <Text key={i} size={12} color={`${checked ? 'roshi.100' : 'trunks.100'}`}>
            {checked ? <GenericCheckAlternative fontSize="1.2rem" /> : <ControlsCloseSmall fontSize="1.2rem" />} {text}
          </Text>
        ))
      ) : (
        <Text size={12} color="roshi.100">
          <GenericCheckAlternative fontSize="1.2rem" />
          Password is strong
        </Text>
      )}
    </>
  );
};

export default PasswordValidator;
