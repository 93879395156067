import { BUSINESS_VERTICAl_TYPES } from '../../constants';
import Yup from '../../utils/validation';

const validateMessages = {
  name: {
    min: 'Business vertical name cannot be less than 2 characters',
    max: 'Business vertical name cannot be longer than 255 characters',
    required: 'Business vertical name cannot be empty',
  },
  company: {
    required: 'Company cannot be empty'
  },
  travelopses: {
    min: 'At least one travelops should be added',
    max: 'Maximum 10 travelopses can be added',
  },
  accountants: {
    min: 'At least one accountant should be added',
    max: 'Maximum 25 accountants can be added',
  },
  ceos: {
    max: 'Maximum 3 CEO(s) can be added',
  },
};

export const businessVerticalSchema = Yup.object().shape({
  name: Yup.string()
    .required(validateMessages.name.required)
    .min(2, validateMessages.name.min)
    .max(255, validateMessages.name.max),
    accountants: Yup
    .array()
    .of(Yup.number())
    .min(1, validateMessages.accountants.min)
    .max(25, validateMessages.accountants.max),
  travelopses: Yup
    .array()
    .of(Yup.number())
    .min(1, validateMessages.travelopses.min)
    .max(10, validateMessages.travelopses.max),
    ceos: Yup
    .array()
    .of(Yup.number())
    .max(3, validateMessages.ceos.max)
    .nullable(),
});
