import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';
import maldives from '../../static/bg/maldives.png';

export const ImageText = styled(Text).attrs({
  size: 14,
  color: 'goku.100',
})`
  position: absolute;
  line-height: 1.7px;
  bottom: 24px;
  right: 24px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  z-index: 3;
`;

export const FullScreenBg = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ isMobile }) => isMobile ? 0 : '1rem 0'};
  z-index: 1;
  background-color: #18191c;
  overflow: hidden;

  &::after {
    width: 100vw;
    min-height: 100vh;
    content: '';
    position: absolute;
    background-image: url(${({ image }) => image || maldives});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    opacity: 1;
  }  
`;

export const Container = styled.div`
  display: flex;
  align-items: ${({ isMobile }) => isMobile ? 'end' : 'center'};
  max-width: 87rem;
  width: ${({ isMobile }) => isMobile ? '100%' : '95%'};
  height: 100%;
  margin: 0 auto;
  z-index: 3;
`;

export const HeadingCustom = styled.h1`
  color: ${({ theme }) => theme.color.goku['100']};

  ${({ isMobile }) => isMobile ? `
    position: absolute;
    max-width: 282px;
    top: 30%;
    right: 16px;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.501786px;
  ` : `
    font-size: 64px;
    max-width: 560px;
    margin-left: 12%;

    @media (max-width: 1199px) {
      display: none;
    }
  `}
`;
