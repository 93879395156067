import { connect } from 'react-redux';
import Companies from './index.ui';
import {
  updateCompanyBalancedStatus,
  updateCompanyBasicInfo,
  updateHibobIntegration,
  deleteHibobIntegration,
  updateJiraIntegration,
  deleteJiraIntegration,
  closeCompanyOverview,
  addHibobIntegration,
  openCompanyOverview,
  updateCompanyStatus,
  addJiraIntegration,
  fetchCompanies,
} from '../../../store/tools/companies/actions';

const mapStateToProps = ({ companiesReducer }) => ({
  isUpdatedCompanyData: companiesReducer.isUpdatedCompanyData,
  overviewCompanyData: companiesReducer.overviewCompanyData,
  isOverviewOpen: companiesReducer.isOverviewOpen,
  isLoadingData: companiesReducer.isLoadingData,
  companies: companiesReducer.companies,
});

const mapDispatchToProps = {
  updateCompanyBalancedStatus,
  updateCompanyBasicInfo,
  updateHibobIntegration,
  deleteHibobIntegration,
  updateJiraIntegration,
  deleteJiraIntegration,
  closeCompanyOverview,
  addHibobIntegration,
  openCompanyOverview,
  updateCompanyStatus,
  addJiraIntegration,
  fetchCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
