import {
  CLOSE_ALL_NOTIFICATION_ITEMS,
  SHOW_ALL_NOTIFICATION_ITEMS,
  CLOSE_UPCOMING_TRIPS,
  SHOW_UPCOMING_TRIPS,
  SET_UPCOMING_TRIPS,
  SET_NOTIFICATIONS,
  SET_GRAPHICS_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
  OPEN_FILTERS,
  SET_OPTIONS,
  SET_NUMBERS,
} from './actionTypes';

const initState = {
  options: {
    relativeDates: [],
    teams: [],
    employers: [],
    purposes: [],
  },
  numbers: [],
  notifications: {
    newFeedbacks: [],
    pendingTrips: [],
    approvedTrips: [],
    completedTrips: [],
    expiringTrips: [],
    bookedYesterday: [],
    underConsiderationExpenses: [],
    verifyingExpenses: [],
    expiredDocuments: [],
    subscriptions: [],
    tripsForMerging: [],
  },
  upcomingTrips: [],
  isFiltersOpen: false,
  isOpenUpcoming: false,
  isOpenNotification: false,
  isOpenNotificationData: {
    data: [],
    title: '',
    notificationType: '',
  },
  chartsData: {
    numbers: [],
    tripStatistics: [],
  },
};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_OPTIONS:
      const relativeDates = action.payload.data.relativeDates.filter(
        (item) => item.value !== 'today' && item.value !== 'yesterday'
      );
      const optionsData = { ...action.payload.data, relativeDates };

      return {
        ...state,
        options: optionsData,
      };

    case SET_NUMBERS:
      return {
        ...state,
        numbers: action.payload.data,
      };

    case SET_UPCOMING_TRIPS:
      return {
        ...state,
        upcomingTrips: action.payload.data,
      };

    case SHOW_ALL_NOTIFICATION_ITEMS:
      const { data, title, notificationType } = action.payload;
      return {
        ...state,
        isOpenNotification: true,
        isOpenUpcoming: false,
        isFiltersOpen: false,
        isOpenNotificationData: {
          data,
          title,
          notificationType,
        },
      };

    case CLOSE_UPCOMING_TRIPS: {
      return {
        ...state,
        isOpenUpcoming: false,
      };
    }

    case SHOW_UPCOMING_TRIPS: {
      return {
        ...state,
        isOpenNotification: false,
        isOpenUpcoming: true,
        isFiltersOpen: false,
        isOpenNotificationData: {
          data: [],
          title: '',
          notificationType: '',
        },
      };
    }

    case CLOSE_ALL_NOTIFICATION_ITEMS: {
      return {
        ...state,
        isOpenNotification: false,
      };
    }

    case OPEN_FILTERS: {
      return {
        ...state,
        isOpenNotification: false,
        isOpenUpcoming: false,
        isFiltersOpen: true,
        isOpenNotificationData: {
          data: [],
          title: '',
          notificationType: '',
        },
      };
    }

    case TOGGLE_FILTERS: {
      return {
        ...state,
        isOpenNotification: false,
        isOpenUpcoming: false,
        isFiltersOpen: !state.isFiltersOpen,
        isOpenNotificationData: {
          data: [],
          title: '',
          notificationType: '',
        },
      };
    }

    case CLOSE_FILTERS: {
      return {
        ...state,
        isFiltersOpen: false,
      };
    }

    case SET_NOTIFICATIONS: {
      const { notificationName, data } = action.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notificationName]: data,
        },
      };
    }

    case SET_GRAPHICS_DATA: {
      const { graphicName, data } = action.payload;

      return {
        ...state,
        chartsData: {
          ...state.chartsData,
          [graphicName]: data,
        },
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
