import { Formik } from 'formik';
import React, { SetStateAction, useEffect, useState } from 'react';
import { AddExternalAccommodationSchema } from '../schema';
import {
  HotelInputWrapper,
  FormItemsWrapper,
  ButtonsWrapper,
  FormWrapper,
  PriceLabel,
  FormItem,
} from '../styles';
import DatePicker from '../../../components/DatePicker';
import { Button, Text } from '@heathmont/moon-core';
import { isEmpty, debounce } from 'lodash';
import CustomSelect from '../../CustomSelect';
import FormField from '../../FormField';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '@heathmont/moon-components';
import request from '../../../models/request';
import { ISelectedFromAccommodation } from '../types';
import { fetchOptions } from '../../../store/editProfile/actions';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_MIN_LENGTH_TO_START_SEARCHING,
  DEFAULT_DEBOUNCE_MS_COUNT,
  EXTERNAL_REFERENCE_PREFIX,
} from '../../../constants';
import { getAccommodationInitialValues } from '../utils';

function ExternalAccommodation({
  selectedFromAccommodation,
  accommodationData,
  isAddExternalTrip = false,
  isEditMode = false,
  isLoading,
  setSelectedFromAccommodation,
  handleSubmit,
  handleClose,
}: {
  selectedFromAccommodation?: ISelectedFromAccommodation;
  isAddExternalTrip?: boolean;
  accommodationData?: any;
  isEditMode?: boolean;
  isLoading?: boolean;
  setSelectedFromAccommodation?: React.Dispatch<SetStateAction<ISelectedFromAccommodation>>;
  handleSubmit: (values) => void;
  handleClose: () => void;
}) {
  const isEditAccommodationMode = accommodationData?.editMode;
  const citiesOptionsInitState = [];
  const hotelsOptionsInitState = [];

  if(isEditAccommodationMode){
    const city = selectedFromAccommodation.cities.find(({ value }) => +value === +accommodationData.city);
    if(city){
      citiesOptionsInitState.push(city);
    }

    const hotel = selectedFromAccommodation.hotels.find(({ value }) => value === accommodationData.hotelId);
    if(hotel){
      hotelsOptionsInitState.push(hotel);
    }
  }

  const currenciesOptions = useSelector((state: any) => state.tripsReducer.tripOptions.currencies);
  const [citiesOptions, setCitiesOptions] = useState(citiesOptionsInitState);
  const [hotelsOptions, setHotelsOptions] = useState(hotelsOptionsInitState);
  const [isHotelsLoading, setIsHotelsLoading] = useState(false);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const getFormattedCities = (cities) => {
    return cities.map((city) => ({
      title: `${city.name} (${city.country})`,
      value: city.id,
    }));
  };

  const getFormattedHotels = ({hotels}) => {
    return hotels.map((hotel) => ({
      title: `${hotel.name}`,
      value: hotel.id,
    }));
  };

  const fetchCities = async (search, setFieldValue?) => {
    if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
      setCitiesOptions([]);
      setHotelsOptions([]);
      setIsCitiesLoading(true);
  
      try {
        const { data } = await request.get(`locations/cities`, { params: { search } });

        setCitiesOptions(getFormattedCities(data.airports || []));
        
        if (setFieldValue) {
          setFieldValue('city', null);
          setFieldValue('hotelId', null);
        }
      } catch (err) {
        setCitiesOptions([]);
      } finally {
        setIsCitiesLoading(false);
      }
    }
  };

  const fetchHotels = async (search, city, setFieldValue?) => {
    if (search.length >= DEFAULT_MIN_LENGTH_TO_START_SEARCHING) {
      setHotelsOptions([]);
      setIsHotelsLoading(true);

      try {
        const { data } = await request.get(`hotels`, { params: { search, regionId: city } });
        setHotelsOptions(getFormattedHotels(data || []));
        setFieldValue && setFieldValue('hotelId', null);
      } catch(err) {
        setHotelsOptions([]);
      } finally {
        setIsHotelsLoading(false); 
      }
    }
  };
  const handleAccommodationSubmit = (values, isAddExternalTrip) => {
    if (isAddExternalTrip) {
      const selectedCity = citiesOptions.find((city) => city.value === values.city);
      const selectedHotel = hotelsOptions.find((hotel) => hotel.value === values.hotelId);

      setSelectedFromAccommodation((prevState) => ({
        cities: selectedCity ? [...prevState.cities, selectedCity] : prevState.cities,
        hotels: selectedHotel ? [...prevState.hotels, selectedHotel] : prevState.hotels,
      }));
    }
    handleSubmit(values);
  };

  useEffect(() => {
    dispatch(fetchOptions(translate));
    if (accommodationData?.cityName) {
      fetchCities(accommodationData.cityName);
    }
    if (accommodationData?.hotelName || accommodationData?.regionId) {
      fetchHotels(accommodationData?.hotelName, accommodationData?.regionId);
    }
  }, []);

  return (
    <>
      <Formik
        validateOnBlur={true}
        validationSchema={AddExternalAccommodationSchema}
        initialValues={getAccommodationInitialValues(accommodationData, currenciesOptions)}
        onSubmit={(values, { resetForm }): void => {
          resetForm();
        }}
      >
        {({
          errors,
          values,
          resetForm,
          setFieldValue,
          touched,
          handleBlur,
          setFieldTouched,
          validateForm,
        }: any): JSX.Element => {
          return (
            <FormWrapper>
              {isAddExternalTrip && (
                <strong>
                  <Text size={16}>{!isEditMode ? "Add": "Update"} accommodation</Text>
                </strong>
              )}
              <FormItemsWrapper>
                <FormItem>
                  <DatePicker
                    placeholder='Select check in date'
                    showTimeSelect
                    isEditView={true}
                    errors={touched?.checkIn && errors}
                    value={values.checkIn}
                    field="checkIn"
                    onChange={(field, value) => {
                      setFieldTouched(field, true);
                      setFieldValue(field, value);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <DatePicker
                    placeholder='Select check out date'
                    showTimeSelect
                    isEditView={true}
                    minDate={values.checkIn}
                    errors={touched?.checkOut && errors}
                    value={values.checkOut}
                    field="checkOut"
                    onChange={(field, value) => {
                      setFieldTouched(field, true);
                      setFieldValue(field, value);
                    }}
                  />
                </FormItem>
              </FormItemsWrapper>
              <FormItemsWrapper>
                <FormItem>
                  <CustomSelect
                    minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                    currentValue={values.city}
                    isSearchable
                    placeholder='Select city'
                    isLoading={isCitiesLoading}
                    isError={touched.city}
                    items={citiesOptions}
                    onInputChange={debounce((search) => fetchCities(search, setFieldValue), DEFAULT_DEBOUNCE_MS_COUNT)}
                    onChange={(value) => {
                      setFieldValue('city', value);
                      setFieldValue('hotelId', null);
                    }}
                  />
                </FormItem>
                <HotelInputWrapper>
                  <FormItem>
                    <CustomSelect
                      minLengthToStartSearching={DEFAULT_MIN_LENGTH_TO_START_SEARCHING}
                      noOptionsMessage={`The hotel wasn't found. Please get in touch with the technical team to have it added to the system. Now you can choose another one and replace it with the one you need in the future.`}
                      isSearchable
                      currentValue={values.hotelId}
                      placeholder='Select hotel'
                      isDisabled={!values.city}
                      isLoading={isHotelsLoading}
                      isError={touched.hotelName}
                      error={(touched.hotelName && errors.hotelName)}
                      items={hotelsOptions}
                      onInputChange={debounce((search) => fetchHotels(search, values.city, setFieldValue), DEFAULT_DEBOUNCE_MS_COUNT)}
                      onChange={(value) => {
                        setFieldValue('hotelId', value)
                      }}
                    />
                  </FormItem>
                </HotelInputWrapper>
              </FormItemsWrapper>
              <PriceLabel>Price without margin</PriceLabel>
              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    value={values.priceWithoutAdditionalPercent}
                    fieldId="priceWithoutAdditionalPercent"
                    fieldName="priceWithoutAdditionalPercent"
                    component={TextInput}
                    className={'text-input'}
                    type="text"
                    placeholder={'Price without margin'}
                    inputSize="large"
                    onChange={(e) => setFieldValue('priceWithoutAdditionalPercent', e.target.value)}
                    onBlur={handleBlur}
                    isError={touched?.priceWithoutAdditionalPercent && errors?.priceWithoutAdditionalPercent}
                    errors={touched?.priceWithoutAdditionalPercent && errors}
                    errorPosition={-20}
                  />
                </FormItem>
                <FormItem>
                  <CustomSelect
                    isSearchable
                    isDisabled={true}
                    currentValue={values.currency}
                    placeholder="Select currency"
                    isError={!!(touched?.currency && errors?.currency)}
                    error={touched?.currency && errors?.currency}
                    items={currenciesOptions}
                    onChange={(value) => {
                      setFieldValue('currency', value);
                      setFieldTouched('currency', true, false);
                    }}
                  />
                </FormItem>
              </FormItemsWrapper>
              <PriceLabel>Reference</PriceLabel>
              <FormItemsWrapper>
                <FormItem>
                  <FormField
                    value={values.reference}
                    fieldId="reference"
                    fieldName="reference"
                    component={TextInput}
                    className={'text-input'}
                    type="text"
                    placeholder={'Reference'}
                    inputSize="large"
                    prefix={EXTERNAL_REFERENCE_PREFIX}
                    onChange={(e) => {
                      setFieldValue('reference', e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                </FormItem>
              </FormItemsWrapper>
              <ButtonsWrapper>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => handleAccommodationSubmit(values, isAddExternalTrip)}
                  {...(isLoading ? {animation:"progress"} : {})}
                  variant="primary"
                  disabled={!isEmpty(errors) || (!isEditMode && Object.values(touched).length < 1) || isLoading}
                >
                  {!isEditMode ? "Save": "Update"}
                </Button>
              </ButtonsWrapper>
            </FormWrapper>
          );
        }}
      </Formik>
    </>
  );
}

export default ExternalAccommodation;
