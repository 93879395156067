import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormikProvider } from 'formik';
import { ArrowsLeft, GenericLogOut, ControlsCloseSmall } from '@heathmont/moon-icons';
import { HeathmontLogo } from '../../../components/HeathmontLogo';
import EditProfileTabRoutes from '../../../components/Routes/EditProfileTabRoutes';
import { editProfileSchema } from '../schema';
import { isEditProfileSubmitButtonDisabled } from '../suggestions';
import { Button } from '@heathmont/moon-core';
import { getHomePagePathByRolePages } from '../../../utils/commonFunctions';
import { changeProfileEditData, setIsChangedTabField, setIsUpdateChanges } from '../../../store/editProfile/actions';
import MobileEditProfileTabs from './Tabs/MobileEditProfileTabs';
import useCustomFormik from '../../../hooks/useCustomFormik';
import { EDIT_PROFILE_TABS, TAB_NAMES } from '../../../components/Routes/suggestions';
import Dialog from '../../../components/Dialog';
import { Link } from '../DesktopEditProfile/styles';
import {
  MobileEditProfileStyled,
  TabFormContainerStyled,
  NotificationWrapper,
  MobileFormContainer,
  NotificationText,
  TabContentFields,
  TabsListStyled,
  ConfirmMessage,
  Notification,
  LogoWrapper,
  TabsStyled,
  Wrapper,
  Header,
  Footer,
} from './styles';

const MOBILE_TABS_MENU = '/edit-profile';

const ConfirmDialog = ({ label, secondButtonLabel, handleAgree, handleCloseDialog }) => (
  <Dialog
    submitButtonLabel={secondButtonLabel}
    title={label}
    onSubmit={handleAgree}
    onClose={handleCloseDialog}
    withoutMinWidth
  >
    <ConfirmMessage>You have unsaved changes. All changes will be lost. Do you want to continue?</ConfirmMessage>
  </Dialog>
);

const getInitialFormData = (values: any) => ({
  values: {
    ...values,
    security: {
      currentPassword: '',
      newPassword: '',
    },
  },
});

const MobileEditProfile = ({
  isValidNewPassword,
  initialValues,
  isUpdateError,
  currentUser,
  isUpdated,
  isGoAway,
  options,
  setIsGoAway,
  history: {
    push,
    location,
  },
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    await dispatch(changeProfileEditData(currentUser.user.id, values, formik.resetForm));
  };

  const formik = useCustomFormik(initialValues, editProfileSchema, handleSubmit);

  const handleReturnToBackOffice = useCallback(() => push(getHomePagePathByRolePages(currentUser?.pages)), []);

  const handleSubmitUnsaved = useCallback(() => {
    const initial = getInitialFormData(formik.values);
    const path = location.pathname.split('/');
    formik.resetForm(initial);
    dispatch(setIsChangedTabField(path[2], false));
    push(EDIT_PROFILE_TABS.MAIN);
    setIsGoAway(false);
  }, [isUpdated]);

  return (
    <>
      <Helmet>
        <title>Edit profile</title>
      </Helmet>

      <Wrapper>
        <MobileEditProfileStyled>
          <LogoWrapper>
            {location.pathname === EDIT_PROFILE_TABS.MAIN ? (
              <HeathmontLogo style={{ marginBottom: 0, width: '32px', height: '32px' }} />
            ) : (
              <Button
                variant="ghost"
                iconLeft={<ArrowsLeft />}
                onClick={() => (formik.dirty ? setIsGoAway((prev) => !prev) : push(EDIT_PROFILE_TABS.MAIN))}
              >
                Back
              </Button>
            )}

            {location.pathname === EDIT_PROFILE_TABS.MAIN && !!currentUser?.pages.length && (
              <Button
                variant="ghost"
                iconLeft={<ArrowsLeft />}
                onClick={handleReturnToBackOffice}
              >
                Return to back office
              </Button>
            )}
          </LogoWrapper>

          <Header>{TAB_NAMES[location.pathname]}</Header>

          {isUpdated && (
            <NotificationWrapper>
              <Notification>
                <NotificationText>Your account has been updated.</NotificationText>
                <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setIsUpdateChanges(false))} />
              </Notification>
            </NotificationWrapper>
          )}

          {isUpdateError.isError && (
            <NotificationWrapper>
              <Notification isUpdateError={isUpdateError}>
                <NotificationText>{isUpdateError.message}</NotificationText>
                <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setIsUpdateChanges(false))} />
              </Notification>
            </NotificationWrapper>
          )}

          {isGoAway && formik.dirty && (
            <ConfirmDialog
              label="Unsaved changes"
              handleCloseDialog={() => setIsGoAway((prev) => !prev)}
              secondButtonLabel="Discard changes"
              handleAgree={handleSubmitUnsaved}
            />
          )}

          {location.pathname === MOBILE_TABS_MENU ? (
            <TabsStyled>
              <TabsListStyled>
                <MobileEditProfileTabs push={push} />
              </TabsListStyled>
              <Link to="/logout">
                <Button
                  variant="ghost"
                  size="small"
                  iconLeft={<GenericLogOut fontSize="1.5rem" />}
                >Log out</Button>
              </Link>
            </TabsStyled>
          ) : (
            <MobileFormContainer>
              <FormikProvider value={formik}>
                <TabFormContainerStyled>
                  <TabContentFields>
                    <EditProfileTabRoutes
                      currentUser={currentUser}
                      formProps={formik}
                      options={options}
                    />              
                  </TabContentFields>
                  <Footer>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => handleSubmit(formik.values)}
                      disabled={isEditProfileSubmitButtonDisabled({
                        ...formik,
                        isValidNewPassword,
                        currentTab: location.pathname,
                      })}
                      fullWidth
                    >Save</Button>
                  </Footer>
                </TabFormContainerStyled>
              </FormikProvider>
            </MobileFormContainer>
          )}
        </MobileEditProfileStyled>
      </Wrapper>
    </>
  );
};

export default MobileEditProfile;
