import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const HiddenStatusBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow: auto;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  > div.togglers{
    gap: 10px;
    display: flex;
    button {
      height: 40px;
    }
  }
`;

export const TripsActionWrapper = styled.div`
  display: flex;

  button {
    height: 40px;
  }
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const DrawerWrapper = styled.div``;

export const ConfirmDialogBodyWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
`;

export const ConfirmMessage = styled(Text).attrs({
  size: 16,
  color: 'bulma.100',
})`
  line-height: 24px;
  margin: 0;
  padding: 0;
`;