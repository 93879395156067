import {
  SET_EMPLOYMENT_TYPES,
  SHOW_EMPLOYER_TYPE_OVERVIEW,
  CLOSE_EMPLOYER_TYPE_OVERVIEW,
  SHOW_DELETE_EMPLOYMENT_TYPES_MODAL,
  CLOSE_DELETE_EMPLOYMENT_TYPES_MODAL,
  SET_TOOLS_ALL_EMPLOYMENT_TYPES_CHECKBOX_DATA,
  SET_TOOLS_EMPLOYMENT_TYPES_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_EMPLOYMENT_TYPE,
  SET_IS_CHANGED_EMPLOYMENT_TYPE,
} from './actionTypes';

const initState = {
  employmentTypes: [],
  totalEmploymentTypes: 0,
  isOpenEmploymentTypeOverview: false,
  overviewEmploymentTypeData: null,
  isDeleteEmploymentTypesModal: false,
  deletableEmploymentTypes: [],
  employmentTypesCheckboxesData: [],
  isActiveAllEmploymentTypesCheckboxes: false,
  isOpenAddNewEmployerType: false,
  isChangedEmploymentType: false,
};

const employmentTypesReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EMPLOYMENT_TYPES:
      return {
        ...state,
        employmentTypes: action.payload.records,
        totalEmploymentTypes: action.payload.total,
      };

    case SHOW_EMPLOYER_TYPE_OVERVIEW:
      return {
        ...state,
        isOpenEmploymentTypeOverview: true,
        overviewEmploymentTypeData: action.payload.data,
      };

    case CLOSE_EMPLOYER_TYPE_OVERVIEW:
      return {
        ...state,
        isOpenEmploymentTypeOverview: false,
        overviewEmploymentTypeData: null,
      };

    case SHOW_DELETE_EMPLOYMENT_TYPES_MODAL:
      return {
        ...state,
        isDeleteEmploymentTypesModal: true,
        deletableEmploymentTypes: action.payload.data,
      };

    case CLOSE_DELETE_EMPLOYMENT_TYPES_MODAL:
      return {
        ...state,
        isDeleteEmploymentTypesModal: false,
        deletableEmploymentTypes: [],
      };

    case SET_TOOLS_ALL_EMPLOYMENT_TYPES_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllEmploymentTypesCheckboxes: isActive,
        employmentTypesCheckboxesData: data,
      };
    }

    case SET_TOOLS_EMPLOYMENT_TYPES_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllEmploymentTypesCheckboxes: false,
          employmentTypesCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          employmentTypesCheckboxesData: [...state.employmentTypesCheckboxesData, data],
        };
      }

      return {
        ...state,
        employmentTypesCheckboxesData: state.employmentTypesCheckboxesData.filter(
          (item) => item.employmentTypeId !== data.employmentTypeId
        ),
        isActiveAllEmploymentTypesCheckboxes: false,
      };
    }

    case SET_IS_OPEN_ADD_NEW_EMPLOYMENT_TYPE:
      return {
        ...state,
        isOpenAddNewEmployerType: action.payload,
      };

    case SET_IS_CHANGED_EMPLOYMENT_TYPE:
      return {
        ...state,
        isChangedEmploymentType: action.payload.isChanged,
      };

    default:
      return state;
  }
};

export default employmentTypesReducer;
