import styled from 'styled-components'
import { rem } from '@heathmont/moon-utils'

export const Container = styled.div((): any => ({
   display: 'flex',
   flexShrink: 0,
   alignItems: 'center',
   justifyContent: 'center',
   marginBottom: '-50px',
   overflowX: 'auto'
}))

export const List = styled.div({
   display: 'flex',
   overflow: 'auto',
   '&::-webkit-scrollbar': {
      display: 'none'
   }
})

export const ItemWrapper = styled.div<{ isActive?: boolean }>(
   ({ theme, isActive }) => ({
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      position: 'relative',
      padding: `${rem(5)} ${rem(25)}`,
      background: isActive ? 'rgb(245, 245, 245)' : 'none',
      borderRadius: rem(8),
      border: 'none',
      willChange: 'background',
      transition: `background ${theme.transitionDuration.default}s`,
      margin:' 0 10px',
      cursor: 'pointer',
   })
)

export const ColorPreview = styled.div`
   width: ${rem(8)};
   height: ${rem(8)};
   background: ${({ color }) => color};
   border-radius: 50%;
   position: absolute;
   top: ${rem(13)};
   left: 24px;
`;

export const Label = styled.div`
   font-size: ${rem(12)};
   line-height: ${rem(24)};
   color: #8697A2;
   white-space: nowrap;
   margin-left: 16px;
`;
