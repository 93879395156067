import styled from 'styled-components';

export const AdditionItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 4px -4px;
  height: 32px;

  svg {
    flex-shrink: 0;
  }
`;

export const AdditionItem = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #78787d;
  margin-left: 7px;
`;
