import moment from 'moment';
import { GenericHome, MapsPin } from '@heathmont/moon-icons';
import TripStepper from '../TripSteapper';
import { setTimeToDate } from '../../utils/commonFunctions';
import { AdditionItem, AdditionItemsWrapper } from '../../styled';
import { IAccommodationOrder } from './types';

const AccommodationOrder: React.FC<IAccommodationOrder> = ({ accommodation }) => {
  const duration = moment(accommodation.checkOut).diff(moment(accommodation.checkIn), 'days');

  return (
    <TripStepper
      startDate={setTimeToDate(accommodation.checkIn, accommodation.checkInTime)}
      startLabel="Check in"
      endDate={setTimeToDate(accommodation.checkOut, accommodation.checkOutTime)}
      endLabel="Check out"
      duration={`${duration}d`}
      withAccommodation
    >
      {accommodation.name && (
        <AdditionItemsWrapper>
          <GenericHome fontSize="1.5rem" />
          <AdditionItem>{accommodation.name}</AdditionItem>
        </AdditionItemsWrapper>
      )}

      {accommodation.address && (
        <AdditionItemsWrapper>
          <MapsPin fontSize="1.5rem" />
          <AdditionItem>{accommodation.address}</AdditionItem>
        </AdditionItemsWrapper>
      )}
    </TripStepper>
  );
};

export default AccommodationOrder;
