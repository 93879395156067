import styled from 'styled-components';

export const TabWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: ${({ height }) => (height ? `${height}px` : '380px')};
  padding: 0 40px;

  @media (max-width: 375px) {
    height: 210px;
  }

  @media (max-width: 414px) {
    height: 430px;
  }
`;

export const FormFieldWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const DoubleFieldWrapper = styled.div`
  margin: ${({ margin }) => margin || 0};
`;
