import React from 'react';
import { TextInputGroup } from '@heathmont/moon-select';
import FormField from '../../FormField';
import MoonSelect from '../../MoonSelect';
import { ITravelDocumentTab } from './types';
import { ErrorMessage, ErrorMessageWrapper } from '../../../pages/SignUp/styles';
import { DoubleFieldWrapper, TabWrapper } from '../styles';
import { FieldWrapper } from './styles';
import { changeFieldId } from '../../../pages/SignUp/suggestions';

const TravelDocumentTab: React.FC<ITravelDocumentTab> = ({
  values,
  errors,
  touched,
  setFieldValue,
  options,
  handleBlur,
}) => {
  const { issuedDocument, number, expirationDate } = values.document;

  const isErrorPassportNumber = !!errors?.document?.number && touched?.passportNumber;
  const isErrorIssuedDocument = !!errors?.document?.issuedDocument && touched?.issuedDocument;
  const isErrorExpirationDate = !!errors?.document?.expirationDate && touched?.passportExpirationDate;

  const passportNumberField = {
    type: 'text',
    value: number,
    onChange: ({ target: { value } }) => setFieldValue('document.number', value),
    inputSize: 'xlarge',
    label: 'Passport number',
    dateFormat: 'dd MMM yyyy',
    id: 'passportNumber',
    isError: isErrorPassportNumber,
    onBlur: handleBlur,
    disabled: options?.documentNumber?.isEditable,
  };

  const expirationDateField = {
    type: 'date',
    value: expirationDate,
    onChange: ({ target: { value } }) => setFieldValue('document.expirationDate', value),
    inputSize: 'xlarge',
    label: 'Passport expiration date',
    id: 'passportExpirationDate',
    isError: isErrorExpirationDate,
    onBlur: handleBlur,
    disabled: options?.documentValidTill?.isEditable,
  };

  return (
    <>
      <FieldWrapper>
        <FormField
          value={(options.country?.options ?? []).find((item) => +item.value === issuedDocument)}
          component={MoonSelect}
          size="xLarge"
          onChange={({ value }) => setFieldValue('document.issuedDocument', +value)}
          label="Country issued document"
          placeholderSlot="Select issued country"
          options={options.country?.options ?? []}
          onBlur={handleBlur}
          refHandler={(ref) => ref && changeFieldId(ref, 'issuedDocument')}
          isError={isErrorIssuedDocument}
          maxMenuHeight={170}
          isSearchable
        />

        {isErrorIssuedDocument && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.issuedDocument}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </FieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          type="text"
          inputProps={{
            input1: passportNumberField,
            input2: expirationDateField,
          }}
        />

        {isErrorPassportNumber && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.number}</ErrorMessage>
          </ErrorMessageWrapper>
        )}

        {isErrorExpirationDate && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.expirationDate}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </>
  );
};

export default TravelDocumentTab;
