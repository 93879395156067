import { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { saveAs } from 'file-saver';
import { TextInput, Text, Button } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { Form, Formik } from 'formik';
import {
  getCryptoPaymentStatusLabel,
  getCardPaymentStatusLabel,
  getPaymentStatusLabel,
  formatDateAndTime,
  getOrderNumber,
} from '../../../utils/tableHelpers';
import { N_A, checkOnIsEqual, getErrorStatusMessage } from '../../../utils/commonFunctions';
import Accordion from '../../../components/Accordion';
import Dialog from '../../../components/Dialog';
import { IPaymentDrawerContent } from './types';
import {
  OverviewItemsWrapper,
  ActionButtonsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
  Link,
} from './styles';
import CopyToClipboard from '../../../components/CopyToClipboard';
import CustomSelect from '../../../components/CustomSelect';
import FormField from '../../../components/FormField';
import { PAYMENT_TYPE, paymentTypeOptions } from '../../../enums/PaymentTypes';
import { PAYMENT_PROVIDER } from '../../../enums/PaymentProviders';
import PaymentProviderIcon from '../../../components/PaymentProviderIcon';
import InfoBlock from '../../../components/InfoBlock';
import EllipsisText from '../../../components/EllipsisText';
import {
  CreditCardOrderStatusDescription,
  CreditCardOrderStatus,
} from '../../../enums/CreditCardOrder';
import {
  CryptoTransactionStatusDescription,
  CryptoTransactionStatus,
} from '../../../enums/CryptoTransaction';
import { PAYMENT_STATUS, paymentStatusOptions } from '../../../enums/PaymentStatus';
import { PAYER_TYPE, payerTypeOptions } from '../../../enums/PayerTypes';
import { payerDocumentTypeOptions } from '../../../enums/PayerDocumentTypes';
import { UserRoleType } from '../../../enums/UserRoleTypes';
import request from '../../../models/request';
import { getInitialValues, payerSchema } from './schema';
import { ArrowsRefreshRound } from '@heathmont/moon-icons-tw';

const PaymentDrawerContent: React.FC<IPaymentDrawerContent> = ({
  currentUserData,
  payment,
  updatePayerDetails,
  syncPaymentStatus,
  setInvoiceNumber,
}) => {
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState<boolean>(false);
  const [isInvoiceCreationLoading, setIsInvoiceCreationLoading] = useState<boolean>(false);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const [isActualizeStatusLoading, setIsActualizeStatusLoading] = useState<boolean>(false);
  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState<boolean>(false);
  const [isEditPayerFormData, setIsEditPayerFormData] = useState<boolean>(false);
  const initialPayerValues = useMemo(() => getInitialValues(payment), [payment]);
  const [isPayerDetailsChangeLoading, setIsPayerDetailsChangeLoading] = useState<boolean>(false);

  const goToTrip = (reference) => {
    window.open(`/trips?search=${encodeURIComponent(reference)}`, '_blank');
  };

  const handleInvoiceCreation = async () => {
    try {
      setIsInvoiceCreationLoading(true);
      const { data } = await request.post(`/payments/invoice/draft/${payment.id}`);

      getErrorStatusMessage({
        status: 200,
        message: 'The payment invoice created successfully.',
      });
      setInvoiceNumber(payment.id, data.balancedIoInvoiceNumber);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsCreateInvoiceModalOpen(false);
      setIsInvoiceCreationLoading(false);
    }
  };

  const handleDownloadInvoice = async () => {
    try {
      setIsInvoiceDownloading(true);

      const response = await request.get(`payments/invoice/${payment.id}`);

      if (response?.data?.invoice) {
        const ticketPdf = await fetch(response?.data?.invoice?.url);
        const blob = await ticketPdf.blob();
        saveAs(blob, `${response?.data?.invoice?.name}.pdf`)
      }

      setIsInvoiceDownloading(false);
    } catch (e) {
      setIsInvoiceDownloading(false);
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  const handleCheckActualStatus = async () => {
     try {
      setIsActualizeStatusLoading(true);

      const response = await request.get(`payments/status/${payment.id}`);

      if (response?.data?.payment) {
        syncPaymentStatus(response?.data?.payment);

        getErrorStatusMessage({
          status: 200,
          message: 'Payment status successfully synchronized!',
        });
      } else {
        getErrorStatusMessage({
          message: 'Something went wrong. Try again later.',
        });
      }
      
      setIsActualizeStatusLoading(false);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      });
      setIsActualizeStatusLoading(false);
    }
  };

  const handlePaymentStatusChange = async (newStatus: PAYMENT_STATUS) => {
    try {
      setIsStatusChangeLoading(true);

      const response = await request.post(`payments/status/${payment.id}`, {
        status: newStatus,
      });

      if (response?.data?.payment) {
        syncPaymentStatus(response?.data?.payment);

        getErrorStatusMessage({
          status: 200,
          message: 'Payment status successfully changed!',
        });
      } else {
        getErrorStatusMessage({
          message: 'Something went wrong. Try again later.',
        });
      }
      
      setIsStatusChangeLoading(false);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      });
      setIsStatusChangeLoading(false);
    }
  };

  const handleUpdatePayerDetails = async (payerDetails: { payerRegistrationNumber: string, payerVatNumber: string }) => {
    try {
      setIsPayerDetailsChangeLoading(true);
      const response = await request.post(`payments/payer/${payment.id}`, payerDetails);
      setIsEditPayerFormData(false);
      updatePayerDetails(response?.data?.payment);
      getErrorStatusMessage({
        status: 200,
        message: `Payer details successfully changed!`,
      });
    } catch (err) {
      getErrorStatusMessage({ 
        status: err?.response?.status, 
        message: err.response?.data?.error ? err.response?.data?.error : 'Something went wrong',
      });
    } finally {
      setIsPayerDetailsChangeLoading(false);
    }
  };

  useEffect(() => {
    setIsEditPayerFormData(false);
  }, [payment.id]);

  return (
    <div>
      {payment.status === PAYMENT_STATUS.SUCCESS && !payment.balancedIoInvoiceNumber &&
        <InfoBlock
          actionLabel={'Create'}
          text={`The payment was successful. But the invoice for the payment was not created.`}
          onHandleAction={() => setIsCreateInvoiceModalOpen(true)}
        />
      }
      <OverviewItemsWrapper>
        <OverviewItemName>Payment ID</OverviewItemName>
        <OverviewItem>{payment.id}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Payment type</OverviewItemName>
        <OverviewItem>{paymentTypeOptions.find(option => option.value === payment.paymentType)?.label || N_A}</OverviewItem>
      </OverviewItemsWrapper>

      {payment.provider !== PAYMENT_PROVIDER.INTERNAL && (
        <OverviewItemsWrapper>
          <OverviewItemName>Provider</OverviewItemName>
          <OverviewItem>
            <PaymentProviderIcon provider={payment.provider} />
          </OverviewItem>
        </OverviewItemsWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>Status</OverviewItemName>
        <OverviewItem>
          {payment.paymentType === PAYMENT_TYPE.INVOICE ? (
            <CustomSelect
              currentValue={payment.status}
              isDisabled={isStatusChangeLoading}
              items={paymentStatusOptions}
              getItemLabel={getPaymentStatusLabel}
              onChange={(value) => handlePaymentStatusChange(value as any)}
            />
          ) : (
            getPaymentStatusLabel(payment.status)
          )}
        </OverviewItem>
      </OverviewItemsWrapper>

      {payment.paymentType === PAYMENT_TYPE.CARD && (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Payment status</OverviewItemName>
            <OverviewItem>
              {getCardPaymentStatusLabel(payment.externalStatus as CreditCardOrderStatus)}
            </OverviewItem>
          </OverviewItemsWrapper>
          <OverviewItemsWrapper>
            <OverviewItemName>Payment status description</OverviewItemName>
            <OverviewItem>{CreditCardOrderStatusDescription[payment.externalStatus]}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      )}

      {payment.paymentType === PAYMENT_TYPE.CRYPTO && (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Payment status</OverviewItemName>
            <OverviewItem>
              {getCryptoPaymentStatusLabel(payment.externalStatus as CryptoTransactionStatus)}
            </OverviewItem>
          </OverviewItemsWrapper>
          <OverviewItemsWrapper>
            <OverviewItemName>Payment status description</OverviewItemName>
            <OverviewItem>{CryptoTransactionStatusDescription[payment.externalStatus]}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      )}

      {payment.paymentType !== PAYMENT_TYPE.INVOICE && (
        <OverviewItemsWrapper>
          <OverviewItemName>Transaction ID (Key)</OverviewItemName>
          <OverviewItem>
            <CopyToClipboard text={payment.key} />
          </OverviewItem>
        </OverviewItemsWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>Invoice #</OverviewItemName>
        <OverviewItem>
          {payment.balancedIoInvoiceNumber || N_A}
        </OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Order #</OverviewItemName>
        <OverviewItem>
          {getOrderNumber(payment.id)}
        </OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Details</OverviewItemName>
        <OverviewItem>{payment?.description}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Created at</OverviewItemName>
        <OverviewItem>
          {formatDateAndTime(payment.createdAt, true)}
        </OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>
          {payment.status === PAYMENT_STATUS.SUCCESS ? 'Total paid' : 'Total'}, EUR
        </OverviewItemName>
        <OverviewItem>{payment.sum}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <ActionButtonsWrapper>
          {
            (payment.status === PAYMENT_STATUS.SUCCESS || (payment.paymentType === PAYMENT_TYPE.INVOICE && payment.status !== PAYMENT_STATUS.FAILED)) && (
              <Button
                animation={isInvoiceDownloading ? 'progress' : undefined}
                disabled={isInvoiceDownloading}
                variant="secondary"
                size="small"
                onClick={handleDownloadInvoice}
              >
                Download invoice
              </Button>
            )
          }
          {
            (payment.paymentType === PAYMENT_TYPE.CRYPTO || payment.paymentType === PAYMENT_TYPE.CARD) && (
              <Button
                animation={isActualizeStatusLoading ? 'progress' : undefined}
                disabled={isActualizeStatusLoading}
                variant="secondary"
                size="small"
                onClick={handleCheckActualStatus}
              >
                <ArrowsRefreshRound />
                Sync payment
              </Button>
            )
          }
        </ActionButtonsWrapper>
      </OverviewItemsWrapper>

      <Accordion title="Payer details" openByDefault withoutPadding>
        {payment.payerType === PAYER_TYPE.BUSINESS && (
          <>
            <InfoBlock
              text={`
                Only company payments can be edited. Editing functionality was provided
                for cases when the draft invoice was not created.
                Editing of the payment will not affect the actual transaction.   
              `}
            />
            {!isEditPayerFormData && (
              <EditButtonWrapper>
                <Button
                  variant="ghost"
                  onClick={() => setIsEditPayerFormData(!isEditPayerFormData)}
                  iconLeft={<GenericEdit fontSize="1.2rem" />}
                >
                  Edit
                </Button>
              </EditButtonWrapper>
            )}
          </>
        )}
        <Formik
          enableReinitialize
          validationSchema={payerSchema}
          validateOnBlur={false}
          initialValues={initialPayerValues}
          onSubmit={handleUpdatePayerDetails}
        >
          {({ errors, values, handleChange, resetForm }: any): JSX.Element => {
            return (
              <Form>
                <OverviewItemsWrapper>
                  <OverviewItemName>Type</OverviewItemName>
                  <OverviewItem>
                    {payerTypeOptions.find(option => option.value === payment.payerType)?.label || N_A}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Name</OverviewItemName>
                  <OverviewItem>
                    <EllipsisText text={payment?.payerName} />
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Email</OverviewItemName>
                  <OverviewItem>
                    <EllipsisText text={payment?.payerEmail} />
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Registration number</OverviewItemName>
                  <OverviewItem>
                    {isEditPayerFormData ? (
                      <FormField
                        errorPosition={-20}
                        placeholder="Registration number"
                        fieldName="payerRegistrationNumber"
                        component={TextInput}
                        inputSize="medium"
                        fieldId="payerRegistrationNumber"
                        errors={errors}
                        value={values.payerRegistrationNumber}
                        type="text"
                        onChange={handleChange}
                      />
                    ) : (
                      values.payerRegistrationNumber ? <EllipsisText text={values.payerRegistrationNumber} /> : N_A
                    )}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>VAT number</OverviewItemName>
                  <OverviewItem>
                    {isEditPayerFormData ? (
                      <FormField
                        errorPosition={-20}
                        placeholder="VAT number"
                        fieldName="payerVatNumber"
                        component={TextInput}
                        inputSize="medium"
                        fieldId="payerVatNumber"
                        errors={errors}
                        value={values.payerVatNumber}
                        type="text"
                        onChange={handleChange}
                      />
                    ) : (
                      values.payerVatNumber ? <EllipsisText text={values.payerVatNumber} /> : N_A
                    )}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Country</OverviewItemName>
                  <OverviewItem>
                    {isObject(payment?.payerCountry) ? (payment?.payerCountry?.name || N_A) : (payment?.payerCountry || N_A)}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>City</OverviewItemName>
                  <OverviewItem>{payment?.payerCity || N_A}</OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>State or county</OverviewItemName>
                  <OverviewItem>{payment?.payerStateOrCounty || N_A}</OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Postcode</OverviewItemName>
                  <OverviewItem>{payment?.payerPostcode || N_A}</OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Address line</OverviewItemName>
                  <OverviewItem>{payment?.payerAddress || N_A}</OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Document number</OverviewItemName>
                  <OverviewItem>
                    {payment?.payerDocumentNumber || N_A}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Document type</OverviewItemName>
                  <OverviewItem>
                    {payerDocumentTypeOptions.find(option => option.value === payment.payerDocumentType)?.label || N_A || N_A}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Birth country</OverviewItemName>
                  <OverviewItem>
                    {isObject(payment?.payerBirthCountry) ? (payment?.payerBirthCountry?.name || N_A) : (payment?.payerBirthCountry || N_A)}
                  </OverviewItem>
                </OverviewItemsWrapper>
                <OverviewItemsWrapper>
                  <OverviewItemName>Birth date</OverviewItemName>
                  <OverviewItem>
                    {payment?.payerBirthDate ? moment.utc(payment?.payerBirthDate).format('MMM DD, YYYY') : N_A}
                  </OverviewItem>
                </OverviewItemsWrapper>
                {isEditPayerFormData && (
                  <ButtonsWrapper>
                    <Button
                      variant="secondary"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        setIsEditPayerFormData(!isEditPayerFormData);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={isPayerDetailsChangeLoading || !isEmpty(errors) || checkOnIsEqual(values, initialPayerValues)}
                      variant="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </ButtonsWrapper>
                )}
              </Form>
            );
          }}
        </Formik>
      </Accordion>

      {
        payment?.bookingReference && (
          <Accordion title="Booking details" openByDefault withoutPadding>
            <OverviewItemsWrapper>
              <OverviewItemName>Reference</OverviewItemName>
              <OverviewItem>
                <EllipsisText text={payment?.bookingReference} />
              </OverviewItem>
            </OverviewItemsWrapper>
            <OverviewItemsWrapper>
              <OverviewItemName>Margin</OverviewItemName>
              <OverviewItem> {payment?.bookingMargin}%</OverviewItem>
            </OverviewItemsWrapper>
            {payment?.trips?.length > 0 && currentUserData?.role?.name === UserRoleType.Travelops && (
              <OverviewItemsWrapper>
                <OverviewItemName>Trips</OverviewItemName>
                <OverviewItem>
                  {payment?.trips.map((trip) => (
                    <Link key={trip.id} onClick={() => goToTrip(trip.reference)}>
                      {trip.id}
                    </Link>
                  ))}
                </OverviewItem>
              </OverviewItemsWrapper>
            )}
          </Accordion>
        )
      }

      {isCreateInvoiceModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          isLoading={isInvoiceCreationLoading}
          maxWidth={500}
          title="Confirm"
          onSubmit={handleInvoiceCreation}            
          onClose={() => setIsCreateInvoiceModalOpen(false)}
        >
          <InfoBlock
            text={`
              Creating the invoice can produce duplication in balanced.io.
              Please ensure that accountants have not previously added the invoice
              manually for this payment.
            `}
          />
          <Text size={16}>Are you sure you want to create an invoice?</Text>
        </Dialog>
      )}
    </div>
  );
};

export default PaymentDrawerContent;
