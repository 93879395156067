import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span style={{ width: '50px' }} {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Time and Frequency',
    accessor: 'timeAndFrequency',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
    minWidth: 180,
  },
  {
    Header: 'Description',
    accessor: 'description',
    disableSortBy: false,
    minWidth: 180,
  },
];
