import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 16px;
  width: 520px;
  min-height: 264px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 0;

  & p {
    display: flex;
    align-items: center;
    line-height: 24px;
  }
`;

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const Content = styled.div`
  padding: 16px 24px;
  max-height: 75vh;
  overflow-y: scroll;
`;

export const Footer = styled.div`
  padding: 16px 24px;
`;

export const FormFieldWrapper = styled.div`
  margin: 0 0 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
`;

export const Background = styled.div`
  background: rgba(24, 27, 28, 0.4);
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

export const DialogWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

export const FormItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

export const FormItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  color: #181b1c;
  text-align: start;

  ${({ withHover }) =>
  withHover &&
    `
      cursor: pointer;
   `}
  .text-input{
    height: 48px;
  }
`;

export const HotelInputWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  > *{ 
    width: 100%;
  }
`;

export const FieldLabel = styled.span`
  color: #78787d;
  font-size: 14px;
  margin-bottom: -5px;
  font-weight: bold;
`;
