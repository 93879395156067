import React from 'react';
import { TextInput, Button } from '@heathmont/moon-core';
import { TextInputGroup } from '@heathmont/moon-select';
import { GenericPlus, GenericDelete, ControlsEye, ControlsEyeCrossed } from '@heathmont/moon-icons';
import FormField from '../../FormField';
import Accordion from '../../Accordion';
import InfoBlock from '../../InfoBlock';
import { TogglePasswordText } from '../../TogglePasswordText';
import { addJiraFields, TabHelperText } from '../../../pages/SignUpCompany/suggestions';
import { ErrorMessage, ErrorMessageWrapper } from '../../../pages/SignUpCompany/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActiveHibobAccordion, setIsActiveJiraAccordion } from '../../../store/signUpCompany/actions';
import { IIntegrationsTab } from './types';
import { ContentItem } from './styles';

const IntegrationsTab: React.FC<IIntegrationsTab> = ({ values, errors, setFieldValue, handleBlur, touched }) => {
  const isEditJiraMode = useSelector((state: any) => state.signUpCompanyReducer.isEditJiraMode);
  const isEditHibobMode = useSelector((state: any) => state.signUpCompanyReducer.isEditHibobMode);

  const dispatch = useDispatch();

  const isErrorAdminEmail = !!errors?.integrations?.jira?.adminEmail && touched?.integrations?.jira?.adminEmail;
  const isErrorAccessToken = !!errors?.integrations?.jira?.accessToken && touched?.integrations?.jira?.accessToken;
  const isErrorHost = !!errors?.integrations?.jira?.host && touched?.integrations?.jira?.host;
  const isErrorProjectKey = !!errors?.integrations?.jira?.projectKey && touched?.integrations?.jira?.projectKey;
  const isErrorHibobAccessToken =
    !!errors?.integrations?.hibob?.accessToken && touched?.integrations?.hibob?.accessToken;

  const adminEmailField = {
    inputSize: 'xlarge',
    isError: isErrorAdminEmail,
    value: values?.integrations?.jira?.adminEmail,
    type: 'text',
    label: 'Admin email',
    id: 'integrations.jira.adminEmail',
    onChange: ({ target: { value } }) => setFieldValue('integrations.jira.adminEmail', value),
    onBlur: handleBlur,
  };

  const accessTokenField = {
    inputSize: 'xlarge',
    isError: isErrorAdminEmail,
    value: values?.integrations?.jira?.accessToken,
    label: 'Access token',
    type: 'password',
    id: 'integrations.jira.accessToken',
    onChange: ({ target: { value } }) => setFieldValue('integrations.jira.accessToken', value),
    onBlur: handleBlur,
    showPasswordText: (
      <TogglePasswordText
        renderShowIcon={() => <ControlsEye fontSize="1.5rem" />}
        renderHideIcon={() => <ControlsEyeCrossed fontSize="1.5rem" />}
      />
    ),
  };

  return (
    <div>
      <Accordion title="Jira" openByDefault withoutPadding>
        <InfoBlock text={TabHelperText.integrations.jira} />

        {!isEditJiraMode && (
          <Button
            iconLeft={<GenericPlus />}
            variant="ghost"
            onClick={() => {
              addJiraFields(setFieldValue);
              dispatch(setIsActiveJiraAccordion(true));
            }}
          >
            Add integration
          </Button>
        )}

        {isEditJiraMode && (
          <>
            <ContentItem>
              <FormField
                component={TextInputGroup}
                orientation="vertical"
                inputProps={{
                  input1: adminEmailField,
                  input2: accessTokenField,
                }}
              />

              {(isErrorAdminEmail || isErrorAccessToken) && (
                <ErrorMessageWrapper isVerticalView>
                  <ErrorMessage>{isErrorAdminEmail && errors?.integrations?.jira?.adminEmail}</ErrorMessage>

                  <ErrorMessage>{isErrorAccessToken && errors?.integrations?.jira?.accessToken}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </ContentItem>

            <ContentItem>
              <FormField
                fieldName="integrations.jira.host"
                fieldId="integrations.jira.host"
                component={TextInput}
                inputSize="xlarge"
                errors={errors}
                label="Host"
                value={values?.integrations?.jira?.host}
                type="text"
                onChange={({ target: { value } }) => setFieldValue('integrations.jira.host', value)}
                onBlur={handleBlur}
                isError={isErrorHost}
              />

              {isErrorHost && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{errors?.integrations?.jira?.host}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </ContentItem>

            <ContentItem>
              <FormField
                fieldName="integrations.jira.projectKey"
                fieldId="integrations.jira.projectKey"
                component={TextInput}
                inputSize="xlarge"
                errors={errors}
                label="Project key"
                value={values?.integrations?.jira?.projectKey}
                type="text"
                onChange={({ target: { value } }) => setFieldValue('integrations.jira.projectKey', value)}
                onBlur={handleBlur}
                isError={isErrorProjectKey}
              />

              {isErrorProjectKey && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{errors?.integrations?.jira?.projectKey}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </ContentItem>

            <Button
              iconLeft={<GenericDelete />}
              variant="ghost"
              onClick={() => {
                dispatch(setIsActiveJiraAccordion(false));
                setFieldValue('integrations.jira', null);
              }}
            >
              Remove integration
            </Button>
          </>
        )}
      </Accordion>

      <Accordion title="Hibob" openByDefault withoutPadding>
        <InfoBlock text={TabHelperText.integrations.hibob} />

        {!isEditHibobMode && (
          <Button
            iconLeft={<GenericPlus />}
            variant="ghost"
            onClick={() => {
              setFieldValue('integrations.hibob.accessToken', '');
              dispatch(setIsActiveHibobAccordion(true));
            }}
          >
            Add integration
          </Button>
        )}

        {isEditHibobMode && (
          <>
            <ContentItem>
              <FormField
                fieldName="integrations.hibob.accessToken"
                fieldId="integrations.hibob.accessToken"
                component={TextInput}
                inputSize="xlarge"
                errors={errors}
                label="Access token"
                value={values?.integrations?.hibob?.accessToken}
                type="password"
                onChange={({ target: { value } }) => setFieldValue('integrations.hibob.accessToken', value)}
                onBlur={handleBlur}
                isError={isErrorHibobAccessToken}
                showPasswordText={
                  <TogglePasswordText
                    renderShowIcon={() => <ControlsEye fontSize="1.5rem" />}
                    renderHideIcon={() => <ControlsEyeCrossed fontSize="1.5rem" />}
                  />
                }
              />

              {isErrorHibobAccessToken && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{errors?.integrations?.hibob?.accessToken}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </ContentItem>

            <Button
              iconLeft={<GenericDelete />}
              variant="ghost"
              onClick={() => {
                dispatch(setIsActiveHibobAccordion(false));
                setFieldValue('integrations.hibob', null);
              }}
            >
              Remove integration
            </Button>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default IntegrationsTab;
