import { Text } from '@heathmont/moon-core';
import {
  ControlsChevronLeft,
  ControlsChevronRight,
  NotificationsApp,
  ArrowsUpdate,
  TextTable,
} from '@heathmont/moon-icons';
import Button from '../Button';
import { Wrapper, PaginationInfo, VerticalLine, PaginationControls } from './styles';
import { ITablePagination } from './types';

const TablePagination: React.FC<ITablePagination> = ({
  isShowTableFilters,
  limitPerPage,
  totalItems,
  data,
  page,
  toggleTableFilters,
  changeRowHeight,
  refetchData,
  prevPage,
  nextPage,
  callback,
}) => {
  const iconFontSize = '1.5rem';
  const iconColor = '#78787D';

  return (
    <Wrapper>
      <PaginationInfo>
        <Text size={16} style={{ marginBottom: 0 }}>
          <strong>
            {data.length ? limitPerPage * (page - 1) + 1 : 0}-{limitPerPage * (page - 1) + data.length}
          </strong>{' '}
          of {totalItems.current} ({Math.ceil((data?.length / (totalItems.current || 1)) * 100)}%)
        </Text>

        <Button
          variant="default"
          size="small"
          handleClick={refetchData}
          label={<ArrowsUpdate fontSize={iconFontSize} style={{ transform: 'rotate(90deg)' }} color={iconColor} />}
        />
      </PaginationInfo>

      <PaginationControls>
        <Button
          variant="default"
          size="small"
          handleClick={() => {
            prevPage();

            if (callback) {
              callback();
            }
          }}
          isDisabled={page === 1}
          label={<ControlsChevronLeft fontSize={iconFontSize} color={iconColor} />}
        />

        <Button
          variant="default"
          size="small"
          handleClick={() => {
            nextPage();

            if (callback) {
              callback();
            }
          }}
          isDisabled={page >= Math.ceil(totalItems.current / limitPerPage)}
          label={<ControlsChevronRight fontSize={iconFontSize} color={iconColor} />}
        />

        <VerticalLine />

        <Button
          variant="default"
          size="small"
          handleClick={changeRowHeight}
          label={<NotificationsApp fontSize={iconFontSize} color={iconColor} />}
        />

        <Button
          variant="default"
          size="small"
          handleClick={toggleTableFilters}
          label={<TextTable fontSize={iconFontSize} color={iconColor} />}
          style={{
            backgroundColor: isShowTableFilters ? 'rgba(253, 239, 196, 0.38)' : '#fff',
          }}
        />
      </PaginationControls>
    </Wrapper>
  );
};

export default TablePagination;
