import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './UserInfo.scss';

const UserInfo = ({ isCurrentUserLoaded, currentUserData, isAppReady }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const editProfileLocation = {
    pathname: '/edit-profile',
    state: { prevPathname: pathname },
  };

  if (!isCurrentUserLoaded || !isAppReady) {
    return (
      <div className="header-userinfo">
        <Link to="/logout">{t('template.header.logout')}</Link>
      </div>
    );
  }

  return (
    <div className="header-userinfo">
      <Link to={editProfileLocation}>
        <FontAwesomeIcon icon="user-circle" size="1x" />
        {currentUserData?.user?.firstname} {currentUserData?.user?.lastname}
      </Link>
      <Link to="/logout">
        <FontAwesomeIcon icon="sign-out-alt" size="1x" />
        {t('template.header.logout')}
      </Link>
    </div>
  );
};
UserInfo.propTypes = {
  isCurrentUserLoaded: PropTypes.bool,
  currentUserData: PropTypes.shape({
    user: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
  isAppReady: PropTypes.bool,
};

UserInfo.defaultProps = {
  isCurrentUserLoaded: false,
  isAppReady: false,
  currentUserData: {},
};

export default UserInfo;
