import { IMergeTripsDialog } from './types';
import {
  DialogContent,
} from './styles';
import { useState } from 'react';
import MergeTripsDialogContent from '../MergeTripsDialogContent';
import Dialog from '../../components/Dialog';
import { Checkbox } from '@heathmont/moon-core';
const MergeTripsDialog: React.FC<IMergeTripsDialog> = ({
  isAvailableRenderAdditionalActions,
  submitButtonLabel,
  isSubmitDisabled,
  mergeTripIds,
  onSubmit,
  onClose,
}) => {
  const [baseTripId, setBaseTripId] = useState(null);
  const [ignoreValidationForMerge, setIgnoreValidationForMerge] = useState(false);
  const renderAdditionalActionsFunc = () => (
    <Checkbox
      checked={ignoreValidationForMerge}
      label={'Ignore additional merging rules'}
      onChange={() => setIgnoreValidationForMerge((prevValue) => !prevValue)}
    />
  );

  return (
    <Dialog
      title={`Merge trips (${mergeTripIds.length})`}
      submitButtonLabel={submitButtonLabel}
      isSubmitDisabled={isSubmitDisabled}
      data={mergeTripIds}
      maxWidth={500}
      onSubmit={() => onSubmit(baseTripId, ignoreValidationForMerge)}
      onClose={onClose}
      renderAdditionalActions={isAvailableRenderAdditionalActions && renderAdditionalActionsFunc}
    >
      <DialogContent>
        <MergeTripsDialogContent
          tripIds={mergeTripIds}
          baseTripId={baseTripId}
          setBaseTripId={setBaseTripId}
          showInfo={isAvailableRenderAdditionalActions}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MergeTripsDialog;
