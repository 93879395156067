import { useCallback, useState } from 'react';
import { ArrowsLeft, ControlsCloseSmall, GenericLogOut } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { EditProfileFormTabs } from '../../../components/EditProfileFormTabs';
import EditProfileTabRoutes from '../../../components/Routes/EditProfileTabRoutes';
import { HeathmontLogo } from '../../../components/HeathmontLogo';
import { FullScreenRandomBg } from '../../../components/FullScreenRandomBgNew';
import {
  changeProfileEditData,
  setIsChangedTabField,
  setIsUpdateChanges,
  setIsUpdateError,
} from '../../../store/editProfile/actions';
import { getHomePagePathByRolePages } from '../../../utils/commonFunctions';
import { editProfileSchema } from '../schema';
import Dialog from '../../../components/Dialog';
import useIsMobile from '../../../hooks/useIsMobile';
import { isEditProfileSubmitButtonDisabled } from '../suggestions';
import {
  TabFormContainerStyled,
  TabFormFieldsStyled,
  NotificationWrapper,
  NotificationText,
  ConfirmMessage,
  HeaderWrapper,
  Notification,
  Wrapper,
  Footer,
  Header,
  Link,
} from './styles';
import 'react-phone-number-input/style.css';

const ConfirmDialog = ({ label, secondButtonLabel, handleAgree, handleCloseDialog }) => (
  <Dialog
    submitButtonLabel={secondButtonLabel}
    title={label}
    onSubmit={handleAgree}
    onClose={handleCloseDialog}
  >
    <ConfirmMessage>You have unsaved changes. All changes will be lost. Do you want to continue?</ConfirmMessage>
  </Dialog>
);

const EditProfile = ({
  isValidNewPassword,
  initialValues,
  isUpdateError,
  currentUser,
  isUpdated,
  isGoAway,
  options,
  tabs,
  setIsGoAway,
  history: {
    push,
    location,
  },
}) => {
  const [isChangeTabWithChanges, setIsChangeTabWithChanges] = useState(false);
  const [nextTab, setNextTab] = useState('');
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const handleSubmit = async (values, resetForm) => {
    await dispatch(changeProfileEditData(currentUser.user.id, values, resetForm));
  };

  let reset;

  const path = location.pathname.split('/');

  const handleSetActiveTab = useCallback(
    (tab) => {
      setNextTab(tab);

      if (path[2] && tabs[path[2]].isChanged) {
        setIsChangeTabWithChanges(true);
      } else {
        setIsChangeTabWithChanges(false);
        push(tab);
      }

      if (isUpdated) {
        dispatch(setIsUpdateChanges());
      }

      if (isUpdateError.isError) {
        dispatch(setIsUpdateError());
      }
    },
    [path]
  );

  const handleReturnToBackOffice = useCallback(() => {
    if (path[2] && tabs[path[2]].isChanged) {
      setIsGoAway((prev) => !prev);
    } else {
      push(getHomePagePathByRolePages(currentUser?.pages));
    }
  }, [path]);

  return (
    <>
      <Helmet>
        <title>Edit profile</title>
      </Helmet>

      {isGoAway && (
        <ConfirmDialog
          label="Unsaved changes"
          handleCloseDialog={() => setIsGoAway((prev) => !prev)}
          secondButtonLabel="Return to back office"
          handleAgree={() => push(getHomePagePathByRolePages(currentUser?.pages))}
        />
      )}

      {isChangeTabWithChanges && (
        <ConfirmDialog
          label="Unsaved changes"
          handleCloseDialog={() => setIsChangeTabWithChanges(false)}
          secondButtonLabel="Continue"
          handleAgree={() => {
            const path = location.pathname.split('/');
            dispatch(setIsChangedTabField(path[2], false));
            reset();
            setIsChangeTabWithChanges(false);
            push(nextTab);
          }}
        />
      )}

      <FullScreenRandomBg
        renderComponent={
          <Wrapper isMobile={isMobile}>
            <HeaderWrapper>
              <HeathmontLogo style={{ margin: 0 }} />

              <Link to="/logout">
                <Button variant="ghost" size="small" iconLeft={<GenericLogOut fontSize="1.5rem" />}>
                  Log out
                </Button>
              </Link>
            </HeaderWrapper>

            <Header>Profile details</Header>

            <EditProfileFormTabs activeTab={location.pathname} setActiveTab={(tab) => handleSetActiveTab(tab)} />
  
            {isUpdated && (
              <NotificationWrapper>
                <Notification>
                  <NotificationText>Your account has been updated.</NotificationText>
                  <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setIsUpdateChanges(false))} />
                </Notification>
              </NotificationWrapper>
            )}

            {isUpdateError.isError && (
              <NotificationWrapper>
                <Notification isUpdateError={isUpdateError}>
                  <NotificationText>{isUpdateError.message}</NotificationText>
                  <ControlsCloseSmall fontSize="1.5rem" onClick={() => dispatch(setIsUpdateError(false))} />
                </Notification>
              </NotificationWrapper>
            )}

            <Formik
              enableReinitialize
              validationSchema={editProfileSchema}
              validateOnBlur={false}
              initialValues={initialValues}
              onSubmit={() => {}}
            >
              {(formProps: any): JSX.Element => {
                const { values, resetForm } = formProps;
                reset = resetForm;
                return (
                  <TabFormContainerStyled>
                    <TabFormFieldsStyled>
                      <EditProfileTabRoutes
                        currentUser={currentUser}
                        formProps={formProps}
                        options={options}
                      />
                    </TabFormFieldsStyled>

                    <Footer>
                      {!!currentUser?.pages.length && (
                        <Button
                          variant="ghost"
                          iconLeft={<ArrowsLeft/>}
                          onClick={handleReturnToBackOffice}
                        >Return to back office</Button>
                      )}

                      <Button
                        variant="primary"
                        onClick={() => handleSubmit(values, resetForm)}
                        disabled={isEditProfileSubmitButtonDisabled({
                          ...formProps,
                          isValidNewPassword,
                          currentTab: location.pathname,
                        })}
                      >Save</Button>
                    </Footer>
                  </TabFormContainerStyled>
                );
              }}
            </Formik>
          </Wrapper>
        }
      />
    </>
  );
};

export default EditProfile;
