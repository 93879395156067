import { Hotel } from '../../../enums/Hotel';
import {
  SET_TOOLS_ALL_CUSTOM_HOTELS_CHECKBOX_DATA,
  SET_TOOLS_CUSTOM_HOTELS_CHECKBOX_DATA,
  SET_IS_OPEN_ADD_NEW_CUSTOM_HOTELS,
  SET_IS_UPDATED_CUSTOM_HOTELS_DATA,
  CLOSE_DELETE_CUSTOM_HOTELS_MODAL,
  SHOW_DELETE_CUSTOM_HOTELS_MODAL,
  CLOSE_CUSTOM_HOTEL_OVERVIEW,
  SHOW_CUSTOM_HOTEL_OVERVIEW,
  SET_CUSTOM_HOTELS,
} from './actionTypes';

interface CustomHotelsState {
  isActiveAllCustomHotelsCheckboxes: boolean,
  customHotelsCheckboxesData: any[],
  isDeleteCustomHotelsModal: boolean,
  isOpenCustomHotelOverview: boolean,
  isUpdatedCustomHotelsData: boolean,
  overviewCustomHotelData: Hotel | null,
  isOpenAddNewCustomHotel: boolean,
  deletableCustomHotels: any[],
  totalCustomHotels: number,
  customHotels: Hotel[],
};

const initState: CustomHotelsState = {
  isActiveAllCustomHotelsCheckboxes: false,
  customHotelsCheckboxesData: [],
  isDeleteCustomHotelsModal: false,
  isOpenCustomHotelOverview: false,
  isUpdatedCustomHotelsData: false,
  overviewCustomHotelData: null,
  isOpenAddNewCustomHotel: false,
  deletableCustomHotels: [],
  totalCustomHotels: 0,
  customHotels: [],
};

const customHotelsReducer = (state = initState, action): CustomHotelsState => {
  switch (action.type) {
    case SET_CUSTOM_HOTELS:
      return {
        ...state,
        totalCustomHotels: action.payload.total,
        customHotels: action.payload.records,
      };
      
    case SET_IS_OPEN_ADD_NEW_CUSTOM_HOTELS:
      return {
        ...state,
        isOpenAddNewCustomHotel: action.payload,
      };

    case SHOW_DELETE_CUSTOM_HOTELS_MODAL:
      return {
        ...state,
        isDeleteCustomHotelsModal: true,
        deletableCustomHotels: action.payload.deletableCustomHotels,
      };

    case CLOSE_DELETE_CUSTOM_HOTELS_MODAL:
      return {
        ...state,
        isDeleteCustomHotelsModal: false,
        deletableCustomHotels: [],
      };

    case SET_TOOLS_CUSTOM_HOTELS_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllCustomHotelsCheckboxes: false,
          customHotelsCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          customHotelsCheckboxesData: [...state.customHotelsCheckboxesData, data],
        };
      }

      return {
        ...state,
        isActiveAllCustomHotelsCheckboxes: false,
        customHotelsCheckboxesData: state.customHotelsCheckboxesData.filter(item => (
          item.customHotelId !== data.customHotelId
        )),
      };
    }

    case SET_TOOLS_ALL_CUSTOM_HOTELS_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllCustomHotelsCheckboxes: isActive,
        customHotelsCheckboxesData: data,
      };
    }

    case SHOW_CUSTOM_HOTEL_OVERVIEW:
      return {
        ...state,
        isOpenCustomHotelOverview: true,
        overviewCustomHotelData: action.payload.data,
      };

    case CLOSE_CUSTOM_HOTEL_OVERVIEW:
      return {
        ...state,
        isOpenCustomHotelOverview: false,
        overviewCustomHotelData: null,
      };

    case SET_IS_UPDATED_CUSTOM_HOTELS_DATA:
      return {
        ...state,
        isUpdatedCustomHotelsData: action.payload.data,
      };

    default:
      return state;
  }
};

export default customHotelsReducer;
