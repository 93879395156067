import { Route, Switch, withRouter } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import AccountDetails from '../../pages/EditProfile/DesktopEditProfile/Tabs/AccountDetails';
import ProfileSettings from '../../pages/EditProfile/DesktopEditProfile/Tabs/ProfileSettings';
import Security from '../../pages/EditProfile/DesktopEditProfile/Tabs/Security';
import TravelDocuments from '../../pages/EditProfile/DesktopEditProfile/Tabs/TravelDocuments';
import LoyaltyPrograms from '../../pages/EditProfile/DesktopEditProfile/Tabs/LoyaltyPrograms';
import FavoriteHotels from '../../pages/EditProfile/DesktopEditProfile/Tabs/FavoriteHotels';
import MobileAccountDetails from '../../pages/EditProfile/MobileEditProfile/Tabs/MobileAccountDetails';
import MobileProfileSettings from '../../pages/EditProfile/MobileEditProfile/Tabs/MobileProfileSettings';
import MobileSecurity from '../../pages/EditProfile/MobileEditProfile/Tabs/MobileSecurity';
import MobileTravelDocument from '../../pages/EditProfile/MobileEditProfile/Tabs/MobileTravelDocument';
import CryptoWallet from '../../pages/EditProfile/DesktopEditProfile/Tabs/CryptoWallet';
import { EDIT_PROFILE_TABS } from './suggestions';

const EditProfileTabRoutes = ({ currentUser, formProps, options }) => {
  const isMobile = useIsMobile();

  return (
    <Switch>
      <Route
        exact
        path={EDIT_PROFILE_TABS.DETAILS}
        render={(props) =>
          isMobile ? (
            <MobileAccountDetails options={options} {...formProps} {...props} />
          ) : (
            <AccountDetails options={options} {...formProps} {...props} />
          )
        }
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.SETTINGS}
        render={(props) =>
          isMobile ? (
            <MobileProfileSettings options={options} {...formProps} {...props} />
          ) : (
            <ProfileSettings options={options} {...formProps} {...props} />
          )
        }
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.DOCUMENT}
        render={(props) =>
          isMobile ? (
            <MobileTravelDocument options={options} {...formProps} {...props} />
          ) : (
            <TravelDocuments options={options} {...formProps} {...props} />
          )
        }
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.LOYALTY_PROGRAMS}
        render={(props) => (<LoyaltyPrograms options={options} {...formProps} {...props} />)}
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.FAVORITE_HOTELS}
        render={(props) => (<FavoriteHotels userId={currentUser?.user?.id} options={options} {...formProps} {...props} />)}
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.SECURITY}
        render={(props) =>
          isMobile ? (
            <MobileSecurity options={options} {...formProps} {...props} />
          ) : (
            <Security options={options} {...formProps} {...props} />
          )
        }
      />
      <Route
        exact
        path={EDIT_PROFILE_TABS.CRYPTO_WALLET}
        render={(props) => (<CryptoWallet userId={currentUser?.user?.id} options={options} {...formProps} {...props} />)}
      />
    </Switch>
  );
};

export default withRouter(EditProfileTabRoutes);
