import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

import { ErrorMessage } from '../../components/ErrorMessage';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBgNew';
import { setToken } from '../../models/auth';

export const GoogleAuth = ({ setIsAuthenticated }) => {
  const { search } = useLocation();
  const { token } = queryString.parse(search);

  useEffect(() => {
    if (token) {
      setToken(token);
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Google Authorization</title>
      </Helmet>

      <FullScreenRandomBg
        renderComponent={
          <ErrorMessage
            withReturnBtn
            errorMessage={
              'User with this Google account is not registered. Please try to log in using another Google account!'
            }
            heading={'Google Authorization Error'}
          />
        }
      />
    </>
  );
};
