import { LogoImg } from '../../styled';
import extendedLogoPath from '../../static/extended-logo.svg';
import extendedLogoLightPath from '../../static/extended-logo-light.svg';
import logoPath from '../../static/logo.svg';

const HeathmontLogo = ({ style = {}, light = false, extended = false }) => {
  const params = { style, alt: 'Yolo Travel' }
  return extended ? (
    light ? (
      <LogoImg {...params} src={extendedLogoLightPath} />
    ) : (
      <LogoImg {...params} src={extendedLogoPath} />
    )
  ) : (
    <LogoImg {...params} src={logoPath} />
  )
};

export default HeathmontLogo;
