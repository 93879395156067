import Yup from '../../utils/validation';
import { REQUEST_PAYMENT_REASON } from '../../enums/RequestPaymentReason';
import { PaymentRequest } from '../../enums/PaymentRequest';
import { Trip } from '../../enums/Trip';

const STRING_MIN_LENGTH = 2;
const STRING_MAX_LENGTH = 255;
const PAYMENT_REQUEST_DETAILS_MIN_LENGTH = 2;
const PAYMENT_REQUEST_DETAILS_MAX_LENGTH = 50;
const TOTAL_SUM_MIN = 5;
const TOTAL_SUM_MAX = 100000;

const validateMessages = {
  tripId: {
    required: 'Trip cannot be empty',
  },
  flightId: {
    required: 'Flight cannot be empty',
  },
  passengerId: {
    required: 'Passenger cannot be empty',
  },
  reason: {
    required: 'Reason cannot be empty',
  },
  details: {
    required: 'Details cannot be empty',
    min: `Details cannot be less than ${PAYMENT_REQUEST_DETAILS_MIN_LENGTH} characters`,
    max: `Details cannot be longer than ${PAYMENT_REQUEST_DETAILS_MAX_LENGTH} characters`,
  },
  sum: {
    required: 'Total sum cannot be empty',
    min: `Total sum cannot be less than ${TOTAL_SUM_MIN} EUR`,
    max: `Total sum cannot be greater than ${TOTAL_SUM_MAX} EUR`,
  },
};

export const getInitialValues = (trip: Trip): PaymentRequest => {
  const flight = trip.flights?.length === 1  ? trip.flights[0] : null;
  const passenger = flight && flight.passengers.length === 1 ? flight.passengers[0] : null;
  
  return {
    applyToAllPassengers: false,
    passengerId: passenger ? passenger.id : null,
    flightId: flight ? flight.id : null,
    details: '',
    tripId: trip.id,
    reason: null,
    sum: null,
  };
};

export const paymentRequestSchema = Yup.object().shape({
  tripId: Yup
    .number()
    .required(validateMessages.tripId.required)
    .typeError(validateMessages.tripId.required),
  flightId: Yup
    .number()
    .required(validateMessages.flightId.required)
    .typeError(validateMessages.flightId.required),
  reason: Yup
    .string()
    .oneOf([
      REQUEST_PAYMENT_REASON.ADDITIONAL_SERVICE_FEE,
      REQUEST_PAYMENT_REASON.CHANGE_FEE,
      REQUEST_PAYMENT_REASON.OTHER,
    ])
    .required(validateMessages.reason.required)
    .typeError(validateMessages.reason.required),
  details: Yup
    .string()
    .min(PAYMENT_REQUEST_DETAILS_MIN_LENGTH, validateMessages.details.min)
    .max(PAYMENT_REQUEST_DETAILS_MAX_LENGTH, validateMessages.details.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.details.required),
  passengerId: Yup
    .number()
    .when('applyToAllPassengers', {
      is: applyToAllPassengers => !!applyToAllPassengers,
      then: Yup
        .number()
        .nullable(),
      otherwise: Yup
        .number()
        .required(validateMessages.passengerId.required)
        .typeError(validateMessages.passengerId.required),
    }),
  applyToAllPassengers: Yup
    .bool()
    .required(),
  sum: Yup
    .number()
    .min(TOTAL_SUM_MIN, validateMessages.sum.min)
    .max(TOTAL_SUM_MAX, validateMessages.sum.max)
    .required(validateMessages.sum.required)
    .typeError(validateMessages.sum.required),  
});
