import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, TextInput } from '@heathmont/moon-core';
import { Form, Formik } from 'formik';
import CustomSelect from '../../CustomSelect';
import FormField from '../../FormField';
import Dialog from '../../Dialog';
import { ButtonsWrapper, DialogContent, FormFooter, FormItemLabel, FormItem } from './styles';
import { purposeEditSchema, getPurposeInitialValues } from './schema';
import { ITripChangePurposeDialog } from './types';

const TripChangePurposeDialog: React.FC<ITripChangePurposeDialog> = ({
  tripPurposes,
  trip,
  handleChangeTripPurpose,
  getTripPurposeLabel,
  onClose,
}) => {
  const initialValues = useMemo(() => getPurposeInitialValues(trip), [trip]);

  return (
    <Dialog
      submitButtonLabel="Confirm"
      withFooter={false}
      title={`Update trip purpose — #${trip.id}`}
      onClose={onClose}
    >
      <DialogContent>
        <Formik
          enableReinitialize
          validationSchema={purposeEditSchema}
          validateOnMount={true}
          initialValues={initialValues}
          onSubmit={handleChangeTripPurpose}
        >
          {({ errors, values, touched, setFieldTouched, setFieldValue }: any): JSX.Element => {
            return (
              <Form>
                <FormItem>
                  <FormItemLabel>Purpose</FormItemLabel>
                  <CustomSelect
                    currentValue={values.purposeId}
                    items={tripPurposes}
                    getItemLabel={getTripPurposeLabel}
                    onChange={(value) => {
                      setFieldValue('purposeId', value);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormItemLabel>Purpose details</FormItemLabel>
                  <FormField
                    placeholder={'Add purpose details'}
                    fieldName='purposeDetails'
                    component={TextInput}
                    inputSize='large'
                    fieldId='purposeDetails'
                    isError={touched?.purposeDetails && errors?.purposeDetails}
                    errors={touched?.purposeDetails && errors}
                    value={values.purposeDetails}
                    type='text'
                    onChange={(e) => setFieldValue('purposeDetails', e.target.value)}
                    onBlur={() => setFieldTouched('comment', true, false)}
                  />
                </FormItem>
                <FormFooter>
                  <ButtonsWrapper>
                    <Button variant="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button disabled={!isEmpty(errors)} variant="primary" type="submit">
                      Confirm
                    </Button>
                  </ButtonsWrapper>
                </FormFooter>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TripChangePurposeDialog;
