import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL,
  SHOW_DELETE_RECOMMENDED_HOTELS_MODAL,
  CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL,
  SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA,
  SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA,
  SHOW_RECOMMENDED_HOTEL_OVERVIEW,
  CLOSE_RECOMMENDED_HOTEL_OVERVIEW,
  SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA,
} from './actionTypes';

export const setIsOpenAddNewHotelRecommendation = (isOpen = false) => ({ type: SET_IS_OPEN_ADD_NEW_RECOMMENDATION_MODAL, payload: isOpen });

export const showDeleteRecommendedHotelsModal = (data: any[] | any) => {
  const deletableRecommendedHotels = data?.length ? data.map((item) => item.recommendedHotelId) : [data.recommendedHotelId];
  return { type: SHOW_DELETE_RECOMMENDED_HOTELS_MODAL, payload: { deletableRecommendedHotels } };
};

export const closeDeleteRecommendedHotelsModal = () => {
  return { type: CLOSE_DELETE_RECOMMENDED_HOTELS_MODAL };
};

export const setRecommendedHotelsCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_RECOMMENDED_HOTELS_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllRecommendedHotelsCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_RECOMMENDED_HOTELS_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const showRecommendedHotelOverview = (data) => {
  return { type: SHOW_RECOMMENDED_HOTEL_OVERVIEW, payload: { data } };
};

export const closeRecommendedHotelOverview = () => {
  return { type: CLOSE_RECOMMENDED_HOTEL_OVERVIEW };
};

export const setIsUpdatedRecommendedHotelsData = (data = false) => ({ type: SET_IS_UPDATED_RECOMMENDED_HOTELS_DATA, payload: { data } });

export const addNewRecommendedHotel = (data) => (dispatch) => {
  dispatch(setIsUpdatedRecommendedHotelsData(true));
  dispatch(setIsLoadingData(true));

  request
    .post(`tools/recommended-hotels`, {
      ...data,
    })
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: `New recommended hotel successfully added`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });

      console.error(e);
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsUpdatedRecommendedHotelsData(false));
    });
};

export const updateRecommendedHotel = (teamId, data) => (dispatch) => {
  dispatch(setIsUpdatedRecommendedHotelsData(true));
  dispatch(setIsLoadingData(true));

  request
    .update(`tools/recommended-hotels/${teamId}`, {
      ...data,
    })
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: `Recommended hotel successfully updated`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });

      console.error(e);
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsUpdatedRecommendedHotelsData(false));
    });
};