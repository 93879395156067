import Yup from '../../utils/validation';

const validateMessages = {
  label: {
    min: 'Name cannot be less than 2 characters',
    max: 'Name cannot be longer than 255 characters',
    required: 'Name cannot be empty',
  },
};

export const schema = Yup.object().shape({
  label: Yup.string()
    .required(validateMessages.label.required)
    .min(3, validateMessages.label.min)
    .max(40, validateMessages.label.max)
    .withoutSpaces(),
  expensesRequired: Yup.number(),
});
