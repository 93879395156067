import styled from 'styled-components';

export const FormFieldWrapperForExternal = styled.div`
  margin: 0;
`;

export const FormFieldWrapper = styled.div`
  margin: 0 0 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & button {
    margin-left: 8px;
  }
`;
