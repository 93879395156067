import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  justify-content: flex-end;
  margin-top: 50px;
  display: flex;
  gap: 5px;
`;

export const FormItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #181b1c;
  text-align: start;

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}

  .text-input{
    height: 48px;
  }
`;

export const FormItemLabel = styled.span`
  color: #78787d;
  font-size: 12px;
`;

