import { HotelFormData } from '../../../enums/HotelFormData';
import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import { SET_CUSTOM_HOTELS } from './actionTypes';
import {
  closeDeleteCustomHotelsModal,
  setIsUpdatedCustomHotelsData,
  setCustomHotelsCheckboxData,
  setIsOpenAddNewCustomHotel,
  closeCustomHotelOverview,
} from './actions';
import { formatHotelBeforeSave } from './utils';

export const fetchCustomHotels = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const { data: { records, total } } = await request.get('tools/custom-hotels', { params });

    dispatch({ type: SET_CUSTOM_HOTELS, payload: { records, total } });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const removeCustomHotel = (hotelIds) => async (dispatch, getState) => {
  try {
    const { customHotelsReducer: { isUpdatedCustomHotelsData } } = getState();

    await request.delete('tools/custom-hotels', { data: { ids: hotelIds } });

    dispatch(setCustomHotelsCheckboxData());
    dispatch(closeDeleteCustomHotelsModal());
    dispatch(setIsUpdatedCustomHotelsData(!isUpdatedCustomHotelsData));

    getErrorStatusMessage({
      status: 200,
      message: hotelIds.length > 1
       ? 'Custom hotels have been successfully deleted!'
       : 'Custom hotel has been successfully deleted!',
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const addNewCustomHotel = (hotel: HotelFormData) => async (dispatch, getState) => {
  try {
    const { customHotelsReducer: { isUpdatedCustomHotelsData } } = getState();
  
    await request.post(`tools/custom-hotels`, formatHotelBeforeSave(hotel));

    dispatch(setIsOpenAddNewCustomHotel(false))
    dispatch(setIsUpdatedCustomHotelsData(!isUpdatedCustomHotelsData));
  
    getErrorStatusMessage({
      status: 200,
      message: `New custom hotel successfully added!`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateCustomHotel = (hotelId: string, hotel: HotelFormData) => async (dispatch, getState) => {
  try {
    const { customHotelsReducer: { isUpdatedCustomHotelsData } } = getState();
  
    await request.update(`tools/custom-hotels/${hotelId}`, formatHotelBeforeSave(hotel));

    dispatch(closeCustomHotelOverview());
    dispatch(setIsUpdatedCustomHotelsData(!isUpdatedCustomHotelsData));
  
    getErrorStatusMessage({
      status: 200,
      message: `Custom hotel successfully updated!`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};
