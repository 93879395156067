
import request from '../../models/request';
import {
  SET_EDIT_PROFILE_OPTIONS_SUCCESS,
  SET_EDIT_PROFILE_OPTIONS_LOADING,
  SET_EDIT_PROFILE_OPTIONS_ERROR,
  SET_IS_VALID_NEW_PASSWORD,
  SET_IS_CHANGED_TAB_VALUE,
  SET_IS_UPDATE_CHANGES,
  SET_IS_UPDATE_ERROR,
  SAVE_DATA,
} from './actionTypes';
import {
  updateProfileSettings,
  updateFavoriteHotels,
  updateLoyaltyNumbers,
  updateTravelDocument,
  updateAccountDetails,
  updatePassword,
  updateCryptoWallets,
} from './api';
import { getErrorMessages } from '../../utils/errors';

export const saveData = (data) => ({
  type: SAVE_DATA,
  payload: { data },
});

export const setIsValidPassword = (isValid) => ({
  type: SET_IS_VALID_NEW_PASSWORD,
  payload: { isValid },
});

export const setIsUpdateChanges = (isUpdate = false) => {
  return { type: SET_IS_UPDATE_CHANGES, payload: { isUpdate } };
};

export const setIsUpdateError = (isUpdateError = false, message = '') => ({
  type: SET_IS_UPDATE_ERROR,
  payload: { isUpdateError, message },
});

export const fetchOptions = (translate) => async (dispatch) => {
  dispatch({ type: SET_EDIT_PROFILE_OPTIONS_LOADING });
  try {
    const { data } = await request.post('user/options');
    dispatch({
      type: SET_EDIT_PROFILE_OPTIONS_SUCCESS,
      payload: {
        options: data.options,
        translate,
      },
    });
  } catch (err) {
    dispatch({
      type: SET_EDIT_PROFILE_OPTIONS_ERROR,
      payload: {
        error: err.message,
      },
    });
  }
};

export const setIsChangedTabField = (field, isChanged) => ({
  type: SET_IS_CHANGED_TAB_VALUE,
  payload: { field, isChanged },
});

export const changeProfileEditData = (userId, data, resetForm) => async (dispatch, getState) => {
  const { editProfileReducer: { tabs, data: initialData } } = getState();
  let isError = false;

  try {
    if (tabs.details.isChanged) {
      await updateAccountDetails(userId, data.details);
    } else if (tabs.settings.isChanged) {
      await updateProfileSettings(userId, data.settings);
    } else if (tabs.document.isChanged) {
      await updateTravelDocument(userId, data.document)
    } else if (tabs.security.isChanged) {
      await updatePassword(userId, data.security);
    } else if (tabs.loyalty.isChanged) {
      await updateLoyaltyNumbers(userId, data.loyaltyNumbers);
    } else if (tabs.favorites.isChanged) {
      await updateFavoriteHotels(userId, data.favoriteHotels);
    } else if (tabs.crypto.isChanged) {
      await updateCryptoWallets(userId, data.cryptoWallets);
    }

    dispatch(setIsUpdateError(false));
    dispatch(setIsUpdateChanges(true));
    dispatch(saveData(data));
  } catch (err) {
    isError = true;

    const status = request.extractStatus(err);
    const messages = request.extractErrors(err);
    let errorMsg = 'Something went wrong. Try again later!';

    if (status === 422 && messages) {
      const errorMsgs = getErrorMessages(messages);
      errorMsg = errorMsgs.join(' ');
    }
  
    dispatch(setIsUpdateError(true, errorMsg));
    dispatch(setIsUpdateChanges(false));
  } finally {
    resetForm({
      values: {
        ...(isError ? initialData : data),
        security: {
          currentPassword: '',
          newPassword: '',
        },
      },
    });
  }
};
