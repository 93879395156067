import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Heading, Button } from '@heathmont/moon-components';
import { Text, Footer } from '@heathmont/moon-core';
import { GenericCheckRounded } from '@heathmont/moon-icons';
import { HeathmontLogo } from '../HeathmontLogo';
import { ReactComponent as RefreshSvg } from '../.././static/icons/refresh.svg';
import { RefreshIconWrapper, MessageContainer, FormContent, TertButtonWrapper } from '../../styled';

const SuccessMessage = ({
  isSecExternal,
  secLinkText,
  isExternal,
  isTertiary,
  linkText,
  secLink,
  heading,
  message,
  link,
  type = 'success',
}) => {
  const renderLink = (text, to, variant, isExternal) => {
    const button = (
      <Button type="button" fullWidth variant={variant} size="small">
        {text}
      </Button>
    );

    if (isExternal) {
      return (
        <a href={to} target="_blank" rel="noreferrer">
          {button}
        </a>
      );
    }

    return <Link to={to}>{button}</Link>;
  };

  return (
    <MessageContainer>
      <FormContent>
        <HeathmontLogo />
        {type === 'info' ? (
          <RefreshIconWrapper>
            <RefreshSvg />
          </RefreshIconWrapper>
        ) : (
          <GenericCheckRounded style={{ margin: '0 auto 10px', display: 'block' }} fontSize="5rem" color="roshi.100" />
        )}
        <Heading style={{ marginBottom: 20, textAlign: 'center' }} size={32}>
          {heading}
        </Heading>
        {message && <Text style={{ textAlign: 'center', paddingBottom: '3rem' }}>{message}</Text>}
      </FormContent>
      {link && (
        <Footer
          isDivider
          primButton={!isTertiary && renderLink(linkText, link, 'primary', isExternal)}
          secButton={
            secLinkText && (
              <TertButtonWrapper>{renderLink(secLinkText, secLink, 'secondary', isSecExternal)}</TertButtonWrapper>
            )
          }
          tertButton={
            isTertiary && <TertButtonWrapper>{renderLink(linkText, link, 'tertiary', isExternal)}</TertButtonWrapper>
          }
        />
      )}
    </MessageContainer>
  );
};

SuccessMessage.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  secLink: PropTypes.string,
  secLinkText: PropTypes.string,
  isSecExternal: PropTypes.bool,
  isExternal: PropTypes.bool,
  isTertiary: PropTypes.bool,
  customFooter: PropTypes.func,
};

SuccessMessage.defaultProps = {
  heading: 'Registration complete!',
  message: 'To start booking use slack app.',
  isSecExternal: false,
  isExternal: false,
  isTertiary: false,
  secLinkText: '',
  linkText: '',
  secLink: '',
  link: '',
  customFooter: null,
};

export default SuccessMessage;
