import { UserRoleType } from '../../enums/UserRoleTypes';
import Yup from '../../utils/validation';
import { businessVerticalSchema } from '../BusinessVerticalOverviewDrawerEditContent/schema';

const validateMessages = {
  firstName: {
    max: 'First name can not be longer than 40 characters',  
  },
  lastName: {
    max: 'Last name can not be longer than 40 characters',  
  },
  employer: {
    required: 'Employer cannot be empty',
  },
  team: {
    required: 'Team cannot be empty',
  },
  email: {
    required: 'Email cannot be empty',
    email: 'Email must be a valid email',
    min: 'Email cannot be less than 2 characters',
    max: 'Email cannot be longer than 255 characters',
  },
  businessVertical: {
    required: 'Business vertical cannot be empty',
  },
};

export default Yup.object().shape({
  firstName: Yup
    .string()
    .nullable()
    .max(40, validateMessages.firstName.max),
  lastName: Yup
    .string()
    .nullable()
    .max(40, validateMessages.lastName.max),
  employer: Yup
    .number()
    .required(validateMessages.employer.required)
    .nullable(),
  external: Yup
    .bool()
    .nullable(),
  team: Yup
    .number()
    .required(validateMessages.team.required)
    .nullable(),
  email: Yup
    .string()
    .email(validateMessages.email.email)
    .required(validateMessages.email.required)
    .matches(/^(?!\s+$)/, validateMessages.email.required)
    .max(255, validateMessages.email.max),
  businessVertical: Yup
    .number()
    .required(validateMessages.businessVertical.required)
    .nullable(),
});

export const getDefaultRole = (roles) => {
  const role = roles.find(role => role.title === UserRoleType.Traveler);
  return role?.value;
};

export const getInitialValues = (roles) => {
  return {
    firstName: '',
    lastName: '',
    employer: null,
    external: true,
    email: '',
    role: getDefaultRole(roles),
    businessVertical: null,
    team: null,
  };
};
