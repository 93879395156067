import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { ControlsPlus } from '@heathmont/moon-icons';
import { Button, Text } from '@heathmont/moon-core';
import TablePagination from '../../../components/TablePagination';
import { LIMIT_PER_PAGE } from '../../../constants';
import { RowHeight } from '../../Reports/suggestions';
import TableContentReciever from '../../../components/TableContentReciever';
import { getTeamStatusLabel, toolsTeamsDataMapper } from '../../../utils/tableHelpers';
import { initialColumns } from './suggestions';
import Drawer from '../../../components/Drawer';
import TeamsOverviewDrawerContent from '../../../components/TeamsOverviewDrawerContent';
import DownDrawer from '../../../components/DownDrawer';
import Dialog from '../../../components/Dialog';
import AddNewTeamDialog from '../../../components/AddNewTeamDialog';
import { PageHeader, TableWrapper, Title } from '../styles';
import { ITeams } from './types';
import { removeTeam } from '../../../store/tools/teams/api';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../components/Spinner';
import GenerateReferralCodeDialog from '../../../components/GenerateReferralCodeDialog';

const Teams: React.FC<ITeams> = ({
  isActiveAllTeamsCheckboxes,
  teamsCheckboxesData,
  isOpenTeamsOverview,
  overviewTeamsData,
  isDeleteTeamsModal,
  isUpdatedTeamData,
  isOpenAddNewTeam,
  deletableTeams,
  totalTeams,
  companies,
  filters,
  sortBy,
  teams,
  page,
  setAllTeamsCheckboxData,
  closeDeleteTeamsModal,
  showDeleteTeamsModal,
  setTeamsCheckboxData,
  setIsOpenAddNewTeam,
  closeTeamOverview,
  showTeamOverview,
  setQueryParams,
  fetchCompanies,
  fetchTeams,
  addNewTeam,
  updateTeam,
  setSortBy,
  nextPage,
  prevPage,
}) => {
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [hoveredRow, setHoveredRow] = useState(null);
  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const companiesList = useMemo(() => companies.map(({ id, label }) => ({ value: id, title: label })), [companies]);
  const [isOpenGenerateReferralCodeDialog, setIsOpenGenerateReferralCodeDialog] = useState<boolean>(false);

  useEffect(() => {
    fetchCompanies();
    return () => {
      closeTeamOverview();
    };
  }, []);

  useEffect(() => {
    fetchTeams(params);
    closeTeamOverview();
  }, [filters, page, sortBy, isUpdatedTeamData]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const columns = useMemo(() => initialColumns, []);
  const teamsGroupedData = useMemo(() => toolsTeamsDataMapper(teams), [teams]);

  const queryParams = useRef('');
  const totalItems = useRef(0);

  useMemo(() => {
    totalItems.current = totalTeams;
  }, [totalTeams]);

  const handleIsOverviewOpen = useCallback(
    (row?: any) => {
      showTeamOverview(teams[row?.id]);
    },
    [teams]
  );

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const handleRemoveTeam = useCallback(async (data: any) => {
    await removeTeam(data);
    fetchTeams(params);
    setTeamsCheckboxData();
    closeDeleteTeamsModal();
  }, []);

  const handleAddNewTeam = useCallback(async (data: any) => {
    setIsOpenAddNewTeam(false);
    await addNewTeam(data);
  }, []);

  const handleUpdateTeam = useCallback(async (teamId, data: any) => {
    setIsOpenAddNewTeam(false);
    closeTeamOverview();
    await updateTeam(teamId, data);
  }, []);

  const deleteDialogText = useMemo(() => {
    let title = 'Do you want to delete ';

    if ((teamsCheckboxesData && teamsCheckboxesData.length > 1) || isActiveAllTeamsCheckboxes) {
      return `${title} these teams?`;
    }

    return `${title} this team?`;
  }, [isActiveAllTeamsCheckboxes, teamsCheckboxesData]);

  const tableContentRecieverProps = {
    isActiveAllTeamsCheckboxes,
    isShowTableFilters,
    isToolsTeamsPage: true,
    withCheckboxRow: true,
    checkboxesData: teamsCheckboxesData,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    data: teamsGroupedData,
    page,
    handleSetAllCheckboxs: setAllTeamsCheckboxData,
    handleIsOverviewOpen,
    handleRemoveDialog: showDeleteTeamsModal,
    toggleTableFilters,
    handleSetCheckbox: setTeamsCheckboxData,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Teams</Title>

          <Button
            variant="primary"
            size="small"
            onClick={() => {
              closeTeamOverview();
              setIsOpenAddNewTeam(true);
            }}
          >
            <ControlsPlus /> Add team
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE.TOOLS.TEAMS}
          totalItems={totalItems}
          data={teams}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => fetchTeams(params)}
          nextPage={nextPage}
          prevPage={prevPage}
        />

        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isOpenTeamsOverview && (
        <div>
          <Drawer handleClose={closeTeamOverview} title={overviewTeamsData?.name ?? ''}>
            <TeamsOverviewDrawerContent
              companies={companiesList}
              data={overviewTeamsData}
              openGenerateReferralCodeDialog={() => setIsOpenGenerateReferralCodeDialog(true)}
              handleUpdateTeam={handleUpdateTeam}
              getStatusLabel={getTeamStatusLabel}
            />
          </Drawer>
        </div>
      )}

      {isDeleteTeamsModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableTeams}
          onSubmit={handleRemoveTeam}
          onClose={closeDeleteTeamsModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {isOpenAddNewTeam && (
        <AddNewTeamDialog
          companies={companiesList}
          handleCloseDialog={() => setIsOpenAddNewTeam(false)}
          handleConfirm={handleAddNewTeam}
        />
      )}

      {isOpenGenerateReferralCodeDialog && overviewTeamsData?.id && (
        <GenerateReferralCodeDialog
          company={overviewTeamsData.company}
          team={overviewTeamsData}
          handleCloseDialog={() => setIsOpenGenerateReferralCodeDialog(false)}
        />
      )}

      {!!teamsCheckboxesData.length && (
        <DownDrawer
          data={teamsCheckboxesData}
          text="team"
          handleSetCheckbox={setTeamsCheckboxData}
          handleRemoveDialog={showDeleteTeamsModal}
        />
      )}
    </>
  );
};

export default Teams;
