import Yup from '../../utils/validation';

const validateMessages = {
  accommodationAdditionalPricePercent: {
    required: 'Accommodation margin is required',
    min: 'Accommodation margin can not be negative',
    max: 'Accommodation margin can not be more than 100',
  },
  flightAdditionalPricePercent: {
    required: 'Flight margin is required',
    min: 'Flight margin can not be negative',
    max: 'Flight margin can not be more than 100',
  },
  name: {
    required: 'Company name cannot be empty',
    min: 'Company name cannot be less than 2 characters',
    max: 'Company name cannot be longer than 40 characters',
  },
  accessToken: {
    required: 'Access token cannot be empty',
    min: 'Access token cannot be less than 2 characters',
    max: 'Access token cannot be longer than 255 characters',
  },
  accessId: {
    required: 'Access id cannot be empty',
    min: 'Access id cannot be less than 2 characters',
    max: 'Access id cannot be longer than 255 characters',
  },
  contactEmail: {
    email: 'Contact email email must be a valid email',
    min: 'Contact email cannot be less than 2 characters',
    max: 'Contact email cannot be longer than 255 characters',
  },
  travelGuideline: {
    min: 'Travel guideline cannot be less than 2 characters',
    max: 'Travel guideline cannot be longer than 255 characters',
    url: 'Travel guideline must be a valid URL',
  },
  accountantsChannelName: {
    min: 'Accountants cannel name be less than 2 characters',
    max: 'Accountants cannel name cannot be longer than 255 characters',
  },
  travelopsesChannelName: {
    min: 'Travelopses cannel name be less than 2 characters',
    max: 'Travelopses cannel name cannot be longer than 255 characters',
  },
  feedbackChannelName: {
    min: 'Feedback cannel name be less than 2 characters',
    max: 'Feedback cannel name cannot be longer than 255 characters',
  },
  email: {
    required: 'Admin email cannot be empty',
    email: 'Admin email must be a valid email',
    min: 'Admin email cannot be less than 2 characters',
    max: 'Admin email cannot be longer than 255 characters',
  },
  host: {
    required: 'Host cannot be empty',
    min: 'Host cannot be less than 2 characters',
    max: 'Host cannot be longer than 255 characters',
  },
  project: {
    required: 'Project key cannot be empty',
    min: 'Project key cannot be less than 2 characters',
    max: 'Project key cannot be longer than 255 characters',
  },
  participantsFieldName: {
    min: 'Participants field name cannot be less than 2 characters',
    max: 'Participants field name cannot be longer than 255 characters',
  },
  defaultTravelopses: {
    max: 'Max number of default travelopses is 5',
    min: 'At least one default travelops should be added',
  },
  defaultAccountants: {
    max: 'Max number of default accountants is 5',
    min: 'At least one default accountant should be added',
  },
  defaultCeos: {
    max: 'Max number of default CEO(s) is 3',
    min: 'At least one default CEO should be added',
  },
};

export const companyBasicInformationSchema = Yup.object().shape({
  accommodationAdditionalPricePercent: Yup
    .number()
    .min(0, validateMessages.accommodationAdditionalPricePercent.min)
    .max(100, validateMessages.accommodationAdditionalPricePercent.max)
    .required(validateMessages.accommodationAdditionalPricePercent.required),
  flightAdditionalPricePercent: Yup
    .number()
    .min(0, validateMessages.flightAdditionalPricePercent.min)
    .max(100, validateMessages.flightAdditionalPricePercent.max)
    .required(validateMessages.flightAdditionalPricePercent.required),
  accountantsChannelName: Yup
    .string()
    .min(2, validateMessages.accountantsChannelName.min)
    .max(255, validateMessages.accountantsChannelName.max)
    .nullable(),
  travelopsesChannelName: Yup
    .string()
    .min(2, validateMessages.travelopsesChannelName.min)
    .max(255, validateMessages.travelopsesChannelName.max)
    .nullable(),
  feedbackChannelName: Yup
    .string()
    .min(2, validateMessages.feedbackChannelName.min)
    .max(255, validateMessages.feedbackChannelName.max)
    .nullable(),
  travelGuideline: Yup
    .string()
    .url(validateMessages.travelGuideline.url)
    .min(2, validateMessages.travelGuideline.min)
    .max(255, validateMessages.travelGuideline.max)
    .nullable(),
  contactEmail: Yup
    .string()
    .min(2, validateMessages.contactEmail.min)
    .max(255, validateMessages.contactEmail.max)
    .email(validateMessages.contactEmail.email)
    .nullable(),
  admins: Yup.array().of(Yup.number()).nullable(),
  name: Yup
    .string()
    .required(validateMessages.name.required)
    .matches(/^(?!\s+$)/, validateMessages.name.required)
    .min(2, validateMessages.name.min)
    .max(40, validateMessages.name.max),
  defaultTravelopses: Yup.array().of(Yup.number())
    .test({
      message: validateMessages.defaultTravelopses.max,
      test: (arr) => arr.length <= 5,
    })
    .test({
      message: validateMessages.defaultTravelopses.min,
      test: (arr) => arr.length !== 0,
    }),
  defaultAccountants: Yup.array().of(Yup.number())
    .test({
      message: validateMessages.defaultAccountants.max,
      test: (arr) => arr.length <= 5,
    })
    .test({
      message: validateMessages.defaultAccountants.min,
      test: (arr) => arr.length !== 0,
    }),
  defaultCeos: Yup.array().of(Yup.number())
    .test({
      message: validateMessages.defaultCeos.max,
      test: (arr) => arr.length <= 3,
    })
    .test({
      message: validateMessages.defaultCeos.min,
      test: (arr) => arr.length !== 0,
    }),
});

export const hibobIntegrationSchema = Yup.object().shape({
  accessToken: Yup
    .string()
    .required(validateMessages.accessToken.required)
    .min(2, validateMessages.accessToken.min)
    .max(255, validateMessages.accessToken.max),
  accessId: Yup
    .string()
    .required(validateMessages.accessToken.required)
    .min(2, validateMessages.accessToken.min)
    .max(255, validateMessages.accessToken.max),
});

export const jiraIntegrationSchema = Yup.object().shape({
  accessToken: Yup
    .string()
    .required(validateMessages.accessToken.required)
    .min(2, validateMessages.accessToken.min)
    .max(255, validateMessages.accessToken.max),
  host: Yup
    .string()
    .required(validateMessages.host.required)
    .matches(/^(?!\s+$)/, validateMessages.host.required)
    .min(2, validateMessages.host.min)
    .max(255, validateMessages.host.max),
  email: Yup
    .string()
    .required(validateMessages.email.required)
    .matches(/^(?!\s+$)/, validateMessages.email.required)
    .min(2, validateMessages.email.min)
    .max(255, validateMessages.email.max)
    .email(validateMessages.email.email),
  project: Yup
    .string()
    .required(validateMessages.project.required)
    .matches(/^(?!\s+$)/, validateMessages.project.required)
    .min(2, validateMessages.project.min)
    .max(255, validateMessages.project.max),
  participantsFieldName: Yup
    .string()
    .min(2, validateMessages.participantsFieldName.min)
    .max(255, validateMessages.participantsFieldName.max)
    .nullable(),
});
