import {
  ShopCryptoCoin,
  GenericGlobe,
  GenericUsers,
  FilesTable,
  GenericBet,
  ChartArea,
} from '@heathmont/moon-icons';

interface Link {
  label: string;
  icon: (color: string) => JSX.Element;
  to: string;
}

export const links: Link[] = [
  {
    label: 'dashboard',
    icon: (color) => <ChartArea fontSize="2rem" color={color} />,
    to: '/',
  },
  {
    label: 'trips',
    icon: (color) => <GenericGlobe fontSize="2rem" color={color} />,
    to: '/trips',
  },
  {
    label: 'reports',
    icon: (color) => <FilesTable fontSize="2rem" color={color} />,
    to: '/reports',
  },
  {
    label: 'expenses',
    icon: (color) => <GenericBet fontSize="1.7rem" color={color} />,
    to: '/expenses',
  },
  {
    label: 'payments',
    icon: (color) => <ShopCryptoCoin fontSize="1.7rem" color={color} />,
    to: '/payments',
  },
  {
    label: 'users',
    icon: (color) => <GenericUsers fontSize="1.7rem" color={color} />,
    to: '/users',
  },
];
