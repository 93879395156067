import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_TOOLS_BUSINESS_VERTICAl_OVERVIEW_IS_UPDATED_DATA,
  SET_TOOLS_BUSINESS_VERTICALS_COMPANIES_DATA,
  SET_IS_OPEN_ADD_NEW_BUSINESS_VERTICAL_MODAL,
  SET_TOOLS_ALL_BUSINESS_VERTICALS_CHECKBOX_DATA,
  SET_TOOLS_BUSINESS_VERTICAL_CHECKBOX_DATA,
  SET_TOOLS_BUSINESS_VERTICALS_DATA,
  SHOW_BUSINESS_VERTICAL_OVERVIEW,
  CLOSE_BUSINESS_VERTICAL_OVERVIEW,
  SHOW_DELETE_BUSINESS_VERTICALS_MODAL,
  CLOSE_DELETE_BUSINESS_VERTICALS_MODAL,
} from './actionTypes';

const setBusinessVerticalsData = (data) => ({ type: SET_TOOLS_BUSINESS_VERTICALS_DATA, payload: data });

export const fetchToolsBusinessVerticals = (params: any) => (dispatch) => {
  request
    .get('tools/business-verticals', { params })
    .then(({ data }) => dispatch(setBusinessVerticalsData(data)))
    .catch((e) => console.error(e))
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const setBusinessVerticalsCompanies = (data) => {
  return { type: SET_TOOLS_BUSINESS_VERTICALS_COMPANIES_DATA, payload: { data } };
};

export const fetchBusinessVerticalsCompanies = () => (dispatch) => {
  request
    .get('/tools/companies')
    .then(({ data }) => dispatch(setBusinessVerticalsCompanies(data.records)))
    .catch((e) => console.error(e));
};

export const setAllBusinessVerticalsCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TOOLS_ALL_BUSINESS_VERTICALS_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const setBusinessVerticalCheckboxData = (checkboxData) => {
  return { type: SET_TOOLS_BUSINESS_VERTICAL_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const showBusinessVerticalOverview = (businessVertical) => {
  return { type: SHOW_BUSINESS_VERTICAL_OVERVIEW, payload: { data: businessVertical } };
};

export const closeBusinessVerticalOverview = () => {
  return { type: CLOSE_BUSINESS_VERTICAL_OVERVIEW };
};

export const setIsOpenAddNewBusinessVertical = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_BUSINESS_VERTICAL_MODAL, payload: isOpen };
};

export const addToolsNewBusinessVertical = (data, params) => (dispatch) => {
  dispatch(setIsLoadingData(true));

  request
    .post('tools/business-verticals', data)
    .then(() => {
      getErrorStatusMessage({
        status: 200,
        message: `New business vertical successfully added`,
      });

      dispatch(fetchToolsBusinessVerticals(params));
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    })
    .finally(() => dispatch(setIsLoadingData(false)));
};

export const setIsUpdatedBusinessVertical = (isUpdated = false) => {
  return { type: SET_TOOLS_BUSINESS_VERTICAl_OVERVIEW_IS_UPDATED_DATA, payload: { isUpdated } };
};

export const showDeleteBusinessVerticalsModal = (data: any[] | any) => {
  const deletableBusinessVerticals = data?.length ? data.map((item) => item.businessVerticalId ) : [ data?.businessVerticalId ];
  return { type: SHOW_DELETE_BUSINESS_VERTICALS_MODAL, payload: { deletableBusinessVerticals } };
};

export const closeDeleteBusinessVerticalsModal = () => {
  return { type: CLOSE_DELETE_BUSINESS_VERTICALS_MODAL };
};

export const updateBusinessVertical = (businessVerticalId, data) => (dispatch) => {
  dispatch(setIsUpdatedBusinessVertical(true));

  request
    .update(`/tools/business-verticals/${businessVerticalId}`, { ...data, name: data.name.trim() })
    .then(() => {
      dispatch(closeBusinessVerticalOverview());

      getErrorStatusMessage({
        status: 200,
        message: `Business vertical ID - ${businessVerticalId} successfully updated.`,
      });
    })
    .catch((e) => {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    })
    .finally(() => {
      dispatch(setIsLoadingData(false));
      dispatch(setIsUpdatedBusinessVertical(false));
    });
};