export const SET_DOWN_DRAWER_TRIP_STATUS_MODAL = 'trips/SET_DOWN_DRAWER_TRIP_STATUS_MODAL';
export const CLOSE_POSSIBLE_TRIPS_FOR_MERGING = 'trips/CLOSE_POSSIBLE_TRIPS_FOR_MERGING';
export const CLOSE_UNASSIGNED_ACCOMMODATIONS = 'trips/CLOSE_UNASSIGNED_ACCOMMODATIONS';
export const OPEN_POSSIBLE_TRIPS_FOR_MERGING = 'trips/OPEN_POSSIBLE_TRIPS_FOR_MERGING';
export const SET_OVERVIEW_TRIP_STATUS_MODAL = 'trips/SET_OVERVIEW_TRIP_STATUS_MODAL';
export const OPEN_UNASSIGNED_ACCOMMODATIONS = 'trips/OPEN_UNASSIGNED_ACCOMMODATIONS';
export const SET_FLIGHT_SELECTED_AIRPORTS = 'trip/EXTERNAL_TRIP/ADD_AIRPORTS';
export const SET_FLIGHT_SELECTED_AIRLINES = 'trip/EXTERNAL_TRIP/ADD_AIRLINES';
export const SET_TABLE_TRIP_STATUS_MODAL = 'trips/SET_TABLE_TRIP_STATUS_MODAL';
export const SET_TRIPS_FOR_MERGING_DATA = 'trips/SET_TRIPS_FOR_MERGING_DATA';
export const CLOSE_UNASSIGNED_FLIGHTS = 'trips/CLOSE_UNASSIGNED_FLIGHTS';
export const SET_TRIPS_CHECKBOX_DATA = 'trips/SET_TRIPS_CHECKBOX_DATA';
export const OPEN_UNASSIGNED_FLIGHTS = 'trips/OPEN_UNASSIGNED_FLIGHTS';
export const CLOSE_DELETE_TRIP_MODAL = 'trips/CLOSE_DELETE_TRIP_MODAL';
export const OPEN_DELETE_TRIP_MODAL = 'trips/OPEN_DELETE_TRIP_MODAL';
export const SET_UPDATE_TRIP_STATUS = 'trips/SET_UPDATE_TRIP_STATUS';
export const SET_TRIPS_OPTIONS_DATA = 'trips/SET_TRIPS_OPTIONS_DATA';
export const DELETE_OTHER_TRANSPORT = 'trip/DELETE_OTHER_TRANSPORT';
export const UPDATE_PAYMENT_STATUS = 'trip/UPDATE_PAYMENT_STATUS';
export const UPDATE_PAYMENT_INVOICE = 'trip/UPDATE_PAYMENT_INVOICE';
export const SET_DELETE_TRIP_DATA = 'trips/SET_DELETE_TRIP_DATA';
export const DELETE_ACCOMMODATION = 'trip/DELETE_ACCOMMODATION';
export const CLOSE_TRIP_OVERVIEW = 'trips/CLOSE_TRIP_OVERVIEW';
export const OPEN_TRIP_OVERVIEW = 'trips/OPEN_TRIP_OVERVIEW';
export const CLOSE_TRIP_TICKET = 'trips/CLOSE_TRIP_TICKET';
export const OPEN_TRIP_TICKET = 'trips/OPEN_TRIP_TICKET';
export const UPDATE_FEEDBACK = 'trip/UPDATE_FEEDBACK';
export const SET_TRIPS_DATA = 'trips/SET_TRIPS_DATA';
export const TOGGLE_FILTERS = 'trip/TOGGLE_FILTERS';
export const CLOSE_FILTERS = 'trip/CLOSE_FILTERS';
export const DELETE_FLIGHT = 'trip/DELETE_FLIGHT';
export const OPEN_FILTERS = 'trip/OPEN_FILTERS';
