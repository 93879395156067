import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow: auto;
`;

export const DialogContent = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

export const AdditionalActionsHeaderWrapper = styled.div`
  display: flex;
`;

export const InfoBlockWrapper = styled.div`
  padding: 6px 8px;
  background: rgba(253, 239, 196, 0.38);
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
  margin-top: -15px;
`;

export const InfoBlockText = styled.span`
  width: calc(100% - 36px);
  font-size: 14px;
  line-height: 24px;
`;

export const IconWrapper = styled.span`
  width: 24px;
`;

export const Reasons = styled.ol`
  list-style-type: circle;
  padding: 0 15px;

  & li {
    font-size: 14px;
    width: fit-content;
  }

  & p {
    font-weight: bold;
    display: inline;
  }
`;

export const ReasonsWrapper = styled.div`
  margin-top: 25px;
  overflow-y: scroll;
  max-height: 300px;
`;
