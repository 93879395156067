import { useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ControlsPlus, ControlsExpand } from '@heathmont/moon-icons';
import { TextInput } from '@heathmont/moon-core';
import { N_A } from '../../../utils/commonFunctions';
import { formatPurpose, formatTtrLink, formatDateAndTime, formatManager } from '../../../utils/tableHelpers';
import { getTripDuration, getTripTime } from '../../../utils/trips';
import CopyToClipboard from '../../../components/CopyToClipboard';
import CustomSelect from '../../../components/CustomSelect';
import FormActions from '../../../components/CompanyDrawerContent/FormActions';
import EditButton from '../../../components/CompanyDrawerContent/EditButton';
import FileViewer from '../../../components/FileViewer';
import Accordion from '../../../components/Accordion';
import FormField from '../../../components/FormField';
import InfoBlock from '../../../components/InfoBlock';
import { IExpensesOverview } from './types';
import { balancedIntegrationSchema, reimbursementInfoSchema } from './schema';
import {
  OverviewItemsWrapper,
  ReceiptsContainer,
  OverviewItemName,
  ReceiptZoomIcon,
  AddNewPhotoIcon,
  ReceiptStyled,
  OverviewItem,
} from './styles';
import { UserRoleType } from '../../../enums/UserRoleTypes';

import { REIMBURSEMENT_ADDRESSES } from '../../../constants';

const USDt_CRYPTO_CURRENCY_TITLE = 'Tether USDt';

const getReimbursementInfoInitialValues = (data) => ({
  reimburseTo: data?.trip?.expensePaymentInfo?.reimburseTo || null,
  reimburseEmployer: data?.trip?.expensePaymentInfo?.reimburseEmployer?.id ? {
    id: data.trip.expensePaymentInfo.reimburseEmployer.id,
    name: data.trip.expensePaymentInfo.reimburseEmployer.name,      
  } : null,
  cryptoWallet: data?.trip?.expensePaymentInfo?.cryptoWallet?.id ? {
    id: data.trip.expensePaymentInfo.cryptoWallet.id,
    address: data.trip.expensePaymentInfo.cryptoWallet.address, 
  } : null,
  cryptoCurrency: data?.trip?.expensePaymentInfo?.cryptoCurrency?.id ? {
    id: data.trip.expensePaymentInfo.cryptoCurrency.id,
    title: data.trip.expensePaymentInfo.cryptoCurrency.title, 
  } : null,
});

const geBalancedIntegrationInitialValues = (data) => ({
  registrationNumber: data?.trip?.user?.employer?.registrationNumber || '',
  balancedId: data?.trip?.user?.balancedId || '',
});

const getInitialCryptoCurrencyOptions = (paymentInfo, cryptoCurrencies) => {
  const options = [];

  if(paymentInfo?.reimburseTo === REIMBURSEMENT_ADDRESSES.BANK_ACCOUNT){
    return options;
  }

  if(paymentInfo?.cryptoWallet?.currencyId){
    const walletCryptoCurrency = cryptoCurrencies.find(({ id }) => id === paymentInfo?.cryptoWallet?.currencyId);
    options.push({ value: walletCryptoCurrency.id, title: walletCryptoCurrency.title });

    if(walletCryptoCurrency && walletCryptoCurrency.isSupportUSDt){
      const USDtCryptoCurrency = cryptoCurrencies.find(({ title }) => title === USDt_CRYPTO_CURRENCY_TITLE);
      options.push({ value: USDtCryptoCurrency.id, title: USDtCryptoCurrency.title });
    }
  }

  return options;
};

const ExpensesOverview: React.FC<IExpensesOverview> = ({
  reimbursementAddresses,
  reimbursementEmployers,
  currentUserData,
  dailyAllowance,
  statuses,
  cryptoCurrencies,
  data,
  handleChangeExpensesStatus,
  updateBalancedIntegration,
  updateReimbursementInfo,
  handleInvoiceCreation,
  handleSetReceiptData,
  setIsOpenAddReceipts,
  getStatusLabel,
}) => {
  const { t } = useTranslation();
  const [isReimbursementInfoEditMode, setIsReimbursementInfoEditMode] = useState(false);
  const reimbursementInfoInitialValues = useMemo(() => getReimbursementInfoInitialValues(data), [data]);
  const [isBalancedIntegrationEditMode, setIsBalancedIntegrationEditMode] = useState(false);
  const balancedIntegrationInitialValues = useMemo(() => geBalancedIntegrationInitialValues(data), [data]);
  const tripDuration = useMemo(() => getTripDuration(data.trip), [data.trip.tripStartDate, data.trip.tripFinishDate]);
  const receipts = data?.receipts ?? [];
  const travelerCryptoWallets = data?.travelerCryptoWallets ?? [];
  const { user } = data.trip;
  const [cryptoCurrenciesOptions, setCryptoCurrenciesOptions] = useState(getInitialCryptoCurrencyOptions(data?.trip?.expensePaymentInfo, cryptoCurrencies));
  const canCreateExpensesReportInvoice = useMemo(() => {
    return !!(
      user.balancedId
      && user.employer.registrationNumber
      && !data.trip.expensesReportInvoiceCreatedAt
      && (data.trip.expensesReportTransferToToBePaidAt || data.trip.expensesReportRefundedAt)
    )
  }, [
    user.balancedId,
    user.employer.registrationNumber,
    data.trip.expensesReportInvoiceCreatedAt,
    data.trip.expensesReportTransferToToBePaidAt,
    data.trip.expensesReportRefundedAt,
  ]);

  const reimbursementInfoFormToViewMode = (resetForm) => {
    setIsReimbursementInfoEditMode(false);
    resetForm();
  };

  const onSubmitReimbursementInfoForm = (values, { resetForm }) => {
    updateReimbursementInfo(data?.trip?.id, values);
    reimbursementInfoFormToViewMode(resetForm);
  };

  const balancedIntegrationFormToViewMode = (resetForm) => {
    setIsBalancedIntegrationEditMode(false);
    resetForm();
  };

  const onSubmitBalancedIntegrationForm = (values, { resetForm }) => {
    updateBalancedIntegration(data?.trip?.id, values);
    setIsBalancedIntegrationEditMode(resetForm);
  };

  const isCurrentUserTravelopsOrAccounting =
    currentUserData?.role?.name === UserRoleType.Travelops || currentUserData?.role?.name === UserRoleType.Accounting;

  const onChangeCryptoWallet = (cryptoWalletId, values, setValues) => {
    const { cryptoCurrencyId: currentCryptoWalletCurrencyId } = travelerCryptoWallets.find(({ id }) => id === cryptoWalletId);
    const currentWalletCryptoCurrency = cryptoCurrencies.find(({ id }) => id === currentCryptoWalletCurrencyId);
    const options = [{ value: currentWalletCryptoCurrency.id, title: currentWalletCryptoCurrency.title }];

    if (currentWalletCryptoCurrency.isSupportUSDt) {
      const USDtCryptoCurrency = cryptoCurrencies.find(({ title }) => title === USDt_CRYPTO_CURRENCY_TITLE);
      options.push({ value: USDtCryptoCurrency.id, title: USDtCryptoCurrency.title });
    }

    setCryptoCurrenciesOptions(options);
  
    setValues({
      ...values,
      cryptoCurrency: { id: options[0].value, title: options[0].title },
      cryptoWallet: {
        id: cryptoWalletId,
        address: travelerCryptoWallets.find(({ id }) => id === cryptoWalletId).address,
      },
    });
  }

  return (
    <div>
      {!!currentUserData?.company?.balancedStatus && !user?.balancedId && (
        <InfoBlock
          text={`
            Traveler's balanced.io integration number is missing.
            The system cannot process invoice creation.
          `} 
        />
      )}
    
      {!!currentUserData?.company?.balancedStatus && !user?.employer?.registrationNumber && (
        <InfoBlock
          text={`
            ${user?.employer?.name} registration number is missing.
            The system cannot process invoice creation.
          `}
          />
      )}

      {!!currentUserData?.company?.balancedStatus && canCreateExpensesReportInvoice && currentUserData?.role?.name === UserRoleType.Accounting && (
        <InfoBlock
          actionLabel={'Create'}
          text={`The expense report is checked. The invoice for the report was not created.`}
          onHandleAction={() => handleInvoiceCreation(data?.trip?.id)}
        />
      )}

      {dailyAllowance?.applyLimitsAfterDay && tripDuration > +dailyAllowance?.applyLimitsAfterDay && (
        <InfoBlock text={`
            The trip's duration is more than 15 days.
            Please double-check daily allowances after the 15th day.
          `}
        />
      )}
        
      {user && (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Traveler</OverviewItemName>
            <OverviewItem>{user?.fullName}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Team</OverviewItemName>
            <OverviewItem>{user?.team?.name}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper style={{ position: 'relative' }}>
            <OverviewItemName>Expenses status</OverviewItemName>

            <OverviewItem>
              <CustomSelect
                currentValue={data?.trip?.status}
                items={statuses}
                getItemLabel={getStatusLabel}
                onChange={(value) => handleChangeExpensesStatus(data?.trip?.id, value)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Manager</OverviewItemName>
            <OverviewItem>{formatManager(user?.manager)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Employer</OverviewItemName>
            <OverviewItem>{user?.employer?.name ?? N_A}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>Reference number</OverviewItemName>
        <OverviewItem>{data?.trip?.reference}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>TTR link</OverviewItemName>
        <OverviewItem>{formatTtrLink(data?.trip.jiraTicketNumber, data?.trip.companyJiraHost)}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>ETR link</OverviewItemName>
        <OverviewItem>{formatTtrLink(data?.trip.expenseReportTicketNumber, data?.trip.companyJiraHost)}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Destination</OverviewItemName>
        <OverviewItem>{data?.trip?.destinationLabel}</OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Trip time</OverviewItemName>
        <OverviewItem>{getTripTime(data?.trip)}</OverviewItem>
      </OverviewItemsWrapper>
      
      <OverviewItemsWrapper>
        <OverviewItemName>Receipts</OverviewItemName>

        <OverviewItem>
          <ReceiptsContainer>
            {!!receipts.length && (
              receipts.map((receipt) => (
                <ReceiptStyled
                  key={receipt.id}
                  onClick={() => handleSetReceiptData({ isOpen: true, receipt: receipt.url, receiptId: receipt.id })}
                >
                  <FileViewer preview url={receipt.url} />
                  <ReceiptZoomIcon>
                    <ControlsExpand fontSize="2rem" />
                  </ReceiptZoomIcon>
                </ReceiptStyled>
              ))
            )}

            <AddNewPhotoIcon onClick={() => setIsOpenAddReceipts(true)}>
              <ControlsPlus fontSize="2rem" />
            </AddNewPhotoIcon>
          </ReceiptsContainer>
        </OverviewItem>
      </OverviewItemsWrapper>

      <OverviewItemsWrapper>
        <OverviewItemName>Purpose</OverviewItemName>
        <OverviewItem>{formatPurpose(data?.trip?.purpose)}</OverviewItem>
      </OverviewItemsWrapper>

      {
        data?.trip?.purposeDetails && (
          <OverviewItemsWrapper>
            <OverviewItemName>Purpose details</OverviewItemName>
            <OverviewItem>{data?.trip?.purposeDetails}</OverviewItem>
          </OverviewItemsWrapper>
        )
      }

      <OverviewItemsWrapper>
        <OverviewItemName>Total cost, EUR</OverviewItemName>
        <OverviewItem isBool>{data?.trip?.totalCost}</OverviewItem>
      </OverviewItemsWrapper>

      {
        !!currentUserData?.company?.balancedStatus && isCurrentUserTravelopsOrAccounting && (
          <Accordion title="BALANCED.IO integration" withoutPadding>
          {!isBalancedIntegrationEditMode && <EditButton onClick={() => setIsBalancedIntegrationEditMode(true)}/>}
            <Formik
              enableReinitialize
              validationSchema={balancedIntegrationSchema}
              validateOnBlur={false}
              initialValues={balancedIntegrationInitialValues}
              onSubmit={onSubmitBalancedIntegrationForm}
            >
              {({ errors, values, resetForm, setFieldValue }: any): JSX.Element => {
                return (
                  <Form>
                    <OverviewItemsWrapper>
                      <OverviewItemName>Traveler integration number</OverviewItemName>
                      <OverviewItem>
                        {
                          isBalancedIntegrationEditMode ? (
                            <FormField
                              fieldName="balancedId"
                              component={TextInput}
                              inputSize="xlarge"
                              fieldId="balancedId"
                              errors={errors}
                              label="Integration number"
                              value={values.balancedId}
                              type="text"
                              onChange={(e) => setFieldValue('balancedId', e.target.value)}
                            />
                          ) : (
                            user?.balancedId || N_A 
                          )
                        }
                      </OverviewItem>
                    </OverviewItemsWrapper>

                    <OverviewItemsWrapper>
                      <OverviewItemName>Employer registration number</OverviewItemName>
                      <OverviewItem>
                        {
                          isBalancedIntegrationEditMode ? (
                            <FormField
                              fieldName="registrationNumber"
                              component={TextInput}
                              inputSize="xlarge"
                              fieldId="registrationNumber"
                              errors={errors}
                              label="Registration number"
                              value={values.registrationNumber}
                              type="text"
                              onChange={(e) => setFieldValue('registrationNumber', e.target.value)}
                            />
                          ) : (
                            user?.employer?.registrationNumber || N_A 
                          )
                        }
                      </OverviewItem>
                    </OverviewItemsWrapper>
                  
                    { isBalancedIntegrationEditMode && <FormActions errors={errors} onCancel={() => balancedIntegrationFormToViewMode(resetForm)} /> }
                  </Form>
                )
              }}
            </Formik>
          </Accordion>
        )
      }

      <Accordion title="Reimbursement info" withoutPadding>
        {!isReimbursementInfoEditMode && isCurrentUserTravelopsOrAccounting && <EditButton onClick={() => setIsReimbursementInfoEditMode(true)}/>}
        <Formik
          enableReinitialize
          validationSchema={reimbursementInfoSchema}
          validateOnBlur={false}
          initialValues={reimbursementInfoInitialValues}
          onSubmit={onSubmitReimbursementInfoForm}
        >
          {({ errors, values, resetForm, setFieldValue, setValues }: any): JSX.Element => {
            return (
              <Form>
                <OverviewItemsWrapper>
                  <OverviewItemName>Reimbursed by</OverviewItemName>
                  <OverviewItem>
                    {isReimbursementInfoEditMode ? (
                      <CustomSelect
                        currentValue={values.reimburseEmployer?.id}
                        items={reimbursementEmployers}
                        placeholder='Reimbursed by'
                        onChange={(option) =>
                          setFieldValue('reimburseEmployer', {
                            id: option,
                            name: reimbursementEmployers.find(({ value }) => value === option).title,
                          })
                        }
                      />
                    ) : data?.trip?.expensePaymentInfo?.reimburseEmployer?.name ? (
                      data.trip.expensePaymentInfo.reimburseEmployer.name
                    ) : (
                      N_A
                    )}
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Reimburse to</OverviewItemName>
                  <OverviewItem>
                    
                    {
                      isReimbursementInfoEditMode ? (
                        <CustomSelect
                          currentValue={values.reimburseTo}
                          isDisabled={!isReimbursementInfoEditMode}
                          items={reimbursementAddresses}
                          onChange={(option: any) => {
                            setValues({
                              ...values,
                              reimburseTo: option,
                              ...(option === REIMBURSEMENT_ADDRESSES.BANK_ACCOUNT ? {
                                cryptoWallet: null,
                                cryptoCurrency: null
                              }: {}),
                            });
                          }}
                        />
                      ) : (
                        data?.trip?.expensePaymentInfo?.reimburseTo 
                          ? t(`trips.lists.reimbursement_address.${data.trip.expensePaymentInfo.reimburseTo}`)
                          : N_A
                      )
                    }
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Crypto wallet</OverviewItemName>
                  <OverviewItem>
                    {
                      isReimbursementInfoEditMode ? (
                        <CustomSelect
                          placeholder='Crypto wallet'
                          currentValue={values.cryptoWallet?.id}
                          isDisabled={values.reimburseTo !== REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET}
                          items={travelerCryptoWallets.map(({ id, address }) => ({ value: id, title: address }))}
                          isError={!!(errors?.cryptoWallet)}
                          error={errors?.cryptoWallet}
                          onChange={cryptoWalletId => onChangeCryptoWallet(cryptoWalletId, values, setValues)}
                        />
                      ) : (
                        data?.trip?.expensePaymentInfo?.reimburseTo === REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET && data?.trip?.expensePaymentInfo?.cryptoWallet?.id
                        ? <CopyToClipboard text={data?.trip?.expensePaymentInfo?.cryptoWallet?.address}/>
                        : N_A
                      )
                    }
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Crypto currency</OverviewItemName>
                  <OverviewItem>
                    {
                      isReimbursementInfoEditMode ? (
                        <CustomSelect
                          placeholder='Crypto currency'
                          currentValue={values.cryptoCurrency?.id}
                          isDisabled={values.reimburseTo !== REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET || !values.cryptoWallet} 
                          items={cryptoCurrenciesOptions}
                          isError={!!(errors?.cryptoCurrency)}
                          error={errors?.cryptoCurrency}
                          onChange={(cryptoCurrencyId) => {
                             setFieldValue('cryptoCurrency', {
                              id: cryptoCurrencyId,
                              title: cryptoCurrenciesOptions.find(({ value }) => value === cryptoCurrencyId).title,
                            });
                            
                          }}
                        />
                      ) : (
                        data?.trip?.expensePaymentInfo?.reimburseTo === REIMBURSEMENT_ADDRESSES.CRYPTO_WALLET && data?.trip?.expensePaymentInfo?.cryptoCurrency?.id
                        ? data?.trip?.expensePaymentInfo?.cryptoCurrency?.title
                        : N_A
                      )
                    }
                  </OverviewItem>
                </OverviewItemsWrapper>                
              
                { isReimbursementInfoEditMode && <FormActions errors={errors} onCancel={() => reimbursementInfoFormToViewMode(resetForm)} /> }
              </Form>
            )
          }}
        </Formik>
      </Accordion>

      <Accordion title="Approval flow" withoutPadding>
        {
          data?.trip?.expensesReportRequestedAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Requested At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportRequestedAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportRequestedBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Requested By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportRequestedBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportSubmittedAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Submitted At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportSubmittedAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportSubmittedBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Submitted By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportSubmittedBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportSendToVerificationAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Requested Verification At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportSendToVerificationAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportSendToVerificationBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Requested Verification By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportSendToVerificationBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportApprovedAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Checked At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportApprovedAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportApprovedBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Checked By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportApprovedBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportTransferToToBePaidAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Transfer To Payment At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportTransferToToBePaidAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportTransferToToBePaidBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Transfer To Payment By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportTransferToToBePaidBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportRefundedAt && (
            <OverviewItemsWrapper>
              <OverviewItemName>Refunded At</OverviewItemName>
              <OverviewItem>{formatDateAndTime(data.trip.expensesReportRefundedAt, true)}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }

        {
          data?.trip?.expensesReportRefundedBy?.id && (
            <OverviewItemsWrapper>
              <OverviewItemName>Refunded By</OverviewItemName>
              <OverviewItem>{data.trip.expensesReportRefundedBy.fullName}</OverviewItem>
            </OverviewItemsWrapper>
          )
        }    
      </Accordion>
    </div>
  );
};

export default ExpensesOverview;
