import { connect } from 'react-redux';
import Footer from './Footer.ui';

const mapStateToProps = ({ currentUser, auth, app }) => ({
  isAuthenticated: auth.isAuthenticated,
  isAppReady: app.isAppReady,
  userRole: currentUser && currentUser.currentUserData && currentUser.currentUserData.role ? currentUser.currentUserData.role.name : null,
});

export default connect(mapStateToProps)(Footer);
