import { setToken } from '../../models/auth';
import { SET_AUTHENTICATED, SET_PROCESSING, SET_TOKEN, LOGOUT, SET_AUTH_ERROR } from './actionTypes';

export const logout = (redirectUrl = '/signin') => {
  setToken(null);
  return { type: LOGOUT, payload: { redirectUrl } };
};

export const setAuthToken = (authToken) => ({ type: SET_TOKEN, data: { authToken } });

export const setAuthError = (error) => ({ type: SET_AUTH_ERROR, payload: error });

export const setIsAuthenticated = (isAuthenticated) => (dispatch) => {
  if (!isAuthenticated) {
    setToken(null);
  }

  dispatch({
    type: SET_AUTHENTICATED,
    data: {
      isAuthenticated,
    },
  });
};

export const setAuthFormProcessing = (isAuthFormProcessing) => ({ type: SET_PROCESSING, data: { isAuthFormProcessing } });
