import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken as getAuthToken } from '../../models/auth';
import useIsMobile from '../../hooks/useIsMobile';

const PrivateRoute = ({ pages = [], component: Component, ...rest }) => {
  const isMobile = useIsMobile();
  
  if (pages.some((page) => page.path === rest.path)) {
    return <Route {...rest} render={(props) => <Component {...props} {...rest} />} />;
  }

  return <Redirect push to={isMobile ? "/edit-profile" : "/edit-profile/details"} />;
};

const mapStateToProps = ({ currentUser, auth, app }) => ({
  isCurrentUserLoaded: currentUser.isCurrentUserLoaded,
  isAuthenticated: auth.isAuthenticated,
  isAppReady: app.isAppReady,
  pages: currentUser?.currentUserData?.pages || [],
});

const mapDispatchToProps = {
  getAuthToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
