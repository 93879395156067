import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 304px;
  max-height: 200px;
  background: #ffffff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin: 0 auto;
  z-index: 11;
  position: absolute;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const Input = styled.input``;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

export const Page = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 296px;
  height: 48px;
  padding: 12px;

  &:hover {
    background: #f6f5f4;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;

  & button {
    margin-left: 8px;
  }
`;

export const GroupedButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
