import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  text-shadow: 0 0 0.01px black;
`;

export const MoreText = styled(Text).attrs({
  size: 14,
  color: 'bulma.100',
  isBold: true,
})`
  line-height: 24px;
  margin: 0px;
  margin-right: 5px;
`;

export const ActionButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;

  button {
    width: calc(100% / 2 - 2.5px);
  }
`;

export const Link = styled.div`
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 0.01px black;
  }
`;