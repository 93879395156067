import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_UPDATE_TOOLS_TEAMS_STATUS,
  SET_IS_OPEN_ADD_NEW_TEAM_MODAL,
  SET_TEAMS_ALL_CHECKBOX_DATA,
  SET_TOOLS_TEAMS_COMPANIES,
  SET_IS_UPDATED_TEAM_DATA,
  CLOSE_DELETE_TEAMS_MODAL,
  SHOW_DELETE_TEAMS_MODAL,
  SET_TEAMS_CHECKBOX_DATA,
  SET_TOOLS_TEAMS_OPTIONS,
  SET_TOOLS_TEAMS_DATA,
  CLOSE_TEAM_OVERVIEW,
  SHOW_TEAM_OVERVIEW,
} from './actionTypes';

const setTeamsData = (data) => ({ type: SET_TOOLS_TEAMS_DATA, payload: data });

export const fetchToolsTeamsData = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const response = await request.get('tools/teams', { params });
    dispatch(setTeamsData(response?.data));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const showTeamOverview = (team) => {
  return { type: SHOW_TEAM_OVERVIEW, payload: { data: team } };
};

export const closeTeamOverview = () => {
  return { type: CLOSE_TEAM_OVERVIEW };
};

const setUpdateExpensesStatus = (data) => ({ type: SET_UPDATE_TOOLS_TEAMS_STATUS, payload: { data } });

export const changeTeamsStatus = (teamId, newStatus) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    await request.update(`tools/teams/${teamId}`, {
      status: newStatus,
    });
    dispatch(setUpdateExpensesStatus(true));
    getErrorStatusMessage({
      status: 200,
      message: `The team status with Team ID - ${teamId} successfully changed.`,
    });
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `The team status with Team ID - ${teamId} can't be changed.`,
    });
  } finally {
    dispatch(setIsLoadingData(false));
    dispatch(setUpdateExpensesStatus(false));
  }
};

export const setTeamsCheckboxData = (checkboxData) => {
  return { type: SET_TEAMS_CHECKBOX_DATA, payload: { data: checkboxData } };
};

export const setAllTeamsCheckboxData = (checkboxData = [], isActive = false) => {
  return { type: SET_TEAMS_ALL_CHECKBOX_DATA, payload: { data: checkboxData, isActive } };
};

export const showDeleteTeamsModal = (data: any[] | any) => {
  const deletableTeams = data?.length ? data.map((item) => item.teamId) : [data?.teamId];
  return { type: SHOW_DELETE_TEAMS_MODAL, payload: { deletableTeams } };
};

export const closeDeleteTeamsModal = () => {
  return { type: CLOSE_DELETE_TEAMS_MODAL };
};

export const setIsOpenAddNewTeam = (isOpen = false) => {
  return { type: SET_IS_OPEN_ADD_NEW_TEAM_MODAL, payload: isOpen };
};

export const setTeamsOptions = (data = [], optionName) => ({
  type: SET_TOOLS_TEAMS_OPTIONS,
  payload: { data, optionName },
});

export const setTeamsCompanies = (data = []) => ({ type: SET_TOOLS_TEAMS_COMPANIES, payload: { data } });

export const fetchToolsTeamsCompanies = () => async (dispatch) => {
  try {
    const response = await request.get(`tools/companies`);
    dispatch(setTeamsCompanies(response?.data?.records));
  } catch (e) {
    console.error(e);
  }
};

export const setIsUpdatedTeamData = (data = false) => ({ type: SET_IS_UPDATED_TEAM_DATA, payload: { data } });

export const addNewTeam = (data) => async (dispatch) => {
  try {
    dispatch(setIsUpdatedTeamData(true));
    dispatch(setIsLoadingData(true));

    await request.post(`tools/teams`, data);
    getErrorStatusMessage({
      status: 200,
      message: `New team successfully added`,
    });
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
    dispatch(setIsUpdatedTeamData(false));
  }
};

export const updateTeam = (teamId, data) => async (dispatch) => {
  try {
    dispatch(setIsUpdatedTeamData(true));
    dispatch(setIsLoadingData(true));

    await request.update(`tools/teams/${teamId}`, data);

    getErrorStatusMessage({
      status: 200,
      message: `Team successfully updated`,
    });
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  } finally {
    dispatch(setIsLoadingData(false));
    dispatch(setIsUpdatedTeamData(false));
  }
};
