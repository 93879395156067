import { TOOLS_PAGES } from '../../components/Routes/suggestions';
import { DEFAULT_SORTING, LIMIT_PER_PAGE } from '../../constants';
import { initToolsFilters } from '../../hooks/useFilters';

const SEARCH_PLACEHOLDER = 'Search for name, id';

export const getFeedbacksPageData = (props) => ({
  isOpenFeedbackOverview: props.isOpenFeedbackOverview,
  overviewFeedbackData: props.overviewFeedbackData,
  totalFeedbacks: props.totalFeedbacks,
  feedbacks: props.feedbacks,
  closeFeedbackOverview: props.closeFeedbackOverview,
  showFeedbackOverview: props.showFeedbackOverview,
  fetchFeedbacks: props.fetchFeedbacks,
  updateFeedback: props.updateFeedback,
});

export const getRolesPageData = (props) => ({
  fetchRoles: props.fetchRoles,
  roles: props.roles,
  totalRoles: props.totalRoles,
  rolesCheckboxesData: props.rolesCheckboxesData,
  setRolesCheckboxData: props.setRolesCheckboxData,
  setAllRolesCheckboxData: props.setAllRolesCheckboxData,
  overviewRolesData: props.overviewRolesData,
  isOpenRolesOverview: props.isOpenRolesOverview,
  fetchPagesData: props.fetchPagesData,
  pages: props.pages,
  isDeleteRolesModal: props.isDeleteRolesModal,
  showDeleteRolesModal: props.showDeleteRolesModal,
  closeDeleteRolesModal: props.closeDeleteRolesModal,
  deletableRoles: props.deletableRoles,
  isOpenAddNewRole: props.isOpenAddNewRole,
  setIsOpenAddNewRole: props.setIsOpenAddNewRole,
  updateRole: props.updateRole,
  isActiveAllRolesCheckboxes: props.isActiveAllRolesCheckboxes,
  closeRoleOverview: props.closeRoleOverview,
  showRoleOverview: props.showRoleOverview,
});

export const getTeamsPageData = (props) => ({
  fetchTeams: props.fetchTeams,
  teams: props.teams,
  totalTeams: props.totalTeams,
  isOpenTeamsOverview: props.isOpenTeamsOverview,
  overviewTeamsData: props.overviewTeamsData,
  isUpdateTeamsStatus: props.isUpdateTeamsStatus,
  changeTeamsStatus: props.changeTeamsStatus,
  teamsCheckboxesData: props.teamsCheckboxesData,
  isActiveAllTeamsCheckboxes: props.isActiveAllTeamsCheckboxes,
  setTeamsCheckboxData: props.setTeamsCheckboxData,
  setAllTeamsCheckboxData: props.setAllTeamsCheckboxData,
  isDeleteTeamsModal: props.isDeleteTeamsModal,
  deletableTeams: props.deletableTeams,
  showDeleteTeamsModal: props.showDeleteTeamsModal,
  closeDeleteTeamsModal: props.closeDeleteTeamsModal,
  isOpenAddNewTeam: props.isOpenAddNewTeam,
  setIsOpenAddNewTeam: props.setIsOpenAddNewTeam,
  teamsOptions: props.teamsOptions,
  setTeamsOptions: props.setTeamsOptions,
  fetchCompanies: props.fetchCompanies,
  companies: props.companies,
  addNewTeam: props.addNewTeam,
  updateTeam: props.updateTeam,
  removeTeam: props.removeTeam,
  isUpdatedTeamData: props.isUpdatedTeamData,
  closeTeamOverview: props.closeTeamOverview,
  showTeamOverview: props.showTeamOverview,
});

export const getEmployersPageData = (props) => ({
  fetchToolsEmployers: props.fetchToolsEmployers,
  employers: props.employers,
  totalEmployers: props.totalEmployers,
  isActiveAllEmployersCheckboxes: props.isActiveAllEmployersCheckboxes,
  employersCheckboxesData: props.employersCheckboxesData,
  setEmployersCheckboxData: props.setEmployersCheckboxData,
  setAllEmployersCheckboxData: props.setAllEmployersCheckboxData,
  addToolsNewEmployer: props.addToolsNewEmployer,
  isOpenAddNewEmployer: props.isOpenAddNewEmployer,
  setIsOpenAddNewEmployer: props.setIsOpenAddNewEmployer,
  employersCompanies: props.employersCompanies,
  fetchAllCompanies: props.fetchAllCompanies,
  isDeleteEmployersModal: props.isDeleteEmployersModal,
  deletableEmployers: props.deletableEmployers,
  showDeleteEmployersModal: props.showDeleteEmployersModal,
  closeDeleteEmployersModal: props.closeDeleteEmployersModal,
  removeEmployer: props.removeEmployer,
  isOpenEmployerOverview: props.isOpenEmployerOverview,
  overviewEmployerData: props.overviewEmployerData,
  closeEmployerOverview: props.closeEmployerOverview,
  showEmployerOverview: props.showEmployerOverview,
  isUpdatedEmployerData: props.isUpdatedEmployerData,
});

export const getBusinessVerticalsPageData = (props) => ({
  businessVerticals: props.businessVerticals,
  totalBusinessVerticals: props.totalBusinessVerticals,
  setAllBusinessVerticalsCheckboxData: props.setAllBusinessVerticalsCheckboxData,
  fetchToolsBusinessVerticals: props.fetchToolsBusinessVerticals,
  isActiveAllBusinessVerticalsCheckboxes: props.isActiveAllBusinessVerticalsCheckboxes,
  businessVerticalsCheckboxesData: props.businessVerticalsCheckboxesData,
  setBusinessVerticalCheckboxData: props.setBusinessVerticalCheckboxData,
  addToolsNewBusinessVertical: props.addToolsNewBusinessVertical,
  businessVerticalsCompanies: props.businessVerticalsCompanies,
  fetchBusinessVerticalsCompanies: props.fetchBusinessVerticalsCompanies,
  isUpdatedBusinessVerticalData: props.isUpdatedBusinessVerticalData,
  showDeleteBusinessVerticalsModal: props.showDeleteBusinessVerticalsModal,
  closeDeleteBusinessVerticalsModal: props.closeDeleteBusinessVerticalsModal,
  isDeleteBusinessVerticalsModal: props.isDeleteBusinessVerticalsModal,
  deletableBusinessVerticals: props.deletableBusinessVerticals,
  isOpenAddNewBusinessVertical: props.isOpenAddNewBusinessVertical,
  isOpenBusinessVerticalOverview: props.isOpenBusinessVerticalOverview,
  showBusinessVerticalOverview: props.showBusinessVerticalOverview,
  closeBusinessVerticalOverview: props.closeBusinessVerticalOverview,
  overviewBusinessVerticalData: props.overviewBusinessVerticalData,
  setIsOpenAddNewBusinessVertical: props.setIsOpenAddNewBusinessVertical,
});

export const getPurposesPageData = (props) => ({
  purposes: props.purposes,
  totalPurposes: props.totalPurposes,
  fetchPurposes: props.fetchPurposes,
  isActiveAllPurposesCheckboxes: props.isActiveAllPurposesCheckboxes,
  purposesCheckboxesData: props.purposesCheckboxesData,
  setPurposesCheckboxData: props.setPurposesCheckboxData,
  setAllPurposesCheckboxData: props.setAllPurposesCheckboxData,
  isOpenAddNewPurpose: props.isOpenAddNewPurpose,
  setIsOpenAddNewPurpose: props.setIsOpenAddNewPurpose,
  addNewPurpose: props.addNewPurpose,
  isChangedPurpose: props.isChangedPurpose,
  showDeletePurposeModal: props.showDeletePurposeModal,
  closeDeletePurposeModal: props.closeDeletePurposeModal,
  isDeletePurposeModal: props.isDeletePurposeModal,
  deletablePurposes: props.deletablePurposes,
  removePurpose: props.removePurpose,
  updatePurpose: props.updatePurpose,
  showPurposeOverview: props.showPurposeOverview,
  isOpenPurposeOverview: props.isOpenPurposeOverview,
  overviewPurposeData: props.overviewPurposeData,
  closePurposeOverview: props.closePurposeOverview,
});

export const getRecommendedHotelsPageData = (props) => ({
  recommendedHotels: props.recommendedHotels,
  totalRecommendedHotels: props.totalRecommendedHotels,
  isOpenAddNewHotelRecommendation: props.isOpenAddNewHotelRecommendation,
  setIsOpenAddNewHotelRecommendation: props.setIsOpenAddNewHotelRecommendation,
  fetchRecommendedHotels: props.fetchRecommendedHotels,
  isDeleteRecommendedHotelsModal: props.isDeleteRecommendedHotelsModal,
  showDeleteRecommendedHotelsModal: props.showDeleteRecommendedHotelsModal,
  closeDeleteRecommendedHotelsModal: props.closeDeleteRecommendedHotelsModal,
  setRecommendedHotelsCheckboxData: props.setRecommendedHotelsCheckboxData,
  recommendedHotelsCheckboxesData: props.recommendedHotelsCheckboxesData,
  setAllRecommendedHotelsCheckboxData: props.setAllRecommendedHotelsCheckboxData,
  isActiveAllRecommendedHotelsCheckboxes: props.isActiveAllRecommendedHotelsCheckboxes,
  fetchRecommendedHotelsCompanies: props.fetchRecommendedHotelsCompanies,
  companies: props.companies,
  fetchRecommendedHotelsPurposes: props.fetchRecommendedHotelsPurposes,
  purposes: props.purposes,
  isOpenRecommendedHotelOverview: props.isOpenRecommendedHotelOverview,
  overviewRecommendedHotelData: props.overviewRecommendedHotelData,
  showRecommendedHotelOverview: props.showRecommendedHotelOverview,
  closeRecommendedHotelOverview: props.closeRecommendedHotelOverview,
  addNewRecommendedHotel: props.addNewRecommendedHotel,
  isUpdatedRecommendedHotelsData: props.isUpdatedRecommendedHotelsData,
  deletableRecommendedHotels: props.deletableRecommendedHotels,
  removeHotelRecommendation: props.removeHotelRecommendation,
  updateRecommendedHotel: props.updateRecommendedHotel,
});

export const getSubscriptionsPageData = (props) => ({
  subscriptions: props.subscriptions,
  fetchSubscriptions: props.fetchSubscriptions,
  showSubscriptionOverview: props.showSubscriptionOverview,
  closeSubscriptionOverview: props.closeSubscriptionOverview,
  overviewSubscriptionsData: props.overviewSubscriptionsData,
  isOpenSubscriptionsOverview: props.isOpenSubscriptionsOverview,
  updateSubscription: props.updateSubscription,
  fetchSubscriptionsLogs: props.fetchSubscriptionsLogs,
  subscriptionLogs: props.subscriptionLogs,
});

export const getActionsAndNotificationsPageData = (props) => ({
  fetchActionsAndNotifications: props.fetchActionsAndNotifications,
  actionsAndNotifications: props.actionsAndNotifications,
  totalActionsAndNotifications: props.totalActionsAndNotifications,
  showActionAndNotificationOverview: props.showActionAndNotificationOverview,
  closeActionAndNotificationOverview: props.closeActionAndNotificationOverview,
  isOpenActionAndNotificationOverview: props.isOpenActionAndNotificationOverview,
  overviewActionAndNotificationData: props.overviewActionAndNotificationData,
  actionAndNotificationLogs: props.actionAndNotificationLogs,
  fetchActionsAndNotificationsLogs: props.fetchActionsAndNotificationsLogs,
  setActionsCheckboxData: props.setActionsCheckboxData,
  setAllActionsCheckboxData: props.setAllActionsCheckboxData,
  actionsCheckboxesData: props.actionsCheckboxesData,
  isActiveAllActionsCheckboxes: props.isActiveAllActionsCheckboxes,
  updateActionAndSubscription: props.updateActionAndSubscription,
  updateActionStatus: props.updateActionStatus,
  isChangedAction: props.isChangedAction,
});

export const getEmploymentTypesPageData = (props) => ({
  fetchEmploymentTypes: props.fetchEmploymentTypes,
  employmentTypes: props.employmentTypes,
  totalEmploymentTypes: props.totalEmploymentTypes,
  isOpenEmploymentTypeOverview: props.isOpenEmploymentTypeOverview,
  overviewEmploymentTypeData: props.overviewEmploymentTypeData,
  showEmployerTypeOverview: props.showEmployerTypeOverview,
  closeEmployerTypeOverview: props.closeEmployerTypeOverview,
  isDeleteEmploymentTypesModal: props.isDeleteEmploymentTypesModal,
  deletableEmploymentTypes: props.deletableEmploymentTypes,
  showDeleteEmploymentTypesModal: props.showDeleteEmploymentTypesModal,
  closeDeleteEmploymentTypesModal: props.closeDeleteEmploymentTypesModal,
  employmentTypesCheckboxesData: props.employmentTypesCheckboxesData,
  isActiveAllEmploymentTypesCheckboxes: props.isActiveAllEmploymentTypesCheckboxes,
  setEmploymentTypesCheckboxData: props.setEmploymentTypesCheckboxData,
  setAllEmploymentTypesCheckboxData: props.setAllEmploymentTypesCheckboxData,
  isOpenAddNewEmployerType: props.isOpenAddNewEmployerType,
  setIsOpenAddNewEmploymentType: props.setIsOpenAddNewEmploymentType,
  removeEmploymentType: props.removeEmploymentType,
  updateEmploymentType: props.updateEmploymentType,
  isChangedEmploymentType: props.isChangedEmploymentType,
  addNewEmploymentType: props.addNewEmploymentType,
  updateEmploymentTypeStatus: props.updateEmploymentTypeStatus,
});

export const getIgnoreListAccommodationsPageData = (props) => ({
  isActiveAllAccommodationsForIgnoreCheckboxes: props.isActiveAllAccommodationsForIgnoreCheckboxes,
  accommodationsForIgnoreCheckboxesData: props.accommodationsForIgnoreCheckboxesData,
  bookingForIgnoreDeleteModalOpen: props.bookingForIgnoreDeleteModalOpen,
  bookingForIgnoreDeleteData: props.bookingForIgnoreDeleteData,
  isDeletionLoading: props.isDeletionLoading,
  isCreationLoading: props.isCreationLoading,
  bookingForIgnoreAddingModalOpen: props.bookingForIgnoreAddingModalOpen,
  isOpenBookingForIgnoreOverview: props.isOpenBookingForIgnoreOverview,
  bookingForIgnoreOverviewData: props.bookingForIgnoreOverviewData,
  ignoreListAccommodationsTotalCount: props.ignoreListAccommodationsTotalCount,
  ignoreListAccommodations: props.ignoreListAccommodations,
  removeBookingFromAccommodationsIgnoreList: props.removeBookingFromAccommodationsIgnoreList,
  addBookingToAccommodationsIgnoreList: props.addBookingToAccommodationsIgnoreList,
  getAccommodationsIgnoreList: props.getAccommodationsIgnoreList,
  closeBookingForIgnoreCreationModal: props.closeBookingForIgnoreCreationModal,
  showBookingForIgnoreCreationModal: props.showBookingForIgnoreCreationModal,
  closeBookingForIgnoreDeleteModal: props.closeBookingForIgnoreDeleteModal,
  showBookingForIgnoreDeleteModal: props.showBookingForIgnoreDeleteModal,
  closeBookingForIgnoreOverview: props.closeBookingForIgnoreOverview,
  showBookingForIgnoreOverview: props.showBookingForIgnoreOverview,
  setBookingForIgnoreDeletionLoading: props.setBookingForIgnoreDeletionLoading,
  setBookingForIgnoreCreationLoading: props.setBookingForIgnoreCreationLoading,
  setAllBookingForIgnoreCheckboxData: props.setAllBookingForIgnoreCheckboxData,
  setBookingForIgnoreCheckboxData: props.setBookingForIgnoreCheckboxData,
});

export const getCustomHotelsPageData = (props) => ({
  isActiveAllCustomHotelsCheckboxes: props.isActiveAllCustomHotelsCheckboxes,
  customHotelsCheckboxesData: props.customHotelsCheckboxesData,
  isDeleteCustomHotelsModal: props.isDeleteCustomHotelsModal,
  isOpenCustomHotelOverview: props.isOpenCustomHotelOverview,
  isUpdatedCustomHotelsData: props.isUpdatedCustomHotelsData,
  overviewCustomHotelData: props.overviewCustomHotelData,
  isOpenAddNewCustomHotel: props.isOpenAddNewCustomHotel,
  deletableCustomHotels: props.deletableCustomHotels,
  totalCustomHotels: props.totalCustomHotels,
  customHotels: props.customHotels,
  setAllCustomHotelsCheckboxData: props.setAllCustomHotelsCheckboxData,
  closeDeleteCustomHotelsModal: props.closeDeleteCustomHotelsModal,
  setIsUpdatedCustomHotelsData: props.setIsUpdatedCustomHotelsData,
  setCustomHotelsCheckboxData: props.setCustomHotelsCheckboxData,
  showDeleteCustomHotelsModal: props.showDeleteCustomHotelsModal,
  setIsOpenAddNewCustomHotel: props.setIsOpenAddNewCustomHotel,
  closeCustomHotelOverview: props.closeCustomHotelOverview,
  showCustomHotelOverview: props.showCustomHotelOverview,
  fetchCustomHotels: props.fetchCustomHotels,
  removeCustomHotel: props.removeCustomHotel,
  addNewCustomHotel: props.addNewCustomHotel,
  updateCustomHotel: props.updateCustomHotel,
});

export const getPageData = (pathname) => {
  switch (true) {
    case pathname === TOOLS_PAGES.ROLES:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.ROLES,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.ROLES,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.TEAMS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.TEAMS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.TEAMS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.EMPLOYERS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.EMPLOYERS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.EMPLOYERS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.COMPANIES:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.COMPANIES,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.COMPANIES,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.PURPOSES:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.PURPOSES,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.PURPOSES,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.SUBSCRIPTIONS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.SUBSCRIPTIONS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.SUBSCRIPTIONS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.ACTIONS_AND_NOTIFICATIONS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.ACTIONS_AND_NOTIFICATIONS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.ACTIONS_AND_NOTIFICATIONS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };
    case pathname === TOOLS_PAGES.DAILY_ALLOWANCES:
      return {
        initFilters: initToolsFilters,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.EMPLOYMENT_TYPES:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.EMPLOYMENT_TYPES,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.EMPLOYMENT_TYPES,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.FEEDBACKS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.FEEDBACKS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.FEEDBACKS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };
    
    case pathname === TOOLS_PAGES.IGNORE_LIST_ACCOMMODATIONS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.IGNORE_LIST_ACCOMMODATIONS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.IGNORE_LIST_ACCOMMODATIONS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    case pathname === TOOLS_PAGES.CUSTOM_HOTELS:
      return {
        defaultSortBy: DEFAULT_SORTING.TOOLS.CUSTOM_HOTELS,
        initFilters: initToolsFilters,
        limitPerPage: LIMIT_PER_PAGE.TOOLS.CUSTOM_HOTELS,
        searchPlaceholder: SEARCH_PLACEHOLDER,
      };

    default:
      return {
        defaultSortBy: [],
        initFilters: () => ({ search: '' }),
        limitPerPage: 20,
        searchPlaceholder: 'Search',
      };
  }
};