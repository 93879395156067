import { createContext, useContext } from 'react';

import localStorageHelper from '../utils/localstorage';

export const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const getToken = () => localStorageHelper.get('token', false);

export const setToken = (token) => localStorageHelper.set('token', token);
