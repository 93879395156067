import styled from 'styled-components';
import { Text } from '@heathmont/moon-core';

export const Wrapper = styled.div`
  min-width: 608px;
  height: calc(100vw / 3.5);
  min-height: 448px;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;

  @media screen and (max-width: 1356px) {
    margin-left: 0;
    height: calc(100vw / 2);
  }

  .recharts-responsive-container {
    min-width: calc(100vw / 2 - 75px) !important;
  }

  .recharts-legend-wrapper {
    left: -8px !important;
  }
`;

export const Header = styled(Text).attrs({
  size: 24,
  color: '#181B1C',
})`
  line-height: 32px;
  margin: 0 0 28px;
  font-weight: 400;
`;
