import _ from 'lodash';

export const data2List = (records: any[]) => {
  return records.map((el) => ({ value: el?.id, title: el?.fullName || el?.name }));
};

export const getBusinessVerticalType = (businessVerticals, selectedVerticals) => {
  let type = '';
  
  businessVerticals?.forEach(bv => {
    if(selectedVerticals?.[0] === bv.value) {
      type = bv?.type;
    }
  });

  return type;
};

export const getBusinessVerticalsAccountants = (businessVerticals, selectedVerticals) => {
  const accountants = [];

  businessVerticals?.forEach(bv => {
    if(selectedVerticals.includes(bv.value)){
      accountants.push(...bv?.accountants)
    }
  });

  return data2List(_.uniqBy(accountants, 'id'));
};

export const getBusinessVerticalsEmployers = (businessVerticals, selectedVerticals) => {
  const employers = [];

  businessVerticals?.forEach(bv => {
    if(selectedVerticals.includes(bv.value)){
      employers.push(...bv?.employers)
    }
  });

  return data2List(_.uniqBy(employers, 'id'));
};

export const isAccountantWithTheSameEmployerBusinessVertical = (employerBusinessVerticals, accountant) => { 
  const employerBusinessVerticalsIds = employerBusinessVerticals?.map(bv => bv.id) || [];
  return accountant?.businessVerticals?.some(bv =>
    employerBusinessVerticalsIds.includes(bv?.id));
};
