import styled from 'styled-components';
import { ReactComponent as RevolutLogoSvg } from '../../static/icons/revolut-logo.svg';
import { ReactComponent as SwapinLogoSvg } from '../../static/icons/swapin-logo.svg';

export const SwapinLogo = styled(SwapinLogoSvg)`
  height: 20px;
  width: auto;
`;

export const RevolutLogo = styled(RevolutLogoSvg)`
  height: 20px;
  width: auto;
  margin-left: -2.5px;
`;
