import { FormTabType } from '../../enums/FormTabType';

export const getInitialValues = (data) => {
  return {
    details: {
      email: data?.email?.defaultValue,
      company: data?.company?.defaultValue?.id ?? null,
      role: data?.role?.defaultValue?.id ?? null,
      employer: data?.employer?.defaultValue?.id ?? null,
      team: data?.team?.defaultValue?.id ?? null,
      businessVertical: data?.businessVertical?.defaultValue?.id ?? null,
    },
    settings: {
      firstname: data.firstname ?? '',
      lastname: data?.lastname ?? '',
      email: data?.passengerEmail?.defaultValue ?? '',
      passengerNumber: data?.passengerNumber?.defaultValue ?? '',
      birthDate: data?.birthDate?.defaultValue ?? '',
      gender: data?.gender?.defaultValue?.id ?? null,
      title: data?.title?.defaultValue?.id ?? null,
    },
    document: {
      issuedDocument: data?.country?.defaultValue?.id ?? null,
      number: data?.documentNumber?.defaultValue ?? '',
      expirationDate: data?.documentValidTill?.defaultValue ?? '',
    },
    security: {
      password: '',
    },
  };
};

export const changeFieldId = (ref, id) => {
  ref.inputRef.id = id;

  return ref;
};

export const isDisableSubmit = (errors, isValidNewPassword, currentTab) => {
  switch (currentTab) {
    case FormTabType.AccountDetails: {
      if (errors?.details) {
        return true;
      }

      return false;
    }
    case FormTabType.ProfileSettings: {
      if (errors?.settings) {
        return true;
      }

      return false;
    }
    case FormTabType.TravelDocument: {
      if (errors?.document) {
        return true;
      }

      return false;
    }
    case FormTabType.ChangePassword: {
      if ((errors?.security || !isValidNewPassword)) {
        return true;
      }

      return false;
    }

    default:
      return false;
  }
};

export const STEPPER_STYLES = {
  position: 'absolute',
  bottom: '17%',
  left: 0,
  zIndex: 1,
};

export const TOTAL_STEPS = 4;
