import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { BusinessVerticalUser } from '../../src/enums/BusinessVerticalUser';

export const ALL_VALUE = 'ALL';

export const prepareFilters = (filters) => {
  let normalizedFilters = cloneDeep(filters);

  Object.keys(normalizedFilters).forEach((key) => {
    if (isObject(normalizedFilters[key])) {
      normalizedFilters[key] = omitBy(normalizedFilters[key], isNil);
    }
  });

  normalizedFilters = omitBy(normalizedFilters, isNil);
  normalizedFilters = omitBy(normalizedFilters, isEmpty);
  normalizedFilters = omitBy(normalizedFilters, (value) => value === ALL_VALUE);

  return normalizedFilters;
};

const filterItemsWIthBusinessVerticalsByCondition = (items = [], condition = (arg: any) => false ) => {
  return items.filter(item => {
    if (Array.isArray(item['businessVerticals'])
      && item['businessVerticals'].some(condition)) {
      return true;
    } else {
      return false;
    }
})
};

export const filterItemsWithVerticalsByVerticalObjectId = (items = [], businessVertical) => {
  return filterItemsWIthBusinessVerticalsByCondition(items, (bv) => businessVertical && bv?.id === businessVertical );
};

export const filterItemsWithBusinessVerticalByVerticalId = (items = [], businessVertical) => {
  return filterItemsWIthBusinessVerticalsByCondition(items, (id) => businessVertical && id === businessVertical );
};

export const filterItemsWithBusinessVertical = (items = [], businessVertical) => {
  return items.filter(item => businessVertical && item?.businessVertical?.id === businessVertical)
};

export const usersListToIds = (users = []) => {
  return users.map(user => user?.value);
};

export const usersToList = (users: BusinessVerticalUser[] = []) => {
  return users.map(user => ({ value: user?.id, title: user.fullName}));
};

export const filterBusinessVerticalsByType = (items = [], type) => {
  return items.filter(bv => bv?.type === type)
};

export const filterBusinessVerticalsListByValue = (items = [], value) => {
  return items.filter(bv => value && bv?.value === value)
};