import React from 'react';
import { ITeamsTab } from './types';
import DynamicFormFields from '../../DynamicFormFields';
import { TabHelperText } from '../../../pages/SignUpCompany/suggestions';

const TeamsTab: React.FC<ITeamsTab> = ({ values, errors, setFieldValue, touched }) => {
  return (
    <DynamicFormFields
      data={values.teams}
      setFieldValue={setFieldValue}
      touched={touched}
      label="Team"
      fieldName="teams"
      infoText={TabHelperText.teams}
      errors={errors}
    />
  );
};

export default TeamsTab;
