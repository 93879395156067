import styled from 'styled-components';
import { Accordion } from '@heathmont/moon-accordion';

export const CustomAccordion = styled(Accordion)`
  padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '1rem')};

  h3 {
    font-weight: 600;
    margin-bottom: 0;
  }

  [class^='layout__AccordionContent'] {
    margin: ${({ withoutPadding }) => (withoutPadding ? 0 : '-10px -10px 0 -10px')};
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '10px 10px 0 10px')};
  }

  &.flight-booking-accordion {
    border: 12px;
    
    > div:first-child {    
      padding: 20px 40px;

      @media screen and (max-width: 768px) {
        padding: 12px 16px;
      }
    
      h3 {
        font-weight: 400;
        display: block;
        height: 100%;
        width: calc(100% - 40px);
        font-size: 24px;
        line-height: 31.25px;

        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 26.04px;
        }
      }
      
      button {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    > div:last-child {
      padding: 0;

      > div {
        padding: 0;
      
        > * {
          padding: 20px 40px 40px;
          border-top: 1px dashed rgba(220, 222, 227, 1);

          @media screen and (max-width: 768px) {
            padding: 16px;
          }
        }
      }
    }
  }

  &.fare-rules-accordion {
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0.5px 1px #0000001a, 0 2px 6px #5a646d33;

    > div:first-child {    
      h3 {
        font-weight: 400;
        display: block;
        height: 100%;
        width: 100%;
        font-size: 20px;
        line-height: 31.25px;

        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 26.04px;
        }
      }
    }
  }
`;
