import isEmpty from 'lodash/isEmpty';
import { Form, Formik } from 'formik';
import { TextInput } from '@heathmont/moon-core';
import InfoBlock from '../InfoBlock';
import FormField from '../FormField';
import Button from '../Button';
import schema from './schema';
import { FormFieldsWrapper, ButtonsWrapper } from './styles';
import { IInvitationCompanyForm } from './types';

const InvitationCompanyForm: React.FC<IInvitationCompanyForm> = ({
  isLoading,
  onSubmit,
  onCancel,
}): JSX.Element => {
  return (
    <div>
      <InfoBlock text="After registration, the company will not be active. The admin will need to activate the company." />
      <Formik
        enableReinitialize
        validationSchema={schema}
        validateOnBlur={true}
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
      >
        {({ errors, values, setFieldValue }: any): JSX.Element => {
          return (
            <Form>
              <FormFieldsWrapper>
                <FormField
                  fieldName="email"
                  component={TextInput}
                  inputSize="xlarge"
                  fieldId="email"
                  errors={errors}
                  value={values.email}
                  label="Email"
                  type="text"
                  onChange={(e) => setFieldValue('email', e.target.value)}
                />
              </FormFieldsWrapper>

              <ButtonsWrapper>
                <Button variant="tertiary" label="Close" handleClick={onCancel} />
                <Button isDisabled={!isEmpty(errors) || isLoading} variant="primary" label="Save" type="submit"/>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvitationCompanyForm;
