import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { ITripEditButton } from './types';
import { EditButtonWrapper } from '../styles';

const TripEditButton: React.FC<ITripEditButton> = ({
  handleEdit,
}) => {
  return (
    <EditButtonWrapper>
      <Button
        iconLeft={<GenericEdit fontSize="1.2rem" />}
        variant="ghost"
        onClick={handleEdit}
      >
        Edit
      </Button>
    </EditButtonWrapper> 
  );
};

export default TripEditButton;
