export enum TripTypes {
  FLIGHT = 'flight',
  ACCOMMODATION = 'accommodation',
  OTHER_TRANSPORT = 'other_transport',
  FLIGHT_AND_ACCOMMODATION = 'flight_accommodation',
  FLIGHT_OTHER_TRANSPORT = 'flight_other_transport',
  ACCOMMODATION_OTHER_TRANSPORT = 'accommodation_other_transport',
  FLIGHT_ACCOMMODATION_OTHER_TRANSPORT = 'flight_accommodation_other_transport',
};

