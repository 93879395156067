import { RECEIPTS_SETTINGS } from '../../constants';

export const convertBytesToMbs = (bytes: number): number => {
  const mb = bytes / 1000000;
  return Math.round(mb * 100) / 100;
};

export const getErrorTest = (error) => {
  if (error?.maxFileSize) {
    return `It is forbidden to upload a file larger than ${convertBytesToMbs(RECEIPTS_SETTINGS.MAX_FILE_SIZE)}Mb.`;
  }

  if (error?.maxNumber) {
    return `It is forbidden to upload more than ${RECEIPTS_SETTINGS.MAX_LENGTH} files at a time.`;
  }

  if (error?.acceptType) {
    return `Only images and PDFs are accepted.`;
  }

  return 'Unknown error';
};
