import styled from 'styled-components';

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 164px;
  width: 100%;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 164px;
  width: 100%;
  text-align: start;
  word-break: break-word;

  ${({ withHover }) =>
    withHover &&
    `
      cursor: pointer;
   `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 10px;

  & span {
    font-size: 16px;
  }
`;

export const NotificationLogs = styled.div`
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled.div`
  margin-top: 20px;
`;
